import {format} from 'date-fns';
import {trainDataSimpleDateString} from '../datetime/timeUtils.ts';

/**
 * Times strings must be converted to Dates to render
 * Meters must be converted to km
 * @param xAxisUnit
 * @param isTimeSelected
 */
export const distanceOrTimeTickFormatter =
  (isTimeSelected: boolean) => (value: number) => {
    return !isTimeSelected
      ? // Convert m to km
        (value / 1000).toLocaleString(0)
      : format(new Date(value), trainDataSimpleDateString);
  };
