import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {CrudList} from 'types/crud/crudList';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {length} from 'ramda';

/**
 * Returns the CrudList of the active TrainGroups
 * @param trainProps
 */
export const resolveActiveTrainGroupCrudList = (
  trainProps: TrainProps,
): CrudList<TrainGroup> => {
  if (
    length(
      trainProps?.trainGroupSingleTrainRunProps?.activeTrainGroupSingleTrainRuns || [],
    )
  ) {
    return trainProps?.trainGroupSingleTrainRunProps?.crudTrainGroups;
  } else {
    return trainProps?.trainGroupOnlyTrainFormationProps
      ?.crudTrainGroupOnlyTrainFormations!;
  }
};
