import {useCemitApiSwrResolveData} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {
  OrganizationLoaded,
  OrganizationMinimized,
} from '../../../../types/organizations/organization.ts';
import {useNotLoadingEffect} from '../../../../utils/hooks/useMemoHooks.ts';
import {equals, filter, has, indexBy, lensPath} from 'ramda';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {PerhapsIfLoading} from 'types/logic/requireIfLoaded.ts';
import {UserStateLoaded, UserStateMinimized} from '../../../../types/userState/userState';
import {
  mergeRightIfDefined,
  onlyOneValueOrThrow,
  sortThenIdListsEqual,
} from '../../../../utils/functional/functionalUtils.ts';
import {CemitApiOrganizationRoute} from '../../../../types/apis/cemitApi';
import {organizationManifests} from 'apis/apiOrganizationConfigs/organizationManifest.ts';
import {OrganizationManifest} from 'types/organizations/organizationManifest';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {clearOrganizationLocalStorage} from 'config/appConfigs/appSettings.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {resolveOrganizationManifest} from 'utils/organization/organizationUtils.ts';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * Retrieves the Organization from the TrainApi based on the organization sourceKey
 * @param loading If true, do nothing
 * @param userState
 * @param organizationMinimized
 * @param organization
 * @param setOrganization
 */
export const useConfiguredApiForOrganization = (
  loading: boolean,
  userState: UserStateMinimized,
  organizationMinimized: OrganizationMinimized,
  organization: Perhaps<OrganizationLoaded>,
  setOrganization: StateSetter<Perhaps<OrganizationMinimized>>,
) => {
  // Resolve the organization data
  const {
    data: organizationsFromResponse,
    isValidating,
    isLoading,
  } = useCemitApiSwrResolveData(loading, userState, 'organization', {
    organization: organizationMinimized,
  });
  useNotLoadingEffect(
    loading || !organizationsFromResponse || isValidating || isLoading,
    () => {
      const organizationFromResponse = onlyOneValueOrThrow(organizationsFromResponse);
      // This deep compare only needs to be done once after setOrganization
      const mergedOrganization = mergeRightIfDefined(
        organization,
        organizationFromResponse,
      );
      if (!organization || !equals(organization, mergedOrganization)) {
        // Merge the static values from frontend with the queried values from the backend
        setOrganization(mergedOrganization);
      }
    },
    [organizationMinimized, organizationsFromResponse],
  );
};

/**
 * Admin only API call to get all Organizations
 * @param loading
 * @param userState
 * @param adminOnlyAllOrganizations
 * @param setAdminOnlyAllOrganizations
 */
export const useConfiguredApiForAdminOnlyOrganizations = (
  loading: boolean,
  userState: UserStateMinimized,
  adminOnlyAllOrganizations: PerhapsIfLoading<typeof loading, OrganizationMinimized[]>,
  setAdminOnlyAllOrganizations: StateSetter<OrganizationMinimized[]>,
) => {
  // Resolve the organization data
  const {data: organizationsFromResponse, error} =
    useCemitApiSwrResolveData<CemitApiOrganizationRoute>(
      loading,
      userState,
      'organizationAdminOnly',
      {},
    );
  useNotLoadingEffect(
    loading || !organizationsFromResponse || error !== undefined,
    (adminOnlyAllOrganizations, organizationsResponseData) => {
      const sourceKeyLookup = indexBy((organizationManifest: OrganizationManifest) => {
        return organizationManifest.organization.sourceKey;
      }, organizationManifests);
      // Only accept organizations in the organizationManifests
      const eligibleOrganizations = filter(
        (organizationsResponse: OrganizationMinimized) => {
          return has(organizationsResponse.sourceKey, sourceKeyLookup);
        },
        organizationsResponseData,
      );
      if (!sortThenIdListsEqual(eligibleOrganizations, adminOnlyAllOrganizations)) {
        setAdminOnlyAllOrganizations(eligibleOrganizations);
      }
    },
    [adminOnlyAllOrganizations, organizationsFromResponse] as const,
  );
};
/**
 * Set the given organization using setUserState, which leads to a full download of the organization
 * For now clear everything in the cache to prevent sync problems
 * Changing the userState.sourceKey forces all child trafficSimComponents of OrganizationDependency
 * to unmount and remount by changing the key on the top child
 */
export const handleAddOrganizationToFilter = (
  organizationProps: OrganizationProps,
  organization: OrganizationMinimized,
): void => {
  clearOrganizationLocalStorage();

  const organizationUserState = clsOrType<UserStateLoaded>(
    CemitTypename.userStateLoaded,
    {
      sourceKey: organization.sourceKey,
    },
  );
  const organizationManifest = resolveOrganizationManifest(organizationUserState);
  // Add an accessKey if configured in the OrganizationManifest to show custom applications
  const modifiedOrganzationUserState = setClassOrType(
    lensPath(['access', 'applicationAccess']),
    organizationManifest?.applicationAccess,
    organizationUserState,
  );
  organizationProps.setUserState(modifiedOrganzationUserState);
  organizationProps.setOrganization(undefined);
};
