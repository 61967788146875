import {OrderedVehicle} from '../../types/trains/orderedVehicle';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {mergeRight} from 'ramda';
import {createVehicle} from './vehicleCrud.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {useMemoClsOrType} from '../../appUtils/typeUtils/useMemoClsOrType.ts';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';

export const createOrderedVehicle = (orderedVehicle: OrderedVehicle) => {
  return clsOrType<OrderedVehicle>(
    CemitTypename.orderedVehicle,
    mergeRightIfDefined(orderedVehicle, {vehicle: createVehicle(orderedVehicle.vehicle)}),
  );
};
