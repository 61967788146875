import {memo, ReactElement, useState} from 'react';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';

/***
 * Loads/Updates MapData layers dependent on sensor data
 * Depends directly on TrainGroupSensor props in trainProps.trainGroupSingleTrainRunProps.geojsonProps and TrainMap props
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainMapSensorLayerDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement<
  Required<TrainAppMapDependencyProps>
> => {
  const [are3dLayersUpdating, set3dLayersUpdating] = useState(false);

  // The 3d layers are dependent on sensor data
  //const dataThresholds = trainProps.trainGroupOnlyTrainFormationProps.dataThresholds;

  // const useUpdate3dForTrainMapProps: UseUpdate3dForTrainMapHookProps = unlessLoadingProps(
  //   loading,
  //   () => {
  //     return {
  //       trainMap: mapProps.trainMap,
  //       trainRouteOrGroup: trainProps.trainRouteGroupProps.trainRouteOrGroup,
  //       featurePropPath: appProps.chosenAcceleration.derivedProp,
  //       dataColumns3DLayerAreVisible: (
  //         mapProps.mapLayerProps.trainMapLayerProps
  //           .trainMapButtonStates as TrainMapStateProps
  //       ).dataColumns3DLayerAreVisible,
  //       dataThresholds,
  //       set3dLayersUpdating,
  //     } as UseUpdate3dForTrainMapHookProps;
  //   },
  // );
  // useUpdate3dForTrainMap({
  //   // TODO Currently not support for if doesOrganizationHaveAlertsView returns true
  //   loading:
  //     loading ||
  //     doesOrganizationHaveAlertsView(
  //       organizationProps.loading,
  //       organizationProps.organization,
  //     ),
  //   appProps,
  //   organizationProps,
  //   trainProps,
  //   mapProps,
  //   hookProps: useUpdate3dForTrainMapProps,
  // });
  // Whenever mostRecentTooltipPayload changes, update the cursor layer on the map to show on the map
  // where the user is hovering on a chart

  return renderChildren!({
    appProps,
    organizationProps,
    mapProps: {
      trainMapSensorLayerProps: {
        // Depends directly on TrainGroupSensor props in trainProps.trainGroupSingleTrainRunProps.sensorProps
        // and TrainMap in mapProps
        loading:
          trainProps.trainGroupSingleTrainRunProps.sensorProps.loading ||
          mapProps.loading,
        are3dLayersUpdating,
        set3dLayersUpdating,
      },
      ...mapProps,
    },
    trainProps,
  });
};

TrainMapSensorLayerDependency.displayName = 'TrainMapSensorLayerDependency';
export default TrainMapSensorLayerDependency;
//export default memo(
//  TrainMapSensorLayerDependency,
/* (prevProps, currentProps) => {
  const appPropsEqual = prevProps.appProps == currentProps.appProps;
  const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
  const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
  const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
  return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
}, */
//);
