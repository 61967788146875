import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {Activity} from 'types/userState/activity';
import {CemitTypename} from 'types/cemitTypename.ts';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * Creates an active Actvity object for a TrainGroup
 * @param activity Optional overrides, such as isActiveColor
 */
export const createTrainGroupActivityAsActive = (activity: Activity = undefined) => {
  return clsOrType<Activity>(
    CemitTypename.activity,
    mergeRightIfDefined(
      {
        isActive: true,
        isSelected: true,
        isVisible: true,
        isListingExpanded: false,
        isActiveColor: undefined,
      },
      activity || {},
    ),
  );
};
/**
 * Creats an inactive Activity object for a TrainGroup
 * @param activity Optional overrides, such as isActiveColor
 */
export const createTrainGroupActivityAsInactive = (activity: Activity = undefined) => {
  return clsOrType<Activity>(
    CemitTypename.activity,
    mergeRightIfDefined(
      {
        isActive: false,
        isSelected: false,
        isVisible: false,
        isListingExpanded: false,
        isActiveColor: undefined,
      },
      activity || {},
    ),
  );
};

/**
 * Simple mergeRight of Activity, where previous is optional
 * @param existing
 * @param incoming
 */
export const mergeTrainGroupActivity = (
  existing: Perhaps<Activity>,
  incoming: Activity,
) => {
  return clsOrType<Activity>(
    CemitTypename.activity,
    mergeRightIfDefined(existing || {}, incoming),
  );
};
