/**
 * Creates a cemitFilterWithTrainRouteGroup, the template for CemitFilters
 * @param trainRoute The TrainRoute instance
 * @param distance
 * @returns cemitFilterWithTrainRouteGroup
 */
import {equals, find, lensProp, map, length} from 'ramda';
import {evalFilter} from 'appUtils/cemitFilterUtils/cemitFilterEval.ts';
import {
  extractAndEvaluateMatchingFilters,
  idsFromEqualsOrIncludes,
  removeCemitFiltersOfType,
} from 'appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {CemitFilter} from '../../types/cemitFilters/cemitFilter.d.ts';
import {TrainRoute} from '../../types/trainRouteGroups/trainRoute';
import {CemitFilterTrainRouteGroup} from '../../types/cemitFilters/cemitFilterTrainRouteGroup';
import {CemitFilterProps} from '../../types/cemitFilters/cemitFilterProps';
import {typenameEquals} from '../typeUtils/typenameUtils.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {TrainRouteOrGroup} from '../../types/trainRouteGroups/trainRouteOrGroup';
import {TrainRouteGroup} from '../../types/trainRouteGroups/trainRouteGroup';
import {CemitFilterTrainRouteGroupProps} from '../../types/cemitFilters/cemitFilterTrainRouteGroupProps';
import {PerhapsCemited} from '../../types/cemited';
import {overClassOrType} from '../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {CemitFilterConfig} from '../../types/cemitFilters/cemitFilterConfig';
import {CemitFilterRightSideExpression} from '../../types/cemitFilters/cemitFilterRideSideExpression';
import {clsOrType} from '../typeUtils/clsOrType.ts';
import {clearOrganizationLocalStorage} from 'config/appConfigs/appSettings.ts';

export const cemitFilterTrainRouteGroupRightSideExpression: CemitFilterRightSideExpression =
  clsOrType<CemitFilterRightSideExpression>(
    CemitTypename.cemitFilterRightSideExpression,
    {view: {lensPath: ['trainRun', 'id']}},
  );

export const isCemitFilterTrainRouteGroup = (cemitFilter: CemitFilter) => {
  return typenameEquals(CemitTypename.cemitFilterTrainRouteGroup, cemitFilter);
};
export const createCemitFilterTrainRouteGroup = (
  trainRouteOrGroup: TrainRouteOrGroup,
): CemitFilterTrainRouteGroup => {
  // filter and its trafficSimComponents can match any Ramda function: https://ramdajs.com/
  // where keys are function names and values are arrays or scalar arguments
  // which can also be ramda functions or the trainRun being tested by using
  // prop: ['id'] or similar, where it's assumed that the second argument to prop
  // is the trainRun being tested
  // Assume that all possible TrainRuns are the argument to filter
  // The following translates to in Ramda
  // R.filter(
  //  R.allPass(
  //    R.equals(
  //      trainRoute.id,
  //      R.view(
  //        R.lensPath(['trainRoute', 'id']),
  //        trainRun
  //      )
  //    )
  //  )
  // )
  // and on the server is converted to SqlAlchemy
  // select(TrainRun).where(TrainRun.route_id == route.id)
  // When more filters are added in CemitFilters, they can add at
  // the allPass level to create ANDs, whose expressions can themselves
  // contains and/or/not or other logic
  return clsOrType<CemitFilterTrainRouteGroup>(CemitTypename.cemitFilterTrainRouteGroup, {
    equals: [trainRouteOrGroup.id, cemitFilterTrainRouteGroupRightSideExpression],
  });
};

export const evalCemitFilterTrainRouteGroup = (
  cemitFilterWithTrainRouteGroup: CemitFilterTrainRouteGroup,
  props: CemitFilterProps,
) => {
  return overClassOrType(
    lensProp('equals'),
    (equalsFilter: CemitFilterTrainRouteGroup) => {
      return evalFilter(equalsFilter, props);
    },
    cemitFilterWithTrainRouteGroup,
  );
};

/**
 * Extract the extractCemitFilterTrainRouteGroups from cemitFilter
 * @param cemitFilter
 * @param props
 */
export const extractCemitFilterTrainRouteGroups = (
  cemitFilter: CemitFilter,
  props: CemitFilterTrainRouteGroupProps,
): CemitFilterTrainRouteGroup[] => {
  return extractAndEvaluateMatchingFilters<TrainRouteGroup, CemitFilterTrainRouteGroup>(
    isCemitFilterTrainRouteGroup,
    evalCemitFilterTrainRouteGroup,
    cemitFilter,
    props,
  );
};

/**
 * Extracts the TrainRoutes from the cemitFilter
 * @param cemitFilter
 * @param props
 * @param props.trainRouteGroups Required in order to resolve TrainRouteGroups from the filter ids
 * @param props.trainRouteGroups Required in order to resolve TrainRoutes from the filter ids
 * @returns {*}
 */
export const extractTrainRouteGroupsFromCemitFilter = (
  cemitFilter: PerhapsCemited<CemitFilter<TrainRouteGroup>>,
  props: CemitFilterTrainRouteGroupProps,
): TrainRouteOrGroup[] => {
  if (!cemitFilter) {
    return [];
  }
  const trainRouteEvaluatedFilters = extractAndEvaluateMatchingFilters<TrainRouteGroup>(
    isCemitFilterTrainRouteGroup,
    evalCemitFilterTrainRouteGroup,
    cemitFilter,
    props,
  );
  return map((cemitFilterTrainRouteGroup) => {
    // Currently, we only support one id.
    const [trainRouteOrGroupId] = idsFromEqualsOrIncludes(cemitFilterTrainRouteGroup);
    if (!length(trainRouteOrGroupId)) {
      throw new Error(
        'cemitFilterTrainRouteGroup does not have any trainRouteOrGroup ids',
      );
    }
    const trainRouteOrGroup = find(
      (trainRoute) => equals(trainRouteOrGroupId, trainRoute.id),

      [...(props.trainRouteGroups || []), ...(props.trainRoutes || [])],
    );
    if (!trainRouteOrGroup) {
      // This only happens when switching users. It should never happen, but for now clear local storage values and reload
      clearOrganizationLocalStorage();
      location.reload();
    }
    return trainRouteOrGroup;
  }, trainRouteEvaluatedFilters);
};

/**
 * We only allow one TrainRoute at a time currently, so cemitFilterWithTrainRouteGroup
 * this is placed under CemitFilterTrainRouteGroup.allPass
 * @param cemitFilterParent A parent CemitFilter scoped to include cemitFilterWithTrainRouteGroups
 * @param cemitFilterWithTrainRouteGroup
 * @returns {*}
 */
export const addCemitFilterTrainRoute = (
  cemitFilterParent: CemitFilterTrainRouteGroup,
  cemitFilterWithTrainRouteGroup: CemitFilterTrainRouteGroup,
): CemitFilterTrainRouteGroup => {
  // Remove the previous first
  const cemitFilterWithTrainRouteGroupsFiltersRemoved = removeCemitFiltersOfType<
    TrainRouteGroup,
    CemitFilterTrainRouteGroup
  >(isCemitFilterTrainRouteGroup, evalCemitFilterTrainRouteGroup, cemitFilterParent);

  return overClassOrType(
    lensProp('allPass'),
    (allPassObj: CemitFilterTrainRouteGroup[]) => {
      return [...allPassObj, cemitFilterWithTrainRouteGroup];
    },
    cemitFilterWithTrainRouteGroupsFiltersRemoved,
  );
};

/**
 * Extracts the label for the TrainRoutes or TrainRouteGroups from the filter
 * @param cemitFilter
 * @param props
 * @param props.trainRouteGroups List of TrainRoutes
 * @param props.trainRouteGroups List of TrainRouteGroups
 * @returns {[String]} List of names
 */
export const extractLabelsForTrainRoutes = (
  cemitFilter: CemitFilterTrainRouteGroup,
  props: CemitFilterTrainRouteGroupProps,
) => {
  const trainRoutes = extractTrainRouteGroupsFromCemitFilter(cemitFilter, props);
  return map((trainRoute: TrainRoute) => trainRoute.name, trainRoutes);
};

export const cemitFilterConfigTrainRouteGroup = clsOrType<
  CemitFilterConfig<TrainRouteGroup>
>(CemitTypename.cemitFilterTrainRouteGroup, {
  isCemitFilter: isCemitFilterTrainRouteGroup,
  evalCemitFilter: evalCemitFilterTrainRouteGroup,
  extractCemitFilter: extractCemitFilterTrainRouteGroups,
  rightSideExpression: cemitFilterTrainRouteGroupRightSideExpression,
  cemitFilterTypename: CemitTypename.cemitFilterTrainRouteGroup,
  cemitFilterPropsTypename: CemitTypename.cemitFilterTrainRouteGroupProps,
});
