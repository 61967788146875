import {LoadingExplanation} from 'types/async/loadingExplanation';
import {
  always,
  compose,
  isNil,
  last,
  length,
  lensPath,
  lensProp,
  over,
  slice,
  split,
  unless,
  view,
} from 'ramda';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useMemo} from 'react';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {PartialCemited} from 'types/cemited';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {WhatIsLoading} from 'types/dependencies/whatIsLoading';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {clsOrType} from '../../typeUtils/clsOrType.ts';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import {useWhatChanged} from '@simbathesailor/use-what-changed';

/**
 * Merge in TrainProps
 * @param trainProps The existing trainProps
 * @param childTrainPropsKeyOrPath Dot-separated string path whither to insert new trainProps
 * @param childTrainProps The child trainProps to insert. Maker sure that these are memoized before calling useMemoMergeTrainProps
 * @param cemitFilter Default undefined The child's cemitFilter, which is set to trainProps.cemitFilter to serve
 * as the parent CemitFilter to the next dependency and to be the most complete cemitFilter available
 * to the containers and trafficSimComponents
 * @param cemitTypenameOutput Defaults to trainProps.__typename. The typename if extending the trainProps interface,
 * such as going from TrainProps to SingleTrainGroupTrainProps
 * @param whatIsLoading
 */
export const useMemoMergeTrainProps = <
  IN_T extends TrainProps | PartialCemited<TrainProps>,
  // TODO How doe we allow T to be TrainDerivedProps here?
  OUT_T extends TrainProps | PartialCemited<TrainProps>,
>(
  trainProps: IN_T,
  cemitTypenameOutput: CemitTypename = trainProps.__typename,
  // TODO can be nested deep in trainProps, but I don't know how to represent that in TS yet
  childTrainPropsKeyOrPath: keyof TrainProps | string,
  // This should be all the deep prop objects of TrainProps without scope and loading. localPropsNotReady is used for loading
  childTrainProps: Omit<TrainProps[keyof TrainProps], 'scope' | 'loading'>,
  cemitFilter: Perhaps<CemitFilter> = undefined,
): PartialCemited<OUT_T> => {
  const childWhatIsLoading = childTrainProps.whatIsLoading;
  const localPropsNotReady = childWhatIsLoading?.loading || false;

  // Set the loading on childTrainProps if localPropsNotReady changes
  const childTrainPropsWithLoading = useMemo<typeof childTrainProps>(() => {
    return setClassOrType(lensProp('loading'), localPropsNotReady, childTrainProps);
  }, [childTrainProps, localPropsNotReady]);

  // Merge the childTrainPropsWithLoading into the trainProps if anything has changed
  const mergedTrainProps: TrainProps = useMemo(() => {
    return _merge(
      localPropsNotReady,
      trainProps,
      cemitTypenameOutput,
      childTrainPropsKeyOrPath,
      childTrainPropsWithLoading,
      cemitFilter,
    );
  }, [
    trainProps,
    cemitTypenameOutput,
    childTrainPropsKeyOrPath,
    childTrainProps,
    cemitFilter,
    childWhatIsLoading,
  ]);
  //Use this to debug a certain childTrainPropsKeyOrPath
  // if ('trainFormationDateProps' == childTrainPropsKeyOrPath) {
  //   useWhatChanged(
  //     [
  //       view(lensPath(split('.', childTrainPropsKeyOrPath)), mergedTrainProps),
  //       trainProps,
  //       cemitTypenameOutput,
  //       childTrainPropsKeyOrPath,
  //       childTrainProps,
  //       childTrainProps.cemitFilter,
  //       childWhatIsLoading,
  //     ],
  //     'updatedChildTrainProps, trainProps, cemitTypenameOutput, childTrainPropsKeyOrPath, childTrainProps, cemitFilter, childWhatIsLoading',
  //     childTrainPropsKeyOrPath,
  //   );
  // }
  return mergedTrainProps;
};

/**
 * Unmemoized version of useMemoMergeTrainProps for loops
 * @param trainProps
 * @param cemitTypenameOutput
 * @param childTrainPropsKeyOrPath
 * @param childTrainProps
 * @param cemitFilter
 */
export const mergeTrainProps = <
  IN_T extends TrainProps | PartialCemited<TrainProps>,
  // TODO How doe we allow T to be TrainDerivedProps here?
  OUT_T extends TrainProps | PartialCemited<TrainProps>,
>(
  trainProps: IN_T,
  cemitTypenameOutput: CemitTypename = trainProps.__typename,
  // TODO can be nested deep in trainProps, but I don't know how to represent that in TS yet
  childTrainPropsKeyOrPath: keyof TrainProps | string,
  // This should be all the deep prop objects of TrainProps without scope and loading. localPropsNotReady is used for loading
  childTrainProps: Omit<TrainProps[keyof TrainProps], 'scope' | 'loading'>,
  cemitFilter: Perhaps<CemitFilter> = undefined,
): PartialCemited<OUT_T> => {
  const childWhatIsLoading = childTrainProps.whatIsLoading;
  const localPropsNotReady = childWhatIsLoading?.loading || false;

  const whatIsLoadingMerged: WhatIsLoading = !childWhatIsLoading
    ? trainProps.whatIsLoading || {}
    : mergeWhatIsLoading(
        localPropsNotReady,
        trainProps.whatIsLoading || {},
        childWhatIsLoading,
        childTrainPropsKeyOrPath,
      );

  return _merge(
    localPropsNotReady,
    trainProps,
    cemitTypenameOutput,
    childTrainPropsKeyOrPath,
    childTrainProps,
    cemitFilter,
    whatIsLoadingMerged,
  );
};

export const _merge = <
  IN_T extends TrainProps | PartialCemited<TrainProps>,
  OUT_T extends TrainProps | PartialCemited<TrainProps>,
>(
  localPropsNotReady: boolean,
  trainProps: IN_T,
  cemitTypenameOutput: CemitTypename = trainProps.__typename,
  // TODO can be nested deep in trainProps, but I don't know how to represent that in TS yet
  childTrainPropsKeyOrPath: keyof TrainProps | string,
  // This should be all the deep prop objects of TrainProps without scope and loading. localPropsNotReady is used for loading
  childTrainProps: Omit<TrainProps[keyof TrainProps], 'scope' | 'loading'>,
  cemitFilter: Perhaps<CemitFilter> = undefined,
  whatIsLoadingMerged: WhatIsLoading,
) => {
  // Only update cemitFilter, loading, and loadingExplanation, we don't want to merge
  // and change all the other objects.
  const trainPropsMerged: OUT_T = compose(
    (trainProps: IN_T) => {
      return setClassOrType(lensProp('whatIsLoading'), whatIsLoadingMerged, trainProps);
    },
    (trainProps: IN_T) => {
      return setClassOrType(
        lensProp('loading'),
        trainProps.loading || localPropsNotReady,
        trainProps,
      );
    },
    (trainProps: IN_T) => {
      return unless(
        always(isNil(cemitFilter)),
        // If one is defined, set the cemitFilter to the top level, overwriting what is in trainProps
        (trainProps: IN_T) => {
          if (Array.isArray(cemitFilter)) {
            // TODO remove, temp problem
            throw new Error('cemitFilter cannot be an array');
          }
          return setClassOrType(lensProp('cemitFilter'), cemitFilter!, trainProps);
        },
      )(trainProps);
    },
  )(trainProps);

  /**
   * Set child props at the childTrainPropsKeyOrPath level.
   */
  const childLensPath = lensPath(split('.', childTrainPropsKeyOrPath));
  if (view(childLensPath, trainPropsMerged)) {
    throw new Error(
      `Attempt to set props of trainProps path ${childTrainPropsKeyOrPath} after they were already set by another dependency`,
    );
  }
  // We must mutate trainPropsMerged to prevent childTrainProps from being mutated
  const childLensParentParts = slice(0, -1, split('.', childTrainPropsKeyOrPath));
  const trainPropsMergedChild = length(childLensParentParts)
    ? view(lensPath(childLensParentParts), trainPropsMerged)
    : trainPropsMerged;
  trainPropsMergedChild[last(split('.', childTrainPropsKeyOrPath))] = childTrainProps;

  return clsOrType<OUT_T>(cemitTypenameOutput, trainPropsMerged) as TrainProps;
};

/**
 * Merges the parent trainProps.whatIsLoading with an incoming child whatIsLoading
 * @param localPropsNotReady
 * @param existing
 * @param incoming
 * @param incomingPropsKeyOrPath
 */
const mergeWhatIsLoading = (
  localPropsNotReady: boolean,
  existing: WhatIsLoading,
  incoming: WhatIsLoading,
  incomingPropsKeyOrPath: string,
): WhatIsLoading => {
  return compose(
    // Merge obj if localPropsNotReady
    (existing: WhatIsLoading) => {
      return over(
        lensProp('obj'),
        (loadingExplanation: LoadingExplanation) => {
          return mergeRightIfDefined(loadingExplanation, {
            [incomingPropsKeyOrPath]: localPropsNotReady ? incoming.obj : {},
          });
        },
        existing,
      );
    },
    // Merge loadingExplanation if localPropsNotReady
    (existing: WhatIsLoading) => {
      return over(
        lensProp('loadingExplanation'),
        (loadingExplanation: LoadingExplanation) => {
          return mergeRightIfDefined(loadingExplanation, {
            [incomingPropsKeyOrPath]: localPropsNotReady
              ? incoming.loadingExplanation
              : {},
          });
        },
        existing,
      );
    },
  )(existing);
};
