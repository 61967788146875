import {
  CemitFilterCall,
  CemitFilterDateRecurrence,
  CemitFilterDateRecurrenceDate,
  CemitFilterDateRecurrenceDay,
  CemitFilterDateRecurrenceTime,
  DaysOrDatesOrTimes,
} from 'types/cemitFilters/cemitFilterDateRecurrence';
import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {CemitFilterDateInterval} from 'types/cemitFilters/cemitFilterDateInterval';
import {
  CemitFilter,
  CemitFilterComponent,
  CemitFilterExpression,
} from 'types/cemitFilters/cemitFilter';
import {cemitTypenameToClass} from '../cemitAppCemitedClasses/cemitClassResolvers.ts';
import {Cemited} from 'types/cemited';
import {ClassFromObj} from 'types/classes';
import {DateRecurrence} from 'types/datetime/dateRecurrence';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {TrainFormation} from 'types/trains/trainFormation';
import {CemitFilterTrainFormation} from 'types/cemitFilters/cemitFilterTrainFormation';
import {IdentifiedClass} from '../cemitAppCemitedClasses/identifiedClass.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

export class CemitFilterClass<T extends Cemited>
  extends IdentifiedClass
  implements CemitFilter<T>
{
  constructor(obj: CemitFilter<T>) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilter;
  modelTypename: CemitTypename;

  /**
   * Looks up the modelType of self.__typename
   * @param self
   */
  get modelClass(): ClassFromObj<T> {
    return cemitTypenameToClass<T>(this.modelTypename);
  }

  allPass?: (CemitFilter<T> | CemitFilterExpression)[];
  any?: CemitFilterExpression[] | CemitFilter<T>[];
  equals?: [CemitFilterComponent, CemitFilterComponent];
  and?: CemitFilterExpression;
  or?: CemitFilterExpression;
  include?: [CemitFilterExpression, CemitFilterExpression[]];
  parent: CemitFilter<T>;
}

export class CemitFilterDateIntervalClass
  extends CemitFilterClass<DateInterval>
  implements CemitFilterDateInterval
{
  constructor(obj: CemitFilterDateInterval) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterDateInterval;
  modelTypename = CemitTypename.dateInterval;
}

export class CemitFilterDateRecurrenceClass
  extends CemitFilterClass<DateRecurrence>
  implements CemitFilterDateRecurrence
{
  constructor(obj: CemitFilterDateRecurrence) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterDateRecurrence;
  modelTypename = CemitTypename.dateRecurrence;

  includes?: [DaysOrDatesOrTimes, CemitFilterCall];
}

export class CemitFilterDateRecurrenceDateClass
  extends CemitFilterClass<DateRecurrence>
  implements CemitFilterDateRecurrenceDate
{
  constructor(obj: CemitFilterDateRecurrenceDate) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterDateRecurrenceDate;
}

export class CemitFilterDateRecurrenceDayClass
  extends CemitFilterClass<DateRecurrence>
  implements CemitFilterDateRecurrenceDate
{
  constructor(obj: CemitFilterDateRecurrenceDay) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterDateRecurrenceDay;
  modelTypename = CemitTypename.dateRecurrence;
}

export class CemitFilterDateRecurrenceTimeClass
  extends CemitFilterClass<DateRecurrence>
  implements CemitFilterDateRecurrenceDate
{
  constructor(obj: CemitFilterDateRecurrenceTime) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterDateRecurrenceTime;
  modelTypename = CemitTypename.dateRecurrenceTime;
}

export class CemitFilterTrainRouteGroupClass
  extends CemitFilterClass<TrainRouteOrGroup>
  implements CemitFilterTrainRouteGroup
{
  constructor(obj: CemitFilterTrainRouteGroupClass) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterTrainRouteGroup;
  modelTypename = CemitTypename.trainRouteGroup;
}

export class CemitFilterTrainFormationClass
  extends CemitFilterClass<TrainFormation>
  implements CemitFilterTrainFormation
{
  constructor(obj: CemitFilterTrainRouteGroupClass) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterTrainFormation;
  modelTypename = CemitTypename.trainFormation;
}
export class CemitFilterTrainAttributeAlertClass
  extends CemitFilterClass<AttributeAlert>
  implements CemitFilterAttributeAlert
{
  constructor(obj: CemitFilterTrainRouteGroupClass) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterAttributeAlert;
  modelTypename = CemitTypename.attributeAlert;
}

export class CemitFilterTrainAttributeAlertChartClass
  extends CemitFilterClass<AttributeAlert>
  implements CemitFilterAttributeAlertChart
{
  constructor(obj: CemitFilterTrainRouteGroupClass) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.cemitFilterAttributeAlertChart;
  modelTypename = CemitTypename.attributeAlert;
}
