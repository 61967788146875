import React, {forwardRef, ReactElement, RefObject} from 'react';
import StackedDataPathComparisonChart, {
  StackedDataPathComparisonChartProps,
} from 'components/charts/stackedChart/StackedDataPathComparisonChart.tsx';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {TrainProps} from '../../../types/propTypes/trainPropTypes/trainProps';
import {ChartDataConfig} from '../../../types/dataVisualizations/chartDataConfig.ts';
import {ScheduledStopPoint} from '../../../types/stops/scheduledStopPoint';
import {SxProps} from '@mui/system';
import {DataFeatureSet} from '../../../types/dataVisualizations/dateFeatureSet';
import {CemitComponentProps} from '../../../types/propTypes/cemitComponenProps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {
  useNotLoadingMemoFinalizeChartDataConfigs,
  useNotLoadingMemoCreateChartDataCommonConfigFinalizeds,
} from '../../../async/trainAppAsync/trainAppHooks/typeHooks/chartDataFeatureHooks.ts';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {clsOrType} from '../../../appUtils/typeUtils/clsOrType.ts';

export interface StackedDataPathComparisonChartContainerProps
  extends CemitComponentProps {
  loading: boolean;
  yAxisWidth: number;
  dataPathsConfigs: ChartDataConfig[];
  trainDataPathStackedChartRefWidth: number;
  referenceStopLookup: Record<string, ScheduledStopPoint>;
  // The outer dimension is for the TrainGroup. The inner is for the DataFeatureSets per TrainGroup
  dataFeatureSets: DataFeatureSet[][];
  maybeLifetimeContainers?: ReactElement[];
}
/**
 * Compares track data for two different week
 * @param map Mapbox map for adding a hover layer when the user hovers on the chart
 * @param yAxisWidth Chart y-axis width in pixels
 * @param comporisionTracks
 * @param comparisonTrackDataSets The two datasets to compare. They should both cover the same geojson
 * points a long a track. They can represent aggregated runs of trains or single runs of trains.
 * @param dataPaths The str paths within geojson properties object to display
 * E.g. 'acceleration.sumMaxMean'
 * @param comparisonTrackDataSets[*].data The dataset
 * @param comparisonTrackDataSets[*].label The label of the dataset
 * @param comparisonTrackDataSets[*].featuresPath The string path to the geojson features, e.g. 'features' or 'foo.geojson.features'
 * string based on the item and comparisonPair.
 * E.g. (item, comparisonPair) => item?.stroke === '#FAF4DE' ? comparisonLabelPair[0] : comparisonLabelPair[1]
 * @param width Width of the dataVisualizations
 * @param height Height of the dataVisualizations
 * @param setChartWidth Setter function to tell the TrainRunLine how wide the svg chart component is
 * @returns {JSX.Element}
 */
const StackedDataPathComparisonChartContainer = forwardRef(
  (
    {
      appProps,
      organizationProps,
      trainProps,
      componentProps: {
        loading,
        yAxisWidth,
        dataPathsConfigs,
        trainDataPathStackedChartRefWidth,
        referenceStopLookup,
        dataFeatureSets,
        maybeLifetimeContainers,
        sx,
      },
    }: TrainAppTrainComponentDependencyProps<StackedDataPathComparisonChartContainerProps>,
    ref: RefObject<any>,
  ) => {
    const chartDataConfigAndFilteredDataFeatureSets =
      useNotLoadingMemoFinalizeChartDataConfigs(
        !dataPathsConfigs,
        dataPathsConfigs,
        dataFeatureSets || [],
      );

    // If ChartDataConfigs have a common chartDataConfigGroupingKey, they are put into the same set so that they
    // can be displayed on the same graph
    const chartDataCommonConfigFinalizeds =
      useNotLoadingMemoCreateChartDataCommonConfigFinalizeds(
        !chartDataConfigAndFilteredDataFeatureSets,
        chartDataConfigAndFilteredDataFeatureSets,
      );

    return (
      <StackedDataPathComparisonChart
        key="StackedDataPathComparisonChart"
        {...{
          ref,
          appProps,
          organizationProps,
          trainProps,
          componentProps: clsOrType<StackedDataPathComparisonChartProps>(
            CemitTypename.stackedDataPathComparisonChartProps,
            {
              loading,
              yAxisWidth,
              chartDataConfigAndFilteredDataFeatureSets,
              chartDataCommonConfigFinalizeds,
              referenceStopLookup,
              trainDataPathStackedChartRefWidth,
              maybeLifetimeContainers,
              sx,
            },
          ),
        }}
      />
    );
  },
);
StackedDataPathComparisonChartContainer.displayName =
  'StackedDataPathComparisonChartContainer';
export default StackedDataPathComparisonChartContainer;
