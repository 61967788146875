import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {
  OrganizationLoaded,
  OrganizationMinimized,
} from 'types/organizations/organization.ts';
import {
  doesOrganizationHaveServiceLines,
  getOrganizationViews,
} from 'utils/organization/organizationUtils.ts';
import {
  any,
  append,
  concat,
  cond,
  equals,
  filter,
  find,
  ifElse,
  includes,
  intersection,
  length,
  T,
  uniq,
  without,
} from 'ramda';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {isViewActive} from 'appUtils/cemitAppUtils/viewUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Activatable} from 'types/userState/activity';
import {ScalarOrList} from 'types/typeHelpers/listHelpers';
import {
  findOrThrow,
  includesAll,
  includesAny,
  toArrayIfNot,
} from 'utils/functional/functionalUtils.ts';
import {trainFormationsGrouping} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendViewGroups.ts';
import {FrontendViewGroupSelectionDisplay} from 'types/frontendViews/frontendViewGroupSelectionDisplay';
import {useMemo} from 'react';
import {isActiveFrontendViewGroupSelection} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/activityUtils.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';

/**
 * Sets the activeViews to the minimum views configured for the organization.
 * These views are currently [
 * FrontendViews.list,
 * FrontendViews.map,
 * trainFormationsMutuallyExclusiveGrouping.defaultFrontendViewOrViews
 * FrontendView.trainGroupMenu
 * ]
 * The first enables the list view,
 * the second the map,
 * the third determines the contents of the list view to be TrainFormations,
 * the fourth defaults trainGroups to the trainGroupMenu views since TrainFormations are listed by default
 * @param organization
 * @param activeViews
 * @param setActiveViews
 */
export const useSetDefaultActiveView = (
  organization: OrganizationMinimized | OrganizationLoaded | undefined,
  activeViews: FrontendView[] | undefined,
  setActiveViews: StateSetter<FrontendView[] | undefined>,
) => {
  return useNotLoadingEffect(
    !organization,
    () => {
      if (!activeViews) {
        // Set to the minimum views available to the organization
        const organizationViews: Perhaps<FrontendView[]> =
          getOrganizationViews(organization);
        const defaultActiveViews: FrontendView[] = filter(
          (frontendView) => {
            return includes(frontendView, organizationViews);
          },
          [
            FrontendView.map,
            ...toArrayIfNot(trainFormationsGrouping.defaultFrontendViewOrViews),
            FrontendView.trainGroupMenu,
          ],
        );
        setActiveViews(defaultActiveViews);
      }
    },
    [activeViews, organization],
  );
};

/**
 * Adds/removes the given frontendView to/from appProps.activeViews if anything is put in
 * activatables has activity.isActive = true. The frontendView can only be added to actvieViews
 * if it is configured in organizationPros.organization.frontendOptions.views
 * @param loading
 * @param appProps
 * @param organizationProps
 * @param frontendViewOrViews A scalar or list of FrontendViews to activate
 * @param activatables
 */
export const useNotLoadingEffectSyncToAnyAreActive = (
  loading: boolean,
  appProps: TrainAppProps,
  organizationProps: OrganizationProps,
  frontendViewOrViews: ScalarOrList<FrontendView>,
  activatables: Perhaps<Activatable[]>,
) => {
  const frontendViews: FrontendView[] = toArrayIfNot(frontendViewOrViews);
  // Update the activeViews to include FrontendViews.detail whenever activeTrainGroups has
  // at least one item. This allows the user to see details about the selected TrainGroup(s)
  const dependencies = [
    activatables,
    appProps.activeViews,
    organizationProps.organization,
  ] as const;
  useNotLoadingEffect(
    loading || !appProps.activeViews,
    (activatables, activeViews, organization: OrganizationLoaded) => {
      // Get teh views the organization has intersected with the specified frontendViewOrViews
      const organizationViews = getOrganizationViews(organization)!;
      const eligibleFrontendViews = intersection(frontendViews, organizationViews);

      if (activeViews && length(eligibleFrontendViews)) {
        // If the organization has frontendViews but it any are not currently active, make them so
        const updatedActiveViews = ifElse(
          (activatables: Activatable[]) => {
            return any((activatable: Activatable): boolean => {
              return Boolean(activatable.activity?.isActive);
            }, activatables);
          },
          // Something in activatables is active, activate eligibleFrontendViews
          () => {
            return includesAll(eligibleFrontendViews, activeViews)
              ? // Nothing to do
                activeViews
              : uniq(concat(activeViews, eligibleFrontendViews));
          },
          () => {
            // Nothing in activatables is active, remove eligibleFrontendViews
            return includesAny(eligibleFrontendViews, activeViews)
              ? without(eligibleFrontendViews, activeViews)
              : activeViews;
          },
        )(activatables);

        if (!equals(activeViews, updatedActiveViews)) {
          appProps.setActiveViews(updatedActiveViews);
        }
      }
    },
    dependencies,
  );
};

/**
 * Adds or removes FrontendViews.detail to appProps.activeViews if anything is put in
 * trainProps.trainGroupActivityProps.activeTrainGroups or it becomes empty
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param loading
 */
export const useUpdateTrainRoutesViewActive = (
  {appProps, organizationProps, trainProps}: TrainAppTrainDependencyProps,
  loading: boolean,
) => {
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);
  const activeViews = appProps.activeViews;
  // Update the activeViews to include FrontendViews.trainRoute if areServiceLinesInScope(trainProps) is true
  useNotLoadingEffect(
    loading || !activeViews,
    (organizationHasServiceLines, activeViews) => {
      const trainRouteIsActive = isViewActive(appProps, FrontendView.trainRoute);
      if (activeViews) {
        const updatedActiveViews: FrontendView[] = cond([
          [
            () => organizationHasServiceLines && !trainRouteIsActive,
            (activeViews: FrontendView[]) => {
              return append(FrontendView.trainRoute, activeViews);
            },
          ],
          [
            () => !organizationHasServiceLines && trainRouteIsActive,
            (activeViews: FrontendView[]) => {
              return without([FrontendView.trainRoute], activeViews);
            },
          ],
          [T, (v) => v],
        ])(activeViews) as FrontendView[];

        if (activeViews !== updatedActiveViews) {
          appProps.setActiveViews(updatedActiveViews);
        }
      }
    },
    [organizationHasServiceLines, appProps.activeViews] as const,
  );
};

/**
 * Returns the first FrontendViewGroupSelectionDisplay that where its frontendViewGroupSelection isActiveFrontendViewGroupSelection
 * @param loading
 * @param frontendViewGroupSelectionDisplays
 * @param activeFrontendViews
 */
export const useNotLoadingMemoActiveFrontendViewGroupSelectionDisplay = (
  loading: boolean,
  frontendViewGroupSelectionDisplays: FrontendViewGroupSelectionDisplay[],
  activeFrontendViews: FrontendView[],
): Perhaps<FrontendViewGroupSelectionDisplay> => {
  return useNotLoadingMemo(
    loading,
    (frontendViewGroupSelectionDisplays, activeFrontendViews) => {
      return findOrThrow(
        (frontendViewGroupSelectionDisplay: FrontendViewGroupSelectionDisplay) => {
          return isActiveFrontendViewGroupSelection(
            frontendViewGroupSelectionDisplay.frontendViewGroupSelection,
            activeFrontendViews,
          );
        },
        frontendViewGroupSelectionDisplays,
      );
    },
    [frontendViewGroupSelectionDisplays, activeFrontendViews] as const,
  );
};
