import {SensorDataTrainGroup} from '../../../../types/trainGroups/sensorDataTrainGroup';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {equals, findIndex, lensIndex, lensPath, mergeRight, over, set, view} from 'ramda';
import {idsEqual} from '../../../../utils/functional/functionalUtils.ts';
import {
  overClassOrType,
  overClassOrTypeList,
  setClassOrType,
} from '../../../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {TrainGroup} from '../../../../types/trainGroups/trainGroup';
import {mergeTrainGroup} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {
  completeLoadingStatus,
  dumpLoadingStatus,
} from '../../../../utils/loading/loadingStatusUtils.ts';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';

/**
 * Update requestedTrainGroupWithSensorDataStatus to completed and merge this value
 * into the corresponding value in setLoadingSensorDataTrainGroups
 * @param requestedSensorDataTrainGroup
 * @param setLoadingSensorDataTrainGroups
 * @param statusLensPath Place to update the status on the requestedTrainGroupWithSensorDataStatus
 * @param timezoneStr
 * Defaults to [] to update the top-level object
 */
export const requestedSensorDataTrainGroupMergeAndMarkComplete = (
  requestedSensorDataTrainGroup: SensorDataTrainGroup,
  setLoadingSensorDataTrainGroups: StateSetter<TrainGroup[]>,
  statusLensPath: string[],
  timezoneStr: string,
): SensorDataTrainGroup => {
  // Mark the request as complete
  const updatedRequestedSensorDataTrainGroup = setClassOrType(
    lensPath(statusLensPath),
    completeLoadingStatus,
    requestedSensorDataTrainGroup,
  );
  setLoadingSensorDataTrainGroups(
    (existingLoadingSensorDataTrainGroups: SensorDataTrainGroup[]) => {
      const existingLoadingSensorDataTrainGroupIndex = findIndex(
        (existingLoadingSensorDataTrainGroup) =>
          idsEqual(
            updatedRequestedSensorDataTrainGroup,
            existingLoadingSensorDataTrainGroup,
          ),
        existingLoadingSensorDataTrainGroups,
      );
      // This should never happen
      if (equals(-1, existingLoadingSensorDataTrainGroupIndex)) {
        throw new Error(
          `existingLoadingSensorDataTrainGroups does not contain requestedSensorDataTrainGroup`,
        );
      }
      // Set the complete status on the matching instance
      const updated: SensorDataTrainGroup[] = overClassOrTypeList(
        lensIndex(existingLoadingSensorDataTrainGroupIndex),
        (existingLoadingSensorDataTrainGroup) => {
          return setClassOrType(
            lensPath(statusLensPath),
            completeLoadingStatus,
            existingLoadingSensorDataTrainGroup,
          );
        },
        existingLoadingSensorDataTrainGroups,
      );
      const updatedInstance = updated[existingLoadingSensorDataTrainGroupIndex];
      console.debug(
        `For ${updatedInstance.trainGroup.localizedName(timezoneStr)}, updated SensorData status to ${dumpLoadingStatus(view(lensPath(statusLensPath), updatedInstance))}`,
      );
      return updated;
    },
  );
  return updatedRequestedSensorDataTrainGroup;
};
