import React from 'react';
import {Box, Chip, FormControl, InputLabel, Select, MenuItem} from '@mui/material';

const TrainFilters = () => {
  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2}}>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>Train ID</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={444031}>Train 444031</MenuItem>
          <MenuItem value={450032}>Train 450032</MenuItem>
          {/* Add more train IDs as needed */}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>Status</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="ACTION REQUIRED">ACTION REQUIRED</MenuItem>
          <MenuItem value="GOOD">GOOD</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>Fleet</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={444}>Fleet 444</MenuItem>
          <MenuItem value={450}>Fleet 450</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>Start Location</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="BEDHAMPTON">BEDHAMPTON</MenuItem>
          <MenuItem value="LONDON">LONDON</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>End Location</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="HAVANT">HAVANT</MenuItem>
          <MenuItem value="BRIGHTON">BRIGHTON</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>Current Position</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Next Station 1">Next Station 1</MenuItem>
          <MenuItem value="Next Station 2">Next Station 2</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>Parameter</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Parameter 1">Parameter 1</MenuItem>
          <MenuItem value="Parameter 2">Parameter 2</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{minWidth: 120}}>
        <InputLabel>SandBoxes</InputLabel>
        <Select>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Front">Front</MenuItem>
          <MenuItem value="Rear">Rear</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default TrainFilters;
