import React, {FC, useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {UserContext, UserContextType} from './UserContext.ts';
import {applicationRegistry} from './utils.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {hasAccess} from 'appUtils/authentication/authenticationUtils.ts';

/**
 * Checks the user access and redirects to the first product/applicaton the user can access.
 */
const AppAccessNavigator: FC = () => {
  const {userState} = useContext(UserContext) as UserContextType;

  if (userState.status !== AccessStatus.Authenticated) {
    return <></>;
  }

  // Find first route that is active and user has authorized access to
  for (const [, appAccess] of Object.entries(applicationRegistry)) {
    if (
      appAccess.isEnabled &&
      (appAccess.accessKey === undefined ||
        hasAccess(appAccess.accessKey, userState))
    ) {
      return <Navigate to={appAccess.indexRoute}></Navigate>;
    }
  }

  // No routes match, redirect to home page, the user is always guaranteed to have access to it
  return <Navigate to={'/home'}></Navigate>;
};

export default AppAccessNavigator;
