import {TFunction} from 'i18next';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  DataThreshold,
  DataThresholdStyles,
  ZoomLevelValue,
} from 'types/dataVisualizations/dataThreshold';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {RideComfortAttributeAlertSValue} from 'types/alerts/rideComfort/rideComfortAlertLevel.ts';
import {
  CEMIT_ALERT_CRITICAL,
  CEMIT_ALERT_NORMAL,
  CEMIT_ALERT_WARNING,
} from 'theme/cemitColors.ts';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';

export const rideComfortDataThresholds = (t: TFunction) => {
  return clsOrTypes<DataThreshold>(CemitTypename.dataThreshold, [
    {
      sourceKey: RideComfortAttributeAlertLevel.normal,
      label: t('good'),
      // Below RideComfortAttributeAlertSValue.warning
      value: RideComfortAttributeAlertSValue.none,
      style: {
        color: CEMIT_ALERT_NORMAL,
        icon: 'ok',
        size: [
          // Always hidden
          {zoom: 0, outputValue: 0} as ZoomLevelValue,
          {zoom: 22, outputValue: 0} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
    {
      sourceKey: RideComfortAttributeAlertLevel.warning,
      label: t('warning'),
      // from RideComfortAttributeAlertSValue.warning to RideComfortAttributeAlertSValue.critical
      value: RideComfortAttributeAlertSValue.warning,
      iconConfigSize: 12,
      style: {
        color: CEMIT_ALERT_WARNING,
        icon: 'warning',
        size: [
          // For value warning, show these outputValues for circle-radius at each zoom level
          // TODO Keep the outputValue at 1 to prevent blurriness. We use the Svg image holder size
          // to make this icon bigger without blurring
          {zoom: 0, outputValue: 1} as ZoomLevelValue,
          {zoom: 22, outputValue: 1} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
    {
      sourceKey: RideComfortAttributeAlertLevel.critical,
      label: t('critical'),
      // All values above RideComfortAttributeAlertSValue.critical
      value: RideComfortAttributeAlertSValue.critical,
      iconConfigSize: 15,
      style: {
        color: CEMIT_ALERT_CRITICAL,
        icon: 'critical',
        size: [
          // For value error, show these outputValues for circle-radius at each zoom level
          // TODO Keep the outputValue at 1 to prevent blurriness. We use the Svg image holder size
          // to make this icon bigger without blurring
          {zoom: 0, outputValue: 1} as ZoomLevelValue,
          {zoom: 22, outputValue: 1} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
  ]);
};
