import React from 'react';
import {Card, CardContent, Typography, Box, Paper, useTheme} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface TrainDetailProps {
  sandBoxHistoricData?: HistoricalData[];
}

// Mock data for the line chart
interface HistoricalData {
  date: string;
  level: number;
  sandbox: string; // To identify which sandbox the data came from
}

const convertName = (sandBoxName: string): string => {
  const parts = sandBoxName.split(/[\s-]+/);
  let part1 = '';
  let part2 = '';

  // Check the first part and convert based on the value
  switch (parts[0]) {
    case 'A':
      part1 = '+100';
      break;
    case 'B':
      part1 = '+200';
      break;
    default:
      part1 = '';
  }

  // Handle the "HOPPER" case with the next part being the number
  const hopperIndex = parts.indexOf('HOPPER');
  if (hopperIndex !== -1 && hopperIndex + 1 < parts.length) {
    const hopperNumber = parts[hopperIndex + 1];

    switch (`HOPPER ${hopperNumber}`) {
      case 'HOPPER 1':
        part2 = 'Wheel Position 6';
        break;
      case 'HOPPER 2':
        part2 = 'Wheel Position 3';
        break;
      default:
        part2 = '';
    }
  }

  // Combine the parts into a single string
  return `${part1} ${part2}`.trim();
};

const SandboxTrainDetail: React.FC<TrainDetailProps> = ({sandBoxHistoricData}) => {
  // Filter out data with null or undefined level values
  const filteredData = sandBoxHistoricData!.filter(
    (data) => data.level !== null && data.level !== undefined,
  );
  filteredData.forEach((data) => {
    if (data.level !== null) {
      data.level = Math.ceil(data.level); // Round up the level value
    }
  });
  // Extract all unique sandboxes
  const uniqueSandboxes = [...new Set(filteredData.map((data) => data.sandbox))];

  return (
    <Card>
      <CardContent>
        <Typography mt={2}>SandBox Usage Trends</Typography>
        <Paper style={{padding: '16px', height: 200}}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={filteredData}
              margin={{top: 20, right: 30, left: 20, bottom: 20}}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />

              {/* Render a Line for each unique sandbox */}
              {uniqueSandboxes.map((sandbox, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey="level"
                  data={filteredData.filter((data) => data.sandbox === sandbox)}
                  name={convertName(sandbox)} // Use sandbox name as the legend name
                  // Random color
                  activeDot={{r: 8}}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default SandboxTrainDetail;
