import {map} from 'ramda';
import React from 'react';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Box, Stack, StackProps} from '@mui/material';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TrainGroupsTitleContainer} from 'components/charts/stackedChart/TrainGroupsTitleContainer.tsx';
import {Perhaps} from 'types/typeHelpers/perhaps';
import TrainGroupAddMenu, {
  TrainGroupOptionsMenuProps,
} from 'components/apps/trainAppComponents/chooserComponents/trainGroupOptionsComponents/TrainGroupAddMenu.tsx';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps';

export interface ChartDescriptionTitleProps extends CemitComponentProps {
  activeTrainGroups: Perhaps<TrainGroup[]>;
  width: string;
}

/**
 * The Header for the RideComfort and Chart description, showing the name of the TrainGroups
 * @param appProps
 * @param organizationProps
 * @param componentProps
 * @constructor
 */
const ChartDescriptionTitlesContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<ChartDescriptionTitleProps>) => {
  const {activeTrainGroups} = componentProps;

  const trainGroupTitleComponents = map((activeTrainGroup: TrainGroup) => {
    // Get active compact representations, removing those that are not loaded yet
    // Currently all filters always show a compact form
    const childComponentProps = {...componentProps, scopedTrainGroup: activeTrainGroup};
    const trainGroupContainer = (
      <TrainGroupsTitleContainer
        {...{
          appProps,
          organizationProps,
          trainProps,
          componentProps: childComponentProps,
        }}
      />
    );

    return (
      <Stack
        key={`trainGroupTitleStack${activeTrainGroup.localizedName(organizationProps.organization?.timezoneStr)}`}
        {...{
          sx: {width: '100%', justifyContent: 'space-between'},
          direction: 'row',
          spacing: 0.25,
        }}
      >
        {trainGroupContainer}
      </Stack>
    );
  }, activeTrainGroups);

  return (
    <Stack
      key="trainsLabelAndAddButton"
      {...ts<StackProps>({
        spacing: 1,
        sx: {minWidth: '100%', width: '100%', alignItems: 'center'},
        direction: 'row',
      })}
    >
      <Stack
        key="trainsLabel"
        {...ts<StackProps>({
          spacing: 1,
          sx: {justifyContent: 'start', width: '100%'},
          direction: 'row',
        })}
      >
        {trainGroupTitleComponents}
      </Stack>
      <Box {...{sx: {minWidth: '40px', maxWidth: '40x'}}}>
        <TrainGroupAddMenu
          {...ts<TrainAppTrainComponentDependencyProps<TrainGroupOptionsMenuProps>>({
            appProps,
            organizationProps,
            trainProps,
            componentProps: ts<TrainGroupOptionsMenuProps>({}),
          })}
        />
      </Box>
    </Stack>
  );
};
ChartDescriptionTitlesContainer.displayName = 'ChartDescriptionTitlesContainer';
export default ChartDescriptionTitlesContainer;
