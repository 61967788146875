import {reqStrPathThrowing} from '@rescapes/ramda';
import {
  ascend,
  cond,
  equals,
  find,
  map,
  propEq,
  propOr,
  sortWith,
  length,
  always,
} from 'ramda';
import {rangeDifferenceWithOrderedRanges} from 'utils/distance/distanceUtils.ts';
import {TrainRunDistanceRangeAndIntervalDifference} from '../../../types/trainRuns/trainRunDistanceRangeAndIntervalDifference';
import {TrainRun} from '../../../types/trainRuns/trainRun';
import {ScheduledStopPoint} from '../../../types/stops/scheduledStopPoint';
import {SensorDataTrainGroup} from '../../../types/trainGroups/trainGroupSensorDataDistanceIntervalAndRangeSet';
import {consolidateIntervals} from '../../../utils/ranges/rangeUtils.ts';
import {distanceResolutionOfDistanceRange} from '../../../config/appConfigs/trainConfigs/dataConfig.tsx';
import {DistanceRange} from '../../../types/distances/distanceRange';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {T as rT} from 'ramda';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {TimetabledPassingDateTime} from '../../../types/timetables/timetabledPassingDateTime';
import {TrainGroup} from '../../../types/trainGroups/trainGroup';

/**
 * Gets the ScheduledStopPoint of the TimetabledPassingDatetime
 * @param timetabledPassingDatetime
 * @returns {*}
 */
export const scheduledStopPointOfTimeTabledPassingDatetime = (
  timetabledPassingDatetime: TimetabledPassingDateTime,
): ScheduledStopPoint => {
  return reqStrPathThrowing(
    'timetabledPassingTime.stopPointInJourneyPattern.scheduledStopPoint',
    timetabledPassingDatetime,
  );
};

/**
 * Gets the scheduledStopPoints of the TrainGroupSingleTrainRun
 * @param trainGroup
 */
export const scheduledStopPointsOfTrainGroup = (
  trainGroup: TrainGroupSingleTrainRun,
): ScheduledStopPoint[] => {
  return map<TimetabledPassingDateTime, ScheduledStopPoint>(
    (timetabledPassingDatetimes) => {
      return scheduledStopPointOfTimeTabledPassingDatetime(timetabledPassingDatetimes);
    },
    trainGroup.singleTrainRun.timetabledPassingDatetimes,
  );
};

/**
 * Calculates the distanceResolution and distanceRanges that need to be requested based on the current
 * trainGroup.distanceRange. The distanceResolution is based on how big the distanceRange is
 * The default distanceResolution is currently 100m, meaning
 * we request an SensorDataPoint every 100m. If we have a small distanceRange, we can afford to request at higher
 * resolution. For now just make every request for less than or equal 5000 meters a distance interval of 50m,
 * end every request for less than or equal to 1000 meters a distance interval of 10m. These resolutions
 * will need to be tuned over time and possibly per organization.
 * The distanceRanges are calculated to be the distanceRanges that have not already been requested at this
 * distanceResolution, as stored in alreadyRequestedDateIntervalsAtDistanceIntervals
 * @param alreadyRequestedDateIntervalsAtDistanceIntervals List of objects in the form [
 *  {distanceResolution: 100, distanceRanges: [{start: startDistance1 end: endDistance1}, {start: startDistance2 end: endDistance2}, ...]}
 *  {distanceResolution: 50, distanceRanges: [{start: startDistance1 end: endDistance1}, {start: startDistance2 end: endDistance2}, ...]}
 *  ...
 * ]
 * indicating what distanceRanges have already been requested for a certain distanceResolution in meters
 * @param trainGroup The TrainGroup that has changed distanceRange or just been created/loaded and thus
 * needs to download its SensorDataPoints. If the distanceRange is over 5000 meters, we always download at 100m for the whole TrainRun interval.
 * This is the default download that occurs when the TrainGroup is create/loaded
 * @returns {distanceResolution, distanceRanges, consolidatedDistanceRanges} where distanceResolution
 * is the distance interval we need to request (e.g. 100, 50, or 10 meters), distanceRanges are the ranges we need,
 * and consolidatedDistanceRanges are the consolidated distanceRanges of what we already have and are requesting
 * at this distanceResolution.
 */
export const userTrainRunDistanceRangeAndIntervalDifference = (
  alreadyRequestedDateIntervalsAtDistanceIntervals: SensorDataTrainGroup[],
  trainGroup: TrainGroup,
): TrainRunDistanceRangeAndIntervalDifference => {
  // We want the distanceRange of the underlying trainRunOrGroup.singleTrainRun.trainRun here,
  // not the trainRunOrGroup.trainRouteOrGroup
  const distanceRange =
    trainGroup.singleTrainRun!.trainRoute.trainDistanceInterval.distanceRange;
  const distanceResolution = distanceResolutionOfDistanceRange(distanceRange);

  // Find the difference between what is already loaded and the distanceRange and distanceResolution
  // First find the existing item with the sough distanceResolution if it exists
  const trainRunLoadedSensorDataPointDataAtDistanceInterval = find(
    propEq(distanceResolution, 'distanceResolution'),
    alreadyRequestedDateIntervalsAtDistanceIntervals,
  );

  // Take its distanceRanges
  const alreadyLoadedOrderedDistanceRanges: DistanceRange[] = propOr(
    [],
    'distanceRanges',
    trainRunLoadedSensorDataPointDataAtDistanceInterval,
  );
  // Get the difference of the given distanceRange with those already existing so we know what ranges we need
  const differenceRanges = rangeDifferenceWithOrderedRanges(
    alreadyLoadedOrderedDistanceRanges,
    distanceRange,
  );
  return {
    distanceResolution,
    // Indicates the distanceRanges that we want to query for
    distanceRanges: differenceRanges,
    // Combine the existing with the new so we can store what has been loaded after we query
    consolidatedDistanceRanges: consolidateIntervals<DistanceRange>(
      alreadyLoadedOrderedDistanceRanges,
      differenceRanges,
    ),
  };
};

/**
 * Sorts TrainGroups based on the singleTrainRun departureDatetime and arrivalDatetime
 * @param trainGroups
 */
export const sortTrainGroups = <T extends TrainGroup>(trainGroups: T[]): TrainGroup[] => {
  if (!length(trainGroups)) {
    return trainGroups;
  }
  const cemitTypename: CemitTypename = trainGroups[0].__typename;
  return cond([
    [
      always(equals(cemitTypename, CemitTypename.trainGroup)),
      (trainGroups: T[]) => {
        // Sort by earliest TrainRuns' departureDatetime or if any are equal by arrivalDatetime
        return sortWith([
          ascend((trainGroup: TrainGroup) => {
            return Math.min(
              ...map(
                (trainRun: TrainRun) => trainRun.departureDatetime.getTime(),
                trainGroup.trainRuns,
              ),
            );
          }),
          ascend((trainGroup: TrainGroup) => {
            return Math.min(
              ...map(
                (trainRun: TrainRun) => trainRun.arrivalDatetime.getTime(),
                trainGroup.trainRuns,
              ),
            );
          }),
        ])(trainGroups as TrainGroup[]);
      },
    ],
    [
      always(equals(cemitTypename, CemitTypename.trainGroupSingleTrainRun)),
      (trainGroups: T[]) => {
        // Sort by singleTrainRun departureDatetime or if any are equal by arrivalDatetime
        return sortWith([
          ascend((trainGroup: TrainGroupSingleTrainRun) => {
            return trainGroup.singleTrainRun.departureDatetime;
          }),
          ascend((trainGroup: TrainGroupSingleTrainRun) => {
            return trainGroup.singleTrainRun.arrivalDatetime;
          }),
        ])(trainGroups as TrainGroupSingleTrainRun[]);
      },
    ],
    [
      always(equals(cemitTypename, CemitTypename.trainGroupOnlyTrainFormation)),
      (trainGroups: T[]) => {
        // Sort by singleTrainRun departureDatetime or if any are equal by arrivalDatetime
        return sortWith([
          ascend((trainGroup: TrainGroupOnlyTrainFormation) => {
            return trainGroup.trainFormation.id;
          }),
        ])(trainGroups as TrainGroupOnlyTrainFormation[]);
      },
    ],
    [
      rT,
      (_: TrainGroup[]) => {
        throw new Error(`Unexpected CemitTypename: ${cemitTypename}`);
      },
    ],
  ])(trainGroups);
};
