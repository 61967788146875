import React, {useEffect} from 'react';
import {Popup} from 'react-map-gl';
import {useTheme} from '@mui/styles';

const CustomPopup = ({longitude, latitude, onClose, children, style}) => {
  const theme = useTheme();

  // const popupRef = useRef<mapboxgl.Popup>();

  useEffect(() => {
    const popupContentElements = document.querySelectorAll('.mapboxgl-popup-content');
    popupContentElements.forEach((el) => {
      el.style.setProperty('width', '260px', 'important');
      el.style.setProperty('height', 'auto', 'important');
      el.style.setProperty(
        'background-color',
        theme.palette.background.surfaceTertiary,
        'important',
      );
      el.style.setProperty('box-shadow', 'none', 'important');
    });

    const popupTip = document.querySelector('.mapboxgl-popup-tip');
    if (popupTip) {
      popupTip.style.borderTopColor = theme.palette.background.surfaceTertiary;
    }

    const closeButton = document.querySelector('.mapboxgl-popup-close-button');
    if (closeButton) {
      closeButton.style.color = theme.palette.text.secondary;
    }
    return () => {
      onClose();
    };
  }, []);

  const defaultPopupStyle = {
    width: '260px',
    height: 'auto',
    padding: '0px',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    ...style,
  };

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      anchor="bottom"
      onClose={onClose}
      closeOnClick={false}
      closeOnMove={false}
      style={defaultPopupStyle}
    >
      {children}
    </Popup>
  );
};

export default CustomPopup;
