import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/styles';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {data} from 'pages/trafficsim/config/fakeJsonData.ts';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import CustomDateTimePicker from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomDateTimePicker.tsx';
import {
  fetchRemoteRestrictionHistory,
  searchRemoteRestrictionHistory,
} from 'pages/trafficsim/api/net/repo.ts';
import {restrictionTypes} from 'pages/trafficsim/api/data/consts.ts';
import AvstangtSpar from 'assets/dataUrlIcons/trafficSimAssets/icons/AvstangtSpar.svg';
import Backforbud from 'assets/dataUrlIcons/trafficSimAssets/icons/Backforbud.svg';
import Hastighetsbegraansning from 'assets/dataUrlIcons/trafficSimAssets/icons/Hastighetsbegraansning.svg';
import AnnanBegransning from 'assets/dataUrlIcons/trafficSimAssets/icons/AnnanBegransning.svg';
import {CircularProgress} from '@mui/material';

const History = () => {
  const theme = useTheme();
  const [filter, setFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [historyData, setHistoryData] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRemoteRestrictionHistory().then((response) => {
      setIsLoading(false);
      if (response !== null) {
        // console.log(JSON.stringify(response));
        setHistoryData(response);
        return;
      }
      setHistoryData([]);
    });
  }, []);

  const icons = {
    closedTrack: AvstangtSpar,
    backProhibition: Backforbud,
    speedLimit: Hastighetsbegraansning,
    other: AnnanBegransning,
  };

  const getIcon = (begransning) => {
    const IconSrc = icons[begransning];
    return IconSrc ? (
      <img src={IconSrc} alt={begransning} style={{marginRight: '8px'}} />
    ) : null;
  };

  const onSearch = (payload) => {
    setIsLoading(true);
    searchRemoteRestrictionHistory(payload).then((response) => {
      setIsLoading(false);
      if (response !== null) {
        // console.log(JSON.stringify(response));
        setHistoryData(response);
        return;
      }
      setHistoryData([]);
    });
  };

  const onRefresh = (payload) => {
    setIsLoading(true);
    fetchRemoteRestrictionHistory().then((response) => {
      setIsLoading(false);
      if (response !== null) {
        setHistoryData(response);
        return;
      }
      setHistoryData([]);
    });
  };

  const Row = ({eventType, row}) => {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow
          sx={{
            'td, th': { color: theme.palette.text.primary} ,
            '& > *': {
              borderBottom: (theme) =>
                open ? 'unset' : `2px solid ${theme.palette.border.greyLight}`,
            },
          }}
        >
          <TableCell
            align="left"
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 0,
              height: '100%',
            }}
          >
            {getIcon(row.selectedRestrictionType)}
            {restrictionTypes[row.selectedRestrictionType]}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.slutPlat.name} ${row.statPlas.name}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.berordaVaxlar}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.hastighet === '' ? '-' : row.hastighet}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.orsakTillBegransning}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {eventType}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.restrictionStartDay} ${row.restrictionStartDayTime}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.restrictionEndDay} ${row.restrictionEndDayTime}`}
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{paddingBottom: 0, paddingTop: 0}}
            sx={{
              textAlign: 'left',
              borderBottom: open
                ? `2px solid ${theme.palette.border.greyLight}`
                : 'unset',
            }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{margin: 1, color: theme.palette.text.primary}}>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Notering:
                  </Box>
                  {row.notering}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Begränsning på begäran av:
                  </Box>
                  {row.authorizedBy}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Begränsning inlagd av:
                  </Box>
                  {row.createdBy}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = data.filter((item) => {
    const itemStartDate = new Date(item.starttid);
    const itemEndDate = new Date(item.sluttid);
    const filterStartDate = startDate ? new Date(startDate) : new Date('1970-01-01');
    const filterEndDate = endDate ? new Date(endDate) : new Date('2999-12-31');

    return (
      (filter === '' || item.begransning === filter) &&
      itemStartDate >= filterStartDate &&
      itemEndDate <= filterEndDate
    );
  });

  const filterDropdown = (
    <FormControl
      sx={{m: 0, minWidth: 260, marginBottom: 1, fontSize: '12px'}}
      size="small"
    >
      <InputLabel id="filter-label" sx={{fontSize: '14px'}}>
        Filter
      </InputLabel>
      <Select
        style={{width: '200px'}}
        labelId="filter-label"
        id="filter-select"
        value={filter}
        label="Filter"
        onChange={handleFilterChange}
        sx={{fontSize: '12px'}}
      >
        <MenuItem value="" sx={{fontSize: '12px'}}>
          Alla
        </MenuItem>
        <MenuItem value="Avstängt spår" sx={{fontSize: '12px'}}>
          Avstängt spår
        </MenuItem>
        <MenuItem value="Backförbud" sx={{fontSize: '12px'}}>
          Backförbud
        </MenuItem>
        <MenuItem value="Hastighetsbegränsning" sx={{fontSize: '12px'}}>
          Hastighetsbegränsning
        </MenuItem>
        <MenuItem value="Annan begränsning" sx={{fontSize: '12px'}}>
          Annan begränsning
        </MenuItem>
      </Select>
    </FormControl>
  );

  const datePickers = (
    <div>
      <CustomDateTimePicker label="" onSearch={onSearch} onRefresh={onRefresh} />
    </div>
  );

  const expandedLayout = (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650 }} size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Begränsning</TableCell>
            <TableCell>Plats</TableCell>
            <TableCell>Berörda</TableCell>
            <TableCell>Hastighet</TableCell>
            <TableCell>Orsak</TableCell>
            <TableCell>Åtgärdstyp</TableCell>
            <TableCell>Starttid</TableCell>
            <TableCell>Sluttid</TableCell>
            <TableCell>Detaljer</TableCell>
          </TableRow>
        </TableHead>
        {historyData && (
          <TableBody>
            {historyData.map(
              (row) =>
                row.data && (
                  <Row key={row.id} eventType={row.event_type} row={row.data} />
                ),
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
  const filtersContainer = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        justifyContent: 'space-between',
      }}
    >
      {/*{filterDropdown}*/}
      {<div></div>}
      {datePickers}
    </div>
  );

  return (
    <div>
      {filtersContainer} {isLoading ? <CircularProgress /> : expandedLayout}
    </div>
  );
};

export default History;
