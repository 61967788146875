import {WhatIsLoading} from 'types/dependencies/whatIsLoading';
import {useCallback, useEffect, useState} from 'react';
import {AppSettings} from 'config/appConfigs/appSettings.ts';

/**
 * Stores a Record of WhatIsLoading, so it's easy to debug what dependencies are stuck in a loading state with
 * The setter can be used to add any Dependency/Container's WhatIsLoading object
 * Objects can be viewed in the debugger using  Window.whatDependenciesAreLoading
 */
export const useStateWhatDependenciesAreLoading = () => {
  const [whatDependenciesAreLoading, setWhatDependenciesAreLoading] = useState<
    WhatIsLoading[]
  >([]);

  useEffect(() => {
    // Shortcut to look at the state of all Dependencies in the console
    // @ts-ignore
    Window.whatDependenciesAreLoading = whatDependenciesAreLoading;
  }, [whatDependenciesAreLoading]);

  useEffect(() => {
    // Shortcut to look at the appSettings in the console
    // @ts-ignore
    Window.appSettings = AppSettings;
  }, []);
  return [whatDependenciesAreLoading, setWhatDependenciesAreLoading];
};
