import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {durationText} from 'utils/datetime/timeUtils.ts';

import {PeriodEnum} from 'types/alerts/periodEnum.ts';

/**
 * The DateIntervalDescriptions configuration
 * @param appProps
 */
export const configuredDateIntervalDescriptions = (
  appProps: TrainAppProps,
): DateIntervalDescription[] => {
  return clsOrTypes<DateIntervalDescription>(CemitTypename.alertIntervalDescription, [
    {
      sourceKey: 'long',
      label: appProps.t('long'),
      durationText: durationText(24 * 60),
      duration: 24 * 60,
    }, // Full day, week, month
    {
      sourceKey: 'normal',
      label: appProps.t('normal'),
      durationText: durationText(60),
      duration: 60,
    }, // Normal hour week, month
    {
      sourceKey: 'short',
      label: appProps.t('short'),
      // 15 minutes leading up to selected time
      durationText: durationText(15),
      duration: 15,
      allowsPeriods: [PeriodEnum.today],
    },
  ]);
};
