import React from 'react';
import {AppBar, Box, Drawer, IconButton, MenuItem, Popover, Toolbar} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {ifElse} from 'ramda';
import {falsyToUndefined} from 'utils/functional/functionalUtils.ts';
import TrafficSimMenus, {
  TrafficSimPageProps,
} from 'pages/trafficsim/trafficSimComponents/TrafficSimMenus.tsx';
import {OrganizationTrafficSimAppDependencyProps} from 'types/propTypes/trafficSimPropTypes/organizationTrafficSimAppDependencyProps.ts';
import {TrafficSimPageConfig} from 'types/trafficsim/trafficSimConfig';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {useTheme} from '@mui/styles';

const TrafficSimNavigationBar = ({
  appProps,
  organizationProps,
}: OrganizationTrafficSimAppDependencyProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const toggleMobileMenu = () => {
    appProps.setMobileMenuOpen(!appProps.mobileMenuOpen);
  };
  const theme = useTheme();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageClick = (page: TrafficSimPageConfig, event: Event): void => {
    if (appProps.isMobile) {
      const isPageCurrentlyExpanded = Boolean(appProps.expandedPages[page.name]);
      appProps.setSelectedPage(isPageCurrentlyExpanded ? null : page);
      appProps.setIsSubpageMenuOpen(!isPageCurrentlyExpanded);
      appProps.setExpandedPages({
        ...appProps.expandedPages,
        [page.name]: !isPageCurrentlyExpanded,
      });
    } else {
      if (appProps.selectedPage === page) {
        appProps.setSelectedPage(null);
      } else {
        setAnchorEl(event.currentTarget);
        appProps.setSelectedPage(page);
      }
    }
  };
  const handleSubpageSelect = (page: TrafficSimPageConfig, subpage: string): void => {
    // Set the subpage
    appProps.setActiveSubPage(subpage);
    appProps.setIsSubpageMenuOpen(false);
    // Remove the page from the expanded pages
    appProps.setExpandedPages({
      ...appProps.expandedPages,
      [page.name]: false,
    });
    appProps.setDrawerOpen(true);
    appProps.setMobileMenuOpen(false);
    handleClose();
  };

  const componentProps: TrafficSimPageProps = ts<TrafficSimPageProps>({
    handlePageClick,
    handleSubpageSelect,
  });

  const menus = ifElse(
    Boolean,
    () => {
      return (
        <IconButton
          key="iconButton"
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            onClick: toggleMobileMenu,
          }}
        >
          <MenuIcon />
        </IconButton>
      );
    },
    () => {
      return [
        <div
          key="divForPopover"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TrafficSimMenus
            key="trafficSimMenus"
            {...{
              appProps,
              organizationProps,
              componentProps,
            }}
          />
        </div>,
        <Popover
          key="popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {appProps?.selectedPage?.subpages?.map((subpage: string) => (
            <MenuItem
              key={subpage}
              sx={{
                backgroundColor: theme.palette.background.navigation,
                color:
                  appProps.activeSubPage === subpage
                    ? theme.palette.text.link
                    : theme.palette.text.tertiary,
              }}
              onClick={() => handleSubpageSelect(appProps.selectedPage, subpage)}
            >
              {appProps.t(subpage)}
            </MenuItem>
          ))}
        </Popover>,
      ];
    },
  )(appProps.isMobile);

  const maybeMobileMenu: Perhaps<JSX.Element> = falsyToUndefined(appProps.isMobile) && (
    <Drawer
      {...{
        anchor: 'left',
        open: appProps.mobileMenuOpen,
        onClose: () => {
          appProps.setMobileMenuOpen(false);
        },
      }}
    >
      <Box
        sx={{
          width: 260,
        }}
        role="presentation"
      >
        <TrafficSimMenus
          key="trafficSimMenus"
          {...{
            appProps,
            organizationProps,
            componentProps,
          }}
        />
      </Box>
    </Drawer>
  );

  return (
    <AppBar
      {...{position: 'static', sx: {background: theme.palette.background.navigation}}}
    >
      <Toolbar
        {...{
          sx: {
            minHeight: '64px',
            display: 'flex',
            justifyContent: 'start',
            paddingLeft: 0,
            m: 0,
          },
        }}
      >
        {menus}
      </Toolbar>
      {maybeMobileMenu}
    </AppBar>
  );
};

export default TrafficSimNavigationBar;
