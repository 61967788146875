// Default tolerances
// TODO define styles in MUI theme
import {cemitColors} from 'theme/cemitColors.ts';
import {TFunction} from 'i18next';
import {DataThresholdConfig} from '../../../types/dataVisualizations/dataThreshold';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {memoizeWith} from 'ramda';
import {clsOrTypes} from '../../../appUtils/typeUtils/clsOrTypes.ts';

/**
 * Data threshold config
 * @param t Translation function for the human-readable label
 * @returns
 * @constructor
 */
export const dataThresholdConfigs = memoizeWith(
  // Memoize by whether t is defined
  (t: TFunction) => Boolean(t).toString(),
  (t: TFunction): DataThresholdConfig[] => {
    // The colors here are used for the part of the icon that is independent of the TrainGroup.activity.isActiveColor
    return clsOrTypes<DataThresholdConfig>(CemitTypename.dataThresholdConfig, [
      {sourceKey: 'good', label: t('good'), style: {color: cemitColors.green}},
      {sourceKey: 'warning', label: t('warning'), style: {color: cemitColors.orange}},
      {sourceKey: 'error', label: t('error'), style: {color: cemitColors.reallyRed}},
    ]);
  },
);

export const MAP_PITCH = 50;
export const TRAIN_MAP_PITCH = MAP_PITCH;

// Multiply extrusion columns on the map by this factor to make them more visible
export const EXTRUSION_HEIGHT_FACTOR = 200;
