import {CemitApiLoadingStatus} from '../../../types/apis/cemitApiLoadingStatus.ts';
import {mergeRight} from 'ramda';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';

/**
 * Simple merge of existing CemitApiLoadingStatus with an incoming one
 * @param existing
 * @param incoming
 */
export const mergeLoadingStatus = (
  existing: CemitApiLoadingStatus,
  incoming: CemitApiLoadingStatus,
) => {
  return mergeRightIfDefined(existing, incoming);
};
