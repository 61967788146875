import React, {FC, ReactNode, useMemo} from 'react';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {useCustomLocalStorageDefaulted} from 'utils/hooks/useCustomLocalStorage.ts';
import {
  ThemeContextInterface,
  ThemeContextInterfaceWithToggle,
} from 'types/themes/themeContextInterface';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {ThemeEnum, themeLookup} from 'theme/lightDarkTheme.ts';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {is, when} from 'ramda';

/**
 * Wraps all Cemit Apps, storing the chosen theme
 */
const CemitDependency: FC<{
  children: ReactNode;
}> = ({children}) => {
  const [themeContextValue, setThemeContextValue] =
    useCustomLocalStorageDefaulted<ThemeContextInterface>(
      {
        serializer: (themeContextInterface: ThemeContextInterface) => {
          return themeContextInterface.themeName;
        },
        rehydrate: (themeNameOrContext: ThemeContextInterface | string) => {
          return when(is(String), (themeNameOrContext: string) => {
            const theme = themeLookup[themeNameOrContext as string];
            return clsOrType<ThemeContextInterface>(CemitTypename.themeContextInterface, {
              themeName: themeNameOrContext as string,
              theme,
            });
          })(themeNameOrContext);
        },
      },
      AppSettings.localStorage.theme,
      clsOrType<ThemeContextInterface>(CemitTypename.themeContextInterface, {
        themeName: ThemeEnum.dark,
        theme: themeLookup[ThemeEnum.dark],
      }),
    );

  function toggleTheme() {
    setThemeContextValue((prevTheme: ThemeContextInterface): ThemeContextInterface => {
      const [themeName, theme] =
        prevTheme.themeName === ThemeEnum.light
          ? [ThemeEnum.dark, themeLookup[ThemeEnum.dark]]
          : [ThemeEnum.light, themeLookup[ThemeEnum.light]];
      return clsOrType<ThemeContextInterface>(CemitTypename.themeContextInterface, {
        themeName,
        theme,
      });
    });
  }

  const themeContextWithToggle = useMemo(() => {
    return clsOrType<ThemeContextInterfaceWithToggle>(
      CemitTypename.themeContextInterfaceWithToggle,
      {
        ...themeContextValue,
        toggleTheme,
      },
    );
  }, [themeContextValue]);

  // Provide the CemitThemeContext so all applications can access and change the current theme
  return (
    <CemitThemeContext.Provider {...{value: themeContextWithToggle}}>
      {children}
    </CemitThemeContext.Provider>
  );
};

export default CemitDependency;
