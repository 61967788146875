import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps';
import {useTheme} from '@mui/styles';
import {alpha, Box, IconButton, Tooltip} from '@mui/material';
import {Sensors} from '@mui/icons-material';
import React from 'react';
import {AlertOptionsMenuProps} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertOptionsMenu.tsx';
import {not} from 'ramda';

/**
 * A button for toggling Alert realtime data
 * @param appProps
 * @param componentProps
 * @constructor
 */
export const AlertRealtimeToggleButton = ({
  appProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<AlertOptionsMenuProps>) => {
  const theme = useTheme();
  return (
    <Tooltip
      {...{
        arrow: true,
        title: appProps.t(
          appProps.realtimeIsActive ? 'hideTrainRealtime' : 'showTrainRealtime',
        ),
      }}
    >
      <Box
        {...{
          spacing: 1,
          sx: {justifyContent: 'left', alignItems: 'start'},
        }}
      >
        <IconButton
          key="button"
          {...{
            size: 'large',
            sx: {
              m: 0,
              p: 0,
              borderRadius: 0,
              color: alpha(
                theme.palette.primary.main,
                appProps.realtimeIsActive ? 1 : 0.5,
              ),
            },
            onClick: () => {
              // Toggle the boolean
              appProps.setRealtimeIsActive(not);
            },
            id: 'trainGroupOptionsMenu-button',
          }}
        >
          <Sensors {...{sx: componentProps?.iconSx}} />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
