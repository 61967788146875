import {includes} from 'ramda';
import {FrontendView} from '../../config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';

/***
 * Checks appProps.activeViews to see if a view is active
 * @param appProps
 * @param view
 */
export const isViewActive = (appProps: TrainAppProps, view: FrontendView): boolean => {
  return includes(view, appProps.activeViews || []);
};
