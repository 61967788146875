import {colorHue} from 'theme/colors/colorHue/colorHue.ts';

export const FunctionalColors = {
  dark: {
    error: {
      main: colorHue.red[400],
      light: colorHue.red[300],
      dark: colorHue.red[700],
      hover: colorHue.red[500],
      contrastText: colorHue.grey[100],
    },
    warning: {
      main: colorHue.gold[400],
      light: colorHue.gold[300],
      dark: colorHue.gold[700],
      contrastText: colorHue.grey[900],
    },
    info: {
      main: colorHue.blue[400],
      light: colorHue.blue[300],
      dark: colorHue.blue[700],
      contrastText: colorHue.grey[50],
    },
    success: {
      main: colorHue.green[600],
      light: colorHue.green[300],
      dark: colorHue.green[700],
      contrastText: colorHue.grey[900],
    },
    warningOrange: {
      main: colorHue.orange[400],
      light: colorHue.orange[300],
      dark: colorHue.orange[700],
      contrastText: colorHue.grey[900],
    },
  },
  light: {
    error: {
      main: colorHue.red[500],
      light: colorHue.red[400],
      dark: colorHue.red[700],
      hover: colorHue.red[700],
      contrastText: colorHue.grey[100],
    },
    warning: {
      main: colorHue.gold[600],
      light: colorHue.gold[500],
      dark: colorHue.gold[700],
      contrastText: colorHue.grey[900],
    },
    info: {
      main: colorHue.blue[500],
      light: colorHue.blue[400],
      dark: colorHue.blue[700],
      contrastText: colorHue.grey[50],
    },
    success: {
      main: colorHue.green[600],
      light: colorHue.green[500],
      dark: colorHue.green[700],
      contrastText: colorHue.grey[900],
    },
    warningOrange: {
      main: colorHue.orange[500],
      light: colorHue.orange[400],
      dark: colorHue.orange[700],
      contrastText: colorHue.grey[900],
    },
  },
};
