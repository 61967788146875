import {
  addIndex,
  chain,
  compose,
  equals,
  head,
  identity,
  indexOf,
  last,
  length,
  lensIndex,
  lensPath,
  map,
  min,
  over,
  pathOr,
  prop,
  reverse,
  slice,
  sortBy,
  uniqBy,
  view,
  zip,
  zipWith,
} from 'ramda';
import {Feature, FeatureCollection, LineString, MultiLineString, Position} from 'geojson';
import {UnaryOneFromMany} from 'types/unaryOneFromMany';
import {ScalarOrList} from 'types/typeHelpers/listHelpers';
import {
  compact,
  extremes,
  onlyOneValueOrThrow,
  toArrayIfNot,
} from '../functional/functionalUtils.ts';
import {Coord, lineString, point} from '@turf/helpers';
import lineSlice from '@turf/line-slice';
import {getCoord} from '@turf/invariant';
import turfLength from '@turf/length';
import bearing from '@turf/bearing';
import rhumbBearing from '@turf/rhumb-bearing';
import distance from '@turf/distance';

/**
 * Get the float property of the feature of features at the given index
 * @param prop The property in features[*].properties[props]
 * @param features The features
 * @param index The index of the item in features
 * @returns the property attribute number with the given precision
 */
export const getFeaturePropertyAtIndex = ({
  precision = 1,
  prop,
  features,
  index,
}: {
  precision?: number;
  prop: string;
  features: Feature[];
  index: number;
}) => {
  return compose<[Feature[]], Feature, string, number>(
    (float: number): string => float.toFixed(precision),
    (floatStr: string | number): number => parseFloat(floatStr.toString()),
    (feature: Feature): string | number =>
      pathOr<string | number>(0, ['properties', prop], feature),
    // @ts-ignore Don't understand the error
    (features: Feature[]): number => {
      return view<Feature[], number>(
        // If the index is greater than the feature length, set it to the the last feature
        // @ts-ignore Don't understand the error
        lensIndex<Feature[], number>(min(length(features) - 1, index)),
        features,
      );
    },
  )(features);
};

/***
 * Puts the given geojson feature or features in a FeatureCollection
 * @param features
 * @return {{features: *[], type: string}}
 */
export const asFeatureCollection = (
  features: ScalarOrList<Feature>,
): FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: toArrayIfNot(features),
  } as FeatureCollection;
};

/**
 * Combines the unique Features by id of the given FeatureCollections into a single FeatureCOllection
 * @param featureCollections
 */
export const combineFeatureCollections = (
  featureCollections: FeatureCollection[],
): FeatureCollection => {
  const uniqueFeatures: Feature[] = compose(
    uniqBy(prop('id')),
    (featureCollections: FeatureCollection[]) => {
      return chain(prop('features'), featureCollections);
    },
  )(featureCollections);

  return {
    type: 'FeatureCollection',
    features: uniqueFeatures,
  } as FeatureCollection;
};

/**
 * Takes the two extreme coordinate pairs from the lineSegment
 * @param lineSegmentFeature
 * @returns the two pairs
 */
export const lineSegmentExtremeCoordinates = (
  lineSegmentFeature: Feature<LineString>,
): [Position, Position] => {
  return map<UnaryOneFromMany<Position>, Position>(
    (f: (coords: Position[]) => Position): Position => {
      return f(lineSegmentFeature.geometry.coordinates);
    },
    [head, last],
  ) as [Position, Position];
};

/**
 * Converts a LineString or MultiLineString feature to a Features with two-point line segments
 * @param trackAsLineString
 */
export const multiLineStringOrLineStringToLineSegments = (
  trackAsLineString: Feature<LineString | MultiLineString>,
) => {
  const func =
    trackAsLineString.geometry.attribute === 'MultiLineString'
      ? chain<Position, Position>
      : map<Position, Position>;
  const pointCoords: Position[] = func(
    identity<Position>,
    trackAsLineString.geometry.coordinates,
  );
  return map(
    (points: Position[]) => lineString(points),
    zip(slice(0, -1, pointCoords), slice(1, Infinity, pointCoords)),
  );
};

/**
 * Use turfjs lineSlice and then reverse the sliced line to be oriented from startPt to endPt
 * @param startPt
 * @param stopPt
 * @param line
 */
export const lineSliceWithCorrectOrientation = (
  startPt: Coord,
  stopPt: Coord,
  line: Feature<LineString> | LineString,
): Feature<LineString> => {
  const slicedLine: Feature<LineString> = lineSlice(startPt, stopPt, line);
  // Reverse if the slicedLine starts with the stopPt
  const transforms = [
    // Orientation is already correct
    identity,
    // Reverse
    (slicedLine: Feature<LineString>): Feature<LineString> => {
      const reversedLine = over(
        lensPath(['geometry', 'coordinates']),
        (positions: Position[]): Position[] => {
          const reversed = reverse(positions);
          return reversed;
        },
        slicedLine,
      );
      return reversedLine;
    },
  ];
  const startStop: Coord[] = [startPt, stopPt];
  const startOfLinePoint = point(head(slicedLine.geometry.coordinates));
  const winningIndex = compose(
    ({point}: {point: Coord}) => indexOf<Coord>(point, startStop),
    head,
    // Sort by the closest
    sortBy<number>(prop('distance')),
    // Get the distance from the start and end
    map((startOrEnd: Coord): number => {
      return {point: startOrEnd, distance: distance(startOfLinePoint, startOrEnd)};
    }),
  )([startPt, stopPt]);

  const orientedSlicedLine: Feature<LineString> = transforms[winningIndex](slicedLine);
  //
  // const pointBearing = rhumbBearing(...startStop);
  // const lineBearing = rhumbBearing(
  //   ...map((coord) => point(coord), extremes(orientedSlicedLine.geometry.coordinates)),
  // );
  return orientedSlicedLine;
};
