import {
  idsInclude,
  includesAny,
  toArrayIfNot,
} from '../../../utils/functional/functionalUtils.ts';
import {
  compose,
  concat,
  equals,
  identity,
  intersection,
  join,
  length,
  lensPath,
  map,
  reduce,
  sortBy,
  without,
} from 'ramda';
import {Identified} from '../../../types/identified';
import {Activatable} from '../../../types/userState/activity';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {FrontendView} from '../../../config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {CrudList} from '../../../types/crud/crudList';
import {Versioned} from '../../../types/versioning/versioned';
import {FrontendViewGroup} from '../../../types/frontendViews/frontendViewGroup';
import {StateSetter} from '../../../types/hookHelpers/stateSetter';
import {FrontendViewGroupSelection} from '../../../types/frontendViews/frontendViewGroupSelection';
import {ListRelationType} from '../../../types/logic/listRelationshipType.ts';
import {setClassOrType} from '../../../utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * Activates the members of list listInstancesToActivate and deactivates all others in list
 * @param list
 * @param listInstancesToActivate
 * @param activateFunction
 * @param deactivateFunction
 */
export const activateSomeAndDeactivateRest = <T extends Identified & Activatable>(
  list: T[],
  listInstancesToActivate: T[],
  activateFunction: (t: T) => T = (t: T) =>
    setClassOrType(lensPath(['activity', 'isActive']), true, t),
  deactivateFunction: (t: T) => T = (t: T) =>
    setClassOrType(lensPath(['activity', 'isActive']), false, t),
): T[] => {
  return map<T, T>((listInstance: T): T => {
    return idsInclude(listInstance, listInstancesToActivate)
      ? activateFunction(listInstance)
      : deactivateFunction(listInstance);
  }, list);
};

export const activateSomeAndDeactiveRestAndUpdate = <
  T extends Identified & Activatable & Versioned,
>(
  crudList: CrudList<T>,
  listInstancesToActivate: T[],
  activateFunction: (t: T) => T = (t: T) =>
    setClassOrType(lensPath(['activity', 'isActive']), true, t),
  deactivateFunction: (t: T) => T = (t: T) =>
    setClassOrType(lensPath(['activity', 'isActive']), false, t),
): void => {
  const updatedList = activateSomeAndDeactivateRest(
    crudList.list,
    listInstancesToActivate,
    activateFunction,
    deactivateFunction,
  );
  crudList.updateOrCreateAll(updatedList);
};

/**
 * Deactivate listInstancesToDeactivate and persist using crudList
 * @param crudList
 * @param listInstancesToDeactivate
 * @param deactivateFunction
 */
export const deactivateAndUpdate = <T extends Identified & Activatable & Versioned>(
  crudList: CrudList<T>,
  listInstancesToDeactivate: T[],
  deactivateFunction: (t: T) => T = (t: T) =>
    setClassOrType(lensPath(['activity', 'isActive']), false, t),
): void => {
  const updatedList = map<T, T>((listInstance: T): T => {
    return deactivateFunction(listInstance);
  }, listInstancesToDeactivate);
  crudList.updateOrCreateAll(updatedList);
};

/**
 * Returns true if FrontendView.detailTrainGroup or FrontendView.detailTrainFormation are active
 * @param appProps
 */
export const anyTrainGroupDetailViewActive = (appProps: TrainAppProps): boolean => {
  return includesAny(
    [FrontendView.detailTrainGroup, FrontendView.detailTrainFormation],
    appProps.activeViews || [],
  );
};

type AccumType = {
  frontendViews: FrontendView[];
  removeViews: FrontendView[];
};
/**
 *
 * Sets the frontendGroupSelections of the given frontendGroup, which deactivates or activates
 * other FrontendViews in the FrontendViewGroup according to its listRelationship type
 * @param setActiveViews The setter
 * @param frontendGroupSelections
 */
export const setActiveViewsOfFrontendViewGroup = (
  setActiveViews: StateSetter<FrontendView[]>,
  frontendGroupSelections: FrontendViewGroupSelection[],
): void => {
  const {frontendViews, removeViews}: AccumType = reduce<
    FrontendViewGroupSelection,
    AccumType
  >(
    (acc: AccumType, frontendGroupSelection: FrontendViewGroupSelection) => {
      const {frontendViewGroup, frontendViewOrViews} = frontendGroupSelection;
      if (
        equals(ListRelationType.mutuallyExclusive, frontendViewGroup.listRelationshipType)
      ) {
        const frontendViews: FrontendView[] = toArrayIfNot(frontendViewOrViews);
        if (length(frontendViews) > 1) {
          throw Error(
            `Attempt to activate more than one mutually exclusive FrontendView: ${join(', ', frontendViews)}`,
          );
        }
        const removeViews: FrontendView[] = without(
          frontendViews,
          frontendViewGroup.frontendViews,
        );
        return {
          frontendViews: concat(acc.frontendViews, frontendViews),
          removeViews: concat(acc.removeViews, removeViews),
        };
      } else {
        return acc;
      }
    },
    {frontendViews: [], removeViews: []} as AccumType,
    frontendGroupSelections,
  );
  setActiveViews((existing: FrontendView[]): FrontendView[] => {
    const addViews: FrontendView[] = without(existing, frontendViews);

    const updatedActiveViews = compose(
      // Add those noe already in existing
      concat(addViews),
      // Remove those excluded by mutual exclusion
      without(removeViews),
    )(existing);
    console.log(`Setting active views ${join(', ', updatedActiveViews)}`);
    return updatedActiveViews;
  });
};
/**
 *
 * The members of allActiveFrontendViews that are in frontendGroup.frontendViews
 * @param frontendGroup
 * @param allActiveFrontendViews
 */
export const activeFrontendViewsOfGroup = (
  frontendGroup: FrontendViewGroup,
  allActiveFrontendViews: FrontendView[],
): FrontendView[] => {
  const groupFrontendViews = frontendGroup.frontendViews;
  return intersection(groupFrontendViews, allActiveFrontendViews);
};

/**
 * Returns true if all frontendViewGroupSelection.frontendViewOrViews are in activeFrontendViews
 * @param frontendViewGroupSelection
 * @param activeFrontendViews
 */
export const isActiveFrontendViewGroupSelection = (
  frontendViewGroupSelection: FrontendViewGroupSelection,
  activeFrontendViews: FrontendView[],
): boolean => {
  const groupActiveFrontendViews = activeFrontendViewsOfGroup(
    frontendViewGroupSelection.frontendViewGroup,
    activeFrontendViews,
  );
  const groupFrontendViews: FrontendView[] = toArrayIfNot(
    frontendViewGroupSelection.frontendViewOrViews,
  );
  return equals(
    sortBy(identity, groupActiveFrontendViews),
    sortBy(identity, groupFrontendViews),
  );
};
