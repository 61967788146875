/**
 * Shows the start and end of the current TrainPage route.
 * In the future it will be possible to click the arrow to reverse the route
 * and click the start and end stations to change them
 * @param trainProps
 * @param trainProps.trainRouteGroupProps
 * @param trainProps.trainRouteGroupProps.trainRoute
 * @param componentProps.reverseFormation Function to reverse the route when the arrow is clicked
 * @param componentProps.chooseFormation Function to bring up to choose the route
 * @returns {JSX.Element}
 */
import React from 'react';
import OrganizationMenu, {OrganizationMenuProps} from './OrganizationMenu.tsx';
import {CemitAppOrganizationComponentDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationComponentDependencyProps';

/***
 * Shows a menu that allows for an admin to choose the active Orgnanization
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationChooser = ({
  organizationProps,
  componentProps,
}: CemitAppOrganizationComponentDependencyProps<OrganizationMenuProps>): JSX.Element => {
  return <OrganizationMenu {...{organizationProps, componentProps}} />;
};

export default OrganizationChooser;
