import {memo, ReactElement, useCallback, useState} from 'react';
import {
  useDeserializeTrainRouteInStorage,
  useNotLoadingSetDefaultTrainRouteOrGroup,
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainRouteHooks.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {isNil, length, lensProp, map, unless} from 'ramda';

import {useCustomLocalStorage} from 'utils/hooks/useCustomLocalStorage.ts';
import {reverseTrainRouteOrGroup} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import {TrainRoute, TrainRouteMinimized} from 'types/trainRouteGroups/trainRoute';
import {
  TrainRouteOrGroup,
  TrainRouteOrGroupMinimized,
} from 'types/trainRouteGroups/trainRouteOrGroup';
import {TrainRouteGroup} from 'types/trainRouteGroups/trainRouteGroup';
import {useNotLoadingUpdateOrCreateCemitFilterTrainRouteGroup} from '../../trainAppHooks/cemitFilterHooks/cemitFilterTrainRouteHooks.ts';
import {useUpdateTrainRoutesViewActive} from '../../trainAppHooks/trainApiHooks/activeViewHooks.ts';
import {CemitTypename} from 'types/cemitTypename.ts';

import {
  useConfiguredApiForTrainRouteGroups,
  useNotLoadingTrainRouteOrGroupImplementsDerived,
} from '../../trainAppHooks/typeHooks/trainRouteGroupHooks.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {cemitTypeObjectAsClassInstanceDowncastedObject} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {CemitFilterTrainRouteGroup} from '../../../../types/cemitFilters/cemitFilterTrainRouteGroup';
import {
  cemitFilterConfigTrainRouteGroup,
  extractTrainRouteGroupsFromCemitFilter,
} from 'appUtils/cemitFilterUtils/cemitFilterTrainRouteGroupUtils.ts';
import {createTrainRouteOrGroup} from 'classes/typeCrud/trainRouteOrGroupCrud.ts';
import {
  useNotLoadingMaybeMergeDetailedInstancesIntoList,
  useNotLoadingMaybeMergeDetailedInstancesIntoPropertyOfList,
} from 'utils/hooks/mergeHooks.ts';
import {
  useConfiguredApiForDetailTrainRoutes,
  useConfiguredApiForTrainRoutes,
} from '../../trainAppHooks/trainApiHooks/trainApiTrainRouteHooks.ts';
import {TrainRouteGroupProps} from 'types/propTypes/trainPropTypes/trainRouteOrGroupProps';
import {useCustomLocalStorageForCemitFilter} from 'utils/hooks/useCustomLocalStorageForCemitFilter.ts';
import {LocalStorageProps} from 'types/cemitFilters/localStorageProps.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {overClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * Loads/Updates TrainRoute dependencies into trainProps.trainRouteGroupProps
 * Depends directly on RailwayLine at trainProps.railwayLineProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainRouteGroupDependency = ({
  appProps,
  organizationProps,
  trainProps,
  renderChildren,
  loading,
}: Required<TrainAppTrainDependencyProps>): ReactElement<
  Required<TrainAppTrainDependencyProps>
> => {
  const parentCemitFilter = trainProps.cemitFilter;

  // All TrainRouteGroups of the organization
  const [trainRouteGroups, setTrainRouteGroups] =
    useState<Perhaps<TrainRouteGroup[]>>(undefined);
  // All TrainRoutes of the organization's ServiceLines
  const [trainRoutes, setTrainRoutes] = useState<Perhaps<TrainRoute[]>>(undefined);
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);

  // Add/Remove FrontendView.trainRoute if organizationHasServiceLines exists/don't exist
  useUpdateTrainRoutesViewActive({appProps, organizationProps, trainProps}, loading);

  // The current TrainRouteOrGroup. The current trainRoute is that with trainRoute.trainRoute match this trainRoute
  // TODO get this from the filter instead
  const [trainRouteOrGroup, setTrainRouteOrGroup] = useCustomLocalStorage<
    TrainRouteOrGroup | TrainRouteOrGroupMinimized
  >(
    // Don't try to get/set TrainRoute in local storage if there are no TrainRoutes
    !organizationHasServiceLines,
    clsOrType<LocalStorageProps<TrainRouteGroup>>(CemitTypename.localStorageProps, {
      localStorageKey: AppSettings.localStorage.trainRouteGroup,
      // Serialize just the id. We get the full TrainRoute or TrainRouteGroup after loading the TrainRoutes and Groups
      serializer: (
        trainRouteOrGroup: Perhaps<TrainRouteOrGroup | TrainRouteOrGroupMinimized>,
      ) => {
        // Limit the parameters to TrainRouteOrGroupMinimized
        const trainRouteOrGroupMinimized: TrainRouteGroup =
          overClassOrType<TrainRouteOrGroup>(
            lensProp('trainRoutes'),
            (trainRoutes: TrainRoute[]) => {
              return map((trainRoute: TrainRoute) => {
                return cemitTypeObjectAsClassInstanceDowncastedObject<
                  TrainRoute,
                  TrainRouteMinimized
                >(
                  // Cast down to CemitTypename.trainRouteMinimized
                  CemitTypename.trainRouteMinimized,
                  trainRoute,
                );
              }, trainRoutes);
            },
            trainRouteOrGroup,
          );
        const serialized = cemitTypeObjectAsClassInstanceDowncastedObject<
          TrainRouteOrGroup,
          TrainRouteOrGroupMinimized
        >(
          // Cast down to CemitTypename.trainRouteMinimized or CemitTypename.trainRouteGroupMinimized
          CemitTypename.trainRouteOrGroupMinimized,
          trainRouteOrGroupMinimized!,
        );
        return serialized;
      },
      rehydrate: (trainRouteOrGroup: Perhaps<TrainRouteOrGroupMinimized>) => {
        return unless(isNil, (trainRouteOrGroup: TrainRouteOrGroupMinimized) => {
          // Reydrate to the minimized classes
          return createTrainRouteOrGroup(trainRouteOrGroup, {
            trainRoute: CemitTypename.trainRouteMinimized,
            trainRouteGroup: CemitTypename.trainRouteGroupMinimized,
          });
        })(trainRouteOrGroup);
      },
    }),
    undefined,
  );

  useDeserializeTrainRouteInStorage({
    loading,
    trainRouteOrGroup,
    trainRoutes,
    trainRouteGroups,
    setTrainRouteOrGroup,
  });

  const [cemitFilterWithTrainRouteGroup, setCemitFilterWithTrainRouteGroup] =
    useCustomLocalStorageForCemitFilter<CemitFilterTrainRouteGroup>(
      false,
      clsOrType<LocalStorageProps>(CemitTypename.localStorageProps, {
        localStorageKey: AppSettings.localStorage.cemitFilterTrainRouteGroups,
      }),
      parentCemitFilter,
      cemitFilterConfigTrainRouteGroup,
    );

  // Tracks the modal to create a TrainRoute filter
  const {organization} = organizationProps;

  const serviceLines = trainProps.serviceLineProps.serviceLines;

  useConfiguredApiForTrainRouteGroups(
    loading,
    organization,
    trainRouteGroups,
    setTrainRouteGroups,
  );

  useConfiguredApiForTrainRoutes(
    loading,
    organization,
    serviceLines,
    trainRoutes,
    setTrainRoutes,
  );

  // Set the current TrainRouteOrGroup. Defaults to TrainRouteGroup for all TrainRoutes
  useNotLoadingSetDefaultTrainRouteOrGroup({
    loading: loading || !trainRouteGroups || !trainRoutes || !organizationHasServiceLines,
    trainRouteGroups,
    trainRoutes,
    trainRouteOrGroup,
    setTrainRouteOrGroup,
  });

  useConfiguredApiForDetailTrainRoutes(
    loading || !trainRouteOrGroup,
    organization,
    trainRouteOrGroup,
    setTrainRouteOrGroup,
  );

  // When trainRouteOrGroup?.trainRouteGroups contained more detailed types, update trainRouteGroups to them
  useNotLoadingMaybeMergeDetailedInstancesIntoList<TrainRoute>(
    loading || !trainRoutes || !trainRouteGroups,
    trainRoutes,
    trainRouteOrGroup?.trainRoutes,
    setTrainRoutes,
  );
  // When trainRouteGroups gets updated to more detailed types,
  // updates trainRouteGroups' trainRouteGroups to them
  useNotLoadingMaybeMergeDetailedInstancesIntoPropertyOfList<TrainRouteGroup, TrainRoute>(
    loading || !trainRouteGroups || !trainRoutes,
    'trainRoutes',
    trainRouteGroups,
    trainRoutes,
    setTrainRouteGroups,
  );

  // The cemitFilterWithTrainRouteGroup is configured to the only filter for the current TrainRoute
  useNotLoadingUpdateOrCreateCemitFilterTrainRouteGroup(
    // TODO trainRoute can currently be set before trainRouteGroups if it came form localStorage
    // so make sure to check both
    loading || !trainRoutes || !trainRouteGroups || !trainRouteOrGroup,
    parentCemitFilter,
    trainRouteGroups,
    trainRoutes,
    trainRouteOrGroup,
    cemitFilterWithTrainRouteGroup,
    setCemitFilterWithTrainRouteGroup,
  );

  // True if all trainRouteOrGroup trainRouteGroups implement TrainRouteDerived
  // // TODO figure out if we still need this
  // const trainRouteOrGroupImplementsDerived =
  //   true || useNotLoadingTrainRouteOrGroupImplementsDerived(loading, trainRouteOrGroup);

  const localPropsNotReadyAbsolute =
    loading ||
    !cemitFilterWithTrainRouteGroup ||
    !trainRouteOrGroup ||
    !trainRouteGroups ||
    !trainRoutes;
  //!trainRouteOrGroupImplementsDerived;

  // Combines TrainRouteGroups and TrainRoutes
  // TODO this will be removed when we represent all TrainRoutes in single TrainRoute TrainRouteGroups
  // like we do with TrainRuns in TrainGroups
  const trainRoutesOrGroups = useNotLoadingMemo(
    localPropsNotReadyAbsolute,
    (): TrainRouteOrGroup[] =>
      [
        ...(trainRouteGroups as TrainRouteGroup[]),
        ...(trainRoutes as TrainRoute[]),
      ] as TrainRouteOrGroup[],
    [trainRouteGroups, trainRoutes],
  );

  const _reverseTrainRouteOrGroup = useCallback(
    (trainRouteOrGroup: TrainRouteOrGroup) => {
      reverseTrainRouteOrGroup(
        {
          trainRoutesOrGroups: trainRoutesOrGroups,
          setTrainRouteOrGroup: setTrainRouteOrGroup,
        },
        trainRouteOrGroup,
      );
    },
    [trainRoutesOrGroups],
  );

  // TODO temp check, this should always be set to trainRouteOrGroup
  const trainRouteOrGroupFromCemitFilterLength = useNotLoadingMemo(
    loading || !trainRoutes || !trainRouteGroups,
    (cemitFilterWithTrainRouteGroup, trainRouteGroups, trainRoutes) => {
      return length(
        extractTrainRouteGroupsFromCemitFilter(cemitFilterWithTrainRouteGroup, {
          trainRouteGroups,
          trainRoutes,
        }),
      );
    },
    [cemitFilterWithTrainRouteGroup, trainRouteGroups, trainRoutes] as const,
  );
  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainRouteGroupDependency.name),
    TrainRouteGroupDependency.name,
    {
      cemitFilterWithTrainRouteGroup,
      trainRouteOrGroupFromCemitFilterLength,
      trainRouteOrGroup,
      trainRouteGroups,
      trainRoutes,
      //trainRouteOrGroupImplementsDerived,
    },
    [
      cemitFilterWithTrainRouteGroup,
      trainRouteOrGroup,
      trainRouteOrGroupFromCemitFilterLength,
      trainRouteGroups,
      trainRoutes,
      //trainRouteOrGroupImplementsDerived,
    ],
    appProps.setWhatDependenciesAreLoading,
    // If the organization has no TrainRoutes configured, then we aren't in a loading state unless the parent is
    !loading && !(organizationHasServiceLines && localPropsNotReadyAbsolute),
  );
  const trainRouteGroupProps = useMemoClsOrType<TrainRouteGroupProps>(
    CemitTypename.trainRouteGroupProps,
    {
      // Indicates the organization is configured with TrainRoutes
      whatIsLoading,
      organizationHasServiceLines,
      loadingExplanation: whatIsLoading,
      trainRouteGroups,
      setTrainRouteGroups,
      trainRoutes,
      setTrainRoutes,
      trainRoutesOrGroups,
      trainRouteOrGroup,
      setTrainRouteOrGroup,
      cemitFilterWithTrainRouteGroup,
      setCemitFilterWithTrainRouteGroup,
      chooseTrainRouteOrGroup: setTrainRouteOrGroup,
      reverseTrainRouteOrGroup: _reverseTrainRouteOrGroup,
    },
  );

  if (cemitFilterWithTrainRouteGroup?.allPass?.length > 1) {
    throw new Error('Debug: Expected <= 1 filter');
  }
  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainRouteGroupProps',
    trainRouteGroupProps,
    cemitFilterWithTrainRouteGroup,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading.loadingExplanation,
    {
      ...trainPropsMerged,
      ...trainPropsMerged.serviceLineProps,
      ...trainPropsMerged.trainRouteGroupProps,
    },
    'TrainRouteGroupDependency',
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
  });
};
TrainRouteGroupDependency.displayName = 'TrainRouteGroupDependency';
export default TrainRouteGroupDependency;
//export default memo(TrainRouteGroupDependency);
