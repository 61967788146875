import {compose, cond, lensPath, lensProp, map, prop, set, T} from 'ramda';
import {compact, strPathOr} from '@rescapes/ramda';
import TrainGroupRemoveButton from './TrainGroupRemoveButton.tsx';
import TrainGroupErrorButton from './TrainGroupErrorButton.tsx';
import {Stack, Tooltip} from '@mui/material';
import {Train} from '@mui/icons-material';
import React from 'react';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {TrainRun} from 'types/trainRuns/trainRun';
import LoaderWithText from '../../../../loading/LoaderWithText.tsx';
import {Property} from 'csstype';
import TrainGroupVisibilityButton from './TrainGroupVisibilityButton.tsx';
import {CemitTypename} from 'types/cemitTypename.ts';
import {Activity} from 'types/userState/activity';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainFormation} from 'types/trains/trainFormation';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {overClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import ColorPicker from 'components/pickers/colorPicker.tsx';
import {createTrainGroupActivityAsActive} from 'classes/typeCrud/trainGroupActivityCrud.ts';

/***
 * Returns the icons/buttons for the TrainGroupLine
 * @param trainProps
 * @param trainGroup
 * @param toggleTrainGroupVisibility
 * @param isSelectedTrainRuns
 * @param t
 * @returns {*}
 */
export const userTrainRunIcons = ({
  appProps,
  trainProps,
  trainGroup,
  toggleTrainGroupVisibility,
  isSelectedTrainRuns,
}: {
  appProps: TrainAppProps;
  trainProps: TrainProps;
  trainGroup: TrainGroup;
  toggleTrainGroupVisibility: (trainGroup: TrainGroup) => void;
  isSelectedTrainRuns: boolean;
}) => {
  const {t} = appProps;
  const loading = trainProps.trainGroupSingleTrainRunProps.loading;
  const color = trainGroup.activity.isActiveColor;
  const iconSx = {
    color,
  };
  // Show a loading icon if the cdc data is loading for active TrainGroups
  const isLoading =
    Boolean(trainGroup) &&
    (trainProps.trainGroupSingleTrainRunProps.trainGroup as TrainRun).loading;

  const loadingExplanation =
    trainGroup &&
    !(trainProps.trainRouteGroupProps.trainGroup as SensorDataTrainGroup).error
      ? {}
      : {};

  const isBaseline = strPathOr(false, 'activity.isBaseline', trainGroup);

  // TODO We currently don't have any unselected TrainGroups, so this is never used
  const activateButton = (
    <TrainGroupVisibilityButton
      key="activateButton"
      {...{
        disabled: loading,
        toggleTrainGroupVisibility,
        trainGroup,
      }}
    />
  );

  // isPreconfigured TrainRuns can't currently be removed because they automatically reload
  // If automatic reload was disabled, the user would need a way to re-enable them.
  const removeButton = trainGroup.isPreconfigured ? undefined : (
    <TrainGroupRemoveButton
      key="removeButton"
      {...{
        disabled: loading,
        isSelectedTrainRuns,
        trainGroup,
        handleRemoveTrainGroupFromFilters:
          handleRemoveTrainGroupOnlyTrainFormationFromFilters,
      }}
    />
  );

  const trainRun = trainProps.trainRouteGroupProps.trainGroup as SensorDataTrainGroup;
  // If the TrainRun is tagged with an error property, it means the data download failed
  const errorButton =
    trainRun.error && !trainRun.loading ? (
      <TrainGroupErrorButton
        key="errorButton"
        {...{
          disabled: loading,
          isSelectedTrainRuns,
          trainGroups: [trainGroup],
          crudTrainGroups: trainProps.trainGroupSingleTrainRunProps.crudTrainGroups,
        }}
      />
    ) : undefined;

  const trainIcon = (
    <Tooltip
      key="trainIconTooltip"
      arrow
      title={isBaseline ? t('baselineDescription') : t('trainGroupDescription')}
    >
      <Stack
        key="trainIcon"
        {...{
          spacing: 0.5,
          direction: 'column',
          sx: {alignItems: 'center'},
        }}
      >
        <Train {...{sx: iconSx}} />
      </Stack>
    </Tooltip>
  );

  const colorPicker = (
    <ColorPicker
      key="colorPicker"
      {...{
        color,
        handleChangeComplete: (color: Property.Color) => {
          const modifiedTrainGroup = set(
            lensPath(['activity', 'isActiveColor']),
            color,
            trainGroup,
          );
          trainProps.trainGroupSingleTrainRunProps.crudTrainGroups.updateOrCreate(
            modifiedTrainGroup,
          );
        },
      }}
    />
  );
  const loader = isLoading ? (
    <LoaderWithText
      key="loader"
      {...{text: '', spinner: true, loadingExplanation, size: 20}}
    />
  ) : undefined;

  return compact([
    loader,
    trainIcon,
    colorPicker,
    activateButton,
    removeButton,
    errorButton,
  ]);
};
/**
 * Selects and activates the TrainGroup
 * For now there is no difference between being selected and activated. If that changes, then so wil the function
 * @param trainGroups
 */
export const selectAndActivateTrainGroups = (trainGroups: TrainGroup[]) => {
  return map((trainGroup) => {
    return selectAndActivateTrainGroup(trainGroup);
  }, trainGroups);
};
/**
 * For now there is no difference between being selected and activated.
 * @param trainGroup
 */
export const selectAndActivateTrainGroup = (trainGroup: TrainGroup): TrainGroup => {
  return set(
    lensProp('activity'),
    createTrainGroupActivityAsActive(
      clsOrType<Activity>(CemitTypename.activity, {
        isActiveColor: trainGroup?.activity?.isActiveColor,
      }),
    ),
    trainGroup,
  );
};

/**
 * Deactivate and deselect the trainGroups
 * @param trainGroups
 */
export const deselectAndDeactivateTrainGroups = (trainGroups: TrainGroup[]) => {
  return map((trainGroup) => {
    return deselectAndDeActivateTrainGroup(trainGroup);
  }, trainGroups);
};
/**
 * Deactivate and deselect the TrainGroup
 * @param trainGroup
 */
export const deselectAndDeActivateTrainGroup = (trainGroup: TrainGroup): TrainGroup => {
  return overClassOrType(
    lensProp('activity'),
    (activity: Activity) => {
      return mergeRightIfDefined(
        activity || clsOrType<Activity>(CemitTypename.activity, {}),
        {
          isSelected: false,
          isActive: false,
        },
      );
    },
    trainGroup,
  );
};

export const trainGroupName = (trainGroup: TrainGroup): Perhaps<string> => {
  const trainFormation: Perhaps<TrainFormation> = trainGroup?.trainFormation;
  if (!trainFormation) {
    return undefined;
  }
  return cond([
    [
      prop('formationDetail'),
      (trainFormation: TrainFormation) => {
        return trainFormation?.formationDetail?.reportingMark;
      },
    ],
    [compose(Boolean, prop('name')), prop('name')],
    [T, () => undefined],
  ])(trainFormation);
};
