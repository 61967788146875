import {equals, is, map} from 'ramda';
import React, {SyntheticEvent, useMemo, useState} from 'react';
import {Button, Menu, MenuItem, Stack, Typography} from '@mui/material';
import {RailwayAlert} from '@mui/icons-material';
import {AlertType} from 'types/alerts/alertType';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {cemitColors} from 'theme/cemitColors.ts';
import {TFunction} from 'i18next';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';

const AlertTypeConfigPicker = ({
  t,
  alertTypeConfig,
  setAlertTypeConfig,
  alertTypeConfigs,
}: {
  t: TFunction;
  alertTypeConfig: AlertTypeConfig;
  setAlertTypeConfig: StateSetter<AlertTypeConfig>;
  alertTypeConfigs: AlertTypeConfig[];
}) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = (_e: SyntheticEvent, alertTypeConfig: AlertTypeConfig) => {
    setAnchorEl(undefined);
    // if alertType comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, alertTypeConfig)) {
      setAlertTypeConfig(alertTypeConfig);
    }
  };
  const activeAlertTypeKey = alertTypeConfig.alertTypeKey;
  const alertTypeComponents = useMemo<JSX.Element[]>(() => {
    return map((alertType: AlertType) => {
      return (
        <MenuItem
          key={alertType.label}
          {...{
            disabled: equals(activeAlertTypeKey, alertType.alertTypeKey),
            onClick: (e: SyntheticEvent) => onClose(e, alertType),
          }}
        >
          {alertType.label}
        </MenuItem>
      );
    }, alertTypeConfigs);
  }, [alertTypeConfigs, alertTypeConfig]);

  return (
    <Stack {...{sx: {alignSelf: 'self-start'}}}>
      <Typography
        {...{
          sx: {
            color: cemitColors.white,
            fontSize: '0.8rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
          },
        }}
      >
        {t('alertType')}
      </Typography>
      <Button
        key="button"
        {...{
          id: 'alertType-button',
          color: 'secondary',
          'aria-controls': open ? 'alertType-positioned-menu' : undefined,
          'aria-haspopup': 'true',
          'aria-expanded': open ? 'true' : undefined,
          onClick: handleClick,
        }}
      >
        <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center'}}}>
          <RailwayAlert key="icon" />
          <Typography key="label">{alertTypeConfig.label}</Typography>
        </Stack>
      </Button>
      <Menu
        key="menu"
        {...{
          id: 'formation-positioned-menu',
          'aria-labelledby': 'formation-button',
          anchorEl,
          open,
          onClose,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {alertTypeComponents}
      </Menu>
    </Stack>
  );
};
AlertTypeConfigPicker.displayName = 'AlertTypePicker';
export default AlertTypeConfigPicker;
