import {clsOrType} from '../typeUtils/clsOrType.ts';
import {CemitFilterConfig} from 'types/cemitFilters/cemitFilterConfig';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  cemitFilterTrainFormationRightSideExpression,
  evalCemitFilterTrainFormation,
  extractCemitFilterTrainFormations,
  isCemitFilterTrainFormation,
} from './cemitFilterTrainFormationUtils.ts';
import {memoizeWith, propEq} from 'ramda';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {
  cemitFilterDateRecurrenceRightSideExpression,
  evalCemitFilterDateRecurrence,
  extractCemitFilterDateRecurrences,
  isCemitFilterDateRecurrence,
} from './cemitFilterDateRecurrenceUtils.ts';

export const resolveCemitFilterConfig = memoizeWith(
  (cemitFilterTypename: CemitTypename) => cemitFilterTypename,
  (cemitFilterTypename: CemitTypename): CemitFilterConfig => {
    const configs = [
      clsOrType<CemitFilterConfig>(CemitTypename.cemitFilterConfig, {
        isCemitFilter: isCemitFilterTrainFormation,
        evalCemitFilter: evalCemitFilterTrainFormation,
        extractCemitFilter: extractCemitFilterTrainFormations,
        cemitFilterTypename: CemitTypename.cemitFilterTrainFormation,
        rightSideExpression: cemitFilterTrainFormationRightSideExpression,
      }),
      clsOrType<CemitFilterConfig>(CemitTypename.cemitFilterConfig, {
        isCemitFilter: isCemitFilterDateRecurrence,
        evalCemitFilter: evalCemitFilterDateRecurrence,
        extractCemitFilter: extractCemitFilterDateRecurrences,
        rightSideExpression: cemitFilterDateRecurrenceRightSideExpression,
        cemitFilterTypename: CemitTypename.cemitFilterDateRecurrence,
      }),
    ];
    return findOrThrow(propEq(cemitFilterTypename, 'cemitFilterTypename'), configs);
  },
);
