import {Identified} from '../../types/identified';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {useNotLoadingEffect, useNotLoadingMemo} from './useMemoHooks.ts';
import {
  always,
  chain,
  filter,
  indexBy,
  indexOf,
  keys,
  Lens,
  lensIndex,
  lensProp,
  map,
  over,
  prop,
  propOr,
  reduce,
  uniqBy,
  unless,
  zipWith,
} from 'ramda';
import {typenamesEqual} from '../../appUtils/typeUtils/typenameUtils.ts';
import {listsShallowEqual, shallowEquals} from '../functional/functionalUtils.ts';
import {PerhapsIfLoading} from '../../types/logic/requireIfLoaded.ts';
import {KeyOfType} from '../../types/logic/keyTypes';
import {overClassOrTypeList} from '../functional/cemitTypenameFunctionalUtils.ts';

/**
 * When not in a loading state, if incomingInstances with ids that are a subset of existingInstances ids
 * have a different __typename than the corresponding instance in existingInstances, set the corresponding
 * existingInstance to the incomingInstance.
 *
 * This is used when incomingInstances represent more detailed versions of existingInstances because they
 * have downloaded additional data or derived additional data and thus have been instantiated with a __typename
 * that reprsents a implemntor/subclass of the existingInstance's instance.
 *
 * The Optional mergeFunction merges the incoming into existing instead of replacing existing.
 *
 * TODO We could support incomingInstances that do not have a corresponding instance in existingInstances
 * @param loading
 * @param existingInstances Existing instances. Can be undefined if loading is true
 * @param incomingInstances
 * @param setter
 * @param mergeFunction Accepts an existing and incoming instance with the same id but different typenames.
 * Defaults to returning the incoming
 */
export const useNotLoadingMaybeMergeDetailedInstancesIntoList = <T extends Identified>(
  loading: boolean,
  existingInstances: PerhapsIfLoading<typeof loading, T[]>,
  incomingInstances: PerhapsIfLoading<typeof loading, T[]>,
  setter: StateSetter<Perhaps<T[]>>,
  mergeFunction: (existing: T, incoming: T) => T = (_existing: T, incoming: T) => {
    return incoming;
  },
): void | never => {
  _useNotLoadingMaybeMergeDetailedInstancesIntoList(
    loading,
    existingInstances,
    incomingInstances,
    setter,
    mergeFunction,
  );
};

/**
 * Wraps useNotLoadingMaybeMergeDetailedInstancesIntoList to work on the list of a property
 * of existingInstances items. incomingInstances are compared to all unique instances
 * of existingInstances' existingInstancesProperty value. If there are updates in incomingInstances
 * existingInstances' existingInstancesProperty are updated by calling the setter
 * @param loading
 * @param existingInstancesProperty
 * @param existingInstances
 * @param incomingInstances
 * @param setter
 * @param mergeFunction
 */
export const useNotLoadingMaybeMergeDetailedInstancesIntoPropertyOfList = <
  T extends Identified,
  PT extends Identified,
>(
  loading: boolean,
  existingInstancesProperty: KeyOfType<T, PT>,
  existingInstances: PerhapsIfLoading<typeof loading, T[]>,
  incomingInstances: PerhapsIfLoading<typeof loading, PT[]>,
  setter: StateSetter<Perhaps<T[]>>,
  mergeFunction: (existing: PT, incoming: PT) => PT = (_existing: PT, incoming: PT) => {
    return incoming;
  },
): void | never => {
  // Get unique property instances from existingInstances
  const existingInstancePropertyInstances: Perhaps<PT[]> = useNotLoadingMemo(
    loading,
    (existingInstances) => {
      return uniqBy(
        (existingInstance: PT) => {
          return existingInstance.id;
        },
        chain((existingInstance: T): PT[] => {
          return existingInstance[existingInstancesProperty] as PT[];
        }, existingInstances),
      );
    },
    [existingInstances],
  );

  // Create a setter that can inject the updated property instances into the existingInstances
  const combinedSetter = (maybeUpdatedExistingInstancePropertyIntstances: PT[]) => {
    const maybeUpdatedExistingInstancePropertyIntstancesById = indexBy(
      prop('id'),
      maybeUpdatedExistingInstancePropertyIntstances,
    );
    const maybeUpdatedExistingInstances: T[] = map<T, T>((existingInstance: T): T => {
      return over(
        lensProp(existingInstancesProperty) as Lens<T, PT[]>,
        (propertyInstances: PT[]): PT[] => {
          return map((propertyInstance: PT): PT => {
            const maybeUpdated: PT = propOr(
              propertyInstance,
              propertyInstance.id,
              maybeUpdatedExistingInstancePropertyIntstancesById,
            );
            // If the instances don't shallow equal, it means that maybeUpdated needs to be merged in
            if (maybeUpdated != propertyInstance) {
              return mergeFunction(propertyInstance, maybeUpdated);
            }
            return propertyInstance;
          }, propertyInstances);
        },
        existingInstance,
      );
    }, existingInstances as T[]);
    if (!listsShallowEqual(existingInstances, maybeUpdatedExistingInstances)) {
      setter(maybeUpdatedExistingInstances);
    }
  };
  // Compare incomingInstances to existingInstancePropertyInstances to see if any of the former
  // have different __typenames. If so, create an updated version of existingInstancePropertyInstances and
  // call combinedSetter with it
  // We must limit the incomingInstances to the existingInstances in terms of ids, since
  // _useNotLoadingMaybeMergeDetailedInstancesIntoList doesn't expect new ids
  const existingPropertyInstancesById = indexBy(
    prop('id'),
    existingInstancePropertyInstances || [],
  );
  const limitedIncomingInstances = filter((incomingInstances: PT) => {
    return incomingInstances.id in existingPropertyInstancesById;
  }, incomingInstances || []);
  _useNotLoadingMaybeMergeDetailedInstancesIntoList<PT>(
    loading,
    existingInstancePropertyInstances,
    limitedIncomingInstances,
    combinedSetter,
    mergeFunction,
  );
};

export const _useNotLoadingMaybeMergeDetailedInstancesIntoList = <T extends Identified>(
  loading: boolean,
  existingInstances: PerhapsIfLoading<typeof loading, T[]>,
  incomingInstances: PerhapsIfLoading<typeof loading, T[]>,
  setter: StateSetter<Perhaps<T[]>>,
  mergeFunction: (existing: T, incoming: T) => T = (_existing: T, incoming: T) => {
    return incoming;
  },
): void | never => {
  const existingInstancesById: Perhaps<Record<string, T>> = useNotLoadingMemo(
    loading || !existingInstances,
    (existingInstances) => {
      return indexBy(prop('id'), existingInstances as T[]);
    },
    [existingInstances],
  );
  const dependencies = [
    existingInstances,
    existingInstancesById,
    incomingInstances,
  ] as const;
  useNotLoadingEffect(
    loading || !existingInstances || !incomingInstances,
    (existingInstances, existingInstancesById, incomingInstances) => {
      const maybeUpdatedExistingInstances: T[] = reduce(
        (existingInstances: T[], incomingInstance: T): T[] => {
          const existingInstance = existingInstancesById[incomingInstance.id];
          return unless(
            always(typenamesEqual(existingInstance, incomingInstance)),
            (existingInstances: T[]) => {
              const index = indexOf(incomingInstance.id, keys(existingInstancesById));
              if (index == -1) {
                throw Error(
                  `Identified Instance of typename ${incomingInstance.__typename}, id: ${incomingInstance.id} not found in existing instances`,
                );
              }
              return overClassOrTypeList<T, number>(
                lensIndex(index),
                (existingInstance: T) => {
                  return mergeFunction(existingInstance, incomingInstance);
                },
                existingInstances,
              );
            },
          )(existingInstances) as T[];
        },
        existingInstances as T[],
        incomingInstances as T[],
      );
      // If anything changed, call the setter
      if (!listsShallowEqual(existingInstances, maybeUpdatedExistingInstances)) {
        setter(maybeUpdatedExistingInstances);
      }
    },
    dependencies,
  );
};
