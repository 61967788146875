import {useCallback, useState} from 'react';
import {parseDates} from 'utils/json/jsonUtils.ts';
import {equals, identity, is, propOr, unless} from 'ramda';
import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {LocalStorageProps} from '../../types/cemitFilters/localStorageProps.ts';
import {Cemited} from '../../types/cemited';
import {CemitTypename} from '../../types/cemitTypename.ts';

import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';

/**
 * Like useState but stores in localStorage
 * @param loading Avoids loading from local storage if true. Instead it set effect to load from local
 * storage when loading becomes false
 * @param parserArgument Keys that are dates
 * @param customParser Defaults to parseDates. Override the JSON.parse second argument function
 * Accepts parserArgument as the only argument
 * @param key
 * @param initialValue Optional initial value that will be passed to rehydrate and returned if
 * there is no value is in localStorage or if loading is true when the state is initialized
 * @param serializer Optional serializer Can return a string or object.
 * @param rehydrate Optionally processes the deserialized object, such as wrapping it in a class
 * @param rehydrate
 * @param [expiry] When the cookie expires
 * If object, JSON.stringfy will stringify it
 * @returns {(*|setValue)[]}
 */
export function useCustomLocalStorage<T extends Cemited, D = undefined>(
  loading = false,
  {
    localStorageKey,
    parserArgument = [],
    customParser = undefined,
    serializer = identity<Perhaps<T>>,
    rehydrate = identity,
    expiry = undefined,
  }: LocalStorageProps<T>,
  initialValue?: D,
): [
  D extends undefined ? Perhaps<T> : T,
  StateSetter<D extends undefined ? Perhaps<T> : T>,
] {
  const parser = customParser || parseDates;
  const initFromLocalStorage = (): T | undefined => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(localStorageKey);
      // Parse stored json or if none return initialValue
      let parsed;
      try {
        const parsedItem = item ? JSON.parse(item, parser(parserArgument)) : initialValue;
        if (propOr(false, 'expiry', parsedItem)) {
          const now = new Date();
          // compare the expiry time of the item with the current time
          if (now.getTime() > parsedItem.expiry) {
            // If the item is expired, delete the item from storage
            // and return initialValue
            localStorage.removeItem(localStorageKey);
            parsed = initialValue;
          } else {
            parsed = parsedItem.value;
          }
        } else {
          parsed = parsedItem;
        }
      } catch {
        // Not json, just use the item directly
        parsed = item;
      }

      if (!parsed) {
        return rehydrate(initialValue);
      }
      return rehydrate(parsed);
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return undefined;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    if (loading || typeof window === 'undefined') {
      return rehydrate(initialValue);
    }
    return initFromLocalStorage() as T;
  });

  // If loading was initially true, call setStoredValue when loading becomes false
  useNotLoadingEffect(loading, () => {
    if (!storedValue || equals(rehydrate(initialValue), storedValue)) {
      setStoredValue(initFromLocalStorage());
    }
  }, []);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Serialize to object or string
        const serialized = serializer(valueToStore);
        // Save to local storage
        if (typeof window !== 'undefined') {
          const item = expiry
            ? {
                value: serialized,
                expiry,
              }
            : serialized;
          const stringified = unless(is(String), (item) => JSON.stringify(item))(item);
          window.localStorage.setItem(localStorageKey, stringified);
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [storedValue],
  );
  return [storedValue, setValue];
}

/**
 * Same as useCustomLocalStorage but with typing for when a default is present
 * @param loading
 * @param parserArgument
 * @param customParser
 * @param serializer
 * @param rehydrate
 * @param expiry
 * @param key
 * @param initialValue
 */
export function useCustomLocalStorageDefaulted<
  T extends Cemited | Record<string, Cemited> | string | string[] | Cemited[],
>(
  {
    loading = false,
    parserArgument = [],
    customParser = undefined,
    serializer = identity<NonNullable<T>>,
    rehydrate = identity,
    expiry = undefined,
  }: {
    loading?: boolean;
    parserArgument?: string[];
    customParser?: Function;
    serializer?: (value: NonNullable<T>) => NonNullable<T> | string;
    rehydrate?: (value: NonNullable<T> | string) => NonNullable<T>;
    expiry?: number;
  },
  key: string,
  initialValue: NonNullable<T>,
): [T, StateSetter<T>] {
  return useCustomLocalStorage<NonNullable<T>, NonNullable<T>>(
    loading,
    clsOrType<LocalStorageProps<T>>(CemitTypename.localStorageProps, {
      localStorageKey: key,
      parserArgument,
      customParser,
      serializer,
      rehydrate,
      expiry,
    }),
    initialValue,
  );
}
