import {any, chain, fromPairs, map, not, prop, props} from 'ramda';
import {mapboxGeojsonSource} from 'utils/map/mapboxSourceUtils.ts';
import {asFeatureCollection} from 'utils/geojson/geojsonUtils.ts';
import {CEMIT_TRAIN_RUN_LINE_DARKER, cemitColors} from 'theme/cemitColors.ts';
import {
  MAP_RAILWAY_LAYER,
  MAP_RAILWAY_SOURCE,
  MAP_TRACK_SWITCH_LAYER,
  MAP_TRACK_SWITCH_SOURCE,
} from 'config/appConfigs/cemitAppConfigs/railwayLineConfig.ts';
import {RailwayLine} from '../../../types/railways/railwayLine';
import {Track} from '../../../types/railways/track';
import {TrainProps} from '../../../types/propTypes/trainPropTypes/trainProps';
import {MapboxLayer, MapSourceVisual} from '../../../types/mapbox/mapSourceVisual';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {FeatureCollection} from 'geojson';
import {clsOrType} from '../../typeUtils/clsOrType.ts';

/**
 * Returns the tracks of the given railwayLines. We currently only expect one Track instance per RailwayLine,
 * but this could change in the future if we define track segments or similar
 * @param railwayLines
 * @return {Track[])}
 */
export const tracksOfRailways = (railwayLines: RailwayLine[]): Track[] => {
  return chain(prop('tracks'), railwayLines);
};

/**
 * Converts tracks of the current railwayLines to Mapbox geojson sources
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines
 * @returns {*}
 */
export const railwayLineMapboxSourcesAndLayersSets = ({
  railwayLines,
}: {
  railwayLines: RailwayLine[];
}): MapSourceVisual[] => {
  // Adding multiple layers so we can have different colors on each different railroad
  // sources is [{source: source key, wasAdded: true|false}, ...]
  return map((railwayLine: RailwayLine) => {
    const sourceName = `${MAP_RAILWAY_SOURCE}-${railwayLine.name}`;
    const layerId = `${MAP_RAILWAY_LAYER}-${railwayLine.name}`;
    const layer = {
      id: layerId,
      type: 'line',
      source: sourceName,
      layout: {
        // TODO move to a Mapbox style config
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          // TODO get styles from MUI theme
          cemitColors.trackGreenLighter,
          CEMIT_TRAIN_RUN_LINE_DARKER,
        ],
        'line-width': 5,
      },
    } as MapboxLayer;
    const featureCollection: FeatureCollection = asFeatureCollection(
      railwayLine.geojson.features,
    );
    if (any(not, featureCollection.features)) {
      throw Error('Got an undefined feature in a FeatureCollection');
    }
    const mapSourceInfo = mapboxGeojsonSource({
      sourceName,
      featureCollection,
    });
    // Add the source if not yet defined
    return clsOrType<MapSourceVisual>(CemitTypename.mapSourceVisual, {
      source: mapSourceInfo,
      layers: [layer],
    });
  }, railwayLines);
};

/**
 * Converts tracks of the current railwayLines to Mapbox geojson sources
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines
 * @returns {*}
 */
export const trackSwitchesMapboxSourcesAndLayersSets = ({
  trainProps,
}: {
  trainProps: TrainProps;
}) => {
  // Adding multiple layers so we can have different colors on each different railroad
  // sources is [{source: source key, wasAdded: true|false}, ...]

  return map((track: Track) => {
    const sourceName = `${MAP_TRACK_SWITCH_SOURCE}-${track.name}`;
    const layerId = `${MAP_TRACK_SWITCH_LAYER}-${track.name}`;
    const layer = {
      id: layerId,
      attribute: 'circle',
      source: sourceName,
      paint: {
        'circle-radius': 4,
        'circle-color': 'rgba(255,255,255,0.74)',
      },
      /*
        TODO svg symbol not working
        type: 'symbol',
        source: sourceName,
        layout: {
          'icon-image': 'track-switch', // reference the image
          'icon-size': 0.25
        }
         */
    };
    // Add the source if not yet defined
    return {
      source: mapboxGeojsonSource({
        sourceName,

        featureCollection: track.switchesGeojson,
      }),
      layers: [layer],
    };
  }, tracksOfRailways(trainProps.railwayLineProps.railwayLines));
};

/**
 * Returns a lookup of railway name to the Railway's ReferenceScheduledStopPoint,
 * e.g. {'drammenbanen': {name: 'Oslo sentralstasjon, ...}, ...}
 * @param railwayLines
 * @returns {string[]}
 */
export const railwayLineSourceKeyToReferenceScheduledStopPoint = (
  railwayLines: RailwayLine[],
): string[] => {
  return fromPairs(map(props(['name', 'referenceScheduledStopPoint']), railwayLines));
};
