import {alpha, createTheme} from '@mui/material';
import {darkColors, lightColors} from 'theme/colors/colors.ts';
import {typography} from 'theme/typography/typography.ts';
import {createTheme, darken, lighten} from '@mui/material/styles/index.js';
import {addIndex, fromPairs, map} from 'ramda';
import {
  CEMIT_ALERT_BLUE,
  CEMIT_ALERT_RED,
  CEMIT_ALERT_YELLOW,
  CEMIT_COMPARE_BASELINE,
  CEMIT_COMPARE_FILL_1,
  CEMIT_COMPARE_FILL_2,
  CEMIT_DARK_RED,
  CEMIT_DEFAULT_TRAIN_COLORS,
  CEMIT_GRAY,
  CEMIT_GREEN,
  CEMIT_ORANGE,
  CEMIT_TRAIN_BUTTON_BACKGROUND,
  CEMIT_TRAIN_RUN_LINE,
  CEMIT_WHITE,
  CEMIT_YELLOW,
} from './cemitColors.ts';
import {Theme} from '@mui/system';
import {components} from 'theme/components.ts';

export enum ThemeEnum {
  light = 'light',
  dark = 'dark',
}

export const lightTheme: Theme = createTheme({
  palette: {
    ...lightColors,
    mode: 'light',
  },
  typography,
  components,
});

export const darkTheme: Theme = createTheme({
  palette: {
    ...darkColors,
    mode: 'dark',
    dividerBar: {
      main: CEMIT_GRAY,
    },
    alertNoData: {
      main: alpha(CEMIT_WHITE, 0.25),
    },
    alertError: {
      main: CEMIT_ALERT_RED,
    },
    alertWarning: {
      main: CEMIT_ALERT_YELLOW,
    },
    alertNormal: {
      main: CEMIT_ALERT_BLUE,
    },
    remove: {
      main: CEMIT_DARK_RED,
    },
    error: {
      main: CEMIT_ORANGE,
    },
    activate: {
      main: CEMIT_GREEN,
    },
    visibility: {
      main: CEMIT_WHITE,
    },
    scheduleButton: {
      main: CEMIT_TRAIN_BUTTON_BACKGROUND,
    },

    trainRunLine: {
      main: CEMIT_TRAIN_RUN_LINE,
      action: {
        isOver: 'orange',
      },
    },
    trainRunLineStationDot: {
      main: CEMIT_WHITE,
      action: {
        isOver: CEMIT_YELLOW,
        isDisabled: alpha(CEMIT_WHITE, 0.5),
      },
    },

    compareButton: {
      main: CEMIT_WHITE,
      disabled: CEMIT_WHITE,
      action: {
        isOver: CEMIT_YELLOW,
        isDisabled: CEMIT_WHITE,
      },
    },

    trainDistanceIntervalBar: {
      main: CEMIT_TRAIN_RUN_LINE,
      dragging: 'orange',
      action: {
        drag: '#35E9E9',
        dragOpacity: 0.2,
      },
    },
    // TODO I can't figure how to define custom color variants
    trainDistanceIntervalBarExtender: {
      main: CEMIT_TRAIN_BUTTON_BACKGROUND,
    },
    trainDistanceIntervalBarExtenderDrag: {
      main: '#FFA500',
    },
    trainGroupAddButton: {
      main: CEMIT_GREEN,
      disabled: alpha(CEMIT_GREEN, 0.5),
      action: {
        isOver: lighten(CEMIT_GREEN, 0.8),
      },
    },

    // Non-standard below this line
    compare: {
      // Used for the comparison graph that shows a line
      baseline: {main: CEMIT_COMPARE_BASELINE},
      // Creates {'1': color1, '2': color2, ...}
      line: fromPairs<string>(
        addIndex<string>(map)(
          (color, index) => [index + 1, color],
          CEMIT_DEFAULT_TRAIN_COLORS,
        ),
      ),
      // Used for the comparison graph that shows a line
      fill: {
        primary: {main: CEMIT_COMPARE_FILL_1},
        secondary: {main: CEMIT_COMPARE_FILL_2},
      },
    },
    schedule: {
      button: {
        background: {
          default: CEMIT_TRAIN_BUTTON_BACKGROUND,
        },
      },
      line: {
        background: {
          default: CEMIT_TRAIN_RUN_LINE,
        },
      },
    },
  },
  typography,
});

export const themeLookup: Record<ThemeEnum, Theme> = {
  [ThemeEnum.light]: lightTheme,
  [ThemeEnum.dark]: darkTheme,
};
