import React from 'react';
import {AppBar, Backdrop, Box, IconButton, Stack, Toolbar, Typography, useMediaQuery, useTheme} from '@mui/material';
import LogoGotteborgDark from '../../../../../assets/dataUrlIcons/trafficSimAssets/images/logoGotteborg/LogoGotteborgDark.svg';
import LogoGotteborgLight from '../../../../../assets/dataUrlIcons/trafficSimAssets/images/logoGotteborg/LogoGotteborgLight.svg';
import ToggleSwitchStyled from 'pages/trafficsim/trafficSimComponents/themedComponents/ToggleSwitchStyled.tsx';
import TrafficSimSearchBar from 'pages/trafficsim/trafficSimComponents/themedComponents/TrafficSimSearchBar.tsx';
import {CemitAppProfileItem} from 'components/apps/cemitAppComponents/CemitAppProfileItem.tsx';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {MapboxMap} from 'react-map-gl';
import {OrganizationTrainComponentDependencyProps} from 'types/propTypes/trainPropTypes/organizationTrainAppComponentDependencyProps';
import {OrganizationTrafficSimAppComponentDependencyProps} from 'types/propTypes/trafficSimPropTypes/organizationTrafficSimAppComponentDependencyProps';
import {InfoRounded} from "@mui/icons-material";
import {Manual} from "../../documentation/Manual.tsx";

export interface TrafficSimHeaderBarProps extends CemitComponentProps {
  searchEntries: string[];
  mapRef: MapboxMap;
}

/**
 * The HeaderBar for the TrafficSim app
 * @param organizationProps
 * @param searchEntries
 * @param mapRef
 * @constructor
 */
export default function TrafficSimHeaderBar({
  appProps,
  organizationProps,
  componentProps: {searchEntries, mapRef},
}: OrganizationTrafficSimAppComponentDependencyProps<TrafficSimHeaderBarProps>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openManualBackDrop, setOpenManualBackDrop] = React.useState(false);

  const logoGotteborg: string = (
    theme === 'dark' ? LogoGotteborgDark : LogoGotteborgLight
  ) as string;


  const onBackDropClose = () => {
    setOpenManualBackDrop(false);
  };
  const onBackDropOpen = () => {
    setOpenManualBackDrop(true);
  };

  const onClickInfoButton = (event) => {
    onBackDropOpen()
  };


  return (
    <Stack
      {...{
        direction: 'row',
        sx: {
          maxHeight: '60px',
          minHeight: '60px',
          [theme.breakpoints.down('sm')]: {
            height: '50px',
            minHeight: '50px',
          },
          backgroundColor: theme.palette.background.tertiary,
          color: theme.palette.common.textColor,
          boxShadow: 'none',
          margin: 0,
          [theme.breakpoints.down('sm')]: {},

          justifyContent: 'space-between',
          minWidth: '100%',
          flex: 1,
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        },
      }}
    >
      <Toolbar
        {...{
          variant: 'dense',
          sx: {
            justifyContent: 'space-between',
            minWidth: '100%',
            flex: 1,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
          },
        }}
      >
        <img
          src={logoGotteborg}
          alt="Company Logo"
          style={{
            height: isMobile ? '60%' : '80%',
            marginLeft: '0px',
            marginTop: isMobile ? '10px' : '25px',
            marginBottom: isMobile ? '10px' : '25px',
          }}
        />
        <Stack
          {...{
            direction: 'row',
            sx: {alignItems: 'center', justifyContent: 'space-between'},
          }}
        >
          <TrafficSimSearchBar {...{searchEntries, mapRef}} />
          <ToggleSwitchStyled />
          <IconButton id="positioned-button"
                      aria-controls={openManualBackDrop ? 'positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openManualBackDrop ? 'true' : undefined}
                      onClick={onClickInfoButton} style={{ color: theme.palette.common.blue }}>
            <InfoRounded />
            {!isMobile && (
                <>
                  <Typography style={{ marginLeft: '8px' }}>
                    Info
                  </Typography>
                </>
            )}
          </IconButton>

          <Backdrop
              sx={{height: '100%', width: '100%', p:0, color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openManualBackDrop}
              onClick={()=> {}}
          >
            <Manual theme={theme} onClose={onBackDropClose} />
          </Backdrop>

          <CemitAppProfileItem
            {...{
              appProps,
              organizationProps,
            }}
          />
        </Stack>
      </Toolbar>
    </Stack>
  );
}
