import {always, filter, ifElse, length, lensProp, map, when} from 'ramda';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * Resolves the TrainGroup in scope, preferring the only item of trainProps.trainGroupActivityProps?.activeTrainGroups
 * and next taking the only item of trainProps.trainGroupOnlyTrainFormationProps?.activeTrainGroupFormations.
 * Returned undefined if both lists are empty
 * @param trainProps
 * @param organizationProps
 * @param limitToCemitTypename
 */
export const resolveActiveTrainGroups = (
  trainProps: TrainProps,
  limitToCemitTypename?: Perhaps<CemitTypename>,
): TrainGroup[] => {
  const trainGroupSingleTrainRuns: TrainGroupSingleTrainRun[] = when((): boolean => {
    return Boolean(
      limitToCemitTypename &&
        limitToCemitTypename != CemitTypename.trainGroupSingleTrainRun,
    );
  }, always([]))(
    trainProps.trainGroupSingleTrainRunProps?.crudTrainGroups?.list || [],
  ) as TrainGroupSingleTrainRun[];

  const trainGroupOnlyTrainFormations: TrainGroupOnlyTrainFormation[] = when(
    (): boolean => {
      return Boolean(
        limitToCemitTypename &&
          limitToCemitTypename != CemitTypename.trainGroupOnlyTrainFormation,
      );
    },
    always([]),
  )(
    trainProps.trainGroupOnlyTrainFormationProps?.crudTrainGroupOnlyTrainFormations
      ?.list || [],
  ) as TrainGroupOnlyTrainFormation[];

  // If we have activeTrainGroupSingleTrainRuns and any are active, return them
  const activeTrainGroupSingleTrainRuns: TrainGroupSingleTrainRun[] = length(
    trainGroupSingleTrainRuns,
  )
    ? filter((trainGroup: TrainGroup) => {
        return Boolean(trainGroup?.activity?.isActive);
      }, trainGroupSingleTrainRuns)
    : ([] as TrainGroupSingleTrainRun[]);

  // Otherwise return any active trainGroupOnlyTrainFormations
  type T = {
    activeTrainGroupSingleTrainRuns: TrainGroupSingleTrainRun[];
    trainGroupOnlyTrainFormations: TrainGroupOnlyTrainFormation[];
  };
  const activeTrainGroups = ifElse(
    ({activeTrainGroupSingleTrainRuns}: T) =>
      Boolean(length(activeTrainGroupSingleTrainRuns)),
    ({activeTrainGroupSingleTrainRuns}: T) => {
      return activeTrainGroupSingleTrainRuns;
    },
    ({trainGroupOnlyTrainFormations}: T) => {
      return filter((trainGroup: TrainGroup) => {
        return Boolean(trainGroup?.activity?.isActive);
      }, trainGroupOnlyTrainFormations);
    },
  )({activeTrainGroupSingleTrainRuns, trainGroupOnlyTrainFormations} as T);
  return activeTrainGroups;
};
