import {visibleScheduledStopPointsOfTrainRouteOrGroupWithDistanceInterval} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import {includes, map, mergeRight, uniq} from 'ramda';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {TrainDerivedProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {RailwayLineMinimizedProps} from '../../../../types/propTypes/trainPropTypes/railwayLineProps';
import {RequiredDeep} from '../../../../types/typeHelpers/deepRequired';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {maybeTrainRouteOrGroupDistanceInterval} from '../../../../appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import {TrainDistanceInterval} from '../../../../types/distances/trainInterval';
import {mergeTrainRouteOrGroup} from '../../../../appUtils/trainAppUtils/trainAppTypeMerging/trainRouteOrGroupMerging.ts';
import {TrainRouteOrGroupLineFinalizedProps} from 'types/propTypes/trainPropTypes/trainRouteOrGroupLineProps.d.ts';
import {TrainRouteOrGroup} from '../../../../types/trainRouteGroups/trainRouteOrGroup';
import {PartialDeep} from 'type-fest';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';

/**
 * Calculates the visible TimetabledPassingTimes based on the config
 *  If onlyStopsNearInterval, calculate which TimetabledPassingTime instances are visible
 * based on the routeDistance of each timetabledPassingTime and the trainRouteOrRunInterval.
 * We also need to include the first and last TimetabledPassingTimes for when the user drags the bar beyond
 * the start and end unless includeEndStops is false
 * @param loading Return undefined if true
 * @param trainProps
 * @param trainRouteOrGroupLineProps
 * @param trainRouteOrGroupLineProps.onlyStopsNearInterval If true, limit to the stops near the trainDistanceInterval
 * @param trainRouteorGroupLineProps.limitedTrainDistanceInterval TODO not sure if this is needed here, it's based on the
 * min distance range of all active TrainGroups. I had it retriggering this function when it
 * changes, but I'm not sure it that's needed
 */
export const useMemoVisibleScheduledStopPoints = ({
  loading,
  trainProps,
  trainRouteOrGroupLineProps,
}: {
  loading: boolean;
  trainProps: TrainDerivedProps;
  trainRouteOrGroupLineProps: TrainRouteOrGroupLineFinalizedProps;
}) => {
  const {
    onlyStopsNearInterval,
    spaceGeospatially,
    trainDistanceInterval,
    showLimitedDistanceRange,
  } = trainRouteOrGroupLineProps;
  return useNotLoadingMemo(loading, () => {
    const railwayLineProps =
      trainProps.railwayLineProps as RequiredDeep<RailwayLineMinimizedProps>;

    // If trainRouteOrGroup has derived values and a trainDistanceInterval has been defined
    const trainRouteOrGroup = trainProps.trainRouteGroupProps.trainRouteOrGroup;
    const trainDistanceInterval: Perhaps<TrainDistanceInterval> =
      maybeTrainRouteOrGroupDistanceInterval(trainRouteOrGroup);
    const showAllStops: boolean = !onlyStopsNearInterval && !showLimitedDistanceRange;
    const visibleScheduledStopPointsAndMaybeDateTimes =
      trainDistanceInterval && !showAllStops
        ? visibleScheduledStopPointsOfTrainRouteOrGroupWithDistanceInterval(
            {
              buffer: 0,
              // Currently we hide intermediate stops if onlyStopsNearInterval is true
              removeIntermediate: Boolean(onlyStopsNearInterval),
              includeEndStops: !spaceGeospatially,
              railwayLines: trainProps.railwayLineProps.railwayLines,
            },
            // @ts-ignore
            scheduledStopPointsAndMaybeDateTimes,
            // Set the trainDistanceInterval if defined and showLimitedDistanceRange is true
            // Otherwise leave trainRouteOrGroup.trainDistanceInterval.distance maximized
            showLimitedDistanceRange
              ? mergeTrainRouteOrGroup(trainRouteOrGroup, {
                  trainDistanceInterval: {distanceRange: limitedTrainDistanceInterval},
                } as PartialDeep<TrainRouteOrGroupDerived>)
              : trainRouteOrGroup,
          )
        : scheduledStopPointsAndMaybeDateTimes;

    // Give the railways' referenceScheduledStopPoints higher priority.
    const railwayLines = railwayLineProps.railwayLines;
    const referenceScheduledStopPointIds = uniq(
      map((railwayLine) => railwayLine.referenceScheduledStopPoint.id, railwayLines),
    );
    return map((scheduledStopPoint) => {
      return mergeRightIfDefined(
        scheduledStopPoint,
        // @ts-ignore
        {
          priority: includes(scheduledStopPoint.id, referenceScheduledStopPointIds)
            ? 2
            : 1,
        },
      );
    }, visibleScheduledStopPointsAndMaybeDateTimes);
  }, [
    spaceGeospatially,
    onlyStopsNearInterval,
    showLimitedDistanceRange,
    trainDistanceInterval,
  ]);
};
