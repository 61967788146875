import {
  always,
  cond,
  filter,
  head,
  indexBy,
  is,
  last,
  map,
  mergeRight,
  mergeWithKey,
  prop,
  propOr,
  T,
  values,
} from 'ramda';
import {Cemited} from '../../types/cemited';
import {mergeDeep} from 'apollo-utilities';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {Identified, Unidentified} from '../../types/identified';

import {CemitedClass} from '../../classes/cemitAppCemitedClasses/cemitedClass.ts';
import {clsOrType} from './clsOrType.ts';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';

type mergeFunctionsType<E extends Cemited, I extends Cemited> = (e: E, i: I) => E & I;
type mergeWithKeyFunctionsType<E extends Cemited, I extends Cemited> = (
  keyFunc: (str: string, x: any, z: any) => any,
  e: E,
  i: I,
) => E & I;

/**
 * Given an existing and incoming of overlapping types, return the type of the one that that is an instance
 * of the type of the other. In other words, the most extended of the two.
 *
 * incoming and existing can be objects that extend interfaces, in which case we always return an object constructor
 * and the caller must label it with the correct CemitTypename.
 * The logic below applies if one or both of incoming and existing are classes
 *
 * Common cases for merging are:
 * 1 existing is a complete type E and incoming I is a partial version of E,
 * in which case we want to return E. So the logic tests that incoming is not and instance of E and thus returns the
 * type of E
 *
 * 2 incoming I is a subclass or extends of interface or class E because I is adding derived attributes. In this
 * case incoming evaluates to be an instance of E's constructor and we return the type of I
 *
 * 3 Both types are equal so we return that type
 *
 * 4 One or both types don't have associated Cemited classes. Take the __typename of incoming or if incoming
 * is partial of existing
 *
 * @param existing
 * @param incoming
 * @returns A function expecting existing, incoming, a merge function, and an optional keyFunction if mergeWithKey or
 * similar is used for merge
 */
export const classifyOrTypeMergeFunctionOfExistingAndIncoming = <
  E extends Cemited,
  I extends Cemited,
>(
  existing: E,
  incoming: I,
):
  | ((e: E, i: I, merge: mergeFunctionsType<E, I>) => E & I)
  | ((
      e: E,
      i: I,
      merge: mergeWithKeyFunctionsType<E, I>,
      keyFunc: Perhaps<(str: string, x: any, z: any) => any>,
    ) => E & I) => {
  const areBothCemitedClasses = is(CemitedClass, existing) && is(CemitedClass, incoming);
  const instanceForPrototype = cond([
    [
      // If they aren't both CemitedClasses, take the incoming or failing that existing CemitedTypename
      always(!areBothCemitedClasses),
      ([existing, incoming]: [E, I]) => (incoming.__typename ? incoming : existing),
    ],
    [
      // If the incoming inherits from the existing's prototype, we can use the prototype of incoming, else use existing
      ([existing, incoming]: [E, I]) =>
        is(Object.getPrototypeOf(existing).constructor, incoming),
      last,
    ],
    [
      // use existing
      T,
      head,
    ],
  ])([existing, incoming]);

  // We don't need the constructor for Cemited interfaces because we resolve the class from CemitTypenam, here
  // instanceForPrototype.__typename if there is a corresponding class
  // const constructor = Object.getPrototypeOf(instanceForPrototype).constructor
  return (
    existing: E,
    incoming: I,
    merge: typeof keyFunc extends undefined
      ? mergeFunctionsType<E, I>
      : mergeWithKeyFunctionsType<E, I>,
    keyFunc: Perhaps<(str: string, x: any, z: any) => any> = undefined,
  ): typeof instanceForPrototype => {
    const merged: E & I = keyFunc
      ? merge(keyFunc!, existing, incoming)
      : // @ts-ignore typescript should know this
        merge(existing, incoming);
    return clsOrType<typeof instanceForPrototype>(
      instanceForPrototype.__typename,
      merged,
    );
  };
};

/**
 * Merges right existing and incoming together with mergeFunction, giving the result the type of existing or
 * incoming depending on the rules documented in mergeFunctionOfExistingAndIncoming
 * @param existing
 * @param incoming
 * @param mergeFunction
 */
export const mergeExistingAndIncoming = <E extends Cemited, I extends Cemited>(
  existing: E,
  incoming: I,
  mergeFunction: (l: E, r: I) => typeof l | typeof r = mergeRight,
): E & I => {
  const merge = classifyOrTypeMergeFunctionOfExistingAndIncoming(existing, incoming) as (
    e: E,
    i: I,
    merge: mergeFunctionsType<E, I>,
  ) => E | I;
  return merge(existing, incoming, mergeFunction);
};
/**
 * Merges deep existing and incoming together with mergeFunction, giving the result the type of existing or
 * incoming depending on the rules documented in mergeFunctionOfExistingAndIncoming
 * @param existing
 * @param incoming
 * @param mergeFunction
 */
export const mergeDeepExistingAndIncoming = <E extends Cemited, I extends Cemited>(
  existing: E,
  incoming: I,
  mergeFunction: (l: E, r: I) => typeof l | typeof r = mergeDeep,
): Perhaps<E | I> => {
  if (!existing) {
    return incoming;
  } else if (!incoming) {
    return existing;
  } else {
    const merge = classifyOrTypeMergeFunctionOfExistingAndIncoming(
      existing,
      incoming,
    ) as (e: E, i: I, merge: mergeFunctionsType<E, I>) => E | I;
    return merge(existing, incoming, mergeFunction);
  }
};

/**
 * Uses mergeWithKey, giving the result the type of existing or
 * incoming depending on the rules documented in mergeFunctionOfExistingAndIncoming.
 * existing or incoming can be undefined
 * @param existing
 * @param incoming
 * @param mergeFunction
 * @param keyFunction
 */
export const mergeWithKeyExistingAndIncoming = <E extends Cemited, I extends E>(
  keyFunction: (str: string, x: any, z: any) => any,
  existing: Perhaps<E>,
  incoming: Perhaps<I>,
  mergeFunction: (
    fn: (str: string, x: any, z: any) => any,
    a: E,
    b: I,
  ) => any = mergeWithKey,
): Perhaps<E | I> => {
  if (!existing) {
    return incoming;
  } else if (!incoming) {
    return existing;
  } else {
    const merge = classifyOrTypeMergeFunctionOfExistingAndIncoming(
      existing,
      incoming,
    ) as (
      e: E,
      i: I,
      merge: mergeWithKeyFunctionsType<E, I>,
      keyFunc: Perhaps<(str: string, x: any, z: any) => any>,
    ) => E | I;
    // If incoming has no type, use existing's
    // This happens when incoming is a partial with limited new data or empty
    return merge(
      existing,
      mergeRightIfDefined({__typename: existing.__typename}, incoming),
      mergeFunction,
      keyFunction,
    );
  }
};

/**
 * Given two lists of similar types and a groupingProp, index each list by groupingProp
 * and merge the two groupProp based records so that instances from each list with the same
 * groupingProp value are merged. The resulting object values are returned wiht all merged and unmerged
 * members of existing and incoming, each typed by their original type if unmerged or the
 * result of mergeFunction if merged
 * @param groupingProp
 * @param keyFunction
 * @param existing
 * @param incoming
 * @param mergeFunction
 */
export const mergeWithKeyExistingAndIncomingListsByGroupingProp = <
  E extends Cemited,
  I extends Cemited,
>(
  groupingProp: keyof E & keyof I,
  keyFunction: (str: string, x: any, z: any) => any,
  existing: E[],
  incoming: I[],
  mergeFunction: (
    fn: (str: string, x: E, z: I) => any,
    a: Record<string, E>,
    b: Record<string, I>,
  ) => any = mergeWithKey,
): (E & I)[] => {
  const [existingById, incomingById] = map(
    (list: (E | I)[]) => {
      return indexBy((item: E | I) => {
        return item[groupingProp];
      }, list);
    },
    [existing, incoming],
  );

  const mergedObject: Record<string, E & I> = mergeFunction(
    keyFunction,
    existingById,
    incomingById,
  );
  return values(mergedObject);
};

/**
 * Returns a concatinated list of existingList followed by incomingList where incoming items replace existing
 * if there is an id match
 * @param existingList
 * @param incomingList
 * @param idProp id prop of T, defaults to 'id'
 */
export const concatReplacingMatchesWithRight = <T extends Unidentified>(
  existingList: Perhaps<T[]>,
  incomingList: T[],
  idProp: keyof T = 'id',
) => {
  const [existingByIdProp, incomingByIdProp]: [Record<string, T>, Record<string, T>] =
    map(indexBy(prop(idProp)), [existingList || [], incomingList]);
  const merged = [
    // Replace existing with incoming if there is an idProp match
    ...map((item: T) => {
      return propOr(item, item[idProp], incomingByIdProp);
    }, existingList || []),
    // Add incoming that don't match existing
    ...filter((item: T) => {
      return !propOr(false, item[idProp], existingByIdProp);
    }, incomingList),
  ];
  return merged;
};
