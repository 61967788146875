import React from 'react';
import {Dialog} from '@mui/material';
import {DialogActions} from '@mui/material';
import {DialogContent} from '@mui/material';
import {DialogContentText} from '@mui/material';
import {DialogTitle} from '@mui/material';
import PrimaryButton from './buttons/GenericButtons/PrimaryButton/PrimaryButton.tsx';
import SecondaryButton from './buttons/GenericButtons/SecondaryButton/SecondaryButton.tsx';

const DeleteConfirmationDialog = ({open, onClose, onConfirm}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Bekräfta radering'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Är du säker på att du vill radera detta objekt?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={onConfirm} autoFocus>
          Bekräfta
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Avbryt</SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
