import {identity, when} from 'ramda';
import {UserStateLoaded} from 'types/userState/userState';
import {ACCESS_LEVELS} from "../../pages/trafficsim/utils/access_levels.ts";

/**
 * Primitive method of testing access by comparing the key and localStorage group
 * to the tabAccess
 *
 * @param key Access key to check, e.g. "train" or "rails"
 * @param userState The authenticated state of the user
 */
export const hasAccess = (key: string, userState: UserStateLoaded): boolean => {
  const group = userState.access.group;
  const applicationAccess = userState.access.applicationAccess;
  return group === 'sysadmin' || applicationAccess.includes(key);
};
/**
 * Same as hasAccess but excludes admin access so admins don't see org specific apps without logging
 * in as that org
 * @param key
 * @param userState
 */
export const hasApplicationAccess = (
  key: string,
  userState: UserStateLoaded,
): boolean => {
  const applicationAccess = userState.access.applicationAccess;
  return applicationAccess.includes(key);
};

/**
 * Returns true if the user as an administrator, false otherwise.
 *
 * @param userState The authenticated state of the user
 */
export const hasAdminAccess = (userState: UserStateLoaded): boolean => {
  return userState.access.group === 'sysadmin';
};


/**
 * Returns true if the user as a TracfficSim admin, false otherwise.
 *
 * @param userState The authenticated state of the user
 */
export const hasTrafficSimAdminAccess = (userState: UserStateLoaded): boolean => {
  return hasAdminAccess(userState) || extractTrafficSimAccess(userState) === ACCESS_LEVELS.ADMIN;
};


/**
 * Returns trafficsim access level from the userstate.
 *
 * @param userState The authenticated state of the user
 */
export const extractTrafficSimAccess = (userState: UserStateLoaded): string => {
  const trafficSimAccess = userState.access.trackAccess;
  const accessLevels = Array.from(trafficSimAccess).filter((s) => s.substring(0, 9) === "tracksim_");
  return accessLevels.length > 0 ? accessLevels[0] : "";
};


/**
 * Pulls the JSON stringified token from local storage when appProps.token isn't available
 */
export const tokenFromStorage = () => {
  return when(identity, (token: Perhaps<string>) => {
    return JSON.parse(token);
  })(localStorage.getItem('token') as Perhaps<string>);
};
