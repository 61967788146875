import {Box, Stack, Typography} from '@mui/material';
import React, {createElement} from 'react';
import {AlertBaseGauge, AlertGaugeValue} from 'types/alerts/alertGauge';
import {cemitColors} from 'theme/cemitColors.ts';
import {
  CemitComponentProps,
  OnlyComponentProps,
} from 'types/propTypes/cemitComponenProps';
import {Cemited} from 'types/cemited';
import {always, and, concat, cond, includes, prop, T} from 'ramda';
import {useTranslation} from 'react-i18next';
import {SvgProps} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/SvgProps.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {AttributeAlertLevel} from 'types/alerts/attributeAlertLevelEnums';

export interface AlertErrorLevelComponentProps extends CemitComponentProps {
  loading: boolean;
  trainGroup: TrainGroup;
  alertGauge: AlertBaseGauge;
  // The label's Typography variant or the default chosen by the component
  labelVariant?: string;
  attributeAlertLevel: keyof Omit<AlertBaseGauge, keyof Cemited>;
  label: string;
  // The Svg component to render by passing fill
  imageSvgComponent: React.FC<SvgProps>;
  // The imageSvgComponent fill color
  color: string;
  // The icon's size or the default chosen by the component
  iconSize?: number;
  // Show the count of alerts. Defaults to false
  showCount?: boolean;
  // The alert title. visible by default
  showAlertTitles?: boolean;
  // The direction of alerts. Defaults to 'column'. Can be 'row
  direction: string;
  // Can the alerts be collapsed and expanded. This is only used in the listing of all trainGroups
  expandable: boolean;
  // Is the alert currently expanded if expandable is true
  isExpanded: boolean;
}

/**
 * Shows the number of errors for a certain RideComfortGauge attribute: normal, warning, critical
 * @constructor
 */
const AlertErrorLevelComponent = ({
  componentProps,
}: OnlyComponentProps<AlertErrorLevelComponentProps>) => {
  const {
    loading,
    trainGroup,
    alertGauge,
    showCount = false,
    labelVariant = 'subtitle2',
    attributeAlertLevel,
    label,
    imageSvgComponent,
    color,
    iconSize = 15,
    showAlertTitles = true,
    direction = 'column',
    isExpanded,
    expandable,
  } = componentProps;
  const {t} = useTranslation();

  const collapsed = expandable && !isExpanded;
  const expanded = expandable && isExpanded;

  const alertGaugeElement: AlertGaugeValue = alertGauge?.[attributeAlertLevel];
  const value = cond([
    [prop('loading'), always('...')],
    [
      ({alertGaugeElement}) => {
        return isNaN(alertGaugeElement?.value);
      },
      always('N/A'),
    ],
    [
      T,
      ({alertGauge}) => {
        // Round unless the value is > 99 and < 100, in which case floor. If the value is < 1 and greater than 0, ceiling
        return cond([
          [
            (value: number) => and(value > 0, value < 1),
            (value: number) => Math.ceil(value),
          ],
          [
            (value: number) => and(value > 99, value < 100),
            (value: number) => Math.floor(value),
          ],
          [T, (value: number) => value.toFixed(0)],
        ])(alertGauge[attributeAlertLevel].value);
      },
    ],
  ])({
    loading,
    alertGauge,
    alertGaugeElement,
  } as const);

  const nonNormalLevels: AttributeAlertLevel[] = concat(
    alertGauge.alertTypeConfig!.warningLevels,
    alertGauge.alertTypeConfig!.criticalLevels,
  );
  const count =
    showCount &&
    includes(attributeAlertLevel, nonNormalLevels) &&
    alertGaugeElement?.count
      ? alertGauge[attributeAlertLevel].count
      : undefined;

  // Only show the percent sign if not expanded, expanded has it in the title
  // Only show count for non-normal alerts
  const valueLabel = loading
    ? '...'
    : `${value}${expanded ? '' : '%'}${count ? `Tot. ${count}` : ''}`;

  return (
    <Stack
      key={label}
      {...{
        direction: 'row',
        spacing: 0.5,
        sx: {
          width: '100%',
          minWidth: 0,
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      }}
    >
      <Stack
        {...{
          direction: 'row',
          spacing: 1,
          sx: {
            alignItems: 'center',
            flex: 1,
          },
        }}
      >
        <Box
          key="box"
          {...{
            sx: {
              alignSelf: 'center',
              width: iconSize,
              height: iconSize,
            },
          }}
        >
          {createElement<SvgProps>(imageSvgComponent, {
            ...{
              width: '15px',
              height: '15px',
              fill: color,
              // TODO disable train coloring if expandable
              trainGroupFill: expandable ? undefined : trainGroup.activity.isActiveColor,
            },
          })}
        </Box>
        {
          // Don't show the title if expandable
          !expandable && showAlertTitles ? (
            <Typography
              key="label"
              {...{
                variant: labelVariant,
                sx: {
                  color: cemitColors.white,
                },
              }}
            >
              {label}
            </Typography>
          ) : undefined
        }
      </Stack>
      {
        // Don't show the number in collapsed mode, just the icon
        collapsed || (expanded && value == 100) ? undefined : (
          <Box
            key="box"
            {...{
              sx: {
                flex: 2,
              },
            }}
          >
            <Typography
              key="rideComfortGaugeLevel"
              {...{
                variant: labelVariant,
                align: direction == 'column' ? 'right' : 'left',
                sx: {
                  textAlign: direction ? 'start' : 'end',
                  color: cemitColors.white,
                },
              }}
            >
              {valueLabel}
            </Typography>
          </Box>
        )
      }
    </Stack>
  );
};
AlertErrorLevelComponent.displayName = 'AlertErrorLevelComponent';
export default AlertErrorLevelComponent;
