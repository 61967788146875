import {useEffect, useMemo} from 'react';
import {LoadingExplanation} from '../../../types/async/loadingExplanation';
import {
  complement,
  concat,
  cond,
  eqProps,
  findIndex,
  head,
  identity,
  includes,
  is,
  isNil,
  join,
  keys,
  lensIndex,
  map,
  none,
  omit,
  prepend,
  set,
  T,
  tail,
  values,
} from 'ramda';
import {WhatIsLoading} from '../../../types/dependencies/whatIsLoading';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {StateSetter} from '../../../types/hookHelpers/stateSetter';
import {compact} from '@rescapes/ramda';
import {Perhaps} from '../../../types/typeHelpers/perhaps';
import {useWhatChanged} from '@simbathesailor/use-what-changed';
import {clsOrType} from '../../../appUtils/typeUtils/clsOrType.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {
  setClassOrType,
  setClassOrTypeList,
} from '../../../utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * Creates an object keyed by property path and valued by boolean to indicate why we are in a loading state.
 * Any values passed in obj are converted to boolean to make memoizing work and to make the explanation simple
 * @param loading
 * @param loadingPathName Names the path represented by the loading boolean
 * @param sourceKey
 * @param obj Keyed by a string indicating a variable or path to variable. The strings are never evaluated. Valued
 * by truthy if the variable is loaded and falsy if is not. Boolean is used to convert them to find out if any
 * are falsy, so be aware that 0 and '' are falsy and should converted to something else before calling this if needed
 * @param dependencies
 * @param setWhatDependenciesAreLoading
 * @param customAllLoaded An optional boolean to indicate that loading is false and all child properties are loaded.
 * If omitted, the values of obj are converted to their !boolean equivalent and they must all false for allLoaded to evaluate to true
 */
export const useWhatIsLoading = (
  loading: boolean,
  loadingPathName: string,
  sourceKey: string,
  obj: LoadingExplanation,
  dependencies: any[],
  setWhatDependenciesAreLoading: StateSetter<WhatIsLoading[]>,
  customAllLoaded?: Perhaps<boolean>,
): WhatIsLoading => {
  const whatIsLoading: WhatIsLoading = useMemo<LoadingExplanation>(() => {
    // Shows a string and a true value for loading and a false value for not loading
    const objWithBooleanValues = {
      [`${loadingPathName}`]: loading,
      ...map((value: any) => {
        return cond([
          [
            (value) => {
              return typeof value === 'boolean';
            },
            (value: boolean) => !value,
          ],
          [T, isNil],
        ])(value);
      }, obj),
    };

    const allLoaded: boolean =
      typeof customAllLoaded !== 'undefined'
        ? customAllLoaded
        : none(identity, values(objWithBooleanValues));
    return clsOrType<WhatIsLoading>(CemitTypename.whatIsLoading, {
      loading: loading || !allLoaded,
      sourceKey,
      // Shows true for attributes that are loading and false for those not loading
      loadingExplanation: clsOrType<LoadingExplanation>(
        CemitTypename.loadingExplanation,
        objWithBooleanValues,
      ),
      obj,
    });
  }, [loading, ...dependencies]);
  useEffect(() => {
    setWhatDependenciesAreLoading(
      (whatDependenciesAreLoading: WhatIsLoading[]): WhatIsLoading[] => {
        const index = findIndex(
          eqProps('sourceKey', whatIsLoading),
          whatDependenciesAreLoading,
        );
        return index >= 0
          ? setClassOrTypeList(
              lensIndex(index),
              whatIsLoading,
              whatDependenciesAreLoading,
              // The first dependency always calls this effect and then renders the children. The children
              // call this effect from deepest to shallowest. TODO it would be better to somehow
            )
          : concat(
              compact([head(whatDependenciesAreLoading), whatIsLoading]),
              tail(whatDependenciesAreLoading),
            );
      },
    );
  }, [loading, whatIsLoading]);
  return whatIsLoading;
};

/**
 * Uses useWhatChanged to show changes in the loadingExplanation
 * if AppSettings.debugWithUseWhatChanged is true.
 * If AppSettings.debugWithUseWhatChanged is an array, it must contain name in order to run useWhatChanged here
 *
 * @param whatIsLoading
 * @param mergedProps
 * @param name
 */
export const useWhatChangedLoadingExplanation = (
  whatIsLoading: WhatIsLoading,
  mergedProps = {},
  name: Perhaps<string> = undefined,
  childPropsKey: string,
) => {
  if (AppSettings.debugWithUseWhatChanged) {
    const parsed = JSON.parse(AppSettings.debugWithUseWhatChanged);
    if (!Array.isArray(parsed) || includes(name, parsed)) {
      const modified = omit(['loadingExplanation', 'obj'], whatIsLoading);
      // Hook can be called in an if statement because the conditions of the statement are constant
      return useWhatChanged(
        [...values(modified), ...values(whatIsLoading.obj), ...values(mergedProps)],
        join(',', [
          ...map((k) => `what is loading: ${k}`, keys(modified)),
          ...map((k) => `what is loading obj ${k}`, keys(whatIsLoading.obj)),
          ...map(
            (k) => `parent prop ${k}`,
            keys(childPropsKey ? omit([childPropsKey], mergedProps) : mergedProps),
          ),
          ...map(
            (k) => `child prop ${k}`,
            keys(childPropsKey ? mergedProps[childPropsKey] || {} : {}),
          ),
        ]),
        name,
      );
    }
  }
};
