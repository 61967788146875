import {RoutePoint, RoutePointResponse} from '../../types/trainRouteGroups/routePoint';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {RailwayLineMinimized} from '../../types/railways/railwayLine';
import {createPointProjection} from './pointProjectionCrud.ts';
import {map} from 'ramda';
import {PointProjection} from '../../types/geometry/pointProjection';
import {createRailwayLineMinimized} from './railwayLineCrud.ts';
import {createScheduledStopPoint} from './scheduledStopPointCrud.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {useMemoClsOrType} from '../../appUtils/typeUtils/useMemoClsOrType.ts';

export const createRoutePoint = (routePointResponse: RoutePointResponse): RoutePoint => {
  return clsOrType<RoutePoint>(CemitTypename.routePoint, {
    ...routePointResponse,
    scheduledStopPoint: createScheduledStopPoint(routePointResponse.scheduledStopPoint),
    railwayLines: map((railwayLine: RailwayLineMinimized) => {
      return createRailwayLineMinimized(railwayLine);
    }, routePointResponse.railwayLines || []),
  });
};
