import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {
  add,
  all,
  always,
  any,
  compose,
  concat,
  cond,
  eqProps,
  equals,
  filter,
  find,
  forEach,
  head,
  includes,
  indexBy,
  isNil,
  join,
  length,
  lensProp,
  map,
  mapObjIndexed,
  prop,
  propOr,
  sortBy,
  T,
  uniqBy,
} from 'ramda';
import {findMapped} from '@rescapes/ramda';
import {useEffectCreateListCrud} from 'utils/hooks/crudHooks.ts';
import {
  mergeCachedTrainRunsIntoTrainGroups,
  mergeTrainGroup,
} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {queryApiForTrainGroupWithSensorPoints} from 'async/trainAppAsync/trainAppHooks/trainApiHooks/trainApiTrainGroupSensorDataQueryHooks.ts';
import {postSetList} from 'classes/typeCrud/trainGroupCrud.ts';
import {CrudList} from 'types/crud/crudList';
import {
  TrainRouteOrGroup,
  TrainRouteOrGroupDerived,
} from 'types/trainRouteGroups/trainRouteOrGroup';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {
  OrganizationLoaded,
  OrganizationMinimized,
} from 'types/organizations/organization.ts';
import {SensorDataTrainGroup} from '../../../../types/trainGroups/sensorDataTrainGroup';
import {TrainRouteGroup} from 'types/trainRouteGroups/trainRouteGroup';
import {useCustomLocalStorage} from 'utils/hooks/useCustomLocalStorage.ts';
import {
  activeSensorDataEligibleTrainGroups,
  doActiveTrainGroupsHaveTrainRuns,
  trainRuns,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {TrainDerivedProps, TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {TimeRecurrence} from 'types/datetime/timeRecurrence';
import {localizedTime, localizedTimeNoTimezone} from 'utils/datetime/dateFormatUtils.ts';
import {TrainGroupFilterProps} from 'types/propTypes/trainPropTypes/trainGroupFilterProps.d.ts';
import {TrainRun} from 'types/trainRuns/trainRun';
import {setListIfGivenPropsChanged} from 'utils/hooks/setterUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {resolveDistance} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import {LeftRight} from 'types/dragNDrop/leftRight';
import {TrainDistanceInterval} from 'types/distances/trainInterval';
import {DragItemType} from 'types/dragNDrop/dragItemType.ts';
import {
  TrainRouteOrGroupLineFinalizedProps,
  TrainRouteOrGroupLineProps,
} from 'types/propTypes/trainPropTypes/trainRouteOrGroupLineProps.d.ts';
import {
  asCemitedClassOrThrow,
  equalsCemitType,
  implementsCemitTypeViaClass,
} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {DistanceRange, DistanceRangeStartEnd} from 'types/distances/distanceRange';
import {
  computedIntervalBarPosition,
  defaultUnmaximizedDistanceRange,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupWithDistanceRangeUtils.ts';
import {ItemTypes} from 'config/appConfigs/trainConfigs/trainDragAndDropConfig.ts';
import {mergeTrainDistanceInterval} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainRouteOrGroupMerging.ts';
import {mirrorLoadingStatusInCrud} from 'classes/typeCrud/listCrud.ts';
import {
  dumpLoadingStatus,
  updateIncompletesToLoading,
} from 'utils/loading/loadingStatusUtils.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {DateIntervalStartOrEnd, StartOrEndPosition} from 'types/distances/startOrEnd';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {useMemo} from 'react';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {
  minimizedStoredTrainGroups,
  trainGroupGroupingCollectionToLookup,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupByGroupingUtils.ts';
import {
  TrainGroupsGrouping,
  TrainGroupsGroupingCollection,
} from 'types/trainGroups/trainGroupsGroupingCollection';
import {overClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {LocalStorageProps} from 'types/cemitFilters/localStorageProps.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {resolveActiveTrainGroups} from '../../../../appUtils/trainAppUtils/scope/activeTrainGroup.ts';
import {TrainGroup, TrainGroupMinimized} from 'types/trainGroups/trainGroup';
import {
  compact,
  idListsEqual,
  idsEqual,
  itemsNotInLookup,
  onlyOneValueOrThrow,
} from 'utils/functional/functionalUtils.ts';
import {dumpDateInterval} from 'utils/datetime/dateUtils.ts';
import {PerhapsIfLoading} from 'types/logic/requireIfLoaded.ts';
import {TrainRoute} from 'types/trainRouteGroups/trainRoute';
import {
  TrainApiTrainRunsRequestProps,
  TrainApiTrainRunsRoute,
  TrainRouteOrGroupRequestProps,
} from 'types/apis/trainApi';
import {useCemitApiSwrResolveData} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {TrainFormation} from 'types/trains/trainFormation';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {TrainGroupSingleTrainRunClass} from 'classes/trainAppCemitedClasses/trainGroupSingleTrainRunClass.ts';
import {limitAndColorActiveTrainGroups} from 'async/trainAppAsync/trainAppHooks/typeHooks/activeTrainGroupHooks.ts';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';

/**
 * TODO we no longer care about common distance range. We store the activeDateInterval on each trainGroup
 * so we know what SensorDataPoints might need to be downloaded for each TrainGroup
 * Computes the distance range encompassing all given TrainGroups in
 * trainGroupGeojsons
 * @param loading Do nothing if undefined
 * @param]} trainGroupGeojsons
 * Gets the min, max distance range of all trainGroupGeojsons combined.
 * @returns { start: min, end: max } The min and max distance in km along the TrainRoute or
 * undefined if loading or trainGroupGeojsons is empty
 */
// export const useMemoActiveTrainGroupsDistanceRange = ({
//   loading,
//   trainGroups,
// }: {
//   loading: boolean;
//   trainGroups: TrainGroup[];
// }) => {
//   const dependencies = [trainGroups];
//   return useNotLoadingMemo(
//     loading,
//     () => {
//       return compose(
//         (distanceRanges: DistanceRange[]) => {
//           return length(distanceRanges) > 1
//             ? applySpec({
//                 start: compose(Math.min, prop('start')),
//                 end: compose(Math.max, prop('end')),
//               })(...distanceRanges)
//             : pick(['start', 'end'], head(distanceRanges));
//         },
//         (trainGroups: TrainGroup[]) => {
//           return map((trainGroup: TrainGroup) => {
//             // TODO maybe this should be trainGroup.trainRouteOrGroup.distanceRange
//             return trainGroup.trainRouteOrGroup!.trainDistanceInterval!.distanceRange;
//           }, trainGroups);
//         },
//       )(trainGroups);
//     },
//     dependencies,
//   );
// };

/**
 * Deserializes the stored TrainGroups for the current TrainRoute if one is in scope
 * or deserializes the stored TrainGroup
 * The paths to compare of existing and incoming trainGroups
 * to see if anything has changed. For TrainGroups this is
 * currently ['id', 'trainRoute.id']
 * The TrainRoute id check exists because we override the TrainRoue of Baseline trainGroups
 * to match the current TrainRoute even though they come from a different same-direction TrainRoute
 * and for TrainGroupOnlyFormations this is currently ['id']
 *
 * For organizations without ServiceLines the paths are currently ['id', 'activity', 'trainFormation.alertStatus'])
 *
 * filteredTrainGroups are merged with trainGroupsForGroupingId to create a list to assign to
 * trainGroups. The list is filteredTrainGroups with matching TrainGroups in trainGroupsForGroupingId
 * overriding any properties that were set by the user
 * @param loading
 * @param organizationHasServiceLines
 * @param filteredTrainGroups All TrainGroups currently in scope via filtering
 * @param trainGroupsForGroupingId The TrainGroups cached for the
 * current TrainRouteGroup or, if the Organization lacks TrainRoutes, all TrainGroups
 * in the scope of the Organization. These are the TrainGroups with {activity: {isActive: true}} that
 * the user activated or that are preconfigured as baseline TrainGroups. They don't have to be
 * in filteredTrainGroups.
 * @param trainGroups The current TrainGroups list that will be updated
 * @param setTrainGroups The setter function
 */
export const useSyncTrainGroupsToStoredAndPreconfigured = ({
  loading,
  organizationHasServiceLines,
  filteredTrainGroups,
  trainGroupsForGroupingId,
  trainGroups,
  setTrainGroups,
}: {
  loading: boolean;
  organizationHasServiceLines: boolean;
  filteredTrainGroups: Perhaps<TrainGroup[]>;
  trainGroupsForGroupingId: Perhaps<TrainGroup[]>;
  trainGroups: TrainGroup[];
  setTrainGroups: StateSetter<TrainGroup[]>;
}) => {
  // The TrainGroups of the current TrainRoute
  useNotLoadingEffect(loading, () => {
    // If the trainRoute changes, set the setTrainGroups to those in storage
    // If TrainRoutes are not defined, this is always the TrainGroups for the cli
    const cachedTrainGroups = trainGroupsForGroupingId || [];

    // If we have active TrainGroups cached by TrainRoute, merge them into the filteredTrainGroups
    // to create TrainGroups that have activity.active = true set.
    // Return the filterTrainGroups with these merges, if any
    const trainGroupsWithAnyStoredActivity = mergeCachedTrainRunsIntoTrainGroups(
      filteredTrainGroups || [],
      cachedTrainGroups,
    );

    // Compare the properties that distinguish TrainGroups
    const comparisonPaths = cond([
      [
        // If the are no ServiceLines (e.g. the Mantena case)
        () => !organizationHasServiceLines,
        always(['id', 'activity', 'trainFormation.alertStatus']),
      ],
      [
        // If TrainGroupSingleTrainRunClass or a subclass
        (trainGroups: TrainGroup[]) => {
          return all((trainGroup: TrainGroup) => {
            return implementsCemitTypeViaClass<TrainGroupSingleTrainRunClass>(
              CemitTypename.trainGroupSingleTrainRun,
              trainGroup,
            );
          }, trainGroups);
        },
        always(['singleTrainRun.id', 'singleTrainRun.trainRoute.id']),
      ],
      [
        // If TrainGroupOnlyTrainFormation
        (trainGroups: TrainGroup[]) => {
          return all((trainGroup: TrainGroup) => {
            return equalsCemitType<TrainGroupOnlyTrainFormation>(
              CemitTypename.trainGroupSingleTrainRun,
              trainGroup,
            );
          }, trainGroups);
        },
        always(['singleTrainRun.id', 'singleTrainRun.trainRoute.id']),
      ],
      [
        T,
        (trainGroups: TrainGroup[]) => {
          throw new Error(
            `Unexpected TrainGroup types: ${join(', ', map(prop('__typename'), trainGroups))}`,
          );
        },
      ],
    ])(trainGroups);

    setListIfGivenPropsChanged<TrainGroup>(
      trainGroups,
      trainGroupsWithAnyStoredActivity,
      // IF id or the activity object changed on any item, set
      comparisonPaths,
      setTrainGroups,
    );
  }, [filteredTrainGroups, trainGroupsForGroupingId, trainGroups]);
};

/**
 * Find active TrainGroupSingleTrainRuns in trainProps?.trainGroupSingleTrainRunProps?.crudTrainGroups if any are active.
 * Otherwise find active c in rainProps?.trainGroupOnlyTrainFormationProps?.crudTrainGroupOnlyTrainFormations
 * The returned TrainGroups are augmented with activeDateInterval, a property derived from the
 * trainProps or TrainGroup itself indicating what that active date interval is for querying SensorDataPoints.
 * For TrainGroupSingleTrainRuns, it defaults to TrainGroupSingleTrainRuns.dateInterval (the interval of the TrainRun)
 * and for TrainGroupSingleTrainRuns it is based on trainProps.trainGroupOnlyTrainFormationProps.dateInterva, the interval
 * chosen by the user.
 * TODO in the future it will be optionally based on a chart or map area chosen by the user
 * @param loading
 * @param trainProps
 * @param maxCount
 * @param limitToCemitTypename
 * @return
 */
export const useNotLoadingMemoActiveTrainGroups = <T extends TrainGroup = TrainGroup>(
  loading: boolean,
  trainProps: TrainProps,
  maxCount: Perhaps<number> = undefined,
  limitToCemitTypename: Perhaps<CemitTypename> = undefined,
): Perhaps<T[]> => {
  const activeTrainGroups = useNotLoadingMemo(
    loading,
    () => {
      const activeTrainGroups = resolveActiveTrainGroups(
        trainProps,
        limitToCemitTypename,
      );
      return activeTrainGroups;
    },
    [
      trainProps?.trainGroupSingleTrainRunProps?.crudTrainGroups,
      trainProps?.trainGroupOnlyTrainFormationProps?.crudTrainGroupOnlyTrainFormations,
    ] as const,
  );
  const trainGroups = useNotLoadingMemo(
    loading,
    () => {
      return resolveActiveTrainGroupCrudList(trainProps)?.list;
    },
    [
      trainProps?.trainGroupSingleTrainRunProps?.crudTrainGroups,
      trainProps?.trainGroupOnlyTrainFormationProps?.crudTrainGroupOnlyTrainFormations,
    ] as const,
  );
  return useNotLoadingMemo(
    loading,
    (activeTrainGroups) => {
      return limitAndColorActiveTrainGroups(
        loading,
        trainGroups || [],
        activeTrainGroups || [],
        maxCount,
      );
    },
    [activeTrainGroups] as const,
  );
};

/**
 * Create a crud object for managing TrainGroups
 * @param organization
 * @param trainRouteGroupsAreInScope
 * @param trainGroups
 * @param setTrainGroups Setter for trainUserRunIntervals
 * @param trainGroupLookup
 * @param setTrainGroupLookup
 * @param trainRouteOrGroup
 * @param setTrainGroupsByGroupingId Optional updated trainGroupsByGroupingId
 * when we do an updateOrCreate to the new values of this list retrieve it
 * @param setCrudTrainGroups The setter for the created CrudTrainGroup object
 * to store it in state
 * @returns {Object} A Crud object for managing lists
 */
export const useEffectCreateCrudTrainGroups = (
  organization: Perhaps<OrganizationLoaded>,
  trainRouteGroupsAreInScope: Perhaps<boolean>,
  trainGroups: TrainGroup[],
  setTrainGroups: Perhaps<StateSetter<TrainGroup[]>>,
  setCrudTrainGroups: StateSetter<CrudList<TrainGroup>>,
  trainGroupLookup: Record<string, TrainGroup>,
  setTrainGroupLookup: StateSetter<Record<string, TrainGroup>>,
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup>,
  setTrainGroupsByGroupingId: Perhaps<StateSetter<Perhaps<Record<string, TrainGroup[]>>>>,
): undefined => {
  // Hack to get useEffectCreateCrudTrainGroups dependencies to work when an Organization doesn't have services
  const undefinedTrainRouteGroup: TrainRouteGroup = useMemo(() => {
    return {} as TrainRouteGroup;
  }, []);

  useEffectCreateListCrud<TrainGroup>({
    equality: (incoming: TrainGroup, existing: TrainGroup): boolean => {
      return eqProps('id', incoming, existing);
    },
    additionalOperations: {},
    list: trainGroups,
    setList: setTrainGroups,
    setListCrud: setCrudTrainGroups,
    lookup: trainGroupLookup || indexBy(prop('id'), trainGroups),
    setLookup: setTrainGroupLookup,
    postSetList: ([organization, trainRouteOrGroup], uniqueList) => {
      if (setTrainGroupsByGroupingId) {
        const _trainRouteOrGroup =
          trainRouteOrGroup === undefinedTrainRouteGroup ? undefined : trainRouteOrGroup;
        postSetList(
          organization,
          _trainRouteOrGroup,
          setTrainGroupsByGroupingId,
          uniqueList,
        );
      }
    },
    merge: mergeTrainGroup,
    // TODO Using noTrainGroup here since undefined tells useEffectCreateListCrud that loading is not ready
    additionalDependencies: [
      organization,
      trainRouteGroupsAreInScope ? trainRouteOrGroup : undefinedTrainRouteGroup,
    ],
  });
};

/**
 * Sets the 'loading' flag on all trainGroups in updatedTrainRunIdsRequestedWithSensorDataPoints.
 * if there is something that needs to load based on trainRunDataToLoad.
 * If there is anything to load,
 * it calls crudTrainGroups.updateOrCreateAll with the added loading flag and then
 * iterates through trainRunDataToLoad and calls trainApiTrainRunWithSensorDataPoints on each, which calls the
 * API
 * @param loading
 * @param organization
 * @param trainRouteOrGroup
 * @param needsLoadingSensorDataTrainGroups
 * @param loadedSensorDataTrainGroups
 * @param crudTrainGroups
 * @param setLoadingSensorDataTrainGroups
 * @param setLoadedSensorDataTrainGroups
 */
export const useEffectQueryAndResolveSensorDataOfTrainGroups = (
  loading: boolean,
  organization: OrganizationLoaded,
  trainRouteOrGroup: TrainRouteOrGroup,
  needsLoadingSensorDataTrainGroups: Perhaps<SensorDataTrainGroup[]>,
  crudTrainGroups: CrudList<TrainGroup>,
  setLoadingSensorDataTrainGroups: StateSetter<SensorDataTrainGroup[]>,
  setLoadedSensorDataTrainGroups: StateSetter<TrainGroup[]>,
) => {
  // Find LoadingSensorDataTrainGroups that need to load data
  useNotLoadingEffect(
    loading,
    (
      needsLoadingSensorDataTrainGroups,
      crudTrainGroups,
      organization,
      trainRouteOrGroup,
    ): void => {
      if (
        organization.serviceLines &&
        doActiveTrainGroupsHaveTrainRuns(crudTrainGroups.list)
      ) {
        // Do not update until the TrainRoute and TrainGroups are synced
        const trainRouteIds = map(prop('id'), trainRouteOrGroup.trainRoutes);
        const mismatchingTrainRuns = filter(
          (trainGroup) =>
            !includes(trainGroup.singleTrainRun.trainRoute.id, trainRouteIds),
          crudTrainGroups.list,
        );
        if (length(mismatchingTrainRuns)) {
          return [];
        }
      }

      // Only load instances that are not already loading and are not complete
      // Since we are in an effect, this prevents constant reloading
      const needsQueryingSensorDataTrainGroups =
        maybeUpdateStatsToNeedsToLoadSensorDataTrainGroups(
          'loadingStatus',
          needsLoadingSensorDataTrainGroups,
          setLoadingSensorDataTrainGroups,
          crudTrainGroups,
        );
      if (length(needsQueryingSensorDataTrainGroups)) {
        forEach((needsQueryingSensorDataTrainGroup: SensorDataTrainGroup) => {
          console.debug(
            `For ${needsQueryingSensorDataTrainGroup.trainGroup.localizedName(organization.timezoneStr)}, updated SensorData loading status to: ${dumpLoadingStatus(needsQueryingSensorDataTrainGroup.loadingStatus)}`,
          );
        }, needsQueryingSensorDataTrainGroups);
      }

      // Request data for each TrainGroup in parallel
      forEach((sensorDataTrainGroup: SensorDataTrainGroup) => {
        const dateInterval = onlyOneValueOrThrow(
          sensorDataTrainGroup.sensorDataDateIntervals,
        );
        console.debug(
          `Query the following DateIntervals: ${dumpDateInterval(dateInterval, organization.timezoneStr)}`,
        );
        queryApiForTrainGroupWithSensorPoints(
          organization,
          sensorDataTrainGroup,
          setLoadingSensorDataTrainGroups,
          setLoadedSensorDataTrainGroups,
          dateInterval,
        );
      }, needsQueryingSensorDataTrainGroups);
    },
    [
      needsLoadingSensorDataTrainGroups,
      crudTrainGroups,
      organization,
      trainRouteOrGroup,
    ] as const,
  );
};

/**
 * Checks for items in loadingSensorDataTrainGroups that are not loading but not complete.
 * Any found are marked loading and setSensorDataLoadingTrainGroup updates the list
 * the sensorDataTrainGroups that need to load are returned
 * @param loadingStatusProperty
 * @param needsLoadingSensorDataTrainGroups
 * @param setLoadingSensorDataTrainGroups
 * @param crudTrainGroups
 */
export const maybeUpdateStatsToNeedsToLoadSensorDataTrainGroups = <
  T extends SensorDataTrainGroup,
>(
  loadingStatusProperty: keyof T,
  needsLoadingSensorDataTrainGroups: T[],
  setLoadingSensorDataTrainGroups: StateSetter<T[]>,
  crudTrainGroups: CrudList<TrainGroup>,
): T[] => {
  // Sets the status of each instance to loading, and
  // mirrors that status and adds the sensorDataDateIntervals to loadingSensorDataTrainGroups
  const updatedNeedsLoadingSensorDataTrainGroups = updateIncompletesToLoading<T>(
    loadingStatusProperty,
    needsLoadingSensorDataTrainGroups,
    setLoadingSensorDataTrainGroups,
  );

  // If any TrainGroup in crudTrainGroups.list needs to load, set it's loadingStatus.loading=true
  // and then filter down to those instances
  mirrorLoadingStatusInCrud<T, TrainGroup>(
    loadingStatusProperty,
    updatedNeedsLoadingSensorDataTrainGroups,
    crudTrainGroups,
  );

  return updatedNeedsLoadingSensorDataTrainGroups;
};

export type StorageProps = {
  cacheKey: string;
  groupingCemitTypename: CemitTypename;
  trainGroupCemitTypename: CemitTypename;
  restoreFilter?: (ts: TrainGroupsGroupingCollection<TrainGroup>) => void;
};

/**
 * Stores/Loads TrainGroups in local storage keyed by an id, such as a TrainRoute, or,
 * if in a non TrainRoute scope, by the Organization id
 * @param organizationProps
 * @param trainProps
 * @param storageProps
 * @param restoreFilter Optional function to restore the state of the corresponding filter
 */
export const useStorageTrainGroupsGroupingCollection = <T extends TrainGroupMinimized>(
  organizationProps: OrganizationProps,
  trainProps: TrainProps,
  storageProps: StorageProps,
): [
  Perhaps<TrainGroupsGroupingCollection<T>>,
  StateSetter<Perhaps<TrainGroupsGroupingCollection<T>>>,
] => {
  const {cacheKey, groupingCemitTypename, trainGroupCemitTypename} = storageProps;
  const initialValue = clsOrType<TrainGroupsGroupingCollection<T>>(
    CemitTypename.trainGroupsGroupingCollection,
    {
      groupingCemitTypename,
      trainGroupCemitTypename,
      trainGroupsByGroupings: [],
    },
  );
  return useCustomLocalStorage<TrainGroupsGroupingCollection<T>>(
    // Loading state if we have TrainRoutes that are not ready
    organizationProps.loading ||
      (groupingCemitTypename == CemitTypename.trainRouteGroup &&
        !trainProps.trainRouteGroupProps.trainRouteOrGroup),
    clsOrType<LocalStorageProps<TrainGroupsGroupingCollection>>(
      CemitTypename.localStorageProps,
      {
        localStorageKey: cacheKey,

        // We need all dates and times to be deserialized to Datetimes.
        // Add more keys as needed or use something better than local storage
        parserArgument: ['departureDatetime'],

        /**
         * Converts each TrainGroup to TrainGroupPreloaded
         * @param trainGroupsByGroupingCollection
         */
        serializer: (
          trainGroupsByGroupingCollection: TrainGroupsGroupingCollection<T>,
        ): TrainGroupsGroupingCollection<TrainGroup> => {
          return minimizedStoredTrainGroups<T>(
            groupingCemitTypename,
            trainGroupsByGroupingCollection,
          );
        },
        // Loading state if we have TrainRoutes that are not ready
        loading:
          organizationProps.loading ||
          (groupingCemitTypename == CemitTypename.trainRouteGroup &&
            !trainProps.trainRouteGroupProps.trainRouteOrGroup),
        rehydrate: (
          trainGroupsByGroupingCollection: TrainGroupsGroupingCollection<TrainGroup>,
        ): TrainGroupsGroupingCollection<TrainGroup> => {
          // Convert the object to a class instance with class instance properties deeply
          const trainGroupsByGroupingCollectionDeep = clsOrType(
            trainGroupsByGroupingCollection.__typename,
            trainGroupsByGroupingCollection,
            false,
          );

          // TODO Generalize
          // Set the default WheelGroup on each TrainGroup, since we don't serialize them
          const rehydrated: TrainGroupsGroupingCollection<TrainGroup> = overClassOrType<
            TrainGroupsGroupingCollection<TrainGroup>,
            TrainGroupsGrouping<TrainGroup>[]
          >(
            lensProp('trainGroupsByGroupings'),
            (trainGroupsByGroupings: TrainGroupsGrouping<TrainGroup>[]) => {
              return map((trainGroupsByGrouping: TrainGroupsGrouping<TrainGroup>) => {
                return trainGroupsByGrouping;
              }, trainGroupsByGroupings);
            },
            trainGroupsByGroupingCollectionDeep,
          );
          storageProps.restoreFilter?.(rehydrated);
          return rehydrated;
        },
      },
    ),
    initialValue,
  );
};

/**
 * Extract the distinct datetime-independent departure times from TrainRuns of the filteredTrainGroups and set
 * setAllKnownTrainRunDepartureTimes
 * @param loading
 * @param filterProps
 * @param filteredTrainGroups
 * @param allKnownTrainRunDepartureTimes
 * @param setAllKnownTrainRunDepartureTimes
 */
export const useNotLoadingDistinctTrainGroupDepartureTimes = (
  loading: boolean,
  filterProps: Perhaps<TrainGroupFilterProps>,
  filteredTrainGroups: Perhaps<TrainGroup[]>,
  allKnownTrainRunDepartureTimes: TimeRecurrence[],
  setAllKnownTrainRunDepartureTimes: StateSetter<Perhaps<TimeRecurrence[]>>,
): void => {
  // Get distinct TrainRun departure times if we have TrainRoutes
  useNotLoadingEffect(
    loading || !filterProps || !filteredTrainGroups,
    () => {
      const merged = compose(
        (times: TimeRecurrence[]) => {
          return sortBy(prop('fullTime'), times);
        },
        (times: TimeRecurrence[]) => {
          return uniqBy(prop('fullTime'), times);
        },
        concat(allKnownTrainRunDepartureTimes),
        map((trainRun: TrainRun) => {
          return {
            label: localizedTimeNoTimezone(trainRun.departureDatetime as Date),
            fullTime: localizedTime(trainRun.departureDatetime as Date),
          } as TimeRecurrence;
        }),
      )(trainRuns(filteredTrainGroups!));
      if (!equals(allKnownTrainRunDepartureTimes, merged)) {
        setAllKnownTrainRunDepartureTimes(merged);
      }
    },
    [filteredTrainGroups],
  );
};

/**
 * Extract the TrainGroups of the currently selected TrainRouteOrGroup (or Organization if no TrainRouteGroups exist)
 * by using trainGroupsByGroupingId.
 * This also calls maybeAddPreconfiguredTrainGroupsByRoute if there are preconfigured TrainGroups that
 * must always be shown irrespective of what the user has filtered for, namely reference runs
 * @param loading
 * @param organizationProps
 * @param trainProps
 * @param trainGroupsByGroupingCollection
 */
export const useNotLoadingMemoTrainGroupsForGroupingId = <T extends TrainGroupMinimized>(
  loading: boolean,
  organizationProps: OrganizationProps,
  trainProps: TrainProps,
  trainGroupsByGroupingCollection: TrainGroupsGroupingCollection<T>,
): Perhaps<TrainGroup[]> => {
  const dependencies = [
    organizationProps?.organization?.id,
    trainGroupsByGroupingCollection,
    trainProps.trainRouteGroupProps?.trainRouteOrGroup,
  ] as const;

  return useNotLoadingMemo<TrainGroup[], typeof dependencies>(
    loading,
    (
      organizationId,
      trainGroupsByGroupingCollection,
      trainRouteOrGroup,
    ): TrainGroup[] => {
      // Create a lookup by Organization or TrainRouteGroup id
      const trainGroupsByGroupingId = trainGroupGroupingCollectionToLookup(
        trainGroupsByGroupingCollection,
      );

      if (doesOrganizationHaveServiceLines(organizationProps)) {
        return propOr([], trainRouteOrGroup.id, trainGroupsByGroupingId);
      } else {
        return trainGroupsByGroupingId[organizationId];
      }
    },
    dependencies,
  );
};

const {trainRunIntervalMinimum} = AppSettings;

/**
 * Returns true if 'start' or 'end' values are equal for the two distanceRanges
 * @param startOrEnd
 * @param distanceRange1
 * @param distanceRange2
 * @returns {*}
 */
export const distanceRangesEqualForProp = (
  startOrEnd: StartOrEndPosition,
  distanceRange1: DateInterval,
  distanceRange2: Partial<Interval>,
) => {
  return eqProps(startOrEnd, distanceRange1, distanceRange2);
};

export const computeDistanceRange = (
  trainRouteOrGroupLineFinalizedProps: TrainRouteOrGroupLineFinalizedProps,
) => {
  const {
    spaceGeospatially,
    showLimitedDistanceRange,
    routeDistancesWithOffsetLefts,
    parentWidth,
    offsetDifference,
    itemType,
    trainRouteOrGroup,
  } = trainRouteOrGroupLineFinalizedProps;
  const {trainDistanceInterval} = trainRouteOrGroup;

  return overClassOrType(
    lensProp('distanceRange'),
    ({start, end}) => {
      // Given a value and hte xOffset, computes the bar position
      const offSetResolver = (value, xOffset) => {
        return computedIntervalBarPosition(
          trainRouteOrGroupLineFinalizedProps,
          value,
          xOffset,
        );
      };
      const distanceResolver = resolveDistance(
        routeDistancesWithOffsetLefts,
        trainDistanceInterval,
        spaceGeospatially,
      );

      // We first resolve the offsetLeft position then translate to the distance in meters that we
      // want to store
      const resolveOffsetDistanceForStartOrEnd = (startOrEndValue: number) => {
        return compose(
          (offset: number) => {
            return distanceResolver(offset);
          },
          (startOrEnd) => {
            return offSetResolver(startOrEnd, offsetDifference);
          },
        )(startOrEndValue);
      };

      const resolver: (side: LeftRight, startOrEndValue: number) => number = cond([
        [
          (itemType) =>
            includes(itemType, [
              ItemTypes.TRAIN_RUN_INTERVAL_BAR_MAXIMIZER,
              ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_MAXIMIZER,
              ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_MAXIMIZER,
            ]),
          () =>
            (side: LeftRight, startOrEndValue: number): number => {
              if (
                (equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_MAXIMIZER, itemType) &&
                  equals('right', side)) ||
                (equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_MAXIMIZER, itemType) &&
                  equals('left', side))
              ) {
                // Do nothing to the other side if the left or right side was clicked
                return startOrEndValue;
              }

              const startOrEnd: StartOrEndPosition = {
                left: 'start',
                right: 'end',
              }[side] as StartOrEndPosition;
              const unmaximizedDistanceRange: DistanceRange | Partial<DistanceRange> =
                cond([
                  [
                    // Get the set unmaximizedDistanceRange if it has been stored
                    propOr(false, 'unmaximizedDistanceRange'),
                    ({
                      unmaximizedDistanceRange,
                    }: {
                      unmaximizedDistanceRange: DistanceRange;
                    }) => {
                      return unmaximizedDistanceRange;
                    },
                  ],
                  [
                    // If not, use the previous distanceRange if not maximized
                    ({distanceRange}: {distanceRange: DistanceRange}) => {
                      return !distanceRangesEqualForProp(
                        startOrEnd,
                        distanceRange,
                        trainDistanceInterval.distanceRange,
                      );
                    },
                    ({distanceRange}: {distanceRange: DistanceRange}) => {
                      return distanceRange;
                    },
                  ],
                  [
                    // If maximized, make up a value that is -/+ 500 from the middle of the route
                    T,
                    (): Partial<DistanceRange> => {
                      return {
                        [startOrEnd]: defaultUnmaximizedDistanceRange(
                          startOrEnd,
                          trainDistanceInterval.distanceRange,
                        ),
                      };
                    },
                  ],
                ])(trainDistanceInterval);

              // Toggle the start and ends to maximized/unmaximized independently
              return distanceRangesEqualForProp(
                startOrEnd,
                trainDistanceInterval.distanceRange,
                unmaximizedDistanceRange,
              )
                ? // If the distanceRange equals unmaximizedDistanceRange, we are not maximized and should maximize
                  trainDistanceInterval.maximizedDistanceRange[startOrEnd]
                : // Otherwise restore to the unmaximized values
                  trainDistanceInterval.unmaximizedDistanceRange[startOrEnd];
            },
        ],
        [
          equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER),
          () =>
            (_side: LeftRight, startOrEndValue: number): number => {
              // Offset both start and end
              return resolveOffsetDistanceForStartOrEnd(startOrEndValue);
            },
        ],
        [
          equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER),
          () =>
            (side: LeftRight, startOrEndValue: number): number => {
              // Offset just the start
              return equals('right', side)
                ? startOrEndValue
                : resolveOffsetDistanceForStartOrEnd(startOrEndValue);
            },
        ],
        [
          equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER),
          () => (side, startOrEnd) => {
            // Offset just the end
            return equals('left', side)
              ? startOrEnd
              : resolveOffsetDistanceForStartOrEnd(startOrEnd);
          },
        ],
        [
          T,
          (itemType) => {
            throw new Error(`Unexpected item type: ${itemType}`);
          },
        ],
      ])(itemType);

      // Don't allow start to be within 100 meters of the routeDistance
      const startDistance = Math.min(
        add(-trainRunIntervalMinimum, trainRouteOrGroup.routeDistance),
        resolver('left', start),
      );
      return {
        start: startDistance,
        // Don't allow end to be within 100 meters of start
        end: Math.max(
          add(trainRunIntervalMinimum, startDistance),
          resolver('right', end),
        ),
      };
    },
    trainDistanceInterval,
  );
};

/**
 * If the updated distanceRange is unmaximized, set unmaximizedDistanceRange to it
 * If the updated distanceRange is maximized, set unmaximizedDistanceRange the old distanceRange
 * unless that too is maximized, in which case set unmaximizedDistanceRange to a default
 * @returns {function(*=): *}
 */
const computeUnmaximizedDistanceRange = (
  trainRouteOrGroupLineFinalizedProps: TrainRouteOrGroupLineFinalizedProps,
): DistanceRange => {
  const maximizedDistanceRange =
    trainRouteOrGroupLineFinalizedProps.limitedTrainDistanceInterval.maximizeDistanceRnag;
  return overClassOrType(
    lensProp('unmaximizedDistanceRange'),
    (currentUnmaximizedDistanceRange) => {
      return mapObjIndexed((value: number, startOrEnd: DateIntervalStartOrEnd) => {
        return findMapped(
          (f: (startOrEnd: DateIntervalStartOrEnd) => DistanceRange) => {
            const value = f(startOrEnd);
            // Return undefined if maximized to trainRouteDistanceRange
            return distanceRangesEqualForProp(startOrEnd, maximizedDistanceRange, {
              [startOrEnd]: value,
            })
              ? undefined
              : value;
          },
          [
            // Prefer the new value
            always(value),
            // Else the previous value
            (startOrEnd: DateIntervalStartOrEnd) => {
              return currentUnmaximizedDistanceRange[startOrEnd];
            },
            // Else a default
            (startOrEnd: DateIntervalStartOrEnd) => {
              return defaultUnmaximizedDistanceRange(
                {startOrEnd},
                maximizedDistanceRange,
              );
            },
          ],
        );
      }, trainRouteOrGroupLineFinalizedProps.trainRouteOrGroup.trainDistanceInterval.distanceRange);
    },
    trainRouteOrGroupLineFinalizedProps,
  );
};

/**
 * Updates the TrainGroup or TrainRouteInterval
 */
export const updateTrainGroupTrainDistanceInterval = (
  trainGroup: TrainGroup,
  trainRouteOrGroupLineFinalizedProps: TrainRouteOrGroupLineFinalizedProps,
) => {
  const trainRouteOrGroup = asCemitedClassOrThrow<TrainRouteOrGroupDerived>(
    CemitTypename.trainRouteOrGroupDerived,
    trainGroup.trainRouteOrGroup,
  );
  // When we finish dropping, update the trainDistanceInterval.distanceRange.start and end properties
  // to match the distances that we convert from offsetDifference--the x distance we dragged
  const trainDistanceInterval = trainRouteOrGroup.trainDistanceInterval!;
  const distanceRange = computeDistanceRange({
    ...trainRouteOrGroupLineFinalizedProps,
    trainDistanceInterval,
  });
  // After the TrainRunInterval is moved, store an unmaximizedDistanceRange to mark the old
  // value if one or both sides have been maximized or store the unmaximizedDistanceRange as the new
  // distanceRange if not maximized
  const unmaximizedDistanceRange = computeUnmaximizedDistanceRange(
    trainRouteOrGroupLineFinalizedProps,
  );
  return mergeTrainDistanceInterval(
    trainDistanceInterval,
    clsOrType<TrainDistanceInterval>(CemitTypename.trainDistanceInterval, {
      distanceRange,
      unmaximizedDistanceRange,
    }),
  );
};

/**
 * Creates a function to maximize/restore the start and/or end of a trainRunInterval distanceRange
 * @props trainProps
 * @props componentProps
 * @returns {Function} A function expecting distanceRangeKeys where distanceRangeKeys can be
 * ['start', 'end'] or one of those values. Maximizes or restores the start and/or end of the distance
 * range to the min 0 or max trainRunInterval.trainRoute.routeDistance based on the values stored in local state
 */
export const useTrainRunOrGroupDistanceIntervalMaximizer = (
  trainProps: TrainDerivedProps,
  trainRouteOrGroupLineProps: TrainRouteOrGroupLineProps,
) => {
  return (distanceRangeKeys: DistanceRangeStartEnd[]) => {
    updateTrainGroupTrainDistanceInterval(
      trainProps.trainGroupSingleTrainRunProps.trainGroup,
      {
        ...trainRouteOrGroupLineProps,
        // No offsetDifference, TRAIN_RUN_INTERVAL_BAR_MAXIMIZER
        // informs the code that it shall maximize or restore the
        // interval to the previous unmaximzed value
        offsetDifference: undefined,
        itemType: cond([
          // Choose the maximizer operation base on if distanceRangeKeys contains 'start', 'end' or both
          [compose(equals(2), length), always(DragItemType.trainRunIntervalBarMaximizer)],
          [
            compose(equals('start'), head),
            always(DragItemType.trainRunIntervalBarLeftMaximizer),
          ],
          [
            compose(equals('end'), head),
            always(DragItemType.trainRunIntervalBarRightMaximizer),
          ],
        ])(distanceRangeKeys),
        showLimitedDistanceRange: undefined,
      },
    );
  };
};

/**
 * Fetch the outside-filter TrainRuns separately after trainRuns. These are queried by TrainRun id
 * If storedTrainRunIds this should give a undefined URL
 * @param loading
 * @param newlyLoadedTrainGroups
 * @param maybeOutsideFilterTrainRunIds
 * @param organization
 * @param trainRunsError
 */
function useSetMaybeOutsideFilterTrainGroups(
  loading: boolean,
  outsideFilterTrainGroups: TrainGroup[],
  newlyLoadedTrainGroups: TrainGroup[],
  maybeOutsideFilterTrainRunIds: string[],
  organization: Perhaps<OrganizationLoaded>,
  trainRunsError: Error,
) {
  // Find outside filter TrainRun ids that have not been loaded with eiter of within or without the filter queries below
  const missingOutsideFilterTrainRunIds = useNotLoadingMemo(
    loading || !newlyLoadedTrainGroups || !maybeOutsideFilterTrainRunIds,
    () => {
      const trainRunIdLookup = indexBy<TrainGroup, string>(
        (trainGroup: TrainGroup) => trainGroup.id as string,
        concat(outsideFilterTrainGroups || [], newlyLoadedTrainGroups || []),
      );
      return itemsNotInLookup(trainRunIdLookup, maybeOutsideFilterTrainRunIds);
    },
    [newlyLoadedTrainGroups, maybeOutsideFilterTrainRunIds],
  );

  // Call only if there are any missingOutsideFilterTrainRunIds left to load
  const {
    data: newlyLoadedOutsideFilterTrainGroups,
    error: outsideFilterTrainRunsError,
  }: {
    data: TrainGroup[];
    error: Error;
  } = useCemitApiSwrResolveData(loading, organization, 'trainRuns', {
    trainGroupIds: length(missingOutsideFilterTrainRunIds || [])
      ? missingOutsideFilterTrainRunIds
      : undefined,
    // This isn't used by the API but by the response to mark the returned TrainGroups as preconfigured so that
    // we can override their trainRoute to match the currently selected TrainRouten
    isPreconfigured: true,
  } as TrainApiTrainRunsRequestProps);

  if (outsideFilterTrainRunsError || trainRunsError) {
    if (newlyLoadedTrainGroups) {
      throw new Error(
        'Error fetching trainRuns. TODO, use a retry here for certain error types',
      );
    }
    if (outsideFilterTrainRunsError) {
      throw new Error(
        'Error fetching outside-filter TrainRuns. TODO, use a retry here for certain error types',
      );
    }
  }
  return {
    missingOutsideFilterTrainRunIds,
    newlyLoadedOutsideFilterTrainGroups,
  };
}

/**
 * Loads all TrainRuns for the current dateInterval and the datetime-independent baseline TrainRuns
 * @param loading
 * @param organization
 * @param organizationHasServiceLines
 * @param trainGroupOnlyFormations
 * @param trainGroupFilterProps The props to filter by
 * @param trainGroupFilterProps.dateInterval The datetime range
 * can currently be certain days of the week or departureTimes
 * @param trainGroupFilterProps.trainRoute The TrainRoute or TrainRouteGroup we want TrainRuns for
 * @param trainRoutes A TrainRoute from here is assigned to TrainRun.trainRoute based
 * on trainRun.journeyPattern.trainRun. The latter lacks derived data about the the trainRoute, like trackData
 * @param filteredTrainGroups
 * @param setFilteredTrainGroups
 * we are in a stable state
 * store in setOutsideFilterTrainRuns. These are loaded after the filter trainRuns if any don't overlap
 */
export const useConfiguredApiForTrainGroups = (
  loading: boolean,
  organization: PerhapsIfLoading<typeof loading, OrganizationMinimized>,
  trainGroupOnlyFormations: PerhapsIfLoading<
    typeof loading,
    TrainGroupOnlyTrainFormation[]
  >,
  trainGroupFilterProps: PerhapsIfLoading<typeof loading, TrainGroupFilterProps>,
  trainRoutes: PerhapsIfLoading<typeof loading, TrainRoute[]>,
  filteredTrainGroups: PerhapsIfLoading<typeof loading, TrainGroup[]>,
  setFilteredTrainGroups: StateSetter<TrainGroup[]>,
) => {
  const trainApiTrainRunsRequestProps = clsOrType<TrainApiTrainRunsRequestProps>(
    CemitTypename.trainApiTrainRunsRequestProps,
    {
      trainRouteGroupProps: trainGroupFilterProps?.trainRoute
        ? clsOrType<TrainRouteOrGroupRequestProps>(
            CemitTypename.trainRouteOrGroupRequestProps,
            {
              trainRouteOrGroup: trainGroupFilterProps?.trainRoute,
            } as TrainRouteOrGroupRequestProps,
          )
        : undefined,
      dateInterval: trainGroupFilterProps?.dateInterval,
      trainFormations: trainGroupFilterProps?.trainFormations,
      dateRecurrences: trainGroupFilterProps?.dateRecurrences,
    },
  );

  // Fetch trainGroup data based on trainApiTrainRunsRequestProps
  const {data: newlyLoadedTrainGroups, error: trainRunsError} =
    useCemitApiSwrResolveData<TrainApiTrainRunsRoute>(
      loading,
      organization,
      'trainRuns',
      trainApiTrainRunsRequestProps,
    );

  // When TrainRuns are loaded, calls setFilteredTrainGroups
  // If there is no TrainRouteOrGroup, calls setFilteredTrainGroups with trainGroupFormations
  useMergeThenSetFilterTrainGroups(
    loading,
    organization,
    trainGroupFilterProps?.trainRoute,
    trainRoutes,
    trainGroupOnlyFormations,
    filteredTrainGroups,
    setFilteredTrainGroups,
    newlyLoadedTrainGroups,
  );
};

/**
 * Outside the filter TrainGroups exist when the organization has reference runs that need to show no matter
 * how they have filtered, or that match the TrainRoute filter but not dates and other filters
 * @param loading
 * @param organization
 * @param trainRouteOrGroup
 * @param trainRoutes
 * @param trainGroupFormations
 * @param filteredTrainGroups
 * @param setFilteredTrainGroups
 * @param newlyLoadedTrainGroups
 */
const useMergeThenSetFilterTrainGroups = (
  loading: boolean,
  organization: PerhapsIfLoading<typeof loading, OrganizationLoaded>,
  trainRouteOrGroup: PerhapsIfLoading<typeof loading, TrainRoute>,
  trainRoutes: PerhapsIfLoading<typeof loading, TrainRoute[]>,
  trainGroupFormations: TrainGroupOnlyTrainFormation[],
  filteredTrainGroups: Perhaps<TrainGroup[]>,
  setFilteredTrainGroups: StateSetter<TrainGroup[]>,
  newlyLoadedTrainGroups: TrainGroup[],
): void => {
  const dependencies = [
    organization,
    newlyLoadedTrainGroups,
    trainRouteOrGroup,
    trainGroupFormations,
    filteredTrainGroups,
  ] as const;

  useNotLoadingEffect<typeof dependencies>(
    loading,
    (
      organization,
      newlyLoadedTrainGroups,
      trainRouteOrGroup,
      trainGroupFormations,
      filteredTrainGroups,
    ) => {
      if (!organization.serviceLines) {
        // If we don't have a TrainRouteOrGroup, use the TrainGroupOnlyTrainFormations as TrainGroups
        if (!equals(filteredTrainGroups, trainGroupFormations)) {
          setFilteredTrainGroups(trainGroupFormations);
        }
      }
      // If we have TrainRoutes and we have loaded data, set the state to it or quit if there is no new loaded data
      else if (
        // Defined indicates that loading has finished
        newlyLoadedTrainGroups
      ) {
        // Combine the outside-filter TrainRuns with the others
        const allLoadedTrainGroupsOrdered: TrainGroup[] = compose<
          [TrainGroup[]],
          TrainGroup[],
          TrainGroup[],
          TrainGroup[]
        >(
          sortBy(prop('departureDatetime')),
          uniqBy(prop('id')),
          (newlyLoadedTrainGroups: TrainGroup[]) => {
            return [
              // Take newly loaded and already loaded filter TrainGroups
              ...newlyLoadedTrainGroups,
              ...(filteredTrainGroups || []),
            ];
          },
        )(newlyLoadedTrainGroups);

        // If our filteredTrainGroups has already been set to the existing and newly loaded TrainGroups,
        // the state is stable and we can stop setting it
        if (idListsEqual(filteredTrainGroups, allLoadedTrainGroupsOrdered)) {
          return;
        }

        // Get the full version of the TrainRoutes that are in the current TrainRouteOrGroup
        const eligibleTrainRouteById = indexBy(prop('id'), trainRouteOrGroup.trainRoutes);
        const eligibleTrainRoutes: TrainRoute[] = filter(
          (trainRoute) => propOr(false, trainRoute.id, eligibleTrainRouteById),
          trainRoutes,
        );

        // Add a short-cut to trainRoute. Note that this is not what we filtered on, a TrainRoute or TrainRouteGroup,
        // rather it is the TrainRoute of the TrainRun
        const orderedTrainGroupsWithRoute: TrainGroup[] = compact(
          map((trainGroup: TrainGroup) => {
            // Find the matching TrainRoute, either of the default trainRun.journeyPattern.trainRoute or the overridden
            // forceTrainRouteId
            const trainRoute: Perhaps<TrainRoute> = find((trainRoute: TrainRoute) => {
              return idsEqual(trainRoute, trainGroup.trainRouteOrGroup!);
            }, eligibleTrainRoutes);

            if (!trainRoute) {
              // Indicates that the user changed the TrainRoute whilst one or more of orderedTrainRuns was loading
              // Abandon the loading
              return undefined;
            }
            return {
              ...trainGroup,
              trainRouteOrGroup: trainRoute,
            } as TrainGroup;
          }, allLoadedTrainGroupsOrdered),
        );

        // The user changed TrainRoute during loading of TrainRuns, give up and wait for the TrainRuns of the
        // newly selected TrainRoute
        if (any(isNil, orderedTrainGroupsWithRoute)) {
          return;
        }
        setFilteredTrainGroups(orderedTrainGroupsWithRoute);
      }
    },
    dependencies,
  );
};

export interface UseApiForAvailableDatesProps {
  organization: Perhaps<OrganizationLoaded>;

  // Available dates are queries based on a TrainRouteOrGroup or TrainFormation
  trainRouteOrGroup?: Perhaps<TrainRouteOrGroup>;
  trainFormation?: Perhaps<TrainFormation>;

  availableDateRanges: DateInterval[];
  setAvailableDateIntervals: StateSetter<DateInterval[]>;
  parentCemitFilter: CemitFilter;
  cemitFilterWithDateIntervals: CemitFilter;
  setCemitFilterWithDateIntervals: StateSetter<CemitFilter>;
  incomingAvailableDateIntervals: DateInterval[];
  dateIntervalDescription: DateIntervalDescription;
}

/**
 * Filters to only allow trainGroups where trainGroup.trainFormation.disableSensorData is not true
 * so we don't wait for sensor data from trainGroups that can't load it
 * @param loading
 * @param trainGroups
 */
export const useNotLoadingActiveSensorDataEligibleTrainGroups = (
  loading: boolean,
  trainGroups: TrainGroup[],
) => {
  return useNotLoadingMemo(loading, activeSensorDataEligibleTrainGroups, [trainGroups]);
};

/**
 * Get the trainProps.trainGroupSingleTrainRunProps.crudTrainGroups or trainProps.trainGroupOnlyTrainFormationProps.crudTrainGroupOnlyTrainFormations
 * depending on the type of trainProps.trainGroupActivityProps.activeTrainGroups
 * @param loading
 * @param trainProps
 */
export const useNotLoadingCrudTrainGroupsOfActiveScope = (
  loading: boolean,
  trainProps: TrainProps,
): CrudList<TrainGroup> | CrudList<TrainGroupOnlyTrainFormation> => {
  return useNotLoadingMemo(
    loading,
    (activeTrainGroups, crudTrainGroups, crudTrainGroupOnlyTrainFormations) => {
      return doActiveTrainGroupsHaveTrainRuns(activeTrainGroups)
        ? crudTrainGroups
        : crudTrainGroupOnlyTrainFormations!;
    },
    [
      trainProps.trainGroupActivityProps.activeTrainGroups,
      trainProps.trainGroupSingleTrainRunProps.crudTrainGroups,
      trainProps.trainGroupOnlyTrainFormationProps.crudTrainGroupOnlyTrainFormations,
    ],
  );
};
