import {compact} from '@rescapes/ramda';
import {
  add,
  always,
  equals,
  find,
  groupBy,
  head,
  ifElse,
  length,
  lt,
  map,
  prop,
  propOr,
  reduce,
  subtract,
  values,
  when,
} from 'ramda';
import {TrainRun} from '../../../types/trainRuns/trainRun';
import {Rmse} from '../../../types/derived/rmse';
import {hasNonZeroLength} from '../../../utils/functional/functionalUtils.ts';
import {AppSettings} from '../../../config/appConfigs/appSettings.ts';

const {gravityAcceleration} = AppSettings;

export const calculateMeanXYZAcceleration = (properties: Record<string, number>) => {
  const values: number[] = map<string, number>(
    (attr: string) => {
      // @ts-ignore Useless error message
      return when<number, number>(
        always<boolean>(equals('accZMean', attr)),
        // @ts-ignore
        subtract(gravityAcceleration),
      )(prop(attr, properties));
    },
    ['accXMean', 'accYMean', 'accZMean'],
  );
  return ifElse(
    (values: number[]) => lt(1, length(values)),
    (values: number[]) => {
      return Math.sqrt(
        reduce(
          (accum: number, value: number) => {
            return add(accum, Math.pow(value, 2));
          },
          0,
          values,
        ),
      );
    },
    head,
  )(values);
};

export const calculateRmse = (
  trainRun: TrainRun,
  {
    properties,
  }: {
    properties: {time: Date};
  },
): (number | undefined)[] => {
  const rmses = find(hasNonZeroLength, values(trainRun.rmses));
  const date = properties.time;

  const frequencyNameLookup = {rmse2hz: 'class_y_2hz', rmse4hz: 'class_y_4hz'};
  const rmseByFrequencyName = groupBy(prop('frequency'), rmses || []);

  // For each frequency, find an Rmse instance that matches the imupoint timestamp, if any
  return compact(
    map((frequenceyName) => {
      const matchingRmse =
        rmses &&
        find(
          (rmse) => {
            return isWithinInterval(date, {start: rmse.startTime, end: rmse.finishTime});
          },
          propOr([], frequenceyName, rmseByFrequencyName),
        );
      return matchingRmse?.classification;
    }, frequencyNameLookup),
  );
};

export const calculateRmseHighestValues = (
  trainRun: TrainRun,
): (number | undefined)[] => {
  const rmses: Rmse[] | undefined = find(length, values(trainRun.rmses || {}));
  const rmsesByFrequencyName = groupBy(prop('frequency'), rmses || []);

  // For each frequency, find the highes rmse level
  return compact(
    map((rmses) => {
      return length(rmses)
        ? Math.max(...map((rmse) => rmse.classification, rmses))
        : undefined;
    }, rmsesByFrequencyName),
  );
};
