import { Rectangle } from 'recharts';
import { useTheme } from '@mui/styles';
import React from 'react';

const CustomCursor = (props: any) => {
  const { points, height } = props;
  const { x, y } = points[0];
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Rectangle {...{
      fill: color,
      stroke: color,
      x,
      y,
      width: 2,
      height
    }}
    />
  );
};

export default CustomCursor;