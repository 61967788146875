import {Stack} from '@mui/material';
import React, {useMemo} from 'react';
import {addIndex, join, map} from 'ramda';
import SensorDataPointDescription, {
  SensorDataPointDescriptionProps,
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainRunningCharacteristicsComponents/SensorDataPointDescription.tsx';
import {ChartPayloadItemComplete} from 'types/dataVisualizations/chartPayloadItem';
import {ChartDataConfig} from 'types/dataVisualizations/chartDataConfig.ts';
import {ScheduledStopPoint} from 'types/stops/scheduledStopPoint';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

interface SensorDataPointStatsComponentProps extends CemitComponentProps {
  activeTrainGroups: TrainGroup[];
  // There is one chartPayloadItems per activeTrainGroup. If the activeTrainGroup had no data
  // to hover over, the spot will be undefined for that TrainGroup
  chartPayloadItems: Perhaps<ChartPayloadItemComplete>[];
  dataPathsConfigs: ChartDataConfig[];
  referenceStopLookup: Record<string, ScheduledStopPoint>;
  otherDisplayDataPaths: ChartDataConfig[];
}

/**
 * Displays each componentProps.chartPayloadItems, where each represents a different TrainGroup and the information
 * is related to where the user is hovering on a chart or the map
 * @returns {JSX.Element}
 * @constructor
 */
const SensorDataPointStatsComponent = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<SensorDataPointStatsComponentProps>): JSX.Element => {
  const {chartPayloadItems, dataPathsConfigs, otherDisplayDataPaths} = componentProps;

  const sensorDataPointDescriptions = useMemo(() => {
    return addIndex(map)(
      (chartPayloadItem: Perhaps<ChartPayloadItemComplete>, index: number) => {
        // Get the featureIndex so we always have a unique combination of TrainGroup and Feature for the key
        if (!chartPayloadItem) {
          return (
            <Stack
              key={index}
              {...{
                sx: [
                  {
                    flexGrow: 0,
                  },
                  ...toArrayIfNot(componentProps.sx),
                ],
              }}
            ></Stack>
          );
        }
        const featureIndex: number =
          chartPayloadItem.dataFeatureCollection.features.indexOf(
            chartPayloadItem.payload,
          );
        return (
          <SensorDataPointDescription
            key={join(', ', [chartPayloadItem.trainGroup.id, featureIndex])}
            {...{
              ...{
                appProps,
                organizationProps,
                trainProps,
                componentProps: {
                  dataPathsConfigs,
                  otherDisplayDataPaths,
                  chartPayloadItem,
                  sx: [
                    {
                      flexGrow: 0,
                    },
                    ...toArrayIfNot(componentProps.sx),
                  ],
                } as SensorDataPointDescriptionProps,
              },
            }}
          />
        );
      },
      chartPayloadItems,
    );
  }, [chartPayloadItems]);

  return (
    <Stack
      {...{
        direction: 'row',
        spacing: 1,
        sx: {
          // Compensate for the buttons to the right of Train titles and RideComfort
          // TODO do this a cleaner way
          maxWidth: 'calc(100% - 45px)',
          flex: 1,
        },
      }}
    >
      {sensorDataPointDescriptions}
    </Stack>
  );
};

export default SensorDataPointStatsComponent;
