import React, {JSX, useEffect} from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import ReactDom from 'react-dom/client';
import CemitApp from 'components/apps/cemitAppComponents/CemitApp.tsx';
import TrainSWRContainer from 'pages/trainApp/api/TrainSWRContainer.tsx';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3/index.js';
import {I18nextProvider, useTranslation} from 'react-i18next';
import {enGB} from 'date-fns/locale/en-GB';

import ErrorBoundaryFallback from './ErrorBoundaryFallback.tsx';
import Authentication from './pages/auth/Authentication.tsx';
import UserContextProvider from 'pages/auth/UserContextProvider.tsx';
import AppAccessNavigator from 'pages/auth/AppAccessNavigator.tsx';
import ProfilePage from './pages/profile/ProfilePage.tsx';
import HomePage from './pages/profile/HomePage.tsx';
import resources from 'translations/resources.ts';
import {Box} from '@mui/material';
import {sentryInit} from 'monitoring/sentry.ts';
import {cemitLoggingAppInit} from 'monitoring/cemitLogging.ts';
import RequireAccess from 'pages/protectedRoute/RequireAccess.tsx';
import CemitAppDependency from 'async/cemitAppAsync/CemitAppDependency.tsx';
import {DndWrapper} from 'utils/dragAndDrop/DnDWrapper.tsx';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import {ErrorBoundary} from 'react-error-boundary';
import {AppSettings} from './config/appConfigs/appSettings.ts';
import {setUseWhatChange} from '@simbathesailor/use-what-changed';
import LegacyAppLink from 'components/apps/cemitAppComponents/LegacyAppLink.tsx';
import {ApplicationType} from 'pages/auth/utils.ts';
import {includes} from 'ramda';
import {AppAccess} from 'pages/auth/AppAccess.tsx';
import TrafficSimAppContainer from 'pages/trafficsim/trafficSimComponents/TrafficSimAppContainer.tsx';
import CemitDependency from 'async/cemitAppAsync/CemitDependency.tsx';
import {envVarIsTrue} from 'utils/functional/functionalUtils.ts';
import {
  WrappedThemeProvider,
  wrapTitledApplicationInAppAndOrgProps,
} from 'appIndexUtils.tsx';
import TrainAppRoute from 'TrainAppRoute.tsx';
import SoftprioAppContainer from 'pages/softPrio/SoftprioAppContainer.tsx';
import {i18nTask} from 'translations/i18n.js';
import VisionAppDependencyUnits from 'async/visionAppAsync/visionAppDependencies/VisionAppDependencyUnits.tsx';

const {
  appsPath,
}: {
  appsPath: string;
} = AppSettings;

if (AppSettings.featureSentry) {
  sentryInit();
}
Error.stackTraceLimit = 100;
if (DEBUG) {
  cemitLoggingAppInit();
}

// language support
type AppIndexProps = {
  i18n: any;
};

// Only enabled useWhatChanged in development
setUseWhatChange({active: includes(process.env.NODE_ENV, ['local', 'development'])});

const AppIndex = ({i18n}: AppIndexProps) => {
  const abortController = new AbortController();
  useEffect(() => {
    // TODO I don't know what the point of this is https://developer.mozilla.org/en-US/docs/Web/API/AbortController
    return () => abortController.abort();
  }, []);

  return (
    <TrainSWRContainer abortController={abortController}>
      {/* Provides the themes for unauthenticated and authenticated Routes */}
      <CemitDependency>
        {/* Context for the user authentication state, should be placed high up as it rarely change after being authenticated */}
        <UserContextProvider>
          {/* Authenticate the user from the token and set userState, components further down will determine authorized access */}
          <Authentication>
            <WrappedThemeProvider>
              <I18nextProvider i18n={i18n}>
                <Routing />
              </I18nextProvider>
            </WrappedThemeProvider>
          </Authentication>
        </UserContextProvider>
      </CemitDependency>
    </TrainSWRContainer>
  );
};

/**
 * Routing to all possible internal and external (legacy directory) applications
 * All applications are behind authentication provided by <RequireAccess...>
 * @constructor
 */
const Routing = (): JSX.Element => {
  return (
    <ErrorBoundary {...{FallbackComponent: ErrorBoundaryFallback}}>
      {/* @ts-ignore */}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        {/* I believe that the DnDWrapper needs this div */}
        <div id="default">
          {/* Adds Drag and Drop support */}
          <DndWrapper id="default">
            <AppAccess>
              <Routes>
                <Route element={<AppTopLayout />}>
                  <Route
                    path="home"
                    element={
                      <CemitAppDependency>
                        <HomePage />
                      </CemitAppDependency>
                    }
                  />

                  <Route path={`${appsPath}`}>
                    {/* A small hack until the auth api can be fixed, this is the path that is set in the emails.
                      When used it should redirect to the landing page.
                   */}
                    <Route
                      path="train/wheel_temperature"
                      element={<Navigate to={AppSettings.landingPage} />}
                    />

                    {envVarIsTrue(process.env.REACT_FEATURE_TRAIN) && (
                      <Route
                        element={<RequireAccess {...{app: ApplicationType.Train}} />}
                      >
                        <Route
                          {...{
                            path: 'index/*',
                            element: <TrainAppRoute />,
                          }}
                        />
                        <Route
                          {...{
                            path: 'train/*',
                            element: <TrainAppRoute />,
                          }}
                        />
                      </Route>
                    )}

                    {envVarIsTrue(process.env.REACT_FEATURE_TRACK_SIM) && (
                      <Route element={<RequireAccess app={ApplicationType.TrackSim} />}>
                        <Route
                          path="trafficsim/overview"
                          element={wrapTitledApplicationInAppAndOrgProps(
                            'TrafficSim',
                            TrafficSimAppContainer,
                          )}
                        />
                      </Route>
                    )}

                    {envVarIsTrue(process.env.REACT_FEATURE_SOFTPRIO) && (
                      <Route element={<RequireAccess app={ApplicationType.TrackSim} />}>
                        <Route
                          path="softprio/overview"
                          element={wrapTitledApplicationInAppAndOrgProps(
                            'Softprio',
                            SoftprioAppContainer,
                          )}
                        />
                      </Route>
                    )}

                    {envVarIsTrue(process.env.REACT_FEATURE_VISION) && (
                      <Route element={<RequireAccess app={ApplicationType.Vision} />}>
                        <Route
                          path="vision/overview"
                          element={wrapTitledApplicationInAppAndOrgProps(
                            'Vision',
                            VisionAppDependencyUnits,
                          )}
                        />
                      </Route>
                    )}

                    {envVarIsTrue(process.env.REACT_FEATURE_TRACK) && (
                      <Route element={<RequireAccess app={ApplicationType.Track} />}>
                        <Route
                          path="track/overview"
                          element={
                            <CemitAppDependency appTitleKey="track">
                              <LegacyAppLink app="track" />
                            </CemitAppDependency>
                          }
                        />
                      </Route>
                    )}

                    {envVarIsTrue(process.env.REACT_FEATURE_CATENARY) && (
                      <Route element={<RequireAccess app={ApplicationType.Catenary} />}>
                        <Route
                          path="catenary/overview"
                          element={
                            <CemitAppDependency appTitleKey="catenary">
                              <LegacyAppLink app="catenary" />
                            </CemitAppDependency>
                          }
                        />
                      </Route>
                    )}

                    {envVarIsTrue(process.env.REACT_FEATURE_DEPOTS) && (
                      <Route element={<RequireAccess app={ApplicationType.Depot} />}>
                        <Route
                          path="depots/overview"
                          element={
                            <CemitAppDependency appTitleKey="depots">
                              <LegacyAppLink app="depot" />
                            </CemitAppDependency>
                          }
                        />
                      </Route>
                    )}

                    {envVarIsTrue(process.env.REACT_FEATURE_ROUTE_PLANNER) && (
                      <Route
                        element={<RequireAccess app={ApplicationType.RoutePlanner} />}
                      >
                        <Route
                          path="grps/overview"
                          element={
                            <CemitAppDependency appTitleKey="grps">
                              <LegacyAppLink app="routePlanner" />
                            </CemitAppDependency>
                          }
                        />
                      </Route>
                    )}
                  </Route>

                  <Route
                    path="profile"
                    element={
                      <CemitAppDependency appTitleKey="userPrrofile">
                        <ProfilePage />
                      </CemitAppDependency>
                    }
                  />

                  {/* The top level index uses AppAccessNavigator, it checks the user application access
                    and navigates to the first application the user has access to. */}
                  <Route index element={<AppAccessNavigator />} />

                  {/* If we don't match any known route use UnknownRoutePage to give information to the user
                    while still having the main menus available. */}
                  <Route
                    path="*"
                    element={
                      <CemitAppDependency>
                        <UnknownRoutePage />
                      </CemitAppDependency>
                    }
                  />
                </Route>
              </Routes>
            </AppAccess>
          </DndWrapper>
        </div>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

/**
 * Renders the main application layout and lets sub-routes render using Outlet.
 */
const AppTopLayout = () => {
  return (
    <CemitApp>
      {/* Catches errors from sub applications, allowing the user to choose another menu element */}
      <ErrorBoundary {...{FallbackComponent: ErrorBoundaryFallback}}>
        <Outlet />
      </ErrorBoundary>
    </CemitApp>
  );
};

/**
 * Displays a friendly message if an unknown route is used.
 */
const UnknownRoutePage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        background: '#22292e',
        color: 'white',
        button: {
          margin: '20px',
          border: '#f6c90e 1px solid',
          borderRadius: '5px',
          padding: '10px',
          color: '#f6c90e',
        },
      }}
    >
      <p>
        {t('brokenLink')}
        <br />
        {t('brokenLinkFix')}
      </p>
      <button onClick={() => navigate('/home')}>{t('cemitVisualizerHome')}</button>{' '}
    </Box>
  );
};

function renderApp(i18n: i18n) {
  const appElement = document.getElementById('app');
  if (appElement === null) {
    if (console?.log) {
      console.log("Failed setting up application, unable to find element with id 'app'");
    }
    return;
  }
  const root = ReactDom.createRoot(appElement);
  // const router = createBrowserRouter();

  root.render(
    <BrowserRouter>
      <AppIndex i18n={i18n} />
    </BrowserRouter>,
  );
}

// When the i18n is initialized, pass it to render
i18nTask(resources)
  .run()
  .promise()
  .then((i18n: i18n) => {
    renderApp(i18n);
  });

if ('serviceWorker' in navigator) {
  if (AppSettings.featureServiceWorker) {
    if (DEBUG) {
      console.log('ServiceWorker installation started');
    }
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(new URL('./sw.ts', import.meta.url))
        .then((registration) => {
          if (DEBUG && console && console.log) {
            console.log('ServiceWorker registered: ', registration);
          }
        })
        .catch((registrationError) => {
          if (DEBUG && console && console.log) {
            console.log('ServiceWorker registration failed: ', registrationError);
          }
        });
    });
  } else {
    let wasRegistered = false;
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
        wasRegistered = true;
      }
    });
    if (DEBUG && console && console.log && wasRegistered) {
      console.log('ServiceWorker removed');
    }
  }
}
