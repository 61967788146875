import React, {useMemo} from 'react';
import SensorDataPointStatsComponent from 'components/apps/trainAppComponents/trainAppBoardComponents/trainRunningCharacteristicsComponents/SensorDataPointStatsComponent.tsx';
import {
  ChartPayloadItem,
  ChartPayloadItemComplete,
  ChartPayloadItemDerived,
} from 'types/dataVisualizations/chartPayloadItem';
import {ChartDataConfig} from 'types/dataVisualizations/chartDataConfig.ts';
import {ScheduledStopPoint} from 'types/stops/scheduledStopPoint';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {indexBy, keys, map} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

export interface SensorDataPointStatsComponentContainerProps extends CemitComponentProps {
  activeTrainGroups: Perhaps<TrainGroup[]>;
  chartPayloadItems: ChartPayloadItemDerived[];
  dataPathsConfigs: ChartDataConfig[];
  referenceStopLookup: Record<string, ScheduledStopPoint>;
  otherDisplayDataPaths: ChartDataConfig[];
}

/**
 * Container for SensorDataPointStatsComponent.
 * Sets up the componentProps.chartPayload items for display
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @returns {JSX.Element}
 * @constructor
 */
const SensorDataPointStatsComponentContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<SensorDataPointStatsComponentContainerProps>): JSX.Element => {
  const {
    chartPayloadItems,
    dataPathsConfigs,
    referenceStopLookup,
    otherDisplayDataPaths,
  } = componentProps;
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);
  const activeTrainGroups = trainProps.trainGroupActivityProps?.activeTrainGroups;
  const chartPayloadItemsLookup = indexBy(
    (chartPayloadItem: ChartPayloadItem) => chartPayloadItem.trainGroup.id,
    chartPayloadItems,
  );
  const completedChartPayloadItems: Perhaps<ChartPayloadItemComplete>[] =
    useMemo((): Perhaps<ChartPayloadItemComplete>[] => {
      return map((activeTrainGroup: TrainGroup): Perhaps<ChartPayloadItemComplete> => {
        const chartPayloadItem: Perhaps<ChartPayloadItemDerived> =
          chartPayloadItemsLookup[activeTrainGroup.id];
        if (!chartPayloadItem) {
          // If there is no data for this TrainGroup, return undefined as a placeholder
          return undefined;
        }
        // Resolve the Railway of this feature to resolve the reference stop
        // TODO We should be never missing this attribute, but if we are just assume the that all railways have
        // the same reference stop for now
        const referenceStop: Perhaps<ScheduledStopPoint> =
          organizationHasServiceLines && referenceStopLookup
            ? referenceStopLookup[
                chartPayloadItem.payload.properties!.railway ||
                  keys(referenceStopLookup)[0]
              ]
            : undefined;
        const timePropertyPath = `properties.${chartPayloadItem.dataFeatureCollection.dataFeatureSet.timePath}`;
        const distancePropertyPath = `properties.${chartPayloadItem.dataFeatureCollection.dataFeatureSet.distancePath}`;
        const railwayDistancePropertyPath = `properties.${chartPayloadItem.dataFeatureCollection.dataFeatureSet.railwayDistancePath}`;

        return clsOrType<ChartPayloadItemComplete>(
          CemitTypename.chartPayloadItemComplete,
          {
            ...chartPayloadItem,
            referenceStop,
            timePropertyPath,
            distancePropertyPath,
            railwayDistancePropertyPath,
          },
        );
      }, activeTrainGroups!);
    }, [activeTrainGroups, chartPayloadItems]);

  return (
    <SensorDataPointStatsComponent
      key="sensorDataPointStatsComponent"
      {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: {
          activeTrainGroups: componentProps.activeTrainGroups,
          chartPayloadItems: completedChartPayloadItems,
          dataPathsConfigs,
          referenceStopLookup,
          otherDisplayDataPaths,
          sx: {
            width: '100%',
            maxWidth: '100%',
          },
        } as SensorDataPointStatsComponentContainerProps,
      }}
    />
  );
};

export default SensorDataPointStatsComponentContainer;
