// Set the trains collection points
// TODO we currently only have sampleResizing data for Drammenbanen, not Gardermobanen
import _along from '@turf/along';
import {cond, equals, T} from 'ramda';
import {referenceStopDistanceForTrainRouteOrGroup} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import {defaultNode} from '@rescapes/ramda';
import {
  ScheduledStopPoint,
  ScheduledStopPointDerived,
} from 'types/stops/scheduledStopPoint';
import {RailwayLine} from 'types/railways/railwayLine';
import {TrainRouteGroupWithOrderedRoutePointsDerived} from 'types/trainRouteGroups/trainRouteGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from '../../typeUtils/clsOrType.ts';

const along = defaultNode(_along);

/**
 * Given a trainRoute and a distance, creates a pseud-ScheduledStopPoint
 * @param trainRoute
 * @param trainRoute.trackData
 * @param trainRoute.trackData.trackAsLineString The geojson linestring
 * @param nextScheduledStopPoint The stop before or after this one that we want to measure the distance against for the label
 * @param railwayLines
 * @param distance The distance in kilometers
 * @returns {Object} An object with a geojson point property
 */
export const pseudoScheduledStopPointAtDistanceAlongRoute = (
  {
    trainRouteOrGroup,
    nextScheduledStopPoint,
    railwayLines,
  }: {
    trainRouteOrGroup: TrainRouteGroupWithOrderedRoutePointsDerived;
    nextScheduledStopPoint: ScheduledStopPointDerived;
    railwayLines: RailwayLine[];
  },
  distance: number,
): ScheduledStopPoint => {
  const pseudoId = `pseudoScheduledStopPointForRoute:${trainRouteOrGroup.id}|Distance:${distance}`;
  const nextScheduledStopPointDistance: Perhaps<number> =
    referenceStopDistanceForTrainRouteOrGroup(
      trainRouteOrGroup,
      nextScheduledStopPoint,
      railwayLines,
    );

  return clsOrType<ScheduledStopPointDerived>(CemitTypename.scheduledStopPointDerived, {
    id: pseudoId,
    distanceLabel: cond([
      // If the distance is 0 name the stop the nextScheduledStopPoint, since it is. Otherwise name it "difference from nextScheduledStopPoint.name"
      [
        (nextScheduledStopPoint: ScheduledStopPointDerived) =>
          nextScheduledStopPoint &&
          equals(
            Math.round(nextScheduledStopPoint.routeDistance),
            Math.round(nextScheduledStopPointDistance as number),
          ),
        (nextScheduledStopPoint: ScheduledStopPointDerived) => {
          return nextScheduledStopPoint.name;
        },
      ],
      // Else specify the distance the nearest stop
      [
        (nextScheduledStopPoint: ScheduledStopPointDerived): ScheduledStopPoint =>
          nextScheduledStopPoint,
        (nextScheduledStopPoint: ScheduledStopPointDerived) => {
          return `(${(Math.abs(nextScheduledStopPoint.routeDistance - distance) / 1000).toFixed(1)}
       ${nextScheduledStopPoint.routeDistance < distance ? '←' : '→'} ${nextScheduledStopPoint.name})`;
        },
      ],
      [
        T,
        (): string => {
          // This shouldn't happen unless the interval is minimized to nothing
          return '';
        },
      ],
    ])(nextScheduledStopPoint) as string,
    osmId: undefined,
    routeDistance: distance,
    // TODO this can be estimated from the real stops if needed

    geojson: along(trainRouteOrGroup.trackData.trackAsLineString, distance),
    shortName: undefined,
    name: undefined,
  });
};
