import {buildDependencyChain} from 'appUtils/dependencies/dependencyUtils.tsx';
import {trainAppDependencyUnits} from './trainDependencyUnitConfig.ts';
import {TrainAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppOrganizationDependencyProps.ts';

/**
 * TrainDependencyUnits is the top-level dependency of the Train application.
 * It loads dependencies configure in dependencyUnits, which ends with render the TrainPageContainer
 * @param appProps
 * @param organizationProps
 * @returns {*}
 * @constructor
 */
const TrainDependencyUnits = ({
  appProps,
  organizationProps,
}: TrainAppOrganizationDependencyProps): JSX.Element => {
  // Composes *Dependency.jsx containers that handle global API calls via hooks and managing state
  // API calls and state can also occur deeper in the component tree.
  return buildDependencyChain<
    Required<Omit<TrainAppOrganizationDependencyProps, 'renderChildren'>>
  >(trainAppDependencyUnits, {appProps, organizationProps, loading: false});
};
TrainDependencyUnits.displayName = 'TrainDependencyUnits';
export default TrainDependencyUnits;
