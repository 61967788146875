import {
  any,
  compose,
  forEach,
  groupBy,
  indexBy,
  length,
  lt,
  map,
  omit,
  pick,
  prop,
  values,
  when,
} from 'ramda';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {compact, mapMDeep, mapObjToValues} from '@rescapes/ramda';
import {PartialCemited} from '../../../types/cemited';
import {implementsCemitTypeViaClass} from '../../../classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {
  TrainGroupsGrouping,
  TrainGroupsGroupingCollection,
} from 'types/trainGroups/trainGroupsGroupingCollection';
import {clsOrType} from '../../typeUtils/clsOrType.ts';
import {
  TrainGroup,
  TrainGroupMinimized,
} from '../../../types/trainGroups/trainGroup.d.ts';

/**
 * Converts TrainGroupsGroupingCollection to Record<string, T[]> where the keys
 * are each grouping id of trainGroupsByGroupingCollection.trainGroupsByGroupings and
 * the values are  trainGroupsByGroupingCollection.trainGroupsByGroupings[*].trainGroups
 * @param trainGroupsByGroupingCollection
 */
export const trainGroupGroupingCollectionToLookup = <T extends TrainGroupMinimized>(
  trainGroupsByGroupingCollection: TrainGroupsGroupingCollection<T>,
): Record<string, T[]> => {
  const trainGroupsByGroupingsByGroupingId = indexBy(
    prop('groupingId'),
    trainGroupsByGroupingCollection.trainGroupsByGroupings,
  );
  return map((trainGroupsByGrouping: TrainGroupsGrouping<T>) => {
    return trainGroupsByGrouping.trainGroups;
  }, trainGroupsByGroupingsByGroupingId);
};
/**
 * Reverse of trainGroupsByGroupingCollectionToLookup.
 * Converts Record<string, T[]> to TrainGroupsGroupingCollection<T>
 * @param groupingCemitTypeName
 * @param trainGroupTypename
 * @param trainGroupsByGroupingId
 */
export const trainGroupsGroupingCollectionFromLookup = <T extends TrainGroupMinimized>(
  groupingCemitTypeName: TrainGroupsGroupingCollection<T>['groupingCemitTypename'],
  trainGroupTypename: CemitTypename,
  trainGroupsByGroupingId: Record<string, T[]>,
): TrainGroupsGroupingCollection<T> => {
  const trainGroupsByGroupings: TrainGroupsGrouping<T>[] = mapObjToValues(
    (trainGroups: T[], groupingId: string) => {
      return clsOrType<TrainGroupsGrouping<T>>(CemitTypename.trainGroupsGrouping, {
        groupingCemitTypename: groupingCemitTypeName,
        trainGroupCemitTypename: trainGroupTypename,
        groupingId,
        trainGroups,
      });
    },
    trainGroupsByGroupingId,
  );
  return clsOrType<TrainGroupsGroupingCollection<T>>(
    CemitTypename.trainGroupsGroupingCollection,
    {
      groupingCemitTypename: groupingCemitTypeName,
      trainGroupCemitTypename: trainGroupTypename,
      trainGroupsByGroupings,
    },
  );
};
/**
 * Minimizes the TrainGroups in the cookies down to the values selected by the user, like activity
 * Returns TrainGroupsGroupingCollection<TrainGroupPreloaded>
 * @param groupingTypeName
 * @param trainGroupsByGroupingCollection
 */
export const minimizedStoredTrainGroups = <T extends TrainGroupMinimized>(
  groupingTypeName: CemitTypename,
  trainGroupsByGroupingCollection: TrainGroupsGroupingCollection<T>,
): TrainGroupsGroupingCollection<TrainGroup> => {
  const trainGroupsByGroupingId = trainGroupGroupingCollectionToLookup(
    trainGroupsByGroupingCollection,
  );

  if (groupingTypeName === CemitTypename.trainRouteGroup) {
    forEach((trainGroups) => {
      const byTrainGroupId = groupBy(prop('id'), trainGroups);
      if (any(compose(lt(1), length), values(byTrainGroupId))) {
        throw new Error(
          `Attempt to store TrainGroupsByRouteId with the same TrainGroup.id`,
        );
      }
    }, values(trainGroupsByGroupingId));
    // We need to include user and trainRuns and trainRouteOrGroup until TrainGroups are stored in the database
    // Once they are, we can just cache the TrainGroup id
    // mapMDeep2 maps each item of each Object value array, maintaining the object structure
    const trainGroupsPreloadedByGroupId: Record<string, TrainGroup[]> = mapMDeep(
      2,
      (trainGroup: TrainGroup) => {
        return clsOrType<PartialCemited<TrainGroup>>(CemitTypename.trainGroupPreloaded, {
          ...omit(
            ['sensorDataGeojson', 'user', 'trainRuns', 'trainRouteOrGroup'],
            trainGroup,
          ),
          userState: when(Boolean, pick(['id']))(trainGroup.userState),
          // If the TrainGroup has one or more trainRuns, include their ids
          // If the TrainGroup is a TrainGroupOnlyTrainFormation, leave out trainRuns since they aren't allowed in
          // TrainGroupOnlyTrainFormation
          ...(implementsCemitTypeViaClass<TrainGroupSingleTrainRun>(
            CemitTypename.trainGroupSingleTrainRun,
            trainGroup,
          )
            ? {
                trainRuns: when(Boolean, map(prop('id')))(trainGroup.trainRuns),
              }
            : {}),
          trainRouteOrGroup: when(Boolean, pick(['id']))(trainGroup.trainRouteOrGroup),
        });
      },
      trainGroupsByGroupingId,
    );
    // Return TrainGroupsGroupingCollection<TrainGroupPreloaded>
    return trainGroupsGroupingCollectionFromLookup<TrainGroup>(
      CemitTypename.trainGroupPreloaded,
      groupingTypeName,
      trainGroupsPreloadedByGroupId,
    );
  } else if (groupingTypeName === CemitTypename.organization) {
    // If the TrainGroups are grouped by organization, we are currently only storing TrainGroupOnlyTrainFormations
    const trainGroupPreloadedByGroupingId: Record<string, TrainGroup[]> = mapMDeep(
      2,
      (trainGroup: TrainGroup) => {
        // For now only cache TrainGroups that are active, since activity.isActive is the only thing
        // in this list that the user sets
        if (!trainGroup?.activity?.isActive) {
          return undefined;
        }
        // We just need the id and activity to tell us what TrainGroups are selected by the user
        return clsOrType<PartialCemited<TrainGroup>>(CemitTypename.trainGroupPreloaded, {
          ...pick(['__typename', 'id', 'activity'], trainGroup),
          trainFormation: pick(['__typename', 'id'], trainGroup),
        });
      },
      trainGroupsByGroupingId,
    );
    // Remove the undefined values created for non-active TrainGroups
    const compactedTrainGroupPreloadedByGroupingId: Record<string, TrainGroup[]> = map(
      (list: TrainGroup[]) => {
        return compact(list);
      },
      trainGroupPreloadedByGroupingId,
    );
    // Return TrainGroupsGroupingCollection<TrainGroupPreloaded>
    return trainGroupsGroupingCollectionFromLookup<TrainGroup>(
      CemitTypename.trainGroupPreloaded,
      groupingTypeName,
      compactedTrainGroupPreloadedByGroupingId,
    );
  } else {
    throw new Error(
      `groupingTypeName: ${groupingTypeName} must to be CemitTypename.organization or CemitTypename.trainRouteGroup`,
    );
  }
};
