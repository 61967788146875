import {Layer, Source} from "react-map-gl";
import * as React from "react";
import {useEffect, useState} from "react";
import {restrictionTypes} from "../../../api/data/consts.ts";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import AvstangtSpar from 'assets/dataUrlIcons/trafficSimAssets/icons/AvstangtSpar.svg';
import Backforbud from 'assets/dataUrlIcons/trafficSimAssets/icons/Backforbud.svg';
import AnnanBegransning from 'assets/dataUrlIcons/trafficSimAssets/icons/AnnanBegransning.svg';
import Hastighetsbegraansning from 'assets/dataUrlIcons/trafficSimAssets/icons/Hastighetsbegraansning.svg';
import CustomPopup from "../../themedComponents/CustomPopup.tsx";

interface IRestriction {
  restriction: any,
  mapRef: any
}
export function Restriction({restriction, mapRef}: IRestriction) {

  const [showPopUp, setPopupState] = useState(false);
  const getRestrictionType = (type) => {
    switch (type) {
      case 'closedTrack':
        return 'AvstangtSpar';
      case 'backProhibition':
        return 'Backforbud';
      case 'speedLimit':
        return 'Hastighetsbegraansning';
      case 'other':
        return 'AnnanBegransning';
      default:
        'AvstangtSpar';
    }
  };
  let prepareRestrictionGeoJson = (_restriction) => {
    const feature =  {
      type: 'Feature',
      properties: {
        type: getRestrictionType(_restriction.selectedRestrictionType),
      },
      geometry: {
        type: 'Point',
        coordinates: _restriction.coordinates,
      },
    };

    return {
      type: 'FeatureCollection',
      name: 'restrictions',
      features: [feature],
    };
  };

  useEffect(() => {
    if (!mapRef) {
      return undefined
    }
    const onClickLayer = (e) => {
      e.originalEvent.stopPropagation();
      if (e.features.length > 0) {
        const clickedId = e.features[0].properties.id;
        console.log(clickedId);
        setPopupState(true)
      }
    };

    mapRef.current.on('click', layerId, onClickLayer)


    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', layerId, onClickLayer)
      }
    }

  }, []);

  const sourceId = `restrictions-source-layer-${restriction.id}`
  const layerId = `restrictions-layer-${restriction.id}`
  const coordinates = restriction.coordinates;
  return (
    <>
      <Source
        id={sourceId}
        type="geojson"
        data={prepareRestrictionGeoJson(restriction)}
      >
        <Layer
          id={layerId}
          type="symbol"
          source={sourceId}
          layout={{
            'icon-image': ['get', 'type'],
            'icon-allow-overlap': true,
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.3,
              14,
              0.3,
              14.1,
              0.2,
              15,
              0.3,
              20,
              0.5,
            ]
          }}
        />
      </Source>

      {showPopUp && (
        <CustomPopup longitude={coordinates[0]} latitude={coordinates[1]}
                     anchor="bottom"
                     collapse={false}
                     onClose={() => setPopupState(false)}>
          <RestrictionContainer restriction={restriction} />
        </CustomPopup>
      )}
    </>
  )
}

function RestrictionContainer({restriction}) {

  const icons = {
    closedTrack: AvstangtSpar,
    backProhibition: Backforbud,
    speedLimit: Hastighetsbegraansning,
    other: AnnanBegransning,
  };

  const getIcon = (begransning: string) => {
    const IconSrc = icons[begransning];
    return IconSrc ? (
      <img
        src={IconSrc}
        alt={begransning}
        style={{marginRight: '8px'}}
      />
    ) : null;
  };

  return (
    <div style={{width: 'auto', marginTop: '10px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} key={restriction.id}>
          <Card variant="outlined">
            <CardContent
              style={{display: 'flex', flexDirection: 'column'}}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="h7"
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  {getIcon(restriction.selectedRestrictionType)}{' '}
                  {restrictionTypes[restriction.selectedRestrictionType]}
                </Typography>
                <span>
                    {/*<ZoomLocationButton onClick={() => onMoveMapToCoord(restriction.coordinates)}/>*/}
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Plats:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.statPlas.name} -{' '}
                  {restriction.slutPlat.name}{' '}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Berörda:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.berordaVaxlar}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Hastighet:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.hastighet}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Orsak:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.orsakTillBegransning}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Starttid:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`${restriction.restrictionStartDay} ${restriction.restrictionStartDayTime}`}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Sluttid:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`${restriction.restrictionEndDay} ${restriction.restrictionEndDayTime}`}
                </Typography>
              </div>

              {/*{isNotReadOnlyAccess() && <Box*/}
              {/*    display="flex"*/}
              {/*    justifyContent="flex-end"*/}
              {/*    alignItems="center"*/}
              {/*    gap="2px"*/}
              {/*    marginTop="5px"*/}
              {/*>*/}
              {/*  <EditButton onEdit={() => editRestriction(restriction)}/>*/}
              {/*  <DeleteButton*/}
              {/*      onDelete={() => handleDeleteClick(restriction)}*/}
              {/*  />*/}
              {/*  <DeleteConfirmationDialog*/}
              {/*      open={isDialogOpen}*/}
              {/*      onClose={handleClose}*/}
              {/*      onConfirm={handleConfirmDelete}*/}
              {/*  />*/}
              {/*</Box>}*/}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
