import {OrganizationLoaded} from '../../../../types/organizations/organization.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {TrainRouteGroup} from '../../../../types/trainRouteGroups/trainRouteGroup';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {useCemitApiSwrResolveData} from '../../../cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {
  useNotLoadingEffect,
  useNotLoadingMemo,
} from '../../../../utils/hooks/useMemoHooks.ts';
import {setListIfIdsChanged} from '../../../../utils/hooks/setterUtils.ts';
import {
  TrainRouteOrGroup,
  TrainRouteOrGroupMinimized,
} from '../../../../types/trainRouteGroups/trainRouteOrGroup';
import {all} from 'ramda';
import {
  TrainRoute,
  TrainRouteDerived,
} from '../../../../types/trainRouteGroups/trainRoute';
import {implementsCemitTypeViaClass} from '../../../../classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {CemitTypename} from '../../../../types/cemitTypename.ts';

/**
 * Resolves the TrainRoutes from the ServiceLines
 * @param loading
 * @param organization The organization
 * @param trainRouteGroups
 * @param setTrainRouteGroups Setter
 */
export const useConfiguredApiForTrainRouteGroups = (
  loading: boolean,
  organization: OrganizationLoaded | undefined,
  trainRouteGroups: Perhaps<TrainRouteGroup[]>,
  setTrainRouteGroups: StateSetter<Perhaps<TrainRouteGroup[]>>,
) => {
  const {
    data: newlyLoadedTrainRouteGroups,
    isLoading,
    isValidating,
  } = useCemitApiSwrResolveData(loading, organization, 'trainRouteGroups', {
    organization,
  });
  useNotLoadingEffect(
    loading || !newlyLoadedTrainRouteGroups || isLoading || isValidating,
    () => {
      setListIfIdsChanged<TrainRouteGroup>(
        trainRouteGroups,
        newlyLoadedTrainRouteGroups,
        setTrainRouteGroups,
      );
    },
    [trainRouteGroups],
  );
};

/**
 * Returns true if trainRouteOrGroup is defined and all trainRouteOrGroup.trainRouteGroups implement TrainRouteDerived,
 * meaning all derived data that is needed is loaded
 * @param loading
 * @param trainRouteOrGroup
 */
export const useNotLoadingTrainRouteOrGroupImplementsDerived = (
  loading: boolean,
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup | TrainRouteOrGroupMinimized>,
): boolean => {
  const trainRouteOrGroupImplementsDerivedDependencies = [trainRouteOrGroup] as const;
  return useNotLoadingMemo<
    boolean,
    typeof trainRouteOrGroupImplementsDerivedDependencies
  >(
    loading || !trainRouteOrGroup,
    (trainRouteOrGroup) => {
      return all<TrainRoute>((trainRoute: TrainRoute) => {
        return implementsCemitTypeViaClass<TrainRouteDerived>(
          CemitTypename.trainRouteDerived,
          trainRoute,
        );
      }, trainRouteOrGroup.trainRoutes);
    },
    trainRouteOrGroupImplementsDerivedDependencies,
  );
};
