import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import CemitFilterTrainGroupsContainer, {
  CemitFilterTrainGroupsContainerProps,
} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainGroupComponents/CemitFilterTrainGroupsContainer.tsx';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import CemitFilterTrainFormationsContainer, {
  CemitFilterTrainFormationsContainerProps,
} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsContainer.tsx';
import React from 'react';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {doActiveTrainGroupsHaveTrainRuns} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {TRAIN_GROUP_MAX_ACTIVE_COUNT} from 'config/appConfigs/trainConfigs/trainConfig.ts';

export interface TrainGroupsTitleContainerProps extends CemitComponentProps {
  activeTrainGroups: TrainGroup[];
  width: string;
  // For menu mode, this indicates a TrainGroup that the TrainGroup of activeTrainGroups to display as selected
  scopedTrainGroup?: Perhaps<TrainGroup>;
  // If true, no TrainGroup will be shown as selected, but a plus icon will indicate
  // that a TrainGroup from the trainProps that are not already
  // in activeTrainGroups can be added to activeTrainGroups
  addTrainGroupMode?: Perhaps<boolean>;
}

/**
 * Displays the componentProps.activeTrainGroup or an add icon if there is no componentProps.activeTrainGroup
 * The title or add icon is a menu to choose a TrainGroup.
 * The TrainGroup chosen and available is either a TrainGroupOnlyTrainFormation or TrainGroupSingleTrainRun,
 * depending on the type of componentProps.activeTrainGroups
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
export const TrainGroupsTitleContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<TrainGroupsTitleContainerProps>) => {
  // Find out if the componentProps.activeTrainRun is a TrainGroupSingleTrainRun or a TrainGroupOnlyTrainFormation.
  // If there is no componentProps.activeTrainRun find out the type from componentProps.activeTrainRuns
  const hasTrainRun = componentProps.scopedTrainGroup
    ? implementsCemitTypeViaClass(
        CemitTypename.trainGroupSingleTrainRun,
        componentProps.scopedTrainGroup,
      )
    : doActiveTrainGroupsHaveTrainRuns(componentProps.activeTrainGroups);

  // Creates a menu of available TrainGroupSingleTrainRun or TrainGroupOnlyTrainFormation, disabling those
  // matching componentProps.activeTrainRuns so the user can only choose on that isn't yet active.
  // The component.activeTrainGroup is displayed as the title if defined, else a plus icon is display to add
  // a new active TrainGroup
  const trainGroupContainer = hasTrainRun ? (
    <CemitFilterTrainGroupsContainer
      key="cemitFilterTrainGroupsContainer"
      {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: ts<CemitFilterTrainGroupsContainerProps>({
          forcedActiveFrontendView: FrontendView.trainGroupMenu,
          maximumAllowed: TRAIN_GROUP_MAX_ACTIVE_COUNT,
          scopedTrainGroup: componentProps.scopedTrainGroup,
          addTrainGroupMode: componentProps.addTrainGroupMode,
        }),
      }}
    />
  ) : (
    <CemitFilterTrainFormationsContainer
      key="cemitFilterTrainFormationsContainer"
      {...{
        appProps,
        trainProps,
        componentProps: ts<CemitFilterTrainFormationsContainerProps>({
          forcedActiveFrontendView: FrontendView.trainFormationMenu,
          maximumAllowed: TRAIN_GROUP_MAX_ACTIVE_COUNT,
          scopedTrainGroup:
            componentProps.scopedTrainGroup as Perhaps<TrainGroupOnlyTrainFormation>,
          addTrainGroupMode: componentProps.addTrainGroupMode,
        }),
      }}
    />
  );
  return trainGroupContainer;
};
