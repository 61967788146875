import {shallowEquals} from 'utils/functional/functionalUtils.ts';
import {mergeDeep} from '@rescapes/ramda';
import {
  all,
  always,
  both,
  complement,
  compose,
  concat,
  cond,
  equals,
  indexBy,
  is,
  mergeWith,
  mergeWithKey,
  prop,
  sortBy,
  T,
  uniqBy,
  values,
} from 'ramda';
import {SensorDataPoint} from '../../../types/sensors/sensorDataPoint';
import {SensorDataTrainGroup} from '../../../types/trainRuns/trainRun';
import {mergeDistanceIntervalAndRanges} from '../../cemitAppUtils/cemitAppTypeMerging/distanceResolutionAndRangesMerging.ts';
import {mergeWithKeyExistingAndIncoming} from '../../typeUtils/mergeTypeUtils.ts';

/**
 * Merge the TrainRuns of TrainGroup
 * @param existingTrainRuns
 * @param incomingTrainRuns
 */
export const mergeTrainRuns = (
  existingTrainRuns: SensorDataTrainGroup[],
  incomingTrainRuns: SensorDataTrainGroup[],
): SensorDataTrainGroup[] => {
  return values(
    mergeWithKey(
      (
        _key: string,
        existingPropValue: SensorDataTrainGroup,
        incomingPropValue: SensorDataTrainGroup,
      ) => {
        return mergeTrainRun(existingPropValue, incomingPropValue);
      },
      indexBy(prop('id'), existingTrainRuns),
      indexBy(prop('id'), incomingTrainRuns),
    ),
  );
};

/**
 * Merge TrainRun does a simple merge except for sensorDataPoints. If new sensorDataPoints come in that differ
 * from the previous, they are sorted and combined
 * @param existingTrainRun The existing TrainRun
 * @param incomingTrainRun The incoming TrainRun
 * @returns {*}
 */
export const mergeTrainRun = (
  existingTrainRun: SensorDataTrainGroup,
  incomingTrainRun: SensorDataTrainGroup,
): SensorDataTrainGroup => {
  const mergedTrainRun: SensorDataTrainGroup = mergeWithKeyExistingAndIncoming(
    (key: string, existingPropValue: any, incomingPropValue: any) => {
      return cond([
        // If references equals or key is geojson, always take the incoming prop value
        [
          shallowEquals,
          (_a: any, b: any) => {
            return b;
          },
        ],
        [
          always(equals('sensorDataGeojson', key)),
          (_a: object, b: object) => {
            return b;
          },
        ],
        // If sensorDataPoints, run the custom merge
        [always(equals('sensorDataPoints', key)), mergeDifferingSensorDataPoints],
        [
          always(equals('distanceResolutionsAndRanges', key)),
          mergeDistanceIntervalAndRanges,
        ],
        [
          T,
          (a: any, b: any) => {
            return all(both(complement(Array.isArray), is(Object)), [a, b])
              ? mergeDeep(a, b)
              : b;
          },
        ],
      ])(existingPropValue, incomingPropValue);
    },
    existingTrainRun,
    incomingTrainRun,
  );
  return mergedTrainRun;
};

/**
 For sensorDataPoints where existing and new are different, we need to merge the array of SensorDataPoints for each cdc Key. Example:
 existing: sensorDataPoints: {cdc0011: [...1000 points over railway...], cdc0037: [...1000 points over railway..]}
 new: sensorDataPoints: {cdc0011: [...1000 more points between two stops...], cdc0037: [...1000 more points between two stops..]}
 would merge the array of points by time for each set for cdc0011 and cdc0037
 TODO there might be a better algorithm to sort this data, since the newData is always clumped
 It might be better to find start and end indexes of the existData where the newData is
 being inserted, then just sort the existing with the new between that index range
 but either way it is basically order 2N
 The backend prevents some duplicates, but we must remove the rest here
 * @param existingSensorDataPoints The existing SensorDataPoints of the TrainRun, meaning those already downloaded
 * @param incomingSensorDataPoints The incoming SensorDataPoints of the TrainRun that are different from the existing
 * @returns {SensorDataPoint[]}
 */
const mergeDifferingSensorDataPoints = (
  existingSensorDataPoints: SensorDataPoint[],
  incomingSensorDataPoints: SensorDataPoint[],
): SensorDataPoint[] => {
  return mergeWith((a: SensorDataPoint[], b: SensorDataPoint[]) => {
    const merged: SensorDataPoint[] = compose(
      (combinedSensorDataPoints: SensorDataPoint[]) => {
        return uniqBy(prop('time'), combinedSensorDataPoints);
      },
      (combinedSensorDataPoints: SensorDataPoint[]) => {
        return sortBy(prop<Date>('time'), combinedSensorDataPoints);
      },
      (a: SensorDataPoint[], b: SensorDataPoint[]) => {
        return concat(a, b);
      },
    )(a, b);
    return merged;
  })(existingSensorDataPoints, incomingSensorDataPoints);
};
