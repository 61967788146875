import {ReactElement, useMemo, useState} from 'react';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  AlertExcelDownloadData,
  AlertTrainGroupProps,
} from 'types/alerts/alertTrainGroupProps.ts';
import {
  useNotLoadingEffectQueryAlertApiAndMutateDependencies,
  useNotLoadingEffectQueryAlertSummaryApiAndMutateDependencies,
  useNotLoadingMemoCreateTrainGroupAlertScopePropsSets,
  useNotLoadingTrainGroupsForAlert,
} from 'async/alertAsync/alertTrainGroupHooks.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';
import {AlertProps} from 'types/alerts/alertProps.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';

const AlertDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement<
  Required<TrainAppMapDependencyProps>
> => {
  // The Alert graphql status for downloading report data to export to Excel
  const [alertReportGraphqlStatus, setAlertReportGraphqlStatus] =
    useState<LoadingStatusEnum>(LoadingStatusEnum.notLoaded);

  // TODO not in use. Needs refactor to handling multiple TrainGroups
  const [alertDownloadData, setAlertDownloadData] =
    useState<Perhaps<AlertExcelDownloadData>>(undefined);

  const activeTrainGroups: Perhaps<TrainGroup[]> =
    trainProps?.trainGroupActivityProps?.activeTrainGroups;

  // Use these values for each TrainGroup's the AlertTrainGroups
  // TODO I'm not sure if each TrainGroup needs a unique report or not yest
  const alertTrainGroupPropsPartial = useMemo(() => {
    return clsOrType<AlertTrainGroupProps>(CemitTypename.alertTrainGroupProps, {
      alertGraphqlStatus: LoadingStatusEnum.notLoaded,
      heatMapData: undefined,
      // TODO not in use
      alertDownloadData: undefined,
      alertGaugeByTimePeriod: undefined,
      dateIntervalDescription: undefined,
      // TODO not in use
      alertReportGraphqlStatus: alertReportGraphqlStatus,
      // TODO not in use
      setAlertReportGraphqlStatus: setAlertReportGraphqlStatus,
      // TODO not in use
      excelDownloadData: alertDownloadData,
      // TODO not in use
      alertDownloadData: setAlertDownloadData,
    });
  }, [alertReportGraphqlStatus, alertDownloadData]);

  const trainGroupsForAlert: Perhaps<TrainGroup[]> = useNotLoadingTrainGroupsForAlert(
    loading,
    activeTrainGroups,
  );

  // Combines trainProps.alertConfigProps with each activeTrainGroup to create
  // a AlertScopeProps for each active TrainGroup
  const alertScopePropSets: Perhaps<AlertScopeProps[]> =
    useNotLoadingMemoCreateTrainGroupAlertScopePropsSets(
      loading,
      appProps,
      organizationProps,
      trainProps,
      alertTrainGroupPropsPartial,
      trainGroupsForAlert,
    );
  const summaryAlertScopePropSets: Perhaps<AlertScopeProps[]> =
    useNotLoadingMemoCreateTrainGroupAlertScopePropsSets(
      loading,
      appProps,
      organizationProps,
      trainProps,
      alertTrainGroupPropsPartial,
      // All TrainGroups
      resolveActiveTrainGroupCrudList(trainProps)?.list || [],
    );

  /**
   * Queries Alerts whenever dependencies change
   */
  useNotLoadingEffectQueryAlertApiAndMutateDependencies(
    loading || !alertScopePropSets,
    appProps.t,
    alertScopePropSets,
    trainProps.alertConfigProps,
    resolveActiveTrainGroupCrudList(trainProps),
  );

  /**
   * Queries Alerts for all TrainGroups summaries whenever time interval changes
   */
  useNotLoadingEffectQueryAlertSummaryApiAndMutateDependencies(
    loading || !alertScopePropSets,
    appProps.t,
    summaryAlertScopePropSets,
    trainProps.alertConfigProps,
    resolveActiveTrainGroupCrudList(trainProps),
  );

  /**
   * Queries Alert Report whenever the download button is clicked,
   * making alertReportGraphqlStatus.needsToLoad
   */
  // useEffectQueryAlertApiReportDependencies(
  //   loading || !activeTrainGroups,
  //   appProps.t
  //   alertScopePropSets,
  //   alertReportGraphqlStatus,
  //   alertDownloadData,
  //   setAlertReportGraphqlStatus,
  //   setAlertDownloadData,
  //   exportAlertDataToExcel,
  // );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(AlertDependency.name),
    AlertDependency.name,
    {
      alertScopePropSets,
      alertReportGraphqlStatus,
    },
    [alertScopePropSets],
    appProps.setWhatDependenciesAreLoading,
  );

  const alertScopeProps = useMemoClsOrType<AlertProps>(
    CemitTypename.alertTrainGroupProps,
    {
      whatIsLoading,
      alertScopePropSets: alertScopePropSets,
    },
  );
  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'alertProps',
    alertScopeProps,
  );

  useWhatChangedLoadingExplanation(whatIsLoading, trainPropsMerged, 'AlertDependency');
  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};

export default AlertDependency;
//export default memo(
//  AlertDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
