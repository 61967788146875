import {DependencyUnit} from 'types/dependencies/dependencyUnit.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import ServiceLineDependency from './trainLineDependencies/ServiceLineDependency.tsx';
import RailwayLineDependency from './trainLineDependencies/RailwayLineDependency.tsx';
import TrainFormationDateDependency from './dateDependencies/TrainFormationDateDependency.tsx';
import TrainFormationDependency from './equipmentDependencies/TrainFormationDepedency.tsx';
import AlertConfigDependency from 'async/trainAppAsync/trainAppDependencies/alertDependencies/AlertConfigDependency.tsx';
import TrainRouteGroupDependency from './trainLineDependencies/TrainRouteGroupDependency.tsx';
import TrainMapDependency from './presentationDependencies/mapDependencies/TainMapDependency.tsx';
import AlertMapSensorLayerDependency from 'async/trainAppAsync/trainAppDependencies/alertDependencies/AlertMapSensorLayerDependency.tsx';
import TrainPageContainer from '../../../pages/trainApp/TrainPageContainer.tsx';
import {always, chain, cond, fromPairs, length, map, T} from 'ramda';
import {ReactElement} from 'react';
import FilteredTrainGroupDependency from 'async/trainAppAsync/trainAppDependencies/trainGroupDependencies/FilteredTrainGroupDependency.tsx';
import TrainGroupSingleTrainRunDependency from 'async/trainAppAsync/trainAppDependencies/trainGroupDependencies/TrainGroupSingleTrainRunDependency.tsx';
import AlertDependency from 'async/trainAppAsync/trainAppDependencies/alertDependencies/AlertDependency.tsx';
import TrainMapLayerDependency from './presentationDependencies/mapDependencies/TrainMapLayerDependency.tsx';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppOrganizationDependencyProps.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import TrainGroupSensorDependency from './sensorDependencies/TrainGroupSensorDependency.tsx';
import TrainGroupChartDependency from './presentationDependencies/chartDependencies/TrainGroupChartDepedency.tsx';
import {undefinedToTrue} from 'utils/functional/functionalUtils.ts';
import {
  doesOrganizationHaveServiceLines,
  doesOrganizationHaveWheelScan,
} from 'utils/organization/organizationUtils.ts';
import TrainGroupGeojsonDependency from './dataFeatureDependencies/TrainGroupGeojsonDepedency.tsx';
import TrainMapSensorLayerDependency from './presentationDependencies/mapDependencies/TrainMapSensorLayerDependency.tsx';
import ChartMapInteractionDependency from './sensorDependencies/ChartMapInteractionDependency.tsx';
import ActiveTrainGroupsDependency from 'async/trainAppAsync/trainAppDependencies/trainGroupDependencies/ActiveTrainGroupsDependency.tsx';
import {doActiveTrainGroupsHaveTrainRuns} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import RealtimeTrainMapSensorLayerDependency from 'async/trainAppAsync/trainAppDependencies/realtimeTrainDependencies/RealtimeTrainMapSensorLayerDependency.tsx';
import RealtimeTrainDependency from 'async/trainAppAsync/trainAppDependencies/realtimeTrainDependencies/RealtimeTrainDependency.tsx';

/**
 * A list of containers that have async functionality, namely api calls via hooks and state via useState
 * The dependencies are composed such that the first (ServiceLineDependency) renders the second as
 * a child (RailwayLineDependency) and so on until TrainPageContainer is finally rendered.
 * Dependencies listed in an array have the same isLoading call but are still rendered serially, not as parallel
 * children
 */
export const trainAppDependencyUnits: DependencyUnit[] = [
  clsOrType<DependencyUnit<TrainAppOrganizationDependencyProps>>(
    CemitTypename.dependencyUnit,
    {
      isLoading: ({organizationProps}: TrainAppOrganizationDependencyProps) => {
        return organizationProps.loading;
      },
      components: [ServiceLineDependency],
    },
  ),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps: {serviceLineProps}}: TrainAppTrainDependencyProps) => {
      return serviceLineProps.loading;
    },
    components: [RailwayLineDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: TrainAppTrainDependencyProps) => {
      return trainProps.railwayLineProps.loading;
    },
    components: [TrainRouteGroupDependency, TrainFormationDateDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: TrainAppTrainDependencyProps) => {
      return trainProps.trainFormationDateProps.loading;
    },
    components: [TrainFormationDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({
      trainProps: {trainFormationDateProps, trainRouteGroupProps},
    }: TrainAppTrainDependencyProps) => {
      return trainFormationDateProps.loading || trainRouteGroupProps.loading;
    },
    components: [FilteredTrainGroupDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({
      trainProps: {filteredTrainGroupProps},
    }: TrainAppTrainDependencyProps) => {
      return filteredTrainGroupProps.loading;
    },
    components: [TrainGroupSingleTrainRunDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: TrainAppTrainDependencyProps) => {
      // ActiveTrainGroupsDependency handles either active TrainGroupSingleTrainRuns if one is chosen
      // or else the active TrainGroupOnlyTrainFormation
      return length(
        trainProps.trainGroupSingleTrainRunProps.activeTrainGroupSingleTrainRuns || [],
      )
        ? trainProps.trainGroupSingleTrainRunProps.loading
        : trainProps.trainGroupOnlyTrainFormationProps.loading;
    },
    components: [ActiveTrainGroupsDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: TrainAppTrainDependencyProps) => {
      // TrainMapDependency handles either active TrainGroupSingleTrainRuns if one is chosen
      // or else the active TrainGroupOnlyTrainFormation
      return doActiveTrainGroupsHaveTrainRuns(
        trainProps.trainGroupActivityProps.activeTrainGroups,
      )
        ? trainProps.trainGroupSingleTrainRunProps.loading
        : trainProps.trainGroupOnlyTrainFormationProps.loading;
    },
    components: [TrainMapDependency],
  }),

  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: TrainAppTrainDependencyProps) => {
      // TrainGroupSensorDependency handles either active TrainGroupSingleTrainRuns if one is chosen
      // or else the active TrainGroupOnlyTrainFormation
      return doActiveTrainGroupsHaveTrainRuns(
        trainProps.trainGroupActivityProps.activeTrainGroups,
      )
        ? trainProps.trainGroupSingleTrainRunProps.loading
        : trainProps.trainGroupOnlyTrainFormationProps.loading;
    },
    components: [TrainGroupSensorDependency], //, TrainGroupEquipmentSensorDependency]
  }),
  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({
      trainProps: {
        trainGroupSingleTrainRunProps: {sensorProps},
      },
    }: TrainAppMapDependencyProps) => {
      return sensorProps.loading;
    },
    components: [TrainGroupGeojsonDependency],
  }),
  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({organizationProps, trainProps}: TrainAppMapDependencyProps) => {
      return cond([
        [
          // TrainGroups with TrainRuns
          ({organizationProps, trainProps}): boolean => {
            return Boolean(doesOrganizationHaveServiceLines(organizationProps));
          },
          ({trainProps}) => {
            return trainProps.trainGroupSingleTrainRunProps.sensorProps.loading;
            //return trainProps.trainGroupSingleTrainRunProps.geojsonProps.loading;
          },
        ],
        [
          // Wheelscan
          ({organizationProps}): boolean => {
            return Boolean(
              doesOrganizationHaveWheelScan(
                organizationProps.loading,
                organizationProps.organization,
              ),
            );
          },
          ({trainProps}) => {
            return undefinedToTrue(
              trainProps.trainGroupSingleTrainRunProps?.equipmentAttributeSensorProps
                ?.loading,
            );
          },
        ],
        [T, always(true)],
      ])({organizationProps, trainProps});
    },
    components: [TrainGroupChartDependency],
  }),
  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps, mapProps}: TrainAppMapDependencyProps) => {
      return mapProps.loading || trainProps.railwayLineProps.loading;
    },
    components: [TrainMapLayerDependency, TrainMapSensorLayerDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: TrainAppTrainDependencyProps) => {
      // RideComfort can represent TrainGroupOnlyTrainFormations or TrainGroupSingleTrainRun instances,
      // so we need to wait for these to load
      return (
        trainProps.trainGroupOnlyTrainFormationProps.loading ||
        trainProps.filteredTrainGroupProps.loading
      );
    },
    components: [AlertConfigDependency],
  }),
  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps: {alertConfigProps}}: TrainAppTrainDependencyProps) => {
      return alertConfigProps.loading;
    },
    components: [AlertDependency],
  }),

  clsOrType<DependencyUnit<TrainAppTrainDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps: {alertConfigProps}}: TrainAppTrainDependencyProps) => {
      return alertConfigProps.loading;
    },
    components: [RealtimeTrainDependency],
  }),
  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps, mapProps}: TrainAppMapDependencyProps) => {
      return Boolean(
        mapProps.loading ||
          trainProps.alertProps.loading ||
          // RailwayLines must be in their loaded form for this component for correct layer ordering
          !trainProps.railwayLineProps.maybeAsLoaded ||
          // Railway map layers must also be loaded so the draw order is correct
          mapProps.mapLayerProps.trainMapLayerProps.loading,
      );
    },
    components: [AlertMapSensorLayerDependency],
  }),

  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps, mapProps}: TrainAppMapDependencyProps) => {
      return Boolean(
        mapProps.loading ||
          trainProps.realtimeTrainProps.loading ||
          // RailwayLines must be in their loaded form for this component for correct layer ordering
          !trainProps.railwayLineProps.maybeAsLoaded ||
          // Railway map layers must also be loaded so the draw order is correct
          mapProps.mapLayerProps.trainMapLayerProps.loading,
      );
    },
    components: [RealtimeTrainMapSensorLayerDependency],
  }),

  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps, mapProps}: TrainAppMapDependencyProps) => {
      // When anything in TrainGroupProps is loading, disable ChartMapInteractionDependency
      // functions for now. TODO it might be possible to have them enabled when data is updating.
      return trainProps.trainGroupSingleTrainRunProps.loading || mapProps.loading;
    },
    components: [ChartMapInteractionDependency],
  }),

  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({}: TrainAppMapDependencyProps) => {
      return false;
    },
    components: [TrainPageContainer],
  }),
];
const componentNameToFuncString = fromPairs(
  chain<DependencyUnit, [string, string]>((dependencyUnit: DependencyUnit) => {
    const funcString = dependencyUnit.isLoading.toString();
    return map((component: (dependencyProps: any) => ReactElement) => {
      return [component.name, funcString];
    }, dependencyUnit.components);
  }, trainAppDependencyUnits),
);

export const isLoadingStringOfDependencyUnit = (componentName: string): string => {
  return componentNameToFuncString[componentName];
};
