import React from 'react';
import {SvgIcon} from '@mui/material';

/**
 * Creates a side-facing train icon
 * @param fill
 * @param fontSize
 * @constructor
 */
export const TrainSidewaysSvgIcon = ({fill = 'white', fontSize = 'medium'}) => {
  return (
    <SvgIcon {...{fontSize}}>
      <svg viewBox="0 0 1024 1045.437" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={fill}
          d="M811.186 754.75H0v-66.783h810.837c75.183 0 136.225-60.545 137.03-135.537v-.076-.5c0-216.698-174.872-392.552-391.195-394.14H0V90.93h556.522c253.29 1.263 458.13 206.884 458.13 460.35v1.413-.073c-1.032 111.684-91.673 201.856-203.44 202.13h-.025zm-682.963-66.783c65.652 0 118.873 53.22 118.873 118.873s-53.22 118.873-118.873 118.873C62.57 925.713 9.35 872.493 9.35 806.84c.076-65.62 53.252-118.797 118.866-118.873h.007zm0 170.963c28.77 0 52.09-23.322 52.09-52.09s-23.322-52.09-52.09-52.09c-28.77 0-52.09 23.322-52.09 52.09.025 28.76 23.332 52.065 52.088 52.09h.003zm469.26-170.963c65.65 0 118.872 53.22 118.872 118.873s-53.22 118.873-118.873 118.873c-65.652 0-118.873-53.22-118.873-118.873.075-65.62 53.25-118.797 118.865-118.873h.007zm0 170.963c28.768 0 52.09-23.322 52.09-52.09s-23.323-52.09-52.09-52.09c-28.77 0-52.09 23.322-52.09 52.09.024 28.76 23.33 52.065 52.087 52.09h.002zM768 687.967c65.652 0 118.873 53.22 118.873 118.873S833.653 925.713 768 925.713c-65.652 0-118.873-53.22-118.873-118.873.076-65.62 53.252-118.797 118.866-118.873H768zm0 170.963c28.77 0 52.09-23.322 52.09-52.09s-23.322-52.09-52.09-52.09c-28.77 0-52.09 23.322-52.09 52.09.025 28.76 23.332 52.065 52.088 52.09H768zm246.472-272.918H682.518c-112.675-.247-203.92-91.645-203.92-204.354 0-.752.003-1.502.01-2.252v.114c.994-65.024 53.62-117.43 118.634-118.07h315.186l10.017 13.356c57.565 76.11 92.206 172.347 92.206 276.675v1.204-.062zm-416.99-257.78c-28.597.345-51.694 23.41-52.09 51.963v.038c-.005.424-.007.924-.007 1.425 0 75.856 61.395 137.373 137.205 137.572h263.988c-6.073-71.984-30.588-137.233-68.753-192.306l.857 1.308zM256 496.968H0v-66.784h256c28.154 0 50.977-22.823 50.977-50.977S284.154 328.23 256 328.23H0V261.45h256c65.373.148 118.37 52.926 118.873 118.2v.94c-1.397 64.57-54.072 116.38-118.847 116.38h-.028zm-94.386-31.612H94.83V294.84h66.784zM1024 925.713H0V858.93h1024z"
        />
      </svg>
    </SvgIcon>
  );
};

TrainSidewaysSvgIcon.displayProps = 'TrainSidewaysSvgIcon';
export default TrainSidewaysSvgIcon;
