import {
  all,
  always,
  any,
  chain,
  clone,
  compose,
  concat,
  cond,
  difference,
  either,
  equals,
  filter,
  findIndex,
  has,
  head,
  identity,
  includes,
  indexBy,
  is,
  length,
  lensIndex,
  lensPath,
  lensProp,
  map,
  mergeRight,
  omit,
  over,
  prop,
  propOr,
  slice,
  T,
  uniq,
  uniqBy,
  values,
  when,
} from 'ramda';
import {
  applyDeepWithKeyWithRecurseArraysAndMapObjs,
  compact,
  flattenObjUntil,
  mapObjToValues,
  mergeDeepWith,
  toArrayIfNot,
  unflattenObj,
} from '@rescapes/ramda';
import {CemitFilterTypeTest} from '../../types/cemitFilters/cemitFilterTypeTest.ts';
import {CemitFilterProps} from '../../types/cemitFilters/cemitFilterProps';
import {CemitFilterUpdater} from '../../types/cemitFilters/cemitFilterUpdater';
import {CemitFilterDateInterval} from '../../types/cemitFilters/cemitFilterDateInterval';
import {CemitFilterDateRecurrence} from '../../types/cemitFilters/cemitFilterDateRecurrence';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {CemitFilterTypeEval} from '../../types/filters/filterTypeEval';
import {
  evalCemitFilterDateInterval,
  extractCemitFilterDateIntervals,
  isCemitFilterDateInterval,
  mergeDateFilterRanges,
} from './cemitFilterDateIntervalUtils.ts';
import {Cemited} from '../../types/cemited';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {
  incomingInstancesWithoutMatchingExisting,
  mergeRightIfDefined,
} from '../../utils/functional/functionalUtils.ts';
import {
  CemitFilter,
  CemitFilterComponentOrLiteral,
  CemitFilterExpressionPair,
} from 'types/cemitFilters/cemitFilter';
import {
  overClassOrType,
  overClassOrTypeList,
  setClassOrType,
} from '../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {clsOrType} from '../typeUtils/clsOrType.ts';
import {useMemo} from 'react';
import {CemitFilterTypeReplace} from 'types/filters/cemitFilterTypeReplace.ts';

/**
 * Given a filterTypeTest and filterTypeEval function, returns a flat list
 * of the matching filters that have been evaled deep with filterTypeEval from cemitFilter
 * @param filterTypeTest
 * @param filterTypeEval
 * @param cemitFilter The top-level filter to test deep
 * @param props
 */
export const extractAndEvaluateMatchingFilters = <
  T extends Cemited,
  FT extends CemitFilter<T> = CemitFilter<T>,
>(
  filterTypeTest: CemitFilterTypeTest,
  filterTypeEval: CemitFilterTypeEval,
  cemitFilter: FT,
  props: Perhaps<CemitFilterProps>,
): FT[] => {
  // Never search the parent; it only serves as a reference when resetting the filter
  const cemitFilterWithoutParent = omit(['parent'], cemitFilter);

  // TODO Type each function
  return compose(
    // Take the __pickMe values
    (obj) => {
      return mapObjToValues(prop('__pickMe'), obj) as CemitFilterDateRecurrence[];
    },
    // Remove values without the __pickMe prop
    filter((obj: Record<string, any>) => {
      return is(Object, obj) && obj.__pickMe;
    }),
    (obj: Record<string, any>) => {
      // Flatten to the point that __pickMe is a key
      return flattenObjUntil(
        (obj: Record<string, any>) => propOr(false, '__pickMe', obj),
        obj,
      );
    },
    (cemitFilterWithoutTypename: FT) => {
      return applyDeepOverFilterMarkMatches<T>(
        filterTypeTest,
        filterTypeEval,
        cemitFilterWithoutTypename,
        props,
      );
    },
  )(cemitFilterWithoutParent);
};

/**
 * Marks matching CemitFilter types deeply with a __pickMe field
 * @param filterTypeTest
 * @param filterTypeEval
 * @param cemitFilterWithoutTypename
 * @param props
 */
const applyDeepOverFilterMarkMatches = <FT extends CemitFilter>(
  filterTypeTest: CemitFilterTypeTest,
  filterTypeEval: CemitFilterTypeEval,
  cemitFilterWithoutTypename: FT,
  props: Perhaps<CemitFilterProps>,
) => {
  return applyDeepWithKeyWithRecurseArraysAndMapObjs(
    (_l: any, r: any) => r,
    (_key: string, obj: CemitFilter) => {
      return cond([
        [
          (obj: CemitFilter<Cemited>) => {
            return filterTypeTest(obj, props || {});
          },
          (obj: CemitFilter<Cemited>) => {
            // Replace the view with the corresponding props value if not a trainRun view
            return {__pickMe: filterTypeEval(obj, props || {})};
          },
        ],
        // Otherwise return obj as is
        [T, identity],
      ])(obj);
    },
    cemitFilterWithoutTypename,
  );
};

/**
 * Replaces a CemitFilter with another CemitFilter for each child CemitFilter in
 * cemitFilter matching the filterTypeTest
 * @param filterTypeTest
 * @param cemitFilterTypeReplace
 * @param cemitFilter
 * @param props
 */
const applyDeepOverReplacesMatches = (
  filterTypeTest: CemitFilterTypeTest,
  cemitFilterTypeReplace: CemitFilterTypeReplace,
  cemitFilter: CemitFilter,
  props: Perhaps<CemitFilterProps>,
) => {
  return applyDeepWithKeyWithRecurseArraysAndMapObjs(
    (_l: any, r: any) => r,
    (_key: string, obj: CemitFilter) => {
      return cond([
        [
          (obj: CemitFilter<Cemited>) => {
            return filterTypeTest(obj, props || {});
          },
          (obj: CemitFilter<Cemited>) => {
            // Replace the view with the corresponding props value if not a trainRun view
            return cemitFilterTypeReplace(obj, props || {});
          },
        ],
        // Otherwise return obj as is
        [T, identity],
      ])(obj);
    },
    cemitFilter,
  );
};

/**
 * Adds or removes a filter type
 * @param cemitFilter
 * @param isTypeFunc Checks for the desired type
 * @param updateFunc
 * @param childFilterTypename
 * @param props
 * @returns {*}
 */
export const updateFilterTypeInFilters = <
  T extends Cemited,
  FT extends Cemited<FT>,
  P extends CemitFilterProps = CemitFilterProps,
>(
  cemitFilter: CemitFilter,
  isTypeFunc: CemitFilterTypeTest,
  updateFunc: CemitFilterUpdater,
  childFilterTypename: CemitTypename,
  props: Perhaps<P> = undefined,
): FT => {
  return overClassOrType(
    lensProp('allPass'),
    (allPassObjs: CemitFilter[] = []) => {
      // Try to find an any that already has the filter type so we can add/remove to/from there
      const existingFilterIndex = findIndex((allPassObj: CemitFilter) => {
        return isTypeFunc(allPassObj, props);
      }, allPassObjs);

      const cemitFiltersOfType =
        existingFilterIndex >= 0
          ? allPassObjs
          : concat(allPassObjs, [
              // Create an empty CemitFilter child type
              clsOrType<FT>(childFilterTypename, {}),
            ]);
      const lensIndexForType = lensIndex<CemitFilter>(
        existingFilterIndex >= 0 ? existingFilterIndex : length(allPassObjs),
      );

      const updated = overClassOrTypeList(
        // If an existing filter exists of th type, add the new filter to the any property
        // Otherwise concat a {any:[]} to allPassObjs and add it to that any
        lensIndexForType,
        (existingFilter: CemitFilter) => {
          return updateFunc(existingFilter);
        },
        cemitFiltersOfType,
      );
      return updated;
    },
    cemitFilter,
  );
};

/**
 * Merges incomingCemitFilters into existingCemitFilter. If existingCemitFilter.any is defined, the incomingCemitFilters
 * are placed within it and filtered with what is already there for uniqueness. If  existingCemitFilter.any is not
 * defined but all incomingCemitFilters have a defined equals or includes, all of the ids from the incoming equals
 * and incliudes are chained uniquely and concatted to those already in the existingCemitFilter equals or includes.
 * @param existingCemitFilter
 * @param incomingCemitFilters
 * @param rightSideExpression
 */
export const mergeUniqueCemitFiltersUpdateFunc = <
  T extends Cemited,
  FT extends CemitFilter<T>,
>(
  existingCemitFilter: FT,
  incomingCemitFilters: FT[],
  rightSideExpression: any,
) => {
  if (
    !equals(
      1,
      length(
        uniq(
          map(prop('__typename'), [existingCemitFilter, ...(incomingCemitFilters || [])]),
        ),
      ),
    )
  ) {
    throw Error(
      `Configuration error. existingCemitFilter and incomingCemitFilters must all have the same __typename`,
    );
  }
  if (propOr(false, 'any', existingCemitFilter)) {
    // If the existing CemitFilter has a defined any value
    // We operate with the any array and put the existingCemitFilter in it.
    // We currently avoid duplicates by taking all unique by their equals or includes property.
    // TODO we could also consolidate the filters here by combining all equals and includes ids into one filter
    return overClassOrType(
      lensProp('any'),
      (existingFilters: FT[]) => {
        return uniqBy(
          (
            filter: CemitFilter,
          ):
            | [CemitFilterComponentOrLiteral, CemitFilterComponentOrLiteral]
            | [CemitFilterComponentOrLiteral[], CemitFilterComponentOrLiteral] => {
            return filter.equals || filter.includes;
          },
          concat(
            toArrayIfNot(existingFilters) as FT[],
            toArrayIfNot(incomingCemitFilters) as FT[],
          ),
        );
      },
      existingCemitFilter,
    );
  } else if (
    all(either(propOr(false, 'equals'), propOr(false, 'includes')), incomingCemitFilters)
  ) {
    // Combine the existing and cemitFilterChildren equals ids
    const ids: string[] = uniq(
      chain(idsFromEqualsOrIncludes, [existingCemitFilter, ...incomingCemitFilters]),
    );
    if (length(ids) > 1) {
      return compose(
        // set existingCemitFilter.includes
        (existingCemitFilter: CemitFilter) => {
          return overClassOrType(
            lensProp('includes'),
            () => {
              return [ids, rightSideExpression] as CemitFilterExpressionPair<
                CemitFilterComponentOrLiteral[]
              >;
            },
            existingCemitFilter,
          );
        },
        // undefine existingCemitFilter.equals if defined
        (existingCemitFilter: CemitFilter) => {
          return overClassOrType(
            lensProp('equals'),
            () => undefined,
            existingCemitFilter,
          );
        },
      )(existingCemitFilter);
    } else {
      return compose(
        // set existingCemitFilter.equals to 1 di or undefined
        (existingCemitFilter: CemitFilter) => {
          return overClassOrType(
            lensProp('equals'),
            () => {
              return [
                head(ids),
                rightSideExpression,
              ] as CemitFilterExpressionPair<CemitFilterComponentOrLiteral>;
            },
            existingCemitFilter,
          );
        },
        // undefine existingCemitFilter.includes if defined
        (existingCemitFilter: CemitFilter) => {
          return overClassOrType(
            lensProp('includes'),
            () => undefined,
            existingCemitFilter,
          );
        },
      )(existingCemitFilter);
    }
  } else {
    throw new Error(
      'Expected the existing CemitFilter to have an any property or for all children to have' +
        'an equals or includes property. There is no defined way to merge these filters',
    );
  }
};

/**
 * Merges parentCemitFilter and childCemitFilter when the parent changes
 * For now this just merges at the top allPass level
 * @param parentCemitFilter
 * @param childCemitFilter
 * @returns {*}
 */
export const mergeCemitFilterOnParentUpdate = (
  parentCemitFilter: CemitFilter,
  childCemitFilter: CemitFilter,
): CemitFilter => {
  const childByTypename = indexBy(prop('__typename'), childCemitFilter.allPass);
  const parentByTypename = indexBy(prop('__typename'), parentCemitFilter.allPass);
  const updatedAllPass = values(mergeRightIfDefined(childByTypename, parentByTypename));

  // We can use the child or parent here, arbitrarily picking parent
  const updatedParent = setClassOrType(
    lensProp('allPass'),
    updatedAllPass,
    parentCemitFilter,
  );

  // Set the parent on the new filter
  return setClassOrType(lensProp('parent'), parentCemitFilter, updatedParent);
};

/**
 * Returns a list of ids, either a single list based on the left side of an equals expression
 * or the list based on the left side of an includes expression
 * @param cemitFilterEvaluated
 * @retursn the ids in a list or an empty list
 */
export const idsFromEqualsOrIncludes = (cemitFilterEvaluated: CemitFilter): string[] => {
  return cond([
    [
      propOr(false, 'equals'),
      (filter: CemitFilter): string[] => {
        return [filter.equals[0]];
      },
    ],
    [
      propOr(false, 'includes'),
      (filter: CemitFilter): string[] => {
        return filter.includes[0];
      },
    ],
    [T, always([])],
  ])(cemitFilterEvaluated) as string[];
};

/**
 * Removes the given ids from cemitFilterEvaluated that are located at the equals or includes property
 * @param cemitFilterEvaluated
 * @param ids
 */
export const removeIdsFromEqualsOrIncludes = (
  cemitFilterEvaluated: CemitFilter,
  ids: number[],
): CemitFilter => {
  return cond([
    [
      propOr(false, 'equals'),
      (filter: CemitFilter): string[] => {
        // Remove the equals attribute if the id is in ids
        if ((includes(filter.equals[0]), ids)) {
          const clonedFilter = clone(filter);
          delete clonedFilter['equals'];
          return clonedFilter;
        } else {
          return filter;
        }
      },
    ],
    [
      propOr(false, 'includes'),
      (filter: CemitFilter): string[] => {
        return over(
          lensPath(['includes', 0]),
          (existingIds) => {
            // Return the existingIds not in ids
            return difference(existingIds, ids);
          },
          filter,
        );
      },
    ],
    [T, identity],
  ])(cemitFilterEvaluated) as CemitFilter;
};

/**
 * Removes filters by type or by type and those containing removeInstances
 * @param filterTypeTest Finds CemitFilters of subclass type FT
 * @param filterTypeEval Evaluates the CemitFilters of subclass type FT. This is only needed if the
 * filters have variables that need to be resolved against props
 * @param cemitFilter The top level CemitFilter in which to seek out and remove subclass instances of type FT
 * @param removeInstances A single item or array of items to remove.
 * If undefined, remove all
 * @param props
 * @template T  the type of the removed instances
 * @template FT  the CemitFilter subclass that is sought to find instances to remove.
 * @returns {*}
 */
export const removeCemitFiltersOfType = <FT extends CemitFilter>(
  filterTypeTest: CemitFilterTypeTest,
  filterTypeEval: CemitFilterTypeEval,
  cemitFilter: CemitFilter,
  removeInstances: Perhaps<Partial<FT['modelType']>[]> = undefined,
  props: any = {},
): CemitFilter => {
  const modelInstanceIdsToRemove: Perhaps<Record<string, FT['modelType']>> =
    removeInstances && indexBy(prop('id'), toArrayIfNot(removeInstances!));
  const modifiedFilterTypeTest = (obj: CemitFilter): boolean => {
    if (!filterTypeTest(obj)) {
      return false;
    }
    if (!modelInstanceIdsToRemove) {
      // Everything matches for removal
      return true;
    }
    const evaluated = filterTypeEval(obj, props);
    const filterIds: string[] = idsFromEqualsOrIncludes(evaluated);
    // Return a match if any match. It means we have to remove this Filter
    // and replace it with another
    return any((filterId: string): boolean => {
      return has(filterId, modelInstanceIdsToRemove);
    }, filterIds);
  };

  const filterAfterRemovals = compose(
    (obj: CemitFilter) => {
      return mergeDeepWith(
        (_l: any, r: any) => {
          return Array.isArray(r) ? compact(r) : r;
        },
        obj,
        obj,
      );
    },
    (obj: Record<string, any>) => {
      // Unflatten the object
      return unflattenObj(obj) as CemitFilter;
    },
    // Remove values with the __pickMe prop
    (flattened: Record<string, any>) => {
      return filter((obj: Record<string, any>) => {
        return !is(Object, obj) || !obj.__pickMe;
      }, flattened);
    },
    (obj: Record<string, any>) => {
      // Flatten to the point that __pickMe is a key
      return flattenObjUntil(
        (obj: Record<string, any>) => propOr(false, '__pickMe', obj),
        obj,
      );
    },
    (cemitFilterWithoutTypename: FT) => {
      return applyDeepOverFilterMarkMatches<FT>(
        removeIdsFromEqualsOrIncludes,
        filterTypeEval,
        cemitFilterWithoutTypename,
        props,
      );
    },
  )(cemitFilter);
  return clsOrType<FT>(cemitFilter.__typename, filterAfterRemovals);
};

/**
 * Replaces the instances specified by removeInstances from the cemitFilter
 * @param filterTypeTest Finds CemitFilters of subclass type FT
 * @param filterTypeEval Evaluates the CemitFilters of subclass type FT. This is only needed if the
 * filters have variables that need to be resolved against props
 * @param cemitFilter The top level CemitFilter in which to seek out and remove subclass instances of type FT
 * @param removeInstances A single item or array of items to remove.
 * If undefined, remove all
 * @param props
 * @template T  the type of the removed instances
 * @template FT  the CemitFilter subclass that is sought to find instances to remove.
 * @returns {*}
 */
export const replaceCemitFiltersOfType = <FT extends CemitFilter>(
  filterTypeTest: CemitFilterTypeTest,
  filterTypeEval: CemitFilterTypeEval,
  cemitFilter: CemitFilter,
  removeInstances: Perhaps<Partial<FT['modelType']>[]>,
  props: any = {},
): CemitFilter => {
  const modelInstanceIdsToRemove = map(prop('id'), toArrayIfNot(removeInstances!));
  // Replaces the given filter with another, removing removeInstances first
  const modifiedFilterTypeReplacement = (obj: CemitFilter): CemitFilter => {
    if (!filterTypeTest(obj)) {
      return obj;
    }
    const evaluated = filterTypeEval(obj, props);
    return removeIdsFromEqualsOrIncludes(evaluated, modelInstanceIdsToRemove);
  };

  const filterAfterRemovals = applyDeepOverReplacesMatches(
    filterTypeTest,
    modifiedFilterTypeReplacement,
    cemitFilter,
    props,
  );
  return clsOrType<FT>(cemitFilter.__typename, filterAfterRemovals);
};

/**
 * Adds a new cemitFilterChildren to cemitFilter
 * @param isCemitFilter
 * @param cemitFilter
 * @param cemitFilterChildren
 * @param rightSideExpression
 * @param props
 * @returns {*}
 */
export const addCemitFilters = <
  T extends Cemited,
  FT extends CemitFilter<T> = CemitFilter<T>,
  P extends CemitFilterProps = CemitFilterProps,
>(
  isCemitFilter: CemitFilterTypeTest,
  rightSideExpression: any,
  cemitFilter: CemitFilter,
  cemitFilterChildren: FT[],
  props: Perhaps<P> = undefined,
): CemitFilter => {
  if (!length(cemitFilterChildren)) {
    // Do nothing if the children are empty
    if (Array.isArray(cemitFilter)) {
      throw new Error('cemitFilter was array instead of CemitFilter');
    }
    return cemitFilter;
  }
  return updateFilterTypeInFilters<T, FT>(
    cemitFilter,
    isCemitFilter,
    (existingCemitFilter: FT) => {
      return mergeUniqueCemitFiltersUpdateFunc(
        existingCemitFilter,
        cemitFilterChildren,
        rightSideExpression,
      );
    },
    cemitFilterChildren[0].__typename,
    props,
  );
};

/**
 * If a maximumAllowed number is specified and incomingInstances + extractInstancesFromFilter instances
 * are greater than maximumAllowed, push out the excess instances from the end of the existing
 * and return a new filter with those instances removed. The new incoming instances are added
 * @param isCemitFilter used to search CemitFilter for the target filter type TF
 * @param evalCemitFilter evals the CemitFilter (usually not needed)
 * @param extractInstancesFromFilter extraction function for the type TF
 * @param cemitFilterRightSideExpression
 * @param cemitTypename
 * @param cemitFilter The parent filter containing a filter of type TF
 * @param incomingInstances The incoming instances of type TF['modelType']
 * @param props The props used to resolve instances when extracting
 * @param maximumAllowed The maximum instances allowed. Undefined means no maximum
 */
export const removeExcessiveInstancesFromFilterAndAddNew = <
  TF extends CemitFilter,
  P extends CemitFilterProps,
>(
  isCemitFilter: CemitFilterTypeTest,
  evalCemitFilter: CemitFilterTypeEval,
  extractInstancesFromFilter: (cemitFilter: CemitFilter, props?: P) => TF['modelType'][],
  cemitFilterRightSideExpression: any,
  cemitTypename: CemitTypename,
  cemitFilter: CemitFilter,
  incomingInstances: TF['modelType'][],
  props?: P,
  maximumAllowed?: Perhaps<number>,
): CemitFilter | never => {
  type T = TF['modelType'];
  const len = length(incomingInstances);
  if (len > (maximumAllowed || Infinity)) {
    throw new Error('Attempt to add more instances than allowed by the filter');
  } else if (len == 0) {
    return cemitFilter;
  }

  // Extract the existing instances from the filter
  const existingInstances: T[] = extractInstancesFromFilter(cemitFilter, props);
  // Find the incoming instances that don't match the existing. These will be added to the filter
  const incomingInstancesWithoutExisting: T[] =
    incomingInstancesWithoutMatchingExisting<T>(existingInstances, incomingInstances);
  // Nothing new
  if (length(incomingInstancesWithoutExisting) == 0) {
    return cemitFilter;
  }

  // Get the number of existing instances that must be removed to fit the new.
  const lengthExisting = length(existingInstances);
  const removeCount =
    length(incomingInstancesWithoutExisting) +
    lengthExisting -
    (maximumAllowed || Infinity);

  const cemitFilterAfterPossibleRemovals = when(
    (_cemitFilter: CemitFilter) => {
      return removeCount > 0;
    },
    (cemitFilter: CemitFilter) => {
      // We can remove removeCount from the tail of these instances
      const existingInstancesWithoutIncoming: T[] =
        incomingInstancesWithoutMatchingExisting<T>(incomingInstances, existingInstances);
      const removeExistingInstances: T[] = slice(
        -removeCount,
        Infinity,
        existingInstancesWithoutIncoming,
      );
      // Remove, returning the filter
      return removeCemitFiltersOfType<TF>(
        isCemitFilter,
        evalCemitFilter,
        cemitFilter,
        removeExistingInstances,
        props,
      );
    },
  )(cemitFilter);

  // Create a new filter using equals for one or includes for multiple incomingInstancesWithoutExisting
  const cemitFilterOfType: TF = createFilterOfType<TF>(
    cemitTypename,
    cemitFilterRightSideExpression,
    incomingInstancesWithoutExisting,
  );

  // Add the type filter to cemitFilterAfterPossibleRemovals. The typeFilter will be merged with the existing one
  // in cemitFilterAfterPossibleRemovals
  const updatedCemitFilter = addCemitFilters(
    isCemitFilter,
    cemitFilterRightSideExpression,
    cemitFilterAfterPossibleRemovals,
    [cemitFilterOfType],
    props,
  );
  return updatedCemitFilter;
};

/**
 * Creates a filter of the given type
 * @param filterTypeName
 * @param rightSideExpression
 * @param instances
 */
export const createFilterOfType = <TF extends CemitFilter>(
  filterTypeName: CemitTypename,
  rightSideExpression: any,
  instances: TF['modelType'][],
): TF => {
  const cemitFilterOfType = {
    [length(instances) > 1 ? 'includes' : 'equals']: [
      (length(instances) > 1 ? identity : head)(
        map((instance: TF['modelType']) => {
          return instance.id;
        }, instances),
      ),
      rightSideExpression,
    ],
  };
  return clsOrType<TF>(filterTypeName, cemitFilterOfType);
};

/**
 * Calls extractFunction with cemitFilter and props if either of the latter have changed
 * @param extractFunction
 * @param cemitFilter
 * @param props
 */
export const useMemoExtractFilterInstances = <
  T extends Cemited,
  FP extends CemitFilterProps,
>(
  extractFunction: (cemitFilter: CemitFilter, props: FP) => T[],
  cemitFilter: CemitFilter,
  props: FP,
): T[] => {
  return useMemo(() => {
    return extractFunction(cemitFilter, props);
  }, [cemitFilter, props]);
};

/**
 * Calls extractLabelsFunction with cemitFilter and props if either of the latter have changed
 * @param extractLabelsFunction
 * @param cemitFilter
 * @param props
 */
export const useMemoExtractFilterInstanceLabels = <FP extends CemitFilterProps>(
  extractLabelsFunction: (cemitFilter: CemitFilter, props: FP) => string[],
  cemitFilter: CemitFilter,
  props: FP,
): string[] => {
  return useMemo(() => {
    return extractLabelsFunction(cemitFilter, props);
  }, [cemitFilter, props]);
};
