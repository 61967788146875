import {Cemited} from '../../types/cemited';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {filter} from 'ramda';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';

/**
 * Create an object of the given type T with the corresponding typename
 * @param typename
 * @param t
 */
export const typeObject = <T extends Cemited = Cemited>(
  typename: CemitTypename,
  t: T | Omit<T, '__typename'>,
): T => {
  if (!t) {
    throw new Error(
      't is undefined. Check the stacktrace when debugging to find the undefined variable',
    );
  }
  // Use this instead of omit so that omit doesn't destroy getters
  const props: T = Object.create(
    Object.getPrototypeOf(t),
    Object.getOwnPropertyDescriptors(t),
  );
  props['__typename'] = typename;
  return props;
};

/**
 * Declassify an object so we can send it to a web worker
 * @param typename
 * @param t
 */
export const declassifyObject = <T extends Cemited = Cemited>(
  typename: CemitTypename,
  t: T | Omit<T, '__typename'>,
): T => {
  // Use this instead of omit so that omit doesn't destroy getters
  const descriptors = filter(
    (descriptor: PropertyDescriptor) => !descriptor.set && !descriptor.get,
    Object.getOwnPropertyDescriptors(t),
  );
  const props: T = Object.create(Object.getPrototypeOf(Object), descriptors);
  props['__typename'] = typename;
  return props;
};

/**
 * Undoes declassifyObject
 * @param t
 */
export const reclassifyObject = <T extends Cemited = Cemited>(t: T) => {
  return clsOrType<T>(t.__typename, t);
};
