import {MapSourceChangeStatus} from '../../types/mapbox/mapSourceChangeStatus';
import {ChangeTypeEnum} from '../../types/mapbox/changeTypeEnum.ts';
import {filter, find, length, map, uniq} from 'ramda';
import {concatReplacingMatchesWithRight} from '../typeUtils/mergeTypeUtils.ts';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {compact} from '@rescapes/ramda';
import {toArrayIfNot} from '../../utils/functional/functionalUtils.ts';

/**
 * Upates mapProps.changeStatuses to the given changeStatuses that are not
 * ChangeTypeEnum.unchanged. Like-named changeStatuses are updated with the incoming
 * @param setChangeStatuses
 * @param changeStatuses
 */
export const setChangeStatusesWithMerge = (
  setChangeStatuses: StateSetter<MapSourceChangeStatus[]>,
  changeStatuses: MapSourceChangeStatus[],
): void => {
  const filteredChangeStatuses = filter((changeStatus: MapSourceChangeStatus) => {
    return changeStatus.changeType != ChangeTypeEnum.unchanged;
  }, changeStatuses);
  setChangeStatuses((existingChangeStatuses: MapSourceChangeStatus[]) => {
    return concatReplacingMatchesWithRight<MapSourceChangeStatus>(
      existingChangeStatuses,
      filteredChangeStatuses,
      'name',
    );
  });
};

/**
 * Find at least one ChangeStatuses whose names starts with the prefix or prefixes.
 * Those prefixes with a match are returned
 * @param changeStatuses
 * @param prefixes
 */
export const changeStatusesMatchNamePrefixes = (
  changeStatuses: MapSourceChangeStatus[],
  prefixes: string | string[],
): boolean => {
  const prefixesAsArray = toArrayIfNot(prefixes);
  const matchingPrefixes = uniq(
    compact(
      map((changeStatus: MapSourceChangeStatus) => {
        return find((prefix: string) => {
          return changeStatus.name.startsWith(prefix);
        }, prefixesAsArray);
      }, changeStatuses || []),
    ),
  );
  return length(matchingPrefixes) == length(prefixes);
};
