import {Dispatch, SetStateAction, createContext} from 'react';
import {UserStateCombined} from 'types/userState/userState';
import {Perhaps} from 'types/typeHelpers/perhaps';

export type UserContextType = {
  userState: UserStateCombined;
  setUserState: Dispatch<SetStateAction<UserStateCombined>>;
};

export const UserContext = createContext<Perhaps<UserContextType>>(undefined);
