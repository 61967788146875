import React, {
  DependencyList,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {isNil} from 'ramda';
import {useEventListener, useIsomorphicLayoutEffect} from 'usehooks-ts';
import {Size} from '../../types/layout/size';
import {SetOffsetLeft} from '../../types/layout/setOffsetLeft';
import {StateSetter} from '../../types/hookHelpers/stateSetter';

import {TrainGroup} from '../../types/trainGroups/trainGroup';

/**
 * Watches the offset left of the target
 * @param target Element defined by useRef
 * @param displayProps The window size
 * @param setOffsetLeft setter Hook
 * if the stations are limited
 * @param recalculateOffsetLefts Toggle to tell the effect to recalculate the offset
 * @param [spaceGeospatially] Space the stations geospatioally
 * @returns {Void}
 */
export const useOffsetLeft = ({
  target,
  containerSize,
  setOffsetLeft,
  recalculateOffsetLefts,
  trainGroup,
  spaceGeospatially,
}: {
  target: React.RefObject<HTMLElement>;
  containerSize: Size;
  setOffsetLeft: SetOffsetLeft;
  recalculateOffsetLefts: Date;
  trainGroup: TrainGroup;
  spaceGeospatially: boolean;
}) => {
  useLayoutEffect(() => {
    // If spaceGeospatially, we don't need to setOffsetLeft, it can be calculated by the TrainRunLine
    if (!(spaceGeospatially || isNil(target.current?.offsetLeft))) {
      setOffsetLeft((target.current as HTMLElement).offsetLeft);
    }
  }, [
    target,
    containerSize,
    trainGroup.trainRouteOrGroup!.trainDistanceInterval,
    recalculateOffsetLefts,
  ]);
};

/**
 * https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
 * @returns {{width: number, height: number}}
 */
const getWindowDimensions = (): Size => {
  const {innerWidth: width, innerHeight: height}: Window = window;
  return {
    width,
    height,
  };
};

/**
 * React Hook to respond to browser window dimensions
 * @returns {{width: number, height: number}}
 */
export function useWindowDimensions(): Size {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

/**
 * Generic useEffect hook that doesn't run initially
 * https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
 * @param func
 * @param deps
 */
export const useDidMountEffect = (func: Function, deps: any[]) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

/**
 * Generic useEffect hook that doesn't run initially and waits for loading to be false
 * https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
 * @param loading
 * @param func
 * @param dependencies
 */
export const useNotLoadingDidMountEffect = (
  loading: boolean,
  func: Function,
  dependencies: DependencyList,
) => {
  const didMount = useRef(false);
  useNotLoadingEffect(
    loading,
    () => {
      if (didMount.current) {
        func();
      } else {
        didMount.current = true;
      }
    },
    dependencies,
  );
};

/**
 * Keeps track of the given element changing size
 * Not that ref is the element itself, not a RefNode, copying { useElementSize } from 'usehooks-ts';
 * @returns a setRef, a size, and a ref that is the element itself
 */
function useElementSizeWithRef(): [
  StateSetter<HTMLElement | undefined>,
  Size,
  HTMLElement | undefined,
] {
  const [ref, setRef] = useState<HTMLElement | undefined>(undefined);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);
  useEventListener('resize', handleSize);
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);
  return [setRef, size, ref];
}

export default useElementSizeWithRef;
