/**
 * Controls what trafficSimComponents are visible. The Organization has access to all trafficSimComponents unless disabled by
 * their configuration on the server
 * Note, this list must mirror the train-organization repo frontend_options.
 */
export enum FrontendView {
  // General alerts. Disabling this disables
  alerts = 'alerts',
  // The organization shows maps
  map = 'map',

  // Shows the TrainFormations as a list
  trainFormationList = 'trainFormationList',
  // Shows the TrainFormations as a table
  trainFormationTable = 'trainFormationTable',
  // Shows the TrainFormations as a menu
  trainFormationMenu = 'trainFormationMenu',

  // Details about the TrainFormations of the active TrainGroups
  // Disabling this disables the detailTrainFormation* key below
  detailTrainFormation = 'detailTrainFormation',

  // Detail about active equipment of the select TrainGroup
  // Currently disabled by default on the frontend
  detailTrainFormationDiagram = 'detailTrainFormationDiagram',
  // Detail about active equipment of the select TrainGroup
  // Currently disabled by default on the frontend
  detailTrainFormationEquipment = 'detailTrainFormationEquipment',
  detailTrainFormationAlert = 'detailTrainFormationAlert',
  // Charts for the active TrainGroup(s)
  detailTrainFormationChart = 'detailTrainFormationChart',

  // This view is active if the organization has TrainGroups.
  // Disabling this disables all trainGroup(*) keys
  trainGroup = 'trainGroup',
  // Shows the TrainGroups as a list
  trainGroupList = 'trainGroupList',
  // Shows the TrainGroups as a table
  trainGroupTable = 'trainGroupTable',
  // Shows the TrainGroups as a menu
  trainGroupMenu = 'trainGroupMenu',

  // Detail about the TrainRuns of the active TrainGroups
  // This can indicate currently that a TrainGroupSingleTrainRun or TrainGroupOnlyTrainFormation is active
  // Disabling this disables the detailTrainGroup* keys below
  detailTrainGroup = 'detailTrainGroup',
  // Alerts for the active TrainGroup(s)
  detailTrainGroupAlert = 'detailTrainGroupAlert',
  // Charts for the active TrainGroup(s)
  detailTrainGroupChart = 'detailTrainGroupChart',

  // TODO Not used yet
  // Detail about a TrainGroupSingleTrainRun
  detailTrainGroupSingleTrainRun = 'detailTrainGroupSingleTrainRun',
  // Alerts for the active TrainGroupSingleTrainRun(s)
  detailTrainGroupSingleTrainRunAlert = 'detailTrainGroupSingleTrainRunAlert',
  // Charts for the active TrainGroupSingleTrainRun(s)
  detailTrainGroupSingleTrainRunChart = 'detailTrainGroupSingleTrainRunChart',

  // TODO Not used yet
  // Detail about a TrainGroup(s) where each has one or more TrainRuns
  detailTrainGroupWithTrainRuns = 'detailTrainGroupWithTrainRuns',
  // Alerts for the active TrainGroup(s) where each has one or more TrainRuns
  detailTrainGroupWithTrainRunsAlert = 'detailTrainGroupWithTrainRuns',
  // Charts for the active TrainGroup(s) where each has one or more TrainRuns
  detailTrainGroupWithTrainRunsChart = 'detailTrainGroupWithTrainRuns',

  // This view is active if the organization has TrainRoutes defined
  // Disabling this disables all detailTrainRoute(*) keys below
  trainRoute = 'trainRoute',
  // Shows detail about the TrainRoute(s) of the active TrainRouteGroup
  detailTrainRoute = 'detail',
  // Alerts for the active TrainRoute(s)
  detailTrainRouteAlert = 'detailTrainRouteAlert',
  // Charts for the active TrainRoute(s)
  detailTrainRouteChart = 'detailTrainRouteChart',

  // Wheelscan based on the Mantena application
  // Currently disabled by default on the frontend
  wheelScan = 'wheel_scan',
  // Power based on the Gothenburg application. For later integration ot this application into Visualizer
  // Currently disabled by default on the frontend
  power = 'power',
  // Currently disabled by default on the frontend
  // Switches based on the Gothenburg application. For later integration ot this application into Visualizer
  switch = 'switch',
}

/**
 * Types of FrontendViews
 */
export enum FrontendViewType {
  // Compact form of a component
  compact = 'compact',
  // Menus are a compact form,
  menu = 'menu',
  // Large form of a component
  large = 'large',
  // Lists are a long form
  list = 'list',
  // Tables are a long form
  table = 'table',

  chart = 'chart',
}
