import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {initStopOffsetLefts} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import {length} from 'ramda';
import {compact} from '@rescapes/ramda';
import {ScheduledStopPoint} from '../../../../types/stops/scheduledStopPoint';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {TrainRouteOrGroupLineFinalizedProps} from 'types/propTypes/trainPropTypes/trainRouteOrGroupLineProps.d.ts';

/**
 * Initializes the offsetLefts of the stops.
 * If config.spaceGeospatially is true it means we are postioning the stops absoultuely based on their geospatial
 * position along the TrainLine. We can calculate them immediately. Otherwise we are spacing the stops evenly
 * with flex and initialize the state to an empty array matching the number of visible stops. We then rely on
 * the TrainLineStations to call their setOffsetLeft to fill one of the items of this array
 * @param loading Do nothing if loading
 * @param spaceGeospatially if true init the list with absolution positions. Else
 * init to empty and wait to get values
 * @param limitedTrainDistanceInterval If non-undefined, then the offsetLefts have to be undefineded when the
 * distanceRange changes because the position of the stops is changing
 * @param distanceRange The distanceRange of the TrainRunOrGroup, used only for geospatially positioning
 * to adjust stops that to 0 or 100% if they are outside the interval
 * are
 * @param scheduledStopPoints The stops we are showing. We create a TrainLineStation for each of these
 * and draw lines between them
 * @param setOffsetLefts The setter
 * @param offsetLefts
 * @returns {[Object, Function]} The getter and setter
 */
export const useInitStopOffsetLefts = ({
  loading,
  trainRouteOrGroupLineProps,
  setOffsetLefts,
  scheduledStopPoints,
  offsetLefts,
}: {
  loading: boolean;
  trainRouteOrGroupLineProps: TrainRouteOrGroupLineFinalizedProps;
  setOffsetLefts: StateSetter<number[]>;
  scheduledStopPoints: ScheduledStopPoint[];
  offsetLefts: number[];
}) => {
  const {spaceGeospatially, limitedTrainDistanceInterval, trainDistanceInterval} =
    trainRouteOrGroupLineProps;
  useNotLoadingEffect(loading, () => {
    if (
      !spaceGeospatially &&
      !limitedTrainDistanceInterval?.distanceRange &&
      length(compact(offsetLefts))
    ) {
      // There's no need to undefined the offsetLefts if they are non-undefined and limitedTrainDistanceInterval is undefined
      // because changing distanceRange does nothing to the position of the stops
      return;
    }
    const stopOffsetLefts = initStopOffsetLefts(
      spaceGeospatially,
      limitedTrainDistanceInterval,
      trainDistanceInterval.distanceRange,
      scheduledStopPoints,
    );
    setOffsetLefts(stopOffsetLefts);
  }, [
    spaceGeospatially,
    limitedTrainDistanceInterval?.distanceRange,
    distanceRange,
    scheduledStopPoints,
  ]);
};
