import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AvstangtSpar from 'assets/dataUrlIcons/trafficSimAssets/icons/AvstangtSpar.svg';
import Backforbud from 'assets/dataUrlIcons/trafficSimAssets/icons/Backforbud.svg';
import AnnanBegransning from 'assets/dataUrlIcons/trafficSimAssets/icons/AnnanBegransning.svg';
import Hastighetsbegraansning from 'assets/dataUrlIcons/trafficSimAssets/icons/Hastighetsbegraansning.svg';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {
  deleteRemoteRestriction,
  getUpcomingRestrictions,
} from 'pages/trafficsim/api/net/repo.ts';
import {restrictionTypes} from 'pages/trafficsim/api/data/consts.ts';
import EditButton from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/SpecificButtons/EditButton/EditButton.tsx';
import DeleteButton from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/SpecificButtons/DeleteButton/DeleteButton.tsx';
import ZoomLocationButton from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/IconsButtons/ZoomLocationButton.tsx';
import DeleteConfirmationDialog from 'pages/trafficsim/trafficSimComponents/themedComponents/DeleteConfirmationDialog.tsx';
import {ACCESS_LEVELS} from 'pages/trafficsim/utils/access_levels.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';
import {falsyToUndefined} from 'utils/functional/functionalUtils.ts';
import {
  extractTrafficSimAccess,
  hasTrafficSimAdminAccess
} from "../../../../appUtils/authentication/authenticationUtils.ts";

const UpcomingLimitations = ({
  setActiveSubPage = (restriction) => {},
  onMoveMapToCoord = (coordinates) => {},
  onDeleteRestriction = (currentRestriction) => {},
  isExpanded,
}) => {
  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;
  const [filter, setFilter] = useState('');
  const [restrictions, setRestrictions] = useState([]);
  const [isLoadingData, setIsLoadingDataState] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentRestriction, setCurrentRestriction] = useState(null);

  const handleDeleteClick = (restriction) => {
    setCurrentRestriction(restriction);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    const response = await onDeleteRestriction(currentRestriction);
    if (response.ok) {
      setIsLoadingDataState(true);
    }
    setDialogOpen(false);
  };

  const icons = {
    closedTrack: AvstangtSpar,
    backProhibition: Backforbud,
    speedLimit: Hastighetsbegraansning,
    other: AnnanBegransning,
  };

  useEffect(() => {
    getUpcomingRestrictions().then((response) => {
      if (response !== null) {
        setRestrictions(response);
        setIsLoadingDataState(false);
      }
    });
  }, [isLoadingData]);
  const getIcon = (begransning) => {
    const IconSrc = icons[begransning];
    return IconSrc ? (
      <img src={IconSrc} alt={begransning} style={{marginRight: '8px'}} />
    ) : null;
  };

  const deleteRestriction = async (restriction) => {
    const response = await deleteRemoteRestriction(restriction);
    if (response) {
      setIsLoadingDataState(true);
    }
  };

  const editRestriction = async (restriction) => {
    setActiveSubPage(restriction);
  };

  const isNotReadOnlyAccess = () => {
    return hasTrafficSimAdminAccess(userState) || extractTrafficSimAccess(userState) !== ACCESS_LEVELS.READ_ONLY;
  };
  const Row = ({row}) => {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
          <TableCell
            align="left"
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 0,
              height: '100%',
            }}
          >
            {getIcon(row.selectedRestrictionType)}

            {restrictionTypes[row.selectedRestrictionType]}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.slutPlat.name} ${row.statPlas.name}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.berordaVaxlar}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.hastighet}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {row.orsakTillBegransning}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.restrictionStartDay} ${row.restrictionStartDayTime}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${row.restrictionEndDay} ${row.restrictionEndDayTime}`}
          </TableCell>
          <TableCell>
            {isNotReadOnlyAccess() && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="2px"
                marginTop="5px"
              >
                <EditButton onEdit={() => editRestriction(row)} />
                <DeleteButton onDelete={() => handleDeleteClick(row)} />
                <DeleteConfirmationDialog
                  open={isDialogOpen}
                  onClose={handleClose}
                  onConfirm={handleConfirmDelete}
                />
              </Box>
            )}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{margin: 1}}>
                <Typography variant="body2" gutterBottom>
                  Notering: {row.notering}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Begränsning på begäran av: {row.authorizedBy}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Begränsning inlagd av: {row.createdBy}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = restrictions.filter((item) => {
    return filter === '' || item.begransning === filter;
  });

  const filterDropdown = (
    <FormControl
      sx={{m: 0, minWidth: 260, marginBottom: 3, fontSize: '12px'}}
      size="small"
    >
      <InputLabel id="filter-label" sx={{fontSize: '14px'}}>
        Filter
      </InputLabel>
      <Select
        style={{width: '200px'}}
        labelId="filter-label"
        id="filter-select"
        value={filter}
        label="Filter"
        onChange={handleFilterChange}
        sx={{fontSize: '12px'}}
      >
        <MenuItem value="" sx={{fontSize: '12px'}}>
          Alla
        </MenuItem>
        <MenuItem value="Avstängt spår" sx={{fontSize: '12px'}}>
          Avstängt spår
        </MenuItem>
        <MenuItem value="Backförbud" sx={{fontSize: '12px'}}>
          Backförbud
        </MenuItem>
        <MenuItem value="Hastighetsbegränsning" sx={{fontSize: '12px'}}>
          Hastighetsbegränsning
        </MenuItem>
        <MenuItem value="Annan begränsning" sx={{fontSize: '12px'}}>
          Annan begränsning
        </MenuItem>
      </Select>
    </FormControl>
  );

  const drawerLayout = (
    <div style={{width: '280px'}}>
      <Grid container spacing={2}>
        {restrictions.map((item, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Card variant="outlined">
                <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                  <RestrictionContent
                    {...{
                      getIcon,
                      onMoveMapToCoord,
                      item,
                    }}
                  />
                  <TableInfo {...{item}} />
                  <MaybeDelete
                    {...{
                      isNotReadOnlyAccess,
                      editRestriction,
                      handleDeleteClick,
                      isDialogOpen,
                      handleClose,
                      handleConfirmDelete,
                      item,
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        {falsyToUndefined(isLoadingData) && (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </div>
  );

  const expandedLayout = (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Begränsning</TableCell>
            <TableCell>Plats</TableCell>
            <TableCell>Berörda</TableCell>
            <TableCell>Hastighet</TableCell>
            <TableCell>Orsak</TableCell>
            <TableCell>Starttid</TableCell>
            <TableCell>Sluttid</TableCell>
            <TableCell>Detaljer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {restrictions.map((row, index) => (
            <Row key={index} row={row} />
          ))}
          {isLoadingData && (
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
              <TableCell>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      {/*{filterDropdown}*/}
      {isExpanded ? expandedLayout : drawerLayout}
    </div>
  );
};

const RestrictionContent = ({getIcon, onMoveMapToCoord, item}) => {
  return (
    <Stack
      {...{
        direction: 'row',
        sx: {
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      }}
    >
      <Stack
        {...{
          direction: 'row',
          sx: {
            alignItems: 'center',
            justifyContent: 'start',
          },
        }}
      >
        {getIcon(item.selectedRestrictionType)}
        {restrictionTypes[item.selectedRestrictionType]}
      </Stack>
      <span>
        <ZoomLocationButton onClick={() => onMoveMapToCoord(item.coordinates)} />
      </span>
    </Stack>
  );
};

const MaybeDelete = ({
  isNotReadOnlyAccess,
  editRestriction,
  handleDeleteClick,
  isDialogOpen,
  handleClose,
  handleConfirmDelete,
  item,
}) => {
  return (
    falsyToUndefined(isNotReadOnlyAccess()) && (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap="2px"
        marginTop="5px"
      >
        <EditButton onEdit={() => editRestriction(item)} />
        <DeleteButton onDelete={() => handleDeleteClick(item)} />
        <DeleteConfirmationDialog
          open={isDialogOpen}
          onClose={handleClose}
          onConfirm={handleConfirmDelete}
        />
      </Box>
    )
  );
};

const TableInfo = ({item}) => {
  return (
    <Stack {...{direction: 'column'}}>
      <Typography variant="caption" color="text.secondary">
        <strong>Plats:</strong> {item.statPlas.name} - {item.slutPlat.name}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Berörda:</strong>
        {item.berordaVaxlar}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Hastighet:</strong> {item.hastighet}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Orsak:</strong> {item.orsakTillBegransning}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Starttid:</strong>{' '}
        {`${item.restrictionStartDay} ${item.restrictionStartDayTime}`}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        <strong>Sluttid:</strong>{' '}
        {`${item.restrictionEndDay} ${item.restrictionEndDayTime}`}
      </Typography>
    </Stack>
  );
};
export default UpcomingLimitations;
