import {darken} from '@mui/material';

export const TRAIN_API_DATE_ATTRIBUTES = [
  'start_date',
  'source:datetime',
  'departureDatetime',
  'arrivalDatetime',
  'medianDatetime',
  'datetimeIntervals',
  'serviceDatetimeRange',
  'timestamp',
];

// TODO move to organization style override object
export const DEFAULT_SERVICE_LINE_COLOR = '#767676';
export const DEFAULT_SERVICE_LINE_DARKENED = darken('#767676', 0.5);
export const TRAIN_RUN_POSITION_LAYER = 'trains-run-position-layer';
// TODO currently how in km far we transform track perpendicular to the main track to show data
export const MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL = 0.01;
export const TRAIN_RUN_GROUP_EXTRUDED_COLUMN_SOURCE_PREFIX =
  'train-run-group-3d-source-extruded';
export const TRAIN_RUN_GROUP_EXTRUDED_COLUMN_LAYER_PREFIX =
  'train-run-group-3d-layer-extruded';

// We currently allow only 3 active TrainGroups of TrainGroupOnlyTrainFormation or TrainGroupSingleTrainRun,
// where the latter takes priority if there is one
export const TRAIN_GROUP_MAX_ACTIVE_COUNT = 3;

// These are the sources and layers used to visualize TrainGroups on the map. We add
// a TrainGroup id to make them unique
export const MAP_USER_TRAIN_RUN_SOURCE_PREFIX = 'user-trains-run-source';
export const MAP_USER_TRAIN_RUN_LAYER_PREFIX = 'user-trains-run-layer';
export const MAP_USER_TRAIN_RUN_THRESHOLD_SOURCE_PREFIX =
  'user-trains-run-threshold-source';
export const MAP_USER_TRAIN_RUN_THRESHOLD_LAYER_PREFIX =
  'user-trains-run-threshold-layer';

// Ride Comfort TrainGroup Mapbox source and layer prefixes. The TrainGroup id is appended to each tom make it unique
export const ALERT_TRAIN_GROUP_SOURCE_PREFIX = 'alert-tg-source';
export const ALERT_TRAIN_GROUP_LAYER_PREFIX = 'alert-tg-layer';

// Realtime TrainGroup Mapbox source and layer prefixes. The TrainGroup id is appended to each tom make it unique
export const REALTIME_TRAIN_GROUP_SOURCE_PREFIX = 'realtime-tg-source';
export const REALTIME_TRAIN_GROUP_LAYER_PREFIX = 'realtime-tg-layer';

export const VISION_TRAIN_GROUP_SOURCE_PREFIX = 'vision-tg-source';
export const VISION_TRAIN_GROUP_LAYER_PREFIX = 'vision-tg-layer';

// Chart hover TrainGroup Mapbox source and layer prefixes. The TrainGroup id is appended to each tom make it unique
export const CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX = 'chart-hover-tg-trace-source';
export const CHART_HOVER_TRAIN_GROUP_LAYER_PREFIX = 'chart-hover-tg-trace-layer';

// Default to accept sensorDataPoints within 15 minutes of a trainRun start and end time. This compensates for
// late trains.
export const TRAIN_RUN_IMUPOINT_MINUTE_BUFFER = 15;

/**
 * Resolves the correct API for the organization based on the given method if any
 * TODO organizations will not have specific treatment in the future
 */
export const organizationTrainUrlLookup = ({
  cargonetResolveApiUrl,

  flytogetResolveApiUrl,
}) => {
  return {
    Cargonet: cargonetResolveApiUrl,
    Flytoget: flytogetResolveApiUrl,
  };
};
