import {
  ChartPayloadItem,
  ChartPayloadItemMinimized,
} from '../../types/dataVisualizations/chartPayloadItem';
import {head, join, map} from 'ramda';
import {pointFeatureFromPayLoadItem} from '../chart/chartMapInteractionUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Feature, Point} from 'geojson';

/***
 * Extracts or creates a Point Feature from the first item of a recharts payload. If the payload has no items, undefined is returned
 * @param payload
 * @returns {*}
 */
export const singlePointFeatureFromPayload = (payload: ChartPayloadItemMinimized[]) => {
  // TODO we currently use head, but maybe we should consider all payload items
  const payloadItem = head(payload || []);
  if (!payloadItem) {
    return undefined;
  }
  return pointFeatureFromPayLoadItem(payloadItem);
};
export const pointsFeatureFromPayload = (
  payload: ChartPayloadItemMinimized[],
): Perhaps<Feature<Point>[]> => {
  // TODO we currently use head, but maybe we should consider all payload items
  return (
    payload &&
    map((payloadItem) => {
      return pointFeatureFromPayLoadItem(payloadItem);
    }, payload)
  );
};

/**
 * Hash the ChartPayloadItem[] for comparison to the previous.
 * Recharts keeps sending events to rener the Tooltip so we need to return undefined
 * if nothing has changed
 * @param chartPayloadItems
 */
export const hashPayload = (chartPayloadItems: ChartPayloadItem[]) => {
  // Hash payloads by name and time. This is required because payloadItems keep updating on hover without moving
  return join(
    ';',
    map((payloadItem: ChartPayloadItem) => {
      return join(',', [
        payloadItem.name,
        payloadItem.dataKey,
        // Hack TODO use chartDataConfig to get the unique identifier
        payloadItem.payload.properties?.time || '',
        payloadItem.payload.properties?.date || '',
        payloadItem.payload.properties?.distance || '',
      ]);
    }, chartPayloadItems),
  );
};
