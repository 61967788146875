import _PieMenu, { Slice } from 'react-pie-menu';
import { Box, Typography } from '@mui/material';
import React from 'react';

const PieMenu = _PieMenu['default'];

/**
 * Opens a pie menu to chose a TrainRoute or TrainRouteGroup based on
 * the clicked stations
 * @param trainRouteGroups The TrainRouteGroups.
 * @param trainRoutes The TrainRoutes
 * @param
 * @param x The x offset in pixels. Should be half the clicked element's width
 * @param y The y offset in pixels. Should be half the clicked element's height
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRouteFunctionChooser = ({ x, y }: {x: number, y: number}) => {
  return <Box sx={{
    position: 'fixed'
  }}
  >
    <PieMenu {...{
      radius: '100px',
      centerRadius: '30px',
      centerX: `${x}px`,
      centerY: `${y}px`,
      position: 'fixed'
    }}>
      <Slice onSelect={() => {
      }}>
        <Typography>From here</Typography>
      </Slice>
      <Slice onSelect={() => {
      }}>
        <Typography>All from here</Typography>
      </Slice>
      <Slice onSelect={() => {
      }}>
        <Typography>To here</Typography>
      </Slice>
      <Slice onSelect={() => {
      }}>
        <Typography>All to here</Typography>
      </Slice>
      <Slice onSelect={() => {
      }}>
        <Typography>Reverse</Typography>
      </Slice>
      <Slice onSelect={() => {
      }}>
        <Typography>Deselect</Typography>
      </Slice>
    </PieMenu>
  </Box>;
};
export default TrainRouteFunctionChooser;
