import React, {useContext} from 'react';
import {styled, useTheme} from '@mui/styles';
import {Switch} from '@mui/material';
import {Brightness4, Brightness7} from '@mui/icons-material';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
// import { Brightness4, Brightness7 } from '@material-ui/icons'
// import styled from '@mui/system/styled';

const StyledSwitchContainer = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
}));

const IconWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: theme.palette.common.gold,
}));

const Text = styled('div')(({theme}) => ({
  ...theme.typography.subtitle1,
  textAlign: 'center',
  color: theme.palette.common.textColor,
}));

const StyledSwitch = styled(Switch)(({theme}) => ({
  '& .MuiSwitch-thumb': {
    width: '24px',
    height: '24px',
    '@media (max-width: 600px)': {
      width: '18px',
      height: '18px',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '24px',
    opacity: 1,
    backgroundColor: theme.palette.common.gold,
    '@media (max-width: 600px)': {
      borderRadius: '18px',
    },
  },
}));

const ToggleSwitchStyled = () => {
  const {themeName, toggleTheme} = useContext(CemitThemeContext);
  const muiTheme = useTheme();

  return (
    <StyledSwitchContainer>
      <Text>{themeName === 'dark' ? 'Dark' : 'Light'}</Text>
      <StyledSwitch
        checked={themeName === 'dark'}
        onChange={toggleTheme}
        icon={
          <IconWrapper theme={muiTheme}>
            <Brightness4 style={{fontSize: '14px', color: 'rgb(127,108,41)'}} />
          </IconWrapper>
        }
        checkedIcon={
          <IconWrapper theme={muiTheme}>
            <Brightness7 style={{fontSize: '14px', color: 'rgb(127,108,41)'}} />
          </IconWrapper>
        }
      />
    </StyledSwitchContainer>
  );
};

export default ToggleSwitchStyled;
