import {CemitedClass} from 'classes/cemitAppCemitedClasses/cemitedClass.ts';
import {WeekYear} from 'types/datetime/weekYear';
import {cemitedConstructor} from 'classes/cemitAppCemitedClasses/cemitClassConstructor.ts';
import {addWeeks, format, getMonth, getYear} from 'date-fns';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {eqProps, equals, join, map, props} from 'ramda';
import {compact} from 'utils/functional/functionalUtils.ts';

export class WeekYearClass extends CemitedClass implements WeekYear {
  week: number;
  year: number;
  dateInterval: DateInterval;

  constructor(obj: WeekYear) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  /**
   * Describes the week in a friendly way
   */
  get labelComponents(): string[] {
    const yearsMatch = equals(
      getYear(this.dateInterval.start),
      getYear(this.dateInterval.end),
    );
    const monthsMatch = equals(
      getMonth(this.dateInterval.start),
      getMonth(this.dateInterval.end),
    );
    const label = join(
      ' - ',
      map(
        (date: DateInterval) => {
          return format(date, yearsMatch ? (monthsMatch ? 'd' : 'd LLL') : 'd LLL, yyyy');
        },
        props(['start', 'end'], this.dateInterval),
      ),
    );

    return [
      this.week.toString(),
      join(
        ', ',
        compact([
          label,
          // Join month and years with space subtracting components if there was a month or year change
          monthsMatch || yearsMatch
            ? join(
                ' ',
                compact([
                  monthsMatch ? format(this.dateInterval.start, 'LLL') : undefined,
                  yearsMatch ? this.year : undefined,
                ]),
              )
            : undefined,
        ]),
      ),
    ];
  }
}
