import {always, cond, filter, gte, includes, T} from 'ramda';
import {ChartDataCategoryConfig} from 'types/dataVisualizations/chartDataCategoryConfig';
import {DistanceRange} from 'types/distances/distanceRange';
import {velocityDataConfig} from './dataConfigVelocity.ts';
import {accelerationDataConfig} from './dataConfigAcceleration.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  AccelerationConfig,
  AccelerationOptions,
} from 'types/dataVisualizations/accelerationConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';

/**
 * Possible acceleration chart configurations, either the mean of the XYZ (forward/backward, sideways, vertical) axes or
 * just the Y (sideways). Y-acceleration is typically more important to passenger comfort than the other two.
 */
export const accelerationConfig: AccelerationOptions = clsOrType<AccelerationOptions>(
  CemitTypename.accelerationOptions,
  {
    xyz: clsOrType<AccelerationConfig>(CemitTypename.accelerationConfig, {
      accelerationKey: 'xyz',
      props: ['accXMean', 'accYMean', 'accZMean'],
      label: 'meanAcceleration',
      derivedProp: 'accXZYMean',
    }),
    y: clsOrType<AccelerationConfig>(CemitTypename.accelerationConfig, {
      accelerationKey: 'y',
      props: ['accYMean'],
      label: 'meanYAcceleration',
      derivedProp: 'accYMean',
    }),
  },
);

/**
 * The data visualization configuration. Returns DataPathConfigurations that are used for charts and Mapbox map
 * hovers. The DataPathConfigurations are limted to thos in organizationProps.organization.frontend
 * @param t
 * @param appProps
 * @returns
 */
export const dataPathConfiguration = ({
  appProps,
  organizationProps,
  trainProps,
}: TrainAppTrainDependencyProps): ChartDataCategoryConfig[] => {
  // TODO this should be specified using commonConfigs, not passed to accelerationDataConfig, etc
  const commonSpeedChartDataCategoryConfig = clsOrType<ChartDataCategoryConfig>(
    CemitTypename.chartCategoryDataConfig,
    {
      otherDisplayDataPaths: [],
    },
  );
  const dataPathConfigurations: ChartDataCategoryConfig[] = [
    accelerationDataConfig({
      appProps,
      commonChartDataCategoryConfig: commonSpeedChartDataCategoryConfig,
    }),
    velocityDataConfig({
      appProps,
      commonChartDataCategoryConfig: commonSpeedChartDataCategoryConfig,
    }),
  ];
  // Only returns the chartDataConfigs whose category match organization.frontendOptions.dataVisualizationOptions
  return filter((dataPathConfiguration: ChartDataCategoryConfig) => {
    return includes(
      dataPathConfiguration.category,
      organizationProps.organization!.frontendOptions.dataVisualizationOptions,
    );
  }, dataPathConfigurations);
};

/**
 * The number of SensorDataPoint to return for the currently selected DistanceRange on a RailwayLine(Network) or TrainRoute
 * For example, if the DistanceRange is >= 20000 meters, a SensorDataPoint is fetched very 50 meters.
 * This can be configured to show a reasonable number of SensorDataPoints at long and short distances.
 * @returns {*}
 */
export const distanceResolutionOfDistanceRange = (
  distanceRange: DistanceRange,
): number => {
  const distance = distanceRange.end - distanceRange.start;
  return cond([
    // >= 20 km
    [
      gte(20000),
      () => {
        return 50;
      },
    ],
    // >= 1 km
    [
      gte(1000),
      () => {
        return 10;
      },
    ],
    // < 100 meters, request SensorDataPoint every 100 meters
    [T, always(100)],
  ])(distance);
};
