import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  addIndex,
  append,
  compose,
  concat,
  filter,
  find,
  includes,
  indexBy,
  length,
  lensPath,
  map,
  prop,
  propOr,
  slice,
} from 'ramda';
import {mergeTrainGroup} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {Activity} from 'types/userState/activity';
import {CemitTypename} from 'types/cemitTypename.ts';
import {CEMIT_COMPARE_BASELINE, CEMIT_DEFAULT_TRAIN_COLORS} from 'theme/cemitColors.ts';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';

/**
 * Limits the number of allowed active TrainGroups
 * @param loading
 * @param trainGroups
 * @param maxCount
 */
export const limitAndColorActiveTrainGroups = <T extends TrainGroup = TrainGroup>(
  loading: boolean,
  trainGroups: TrainGroup[],
  activeTrainGroups: TrainGroup[],
  maxCount: Perhaps<number>,
) => {
  // any(trainGroup => trainGroup.activity.isBaseline, crudTrainGroups) ? USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT : USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT - 1;
  const visibleActiveTrainGroups: T[] = maxCount
    ? slice(0, maxCount, activeTrainGroups)
    : activeTrainGroups;
  const invisibleActiveTrainGroups: T[] = maxCount
    ? slice(maxCount, Infinity, activeTrainGroups)
    : [];

  let colorsInUse: string[] = map((activeTrainGroup: TrainGroup) => {
    return activeTrainGroup.activity.isActiveColor;
  }, trainGroups);

  const nextAvailableColor = (): string => {
    const nextColor: Perhaps<string> =
      find(
        (color: string) => !includes(color, colorsInUse),
        CEMIT_DEFAULT_TRAIN_COLORS,
      ) || Math.floor(Math.random() * 16777215).toString(16);
    colorsInUse = append<string>(nextColor!, colorsInUse);
    return nextColor;
  };

  const coloredVisibleActiveTrainGroups: T[] = addIndex(map)(
    (trainGroup: T, index: number): T => {
      const mergedTrainGroup: T = mergeTrainGroup<T>(trainGroup, {
        activity: clsOrType<Activity>(CemitTypename.activity, {
          isActiveColor:
            trainGroup.activity?.isActiveColor ||
            (trainGroup.activity?.isBaseline
              ? CEMIT_COMPARE_BASELINE
              : nextAvailableColor()),
        }),
      } as Partial<T>);
      return mergedTrainGroup;
    },
    visibleActiveTrainGroups,
  );
  // If there is no maxCount or no activeTrainGroups, return visibleActiveTrainGroups
  if (!maxCount || !length(activeTrainGroups)) {
    return visibleActiveTrainGroups;
  }
  const uncoloredInvisibleActiveTrainGroups: T[] = map((trainGroup: T) => {
    return clsOrType<T>(
      trainGroup.__typename,
      compose(
        (trainGroup: T) =>
          setClassOrType(lensPath(['activity', 'isActiveColor']), undefined, trainGroup),
        (trainGroup: T) =>
          setClassOrType(lensPath(['activity', 'isActive']), false, trainGroup),
      )(trainGroup),
    );
  }, invisibleActiveTrainGroups);
  // Assign a color to the visibleActiveTrainGroups lacking a color
  const updatedActiveTrainGroups: T[] = concat(
    coloredVisibleActiveTrainGroups,
    uncoloredInvisibleActiveTrainGroups,
  );

  // Inject the updated into our original trainGroups
  const idToActiveTrainGroup: Record<string, T> = indexBy(
    (trainGroup) => prop<string>('id', trainGroup),
    updatedActiveTrainGroups,
  );
  return map((trainGroup: T) => {
    return propOr<T, Record<string, T>, T>(
      trainGroup,
      trainGroup.id as string,
      idToActiveTrainGroup,
    );
  }, visibleActiveTrainGroups);
};
/**
 * Extract the activeTrainGroups without errors
 * @param loading
 * @param activeTrainGroups
 * @returns {*}
 */
export const useMemoActiveTrainGroupsWithoutErrors = ({
  loading,
  activeTrainGroups,
}: {
  loading: boolean;
  activeTrainGroups: Perhaps<TrainGroup[]>;
}): any => {
  return useNotLoadingMemo(
    loading || !activeTrainGroups,
    () => {
      return filter((trainGroup) => !(trainGroup?.error || false), activeTrainGroups!);
    },
    [activeTrainGroups],
  );
};
