export enum DirectionType {
  // Used by the TrainRouteGroup that represents all TrainRoutes
  all = 'all',
  // inbound (towards the city centre or transport hub)
  inbound = 'inbound',
  //outbound (from the city centre or transport hub)
  outbound = 'outbound',
  // clockwise (circular route in the clockwise direction)
  clockwise = 'clockwise',
  // anticlockwise (circular route in the anticlockwise direction)
  anticlockwise = 'anticlockwise'
}
