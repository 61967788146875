import { useState, useEffect } from 'react';

interface GeoJSONLoaderOptions {
  url: string; // URL to the GeoJSON file
}

export const useGeoJSONLoader = ({ url }: GeoJSONLoaderOptions) => {
  const [geojsonData, setGeojsonData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setGeojsonData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { geojsonData, loading, error };
};
