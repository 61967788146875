import {useContext} from 'react';
import CemitThemeContext from 'theme/CemitThemeContext.ts';

import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import StatusPageContainer from './StatusPageContainer.tsx';
const SoftprioAppContainer = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  const {themeName} = useContext(CemitThemeContext);
  const realtimeSpaceId = organizationProps?.organization?.realtimeSpaceId;
  // TODO Your code here
  return <StatusPageContainer spaceId={realtimeSpaceId!} pointId={''} />;
};

export default SoftprioAppContainer;
