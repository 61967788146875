import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {PerhapsIfLoading} from 'types/logic/requireIfLoaded.ts';
import {extractTrainFormationsAsTrainGroups} from 'appUtils/cemitFilterUtils/cemitFilterTrainFormationUtils.ts';
import {filter, head, lensProp, map} from 'ramda';
import {
  listsShallowEqual,
  sortThenIdListsEqual,
} from 'utils/functional/functionalUtils.ts';
import {CrudList} from 'types/crud/crudList';
import {
  activateTrainGroupOnlyTrainFormation,
  deactivateTrainGroupOnlyTrainFormation,
} from 'classes/typeCrud/trainGroupOnlyTrainFormationCrud.ts';
import {activateSomeAndDeactiveRestAndUpdate} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/activityUtils.ts';
import {extractDateIntervals} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {
  setClassOrType,
  setClassOrTypeIfChanged,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {dumpDateInterval} from 'utils/datetime/dateUtils.ts';

/**
 * cemitFilterWithTrainFormations is the authority for what TrainFormations are active and what the selected
 * DateInterval is
 * Sync crudTrainGroupOnlyTrainFormations to have the same active TrainFormations as cemitFilterWithTrainFormations
 * and activeDateInterval
 * @param loading
 * @param cemitFilterWithTrainFormations
 * @param crudTrainGroupOnlyTrainFormations
 */
export const useNotLoadingSyncCrudListToTrainFormationFilter = (
  loading: boolean,
  cemitFilterWithTrainFormations: PerhapsIfLoading<typeof loading, CemitFilter>,
  crudTrainGroupOnlyTrainFormations: PerhapsIfLoading<
    typeof loading,
    CrudList<TrainGroupOnlyTrainFormation>
  >,
) => {
  const dependencies = [
    cemitFilterWithTrainFormations,
    crudTrainGroupOnlyTrainFormations,
  ] as const;
  useNotLoadingEffect(
    loading,
    (cemitFilterWithTrainFormations, crudTrainGroupOnlyTrainFormations) => {
      // Did anything actually change
      const crudListTrainGroupOnlyFormations: TrainGroupOnlyTrainFormation[] =
        crudTrainGroupOnlyTrainFormations.list;
      const crudListTrainGroupOnlyFormationsActive = filter(
        (crudListTrainGroupOnlyFormation: TrainGroupOnlyTrainFormation) => {
          return Boolean(crudListTrainGroupOnlyFormation?.activity?.isActive);
        },
        crudListTrainGroupOnlyFormations,
      );
      const trainGroupOnlyTrainFormationsToActivate = extractTrainFormationsAsTrainGroups(
        cemitFilterWithTrainFormations,
        {
          trainGroupOnlyTrainFormations: crudListTrainGroupOnlyFormations,
        },
      );
      if (
        sortThenIdListsEqual(
          trainGroupOnlyTrainFormationsToActivate,
          crudListTrainGroupOnlyFormationsActive,
        )
      ) {
        return;
      }

      // Sync crudTrainGroupOnlyTrainFormations, activating existingCemitFilterTrainFormations
      activateSomeAndDeactiveRestAndUpdate(
        crudTrainGroupOnlyTrainFormations!,
        trainGroupOnlyTrainFormationsToActivate,
        activateTrainGroupOnlyTrainFormation,
        deactivateTrainGroupOnlyTrainFormation,
      );
    },
    dependencies,
  );

  // Update the activeDateInterval of crudTrainGroupOnlyTrainFormations.list items if the DateInterval
  // in cemitFilterWithTrainFormations changes
  useNotLoadingEffect(
    loading,
    (cemitFilterWithTrainFormations, crudTrainGroupOnlyTrainFormations) => {
      const dateInterval: Perhaps<DateInterval> = head(
        extractDateIntervals(cemitFilterWithTrainFormations),
      );
      if (dateInterval) {
        // Update the activeDateInterval of all crudTrainGroupOnlyTrainFormations.list items if they has changed
        const updatedList = map(
          (trainGroup: TrainGroupOnlyTrainFormation): TrainGroupOnlyTrainFormation => {
            return setClassOrTypeIfChanged(
              lensProp('activeDateInterval'),
              dateInterval,
              trainGroup,
            );
          },
          crudTrainGroupOnlyTrainFormations.list,
        );
        if (!listsShallowEqual(crudTrainGroupOnlyTrainFormations.list, updatedList)) {
          crudTrainGroupOnlyTrainFormations.updateOrCreateAll(updatedList);
        }
      }
    },
    dependencies,
  );
};
