import React from 'react';
import { TextField, Box } from '@mui/material';

const SearchBar = () => {
  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        fullWidth
        label="Search train, route, etc..."
        variant="outlined"
      />
    </Box>
  );
};

export default SearchBar;
