import {allPass, find, head, includes, map, prop, propEq} from 'ramda';

import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {scheduledStopPointsOfTrainRouteOrGroup} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import {findOrThrow, idsEqual} from 'utils/functional/functionalUtils.ts';
import {useMemo} from 'react';
import {
  TrainRoute,
  TrainRouteMinimized,
} from '../../../../types/trainRouteGroups/trainRoute';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {TrainRouteGroup} from '../../../../types/trainRouteGroups/trainRouteGroup';
import {
  TrainRouteOrGroup,
  TrainRouteOrGroupMinimized,
} from '../../../../types/trainRouteGroups/trainRouteOrGroup';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {DirectionType} from '../../../../types/trainRouteGroups/directionType.ts';

/**
 * If undefined, sets trainRoute to the first of TrainRoute
 * @param loading Do nothing if true
 * @param [{Object}] trainRouteGroups The Routes
 * @param trainRoute The current Route if any
 * @param setTrainRoute The setter
 */
export const useNotLoadingSetDefaultTrainRouteOrGroup = ({
  loading,
  trainRouteGroups,
  trainRoutes,
  trainRouteOrGroup,
  setTrainRouteOrGroup,
}: {
  loading: boolean;
  trainRouteGroups: Perhaps<TrainRouteGroup[]>;
  trainRoutes: Perhaps<TrainRoute[]>;
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup | TrainRouteOrGroupMinimized>;
  setTrainRouteOrGroup: StateSetter<
    Perhaps<TrainRouteOrGroup | TrainRouteOrGroupMinimized>
  >;
}) => {
  useNotLoadingEffect(
    loading,
    () => {
      if (
        !trainRouteOrGroup ||
        !includes(
          trainRouteOrGroup.id,
          map(prop('id'), [...trainRoutes, ...trainRouteGroups]),
        )
      ) {
        // TODO We currently require a TrainRouteGroup with DirectionType.all and no start or end station or serviceLine
        // In other words a TrainRouteGroup that represents all TrainRoutes of the operator
        const updatedTrainRouteOrGroup = findOrThrow(
          (trainRouteGroup: TrainRouteGroup) => {
            return allPass([
              propEq(DirectionType.all, 'directionType'),
              propEq(undefined, 'startScheduledStopPoint'),
              propEq(undefined, 'endScheduledStopPoint'),
              propEq(undefined, 'serviceLine'),
            ])(trainRouteGroup);
          },
          trainRouteGroups!,
        );
        setTrainRouteOrGroup(updatedTrainRouteOrGroup);
      }
    },
    [trainRouteGroups, trainRoutes, trainRouteOrGroup],
  );
};

/**
 * With useMemo gets the scheduledStopPoints of the TrainRoute and adds the routeDistance from the routePoint to each
 * @param trainRouteOrGroup The TrainRoute instance
 * @returns {[Object]} list ScheduledStopPoints
 */
export const useMemoScheduledStopPointsOfTrainRouteOrGroup = (
  trainRouteOrGroup: TrainRouteOrGroup,
) => {
  return useMemo(() => {
    return scheduledStopPointsOfTrainRouteOrGroup(trainRouteOrGroup);
  }, [trainRouteOrGroup]);
};

/***
 * Deserializes a TrainRoute or TrainRoute group with just an id to the matching instance
 * @param loading
 * @param trainRoute
 * @param trainRoutes
 * @param trainRouteGroups
 * @param setTrainRouteOrGroup
 */
export const useDeserializeTrainRouteInStorage = ({
  loading,
  trainRouteOrGroup,
  trainRoutes,
  trainRouteGroups,
  setTrainRouteOrGroup,
}: {
  loading: boolean;
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup | TrainRouteMinimized>;
  trainRoutes: Perhaps<TrainRoute[]>;
  trainRouteGroups: Perhaps<TrainRouteGroup[]>;
  setTrainRouteOrGroup: StateSetter<Perhaps<TrainRouteOrGroup>>;
}) => {
  // The TrainGroups of the current TrainRoute
  useNotLoadingEffect(
    loading || !trainRouteOrGroup || !trainRoutes || !trainRouteGroups,
    () => {
      // If the trainRoute changes, set the setTrainGroups to those in storage
      const matchingTrainRouteOrGroup = find(
        (aTrainRouteOrGroup: TrainRouteOrGroup) => {
          return idsEqual(trainRouteOrGroup, aTrainRouteOrGroup);
        },
        [...trainRoutes!, ...trainRouteGroups!],
      );
      if (!matchingTrainRouteOrGroup) {
        console.warn(
          `No matching TrainRoute or TrainRouteGroup for value id in cache ${trainRouteOrGroup.id}`,
        );
        return;
      }
      if (!idsEqual(trainRouteOrGroup, matchingTrainRouteOrGroup)) {
        setTrainRouteOrGroup(matchingTrainRouteOrGroup);
      }
    },
    [trainRoutes, trainRouteGroups, trainRouteOrGroup],
  );
};
