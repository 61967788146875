// Deserialize json with keys as a list of expected datetime props occuring at any level
import {includes} from 'ramda';
import {Perhaps} from "../../types/typeHelpers/perhaps";

export const parseDates = (keys: string[]) => {
  return (key: string, value: string) => {
    // Deserialize any dates
    return includes(key, keys) ? new Date(value) : value;
  };
};

//https://stackoverflow.com/questions/13861254/json-stringify-deep-objects/57193345#57193345
export const stringify = (
  val: any,
  depth: number,
  replacer: ((k: string, v: any) => any) | undefined,
  space: number,
  onGetObjID: ((v: any) => any) | undefined = undefined
): string => {
  depth = isNaN(+depth) ? 1 : depth;
  const recursMap = new WeakMap();

  // (JSON.stringify() has it's own rules, which we respect here by using it for property iteration)
  function _build(val: any, depth: number, o?: any, a?: any, r?: any) {
    return !val || typeof val != 'object' ? val :
      (r = recursMap.has(val), recursMap.set(val, true), a = Array.isArray(val),
        r ? (o = onGetObjID && onGetObjID(val) || undefined) : JSON.stringify(val, function(k, v) {
          if (a || depth > 0) {
            if (replacer) v = replacer(k, v);
            if (!k) return (a = Array.isArray(v), val = v);
            !o && (o = a ? [] : {});
            o[k] = _build(v, a ? depth : depth - 1);
          }
        }),
        o === void 0 ? (a ? [] : {}) : o);
  }

  return JSON.stringify(_build(val, depth), undefined, space);
};

// A wrapper for "JSON.parse()"" to support "undefined" value
export function maybeParseJSON<T>(value: Perhaps<string>): Perhaps<T> | never {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    if (typeof value === 'string') {
      console.warn('Found string, converting to JSON', {value})
      return value
    } else {
      throw new Error('parsing error on', {value})
    }
  }
}

/**
 * Parse the given value as JSON and store in localStorage at key
 * value can be undefined
 * @param value
 * @param key
 */
export function maybeParseJSONAndSetCookie<T>(value: Perhaps<string>, key: string): Perhaps<T> | never {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    if (typeof value === 'string') {
      console.warn('Found string in localStorage, converting to JSON', {value})
      window.localStorage.setItem(key, JSON.stringify(value))
      return value
    } else {
      throw new Error('parsing error on', {value})
    }
  }
}
