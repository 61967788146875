import {add, reduce} from 'ramda';
import {AlertCount} from 'types/alerts/alertMapData';

/**
 * Adds up the count attirbute value of each RideComfortAlertCount
 * @param rideComfortAlertCounts
 */
export const getTotalCount = (rideComfortAlertCounts: AlertCount[]): number => {
  return reduce(
    (accum, rideComfortAlertCount: AlertCount) => {
      return add(accum, rideComfortAlertCount.count);
    },
    0,
    rideComfortAlertCounts,
  );
};
