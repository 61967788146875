import React, {useContext, useEffect, useState} from 'react';
import {UserContext, UserContextType} from './UserContext.ts';
import {Button, Stack, SvgIcon, TextField, Typography} from '@mui/material';
import CemitLogo from '../../assets/icons/cemit_logo_responsive.svg';
import LoaderWithText from '../../components/loading/LoaderWithText.tsx';
import {useTranslation} from 'react-i18next';
import {AppSettings} from '../../config/appConfigs/appSettings.ts';
import {UserStateCombined, UserStateLoaded} from '../../types/userState/userState';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {AccessStatus} from '../../utils/userTypes.ts';
import {Access} from '../../types/authentication/access';

enum TwoFactorAPIResponseType {
  OTP_VERIFICATION_FAILED,
  OTP_VERIFICATION_SUCCESS,
  NEW_USER,
  ERROR,
}

interface TwoFactorAPIResponse {
  data: any;
  response_type: TwoFactorAPIResponseType;
}

interface TwoFactorAuthenticationAccessProps {
  token: string | undefined;
}

/**
 * Enforces two-factor authentication
 * @param token
 * @constructor
 */
export function TwoFactorAuthenticationAccess({
  token,
}: TwoFactorAuthenticationAccessProps) {
  const {t} = useTranslation();
  const {userState, setUserState} = useContext(UserContext) as UserContextType;
  const [otpValue, setOtpValue] = useState('');
  const [{qrCode, isLoading, state, postInit}, set2FAState] = useState({
    qrCode: null,
    isLoading: true,
    postInit: false,
    state: null,
  });

  const baseUrl = process.env.REACT_2FA_SERVER_URL;
  const check2FA = async (otp_data: string | null = null) => {
    const url = `${baseUrl}/2fa/check`;
    const otp_value = otp_data
      ? JSON.stringify({
          otp: otp_data,
        })
      : null;

    try {
      const response: Response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          JWT_TOKEN: token ?? '',
          'Access-Control-Allow-Origin': '*',
        },
        body: otp_value,
      });

      if (!response.ok) {
        if (response.status === 403) {
          const otp_fail: TwoFactorAPIResponse = {
            data: null,
            response_type: TwoFactorAPIResponseType.OTP_VERIFICATION_FAILED,
          };
          return otp_fail;
        }

        return {data: null, response_type: TwoFactorAPIResponseType.ERROR};
      }

      if (otp_data) {
        const {data} = await response.json();
        const {api_key} = data;
        const otp_succ: TwoFactorAPIResponse = {
          data: api_key,
          response_type: TwoFactorAPIResponseType.OTP_VERIFICATION_SUCCESS,
        };
        return otp_succ;
      }

      const {data} = await response.json();
      const {qr_code} = data;
      const otp_new_user: TwoFactorAPIResponse = {
        data: qr_code,
        response_type: TwoFactorAPIResponseType.NEW_USER,
      };
      return otp_new_user;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    check2FA().then((data) => {
      set2FAState({
        qrCode: data ? data.data : null,
        isLoading: false,
        state: data.response_type,
        postInit,
      });
    });
  }, []);

  const onSubmit = async () => {
    const res = await check2FA(otpValue);
    if (res.response_type === TwoFactorAPIResponseType.OTP_VERIFICATION_SUCCESS) {
      const access = userState.access;
      setUserState(
        clsOrType<UserStateLoaded>(CemitTypename.userStateLoaded, {
          ...userState,
          access: clsOrType<Access>(CemitTypename.access, {
            ...access,
            is2FAFulfilled: true,
          }),
        }),
      );
      return;
    }
    console.log(res.response_type);

    set2FAState({
      qrCode,
      isLoading,
      state: res.response_type,
      postInit: true,
    });
  };

  const appRelease = AppSettings.appRelease;
  const logoTitle = `${t('cemitVisualizer')} - Build: ${appRelease}`;
  return (
    <Stack
      {...{
        sx: {
          p: 2,
          height: '100vh',
          width: '100%',
          alignItems: 'top',
          justifyContent: 'center',
        },
      }}
    >
      <Stack {...{spacing: 2, sx: {color: 'secondary.main'}, alignItems: 'center'}}>
        {state === TwoFactorAPIResponseType.NEW_USER && <img src={qrCode} />}

        {!isLoading && (
          <>
            {state !== TwoFactorAPIResponseType.NEW_USER && (
              <SvgIcon
                {...{
                  component: CemitLogo,
                  viewBox: '0 0 33 33',
                  sx: {width: '200px', height: 'auto'},
                  title: logoTitle,
                }}
              />
            )}
            <Typography>Enter OTP from your Authenticator app</Typography>
            <TextField
              value={otpValue}
              onChange={(e) => {
                set2FAState({
                  qrCode,
                  isLoading,
                  state,
                  postInit: false,
                });
                setOtpValue(e.target.value);
              }}
            />
            <Button variant="contained" onClick={async () => await onSubmit()}>
              <Typography>Verify OTP</Typography>
            </Button>
            {postInit && state === TwoFactorAPIResponseType.OTP_VERIFICATION_FAILED && (
              <Typography>
                Sorry {otpValue} did not work. Please try another OTP
              </Typography>
            )}
            {postInit && state === TwoFactorAPIResponseType.ERROR && (
              <Typography>Sorry there was an error. Please try again</Typography>
            )}
          </>
        )}

        {isLoading && (
          <>
            <SvgIcon
              {...{
                component: CemitLogo,
                viewBox: '0 0 33 33',
                sx: {width: '200px', height: 'auto'},
                title: logoTitle,
              }}
            />
            <Typography key="title" variant="h3" color="inherit" textAlign={'center'}>
              {/*{t('visualizer')}*/}
              Please Wait
            </Typography>
            <LoaderWithText text={t('loading')} spinner={true} />
          </>
        )}
      </Stack>
      ;
    </Stack>
  );
}
