import {MapSourceVisualForTrainGroup} from '../../../../types/mapbox/mapSourceVisual';
import {MapLayerMouseEvent, Popup} from 'mapbox-gl';
import {ChartPayloadItemMinimized} from '../../../../types/dataVisualizations/chartPayloadItem';
import {TrainRunSensorDataPointFeaturePropertiesDerived} from '../../../../types/featureProperties/trainRunFeatureProperties';
import {railwayLineMapboxSourcesAndLayersSets} from '../../../../appUtils/cemitAppUtils/cemitSourceLayerUtils/railwayLineSourceLayerUtils.ts';
import {useNotLoadingSetterEffect} from '../../../../utils/hooks/useMemoHooks.ts';
import {setMapboxSourceAndLayersSets} from '../../../../utils/map/mapboxSourceUtils.ts';

import {singlePointFeatureFromPayload} from '../../../../utils/dataFeatures/dataFeaturePayloadUtils.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {RailwayLine} from '../../../../types/railways/railwayLine';
import {scheduledStopPointMapboxSourcesAndLayersSets} from '../../../../appUtils/cemitAppUtils/cemitSourceLayerUtils/scheduledStopPointSourceLayerUtils.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * Call railwayLineMapboxSourcesAndLayersSets and update/creates the resulting sources on the trainMap
 * @param loading Do nothing if true
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines The railwayLines whose tracks we create sources for
 * @param mapProps
 * @param mapProps.trainMap The Mapbox map
 * @param t The translation function
 */
export const useNotLoadingEffectScheduledStopPointMapboxSourcesAndLayers = (
  loading: boolean,
  {organizationProps, trainProps, mapProps}: TrainAppMapDependencyProps,
) => {
  // TODO we used to used ordered tracks, now we need the railwayLines of the TrainRouteOrGroup,
  // This is passing all railways
  const railwayLines: Perhaps<RailwayLine[]> = trainProps.railwayLineProps?.railwayLines;
  const dependencies = [railwayLines] as const;
  useNotLoadingSetterEffect(
    {loading: loading!},
    scheduledStopPointMapboxSourcesAndLayersSets,
    {organizationProps, trainProps},
    (mapSourceVisuals: MapSourceVisualForTrainGroup[]) => {
      // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
      // for each RailwayLine Track. Set Mapbox to them here
      setMapboxSourceAndLayersSets(
        mapProps.trainMap,
        mapProps.setChangeStatuses,
        mapSourceVisuals,
        true,
      );
    },
    dependencies,
  );
};

/**
 * Call railwayLineMapboxSourcesAndLayersSets and update/creates the resulting sources on the trainMap
 * @param loading Do nothing if true
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines The railwayLines whose tracks we create sources for
 * @param mapProps
 * @param mapProps.trainMap The Mapbox map
 * @param t The translation function
 */
export const useNotLoadingEffectRailwayLineMapboxSourcesAndLayers = (
  loading: boolean,
  {trainProps, mapProps}: TrainAppMapDependencyProps,
) => {
  // TODO we used to used ordered tracks, now we need the railwayLines of the TrainRouteOrGroup,
  // This is passing all railways
  const railwayLines: Perhaps<RailwayLine[]> = trainProps.railwayLineProps?.railwayLines;
  // trainProps.trainRouteGroupProps.trainRouteOrGroup.orderedTracks
  const dependencies = [railwayLines] as const;
  useNotLoadingSetterEffect(
    {loading: loading!},
    railwayLineMapboxSourcesAndLayersSets,
    {railwayLines},
    (mapSourceVisuals: MapSourceVisualForTrainGroup[]) => {
      // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
      // for each RailwayLine Track. Set Mapbox to them here
      setMapboxSourceAndLayersSets(
        mapProps.trainMap,
        mapProps.setChangeStatuses,
        mapSourceVisuals,
        true,
      );
    },
    dependencies,
  );

  /*
  TODO this icon doesn't work. Copy the code of trainMap.addImage('trains-highlighted', imgHighlighted) instead,
  which did work at some point
  useNotLoadingEffect(loading, () => {
    const img = new Image(20, 20);
    img.onload = () => {
      mapProps.trainMap.addImage('track-switch', img);
      setTrainSwitchLoaded(true);
    };
    img.translation = TrackSwitchIconSvg;
  });
  */
  //const trainSwitchLoaded = true;

  // TrainPage switches layer
  // TODO fix later
  // useNotLoadingSetterEffect(
  //   { loading: loading || !trainSwitchLoaded },
  //   trackSwitchesMapboxSourcesAndLayersSets,
  //   { trainProps },
  //   (mapSourceVisuals: MapSourceVisual[]) => {
  //     // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
  //     // for each RailwayLine Track. Set Mapbox to them here
  //     setMapboxSourceAndLayersSets({ mapboxMap: mapProps.trainMap, mapSourceVisuals, zoomToSources: false });
  //     setMapboxOnHover({
  //       appProps,
  //       organizationProps,
  //       trainProps,
  //       mapProps,
  //       dataProps: {
  //         mapSourceVisuals,
  //         createOnHover: trackSwitchesMapboxSourcesAndLayersSetsOnClick,
  //         // Only disable the switch popup on click
  //         onClickOnly: true
  //       }
  //     });
  //   },
  //   [trainProps.railwayLineProps.railwayLines]
  // );
};

/**
 * Popup to describe a railway switch feature point
 * @param mapProps
 * @param eventProps
 * @param payload
 */
const trackSwitchesMapboxSourcesAndLayersSetsOnClick = (
  {
    mapProps,
    eventProps,
  }: TrainAppMapDependencyProps & {
    eventProps: {
      trainGroup: TrainGroup;
      hoveredStateId: string;
      e: MapLayerMouseEvent;
      popup: Popup;
    };
  },
  payload: ChartPayloadItemMinimized[],
): void => {
  eventProps.popup.remove();

  const feature = singlePointFeatureFromPayload(payload);
  const properties: TrainRunSensorDataPointFeaturePropertiesDerived =
    feature?.properties as TrainRunSensorDataPointFeaturePropertiesDerived;
  // TODO Convert to React component
  const description = `Direction: ${properties.direction}<br/>Type: ${properties.attribute}<br/>Km: ${properties.km}`;
  // Populate the popup and set its coordinates
  // based on the feature found.
  eventProps.popup
    .setLngLat(eventProps.e.lngLat)
    .setHTML(description)
    .addTo(mapProps.trainMap);
};
