import {
  FrontendViewGroupSelectionDisplay,
  FrontendViewGroupSelectionDisplayConfig,
} from '../../../../../types/frontendViews/frontendViewGroupSelectionDisplay';
import {CemitTypename} from '../../../../../types/cemitTypename.ts';
import {
  trainFormationsGrouping,
  trainGroupsGrouping,
} from '../../../../../config/appConfigs/cemitAppConfigs/frontendConfig/frontendViewGroups.ts';
import {FrontendViewGroupSelection} from '../../../../../types/frontendViews/frontendViewGroupSelection';
import {FrontendView} from '../../../../../config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {clsOrType} from '../../../../../appUtils/typeUtils/clsOrType.ts';
import {clsOrTypes} from '../../../../../appUtils/typeUtils/clsOrTypes.ts';

export const trainFormationGroupSelectionPrimaryDisplay =
  clsOrType<FrontendViewGroupSelectionDisplay>(
    CemitTypename.frontendViewGroupSelectionDisplay,
    {
      frontendViewGroupSelection: clsOrType<FrontendViewGroupSelection>(
        CemitTypename.frontendViewGroupSelection,
        {
          frontendViewGroup: trainFormationsGrouping,
          frontendViewOrViews: FrontendView.trainFormationList,
        },
      ),
      style: {color: 'primary'},
    },
  );
export const trainFormationGroupSelectionSecondaryDisplay =
  clsOrType<FrontendViewGroupSelectionDisplay>(
    CemitTypename.frontendViewGroupSelectionDisplay,
    {
      frontendViewGroupSelection: clsOrType<FrontendViewGroupSelection>(
        CemitTypename.frontendViewGroupSelection,
        {
          frontendViewGroup: trainFormationsGrouping,
          frontendViewOrViews: FrontendView.trainFormationMenu,
        },
      ),
      style: {color: 'secondary'},
    },
  );

export const trainGroupSelectionPrimaryDisplay =
  clsOrType<FrontendViewGroupSelectionDisplay>(
    CemitTypename.frontendViewGroupSelectionDisplay,
    {
      frontendViewGroupSelection: clsOrType<FrontendViewGroupSelection>(
        CemitTypename.frontendViewGroupSelection,
        {
          frontendViewGroup: trainGroupsGrouping,
          frontendViewOrViews: FrontendView.trainGroupList,
        },
      ),
      style: {color: 'primary'},
    },
  );
export const trainGroupSelectionSecondaryDisplay =
  clsOrType<FrontendViewGroupSelectionDisplay>(
    CemitTypename.frontendViewGroupSelectionDisplay,
    {
      frontendViewGroupSelection: clsOrType<FrontendViewGroupSelection>(
        CemitTypename.frontendViewGroupSelection,
        {
          frontendViewGroup: trainGroupsGrouping,
          frontendViewOrViews: FrontendView.trainGroupMenu,
        },
      ),
      style: {color: 'secondary'},
    },
  );

export const frontendViewGroupSelectionDisplayConfigs: FrontendViewGroupSelectionDisplayConfig[] =
  clsOrTypes<FrontendViewGroupSelectionDisplayConfig>(
    CemitTypename.frontendViewGroupSelectionDisplayConfig,
    [
      {
        frontendViewGroup: trainFormationsGrouping,
        primary: trainFormationGroupSelectionPrimaryDisplay,
        secondary: trainFormationGroupSelectionSecondaryDisplay,
      },
      {
        frontendViewGroup: trainGroupsGrouping,
        primary: trainGroupSelectionPrimaryDisplay,
        secondary: trainGroupSelectionSecondaryDisplay,
      },
    ],
  );
