import {CemitTypename} from 'types/cemitTypename.ts';
import {Jurisdiction} from 'types/jurisdiction/jurisdition';
import {Address} from 'types/jurisdiction/address';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrafficSimConfig, TrafficSimPageConfig} from 'types/trafficsim/trafficSimConfig';
import {TRAFFIC_SIM_PAGE_NAME} from "../utils/page_names.ts";

/**
 * The Configurations for organizations supporting trafficsim
 * TOOD move to a backend
 * @param t
 */
export const trafficSimOrganizationConfigs: TrafficSimConfig[] = [
  clsOrType<TrafficSimConfig>(CemitTypename.trafficSimConfig, {
    jurisdiction: clsOrType<Jurisdiction>(CemitTypename.jurisdiction, {
      address: clsOrType<Address>(CemitTypename.address, {
        country: 'Sweden',
        city: 'Gothenburg',
      }),
    }),
    organizationKey: 'gothenburg',
    defaultCenter: [11.9746, 57.7089],
    dataUrl: '/gothenburgData.geojson',
    dataJson: '/dataGothenburgJson.json',
    pages: [
      clsOrType<TrafficSimPageConfig>(CemitTypename.trafficSimPageConfig, {
        name: TRAFFIC_SIM_PAGE_NAME.FEEDPOINTS,
        subpages: [TRAFFIC_SIM_PAGE_NAME.FEEDPOINTS, TRAFFIC_SIM_PAGE_NAME.RESERVE_FEEDPOINTS],
      }),
      {
        name: TRAFFIC_SIM_PAGE_NAME.LIMITATIONS,
        subpages: [TRAFFIC_SIM_PAGE_NAME.ADD_LIMITATION, TRAFFIC_SIM_PAGE_NAME.LIMITATIONS, TRAFFIC_SIM_PAGE_NAME.UPCOMING_LIMITATION, TRAFFIC_SIM_PAGE_NAME.HISTORY],
      },
    ],
  }),
];

export const trafficSimCommonConfig = {
  mapStyles: {
    light: process.env.REACT_MAPBOX_STYLE_LIGHT,
    dark: process.env.REACT_MAPBOX_STYLE_DARK,
  },
  defaultZoom: 12,
};
