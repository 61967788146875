import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  TrainGroupsTitleContainer,
  TrainGroupsTitleContainerProps,
} from 'components/charts/stackedChart/TrainGroupsTitleContainer.tsx';
import React, {SyntheticEvent, useState} from 'react';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {Stack} from '@mui/material';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {includes, is, prepend, without} from 'ramda';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {SxProps} from '@mui/system';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';

export interface TrainGroupOptionsMenuProps extends CemitComponentProps {
  iconSx: SxProps;
}

/**
 * Presents an add button for adding TrainGroups and a menu for choosing options, such as hiding minor alerts
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param addTrainGroupDisabled
 * @constructor
 */
const TrainGroupAddMenu = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<TrainGroupOptionsMenuProps>) => {
  const activeTrainGroups: Perhaps<TrainGroup[]> =
    trainProps.trainGroupActivityProps.activeTrainGroups;
  const addTrainGroupContainerChildProps = ts<TrainGroupsTitleContainerProps>({
    activeTrainGroups,
    width: '100%',
    addTrainGroupMode: true,
  });
  return (
    <TrainGroupsTitleContainer
      {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: addTrainGroupContainerChildProps,
      }}
    />
  );
};
export default TrainGroupAddMenu;
