import {memoizedWith} from "@rescapes/ramda";
import {CemitTypename} from "../../types/cemitTypename.ts";
import {Cemited} from "../../types/cemited";
import {Perhaps} from "../../types/typeHelpers/perhaps";
import {clsOrType} from "./clsOrType.ts";

/**
 * Version of useMemoClassifyOrTypeObject to use outside of hooks
 * Be very careful with t and overrideDependencies, as these are flattened in order to
 * use a NamedTupleMap cache that can only compare objects at one level. Thus {a: {b: {c: true}}} is
 * flattened to {a.b.c: true} for the NamedTupleMap. This is very expensive, so it's best to
 * pass in the minimum values into overrideDependencies that are needed to establish a correct cache hit/miss
 */
export const memoizedWithClassifyOrTypeObject = memoizedWith(
    (typename: CemitTypename,
     t: Cemited | Omit<WritablePart<Cemited>, '__typename'>,
     overrideDependencies: Perhaps<any[]>
    ) => {
        return [typename, ...(overrideDependencies || [t])];
    },
    (
        typename: CemitTypename,
        t: Cemited | Omit<WritablePart<Cemited>, '__typename'>,
        _overrideDependencies: Perhaps<any[]> = undefined
    ): Cemited => {
        return clsOrType<Cemited>(typename, t);
    }
) as <T extends Cemited>(
    typename: CemitTypename,
    t: T | Omit<WritablePart<T>, '__typename'>,
    overrideDependencies: Perhaps<any[]>
) => T;
