import {TrainFormation, TrainFormationMinimized} from 'types/trains/trainFormation';
import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {OrderedVehicle} from 'types/trains/orderedVehicle';
import {Operator} from 'types/operators/operator';
import {
  TrainFormationDetail,
  TrainReportAlertStatus,
} from 'types/trainFormations/trainFormationDetail.ts';

import {IdentifiedClass} from '../cemitAppCemitedClasses/identifiedClass.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

export class TrainFormationMinimizedClass
  extends IdentifiedClass
  implements TrainFormationMinimized
{
  constructor(obj: TrainFormationMinimized) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}

export class TrainFormationClass
  extends TrainFormationMinimizedClass
  implements TrainFormation
{
  constructor(obj: TrainFormation) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  orderedVehicles?: OrderedVehicle[];
  // formationCount?: number;
  // length?: number;
  // speed?: number;
  // bruttoWeight?: number;
  // nettoWeight?: number;
  // tareWeight?: number;
  // load?: number;
  // etcsTrainCategory?: int;
  operator?: Operator;
  alertStatus?: TrainReportAlertStatus;
  formationDetail?: TrainFormationDetail;
  trainGroup?: TrainGroup;
  lastScanTime?: Date;
  disableSensorData?: boolean;
}
