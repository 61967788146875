import {PartialCemited, PerhapsCemited} from '../../../../types/cemited';
import {TrainProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {useNotLoadingMemo} from '../../../../utils/hooks/useMemoHooks.ts';
import {strPathListsEqual} from '../../../../utils/functional/functionalUtils.ts';
import {CrudList} from '../../../../types/crud/crudList';
import {TrainRoute} from '../../../../types/trainRouteGroups/trainRoute';
import {all, any, indexBy, prop, propOr} from 'ramda';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * Compares the TrainRouteOrGroup to that of the TrainGroups. Any mismatch means we are in a loading state
 * @returns {*}
 */
export const useNotLoadingMemoTrainGroupsMatchTrainRouteOrGroup = (
  loading: boolean,
  trainProps: PartialCemited<TrainProps>,
  trainGroups: TrainGroup[],
): boolean => {
  const dependencies = [
    trainProps?.trainRouteGroupProps?.trainRouteOrGroup?.trainRoutes,
    trainGroups,
  ] as const;
  return useNotLoadingMemo<boolean, typeof dependencies>(
    loading,
    (trainRoutes, trainGroups) => {
      const trainRouteIdLookup: {[p: string]: TrainRoute} = indexBy(
        prop('id'),
        trainRoutes,
      );
      return all((trainGroup) => {
        return !propOr(false, trainGroup.trainRouteOrGroup!.id, trainRouteIdLookup);
      }, trainGroups);
    },
    dependencies,
  );
};

/**
 * Compares the TrainRuns loaded from cache and preconfigured to those that are in trainGroups to
 * determine if trainGroups is up-to-datetime
 * @returns {*}
 */
export const useNotLoadingMemoTrainGroupsMatchCache = (
  loading: boolean,
  trainProps: PartialCemited<TrainProps>,
  trainGroups: TrainGroup[],
): boolean => {
  const dependencies = [
    trainProps.filteredTrainGroupProps?.trainGroupsForTrainRoute,
    trainProps.trainRouteGroupProps?.trainRouteOrGroup,
    trainGroups,
  ] as const;
  return useNotLoadingMemo<boolean, typeof dependencies>(
    loading,
    (trainGroupsForTrainRoute, trainRouteOrGroup, trainGroups) => {
      return (
        trainRouteOrGroup &&
        strPathListsEqual('singleTrainRun.id', trainGroupsForTrainRoute, trainGroups)
      );
    },
    dependencies,
  );
};

/**
 * Checks that trainGroups matches crudTrainGroups.list by id
 * @param loading
 * @param trainGroups
 * @param crudTrainGroups
 */
export const useNotLoadingMemoTrainGroupsMatchCrud = (
  loading: boolean,
  trainGroups: PerhapsCemited<TrainGroup[]>,
  crudTrainGroups: PerhapsCemited<CrudList<TrainGroup>>,
): boolean => {
  const dependencies = [trainGroups, crudTrainGroups] as const;
  return useNotLoadingMemo<boolean, typeof dependencies>(
    loading,
    (trainGroups, crudTrainGroups) => {
      return strPathListsEqual<TrainGroup>(
        'singleTrainRun.id',
        trainGroups,
        crudTrainGroups.list || [],
      );
    },
    dependencies,
  );
};
