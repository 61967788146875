import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {Stack} from '@mui/material';
import VisionSideBar from 'pages/vision/visionComponents/VisionSideBar.tsx';
import VisionImagePane from 'pages/vision/visionComponents/VisionImagePane.tsx';
import React, {useMemo} from 'react';
import {VisionAppMapDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppMapDependencyProps.ts';
import {undefinedToTrue} from 'utils/functional/functionalUtils.ts';
import {
  omitClassOrType,
  pickClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * The entry point the VisionApp
 * @param appProps
 * @param organizationProps
 * @param visionProps
 * @constructor
 */
export const VisionAppContainer = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
}: VisionAppMapDependencyProps) => {
  const {whatIsLoading, visionMetasError} = trainProps.visionProps;
  // Vision components often only need trainProps.visionProps
  const trainPropsOnlyVisionProps = useMemo(() => {
    return pickClassOrType(['visionProps'], trainProps);
  }, [trainProps.visionProps]);

  return whatIsLoading.loading ? (
    <LoaderWithText
      {...{
        text: visionMetasError ? 'Image service is currently unavailable' : 'loading',
        loadingExplanation: visionMetasError
          ? undefined
          : whatIsLoading.loadingExplanation,
      }}
    />
  ) : (
    <Stack
      key="vision"
      {...{
        direction: 'row',
        sx: {
          minWidth: '100%',
          minHeight: '100%',
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
        },
      }}
    >
      <VisionSideBar
        key="visionSideBar"
        {...{
          appProps,
          organizationProps,
          trainProps,
          mapProps,
        }}
      />
      <VisionImagePane
        key="visionImagePane"
        {...{appProps, organizationProps, trainProps: trainPropsOnlyVisionProps}}
      />
    </Stack>
  );
};
