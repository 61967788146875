import {Minimized} from '../../types/cemitTypes/minimized';
import {cemitedConstructor} from './cemitClassConstructor.ts';
import {IdentifiedClass} from "./identifiedClass.ts";

export class MinimizedClass extends IdentifiedClass implements Minimized {
  constructor(obj: Minimized) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}

