import {colorHue} from 'theme/colors/colorHue/colorHue.ts';
import {alpha} from '@mui/material';

export const BackgroundColors = {
  // Light Mode
  light: {
    common: {
      dark: colorHue.grey[500],
      light: colorHue.grey[50],
    },

    background: {
      default: colorHue.grey[200],
      paper: colorHue.grey[50],
      surfacePrimary: colorHue.grey[50],
      surfaceSecondary: colorHue.grey[50],
      surfaceTertiary: colorHue.grey[100],
      surfaceModerate: alpha(colorHue.grey[400], 0.7),
      surfaceContrast: colorHue.grey[400],
      surfaceReverse: colorHue.grey[50],
      surfaceOverlay: alpha(colorHue.grey[200], 0.8),
      header: colorHue.grey[500],
      navigation: colorHue.grey[50],
    },
    switch: {
      boldSelected: colorHue.gold[500],
      subtleSelected: colorHue.gold[300],
      selectedDisabled: colorHue.gold[100],
    },
    action: {
      active: colorHue.gold[600],
      focus: colorHue.gold[600],
      hover: alpha(colorHue.gold[700], 0.7),
      selected: alpha(colorHue.grey[300], 0.5),
      disabled: colorHue.grey[100],
      disabledBackground: colorHue.grey[100],
    },
  },
  // Dark Mode
  dark: {
    common: {
      dark: colorHue.grey[600],
      light: colorHue.grey[50],
    },
    background: {
      default: colorHue.grey[700],
      paper: colorHue.grey[600],
      surfacePrimary: colorHue.grey[600],
      surfaceSecondary: colorHue.grey[700],
      surfaceTertiary: colorHue.grey[600],
      surfaceModerate: alpha(colorHue.grey[400], 0.5),
      surfaceContrast: colorHue.grey[400],
      surfaceReverse: colorHue.grey[600],
      surfaceOverlay: alpha(colorHue.grey[700], 0.8),
      header: colorHue.grey[700],
      navigation: colorHue.grey[500],
    },
    switch: {
      boldSelected: colorHue.gold[700],
      subtleSelected: colorHue.gold[500],
      selectedDisabled: colorHue.gold[100],
    },
    action: {
      active: colorHue.gold[400],
      focus: colorHue.gold[600],
      hover: alpha(colorHue.gold[500], 0.5),
      selected: colorHue.grey[500],
      disabled: colorHue.gold[100],
      disabledBackground: colorHue.gold[100],
    },
  },
};
