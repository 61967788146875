import {ChartDataCategoryConfig} from '../../../types/dataVisualizations/chartDataCategoryConfig';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {ChartDataConfig} from '../../../types/dataVisualizations/chartDataConfig.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {clsOrType} from '../../../appUtils/typeUtils/clsOrType.ts';
import {Area, Line} from 'recharts';
import {distanceOrTimeTickFormatter} from '../../../utils/chart/distanceOrTimeTickFormatter.ts';

/**
 * Velocity Data Config. TODO remove commonChartDataCategoryConfig
 * and instead configure as in commonConfigs
 * @param appProps
 * @param commonChartDataCategoryConfig
 */
export const velocityDataConfig = ({
  appProps,
  commonChartDataCategoryConfig = {},
}: {
  appProps: TrainAppProps;
  commonChartDataCategoryConfig: Partial<ChartDataCategoryConfig>;
}): ChartDataCategoryConfig => {
  return clsOrType<ChartDataCategoryConfig>(CemitTypename.chartCategoryDataConfig, {
    ...commonChartDataCategoryConfig,
    category: 'speed',
    showGradients: true,

    // Mean velocity
    configs: [
      clsOrType<ChartDataConfig>(CemitTypename.chartDataConfig, {
        // TODO placeholder
        dataFeatureCollectionSourceTypename: CemitTypename.trainGroup,
        chartType: Area,
        // TODO All Belongs in the CommonConfig
        xAxisDataPath: appProps.isTimeSelected ? 'properties.time' : 'properties.meters',
        // For the X axis Time vs Distance case
        xAxisTickFormatter: distanceOrTimeTickFormatter(appProps.isTimeSelected, true),
        labelXAxis: appProps.isTimeSelected
          ? ''
          : `${appProps.t('totalDistance')} (${appProps.t('km')})`,
        yAxisDataPath: 'properties.kmh',
        // End TODO Belongs in the CommonConfig
        label: appProps.t('velocity'),
        labelYAxis: 'kmh',
        alwaysShow: true,
      }),
    ],
    showGradients: true,
  });
};
