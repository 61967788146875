import {
  useConfiguredApiForAdminOnlyOrganizations,
  useConfiguredApiForOrganization,
} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/trainApiOrganizationHooks.ts';
import {ComponentType, ReactNode, useContext, useMemo, useState} from 'react';
import {createChildElementsWithKey} from 'utils/componentLogic/createComponentUtils.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {
  OrganizationLoaded,
  OrganizationMinimized,
} from 'types/organizations/organization.ts';
import {UserStateLoaded, UserStateMinimized} from 'types/userState/userState';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useSetDefaultActiveView} from 'async/trainAppAsync/trainAppHooks/trainApiHooks/activeViewHooks.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from 'async/trainAppAsync/trainAppHooks/loadingExplanationHooks.ts';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {WhatIsLoading} from 'types/dependencies/whatIsLoading';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {MinimizedOrLoaded} from 'types/cemitTypes/minimizedOrLoaded';
import {concat, equals, identity, includes, isNil, lensPath, sortBy, uniq} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {useCustomLocalStorageDefaulted} from 'utils/hooks/useCustomLocalStorage.ts';
import {logout} from 'pages/protectedRoute/userUtils.ts';
import {useNavigate} from 'react-router-dom';
import {useToken} from 'utils/hooks/useTokenHook.ts';
import {resolveOrganizationManifestFromSourceKey} from 'utils/organization/organizationUtils.ts';
import {
  overClassOrType,
  setClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {CemitAppProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppProps';
import {useNotLoadingDoesLocalTimezoneMatchOrganization} from 'utils/hooks/timeHooks.ts';

interface OrganizationDependencyProps {
  appProps: CemitAppProps | TrainAppProps;
  renderChildren: ComponentType | ComponentType[];
}

/**
 * Loads the organization if needed
 * This currently comes from a hard coded appProps.organizationConfig.sourceKey, but the organization should of
 * course be identified by the logged-in user
 * @param appProps
 * @param appProps.organizationConfig
 * @param appProps.organizationConfig.sourceKey
 * @param children child trafficSimComponents to create, passing the existing props and loaded data
 * @return {*}
 * @constructor
 */
const OrganizationDependency = ({
  appProps,
  renderChildren,
}: OrganizationDependencyProps): ReactNode => {
  const navigate = useNavigate();
  const [, setToken] = useToken();
  const loading = appProps.loading;
  const [organization, setOrganization] =
    useState<Perhaps<MinimizedOrLoaded<OrganizationMinimized, OrganizationLoaded>>>(
      undefined,
    );
  const [adminOnlyAllOrganizations, setAdminOnlyAllOrganizations] =
    useState<Perhaps<OrganizationMinimized[]>>(undefined);

  const {userState: userStateLoaded, setUserState: setUserStateLoaded} =
    useContext<UserContextType>(UserContext) as UserContextType;

  // The initialSourceKey of the user is from userStateLoaded.sourceKey. Failing that,
  // for admins, it is AppSettings.clientSourceKey
  //  AppSettings.clientSourceKey is used as the initial organization for admins. They can
  // change to other users with setUserState and it will store in a cookie below
  const initialSourceKey = useMemo((): string | never => {
    const organizationSourceKey =
      userStateLoaded.status === AccessStatus.Authenticated
        ? userStateLoaded.sourceKey
        : undefined;

    const organizationSourceKeyForAdmin =
      userStateLoaded?.access?.group == 'sysadmin'
        ? AppSettings.clientSourceKey
        : undefined;

    // Temp hack for Gothenburg
    const extraSourceKey = includes(
      'tracksim',
      userStateLoaded?.access?.applicationAccess || [],
    )
      ? 'gothenburg'
      : undefined;

    // In the event of an unidentifiable user, log the user out.
    // This was a problem when tokens lacked customerId
    if (!organizationSourceKey && !organizationSourceKeyForAdmin && !extraSourceKey) {
      console.error('Problem with organization identification');
      logout(setToken, navigate);
    }

    return organizationSourceKey || organizationSourceKeyForAdmin || extraSourceKey;
  }, [userStateLoaded.sourceKey, AppSettings.clientSourceKey]);

  const [userState, setUserState] = useCustomLocalStorageDefaulted<
    MinimizedOrLoaded<UserStateMinimized, UserStateLoaded>
  >(
    {},
    AppSettings.localStorage.userState,
    clsOrType<UserStateMinimized>(CemitTypename.userStateMinimized, {
      // Use  userAuth.sourceKey as the user or the hardcoded AppSettings.clientSourceKey
      sourceKey: initialSourceKey,
    }),
  );
  // If the admin updates the user, we might need to add to the userStateLoaded based on the organizationManifest
  // since we don't know what applications the new user has, since we are asking firebase
  useNotLoadingEffect(loading, () => {
    if (userState) {
      const sourceKey = userState.sourceKey;
      const organizationManifest = resolveOrganizationManifestFromSourceKey(sourceKey);
      // Augment the userStateLoaded?.access?.applicationAccess to include
      // organizationManifest.applicationAccess items if userState.access.group == 'sysadmin'
      // We do this because as admin we can't get the specially configured applications from firebase for that user
      const modifiedUserStateLoaded =
        userStateLoaded?.access?.group == 'sysadmin'
          ? overClassOrType(
              lensPath(['access', 'applicationAccess']),
              (hasApplicationAccess) => {
                return sortBy(
                  identity,
                  uniq(
                    concat(
                      hasApplicationAccess,
                      organizationManifest?.applicationAccess || [],
                    ),
                  ),
                );
              },
              userStateLoaded,
            )
          : userStateLoaded;
      if (
        !equals(
          modifiedUserStateLoaded?.access?.applicationAccess,
          userStateLoaded?.access?.applicationAccess,
        )
      ) {
        setUserStateLoaded(modifiedUserStateLoaded);
      }
    }
  }, [userState, userStateLoaded]);

  const localTimezoneMatchesOrganization =
    useNotLoadingDoesLocalTimezoneMatchOrganization(loading, organization);

  // We query for the organization based on the userState.sourceKey. Memoize to limit runs of useConfiguredApiForOrganization
  const organizationMinimized = useMemoClsOrType<OrganizationMinimized>(
    CemitTypename.organizationMinimized,
    {sourceKey: userState.sourceKey},
    // Cache hit/miss determined by this
    [userState.sourceKey],
  );
  // Loads the Organization instance TODO get the sourceKey from the authenticate user
  useConfiguredApiForOrganization(
    !userState.sourceKey,
    userState,
    organizationMinimized,
    organization,
    setOrganization,
  );

  // Check if the UserState is an admin whenever it changes
  const isAdmin = useMemo(() => {
    return Boolean(userStateLoaded && equals('sysadmin', userStateLoaded.access.group));
  }, [userStateLoaded]);

  useConfiguredApiForAdminOnlyOrganizations(
    !isAdmin,
    userState,
    adminOnlyAllOrganizations,
    setAdminOnlyAllOrganizations,
  );

  // Sets the application activeViews to the minimum views configured for the organization if activeViews is not set
  // Setting appProps.setActiveViews to undefined will also cause this to reset the activeViews to the mimimums
  useSetDefaultActiveView(organization, appProps.activeViews, appProps.setActiveViews);

  const whatIsLoading: WhatIsLoading = useWhatIsLoading(
    loading,
    'organizationProps.loading',
    OrganizationDependency.name,
    {
      'organization.id': organization?.id,
      '!isNil(localTimezoneMatchesOrganization)': !isNil(
        localTimezoneMatchesOrganization,
      ),
    },
    [organization?.id, localTimezoneMatchesOrganization],
    appProps.setWhatDependenciesAreLoading,
  );

  const organizationProps = clsOrType<OrganizationProps>(
    CemitTypename.organizationProps,
    {
      loading: whatIsLoading.loading,
      isAdmin,
      whatIsLoading,
      organization,
      setOrganization,
      adminOnlyAllOrganizations,
      setAdminOnlyAllOrganizations,
      userState,
      setUserState,
      localTimezoneMatchesOrganization,
    },
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    organizationProps,
    OrganizationDependency.name,
  );
  return createChildElementsWithKey(
    // This key makes all children unmount and remount when the user changes
    userState.sourceKey,
    {
      appProps,
      organizationProps,
    },
    ...toArrayIfNot(renderChildren),
  );
};
OrganizationDependency.displayName = 'OrganizationDependency';
export default OrganizationDependency;
