import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import {always, ifElse} from 'ramda';
import {ExpandMore} from '@mui/icons-material';
import TrainRunLineContainer from '../../trainLineComponents/TrainRunLineContainer.tsx';
import {DEFAULT_SERVICE_LINE_COLOR} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {TrainDescription} from './TrainDescription.tsx';
import {TrainRunEquipment} from './TrainRunEquipment.tsx';
import {TrainRouteOrGroupServiceDescription} from './TrainRouteOrGroupServiceDescription.tsx';
import React, {ReactElement} from 'react';
import {Size} from 'types/layout/size';
import {Property} from 'csstype';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {format} from 'date-fns';
import {equalsCemitType} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {trainRouteOrGroupsOfTrainGroup} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {serviceLinesOfTrainRouteOrGroup} from 'appUtils/trainAppUtils/serviceLineUtils/serviceLineUtils.ts';
import {ServiceLine} from 'types/trainRouteGroups/serviceLine';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';

// Icons are 24px plus 4 for margins
const iconWidth = 28;
/**
 * Description of the TrainRun along with optional buttons and icons: add, remove, and loading icon
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainRunDescription = <T extends TrainGroup>({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {
    trainGroup,
    isTrainGroupDetailLine,
    isBaseline,
    buttons,
    dateAndOrTimeFormatString,
    trainRouteLabel,
    trainDetailSize,
    rmseIcons,
  },
}: TrainAppTrainDependencyProps & {
  componentProps: {
    trainGroup: T;
    isTrainGroupDetailLine: boolean;
    isBaseline: boolean;
    buttons: React.ReactElement[];
    departureDatetime: Perhaps<Date>;
    dateAndOrTimeFormatString: string;
    trainRouteLabel: string;
    trainDetailSize: Size;
  };
}) => {
  const sxBox: {bgcolor: Property.Color} = {bgcolor: DEFAULT_SERVICE_LINE_COLOR};

  // We only have a departureDatetime for interface TrainGroupSingleTrainRun
  // TODO we could show a range of dates for TrainGroup
  const perhapsDateAndOrTime: Perhaps<ReactElement> = ifElse(
    (trainGroup: T) => {
      return equalsCemitType(CemitTypename.trainGroupSingleTrainRun, trainGroup);
    },
    (trainGroup: T): ReactElement => {
      return (
        <Typography
          {...{
            variant: 'caption',
            sx: {color: 'secondary.main'},
          }}
        >
          {format(trainGroup.singleTrainRun.departureDatetime, dateAndOrTimeFormatString)}
        </Typography>
      );
    },
    always(undefined),
  )(trainGroup);

  const serviceLines: ServiceLine[] = serviceLinesOfTrainRouteOrGroup(
    trainRouteOrGroupsOfTrainGroup(trainGroup),
  );
  return (
    <Stack
      key={trainGroup.id}
      justifyContent="space-between"
      direction="row"
      sx={{color: 'secondary.main'}}
    >
      <Stack {...{sx: {width: `calc(100% - ${iconWidth}px)`}}}>
        <Stack key="serviceAndRoute" {...{direction: 'row', spacing: 1}}>
          {/* @ts-ignore */}
          <TrainRouteOrGroupServiceDescription
            {...{
              showTitle: false,
              isBaseline,
              sxBox,
              source: trainGroup.source,
              serviceLines,
            }}
          />
          <Typography
            key="destination"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="caption"
          >
            {trainRouteLabel}
          </Typography>
        </Stack>
        <Stack key="descriptionAndEquip" {...{direction: 'row', spacing: 1}}>
          <Stack {...{direction: 'row', spacing: 1}}>{rmseIcons}</Stack>
          <TrainDescription {...{showTitle: false, trainGroup}} />
          <TrainRunEquipment {...{showTitle: false, trainGroup}} />
        </Stack>
        <Accordion key="trainRunLineAccordion" {...{disableGutters: true}}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            {perhapsDateAndOrTime}
          </AccordionSummary>
          <AccordionDetails>
            {/* @ts-ignore */}
            <TrainRunLineContainer
              key="trainRunLine"
              {...{
                appProps,
                organizationProps,
                trainProps,
                componentProps: {
                  // Setting this true cases removeIntermediate to be set true, which doesn't seem to have any point now
                  onlyStopsNearInterval: false,
                  spaceGeospatially: false,
                  showLimitedDistanceRange: undefined,
                  showTrainRunOrGroupIntervalBars: false,
                  isTrainRouteLine: false,
                  isTrainGroupDetailLine,
                  trainDetailSize,
                },
                sxTrainRunOrGroupIntervalBar: [],
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Stack
        {...{
          spacing: 0.5,
          sx: {
            width: `${iconWidth}px`,
            marginLeft: '2px',
            marginRight: '2px',
            alignItems: 'center',
          },
        }}
      >
        {buttons}
      </Stack>
    </Stack>
  );
};

export default TrainRunDescription;
