import mapboxgl, {AnyLayer, Map, MapLayerMouseEvent, Popup} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {compact} from '@rescapes/ramda';
import {forEach, map} from 'ramda';
import {
  ChartPayloadItem,
  ChartPayloadItemMinimized,
} from 'types/dataVisualizations/chartPayloadItem';
import {MapSourceVisualForTrainGroup} from 'types/mapbox/mapSourceVisual';
import {Feature} from 'geojson';
import {MapboxPosition} from 'types/mapbox/mapboxPosistion';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TRAIN_MAP_PITCH} from 'config/appConfigs/cemitAppConfigs/cemitMapConfig.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {changeStatusesMatchNamePrefixes} from 'appUtils/cemitAppUtils/mapboxSourceChangeStatusUtils.ts';
import {MapSourceChangeStatus} from 'types/mapbox/mapSourceChangeStatus';
import {combineFeatureCollections} from 'utils/geojson/geojsonUtils.ts';
import bbox from '@turf/bbox';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {lengthAsBoolean, throwUnlessDefined} from 'utils/functional/functionalUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {MapboxSearchBox} from '@mapbox/search-js-web';

const {mapboxDivId, mapboxStyle} = AppSettings;

const CEMIT_MAPBOX_API_TOKEN = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN);

/**
 * Mounts a map. TODO move style config to a configuration
 * @param [bbox] Optional bounding box to pan and zoom to
 * @param [center] Optional center lon, lat array to pan to
 * @param [zoom] Optional zoom level
 * @returns {Object} The Mabox map instance
 */
const mountMap = ({
  bounds = undefined,
  center = undefined,
  zoom = undefined,
}: MapboxPosition): Map => {
  mapboxgl.accessToken = CEMIT_MAPBOX_API_TOKEN;

  const position = compact({
    center,
    zoom,
    bounds,
  }) as MapboxPosition;

  const map = new mapboxgl.Map({
    container: mapboxDivId,
    style: mapboxStyle,
    antialias: true,
    pitch: TRAIN_MAP_PITCH,
    ...position,
  });
  // instantiate a search box instance
  const searchBox = new MapboxSearchBox();

  // set the mapbox access token, search box API options
  searchBox.accessToken = CEMIT_MAPBOX_API_TOKEN;
  searchBox.options = {};

  // set the mapboxgl library to use for markers and enable the marker functionality
  searchBox.mapboxgl = mapboxgl;
  searchBox.marker = true;

  // bind the search box instance to the map instance
  searchBox.bindMap(map);

  // add the search box instance to the DOM
  document.getElementById('search-box-container').appendChild(searchBox);
  return map;
};

/**
 * Mounts the mapbox map
 * @param loading
 * @param organizationProps
 * @param isTrainMapMounted
 * @param setTrainMap Setter to set the Mapbox map
 * @param setTrainMapLoading Setter to indicate loading
 * @param setIsTrainMapMounted Setter to indicate the map is mounted
 */
export const useNotLoadingEffectTrainMap = (
  loading: boolean,
  organizationProps: OrganizationProps,
  isTrainMapMounted: boolean,
  setTrainMap: StateSetter<Map | undefined>,
  setTrainMapLoading: StateSetter<boolean>,
  setIsTrainMapMounted: StateSetter<boolean>,
): void => {
  useNotLoadingEffect(loading, () => {
    if (!isTrainMapMounted) {
      // Once our async data is loaded we set up the map
      const bounds = lengthAsBoolean(
        organizationProps.organization.stopFeatureCollections,
      )
        ? bbox(
            combineFeatureCollections(
              organizationProps.organization.stopFeatureCollections,
            ),
          )
        : undefined;

      const trainMap = mountMap({bounds});
      setTrainMap(trainMap);

      trainMap.on('style.load', () => {
        // This has to be done because Mapbox doesn't correctly report
        // the loading of styles
        const waiting = () => {
          if (!trainMap.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            setTrainMapLoading(false);
            trainMap.resize();
          }
        };
        waiting();
      });
      setIsTrainMapMounted(true);
    }
  }, [isTrainMapMounted]);
};

export interface MapboxHoverEventProps {
  trainGroup: TrainGroup;
  hoveredStateId: string;
  e: MapLayerMouseEvent;
  popup: Popup;
}

export interface CreateOnHoverProps extends TrainAppMapDependencyProps {
  mostRecentTooltipPayload: ChartPayloadItem[];
  eventProps: MapboxHoverEventProps;
}

export interface MapboxHoverProps extends TrainAppMapDependencyProps {
  dataProps: {
    mapSourceVisuals: MapSourceVisualForTrainGroup[];
    createOnHover: (
      props: CreateOnHoverProps,
      payload: ChartPayloadItemMinimized[],
    ) => void;
    onClickOnly: boolean;
    mostRecentTooltipPayload: ChartPayloadItem[];
  };
}

/**
 *  When the user moves their mouse over the given layers,
 *  calls onHover on the first feature
 * @param appProps
 * @param organizationProps
 * @param trainMap The TrainMap
 * @param mapSourceVisuals Sets of {source, layers, trainGroup} where
 * @param createOnHover Currently only used to show a hover for the train switches on the map. Could be used
 * for other map based hover
 * @param t The translation property
 * @param onClickOnly Default false. If true, require a click to activate the onHover
 * trainGroup is only set if the layer is specific to a TrainRun
 */
export const setMapboxOnHover = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  dataProps: {
    mapSourceVisuals,
    createOnHover,
    onClickOnly = false,
    mostRecentTooltipPayload,
  },
}: MapboxHoverProps) => {
  const {trainMap} = mapProps;
  forEach(({layers, trainGroup}) => {
    let hoveredStateId: Perhaps<string>;
    forEach((layer: AnyLayer) => {
      const popup = new mapboxgl.Popup({
        // The closeButton doesn't work correctly, so exclude it here
        /*closeButton: true,*/
        closeOnClick: true,
      });
      const mapboxEvent = onClickOnly ? 'click' : 'mouseenter';
      if (onClickOnly) {
        trainMap.on('mouseenter', layer.id, () => {
          // Change the cursor style as a UI indicator.
          trainMap.getCanvas().style.cursor = 'pointer';
        });
      }
      // On mouseenter or onclick
      trainMap.on(mapboxEvent, layer.id, (e: MapLayerMouseEvent) => {
        if (!onClickOnly) {
          // Change the cursor style as a UI indicator.
          trainMap.getCanvas().style.cursor = 'pointer';
        }
        // Imitate recharts payload format
        const chartStylePayloadFromFeatures = (features: Feature[]) => {
          return map((feature: Feature): ChartPayloadItemMinimized => {
            return {payload: feature};
          }, features);
        };

        const payload: ChartPayloadItemMinimized[] = chartStylePayloadFromFeatures(
          e.features as Feature[],
        );
        const createOnHoverProps: CreateOnHoverProps = ts<CreateOnHoverProps>({
          appProps,
          organizationProps,
          trainProps,
          mapProps,
          mostRecentTooltipPayload,
          eventProps: {
            e,
            popup,
            trainGroup,
            hoveredStateId: hoveredStateId as string,
          },
        });
        if (createOnHover) {
          createOnHover(createOnHoverProps, payload);
        }
      });

      trainMap.on('mouseleave', layer.id, () => {
        trainMap.getCanvas().style.cursor = '';
      });
    }, layers);
  }, mapSourceVisuals);
};

/**
 * Returns true if all sourcePrefixes are found in at least one changeStatus instances. It's possible
 * to have more than one changeStatuses whose name matches the sourcePrefix.
 * @param loading
 * @param changeStatuses
 * @param sourcePrefixes
 */
export const useNotLoadingMemoAreMapSourcesLoaded = (
  loading: boolean,
  changeStatuses: MapSourceChangeStatus[],
  sourcePrefixes: string[],
) => {
  return useNotLoadingMemo(loading, () => {
    return changeStatusesMatchNamePrefixes(changeStatuses, sourcePrefixes);
  }, [changeStatuses, sourcePrefixes]);
};
