import {HuntingAlertLevels} from 'types/alerts/hunting/huntingAlertLevels';
import {AlertLabels} from 'types/alerts/alertLabels';
import {TFunction} from 'i18next';
import {HuntingAttributeAlertLevel} from 'types/alerts/hunting/huntingAttributeAlertLevel.ts';

/**
 * The API marks the alert level with these numbers
 * From graphql: element?.[1]?.node?.metadata?.['s-value'];
 */
export enum HuntingAttributeAlertSValue {
  // type = L0 [0,0.15> - Fargekode Grønn
  none = 0,
  // type = L1 [0.15, 0.2> - Fargekode Gul
  warning = 0.15,
  // type = L2 [0.2, 0.25> - Fargekode Rød
  // type = L3,L4 [0.25, -->> - Fargekode Rød
  critical = 0.2,
}

export const huntingLevelToAttribute: HuntingAlertLevels = {
  L0: HuntingAttributeAlertLevel.normal,
  L1: HuntingAttributeAlertLevel.warning,
  L2: HuntingAttributeAlertLevel.critical,
  L3: HuntingAttributeAlertLevel.critical,
} as HuntingAlertLevels;

export const huntingLevelToAttributeForType: HuntingAlertLevels = {
  'No Hunting': HuntingAttributeAlertLevel.normal,
  'Light Hunting': HuntingAttributeAlertLevel.warning,
  'Soft Hunting': HuntingAttributeAlertLevel.critical,
  Hunting: HuntingAttributeAlertLevel.critical,
  'Hard Hunting': HuntingAttributeAlertLevel.critical,
} as HuntingAlertLevels;

export const huntingAlertLevelToLabel = (t: TFunction): AlertLabels => {
  return {
    L0: t('green'),
    L1: t('yellow'),
    L2: t('red'),
    L3: t('urgent'),
    L4: t('extreme'),
  };
};
