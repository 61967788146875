import {clearOrganizationLocalStorage} from 'config/appConfigs/appSettings.ts';
import {NavigateFunction} from 'react-router';
import {SetTokenType} from 'utils/hooks/useTokenHook.ts';

/**
 * Logout function
 * @param setToken
 * @param navigate
 */
export const logout = (setToken: SetTokenType, navigate: NavigateFunction) => {
  setToken(undefined);
  // For the Train app, clear localStorage to avoid admin problems of changing organizations
  clearOrganizationLocalStorage();
  navigate('/');
};
