// src/graphql.ts
import {createClient, Client} from 'graphql-ws';
import {WebSocket as NodeWebSocket} from 'ws';

const websocketUrl = process.env.REALTIME_TRAIN_WSL_URL;
const tenantId = process.env.REALTIME_TRAIN_TENANT_ID;
const tenantKey = process.env.REALTIME_TRAIN_TENANT_KEY;
if (!websocketUrl || !tenantId || !tenantKey) {
  throw new Error('Graphql websocket configuration error. Check environmental variables');
}

/**
 * Creates a graphql client for the environment's tenantId and tenantKey
 */

const client: Client = createClient({
  url: websocketUrl,
  connectionParams: {
    'x-tenant-id': tenantId,
    'x-tenant-key': tenantKey,
  },
  webSocketImpl: NodeWebSocket,
});

export default client;
