import { Location } from 'react-router-dom';
import { AppSettings } from '../config/appConfigs/appSettings.ts';

/***
 * Logging app initialization message
 */
export const cemitLoggingAppInit = () => {
  console.debug(
    `Initialized Cemit Web Application, environment=${AppSettings.appEnv}, release=${AppSettings.appRelease}, debug=${JSON.stringify(DEBUG)}`);
};

/***
 * Logs changes to the location object pathname
 * @param location
 */
export const cemitLogLocation = (location: Location) => {
  console.debug(`Location is ${location.pathname}`);
};