import {DependencyUnit, SingleDependencyUnit} from 'types/dependencies/dependencyUnit.ts';
import React from 'react';
import {chain, length, map, omit, tail} from 'ramda';
import {CemitTypename} from 'types/cemitTypename.ts';
import {headOrThrow} from 'utils/functional/functionalUtils.ts';
import {clsOrType} from '../typeUtils/clsOrType.ts';
import {TrainAppDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppDependencyProps.d.ts';
import {TrainAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppOrganizationDependencyProps.ts';

/**
 * Renders a list of SingleDependencyUnit trafficSimComponents, recursing to call each component with a renderChildren function to render
 * the next component and a loading boolean to indicate if the component is in a loading state
 * @param singleDependenciesUnits
 * @param dependencyProps
 */
export const buildDependencyUnitChain = <
  P extends TrainAppDependencyProps<RCP>,
  RCP extends P = P,
>(
  singleDependenciesUnits: SingleDependencyUnit[],
  dependencyProps: P,
): JSX.Element => {
  // The last component in the chain should not call a renderChildren, so we should never call this function
  // with no singleDependenciesUnits left
  const singleDependencyUnit = headOrThrow(singleDependenciesUnits);
  const remainingDependencyUnits = tail(singleDependenciesUnits);
  const Component: (dependencyProps: P) => React.ReactElement =
    singleDependencyUnit.component;
  return (
    <Component
      key={Component.name}
      {...({
        loading: singleDependencyUnit.isLoading(dependencyProps),
        ...omit(['loading', 'renderChildren'], dependencyProps),
        // This recursion gets called by Component except for the last component in the chain
        ...(length(remainingDependencyUnits)
          ? {
              renderChildren: (childDependencyProps: RCP) => {
                return buildDependencyUnitChain(
                  remainingDependencyUnits,
                  childDependencyProps,
                );
              },
            }
          : {}),
      } as P)}
    />
  );
};

/**
 * Converts a list of DependencyUnits into SingleDependency units and calls buildDependencyUnitChain
 * @param dependencyUnits
 * @param dependencyProps
 */
export const buildDependencyChain = <P extends TrainAppOrganizationDependencyProps>(
  dependencyUnits: DependencyUnit[],
  dependencyProps: P,
) => {
  const singleDependencyUnits: SingleDependencyUnit[] = chain<
    DependencyUnit,
    SingleDependencyUnit
  >((dependencyUnit: DependencyUnit) => {
    return map((component: (dependencyProps: P) => React.ReactElement) => {
      return clsOrType<SingleDependencyUnit>(CemitTypename.dependencyUnit, {
        isLoading: dependencyUnit.isLoading,
        component,
      });
    }, dependencyUnit.components);
  }, dependencyUnits);
  return buildDependencyUnitChain<P>(singleDependencyUnits, dependencyProps);
};
