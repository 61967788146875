import {Perhaps} from '../../types/typeHelpers/perhaps';
import {idListsEqual, idsEqual} from '../functional/functionalUtils.ts';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {Identified} from '../../types/identified';
import {all, identity, length, zipWith} from 'ramda';
import {eqStrPath} from '@rescapes/ramda';

/**
 * Calls a list setter if the ids of incomingList or different than existingList
 * The setter doesn't have to set the same type as T, thus you can be provided
 * @param existing
 * @param incoming
 * @param setter
 * @param   incomingValueMapped: (ts: T) => T = identity<T> Optional map the value before setting
 * @returns Returns true if the setter was called, else false
 */
export const setIfIdChanged = <
  T extends Perhaps<Identified>,
  U extends Perhaps<Identified> = T,
>(
  existing: Perhaps<T>,
  incoming: Perhaps<T>,
  setter: StateSetter<Perhaps<T | U>>,
  incomingValueMapped: (t: Perhaps<T | U>) => Perhaps<T | U> = identity<Perhaps<T | U>>,
) => {
  if (incoming && !idsEqual(incoming, existing)) {
    setter(incomingValueMapped(incoming));
    return true;
  }
  return false;
};

/**
 * Calls a list setter if the ids of incomingList or different than existingList
 * @param existingList
 * @param incomingList
 * @param setter
 * @param incomingValuesMapped Optional map the values before setting
 * @returns Returns true if the setter was called, else false
 */
export const setListIfIdsChanged = <T extends Identified>(
  existingList: Perhaps<T[]>,
  incomingList: Perhaps<T[]>,
  setter: StateSetter<Perhaps<T[]>>,
  incomingValuesMapped: (ts: T[]) => T[] = identity<T[]>,
) => {
  if (incomingList && !idListsEqual(incomingList, existingList)) {
    setter(incomingValuesMapped(incomingList));
    return true;
  }
  return false;
};

/**
 * Set list if the given any of the prop paths of any matching list items are not equal
 * @param existingList The existing list of Ts
 * @param incomingList The incoming list of Ts
 * @param propPaths Dot-separated paths to compare. If the any part of the propPath is undefined, the value
 * is resolved as undefined. An existing undefined and current undefined are evaluated as equal.
 * @param setter The setter to set with incomingList
 * @param incomingValuesMapped Optional mapping of incomingList from T[] to T[] before setting
 */
export const setListIfGivenPropsChanged = <T extends Identified>(
  existingList: Perhaps<T[]>,
  incomingList: Perhaps<T[]>,
  propPaths: string[],
  setter: StateSetter<Perhaps<T[]>>,
  incomingValuesMapped: (ts: T[]) => T[] = identity<T[]>,
) => {
  let needsSet = false;
  if (
    (!existingList && incomingList) ||
    (incomingList && length(existingList!) != length(incomingList))
  ) {
    needsSet = true;
  } else if (!incomingList) {
    needsSet = false;
  } else {
    needsSet = !all(
      identity<boolean>,
      zipWith<T, T, boolean>(
        (incoming: T, existing: T) => {
          return all<string>((propPath) => {
            return eqStrPath(propPath, incoming, existing);
          }, propPaths);
        },
        incomingList,
        existingList!,
      ),
    );
  }
  if (needsSet) {
    setter(incomingValuesMapped(incomingList!));
    return true;
  }
  return false;
};
