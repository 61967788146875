import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {OrganizationLoaded} from '../../../../types/organizations/organization.ts';
import {ServiceLine} from '../../../../types/trainRouteGroups/serviceLine';
import {
  TrainRoute,
  TrainRouteMinimized,
  TrainRouteResponse,
} from '../../../../types/trainRouteGroups/trainRoute';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {useCemitApiSwrResolveData} from '../../../cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {useNotLoadingEffect} from '../../../../utils/hooks/useMemoHooks.ts';
import {indexBy, map, mergeRight, omit, prop, sortBy} from 'ramda';
import {setListIfIdsChanged} from '../../../../utils/hooks/setterUtils.ts';
import {CemitTypename} from '../../../../types/cemitTypename.ts';
import {TrainRouteOrGroup} from '../../../../types/trainRouteGroups/trainRouteOrGroup';
import {TrainRouteGroupResponse} from '../../../../types/trainRouteGroups/trainRouteGroup';
import {mergeTrainRouteOrGroup} from '../../../../appUtils/trainAppUtils/trainAppTypeMerging/trainRouteOrGroupMerging.ts';
import {createTrainRouteOrGroup} from '../../../../classes/typeCrud/trainRouteOrGroupCrud.ts';
import {idsAndTypenamesEqual} from '../../../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {clsOrType} from '../../../../appUtils/typeUtils/clsOrType.ts';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';

/**
 * Resolves the TrainRoutes from the ServiceLines
 * @param loading
 * @param organization The organization
 * @param serviceLines The ServiceLines to get the TrainRoutes of
 * @param trainRoutes
 * @param setTrainRoutes Setter
 */
export const useConfiguredApiForTrainRoutes = (
  loading: boolean,
  organization: Perhaps<OrganizationLoaded>,
  serviceLines: Perhaps<ServiceLine[]>,
  trainRoutes: Perhaps<TrainRoute[]>,
  setTrainRoutes: StateSetter<Perhaps<TrainRoute[]>>,
): void => {
  const {
    data: newlyLoadedTrainRoutes,
    isLoading,
    isValidating,
  } = useCemitApiSwrResolveData(loading, organization, 'trainRoutes', {serviceLines});
  useNotLoadingEffect(
    loading || !newlyLoadedTrainRoutes || isLoading || isValidating,
    () => {
      const serviceLineLookup = indexBy(prop('id'), serviceLines as ServiceLine[]);
      const newlyLoadedTrainRoutesOrdered = sortBy(prop('id'), newlyLoadedTrainRoutes);
      setListIfIdsChanged<TrainRoute>(
        trainRoutes,
        newlyLoadedTrainRoutesOrdered,
        setTrainRoutes,
        (newlyLoadedTrainRoutesOrdered: TrainRoute[]): TrainRoute[] => {
          return map((trainRoute) => {
            return clsOrType<TrainRouteMinimized>(CemitTypename.trainRouteMinimized, {
              // Resolve the serviceLine from serviceLineId
              serviceLine: serviceLineLookup[trainRoute.serviceLine.id],
              ...trainRoute,
            } as TrainRoute);
          }, newlyLoadedTrainRoutesOrdered);
        },
      );
    },
    [newlyLoadedTrainRoutes, trainRoutes],
  );
};
/**
 * Resolves the detailed TrainRoutes from the activeTrainRouteOrGroup
 * @param loading
 * @param organization The organization
 * @param trainRouteOrGroup
 * @param setTrainRouteOrGroup
 */
export const useConfiguredApiForDetailTrainRoutes = (
  loading: boolean,
  organization: Perhaps<OrganizationLoaded>,
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup>,
  setTrainRouteOrGroup: StateSetter<Perhaps<TrainRouteOrGroup[]>>,
): void => {
  const trainRoutes = trainRouteOrGroup?.trainRoutes;
  const {
    data: newlyLoadedTrainRoutes,
  }: {
    data: (TrainRouteResponse | TrainRouteGroupResponse)[];
  } = useCemitApiSwrResolveData(loading, organization, 'detailTrainRoutes', {
    trainRoutes,
  });

  const dependencies = [newlyLoadedTrainRoutes, trainRouteOrGroup] as const;
  useNotLoadingEffect(
    loading || !newlyLoadedTrainRoutes,
    (newlyLoadedTrainRoutes, trainRouteOrGroup) => {
      if (!idsAndTypenamesEqual(newlyLoadedTrainRoutes, trainRouteOrGroup.trainRoutes)) {
        const trainRoutes: TrainRoute[] = map((newlyLoadedTrainRoute) => {
          return clsOrType<TrainRoute>(
            CemitTypename.trainRouteMinimized,
            newlyLoadedTrainRoute,
          ) as TrainRouteOrGroup;
        }, newlyLoadedTrainRoutes);
        // Create a TrainRouteGroup
        const mergedTrainRouteOrGroup = createTrainRouteOrGroup(
          mergeRightIfDefined(trainRouteOrGroup, {
            __typename: trainRouteOrGroup.__typename,
            // This needs to be a getter so that TrainRouteClasses know that is read-only
            get trainRoutes(): TrainRoute[] {
              return trainRoutes;
            },
          }),
        );
        setTrainRouteOrGroup(mergedTrainRouteOrGroup);
      }
    },
    dependencies,
  );
};
