import {
  cemitFilterDateIntervalRightSideExpression,
  createDateIntervalFilter,
  evalCemitFilterDateInterval,
  extractCemitFilterDateIntervals,
  extractDateIntervals,
  isCemitFilterDateInterval,
  mergeDateFilterRanges,
} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {always, equals, filter, head, is, isNil, length, unless} from 'ramda';
import {onlyOneValueOrNoneThrow} from 'utils/functional/functionalUtils.ts';
import {memoized} from '@rescapes/ramda';
import {endOfDay, isValid, isWithinInterval, parseISO, startOfDay} from 'date-fns';
import {CemitFilterDateInterval} from 'types/cemitFilters/cemitFilterDateInterval';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {useCustomLocalStorageForCemitFilter} from 'utils/hooks/useCustomLocalStorageForCemitFilter.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {LocalStorageProps} from 'types/cemitFilters/localStorageProps.ts';
import {CemitFilterConfig} from 'types/cemitFilters/cemitFilterConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Loads the cemitFilterWithDateIntervals dateInterval if not loading
 * @param loading
 * @param cemitFilterWithDateIntervals
 */
export const useNotLoadingMemoCDateIntervalFromCemitFilter = (
  loading: boolean,
  cemitFilterWithDateIntervals: Perhaps<CemitFilter>,
): Perhaps<DateInterval> => {
  return useNotLoadingMemo(
    loading,
    (cemitFilterWithDateIntervals): Perhaps<DateInterval> | never => {
      return extractOnlyDateInterval(cemitFilterWithDateIntervals, {});
    },
    [cemitFilterWithDateIntervals] as const,
  );
};

/***
 * Return the single dateInterval from cemitFilterWithDateIntervals
 * The Train App only supports one DateInterval, although the filter can technically store multiple
 * @param cemitFilterWithDateIntervals
 * @returns {*}
 */
export const extractOnlyDateInterval = memoized(
  (cemitFilterWithDateIntervals: CemitFilter) => {
    return onlyOneValueOrNoneThrow(
      extractDateIntervals(cemitFilterWithDateIntervals, {}),
    );
  },
);

/**
 * Stores the dateIntervals from the CemitFilter in local storage.
 * The Train App only supports one DateInterval, although the filter can technically store multiple
 * This DateInterval stored as a cookie for a day, such that a stored value from yesterday doesn't persist
 * and instead the user gets the now datetime
 * @param loading
 * @param parentCemitFilter
 * @param cacheKey
 */
export const useCemitFilterDateIntervalsLocalStorage = (
  loading: boolean,
  cacheKey: string,
  parentCemitFilter: CemitFilter,
): [Perhaps<CemitFilter>, StateSetter<Perhaps<CemitFilter>>] => {
  return useCustomLocalStorageForCemitFilter(
    loading,
    clsOrType<LocalStorageProps<DateInterval>>(CemitTypename.localStorageProps, {
      localStorageKey: cacheKey,
      /**
       * Rehydrate with parentCemitFilter if a dateIntervalFilter exists
       * Remove any dateIntervalFilters that start and end today in favor of the current DateTime
       * If the dateIntervalFilters end before today, it suggests the user set it intentionally
       * and we expire this cookie at the end of the day
       * @param dateIntervalFilters
       */
      rehydrate: (dateIntervalFilters: CemitFilterDateInterval[]) => {
        const now = new Date();
        const today = {start: startOfDay(now), end: now};
        const dateIntervalFiltersOutsideOfToday = filter(
          (dateIntervalFilter: CemitFilterDateInterval) => {
            // TODO we currently only allow one dateInterval
            const dateInterval = head(extractDateIntervals(dateIntervalFilter));
            return unless(isNil, (dateInterval: DateInterval) => {
              return !isWithinInterval(dateInterval.end, today);
            })(dateInterval);
          },
          dateIntervalFilters,
        );
        const mergedDateFilterOrParentFilter = length(dateIntervalFiltersOutsideOfToday)
          ? // Merge the dates with those in the parentCemitFilter
            // TODO this currently assumes that all CemitFilters only have one dateInterval that is merged
            // each time a new dateIntervalFilter is added
            mergeDateFilterRanges(
              parentCemitFilter,
              dateIntervalFiltersOutsideOfToday,
              {},
            )
          : parentCemitFilter;
        if (Array.isArray(mergedDateFilterOrParentFilter)) {
          throw new Error(
            'mergedDateFilterOrParentFilter is an array. This should never happen',
          );
        }
        return mergedDateFilterOrParentFilter;
      },
      // We use always here because our customParser takes no config arguments.
      // _key and value are passed from JSON.parse for each key/value pair
      // We need all dates and times to be deserialized to Datetimes.
      customParser: always((_key: string, value: any) => {
        if (is(String, value)) {
          const parsedDate = is(String, value) && parseISO(value);
          return isValid(parsedDate) ? parsedDate : value;
        }
        return value;
      }),
      parserArgument: undefined,
      // Expire at the end of the day so that the user sees the latest datetime
      expiry: endOfDay(new Date()).getTime(),
    }),
    parentCemitFilter,
    clsOrType<CemitFilterConfig>(CemitTypename.cemitFilterConfig, {
      isCemitFilter: isCemitFilterDateInterval,
      evalCemitFilter: evalCemitFilterDateInterval,
      extractCemitFilter: extractCemitFilterDateIntervals,
      rightSideExpression: cemitFilterDateIntervalRightSideExpression,
      cemitFilterTypename: CemitTypename.cemitFilterDateInterval,
    }),
  );
};

/**
 * Update the cemitFilterWithDateInterval based to  on incomingDateInterval if it has changed
 * @param cemitFilterWithDateIntervals
 * @param setCemitFilterWithDateIntervals
 * @param incomingDateInterval
 */
export const setCemitFilterToDateInterval = (
  cemitFilterWithDateIntervals: CemitFilter,
  setCemitFilterWithDateIntervals: StateSetter<CemitFilter>,
  incomingDateInterval: DateInterval,
) => {
  const existingDateInterval = extractOnlyDateInterval(cemitFilterWithDateIntervals);
  const dateIntervalFilter = createDateIntervalFilter(incomingDateInterval);
  const updatedCemitFilterDateInterval = mergeDateFilterRanges(
    cemitFilterWithDateIntervals.parent,
    [dateIntervalFilter],
  );
  if (!equals(existingDateInterval, incomingDateInterval)) {
    setCemitFilterWithDateIntervals(updatedCemitFilterDateInterval);
  }
};
