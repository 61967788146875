export const data = [
  {
    id: 1,
    begransning: 'Avstängt spår',
    plats: 'Sahlgrenska huvudentré - Slingan sahlgrenska',
    berorda: ' 292 är klossad för färd rakspår',
    hastighet: '15',
    orsak: 'Tungorna har dragit sig bakåt och låser växeln',
    starttid: '2023-12-14 14:55',
    sluttid: '2024-06-28 22:00',
    notering: 'CW 16675',
    begränsningPåBegäranAv: 'Håkan Yngvik',
    begränsningInlagdAv: 'hakyng0730',
  },
  {
    id: 2,
    begransning: 'Backförbud',
    plats: 'Drottningtorget - Södra Hamngatan',
    berorda: ' 292 är klossad för färd rakspår',
    hastighet: '10',
    orsak: 'Tungorna har dragit sig bakåt och låser växeln',
    starttid: '2023-12-14 14:55',
    sluttid: '2024-06-28 22:00',
    notering: 'CW 16675',
    begränsningPåBegäranAv: 'Håkan Yngvik',
    begränsningInlagdAv: 'hakyng0730',
  },
  {
    id: 3,
    begransning: 'Hastighetsbegränsning',
    plats: 'Vagnhallen Rantorget',
    berorda: ' 292 är klossad för färd rakspår',
    hastighet: '4',
    orsak: 'Tungorna har dragit sig bakåt och låser växeln',
    starttid: '2023-12-14 14:55',
    sluttid: '2024-06-28 22:00',
    notering: 'CW 16675',
    begränsningPåBegäranAv: 'Håkan Yngvik',
    begränsningInlagdAv: 'hakyng0730',
  },
  {
    id: 4,
    begransning: 'Annan begränsning',
    plats: 'Korsvägen - Berzeliigatan',
    berorda: ' 292 är klossad för färd rakspår',
    hastighet: '15',
    orsak: 'Tungorna har dragit sig bakåt och låser växeln',
    starttid: '2023-12-14 14:55',
    sluttid: '2024-06-28 22:00',
    notering: 'CW 16675',
    begränsningPåBegäranAv: 'Håkan Yngvik',
    begränsningInlagdAv: 'hakyng0730',
  },
];
