import React from 'react';
import {IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';
import {useTheme} from '@mui/styles';

function CloseStyledButton({onClose}) {
  const theme = useTheme();

  const closeButtonStyle = {
    color: theme.palette.text.tertiary,
  };

  return (
    <IconButton onClick={onClose} style={closeButtonStyle}>
      <Close />
    </IconButton>
  );
}

export default CloseStyledButton;
