/**
 * Shows the start and end of the current TrainPage route.
 * In the future it will be possible to click the arrow to reverse the route
 * and click the start and end stations to change them
 * @param trainProps
 * @param trainProps.trainRouteGroupProps
 * @param trainProps.trainRouteGroupProps.trainRoute
 * @param componentProps.reverseFormation Function to reverse the route when the arrow is clicked
 * @param componentProps.chooseFormation Function to bring up to choose the route
 * @returns {JSX.Element}
 */
import React from 'react';
import TrainGroupMenu, {
  TrainGroupMenuProps,
} from '../trainRunChooserComponents/TrainGroupMenu.tsx';

/***
 * Shows a menu that allows selecting a TrainGroup
 * @constructor
 */
const TrainGroupChooser = (props: TrainGroupMenuProps): JSX.Element => {
  return <TrainGroupMenu {...props} />;
};

export default TrainGroupChooser;
