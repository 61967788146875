import { Box } from '@mui/material';
import TrainRouteFunctionChooser
  from 'components/apps/trainAppComponents/chooserComponents/trainRouteChooserComponents/TrainRouteFunctionChooser.tsx';
import { SxProps } from '@mui/system';
import React, { MutableRefObject } from 'react';
import { toArrayIfNot } from '../../../../utils/functional/functionalUtils.ts';

/**
 * The TrainPage Line Station Dot
 * @param sx
 * @param diameter
 * @param stationRef
 * @param showMenu
 * @constructor
 */
const TrainLineStationDot = (
  {
    sx,
    diameter,
    stationRef,
    // showTrainRouteFunctionChooser,
    // hideTrainRouteFunctionChooser,
    showMenu,
  }: {
    sx: SxProps,
    diameter: number,
    stationRef: MutableRefObject<typeof Box>,
    // showTrainRouteFunctionChooser,
    // hideTrainRouteFunctionChooser,
    showMenu: boolean,
  }) => {
  return <Box
    ref={stationRef}
    // TODO supports a pie menu for choosing TrainRoutes
    // onTouchStart={showTrainRouteFunctionChooser}
    // onTouchEnd={hideTrainRouteFunctionChooser}
    // onMouseDown={showTrainRouteFunctionChooser}
    // onMouseUp={hideTrainRouteFunctionChooser}
    // onMouseLeave={hideTrainRouteFunctionChooser}
    sx={[{
      height: diameter,
      width: diameter,
      bgcolor: 'secondary.main',
      borderRadius: '100%',
      zIndex: 2,
      position:'relative'
    },
      ...toArrayIfNot(sx)
    ]}
  >
    {showMenu && <TrainRouteFunctionChooser {...{
      
      x:(stationRef.current).offsetWidth/2,
      
      y:stationRef.current.offsetHeight/2,
    }} />
    }
  </Box>;
};

export default TrainLineStationDot;