import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Feature } from 'geojson';
import { ResolveOffsetLeft } from '../../../../types/layout/resolveOffsetLeft';
import { SxProps } from '@mui/system';
import React from 'react';
import { toArrayIfNot } from '../../../../utils/functional/functionalUtils.ts';

/**
 * Shows the hover line over the TrainGroup based on the map or chart hover
 * @param hoverFeature  The Stored geojson feature that is being hovered over
 * @param resolveOffsetLeft A function that resolves the offset of each bar on the line given the distance property of the bar
 * @param spaceGeospatially
 * @param sx
 * @constructor
 */
const HoverFeature = (
  {
    componentProps: {
      hoverFeature,
      resolveOffsetLeft,
      spaceGeospatially
    },
    sx
  }: {
    componentProps: {
      hoverFeature: Feature,
      resolveOffsetLeft: ResolveOffsetLeft,
      spaceGeospatially: boolean
    },
    sx: SxProps
  }) => {
  const { t } = useTranslation();
  const percentOrPixel = (prop: string | number) => `${prop.toString()}${spaceGeospatially ? '%' : 'px'}`;
  if (!hoverFeature.properties) {
    throw Error('hoverFeature has no properties');
  }
  const left: number = resolveOffsetLeft(hoverFeature.properties.meters);
  const width: number = resolveOffsetLeft(hoverFeature.properties.meters + (spaceGeospatially ? 1 : 5)) - left;
  const bgcolor = 'trainDistanceIntervalBar.main';
  return <Tooltip arrow title={t('hoverFeature')}>
    <Box
      sx={[
        {
          position: 'absolute',
          left: percentOrPixel(left),
          width: percentOrPixel(width),
          top: '-5px',
          height: '20px',
          borderRadius: '20%',
          border: 1,
          borderColor: 'secondary.main',
          bgcolor,
          zIndex: 10
        },
        ...toArrayIfNot(sx)
      ]}>
    </Box>
  </Tooltip>;
};
export default HoverFeature;
