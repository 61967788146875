import {memoizedWith} from '@rescapes/ramda';
import {memoizedTrainRunFeatureCollectionSensorPoints} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunGeojsonUtils.ts';
import {callWorkerAndGetPromise} from '../../../async/webWorker/workerCommunication.ts';
import {SensorDataTrainGroup} from '../../../types/trainGroups/sensorDataTrainGroup';
import {Perhaps} from '../../../types/typeHelpers/perhaps';
import {TrainRouteOrGroup} from '../../../types/trainRouteGroups/trainRouteOrGroup';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Calls memoizedTrainRunFeatureCollectionSensorPoints with a WebWorker
 */
export const workerizedTrainRunFeatureCollectionSensorPoints = memoizedWith(
  ({
    trainRouteOrGroup,
    sensorDataTrainGroup,
  }: {
    trainRouteOrGroup: Perhaps<TrainRouteOrGroup>;
    sensorDataTrainGroup: SensorDataTrainGroup;
  }) => {
    return [
      trainRouteOrGroup?.id,
      sensorDataTrainGroup.trainGroup.id,
      // These are the values that matter for computing geojson
      sensorDataTrainGroup.sensorDataDateIntervals,
      sensorDataTrainGroup.sensorDataPoints,
      sensorDataTrainGroup.trainGroup.activeDateInterval,
    ];
  },
  ({
    trainRouteOrGroup,
    sensorDataTrainGroup,
  }: {
    trainRouteOrGroup: Perhaps<TrainRouteOrGroup>;
    sensorDataTrainGroup: SensorDataTrainGroup;
  }): any => {
    // const trainRunGeojsonWorker = new Worker(
    //   new URL('async/webWorker/trainRunGeojsonWorker.js', import.meta.url),
    // );
    // TODO a curry function is being passed in the arguments and braking the Worker
    //  Worker
    //   ? callWorkerAndGetPromise({
    //       worker: trainRunGeojsonWorker,
    //       workerProps: {
    //         trainRouteOrGroup,
    //         sensorDataTrainGroup,
    //       },
    //     })
    //   :
    return memoizedTrainRunFeatureCollectionSensorPoints(
      trainRouteOrGroup,
      sensorDataTrainGroup,
    );
  },
);
