import { ServiceLine, ServiceLineMinimized } from '../../types/trainRouteGroups/serviceLine';
import { TrainRouteGroupMinimized } from '../../types/trainRouteGroups/trainRouteGroup';
import { cemitedConstructor } from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import { CemitTypename } from '../../types/cemitTypename.ts';
import { Operator } from '../../types/operators/operator';
import { RailwayLine } from '../../types/railways/railwayLine';

import {IdentifiedClass} from "../cemitAppCemitedClasses/identifiedClass.ts";

export class ServiceLineMinimizedClass extends IdentifiedClass implements ServiceLineMinimized {
  constructor(obj: TrainRouteGroupMinimized) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.serviceLineMinimized;
}

export class ServiceLineClass extends ServiceLineMinimizedClass implements ServiceLine {
  constructor(obj: ServiceLineClass) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  declare name: string;
  operator: Operator;
  operatorId: string;
  publicCode: string;
  railwayLines: RailwayLine[];
  transportMode: string;
  transportSubmode: string;
  version: string;
  color: string;
}
