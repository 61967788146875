import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';
import {cemitedConstructor} from 'classes/cemitAppCemitedClasses/cemitClassConstructor.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {CemitedClass} from 'classes/cemitAppCemitedClasses/cemitedClass.ts';
import {CemitApiLoadingStatus} from 'types/apis/cemitApiLoadingStatus.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

export class SensorDataTrainGroupClass
  extends CemitedClass
  implements SensorDataTrainGroup
{
  constructor(obj: SensorDataTrainGroup) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.sensorDataTrainGroup;

  trainGroup: TrainGroup;

  sensorDataDateIntervalErrors: DateInterval[];
  sensorDataDateIntervals: [];
  loadingStatus: CemitApiLoadingStatus;

  get id(): string {
    // Delegate to trainGroup to comfort to the Identified interface
    return this.trainGroup.id;
  }

  get name(): Perhaps<string> {
    // Delegate to trainGroup to comfort to the Identified interface
    return this.trainGroup.name;
  }
}
