import {memo, ReactElement, useCallback, useState} from 'react';
import {TrainGroupFilterProps} from 'types/propTypes/trainPropTypes/trainGroupFilterProps.d.ts';
import {TimeRecurrence} from 'types/datetime/timeRecurrence';
import {
  useConfiguredApiForTrainGroups,
  useNotLoadingDistinctTrainGroupDepartureTimes,
  useNotLoadingMemoTrainGroupsForGroupingId,
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {FilteredTrainGroupProps} from 'types/propTypes/trainPropTypes/filteredTrainGroupProps.d.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useNotLoadingSetFilterAndFilteredTrainGroups} from '../../trainAppHooks/typeHooks/filteredTrainGroupHooks.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {isNil, length} from 'ramda';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {TrainGroupsGroupingCollection} from 'types/trainGroups/trainGroupsGroupingCollection';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';

/**
 * Loads/Updates TrainGroups (currently TrainGroupOnlyTrainFormations or TrainGroupSingleTrainRuns) into trainProps.filteredTrainGroupProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const FilteredTrainGroupDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: TrainAppMapDependencyProps): ReactElement<Required<TrainAppMapDependencyProps>> => {
  // For organizations with ServicesLnes and TrainRoutes, we check that trainGroupsForTrainRouteOrGroup
  // are defined or else we are in a loading state
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);

  // TrainGroupSingleTrainRuns with the selected line and direction
  const [filteredTrainGroups, _setFilteredTrainGroups] =
    useState<Perhaps<TrainGroup[]>>(undefined);

  const setFilteredTrainGroups = useCallback((value) => {
    _setFilteredTrainGroups(value);
  }, []);

  const [allKnownTrainRunDepartureTimes, setAllKnownTrainRunDepartureTimes] = useState<
    TimeRecurrence[]
  >([]);

  const {organization} = organizationProps;

  // FilterProps are an extraction of the instances stored in trainProps.cemitFilter
  // Since the TrainApi currently only supports simple parameters, we can't pass a cemitFilter to it directly
  // When we set the filterProps we clear filteredTrainProps so that the TrainApi can repopulate it. TODO: this
  // might not be desirable in the future, we might want to save the previous sets in filteredTrainGroups
  const [trainGroupFilterProps, setTrainGroupFilterProps] =
    useState<Perhaps<TrainGroupFilterProps>>(undefined);
  useNotLoadingSetFilterAndFilteredTrainGroups(loading, trainProps, {
    setFilteredTrainGroups,
    filterProps: trainGroupFilterProps,
    setFilterProps: setTrainGroupFilterProps,
  });

  // The grouping id is a TrainRouteGroup or organization.id if the former does not exist for the organization
  // trainGroupsByGroupingId allows us to store TrainGroups by TrainRouteGroup so the user can have different
  // TrainGroups active per TrainRouteGroup.
  const [trainGroupsGroupingCollection, setTrainGroupsGroupingCollection] = useState<
    Perhaps<TrainGroupsGroupingCollection>
  >(
    clsOrType<TrainGroupsGroupingCollection<TrainGroupSingleTrainRun>>(
      CemitTypename.trainGroupsGroupingCollection,
      {
        groupingCemitTypename: CemitTypename.trainRouteGroup,
        trainGroupCemitTypename: CemitTypename.trainGroupSingleTrainRun,
        trainGroupsByGroupings: [] as TrainGroupSingleTrainRun[],
      },
    ),
  );
  // Disabling storage of this for now
  //   useStorageTrainGroupsGroupingCollection<TrainGroupSingleTrainRun>(
  //     organizationProps,
  //     trainProps,
  //     {
  //         cacheKey: AppSettings.localStorage.trainGroups,
  //         groupingCemitTypename: organizationHasServiceLines ?
  //             CemitTypename.trainRouteGroup :
  //             CemitTypename.organization,
  //         trainGroupCemitTypename: CemitTypename.trainGroupSingleTrainRun
  //     }
  // );

  // The TrainGroups of the current TrainRouterGroup or Organization if no TrainRouterGroups exist
  const trainGroupsForTrainRouteOrGroup: Perhaps<TrainGroup[]> =
    useNotLoadingMemoTrainGroupsForGroupingId(
      loading,
      organizationProps,
      trainProps,
      trainGroupsGroupingCollection,
    );

  // If trainProps.trainRouteGroupProps.trainRoutes is empty, filteredTrainGroups is as well
  // and we're not in a loading state
  const hasNoTrainRouteGroups: Perhaps<boolean> = useNotLoadingMemo(
    loading!,
    (trainRouteGroupProps) => {
      return !length(trainRouteGroupProps.trainRoutes!);
    },
    [trainProps.trainRouteGroupProps] as const,
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(FilteredTrainGroupDependency.name),
    FilteredTrainGroupDependency.name,
    {
      filteredTrainGroups,
      trainRoute: trainGroupFilterProps?.trainRoute,
      trainGroupsForTrainRouteOrGroup,
    },
    [
      filteredTrainGroups,
      // These are only required if organizationHasServiceLines
      !isNil(organizationHasServiceLines) &&
        organizationHasServiceLines &&
        trainGroupFilterProps?.trainRoute,
      !isNil(organizationHasServiceLines) &&
        organizationHasServiceLines &&
        trainGroupsForTrainRouteOrGroup,
    ],
    appProps.setWhatDependenciesAreLoading,
    // If hasNoTrainRouteGroups is true, everything we need is loaded. Else test each prop in obj for loading
    hasNoTrainRouteGroups ? true : undefined,
  );

  // When we have loaded all filtered TrainsRunGroups for the filter and outsideFilterTrainRuns,
  // we can store them. Once storedTrainRunIds is set to an array (can be empty), we can query
  useConfiguredApiForTrainGroups(
    loading || whatIsLoading.loadingExplanation.trainRoute || hasNoTrainRouteGroups,
    organization,
    trainProps.trainGroupOnlyTrainFormationProps!.trainGroupOnlyTrainFormations,
    trainGroupFilterProps,
    trainProps.trainRouteGroupProps!.trainRoutes,
    filteredTrainGroups,
    setFilteredTrainGroups,
  );

  /**
   * Store the distinct datetime-independent departure times to use a TimeRecurrence filter
   */
  useNotLoadingDistinctTrainGroupDepartureTimes(
    whatIsLoading.loading,
    trainGroupFilterProps,
    filteredTrainGroups,
    allKnownTrainRunDepartureTimes,
    setAllKnownTrainRunDepartureTimes,
  );

  const filteredTrainGroupProps = useMemoClsOrType<
    Omit<FilteredTrainGroupProps, 'loading'>
  >(CemitTypename.filteredTrainGroupProps, {
    whatIsLoading,
    filteredTrainGroups,
    setFilteredTrainGroups,
    allKnownTrainRunDepartureTimes,
    trainGroupsByGroupingId: trainGroupsGroupingCollection,
    setTrainGroupsByGroupingId: setTrainGroupsGroupingCollection,
    trainGroupsForTrainRoute: trainGroupsForTrainRouteOrGroup,
    filterProps: trainGroupFilterProps,
  });

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'filteredTrainGroupProps',
    filteredTrainGroupProps,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    filteredTrainGroupProps,
    'FilteredTrainGroupDependency',
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};
FilteredTrainGroupDependency.displayName = 'TrainRunDependency';
export default FilteredTrainGroupDependency;
//export default memo(TrainGroupDependency);
