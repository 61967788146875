import {Stack} from '@mui/material';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {VisionAppTrainDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppTrainDependencyProps';

export interface VisionImageViewerProps extends CemitComponentProps {
  url: string;
}

const VisionImageViewer = ({
  appProps,
  organizationProps,
  trainProps,
}: VisionAppTrainDependencyProps) => {
  const visionProps = trainProps.visionProps;
  return (
    <Stack
      {...{
        sx: {
          minWidth: 0,
          minHeight: 0,
          justifyContent: 'top',
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          height: '100%',
        },
      }}
    >
      {visionProps.visionMeta ? (
        <img src={visionProps.visionMeta.pictureUrl} />
      ) : undefined}
    </Stack>
  );
};
export default VisionImageViewer;
