import {compose, indexBy, map, mergeDeepWithKey, prop, values} from 'ramda';
import {consolidateIntervals} from 'utils/ranges/rangeUtils.ts';
import {DistanceRange} from 'types/distances/distanceRange';
import {CemitTypename} from 'types/cemitTypename.ts';

import {clsOrTypes} from '../../typeUtils/clsOrTypes.ts';
import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';

/**
 * Merge and existing and incoming lists
 * @param existingDistanceIntervalAndRanges
 * @param incomingDistanceIntervalAndRanges
 */
export const mergeDistanceIntervalAndRanges = (
  existingDistanceIntervalAndRanges: SensorDataTrainGroup[],
  incomingDistanceIntervalAndRanges: SensorDataTrainGroup[],
) => {
  return clsOrTypes<SensorDataTrainGroup>(
    CemitTypename.distanceResolutionAndRangeSensorDataTrainGroup,
    compose(
      // Remove the distanceResolutionKeys
      values,
      // Merge, consolidating distanceRanges from existing and incoming
      ([a, b]: [
        Record<string, SensorDataTrainGroup>,
        Record<string, SensorDataTrainGroup>,
      ]) => {
        return mergeDeepWithKey(
          (key: string, existing: DistanceRange[], incoming: DistanceRange[]) => {
            if (key === 'distanceRanges') {
              return consolidateIntervals<DistanceRange>(existing, incoming);
            }
            return incoming;
          },
          a,
          b,
        );
      },
      // Creates two Record<string, TrainRunDistanceIntervalAndRanges> to merge
      ([existing, incoming]) => {
        return map<SensorDataTrainGroup[], Record<string, SensorDataTrainGroup>>(
          (intervalAndRangeSets: SensorDataTrainGroup[]) =>
            indexBy(
              (intervalAndRanges) =>
                prop<string>('distanceResolution', intervalAndRanges),
              intervalAndRangeSets,
            ),
          [existing, incoming],
        );
      },
    )([existingDistanceIntervalAndRanges, incomingDistanceIntervalAndRanges]),
  );
};
