// MapboxIconConfigs need a unique name per trainGroup per icon
// The svgs must be data urls
import {MapboxIconConfig} from 'types/mapbox/mapSourceVisual';
import {svgDataUrlIconNameForIconOfTrainGroup} from 'appUtils/trainAppUtils/trainGroupMapUtils/trainGroupMapUtils.ts';
import {map} from 'ramda';
import {svgIconComponentToBase64Encoded} from 'utils/svg/svgUtils.ts';

import {TrainGroup} from 'types/trainGroups/trainGroup';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig';

/**
 * Creates a MapboxIconConfig for each alertTypeConfig.attributeAlertLevelConfigs colored for the TrainGroup
 * @param alertTypeConfig
 * @param trainGroup
 */
export const alertTypeConfigTrainGroupIconConfigs = (
  alertTypeConfig: AlertTypeConfig,
  trainGroup: TrainGroup,
): MapboxIconConfig[] => {
  // Convert the React component svg to a data url that Mapbox can use
  return map((attributeAlertLevelConfig: AttributeAlertLevelConfig): MapboxIconConfig => {
    return {
      name: svgDataUrlIconNameForIconOfTrainGroup(
        attributeAlertLevelConfig.attributeAlertLevel,
        trainGroup,
      ),
      svg: svgIconComponentToBase64Encoded(attributeAlertLevelConfig.imageSvgComponent, {
        fill: attributeAlertLevelConfig.color,
        trainGroupFill: trainGroup!.activity!.isActiveColor,
      }),
    } as MapboxIconConfig;
  }, alertTypeConfig.attributeAlertLevelConfigs);
};
