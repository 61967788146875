import {VisionConfig} from 'types/vision/visionConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';

export const getVisionConfig = () => {
  return clsOrType<VisionConfig>(CemitTypename.visionConfig, {
    // The meters from trackDistanceInMeters to query, thus meterDelta*2 meters around trackDistanceInMeters
    // TODO currenlty overridden see visionApiResolvers.ts
    meterDelta: 5,
  });
};
