import {IdentifiedUnsavedClass} from "./identifiedUnsavedClass.ts";
import {Identified} from "../../types/identified";
import {cemitedConstructor} from "./cemitClassConstructor.ts";

export abstract class IdentifiedClass extends IdentifiedUnsavedClass implements Identified {

    protected constructor(obj: Identified) {
        super(obj);
        cemitedConstructor(obj, this);
    }

    declare id: string;
}
