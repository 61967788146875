import {SensorDataPoint} from '../../../types/sensors/sensorDataPoint';
import {concat, ifElse, length, mergeWith, sortBy, uniqBy} from 'ramda';
import {
  hasNonZeroLength,
  headOrThrow,
  lastOrThrow,
} from '../../../utils/functional/functionalUtils.ts';
import {Perhaps} from '../../../types/typeHelpers/perhaps';
import {areIntervalsOverlapping} from 'date-fns';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Merges existing SensorDataPoints with incoming SensorDataPoints such that they are sorted by chronological order.
 * The points come in as a record keyed by device id
 * @param existingSensorDataPoints
 * @param incomingSensorDataPoints
 */
export const mergeSensorDataPoints = (
  existingSensorDataPoints: Record<string, SensorDataPoint[]>,
  incomingSensorDataPoints: Record<string, SensorDataPoint[]>,
): SensorDataPoint[] => {
  // Concat incoming and existing SensorDataPoints for each CDC, sorting by date
  return mergeWith(
    (as: SensorDataPoint[], bs: SensorDataPoint[]) => {
      const timeStamp = (sensorDataPoint: SensorDataPoint) => {
        return sensorDataPoint.time;
      };
      const dateIntervalFromExtremes = (sensorDataPoints: SensorDataPoint[]) => {
        if (length(sensorDataPoints) < 2) {
          return undefined;
        }
        return {
          start: timeStamp(headOrThrow(sensorDataPoints)),
          end: timeStamp(lastOrThrow(sensorDataPoints)),
        };
      };
      const aInterval: Perhaps<DateInterval> = dateIntervalFromExtremes(as);
      const bInterval: Perhaps<DateInterval> = dateIntervalFromExtremes(bs);

      // Merge if both lists are nonempty and overlap
      const mergedSensorDataPoints = ifElse(
        ([aInterval, bInterval]) => {
          return (
            !hasNonZeroLength(as) ||
            !hasNonZeroLength(bs) ||
            !areIntervalsOverlapping(aInterval, bInterval)
          );
        },
        () => {
          // If incoming and existing don't overlap or one is length < 2, do a simple concat of the two
          // arrays based on which is earliest
          return concat(
            ...(sortBy(
              (sensorDataPoints: SensorDataPoint[]) => {
                return length(sensorDataPoints)
                  ? timeStamp(sensorDataPoints[0])
                  : undefined;
              },
              [as, bs],
            ) as [SensorDataPoint[], SensorDataPoint[]]),
          );
        },
        () => {
          // Else sort them all. This is only for the case of loading a higher resolution of a
          // range of SensorDataPoints
          return uniqBy(timeStamp, sortBy(timeStamp, [...as, ...bs]));
        },
      )([aInterval!, bInterval!]);
      return mergedSensorDataPoints;
    },
    existingSensorDataPoints,
    incomingSensorDataPoints,
  );
};
