import {colorHue} from 'theme/colors/colorHue/colorHue.ts';
import {PaletteColorOptions} from '@mui/material';

export const darkBrandColors: {
  brand: PaletteColorOptions;
  primary: PaletteColorOptions;
  secondary: PaletteColorOptions;
} = {
  brand: {
    main: colorHue.gold[700],
    light: colorHue.gold[500],
    dark: colorHue.gold[800],
    hover: colorHue.gold[700],
    contrastText: colorHue.grey[500],
  },
  primary: {
    main: colorHue.gold[500],
    light: colorHue.gold[300],
    dark: colorHue.gold[700],
    contrastText: colorHue.grey[900],
  },
  secondary: {
    main: colorHue.grey[100],
    light: colorHue.grey[300],
    dark: colorHue.grey[600],
    contrastText: colorHue.grey[900],
  },
};
export const lightBrandColors: {
  brand: PaletteColorOptions;
  primary: PaletteColorOptions;
  secondary: PaletteColorOptions;
} = {
  brand: {
    main: colorHue.gold[700],
    light: colorHue.gold[600],
    dark: colorHue.gold[800],
    hover: colorHue.gold[600],
    contrastText: colorHue.grey[500],
  },
  primary: {
    main: colorHue.gold[700],
    light: colorHue.gold[500],
    dark: colorHue.gold[700],
    hover: colorHue.gold[600],
    contrastText: colorHue.grey[900],
  },
  secondary: {
    main: colorHue.grey[400],
    light: colorHue.grey[100],
    dark: colorHue.grey[200],
    contrastText: colorHue.grey[50],
  },
};
