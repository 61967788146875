import {PropStatusClass} from './propStatusClass.ts';
import {
  DateDerivedProps,
  DateLoadedProps,
  DateProps,
} from '../../types/propTypes/trainPropTypes/dateProps';
import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {Interval} from 'date-fns';
import {CemitFilter} from '../../types/cemitFilters/cemitFilter';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {setCemitFilterToDateInterval} from 'async/trainAppAsync/trainAppHooks/cemitFilterHooks/cemitFilterDateIntervalHooks.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';

export class DatePropsClass
  extends PropStatusClass<DatePropsClass, DateLoadedPropsClass, DateDerivedPropsClass>
  implements DateProps
{
  constructor(obj: DateProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  get maybeAsLoaded(): Perhaps<DateDerivedProps> {
    return undefined;
  }

  get maybeAsDerived(): Perhaps<DateDerivedProps> {
    return undefined;
  }

  availableDateRanges: Perhaps<Interval[]>;
  cemitFilterWithDateIntervals: Perhaps<CemitFilter>;
  choosingDateInterval: boolean;
  dateInterval: DateInterval;
  setCemitFilterWithDateIntervals: Perhaps<StateSetter<CemitFilter>>;

  setChoosingDateInterval: StateSetter<boolean>;
  // DateIntervalDescriptions are a convenient way for the user to choose a DateInterval start
  // They specify the end date and choose a DateIntervalDescription to calculate a start date
  // THe possible DateIntervalDescriptions, such as 24hr, 1hr, 15min
  dateIntervalDescriptions: DateIntervalDescription[];
  // The currently chosen DateIntervalDescription
  dateIntervalDescription: DateIntervalDescription;
  setDateIntervalDescription: StateSetter<DateIntervalDescription>;
}

export class DateLoadedPropsClass extends DatePropsClass implements DateLoadedProps {
  constructor(obj: DateLoadedProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}

export class DateDerivedPropsClass
  extends DateLoadedPropsClass
  implements DateDerivedProps
{
  constructor(obj: DateDerivedProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}
