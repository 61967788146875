import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps';
import React, {createElement, SyntheticEvent, useMemo, useState} from 'react';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {concat, includes, indexOf, lensPath, map, over, without} from 'ramda';
import {Box, IconButton, MenuItem, Stack, Tooltip, Typography} from '@mui/material';
import {FilterAlt} from '@mui/icons-material';
import Menu from '@mui/material/Menu/Menu.js';
import {AlertOptionsMenuProps} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertOptionsMenu.tsx';
import {
  AlertTypeConfigVisibleAttributeAlertLevelEnum,
  AttributeAlertLevel,
} from 'types/alerts/attributeAlertLevelEnums';
import {useMemoCurrentAlertTypeConfigVisibleAttributeAlertLevelEnum} from 'async/trainAppAsync/trainAppHooks/alertConfigHooks/alertTypeConfigVisibleAttributeAlertLevelEnumHooks.ts';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';

/**
 * A menu for selected Alert filter options
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
export const AlertFilterMenu = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<AlertOptionsMenuProps>) => {
  const menuItemIconSize = 20;
  const alertTypeConfig = trainProps.alertConfigProps.alertTypeConfig;
  const warningAttributeAlertLevels = alertTypeConfig.warningLevels;
  const [anchorEl, setAnchorEl] = useState<Perhaps<EventTarget & Element>>(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  // The AlertTypeConfigVisibleAttributeAlertLevelEnum corresponding to trainProps.alertConfigProps.alertTypeConfig
  // We need to update its visibleAttributeAlertLevelEnums when the user selects items from the menu
  const alertTypeConfigVisibleAttributeAlertLevelEnum: AlertTypeConfigVisibleAttributeAlertLevelEnum =
    useMemoCurrentAlertTypeConfigVisibleAttributeAlertLevelEnum(trainProps);

  const handleClose = (
    _e: SyntheticEvent,
    visibleAttributeAlertLevelEnum: AttributeAlertLevel,
  ) => {
    setAnchorEl(undefined);
    // if visibleAttributeAlertLevelEnum comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (
      includes(
        visibleAttributeAlertLevelEnum,
        alertTypeConfig.defaultVisibleAttributeAlertLevels,
      )
    ) {
      const index = indexOf(
        alertTypeConfigVisibleAttributeAlertLevelEnum,
        trainProps.alertConfigProps.alertTypeConfigVisibleAttributeAlertLevelEnums,
      );
      trainProps.alertConfigProps.setAlertTypeConfigVisibleAttributeAlertLevelEnums(
        (
          prevAlertTypeConfigVisibleAttributeAlertLevelEnums: AlertTypeConfigVisibleAttributeAlertLevelEnum[],
        ): AlertTypeConfigVisibleAttributeAlertLevelEnum[] => {
          // Either add or remove the visibleAttributeAlertLevelEnum, depending on if it's already in the list
          return over<AlertTypeConfigVisibleAttributeAlertLevelEnum[]>(
            lensPath([index, 'visibleAttributeAlertLevelEnums']),
            (
              visibleAttributeAlertLevelEnums: AttributeAlertLevel[],
            ): AttributeAlertLevel[] => {
              const alreadyIncluded = includes(
                visibleAttributeAlertLevelEnum,
                visibleAttributeAlertLevelEnums,
              );
              return alreadyIncluded
                ? without(
                    [visibleAttributeAlertLevelEnum],
                    visibleAttributeAlertLevelEnums,
                  )
                : concat(visibleAttributeAlertLevelEnums, [
                    visibleAttributeAlertLevelEnum,
                  ]);
            },
            prevAlertTypeConfigVisibleAttributeAlertLevelEnums,
          );
        },
      );
    }
  };

  // Create menu items for each trainProps.alertConfigProps.alertTypeConfig.defaultVisibleAttributeAlertLevels
  // For each that is currently in alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums,
  // selecting it will remove it from  alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums.
  // If it is currently no in that collection, selected it will add it
  const menuItems = useMemo((): Element[] => {
    return map((defaultVisibleAttributeAlertLevel: AttributeAlertLevel): Element => {
      const attributeAlertLevelConfig: AttributeAlertLevelConfig = findOrThrow(
        (attributeAlertLevelConfig: AttributeAlertLevelConfig): boolean => {
          return (
            attributeAlertLevelConfig.attributeAlertLevel ===
            defaultVisibleAttributeAlertLevel
          );
        },
        alertTypeConfig.attributeAlertLevelConfigs,
      );
      return (
        <MenuItem
          key={defaultVisibleAttributeAlertLevel}
          {...{
            onClick: (e: SyntheticEvent) => {
              handleClose(e, defaultVisibleAttributeAlertLevel);
            },
          }}
        >
          {
            <Stack {...{spacing: 1, direction: 'row', sx: {alignItems: 'center'}}}>
              <Box
                key="box"
                {...{
                  sx: {
                    alignSelf: 'center',
                    width: menuItemIconSize,
                    height: menuItemIconSize,
                  },
                }}
              >
                {createElement(attributeAlertLevelConfig.imageSvgComponent, {
                  width: menuItemIconSize,
                  height: menuItemIconSize,
                  fill: attributeAlertLevelConfig.color,
                })}
              </Box>
              <Typography>
                {`${
                  includes(
                    defaultVisibleAttributeAlertLevel,
                    alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums,
                  )
                    ? appProps.t('hide')
                    : appProps.t('show')
                } ${appProps.t(defaultVisibleAttributeAlertLevel)}`}
              </Typography>
            </Stack>
          }
        </MenuItem>
      );
    }, trainProps.alertConfigProps.alertTypeConfig.defaultVisibleAttributeAlertLevels);
  }, [
    trainProps.alertConfigProps.alertTypeConfig,
    alertTypeConfigVisibleAttributeAlertLevelEnum,
  ]);

  return (
    <Tooltip
      {...{
        arrow: true,
        title: appProps.t('filterOptions'),
      }}
    >
      <Stack
        {...{
          direction: 'row',
          spacing: 1,
          sx: {justifyContent: 'left', alignItems: 'start'},
        }}
      >
        <IconButton
          key="button"
          {...{
            size: 'large',
            sx: {
              m: 0,
              p: 0,
              borderRadius: 0,
            },
            id: 'trainGroupOptionsMenu-button',
            'aria-controls': open ? 'formation-positioned-menu' : undefined,
            'aria-haspopup': 'true',
            'aria-expanded': open ? 'true' : undefined,
            onClick: handleClick,
          }}
        >
          <FilterAlt {...{sx: componentProps?.iconSx}} />
        </IconButton>
        <Menu
          key="menu"
          id="trainGroupOptionsMenu-positioned-menu"
          aria-labelledby="trainGroupOptionsMenu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {menuItems}
        </Menu>
      </Stack>
    </Tooltip>
  );
};
