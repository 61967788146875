import {
  chain,
  compose,
  filter,
  forEach,
  head,
  identity,
  ifElse,
  indexOf,
  join,
  lensPath,
  lensProp,
  map,
  set,
  sortBy,
} from 'ramda';
import {Map} from 'mapbox-gl';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {Feature, FeatureCollection, LineString, Point} from 'geojson';
import {
  unlessLoadingProps,
  unlessLoadingValue,
} from 'utils/componentLogic/loadingUtils.ts';
import {
  CreateOnHoverProps,
  setMapboxOnHover,
} from '../cemitAppAsync/cemitAppHooks/mapHooks/trainMapHooks.ts';
import {
  removeMapboxLayersAndSources,
  setMapboxSourceAndLayersSets,
} from 'utils/map/mapboxSourceUtils.ts';
import {
  REALTIME_TRAIN_GROUP_LAYER_PREFIX,
  REALTIME_TRAIN_GROUP_SOURCE_PREFIX,
} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {ChartPayloadItemMinimized} from 'types/dataVisualizations/chartPayloadItem';
import {singlePointFeatureFromPayload} from 'utils/dataFeatures/dataFeaturePayloadUtils.ts';
import {
  MapboxIconConfig,
  MapboxLayer,
  MapSourceVisual,
  MapSourceVisualForTrainGroup,
} from 'types/mapbox/mapSourceVisual';
import {useNotLoadingMemo, useNotLoadingSetterEffect} from 'utils/hooks/useMemoHooks.ts';
import {MapHookDependencyProps} from 'types/propTypes/mapPropTypes/mapHookDependencyProps.ts';

import {TrainGroup} from 'types/trainGroups/trainGroup';
import {RealtimeTrainScopeProps} from 'types/realtimeTrain/realtimeTrainScopeProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {realtimeTrainIncomingOrExistingStatusIsIncomplete} from 'appUtils/realtimeTrainUtils/realtimeTrainScopePropsUtils.ts';
import {TrainGroupRealtimeTrainGeojson} from 'types/trainGroups/trainGroupRealtimeTrainGeojson.ts';
import {updateClsOrTypeDateAndVersion} from 'utils/versionUtils.ts';
import {clsOrType, ts} from 'appUtils/typeUtils/clsOrType.ts';
import {svgIconComponentToBase64Encoded} from 'utils/svg/svgUtils.ts';
import {compact, headOrThrow, lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import {
  memoizedTrainGroupIconImagesSourceAndLayers,
  MemoizedTrainGroupIconImagesSourceAndLayersProps,
} from 'utils/map/layers/dynamicIconLayers.ts';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {svgDataUrlIconNameForIconOfTrainGroup} from 'appUtils/trainAppUtils/trainGroupMapUtils/trainGroupMapUtils.ts';
import TrainFacingRealtimeSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/trainFacingRealtimeSvgIcon.tsx';
import {
  formatInTimeZoneUnlessLocal,
  trainDataFriendlyDatetimeFormatString,
  trainDataFriendlyDatetimeFormatWithTimezoneString,
} from 'utils/datetime/timeUtils.ts';
import {TrackData} from 'types/railways/track';
import nearestPointOnLine from '@turf/nearest-point-on-line';

export type UseUpdate3dForRealtimeTrainMapHookProps = {
  trainMap: Map;
  areRealtimeLayersUpdating: boolean;
  setAreRealtimeLayersUpdating: StateSetter<boolean>;
  featurePropPath: string;
};

/**
 * Creates the RealtimeTrain Mapbox layers for each active TrainGroup
 * TODO we don't currently store trainGroup.realtimeTrainGeojson with crud because it's only used by the map layer
 * @param loading If true, do nothing
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param hookProps
 */
export const useNotLoadingEffectUpdateAlertLayerForRealtimeTrainMap = (
  loading: boolean,
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    hookProps,
  }: MapHookDependencyProps<UseUpdate3dForRealtimeTrainMapHookProps>,
) => {
  const {
    trainMap,
    setAreRealtimeLayersUpdating,
    featurePropPath,
    dataColumns3DLayerAreVisible,
  } = unlessLoadingProps(
    loading,
    () => hookProps as UseUpdate3dForRealtimeTrainMapHookProps,
  );
  const realtimeTrainScopePropsSets =
    trainProps.realtimeTrainProps.realtimeTrainScopePropSets;

  // For each realtimeTrainScopePropsSets, each of which retrains an active TrainGroup
  const realtimeTrainGeojsonTrainGroups: Perhaps<TrainGroup>[] =
    useNotLoadingMemo<TrainGroup>(
      // TODO  !realtimeTrainScopePropsSets should be covered by loading
      loading || !realtimeTrainScopePropsSets,
      (realtimeTrainScopePropsSets: RealtimeTrainScopeProps[]): Perhaps<TrainGroup>[] => {
        return map(
          (realtimeTrainScopeProps: RealtimeTrainScopeProps): Perhaps<TrainGroup> => {
            const scopedTrainGroup = realtimeTrainScopeProps.scopedTrainGroup;
            // If the incoming or existing loading status is not complete, data and thus geojson are not available
            const loading =
              !realtimeTrainScopeProps!.scopedTrainGroup!.realtimeTrainScopeProps ||
              realtimeTrainIncomingOrExistingStatusIsIncomplete(
                realtimeTrainScopeProps.scopedTrainGroup.realtimeTrainScopeProps,
              );

            if (loading) {
              return scopedTrainGroup;
            } else {
              const signal =
                realtimeTrainScopeProps!.scopedTrainGroup!.realtimeTrainScopeProps!
                  .realtimeTrainGroupProps!.signal;

              const signalAsFeature = {
                type: 'Feature',
                properties: {
                  externalId: signal.signalAdded.point.externalId,
                  name: signal.signalAdded.point.name,
                  numericValue: signal.signalAdded.data.numericValue,
                  timestamp: signal.signalAdded.timestamp,
                  type: signal.signalAdded.level,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [
                    signal.signalAdded.location.lon,
                    signal.signalAdded.location.lat,
                  ],
                },
              };

              const featureCollection: Perhaps<FeatureCollection<Point>> =
                unlessLoadingValue(loading, () => {
                  return {
                    type: 'FeatureCollection',
                    features: [signalAsFeature],
                  } as FeatureCollection<Point>;
                });

              return unlessLoadingValue<TrainGroup>(loading, (): TrainGroup => {
                const trainGroupRealtimeTrainGeojson = updateClsOrTypeDateAndVersion(
                  clsOrType<TrainGroupRealtimeTrainGeojson>(
                    CemitTypename.realtimeTrainGroupGeojson,
                    {
                      activeDateInterval: scopedTrainGroup.activeDateInterval!,
                      featureCollections: [featureCollection!],
                      transformedFeatureCollection: featureCollection,
                    },
                  ),
                );
                return setClassOrType<TrainGroup>(
                  lensProp('realtimeTrainGeojson'),
                  trainGroupRealtimeTrainGeojson,
                  scopedTrainGroup,
                );
              });
            }
          },
          realtimeTrainScopePropsSets,
        );
      },
      [realtimeTrainScopePropsSets, appProps.realtimeIsActive] as const,
    );

  return useNotLoadingSetterEffect(
    {
      loading: loading || !realtimeTrainGeojsonTrainGroups,
      inProcessSetter: setAreRealtimeLayersUpdating,
    },
    realtimeTrainLayers,
    ts<RealtimeTrainLayersProps>({
      realtimeTrainGeojsonTrainGroups: realtimeTrainGeojsonTrainGroups as TrainGroup[],
      featurePropPath,
    }),
    // Update the trainMap to the sources and layers that are defined.
    // Those not defined indicate that the corresponding TrainGroup's RealtimeTrain is in a loading state
    (mapSourceVisualSetPairs: MapSourceVisualForTrainGroup[]): void => {
      if (!appProps.realtimeIsActive) {
        // Remove any REALTIME_TRAIN_GROUP_LAYER_PREFIX layers from previous iterations that are no longer train
        removeMapboxLayersAndSources({
          layerPrefix: REALTIME_TRAIN_GROUP_LAYER_PREFIX,
          sourcePrefix: REALTIME_TRAIN_GROUP_SOURCE_PREFIX,
          preserveSourceVisuals: [],
          mapboxMap: trainMap,
        });
        return;
      }
      const realtimeTrainSourceVisuals = filter(
        Boolean,
        chain(identity, mapSourceVisualSetPairs),
      );

      // Snap the single Feature<Point> to the closest railway line segment
      const trackData: TrackData = mapProps.mapLayerProps.trainMapLayerProps.trackData;

      const adjustedRealtimeTrainSourceVisuals = map(
        (realtimeTrainSourceVisual: MapSourceVisual) => {
          const point = onlyFeaturePointOfMapSourceVisual(realtimeTrainSourceVisual);

          const [nearestPoint, trackRouteLineGeojson] = ifElse(
            lengthAsBoolean,
            (trackRouteLines: Feature<LineString>[]) => {
              // Fid the nearestPoint of each trackRouteLineGeojson to point
              const nearestPoints = map((trackRouteLineGeojson: Feature<LineString>) => {
                return nearestPointOnLine(trackRouteLineGeojson, point);
              }, trackRouteLines);

              // Find the nearestPoint overall
              const nearestPoint = head(
                sortBy(
                  (nearestPoint: Feature<Point>) => nearestPoint.properties.dist,
                  nearestPoints,
                ),
              );
              // Identify the that trackRouteLineGeojson had this point
              const trackRouteLineGeojson =
                trackRouteLines[indexOf(nearestPoint, nearestPoints)];
              return [nearestPoint, trackRouteLineGeojson];
            },
            () => {
              return [
                nearestPointOnLine(trackData.trackAsMultiLineString, point),
                undefined,
              ];
            },
          )(trackData.trackRouteLines);

          // Update the properties since nearestPointOnLine creates its own
          const nearestPointWithProperties = set(
            lensProp('properties'),
            point.properties,
            nearestPoint,
          );
          const modifiedRealtimeTrainSourceVisual = compose(
            (realtimeTrainSourceVisual: MapSourceVisual) => {
              return set(
                lensProp('trackRouteLineGeojson'),
                trackRouteLineGeojson,
                realtimeTrainSourceVisual,
              );
            },
            (realtimeTrainSourceVisual: MapSourceVisual) => {
              return nearestPoint
                ? set(
                    lensPath(['source', 'data', 'features', 0]),
                    nearestPointWithProperties,
                    realtimeTrainSourceVisual,
                  )
                : realtimeTrainSourceVisual;
            },
          )(realtimeTrainSourceVisual);
          return modifiedRealtimeTrainSourceVisual;
        },
        realtimeTrainSourceVisuals,
      );

      // Set the sources and layers on TrainMap
      setMapboxSourceAndLayersSets(
        trainMap,
        mapProps.setChangeStatuses,
        adjustedRealtimeTrainSourceVisuals,
        false,
        true,
      );

      // Move these layers to the top so they are above tracks
      // Sort by Alert type so that the more important errors are on top
      // Prioritize RealtimeTrainAttributeAlertLevel so we can sort the icons
      const allLayers = chain(
        (realtimeTrainSourceVisual: MapSourceVisualForTrainGroup) => {
          return realtimeTrainSourceVisual.layers;
        },
        realtimeTrainSourceVisuals,
      );

      forEach((layer: MapboxLayer) => {
        trainMap.moveLayer(layer.id);
      }, allLayers);

      // Create a hover popup that shows info about the TrainGroup and RealtimeTrainAlertType
      setMapboxOnHover({
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        dataProps: {
          mapSourceVisuals: realtimeTrainSourceVisuals,
          createOnHover: realtimeTrainMapboxSourcesAndLayersSetsOnClick,
          onClickOnly: true,
        },
      });

      // Remove any REALTIME_TRAIN_GROUP_LAYER_PREFIX layers from previous iterations that are no longer train
      removeMapboxLayersAndSources({
        layerPrefix: REALTIME_TRAIN_GROUP_LAYER_PREFIX,
        sourcePrefix: REALTIME_TRAIN_GROUP_SOURCE_PREFIX,
        preserveSourceVisuals: realtimeTrainSourceVisuals,
        mapboxMap: trainMap,
      });
    },
    [
      realtimeTrainGeojsonTrainGroups,
      // Update if the user toggles columns
      dataColumns3DLayerAreVisible,
      // Update if the feature props the user is looking at change
      featurePropPath,
      trainProps?.realtimeTrainProps.realtimeTrainScopePropSets,
      appProps.realtimeIsActive,
    ],
  );
};

interface RealtimeTrainLayersProps {
  realtimeTrainGeojsonTrainGroups: TrainGroup[];
  featurePropPath: string;
}

/**
 * Creates the RealtimeTrain Icon layers for each TrainGroup and for each critical and warning RealtimeTrainAlertLevels.
 * Icons for lower alerts are not shown. The critical and warning alerts are put in separate layers so that
 * the former can always appear above the latter
 *
 * @param realtimeTrainGeojsonTrainGroups The TrainGroup instances that are being displayed
 * @param featurePropPath The path in each feature.properties to use for the heatmap value.
 */
const realtimeTrainLayers = ({
  realtimeTrainGeojsonTrainGroups,
  featurePropPath,
}: RealtimeTrainLayersProps): MapSourceVisual[] => {
  // Creates a MapSourceVisual for each TrainGroup if its realtime geojson is loaded
  const mapSourceVisuals: MapSourceVisual[] = compact(
    map((realtimeTrainGeojsonTrainGroup: TrainGroup) => {
      // If the incoming or existing loading status is not complete, data and thus geojson are not available
      const loading = !realtimeTrainGeojsonTrainGroup.realtimeTrainGeojson;
      if (loading) {
        return undefined;
      }
      const sensorPointsTransformed =
        realtimeTrainGeojsonTrainGroup.realtimeTrainGeojson.transformedFeatureCollection;

      if (!realtimeTrainGeojsonTrainGroup!.activity!.isActiveColor) {
        throw new Error('trainGroup.activity.isActiveColor is not defined');
      }
      // Convert the React component svg to a data url that Mapbox can use
      const encodedSvg = map(
        (iconComponent) =>
          svgIconComponentToBase64Encoded(iconComponent, {
            fill: realtimeTrainGeojsonTrainGroup!.activity!.isActiveColor,
          }),
        {realtimeTrain: TrainFacingRealtimeSvgIcon},
      );

      const iconPrefix = 'realtimeTrain';
      // MapboxIconConfigs need a unique name per trainGroup per icon
      // The svgs must be data urls
      const trainGroupIconConfigs: MapboxIconConfig[] = [
        // We don't currently show ok on the map, but it is configured here in case we want to
        {
          name: svgDataUrlIconNameForIconOfTrainGroup(
            iconPrefix,
            realtimeTrainGeojsonTrainGroup,
          ),
          svg: encodedSvg['realtimeTrain'],
        } as MapboxIconConfig,
      ];

      // Creates a MapSourceVisual for each TrainGroup. Each MapSourceVisual has two layers, one for
      // critical and one for warning alerts
      const columnSourceAndLayers: MapSourceVisual =
        memoizedTrainGroupIconImagesSourceAndLayers(
          ts<MemoizedTrainGroupIconImagesSourceAndLayersProps>({
            trainGroup: realtimeTrainGeojsonTrainGroup,
            trainGroupIconConfigs,
            featurePropPath,
            geojson: sensorPointsTransformed,
            sourceNamePrefix: REALTIME_TRAIN_GROUP_SOURCE_PREFIX,
            layerNamePrefix: REALTIME_TRAIN_GROUP_LAYER_PREFIX,
            iconPrefix,
            iconConfigSize: 40,
          }),
        );

      return columnSourceAndLayers;
    }, realtimeTrainGeojsonTrainGroups),
  );
  return mapSourceVisuals;
};

/**
 * Popup to describe ride comfort
 * @param organizationProps
 * @param mapProps
 * @param eventProps
 * @param payload
 */
export const realtimeTrainMapboxSourcesAndLayersSetsOnClick = (
  {organizationProps, mapProps, eventProps}: CreateOnHoverProps,
  payload: ChartPayloadItemMinimized[],
): void => {
  eventProps.popup.remove();

  const feature = singlePointFeatureFromPayload(payload);
  const properties = feature.properties;
  if (!feature || !properties) {
    return;
  }
  const description = join('<br/>', [
    `Train: ${eventProps.trainGroup.localizedName(organizationProps.organization!.timezoneStr)}`,
    formatInTimeZoneUnlessLocal(
      organizationProps.localTimezoneMatchesOrganization,
      new Date(properties.timestamp as string),
      organizationProps.organization.timezoneStr,
      trainDataFriendlyDatetimeFormatWithTimezoneString,
      trainDataFriendlyDatetimeFormatString,
    ),
    `Device Name: ${properties.name}`,
    `Lat-lon: ${feature.geometry.coordinates[1].toFixed(4)}, ${feature.geometry.coordinates[0].toFixed(4)}`,
  ]);

  // Populate the popup and set its coordinates
  // based on the feature found.
  eventProps.popup
    .setLngLat(eventProps.e.lngLat)
    .setHTML(`<div style='font-size: 12px;'>${description}</div>`)
    .addTo(mapProps.trainMap);
};

/**
 * Returns the single point of the
 * @param realtimeTrainMapSourceVisual
 */
export const onlyFeaturePointOfMapSourceVisual = (
  realtimeTrainMapSourceVisual: MapSourceVisual,
): Feature<Point> => {
  return headOrThrow(realtimeTrainMapSourceVisual.source.data.features) as Feature<Point>;
};
