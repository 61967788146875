import {colorHue} from 'theme/colors/colorHue/colorHue.ts';

export const CommonColors = {
  common: {
    constantColor: colorHue.gold[500],
    anotherConstantColor: '#123456',
    backgroundDark: colorHue.grey[500],
    headerBackground: colorHue.grey[600],
    textColor: colorHue.grey[100],
    gold: colorHue.gold[500],
    blue: colorHue.blue[400],
    grey: colorHue.grey[200],
    greyLight: colorHue.grey[100],
    greyDark: colorHue.grey[700],
    navigationBackground: colorHue.grey[500],
  },
};
