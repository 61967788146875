import {DependencyUnit} from 'types/dependencies/dependencyUnit.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import ServiceLineDependency from 'async/trainAppAsync/trainAppDependencies/trainLineDependencies/ServiceLineDependency.tsx';
import RailwayLineDependency from 'async/trainAppAsync/trainAppDependencies/trainLineDependencies/RailwayLineDependency.tsx';
import TrainMapDependency from 'async/trainAppAsync/trainAppDependencies/presentationDependencies/mapDependencies/TainMapDependency.tsx';
import {chain, fromPairs, map, length} from 'ramda';
import {ReactElement} from 'react';
import TrainMapLayerDependency from 'async/trainAppAsync/trainAppDependencies/presentationDependencies/mapDependencies/TrainMapLayerDependency.tsx';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppOrganizationDependencyProps.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import VisionAppDependency from 'async/visionAppAsync/visionAppDependencies/VisionAppDependency.tsx';
import TrainRouteGroupDependency from 'async/trainAppAsync/trainAppDependencies/trainLineDependencies/TrainRouteGroupDependency.tsx';
import AlertConfigDependency from 'async/trainAppAsync/trainAppDependencies/alertDependencies/AlertConfigDependency.tsx';
import TrainFormationDependency from 'async/trainAppAsync/trainAppDependencies/equipmentDependencies/TrainFormationDepedency.tsx';
import TrainFormationDateDependency from 'async/trainAppAsync/trainAppDependencies/dateDependencies/TrainFormationDateDependency.tsx';
import ActiveTrainGroupsDependency from 'async/trainAppAsync/trainAppDependencies/trainGroupDependencies/ActiveTrainGroupsDependency.tsx';
import FilteredTrainGroupDependency from 'async/trainAppAsync/trainAppDependencies/trainGroupDependencies/FilteredTrainGroupDependency.tsx';
import AlertDependency from 'async/trainAppAsync/trainAppDependencies/alertDependencies/AlertDependency.tsx';
import TrainGroupSingleTrainRunDependency from 'async/trainAppAsync/trainAppDependencies/trainGroupDependencies/TrainGroupSingleTrainRunDependency.tsx';
import {VisionAppMapDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppMapDependencyProps.ts';

/**
 * A list of containers that have async functionality, namely api calls via hooks and state via useState
 * The dependencies are composed such that the first (ServiceLineDependency) renders the second as
 * a child (RailwayLineDependency) and so on until TrainPageContainer is finally rendered.
 * Dependencies listed in an array have the same isLoading call but are still rendered serially, not as parallel
 * children
 */
export const visionAppDependencyUnits: DependencyUnit[] = [
  clsOrType<DependencyUnit<TrainAppOrganizationDependencyProps>>(
    CemitTypename.dependencyUnit,
    {
      isLoading: ({organizationProps}: TrainAppOrganizationDependencyProps) => {
        return organizationProps.loading;
      },
      components: [ServiceLineDependency],
    },
  ),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps: {serviceLineProps}}: VisionAppMapDependencyProps) => {
      return serviceLineProps.loading;
    },
    components: [RailwayLineDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: VisionAppMapDependencyProps) => {
      return trainProps.railwayLineProps.loading;
    },
    components: [TrainRouteGroupDependency, TrainFormationDateDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: VisionAppMapDependencyProps) => {
      return trainProps.trainFormationDateProps.loading;
    },
    components: [TrainFormationDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({
      trainProps: {trainFormationDateProps, trainRouteGroupProps},
    }: VisionAppMapDependencyProps) => {
      return trainFormationDateProps.loading || trainRouteGroupProps.loading;
    },
    components: [FilteredTrainGroupDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps: {filteredTrainGroupProps}}: VisionAppMapDependencyProps) => {
      return filteredTrainGroupProps.loading;
    },
    components: [TrainGroupSingleTrainRunDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: VisionAppMapDependencyProps) => {
      // ActiveTrainGroupsDependency handles either active TrainGroupSingleTrainRuns if one is chosen
      // or else the active TrainGroupOnlyTrainFormation
      return length(
        trainProps.trainGroupSingleTrainRunProps?.activeTrainGroupSingleTrainRuns || [],
      )
        ? trainProps.trainGroupSingleTrainRunProps.loading
        : trainProps.trainGroupOnlyTrainFormationProps.loading;
    },
    components: [ActiveTrainGroupsDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: VisionAppMapDependencyProps) => {
      return trainProps.trainRouteGroupProps.loading;
    },
    components: [TrainMapDependency],
  }),
  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps, mapProps}: TrainAppMapDependencyProps) => {
      return mapProps.loading || trainProps.railwayLineProps.loading;
    },
    components: [TrainMapLayerDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps}: VisionAppMapDependencyProps) => {
      // Alerts can represent TrainGroupOnlyTrainFormations or TrainGroupSingleTrainRun instances,
      // so we need to wait for these to load
      return (
        trainProps.trainGroupOnlyTrainFormationProps.loading ||
        // This can be undefined if there are not TrainGroupSingleTrainRuns
        trainProps.filteredTrainGroupProps?.loading
      );
    },
    components: [AlertConfigDependency],
  }),
  clsOrType<DependencyUnit<VisionAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({trainProps: {alertConfigProps}}: VisionAppMapDependencyProps) => {
      return alertConfigProps.loading;
    },
    components: [AlertDependency],
  }),

  clsOrType<DependencyUnit<TrainAppMapDependencyProps>>(CemitTypename.dependencyUnit, {
    isLoading: ({mapProps}: VisionAppMapDependencyProps) => {
      return false;
    },
    components: [VisionAppDependency],
  }),
];
const componentNameToFuncString = fromPairs(
  chain<DependencyUnit, [string, string]>((dependencyUnit: DependencyUnit) => {
    const funcString = dependencyUnit.isLoading.toString();
    return map((component: (dependencyProps: any) => ReactElement) => {
      return [component.name, funcString];
    }, dependencyUnit.components);
  }, visionAppDependencyUnits),
);

export const isLoadingStringOfDependencyUnit = (componentName: string): string => {
  return componentNameToFuncString[componentName];
};
