import {ReactElement, useMemo, useState} from 'react';

import {
  useNotLoadingEffectUpdateAlertLayerForAlertTrainMap,
  UseUpdate3dForAlertTrainMapHookProps,
} from 'async/alertAsync/alertLayersHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useWhatIsLoading} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {TrainMapSensorLayerProps} from 'types/propTypes/mapPropTypes/trainMapSensorLayerProps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {DataThreshold} from 'types/dataVisualizations/dataThreshold';
import {MapLayerProps, MapProps} from 'types/propTypes/mapPropTypes/mapProps';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {MapSourceVisual} from 'types/mapbox/mapSourceVisual';
import {
  overClassOrType,
  setClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {lensProp} from 'ramda';

/***
 * Loads/Updates MapData layers dependent on sensor data
 * Depends directly on TrainGroupSensor props in trainProps.trainGroupSingleTrainRunProps.geojsonProps and TrainMap props
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const AlertMapSensorLayerDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement<
  Required<TrainAppMapDependencyProps>
> => {
  const [are3dLayersUpdating, set3dLayersUpdating] = useState(false);
  // Store these for zooming back to them later
  const [alertSourceVisuals, setAlertSourceVisuals] = useState<MapSourceVisual[]>([]);
  // The 3d layers are dependent on sensor data
  const dataThresholds: DataThreshold[] =
    trainProps.alertConfigProps.alertTypeConfig.dataThresholds;

  const hookProps: UseUpdate3dForAlertTrainMapHookProps =
    useNotLoadingMemo(loading, () => {
      return {
        trainMap: mapProps.trainMap,
        featurePropPath: 'value',
        dataColumns3DLayerAreVisible: true,
        extrude: false,
        dataThresholds,
        set3dLayersUpdating,
        setAlertSourceVisuals,
      } as UseUpdate3dForAlertTrainMapHookProps;
    }, [mapProps.trainMap, dataThresholds]);

  useNotLoadingEffectUpdateAlertLayerForAlertTrainMap(loading, {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    hookProps,
  });

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(AlertMapSensorLayerDependency.name),
    AlertMapSensorLayerDependency.name,
    {
      '3dLayersUpdatingNotUpdating': !are3dLayersUpdating,
    },
    [are3dLayersUpdating],
    appProps.setWhatDependenciesAreLoading,
  );

  const trainMapSensorLayerProps = useMemoClsOrType<TrainMapSensorLayerProps>(
    CemitTypename.trainMapSensorProps,
    {
      whatIsLoading,
      areLayersUpdating: are3dLayersUpdating,
      setAreLayersUpdating: set3dLayersUpdating,
      alertSourceVisuals,
      setAlertSourceVisuals,
    },
  );

  const mapPropsMerged = useMemo<MapProps>(() => {
    return overClassOrType(
      lensProp('mapLayerProps'),
      (mapLayerProps) => {
        return setClassOrType(
          lensProp('trainMapSensorLayerProps'),
          trainMapSensorLayerProps,
          mapLayerProps,
        );
      },
      mapProps,
    );
  }, [mapProps, are3dLayersUpdating]);

  return renderChildren({
    appProps,
    organizationProps,
    mapProps: mapPropsMerged,
    trainProps,
  });
};

AlertMapSensorLayerDependency.displayName = 'AlertMapSensorLayerDependency';
export default AlertMapSensorLayerDependency;
//export default memo(
//  AlertMapSensorLayerDependency
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
