import {Versioned} from '../types/versioning/versioned';
import {clsOrType} from '../appUtils/typeUtils/clsOrType.ts';
import {compose, isNil, lensProp, over, set} from 'ramda';
import {Perhaps} from '../types/typeHelpers/perhaps';
import {Cemited} from '../types/cemited';

/**
 * Calls updateDateAndVersion and wraps in clsOrType
 * @param obj
 */
export const updateClsOrTypeDateAndVersion = <T extends Versioned>(obj: T): T => {
  return clsOrType<T>(obj.__typename, updateDateAndVersion(obj));
};

/**
 * Updates the localUpdateVersion and localUpdateDate of a Version instance
 * @param obj
 */
export const updateDateAndVersion = <T extends Versioned>(obj: T): T => {
  return compose(
    (obj: T) => {
      return set(lensProp('localUpdateDate'), new Date(), obj);
    },
    (obj: T) => {
      return over(
        lensProp('localUpdateVersion'),
        (localUpdateVersion: Perhaps<number>) => {
          return localUpdateVersion ? obj.localUpdateVersion + 1 : 1;
        },
        obj,
      );
    },
  )(obj);
};

/**
 * Returns true if the obj implements Versioned based on duck-typing
 * @param obj
 */
export const isVersioned = (obj: Cemited): boolean => {
  return !isNil(obj?.localUpdateVersion);
};
