import {darkBrandColors, lightBrandColors} from 'theme/colors/brandColors/brandColors.ts';
import {BackgroundColors} from 'theme/colors/backgroundColors/backgroundColors.ts';
import {TextColor} from 'theme/colors/textColors/textColor.ts';
import {CommonColors} from 'theme/colors/comonColors/comonColors.ts';
import {MapColors} from 'theme/colors/mapColors/mapColors.ts';
import {BorderColors} from 'theme/colors/borderColor/borderColor.ts';
import {FunctionalColors} from 'theme/colors/functionalColors/functionalColors.ts';
import {PaletteOptions} from '@mui/material';

export const darkColors: Omit<PaletteOptions, 'mode'> = {
  ...darkBrandColors,
  ...BackgroundColors.dark,
  ...BorderColors.dark,
  ...TextColor.dark,
  ...FunctionalColors.dark,
  ...MapColors.dark,
  ...CommonColors,
};
export const lightColors: Omit<PaletteOptions, 'mode'> = {
  ...lightBrandColors,
  ...BackgroundColors.light,
  ...BorderColors.light,
  ...TextColor.light,
  ...FunctionalColors.light,
  ...MapColors.light,
  ...CommonColors,
};
