import React, {FC} from 'react';
import {Box, SvgIcon, Typography} from '@mui/material';
import CemitLogo from 'assets/icons/cemit_logo_responsive.svg';
import {useTranslation} from 'react-i18next';
import {AppSettings} from 'config/appConfigs/appSettings.ts';

/**
 * Simple home page which any authenticated user always has access to.
 */
const HomePage: FC = () => {
  const {t} = useTranslation();
  const appRelease = AppSettings.appRelease;
  const logoTitle = `${t('cemitVisualizer')} - Build: ${appRelease}`;
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        background: '#22292e',
        color: 'white',
      }}
    >
      <SvgIcon
        {...{
          component: CemitLogo,
          viewBox: '0 0 33 33',
          sx: {width: '200px', height: 'auto', marginBottom: '1em'},
          title: logoTitle,
        }}
      />
      <Typography variant="h3">Welcome to Cemit Visualizer</Typography>
      <Typography variant="h6" mb={'1em'} color={'gray'}>
        Empowering smarter decisions for the railway industry
      </Typography>
      <Typography>
        Navigate to your specific tools using the left menu.
      </Typography>
    </Box>
  );
};

export default HomePage;
