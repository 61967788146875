import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {CrudList} from 'types/crud/crudList';
import {TrainRoute} from 'types/trainRouteGroups/trainRoute';
import {PerhapsCemited} from 'types/cemited';
import {indexBy, lensProp, map, prop, length} from 'ramda';
import {idsEqual, lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import {
  idsAndTypenamesEqual,
  setClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Identified} from 'types/identified';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';

/**
 * Updates each crudTrainGroups.list to have a value of the given instances at trainGroupSettingProp
 * if the instances change.
 * This allows us to update from the minimum version of the instance that loads with the TrainGroup from the train-api
 * to the fully loaded version of the instance.
 * @param loading Do nothing if true since we are in a loading state
 * @param instances The instances matching the type of crudTrainGroups.list item[trainGroupSettingProp]
 * @param crudTrainGroups The CrudList whose list we might update and save
 * @param trainGroupProp The property of each crudTrainGroups.list item
 */
export const useNotLoadingEffectSetLoadedVersionOfProp = <
  T extends Identified,
  TG extends TrainGroup,
>(
  loading: boolean,
  instances: Perhaps<T[]>,
  crudTrainGroups: PerhapsCemited<CrudList<TG>>,
  trainGroupProp: keyof TrainGroup,
) => {
  return useNotLoadingEffect(
    loading || !lengthAsBoolean(crudTrainGroups?.list),
    (instances: T[], crudTrainGroups: CrudList<TG>) => {
      const idToMaybeLoadedInstances: Record<string, TrainRoute> = indexBy(
        prop('id'),
        instances,
      );
      const updatedList = map((trainGroup: TrainGroup) => {
        const trainGroupInstance: T = trainGroup[trainGroupProp];
        const maybeLoadedInstance: T = idToMaybeLoadedInstances[trainGroupInstance.id];
        const equals = idsEqual(maybeLoadedInstance, trainGroupInstance);
        return equals
          ? setClassOrType(lensProp(trainGroupProp), maybeLoadedInstance, trainGroup)
          : trainGroup;
      }, crudTrainGroups.list);
      // If anything was updated, call updateOrCreateAll
      if (
        !idsAndTypenamesEqual(
          map(prop(trainGroupProp), crudTrainGroups.list),
          map(prop(trainGroupProp), updatedList),
        )
      ) {
        crudTrainGroups.updateOrCreateAll(updatedList);
      }
    },
    [instances, crudTrainGroups] as const,
  );
};

/**
 * When the typename of trainProps.trainRouteGroupProps.trainRouteOrGroup changes because more detail data
 * has loaded, update crudTrainGroups.
 * @param loading
 * @param trainRoutes
 * @param crudTrainGroups
 */
export const useNotLoadingSetLoadedTrainRouteOrGroups = (
  loading: boolean,
  trainRoutes: Perhaps<TrainRoute[]>,
  crudTrainGroups: PerhapsCemited<CrudList<TrainGroup>>,
) => {
  return useNotLoadingEffectSetLoadedVersionOfProp(
    loading,
    trainRoutes,
    crudTrainGroups,
    'trainRouteOrGroup',
  );
};
/**
 * When the typename of trainProps.trainRouteGroupProps.trainRouteOrGroup changes because more detail data
 * has loaded, update crudTrainGroups. TODO this should be generalized
 * @param loading
 * @param trainGroupOnlyTrainFormations
 * @param crudTrainGroups
 */
export const useNotLoadingSetLoadedTrainFormations = (
  loading: boolean,
  trainGroupOnlyTrainFormations: TrainGroupOnlyTrainFormation[],
  crudTrainGroups: PerhapsCemited<CrudList<TrainGroup>>,
) => {
  const trainFormations = useNotLoadingMemo(
    loading,
    () => {
      (trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation) => {
        return trainGroupOnlyTrainFormation.trainFormation;
      };
    },
    trainGroupOnlyTrainFormations,
  );

  return useNotLoadingEffectSetLoadedVersionOfProp(
    loading,
    trainFormations,
    crudTrainGroups,
    'trainFormation',
  );
};
