import React, {useState} from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import {Edit, InfoRounded} from '@mui/icons-material';
import {List} from '@mui/material';
import {ListItem} from '@mui/material';
import {useTheme} from '@mui/styles';
import PrimaryButton from '../themedComponents/buttons/GenericButtons/PrimaryButton/PrimaryButton.tsx';
import SecondaryButton from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/GenericButtons/SecondaryButton/SecondaryButton.tsx';

export function MapDialog({
  type,
  label,
  sectionStatus,
  data,
  canBeActivated,
  containsSpareInSection,
  onSave,
  onCancel,
}) {
  const theme = useTheme();
  const [voltageStatus, setVoltageStatus] = useState(sectionStatus ? 'on' : 'off');
  const [tabValue, setTabValue] = useState('1');
  const [notering, setNotering] = useState(data.comments ? data.comments : '');

  const onOptionSelected = (event) => {
    setVoltageStatus(event.target.value);
  };

  const onNoteChange = (e) => {
    setNotering(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabContentStyle = {
    height: 'auto',
    width: '240px',
    background: 'transparent',
    pb: 1,
  };

  const renderListItem = (title, value) => {
    let items;
    if (typeof value === 'string') {
      items = value.split(/,|\./).map((item) => item.trim());
    } else {
      items = [value];
    }

    return (
      <ListItem
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="subtitle2" sx={{wordWrap: 'break-word'}}>
              {title}
            </Typography>
            {items.length > 0 && (
              <Typography
                variant="body3"
                sx={{wordWrap: 'break-word', textAlign: 'right'}}
              >
                {items[0]}
              </Typography>
            )}
          </Box>
          {items.slice(1).map((item, index) => (
            <Typography
              key={index}
              variant="body3"
              sx={{wordWrap: 'break-word', textAlign: 'right'}}
            >
              {item}
            </Typography>
          ))}
        </Box>
      </ListItem>
    );
  };

  const renderContentBasedOnType = () => {
    switch (type) {
      case 'feedPoint':
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 2,
                padding: '5px',
              }}
            >
              <Typography variant="body2" sx={{color: theme.palette.text.primary}}>
                Övriga egenskaper
              </Typography>
              <SecondaryButton size="extra small">Återställ Sektioner</SecondaryButton>
            </Box>
            <List
              dense={true}
              sx={{
                color: 'text.primary',
                overflow: 'auto',
                maxHeight: 300,
              }}
            >
              {renderListItem('Drift:', data.drift)}
              {renderListItem('Placering:', data.placering)}
              {renderListItem('LS-station:', data.lsStation)}
              {renderListItem('Sektion:', data.section)}
              {renderListItem('Säkerhets-sektioner:', data.sakerhetsSektioner)}
              {renderListItem('ReserveMatarpunkter:', `${data.isSpare}`)}
            </List>
          </>
        );
      case 'station':
        return (
          <List
            dense={true}
            sx={{
              color: 'text.primary',
              overflow: 'auto',
              maxHeight: 300,
            }}
          >
            {renderListItem('Stationens namn:', data.namn)}
            {renderListItem('Stationens status:', data.isOpen ? 'Öppen' : 'Stängd')}
          </List>
        );
      case 'section':
        return (
          <List
            dense={true}
            sx={{
              color: 'text.primary',
              overflow: 'auto',
              maxHeight: 300,
            }}
          >
            {renderListItem('Spårsektion:', data.namn)}
          </List>
        );
      default:
        return <div>Okänd typt</div>;
    }
  };

  const showActionButtons =
    type === 'feedPoint' ? (data.isSpare ? data.isActive : true) : true;

  return (
    <Box sx={tabContentStyle}>
      <Typography
        variant="body4"
        sx={{
          marginBottom: '3px',
          color: theme.palette.text.primary,
        }}
      >
        {label}
      </Typography>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="edit and info tabs"
        variant="fullWidth"
        sx={{
          marginBottom: 1,
          padding: 0,
          minHeight: 0,
        }}
      >
        <Tab
          icon={<Edit sx={{fontSize: 'small', mr: 1}} />}
          iconPosition="start"
          label="Edit"
          aria-label="Edit"
          value="1"
          sx={{
            padding: '0px 12px',
            margin: 0,
            fontSize: '14px',
            minHeight: 'auto',
            '.MuiTab-root.Mui-selected': {
              backgroundColor: 'transparent',
            },
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        />
        <Tab
          icon={<InfoRounded sx={{fontSize: 'small', mr: 1}} />}
          iconPosition="start"
          label="Info"
          aria-label="Info"
          value="2"
          sx={{
            padding: '6px 12px',
            margin: 0,
            fontSize: '14px',
            minHeight: 'auto',
            '.MuiTab-root.Mui-selected': {
              backgroundColor: 'transparent',
            },
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        />
      </Tabs>

      <div style={{display: tabValue === '1' ? 'block' : 'none'}}>
        <FormControl component="fieldset">
          <FormLabel component="legend" sx={{fontSize: '14px'}}>
            Status
          </FormLabel>
          <RadioGroup
            aria-label="voltage status"
            name="voltageStatus"
            value={voltageStatus}
            onChange={onOptionSelected}
          >
            <FormControlLabel
              value={'on'}
              control={<Radio />}
              label={type === 'section' ? 'Återtag nödutlöst sektion' : 'Spänningssatt'}
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '0.8rem',
                  color: theme.palette.text.secondary,
                },
              }}
            />
            <FormControlLabel
              value={'off'}
              control={<Radio />}
              label={type === 'section' ? 'Nödutlösning sektion' : 'Ej spänningssatt'}
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '0.8rem',
                  color: theme.palette.text.secondary,
                },
              }}
            />
          </RadioGroup>
        </FormControl>
        <TextField
          margin="normal"
          required
          fullWidth
          id="note"
          label="Notering"
          name="note"
          autoComplete="note"
          autoFocus
          value={notering}
          onChange={onNoteChange}
          InputLabelProps={{
            style: {
              color: theme.palette.text.tertiary,
              fontSize: '12px',
            },
          }}
        />
        {type === 'feedPoint' && data.isSpare && (
          <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.isActive}
                    onChange={() => {
                      const isActive = !data.isActive;
                      // const isOpen = isActive === false && data.isSpare ? true : data.isOpen;
                      onSave(notering, isActive, isActive);
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography style={{fontSize: '14px'}}>
                    {data.isActive ? 'Aktivera' : 'Aktivera'}
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
        )}

        {showActionButtons && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <PrimaryButton
              onClick={() => {
                if (type === 'feedPoint') {
                  onSave(notering, voltageStatus === 'on', data.isActive);
                  return;
                }
                onSave(notering, voltageStatus === 'on');
              }}
              variant="contained"
              size="small"
              sx={{
                margin: '5px',
              }}
            >
              SPARA
            </PrimaryButton>
            <PrimaryButton
              onClick={onCancel}
              variant="outlined"
              size="small"
              sx={{
                margin: '5px',
              }}
            >
              Avbryt
            </PrimaryButton>
          </Box>
        )}
      </div>
      <div
        style={{
          display: tabValue === '2' ? 'block' : 'none',
          maxHeight: '240px',
          overflowY: 'auto',
        }}
      >
        {renderContentBasedOnType()}
      </div>
    </Box>
  );
}
