import { SvgProps } from './SvgProps.ts';
import React from 'react';

export const CatenaryIcon = ({ fill }: SvgProps) => {
  return <svg width='23' height='47' viewBox='0 0 23 47' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path d='M1.08594 20.7583H22.2108V22.2383H1.08594V20.7583Z' fill={fill} stroke={fill} strokeWidth='1.5'></path>
    <path d='M2 25.2827H2.39038V46.1884L2 44.5803V25.2827Z' fill={fill} stroke={fill} strokeWidth='1.5'></path>
    <rect x='1.08594' y='14.8384' width='2.11249' height='2.9601' fill={fill} stroke={fill} strokeWidth='1.5'></rect>
    <path d='M4.25977 21.3291L13.7601 11.8782L14.2882 12.6183L5.30199 22.0989L4.25977 21.3291Z' fill={fill}
          stroke={fill} strokeWidth='1.5'></path>
    <ellipse cx='16.9293' cy='23.7188' rx='1.05625' ry='1.48005' fill={fill}></ellipse>
    <path d='M1.08594 10.3984H14.8171V11.8785H1.08594V10.3984Z' fill={fill} stroke={fill} strokeWidth='1.5'></path>
    <path
      d='M1.08594 2.57431C1.08594 1.99096 1.55883 1.51807 2.14218 1.51807C2.72553 1.51807 3.19843 1.99096 3.19843 2.57431V7.43826H1.08594V2.57431Z'
      fill={fill} stroke={fill} strokeWidth='1.5'></path>
  </svg>;
};