import {
  any,
  chain,
  equals,
  filter,
  ifElse,
  indexBy,
  length,
  lensProp,
  map,
  pick,
  prop,
  set,
  transpose,
  zipWith,
} from 'ramda';
import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';
import {isWithinInterval} from 'date-fns';
import {
  overClassOrType,
  setClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {SensorDataPoint} from 'types/sensors/sensorDataPoint';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  compact,
  hasNonZeroLength,
  idsInclude,
  mergeRightIfDefined,
  propOfListsEqual,
} from 'utils/functional/functionalUtils.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {CrudList} from 'types/crud/crudList';
import {TrainGroup, TrainGroup} from 'types/trainGroups/trainGroup';
import {clsOrType} from '../../typeUtils/clsOrType.ts';
import {
  dateIntervalIntersection,
  dateIntervalsToElapsedTime,
  dumpDateIntervals,
} from 'utils/datetime/dateUtils.ts';
import {consolidateIntervals} from 'utils/ranges/rangeUtils.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {trainGroupSensorDataPointsWithMostData} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';

/**
 * TrainRuns key their sensorDataPoints by collection device id
 * @param trainGroup
 * @returns {boolean|*}
 */
export const trainGroupHasSensorData = (
  trainGroup: TrainGroup | SensorDataTrainGroup,
): boolean => {
  const sensorDataPoints = trainGroupSensorDataPointsWithMostData(trainGroup);
  return Boolean(length(sensorDataPoints));
};

/**
 * Remove SensorDataPoints from the sensorDataTrainGroup that don't overlap the DateInterval.
 * This is a cache reducing step so we don't have too many SensorDataPoints in memory
 * @param timezoneStr Only used for logging. The local timezone.
 * @param activeDateIntervals. If defined, remove SensorDataPoints that don't overlap this DateInterval. If undefined,
 * remove all SensorDataPoints
 * @param loadedSensorDataTrainGroup
 */
export const removeClearableSensorData = (
  timezoneStr: string,
  activeDateIntervals: Perhaps<DateInterval[]>,
  loadedSensorDataTrainGroup: TrainGroup,
): TrainGroup => {
  // Only keep the sensorDataDateIntervals that overlap dateInterval, or remove all if DateInterval is undefined
  const maybeUpdateSensorDataDateIntervals = (
    sensorDataDateIntervals: DateInterval[],
  ): Perhaps<DateInterval[]> => {
    const keepSensorDataDateIntervals: DateInterval[] = hasNonZeroLength(
      activeDateIntervals,
    )
      ? compact(
          chain((sensorDataDateInterval: DateInterval) => {
            return map((activeDateInterval: DateInterval) => {
              return dateIntervalIntersection(sensorDataDateInterval, activeDateInterval);
            }, activeDateIntervals!);
          }, sensorDataDateIntervals),
        )
      : [];
    const consolidatedDateIntervals = consolidateIntervals(keepSensorDataDateIntervals);
    if (!equals(keepSensorDataDateIntervals, sensorDataDateIntervals)) {
      // console.debug(
      //   `Keeping cached sensor data ${length(consolidatedDateIntervals) ? dumpDateIntervals(consolidatedDateIntervals, timezoneStr) : 'none'} of DateIntervals: ${dumpDateIntervals(sensorDataDateIntervals, timezoneStr)}`,
      // );
      return consolidatedDateIntervals;
    } else {
      // No change
      return undefined;
    }
  };
  const maybeUpdatedSensorDataIntervals: Perhaps<DateInterval[]> =
    maybeUpdateSensorDataDateIntervals(
      loadedSensorDataTrainGroup.sensorDataDateIntervals,
    );

  const maybeUpdatedLoadedSensorDataTrainGroup = maybeUpdatedSensorDataIntervals
    ? setClassOrType(
        lensProp('sensorDataDateIntervals'),
        maybeUpdatedSensorDataIntervals,
        loadedSensorDataTrainGroup,
      )
    : loadedSensorDataTrainGroup;

  // Remove the SensorDataPoints that don't overlap from loadedSensorDataTrainGroup, loadingSensorDataTrainGroup doesn't have them
  const finalizedSensorDataTrainGroup = maybeUpdatedSensorDataIntervals
    ? overClassOrType(
        lensProp('sensorDataPoints'),
        (deviceIdToSensorDataPoints: Record<string, SensorDataPoint[]>) => {
          return map((sensorDataPoints: SensorDataPoint[]) => {
            if (!hasNonZeroLength(activeDateIntervals)) {
              return [];
            }
            return filter((sensorDataPoint: SensorDataPoint) => {
              return any((activeDateInterval: DateInterval) => {
                return isWithinInterval(sensorDataPoint.time, activeDateInterval);
              }, activeDateIntervals);
            }, sensorDataPoints);
          }, deviceIdToSensorDataPoints);
        },
        maybeUpdatedLoadedSensorDataTrainGroup,
      )
    : maybeUpdatedLoadedSensorDataTrainGroup;
  return finalizedSensorDataTrainGroup;
};

/**
 * Updates loadingSensorDataTrainGroups, loadedSensorDataTrainGroups, and crudTrainGroups
 * by removing old sensor data taking up memory.
 * The properties sensorDataDateIntervals and sensorDataPoints are updated to remove entries
 * that do not match the activeTrainGroups's activeDateInterval
 * @param timezoneStr
 * @param loadingSensorDataTrainGroups
 * @param loadedSensorDataTrainGroups
 * @param activeTrainGroups
 * @param setLoadingSensorDataTrainGroups
 * @param setLoadedSensorDataTrainGroups
 * @param crudTrainGroups
 */
export const removeClearableSensorDataFromTrainGroups = (
  timezoneStr: string,
  loadingSensorDataTrainGroups: SensorDataTrainGroup[],
  loadedSensorDataTrainGroups: SensorDataTrainGroup[],
  activeTrainGroups: TrainGroup[],
  setLoadingSensorDataTrainGroups: StateSetter<SensorDataTrainGroup[]>,
  setLoadedSensorDataTrainGroups: StateSetter<SensorDataTrainGroup[]>,
  crudTrainGroups: CrudList<TrainGroup>,
): void => {
  // Keep data of these DateIntervals
  const activeDateIntervals: DateInterval[] = map(
    prop('activeDateInterval'),
    activeTrainGroups || [],
  );
  // Delete all that don't match the requested TrainGroups and current date
  // This prevents memory overload
  if (length(loadedSensorDataTrainGroups)) {
    const zipped = zipWith(
      (
        loadingSensorDataTrainGroup: SensorDataTrainGroup,
        loadedSensorDataTrainGroup: TrainGroup,
      ): [SensorDataTrainGroup, TrainGroup] => {
        // Delete all sensor data from inactive loadedSensorDataTrainGroups
        const deleteAllSensorDataPoints: boolean = !idsInclude(
          loadedSensorDataTrainGroup,
          activeTrainGroups,
        );
        // Either delete all SensorDataPoints or those that don't match the DateInterval.
        // Delete all if this loadedSensorDataTrainGroup isn't in activeTrainGroups
        // Delete selectively if it is, since we don't want to delete DateIntervals in use
        const maybedUpdatedLoadedSensorDataTrainGroup = removeClearableSensorData(
          timezoneStr,
          deleteAllSensorDataPoints ? undefined : activeDateIntervals,
          loadedSensorDataTrainGroup,
        );
        if (maybedUpdatedLoadedSensorDataTrainGroup != loadedSensorDataTrainGroup) {
          return [
            setClassOrType<SensorDataTrainGroup, DateInterval[]>(
              lensProp('sensorDataDateIntervals'),
              maybedUpdatedLoadedSensorDataTrainGroup.sensorDataDateIntervals,
              loadingSensorDataTrainGroup,
            ),
            maybedUpdatedLoadedSensorDataTrainGroup,
          ];
        } else {
          return [loadingSensorDataTrainGroup, loadedSensorDataTrainGroup];
        }
      },
      loadingSensorDataTrainGroups,
      loadedSensorDataTrainGroups,
    );
    const [
      maybeUpdatedLoadingSensorDataTrainGroups,
      maybeUpdatedLoadedSensorDataTrainGroups,
    ] = transpose(zipped);

    // If any sensorDataDateIntervals where removed in clearedLoadedSensorDataTrainGroups,
    // Update loadingSensorDataTrainGroups, loadedSensorDataTrainGroups, and crudTrainGroups
    // So all of them remove the sensorDataDateIntervals and the latter two remove the sensorDataPoints
    if (
      !propOfListsEqual(
        'sensorDataDateIntervals',
        loadedSensorDataTrainGroups,
        maybeUpdatedLoadedSensorDataTrainGroups,
      )
    ) {
      // Find the equivalent items in crudTrainGroups to update
      const trainGroupsById = indexBy(prop('id'), crudTrainGroups.list);
      const trainGroupsToUpdate = map(
        (loadedSensorDataTrainRunGoup: SensorDataTrainGroup) => {
          const updateProps = pick(
            ['sensorDataDateIntervals', 'sensorDataPoints'],
            loadedSensorDataTrainRunGoup,
          );
          const trainGroup = trainGroupsById[loadedSensorDataTrainRunGoup.id];
          // Do a simple mergeRight to replace the two props
          return clsOrType<TrainGroup>(
            trainGroup.__typename,
            mergeRightIfDefined(trainGroup, updateProps),
          );
        },
        maybeUpdatedLoadedSensorDataTrainGroups,
      );

      setLoadingSensorDataTrainGroups(maybeUpdatedLoadingSensorDataTrainGroups);
      setLoadedSensorDataTrainGroups(maybeUpdatedLoadedSensorDataTrainGroups);
      // Update without merging so we can reduce sensorDataDateIntervals and sensorDataPoints
      crudTrainGroups.updateOrCreateAllNoMerge(trainGroupsToUpdate);
    }
  }
};

/**
 * Checks if crudTrainGroup.sensorDataDateIntervals and
 * crudTrainGroup.sensorDataGeojson.sensorDataDateIntervals are up-date-date
 * with what has been loaded into loadedSensorDataTrainGroup.
 * @param loadedSensorDataTrainGroup
 * @param crudTrainGroup
 */
export const areSensorDataDateIntervalsAreUpToDate = (
  loadedSensorDataTrainGroup: SensorDataTrainGroup,
  crudTrainGroup: TrainGroup,
): boolean => {
  const latestLoadedSensorDataDateIntervals = dateIntervalsToElapsedTime(
    loadedSensorDataTrainGroup.sensorDataDateIntervals,
  );
  return (
    equals(
      latestLoadedSensorDataDateIntervals,
      dateIntervalsToElapsedTime(crudTrainGroup.sensorDataDateIntervals),
    ) &&
    Boolean(crudTrainGroup?.sensorDataGeojson?.sensorDataDateIntervals) &&
    equals(
      latestLoadedSensorDataDateIntervals,
      dateIntervalsToElapsedTime(
        crudTrainGroup!.sensorDataGeojson!.sensorDataDateIntervals,
      ),
    )
  );
};
