import {
  CemitApi,
  CemitApiVisionNearbyPictures,
  TrainApiVisionNearbyPicturesRequest,
} from 'types/apis/cemitApi';
import {CemitTypename} from 'types/cemitTypename.ts';
import {typeObject} from 'appUtils/typeUtils/typeObject.ts';
import {
  trainApiVisionNearbyPicturesResponseResolver,
  trainApiVisionRequestResolver,
  visionNearbyPicturesParamsFilter,
} from 'apis/cemitApis/trainApi/visionApiResolvers.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';

export const visionApi: CemitApi = clsOrType<CemitApi>(CemitTypename.cemitApi, {
  baseUrl: process.env.VISION_APP_API as string,
  basePath: 'api',
  version: 1,
  slugifyRequestParams: false,
  // Responses from trainApi are camelized already
  camelizeResponse: false,
  routes: {
    vision: typeObject<CemitApiVisionNearbyPictures>(
      CemitTypename.cemitApiVisionNearbyPicturesRoute,
      {
        routePath: 'vision/nearby_pictures',
        request: clsOrType<TrainApiVisionNearbyPicturesRequest>(
          CemitTypename.trainApiVisionNearbyPicturesRequest,
          {
            // Formations expects only the operator id from the organization
            paramsFilter: visionNearbyPicturesParamsFilter,
          },
        ),
        urlResolver: trainApiVisionRequestResolver,
        responseResolver: trainApiVisionNearbyPicturesResponseResolver,
      },
    ),
  },
});
