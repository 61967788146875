export const CEMIT_ALERT_BLUE = '#4385FF';
export const CEMIT_ALERT_RED = '#FA5760';
export const CEMIT_ALERT_YELLOW = '#EBBD1A';

export const CEMIT_YELLOW = '#FFD952';
export const CEMIT_WHITE = '#FFFFFF';
export const CEMIT_GRAY = '#323F47';
export const CEMIT_GRAY_ON_DARK = '#2E383F';
export const CEMIT_ORANGE = '#f0541e';
// Graph comparison colors
export const CEMIT_COMPARE_BASELINE = '#339900';
export const CEMIT_DEFAULT_TRAIN_COLORS = [
  '#E0B034',
  '#D1CCBA',
  '#3f00ff',
  '#DC1D51',
  '#9D9DFA',
  '#0D9DFA',
  '#FDFD0A',
  '#FD0DAA',
  '#000DAA',
  '#AA0DAA',
  '#C0FDF0',
];
export const CEMIT_COMPARE_FILL_1 = '#A5CB5D';
export const CEMIT_COMPARE_FILL_2 = '#DC1D51';
// Stuff made up for the TrainPage overview Figma sketch
export const CEMIT_TRAIN_RUN_LINE = '#35E9E9';
export const CEMIT_TRAIN_RUN_LINE_DARKER = '#357979';
// TODO don't know how to use this in the theme
export const CEMIT_TRAIN_RUN_INTERVAL_BAR_OPACITY = 0.5;
export const CEMIT_TRAIN_BUTTON_BACKGROUND = '#1B3547';
export const CEMIT_DARK_RED = '#880000';
export const CEMIT_GREEN = '#007700';
export const CEMIT_GREEN_TRAIN = '#007700';

export const CEMIT_HISTORY = '#86378D';
export const CEMIT_ALERT_NORMAL = '#6FCF97';
export const CEMIT_ALERT_WARNING = '#F2C94C';
export const CEMIT_ALERT_CRITICAL = '#EB5757';
export const CEMIT_ALERT_EXTREME = '#FF0000';
export const CEMIT_ALERT_NO_DATA = '#D0D0D010';

// TODO move to theme
export const cemitColors = {
  darkBlue: '#22292e',
  orange: '#FFD952',
  lightBlue: '#485863',
  labelText: '#A3ABB1',
  red: '#F7005B',
  reallyRed: '#FF0000',
  green: '#badc58',
  deepGreen: '#27ae60',
  errorTwist: '#D7FCD4',
  errorTwistAlt: '#48E5C2',
  errorDistance: '#9D9DFA',
  errorDistanceAlt: '#7AB5D8',
  trackGreen: '#009900',
  trackGreenLighter: '#00FF00',
  // These wre from the ride comfort POC
  background: '#2e383f',
  darkGrey: '#2E383F',
  grey: '#fff9',
  white: '#fff',
  yellow: '#f2c94c',
  transparentYellow: '#f2c94c80',
  transparentWhite: `${CEMIT_WHITE}20`,
  transparentWhite50: `${CEMIT_WHITE}50`,
};
