import {
  any,
  concat,
  differenceWith,
  equals,
  filter,
  findIndex,
  forEach,
  groupBy,
  indexBy,
  is,
  isNil,
  join,
  length,
  lensIndex,
  lensProp,
  map,
  mergeRight,
  prop,
  reduce,
  set,
  slice,
  uniqBy,
  zipWith,
} from 'ramda';
import React, {useEffect, useMemo} from 'react';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {mergeDeep} from '@rescapes/ramda';
import {CrudList, ListCrudProps} from 'types/crud/crudList';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {Identified} from 'types/identified';
import {Versioned} from 'types/versioning/versioned';
import {
  overClassOrType,
  overClassOrTypeList,
} from '../functional/cemitTypenameFunctionalUtils.ts';
import {updateClsOrTypeDateAndVersion} from '../versionUtils.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {Activity} from 'types/userState/activity';
import {mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';

const remove = <T>(
  {
    equality,
    setList,
  }: {
    equality: <T>(left: T, right: T) => boolean;
    setList: StateSetter<T[]>;
  },
  obj: T,
) => {
  setList((list: T[]) => {
    const updated = differenceWith(equality, list, [obj]);
    return updated;
  });
};
const clear = <T>(setList: StateSetter<T[]>) => {
  setList([]);
};
/**
 * Update based on equality or create if the object doesn't equal anything in the list
 * @param equality
 * @param setList
 * @param merge The merge function to merge an existing instance with a new one
 * @param incomingList
 * @returns {Object} The updated/created objects
 */
export const updateOrCreate = <T extends Identified>(
  {
    equality,
    setList,
    merge,
  }: {
    equality: <T>(left: T, right: T) => boolean;
    setList: StateSetter<T[]>;
    merge: (existing: T, incoming: T) => T;
  },
  incomingList: T[],
) => {
  // Merge the most recent list with the incoming objs
  setList((existingList: T[]) => {
    const {true: matchingItems = [], false: nonMatchingItems = []} = groupBy((obj: T) => {
      return any((existing: T) => equality(obj, existing), existingList).toString();
    }, incomingList);

    const updatedList: T[] = concat(
      reduce(
        (accum: T[], matchingItem: T) => {
          const index = findIndex(
            (existing: T) => equality(matchingItem, existing),
            existingList,
          );
          // Update the existing object in the list of trainGroups
          return overClassOrTypeList<T, number>(
            lensIndex(index),
            (existing: T) => {
              // Use the configured merge function for the type
              // This will eventually merge recursively similarly to apollo-cache
              const merged = merge(existing, matchingItem);
              return merged;
            },
            accum,
          );
        },
        existingList,
        matchingItems,
      ),
      nonMatchingItems,
    );

    return updatedList;
  });
};

/**
 * Memoized to only run once
 * Simple crud methods for manipulating state that is a list of objects.
 * TODO this needs an id function to match incoming objects with existing, such as (existing, incoming) => eqProps('id', existing, incoming)
 * Right now it just does a deep compare with ramda.equals
 * @param config
 * @param config.equality binary function expecting an incoming object and an existing. Returns true
 * if the objects are considered equal, other false
 * @param [additionalOperations] Optional additional operations, keyed by operation name and valued by
 * a function that always receives the crud object as it's first argument and can use any number of other arguments
 * @param list useState getter or similar
 * @param setList useState setter
 * @param setListCrud Function to store this crud object
 * @param [postSetList] Default undefined. Post processing to call after setting
 * @param [merge] Binary function to call on an existing and new value of the same id.
 * Defaults to mergeDeep, merging deep objects but not arrays
 * @param additionalDependencies These serve as additional dependencies for the useMemo in addition to list and
 * are also passed as the first argument to postInit if the latter is defined. Functions in additionalDependencies
 * are filtered out and not used as dependencies.
 * @returns {{set, addOrUpdate: (function(*=): void), get, clear: (function(): void), removeTrainGroup: (function(*): void)}}
 */
export const useEffectCreateListCrud = <T extends Identified & Versioned & Activity>({
  equality = equals,
  additionalOperations = {},
  list,
  setList,
  setListCrud,
  lookup,
  setLookup,
  postSetList,
  merge = mergeDeep,
  additionalDependencies = [],
}: ListCrudProps<T>): undefined => {
  // Filter out functions. They are passed to postInit but not used as dependencies.
  const dependencies = filter(
    (dependency) => !is(Function, dependency),
    additionalDependencies,
  );

  useEffect(() => {
    // If list or any additionalDependencies are undefined or nothing has changed in the list, do nothing
    if (!list || any(isNil, dependencies)) {
      return;
    }
    // This setList wrapper checks for duplicates and has a sideEffect of calling setLookup
    const setListWrapper = (valueOrFunc: T[] | ((list: T[]) => T[])) => {
      const wrapper = (newList: T[]) => {
        const uniqueList = uniqBy(prop('id'), newList);
        // It should not be possible to insert duplicates, but if it happens, warn and remove them
        if (length(newList) !== length(uniqueList)) {
          console.warn(`Duplicates found: ${join(', ', map(prop('id'), newList))}`);
        }
        // Calls updateDateAndVersion and wraps in clsOrType
        const updatedList = map(updateClsOrTypeDateAndVersion, uniqueList);
        if (postSetList) {
          postSetList(additionalDependencies, updatedList);
        }
        // SideEffect, call setLookup
        setLookup(indexBy(prop('id'), updatedList));
        return updatedList;
      };
      if (is(Function, valueOrFunc)) {
        setList((list) => {
          const newList: T[] = valueOrFunc(list);
          return wrapper(newList);
        });
      } else {
        wrapper(valueOrFunc);
      }
    };

    const crud: CrudList<T> = clsOrType<CrudList<T>>(CemitTypename.crudList, {
      list,
      lookup,
      set: (objs: React.SetStateAction<T[]>) => {
        // We don't allow passing a setter function since we handle merging with updateOrCreate
        return setListWrapper(objs as T[]);
      },
      updateOrCreate: (obj: T): void => {
        updateOrCreate({equality, setList: setListWrapper, merge}, [obj]);
      },
      /**
       * Update or create a subset or all of the list. Only those itmes passed in will be updated
       * @param objs
       */
      updateOrCreateAll: (objs: T[]): void => {
        updateOrCreate({equality, setList: setListWrapper, merge}, objs);
      },
      /**
       * Update or create all but bypass the merge function. This is only used for special cases
       * like clearing a cache, where we want to replace prop values instead of merging them
       * We use mergeRightIfDefined so that any props omitted in each of objs won't overwrite previous values
       * with undefined, since classes can't omit props but can only make them undefined
       * @param objs
       */
      updateOrCreateAllNoMerge: (objs: T[]): void => {
        updateOrCreate(
          {equality, setList: setListWrapper, merge: mergeRightIfDefined},
          objs,
        );
      },
      remove: (obj) => {
        remove({equality, setList: setListWrapper}, obj);
      },
      clear: () => {
        clear(setList);
      },
    });

    const additionalOperationsEvaled = map(
      (func: (crudList: CrudList<T>, ...rest: any[]) => any) => {
        return (...args: any[]) => {
          return func(crud, ...args);
        };
      },
      additionalOperations,
    );
    const updated = {
      ...crud,
      // Pass crud as the first argument to any additionalOperations functions that are defined
      ...additionalOperationsEvaled,
    };
    // if (listCrud && list) {
    //   const diff = objectDiff(listCrud.list, list)
    //   console.log(diff)
    // }
    setListCrud(updated);
  }, [list, ...dependencies]);
};

/**
 * Applies a filter the crudList.list so that items are limited.
 * TODO this is currently done by filtering the list but need to be done as a query in the future to deal with
 * large data
 * @param filterItemFunc Unary filter function to apply to each item of crudList.list
 * @param crudList
 * @param crudList.list The list being filtered
 * @returns A copy of crudList with a list property that returns the filtered items
 */
export const useFilterCrudList = <T>(
  filterItemFunc: (item: T) => boolean,
  crudList: CrudList<T>,
) => {
  return useMemo(() => {
    return filterCrudList(filterItemFunc, crudList);
  }, [crudList.list]);
};

/**
 * useFilterCrudList that returns undefined does nothing if loading is true
 * @param loading Returns undefined if true
 * @param filterItemFunc Unary filter function to apply to each item of crudList.list
 * @param crudList
 * @param crudList.list The list being filtered
 * @returns {Object} a copy of crudList with a list property that returns the filtered items
 */
export const useNotLoadingFilterCrudList = <T>(
  loading: boolean,
  filterItemFunc: (item: T) => boolean,
  crudList: CrudList<T>,
) => {
  return useNotLoadingMemo(loading, () => {
    return filterCrudList(filterItemFunc, crudList);
  }, [crudList]);
};

export const filterCrudList = <T extends Identified & Versioned>(
  filterItemFunc: (item: T) => boolean,
  crudList: CrudList<T>,
) => {
  return overClassOrType(
    lensProp('list'),
    (list: T[]) => {
      return filter(filterItemFunc, list);
    },
    crudList,
  );
};

export const useLimitedCrudList = <T>(list: T[], crudList: CrudList<T>) => {
  return useMemo(() => {
    return limitedCrudList(list, crudList);
  }, [crudList]);
};

export const limitedCrudList = <T>(list: T[], crudList: CrudList<T>) => {
  return set(lensProp('list'), list, crudList);
};

/**
 * Memoized filtering of a list
 * @param filterItemFunc
 * @param list
 * @returns The filtered items
 */
export const useFilterList = <T>(filterItemFunc: (item: T) => boolean, list: T[]) => {
  return useMemo(() => {
    return filter(filterItemFunc, list);
  }, [list]);
};
