import { TrainRouteOrGroup, TrainRouteOrGroupDerived } from '../../../types/trainRouteGroups/trainRouteOrGroup';
import { cond, equals, mergeWithKey, T } from 'ramda';
import { shallowEquals } from '../../../utils/functional/functionalUtils.ts';
import { TrainDistanceInterval } from '../../../types/distances/trainInterval';
import { mergeWithKeyExistingAndIncoming } from 'appUtils/typeUtils/mergeTypeUtils.ts';
import { PartialCemited } from '../../../types/cemited';

/**
 * Merge the existing and incoming TrainRouteOrGroup
 * @param existingTrainRouteOrGroup
 * @param incomingTrainRouteOrGroup
 */
export const mergeTrainRouteOrGroup = (
  existingTrainRouteOrGroup: TrainRouteOrGroupDerived,
  incomingTrainRouteOrGroup: PartialCemited<TrainRouteOrGroupDerived>
): TrainRouteOrGroupDerived => {
  return mergeWithKeyExistingAndIncoming(
    (key: keyof TrainRouteOrGroup, existingPropValue: any, incomingPropValue: any) => {
      return cond([
        // If references equal, just take the incoming prop value
        [shallowEquals, (_a: any, b: any) => {
          return b;
        }],
        [() => equals('trainDistanceInterval', key),
          // trainDistanceInterval can be merged
          (a: TrainDistanceInterval, b: TrainDistanceInterval) => {
            return mergeTrainDistanceInterval(a, b);
          }
        ],
        [T, (_a: any, b: any) => {
          // For now we don't merge anything else in TrainRouteOrGroup
          return b;
        }]
      ])(existingPropValue, incomingPropValue);
    },
    existingTrainRouteOrGroup,
    incomingTrainRouteOrGroup
  ) as TrainRouteOrGroupDerived;
};
export const mergeTrainDistanceInterval = (existingTrainDistanceInterval: TrainDistanceInterval, incomingTrainDistanceInterval: TrainDistanceInterval) => {
  return mergeWithKey<TrainDistanceInterval, TrainDistanceInterval>(
    (key: keyof TrainDistanceInterval, existingPropValue: any, incomingPropValue: any) => {
      return cond([
        // If references equal, just take the incoming prop value
        [shallowEquals, (_a: any, b: any) => {
          return b;
        }],
        [T, (_a: any, b: any) => {
          // For now we don't merge anything else in TrainDistanceInterval
          return b;
          //return all(is(Object), [a, b]) ? mergeDeep(a, b) : b;
        }]
      ])(existingPropValue, incomingPropValue);
    },
    existingTrainDistanceInterval,
    incomingTrainDistanceInterval
  ) as TrainDistanceInterval;
};
