import {TrainFormation} from '../../../types/trains/trainFormation';
import {all, both, complement, cond, is, T} from 'ramda';
import {compact, shallowEquals} from '../../../utils/functional/functionalUtils.ts';
import {mergeDeep} from '@rescapes/ramda';
import {mergeWithKeyExistingAndIncoming} from '../../typeUtils/mergeTypeUtils.ts';

/**
 * Merge TrainFormations
 * @param existingTrainFormation
 * @param incomingTrainFormation
 */
export const mergeTrainFormation = (
  existingTrainFormation: TrainFormation,
  incomingTrainFormation: TrainFormation,
): TrainFormation => {
  return mergeWithKeyExistingAndIncoming(
    (_key: string, existingPropValue: any, incomingPropValue: any) => {
      return cond([
        // If references equal, just take the incoming prop value
        [
          shallowEquals,
          (_a: any, b: any) => {
            return b;
          },
        ],
        [
          T,
          (a: any, b: any) => {
            return all(both(complement(Array.isArray), is(Object)), [a, b])
              ? mergeDeep(a, b)
              : b;
          },
        ],
      ])(existingPropValue, incomingPropValue);
    },
    compact(existingTrainFormation),
    compact(incomingTrainFormation),
  );
};
