import {Location} from '@remix-run/router';

/**
 * Resolves the tab of the TrainPage App based on a Location from useLocation
 * @param location
 * @return {string}
 */
export const resolveTabFromLocation = (location: Location): string => {
  const str = location.pathname;
  return (str.match(/\//g) || []).length === 3
    ? (str.split('/').pop() as string)
    : str.substring(str.lastIndexOf('/trains/') + 7, str.lastIndexOf('/'));
};
