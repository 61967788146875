import {equals, is, map} from 'ramda';
import React, {SyntheticEvent, useMemo, useState} from 'react';
import {Button, Menu, MenuItem, Stack, Typography} from '@mui/material';
import {WeekYear} from 'types/datetime/weekYear';
import {cemitColors} from 'theme/cemitColors.ts';
import {VisionAppTrainDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppTrainDependencyProps';
import {KeyboardArrowDown} from '@mui/icons-material';
import {WeekYearLabel} from 'pages/vision/visionComponents/WeekYearLabel.tsx';

const VisionWeekYearChooser = ({appProps, trainProps}: VisionAppTrainDependencyProps) => {
  const visionProps = trainProps.visionProps;
  const {t} = appProps;
  const [anchorEl, setAnchorEl] = useState(undefined);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = (_e: SyntheticEvent, weekYear: WeekYear) => {
    setAnchorEl(undefined);
    // if weekYear comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, weekYear)) {
      visionProps.setWeekYear(weekYear);
    }
  };
  const weekYearComponents = useMemo<JSX.Element[]>(() => {
    return map((weekYear: WeekYear) => {
      return (
        <MenuItem
          key={weekYear.labelComponents}
          {...{
            disabled: equals(weekYear, visionProps.weekYear),
            onClick: (e: SyntheticEvent) => onClose(e, weekYear),
          }}
        >
          <WeekYearLabel {...{weekYear}} />
        </MenuItem>
      );
    }, visionProps.weekYears);
  }, [visionProps.weekYears, visionProps.weekYear]);

  return (
    <Stack {...{sx: {alignSelf: 'self-start', width: '100%'}}}>
      <Typography
        {...{
          sx: {
            color: cemitColors.white,
            fontSize: '0.8rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
            alignSelf: 'self-start',
          },
        }}
      >
        {t('weekYear')}
      </Typography>
      <Button
        key="button"
        {...{
          variant: 'outlined',
          fullWidth: true,
          sx: {justifyContent: 'space-between'},
          id: 'weekYear-button',
          color: 'secondary',
          'aria-controls': open ? 'weekYear-positioned-menu' : undefined,
          'aria-haspopup': 'true',
          'aria-expanded': open ? 'true' : undefined,
          onClick: handleClick,
          endIcon: <KeyboardArrowDown />,
        }}
      >
        <WeekYearLabel {...{weekYear: visionProps.weekYear}} />
      </Button>
      <Menu
        key="menu"
        {...{
          id: 'weekYear-positioned-menu',
          'aria-labelledby': 'weekYear-button',
          anchorEl,
          open,
          onClose,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {weekYearComponents}
      </Menu>
    </Stack>
  );
};
export default VisionWeekYearChooser;
