import {ascend, cond, equals, sortWith, T} from 'ramda';
import {calculatePercentageOfDistanceRange} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import {reqStrPathThrowing} from '@rescapes/ramda';
import {TrainRun} from '../../../types/trainRuns/trainRun';
import {TrainRouteOrGroupLineFinalizedProps} from 'types/propTypes/trainPropTypes/trainRouteOrGroupLineProps.d.ts';
import {DistanceRange} from '../../../types/distances/distanceRange';

/**
 *
 * Computes the position of an TrainRunOrGroupInterval bar, where the input value is either
 * the left position or the right position (where right is used after to calculate the width)
 * @param trainRouteOrGroupLineFinalizedProps
 * @param value The input value, which is typically a dragged trainDistanceInterval and thus
 * either trainDistanceInterval.distanceRange.start or trainDistanceInterval.distanceRange.end
 * @param xOffset The number of pixels offset of the drag operation
 * @returns {*}
 */
export const computedIntervalBarPosition = (
  trainRouteOrGroupLineFinalizedProps: TrainRouteOrGroupLineFinalizedProps,
  value: number,
  xOffset: number,
): number => {
  const {
    spaceGeospatially,
    showLimitedDistanceRange,
    parentWidth,
    resolveOffsetLeft,
    trainRouteOrGroup: {trainDistanceInterval},
  } = trainRouteOrGroupLineFinalizedProps;

  return cond([
    [
      () => {
        return spaceGeospatially && showLimitedDistanceRange;
      },
      (value: number) => {
        const xOffsetPercentageOfParentWidth: number = (100 * xOffset) / parentWidth;
        return (
          calculatePercentageOfDistanceRange(trainDistanceInterval.distanceRange, value) +
          xOffsetPercentageOfParentWidth
        );
      },
    ],
    [
      () => {
        return spaceGeospatially;
      },
      (value: number) => {
        // If we don't have a limited distance range, we need to normalize from 0 to the routeDistance
        const xOffsetPercentageOfParentWidth = (100 * xOffset) / parentWidth;
        return (
          calculatePercentageOfDistanceRange(trainDistanceInterval.distanceRange, value) +
          xOffsetPercentageOfParentWidth
        );
      },
    ],
    [
      T,
      (value: number) => {
        // If not spaceGeospatially, we need to calculate the offset relative to the station stops, which are evenly spaced
        // and have routeDistances that tell us where our offset should be
        return resolveOffsetLeft(value + xOffset);
      },
    ],
  ])(value);
};

/**
 *  Creates a default unmaximized distanceRange of 1km in the middle of the TrainRoute.
 *  If the user double clicks a maximized TrainRunOrGroupInterval that didn't previously smaller interval,
 *  it defaults to this.
 * @param startOrEnd
 * @param trainRouteDistanceRange
 * @returns {Number} The distance
 */
export const defaultUnmaximizedDistanceRange = (
  startOrEnd: string,
  trainRouteDistanceRange: DistanceRange,
): number => {
  return (
    trainRouteDistanceRange['end'] / 2 + (equals('start', startOrEnd) ? -1000 : 1000)
  );
};

export const sortTrainRunOrGroupIntervals = (trainRuns: TrainRun[]) => {
  return sortWith([
    ascend(reqStrPathThrowing('trainRun.departureDatetime')),
    ascend(reqStrPathThrowing('trainRun.arrivalDatetime')),
  ])(trainRuns);
};
