import {strPathOr} from '@rescapes/ramda';
import {any} from 'ramda';
import React from 'react';
import TrainMapComponent, {
  TrainMapComponentProps,
} from 'pages/trainApp/mapbox/TrainMapComponent.tsx';
import {CemitTypename} from 'types/cemitTypename.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {MapComponentDependencyDerivedProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';
import {useNotLoadingAnyAlertIncomingOrExistingStatusIsIncomplete} from 'async/alertAsync/alertHooks.ts';

export interface TrainMapContainerProps extends CemitComponentProps {
  hideSearch: boolean;
}

/**
 * The Mapbox map for the TrainApp
 * @param trainProps
 * @param mapProps
 * @param sx
 * @constructor
 */
const TrainMapContainer = ({
  trainProps,
  mapProps,
  componentProps,
}: MapComponentDependencyDerivedProps<TrainMapContainerProps>) => {
  // Loading comes from mapProps.loading. updating from mapProps.trainMapLayerProps.geojsonProps updating.
  const loading = mapProps.loading || trainProps.alertProps.loading;
  const rideComfortScopePropSets = trainProps.alertProps.alertScopePropSets;
  // Check if any of the rideComfortScopePropSets are updating
  const updating = useNotLoadingAnyAlertIncomingOrExistingStatusIsIncomplete(
    loading,
    rideComfortScopePropSets,
  );

  // The former indicates loading the map initially. The latter indicates waiting for geojson to be ready
  const loadingMapLabel = loading ? 'loadingMap' : 'updatingMap';

  // TODO improve error handling to use a generic error interface
  const error: boolean = any(
    (trainGroup) => {
      return (
        strPathOr(false, 'trainRun.error', trainGroup) &&
        !strPathOr(false, 'trainRun.loading', trainGroup)
      );
    },
    // If we have none active without errors, look for error in all that are active
    trainProps.trainGroupActivityProps?.activeTrainGroupsWithoutErrors ||
      trainProps.trainGroupActivityProps?.activeTrainGroups ||
      [],
  );
  const childComponentProps: TrainMapComponentProps =
    useMemoClsOrType<TrainMapComponentProps>(
      CemitTypename.trainMapComponentProps,
      {
        loadingMapLabel,
        error,
        loadingOrUpdating: loading || updating,
        ...componentProps,
      },
      [loadingMapLabel, error, loading, updating, componentProps.sx],
    );

  return (
    <TrainMapComponent
      {...{
        trainProps,
        mapProps,
        componentProps: childComponentProps,
      }}
    />
  );
};

TrainMapContainer.displayName = 'MapboxContainer';
export default TrainMapContainer;
