import {
  useMemoCalculateTrainGroupSensorDataToLoad,
  useSetTrainGroupsCompletedLoadingSensorData,
} from 'async/trainAppAsync/trainAppHooks/trainApiHooks/trainApiTrainGroupSensorDataHooks.ts';
import {ReactElement, useCallback, useState} from 'react';
import {all, always, ifElse, indexBy, isNil, prop} from 'ramda';
import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {
  useEffectQueryAndResolveSensorDataOfTrainGroups,
  useNotLoadingActiveSensorDataEligibleTrainGroups,
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {
  useNotLoadingMemoSensorDataPointsSyncedToActive,
  useNotLoadingSyncSensorDataTrainGroups,
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupSensorDataHooks.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {
  crudTrainGroupsOfActiveScope,
  filterSensorTrainGroupsToActiveScope,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {TrainGroupSensorProps} from 'types/propTypes/trainPropTypes/sensorProps';
import {hasNonZeroLength, propOfListsEqual} from 'utils/functional/functionalUtils.ts';
import {
  removeClearableSensorDataFromTrainGroups,
  areSensorDataDateIntervalsAreUpToDate,
} from 'appUtils/trainAppUtils/trainGroupSensorDataUtils/trainGroupSensorDataUtils.ts';
import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';
import {CrudList} from 'types/crud/crudList';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';

/**
 * Loads TrainGroup sensor base props, meaning data from the CDC devices in similar. This
 * data is loaded to match the TrainRun of the TrainGroup and might in the future
 * be limited to the interval of the TrainGroup.
 * Depends directly on trainProps.trainGroupSingleTrainRunProps.loading
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @returns {*}
 * @constructor
 */
const TrainGroupSensorDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: TrainAppMapDependencyProps): ReactElement => {
  // Keeps track of loading of based sensor data for trainGroups
  // Each one's trainGroup property can currently reference a TrainGroupSingleTrainFormation based TrainGroupSingleTrainFormation
  const [loadingSensorDataTrainGroups, _setLoadingSensorDataTrainGroups] = useState<
    SensorDataTrainGroup[]
  >([]);
  const setLoadingSensorDataTrainGroups = useCallback((value) => {
    return _setLoadingSensorDataTrainGroups(value);
  }, []);

  // The loaded version of loadingTrainGroupSensorDataStatus
  // loadingTrainGroupSensorDataStatus is compared to this to determine what needs to be downloaded
  // For the TrainRoute case, we store TrainGroups here for all TrainRoutes so that when the user switches between TrainRoutes, we don't
  // download the same SensorDataPoint data again. This means that we must always filter loadedTrainGroupSensorDataStatus
  // by the current trainProps.trainRouteGroupProps.trainRoute
  const [loadedSensorDataTrainGroups, setLoadedSensorDataTrainGroups] = useState<
    SensorDataTrainGroup[]
  >([]);

  // The props are used for hooks unless we are in a loading state
  type MaybeLoadedTypes = {
    inScopeCrudTrainGroups?: Perhaps<
      CrudList<TrainGroup> | CrudList<TrainGroupOnlyTrainFormation>
    >;
    activeTrainGroups?: Perhaps<TrainGroup[]>;
    activeTrainGroupsWithoutErrors?: Perhaps<TrainGroup<TrainGroup>[]>;
    activeLoadingSensorDataTrainGroups?: Perhaps<SensorDataTrainGroup[]>;
    activeLoadedSensorDataTrainGroups?: Perhaps<SensorDataTrainGroup[]>;
  };
  const {
    inScopeCrudTrainGroups,
    activeTrainGroups,
    activeTrainGroupsWithoutErrors,
    activeLoadedSensorDataTrainGroups,
    activeLoadingSensorDataTrainGroups,
  }: MaybeLoadedTypes = useNotLoadingMemo(
    loading,
    () => {
      const inScopeCrudTrainGroups = crudTrainGroupsOfActiveScope(trainProps);
      const activeLoadingSensorDataTrainGroups = filterSensorTrainGroupsToActiveScope(
        trainProps.trainGroupActivityProps!.activeTrainGroups,
        loadingSensorDataTrainGroups,
      );
      const activeLoadedSensorDataTrainGroups = filterSensorTrainGroupsToActiveScope(
        trainProps.trainGroupActivityProps!.activeTrainGroups,
        loadedSensorDataTrainGroups,
      );

      return {
        inScopeCrudTrainGroups,
        activeTrainGroups: trainProps.trainGroupActivityProps.activeTrainGroups,
        activeTrainGroupsWithoutErrors:
          trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors,
        activeLoadingSensorDataTrainGroups,
        activeLoadedSensorDataTrainGroups,
      };
    },
    [
      trainProps.trainGroupOnlyTrainFormationProps.crudTrainGroupOnlyTrainFormations,
      trainProps.trainGroupSingleTrainRunProps.crudTrainGroups,
      trainProps.trainGroupActivityProps.activeTrainGroups,
      trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors,
      loadingSensorDataTrainGroups,
      loadedSensorDataTrainGroups,
    ],
    {} as MaybeLoadedTypes,
  );

  // Keep loadingSensorDataTrainGroups and loadedSensorDataTrainGroups' trainGroup synced
  // the the inScopeCrudTrainGroups
  useNotLoadingSyncSensorDataTrainGroups(
    loading,
    inScopeCrudTrainGroups,
    activeLoadingSensorDataTrainGroups,
    setLoadingSensorDataTrainGroups,
  );
  useNotLoadingSyncSensorDataTrainGroups(
    loading,
    inScopeCrudTrainGroups,
    activeLoadedSensorDataTrainGroups,
    setLoadedSensorDataTrainGroups,
  );

  // We are in a loading state until loadingSensorDataTrainGroups and loadedSensorDataTrainGroups' trainGroup are
  // synced to the active crudList
  const areLoadedSensorDataTrainGroupsUpToDate = useNotLoadingMemo(
    loading!,
    () => {
      // All loadingSensorDataTrainGroups and loadedSensorDataTrainGroups matching
      // inScopeCrudTrainGroups.list must be synced
      const loaded = all((trainGroup: TrainGroup) => {
        return all(
          (sensorDataTrainGroups) => {
            const lookup = indexBy(prop('id'), sensorDataTrainGroups);
            return ifElse(
              isNil,
              always(true),
              (sensorDataTraingGoup: SensorDataTrainGroup) => {
                return trainGroup == sensorDataTraingGoup.trainGroup;
              },
            )(lookup[trainGroup.id]);
          },
          [loadingSensorDataTrainGroups, loadedSensorDataTrainGroups],
        );
      }, inScopeCrudTrainGroups?.list || []);

      return loaded;
    },
    [inScopeCrudTrainGroups, loadingSensorDataTrainGroups, loadedSensorDataTrainGroups],
  );

  // Convert the activeTrainGroups into SensorDataTrainGroups for querying.
  // SensorDataTrainGroup specify props to query the correct TrainRuns or TrainFormations
  // and also specify what SensorData to query for by DateIntervals or DistanceIntervals
  // TrainFormation.disableSensorData == True are never marked as needing to load data
  const needingToLoadSensorDataTrainGroups: Perhaps<SensorDataTrainGroup[]> =
    useMemoCalculateTrainGroupSensorDataToLoad(
      loading || !areLoadedSensorDataTrainGroupsUpToDate,
      organizationProps,
      trainProps,
      // Include those with errors in case the re-requests
      activeTrainGroups,
      activeLoadingSensorDataTrainGroups,
    );

  // Queries for the sensorDataDateIntervals in each needingToLoadSensorDataTrainGroups
  // and resolves each query
  // The effect cycles each needingToLoadSensorDataTrainGroups from
  // Initial: loading: false, complete: false to
  // Loading: loading: true, complete: false to
  // Done: loading: false, complete: true
  useEffectQueryAndResolveSensorDataOfTrainGroups(
    loading ||
      !hasNonZeroLength(needingToLoadSensorDataTrainGroups) ||
      !areLoadedSensorDataTrainGroupsUpToDate,
    organizationProps.organization,
    trainProps.trainRouteGroupProps.trainRouteOrGroup,
    needingToLoadSensorDataTrainGroups,
    inScopeCrudTrainGroups,
    setLoadingSensorDataTrainGroups,
    setLoadedSensorDataTrainGroups,
  );

  // Set crudTrainGroups to the data loaded in useMemoCalculateTrainRunDataToLoad
  // once modifiedLoadingSensorDataTrainGroups has all those in crudTrainGroups
  useSetTrainGroupsCompletedLoadingSensorData(
    loading!,
    areSensorDataDateIntervalsAreUpToDate,
    inScopeCrudTrainGroups,
    activeLoadedSensorDataTrainGroups,
  );

  // Only test activeTrainGroupsWithoutErrors that are configured to load sensor data
  const activeSensorDataEligibleTrainGroupsWithoutErrors =
    useNotLoadingActiveSensorDataEligibleTrainGroups(
      loading!,
      activeTrainGroupsWithoutErrors,
    );

  // Checks that all activeTrainGroupsWithoutErrors have complete: true
  const allActiveTrainGroupsHaveSensorData =
    useNotLoadingMemoSensorDataPointsSyncedToActive(
      loading!,
      organizationProps,
      trainProps,
      activeLoadedSensorDataTrainGroups,
      activeSensorDataEligibleTrainGroupsWithoutErrors,
      areSensorDataDateIntervalsAreUpToDate,
    );

  // If no loading is happening, clear old values from the cache to same memory
  // TODO this only works on the active crudTrainGroups
  useNotLoadingEffect(
    loading ||
      hasNonZeroLength(needingToLoadSensorDataTrainGroups) ||
      !allActiveTrainGroupsHaveSensorData,
    (
      activeLoadingSensorDataTrainGroups,
      activeLoadedSensorDataTrainGroups,
      activeTrainGroups,
      inScopeCrudTrainGroups,
    ) => {
      // If for some reason each list item's sensorDataDateIntervals aren't
      // equal, don't clear the cache. But we should never
      // get to this point before the system is stablized and all are equal
      if (
        !propOfListsEqual(
          'sensorDataDateIntervals',
          loadingSensorDataTrainGroups,
          loadedSensorDataTrainGroups,
        )
      ) {
        return;
      }

      // Clear other sensorDataDateIntervals and sensorDataPoints that
      // don't overlap DateInterval
      removeClearableSensorDataFromTrainGroups(
        organizationProps.organization.timezoneStr,
        activeLoadingSensorDataTrainGroups,
        activeLoadedSensorDataTrainGroups,
        activeTrainGroups,
        setLoadingSensorDataTrainGroups,
        setLoadedSensorDataTrainGroups,
        inScopeCrudTrainGroups,
      );
    },
    [
      activeLoadingSensorDataTrainGroups,
      activeLoadedSensorDataTrainGroups,
      activeTrainGroups,
      inScopeCrudTrainGroups,
    ] as const,
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainGroupSensorDependency.name),
    TrainGroupSensorDependency.name,
    {
      allActiveTrainGroupsHaveSensorData,
      activeLoadedSensorDataTrainGroups,
      areLoadedSensorDataTrainGroupsUpToDate,
    },
    [allActiveTrainGroupsHaveSensorData, areLoadedSensorDataTrainGroupsUpToDate],
    appProps.setWhatDependenciesAreLoading,
  );

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainGroupSingleTrainRunProps.sensorProps',
    useMemoClsOrType<Omit<TrainGroupSensorProps, 'loading'>>(
      CemitTypename.trainGroupSensorProps,
      {
        whatIsLoading,
        loadedSensorDataTrainGroups,
        allActiveTrainGroupsHaveSensorData,
      },
    ),
    // No new CemitFilter for this dependency
    undefined,
    whatIsLoading,
  );
  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainPropsMerged,
    'TrainGroupSensorDependency',
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};

TrainGroupSensorDependency.displayName = 'TrainGroupSensorDependency';
export default TrainGroupSensorDependency;
// export default memo(
//   TrainGroupSensorDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
