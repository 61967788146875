import {TrainFormation, TrainFormationOutput} from '../../types/trains/trainFormation';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {map} from 'ramda';
import {OrderedVehicle} from '../../types/trains/orderedVehicle';
import {createOrderedVehicle} from './orderedVehicleCrud.ts';
import {mergeExistingAndIncoming} from '../../appUtils/typeUtils/mergeTypeUtils.ts';
import {PartialCemited} from '../../types/cemited';
import {classifyObject} from '../../appUtils/typeUtils/classifyObject.ts';
import {typeObject} from '../../appUtils/typeUtils/typeObject.ts';

export const createTrainFormation = (
  trainFormation: TrainFormationOutput,
): TrainFormation => {
  return classifyObject<TrainFormation>(
    CemitTypename.trainFormation,
    mergeExistingAndIncoming(
      trainFormation,
      // Don't classify here to prevent undefined fields that override existing
      typeObject<PartialCemited<TrainFormation>>(CemitTypename.trainFormation, {
        operator: trainFormation.operator,
        orderedVehicles:
          trainFormation.orderedVehicles &&
          map((orderedVehicle: OrderedVehicle) => {
            return createOrderedVehicle(orderedVehicle);
          }, trainFormation.orderedVehicles),
      }),
    ),
  );
};
