import {
  MapboxIconConfig,
  MapboxIconsConfig,
  MapboxLayer,
  MapSourceVisual,
} from 'types/mapbox/mapSourceVisual';
import {
  append,
  filter,
  includes,
  is,
  isNil,
  join,
  length,
  lensPath,
  map,
  over,
  slice,
  unless,
  zipWith,
} from 'ramda';
import {
  dataThresholdsToMapboxAttributeLevels,
  dataThresholdsToMapBoxZoomAttributeLevels,
} from 'utils/map/mapboxExpressionUtils.ts';
import {DataThreshold} from 'types/dataVisualizations/dataThreshold';
import {svgDataUrlIconNameForIconOfTrainGroup} from 'appUtils/trainAppUtils/trainGroupMapUtils/trainGroupMapUtils.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {FeatureCollection} from 'geojson';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Expression} from 'mapbox-gl';
import {NamedMapboxExpression} from 'types/mapbox/namedMapboxExpression.ts';
import {
  compact,
  onlyOneValueOrNoneThrow,
  undefinedToTrue,
} from 'utils/functional/functionalUtils.ts';

export interface MemoizedTrainGroupIconImagesSourceAndLayersProps {
  trainGroup: TrainGroup;
  trainGroupIconConfigs: MapboxIconConfig[];
  sourceNamePrefix: string;
  layerNamePrefix: string;
  geojson: FeatureCollection;
  // Used to calculate iconSize and iconImage if specified
  featurePropPath?: string;
  // Used to calculate iconSize and iconImage if specified
  dataThresholds?: Perhaps<DataThreshold[]>;
  iconSize?: number;
  iconPrefix?: string;
  // Defaults to 20pixels. This is the size given to the width and height of the svg image holder
  // Without setting this, the iconSize: 1 will correspond to 20px
  iconConfigSize?: number;
  // Default false. By default, one layer is created for the TrainGroup.
  // If true, create one layer per threshold
  createOneLayerPerThreshold: boolean;
}

/**
 * Extrude columns on the map for the given geojson
 * TODO Rewrite and move colors, zooms, etc to appProps and organizationProps
 * @param  {Object} Returned with source and layers to so we know what TrainGroup they belong to
 * @param trainMap The Mapbox map
 * @param featurePropPath The path in each feature.properties to use for the visualization
 * e.g. 'acceleration.sumMaxMean' or 'sumMaxMean'
 * @param geojson FeatureCollection where features are points or anything else whose center
 * is used for the columns
 * @param dataThresholds Objects with a {style: {color}, value}} where color colors column and value
 * determines the threshold for changing color
 * @param [mapAsseSuffix] Default 'singleton' Unique suffix to add to the Mabbox source and layer
 * in case multiple datasets need to be shown, in which case each has a separate Mapbox source and layer
 * source: `3d-source-${mapAsseSuffix}`
 * layer: `3d-layer-${mapAsseSuffix}`
 * @param iconConfigSize The default width and height of the svg image holder. Defaults to 20. Use this
 * to scale the image without blurrinesss
 * @param createOneLayerPerThreshold Default false. If true, create a separate layer for each of dataThresholds.
 * This is done so that some icon types can appear above others across TrainGroups
 * @returns {Object} {source: The Mapbox source config, layers: array of currently one Mapbox layer, trainGroup}
 */
export const memoizedTrainGroupIconImagesSourceAndLayers = ({
  trainGroup,
  trainGroupIconConfigs,
  geojson,
  sourceNamePrefix,
  layerNamePrefix,
  featurePropPath,
  dataThresholds,
  iconSize = 1,
  iconPrefix,
  iconConfigSize = 20,
  createOneLayerPerThreshold = false,
}: MemoizedTrainGroupIconImagesSourceAndLayersProps): MapSourceVisual => {
  // Makes the source and layer unique to the TrainGroup
  const mapAssetSuffix = trainGroup.id;

  const mapboxSourceName = join('-', [sourceNamePrefix, mapAssetSuffix]);
  const mapboxLayerId = join('-', [layerNamePrefix, mapAssetSuffix]);

  const source = {
    name: mapboxSourceName,
    type: 'geojson',
    data: geojson,
  };

  // Determines the icon size based on the config of each dataThreshold.size array,
  // in a DataThreshold such as:
  /*
          {
              sourceKey: 'warning',
              label: t('warning'),
              value: RideComfortAttributeAlertSValue.warning,
              style: {
                  color: '#F2C94C',
                  icon: 'warning',
                  size: [
                      // For value RideComfortAttributeAlertSValue.warning,
                      // show these outputValues for the icon at each zoom level
                      {zoom: 0, outputValue: 0.2} as ZoomLevelValue,
                      {zoom: 8, outputValue: 0.5} as ZoomLevelValue,
                      {zoom: 16, outputValue: 1} as ZoomLevelValue,
                  ]
              }
          },
           */

  // Configure each icon name to match that of the svg dataUrl created for the specifi TrainGroup/Alert combo
  const namedMapboxExpressions: Perhaps<NamedMapboxExpression[]> | string[] = iconPrefix
    ? [svgDataUrlIconNameForIconOfTrainGroup(iconPrefix, trainGroup)]
    : (unless(isNil, (dataThresholds: Perhaps<DataThreshold[]>) => {
        // Meaning that feature.property[featurePropPath] >= RideComfortAttributeAlertSValue.warning
        // and feature.property[featurePropPath] < RideComfortAttributeAlertSValue.error
        // will produce the outputValue that matches the current zoom level

        const trainGroupDataThresholds = map((dataThreshold: DataThreshold) => {
          return over(
            lensPath(['style', 'icon']),
            (iconPrefix: string) => {
              return svgDataUrlIconNameForIconOfTrainGroup(iconPrefix, trainGroup);
            },
            dataThreshold,
          );
        }, dataThresholds!);
        // If createOneLayerPerThreshold, process each of trainGroupDataThresholds as a single item array.
        // If false, process all trainGroupDataThresholds as a single item
        return map(
          (trainGroupDataThresholds: DataThreshold[]) => {
            // Chooses the icon name based on feature.property[featurePropPath]
            const iconsInterpolations = dataThresholdsToMapboxAttributeLevels(
              trainGroupDataThresholds,
              'icon',
            );
            const trainGroupDataThresholdsLabel = join(
              '&',
              map(
                (trainGroupDataThreshold) => trainGroupDataThreshold.sourceKey,
                trainGroupDataThresholds,
              ),
            );
            const iconSizeExpression = {
              property: featurePropPath,
              stops: dataThresholdsToMapBoxZoomAttributeLevels(
                trainGroupDataThresholds,
                'size',
              ),
            };

            return clsOrType<NamedMapboxExpression>(CemitTypename.namedMapboxExpression, {
              name: trainGroupDataThresholdsLabel,
              dataThresholds: trainGroupDataThresholds,
              iconSizeExpression,
              // Use iconConfigSize if only one dataThreshold
              iconConfigSize:
                length(trainGroupDataThresholds) == 1
                  ? trainGroupDataThresholds[0].iconConfigSize
                  : undefined,
              expression: [
                'step',
                ['number', ['get', featurePropPath]],
                ...iconsInterpolations,
              ] as Expression,
            });
          },
          createOneLayerPerThreshold
            ? map((item: DataThreshold) => [item], trainGroupDataThresholds)
            : [trainGroupDataThresholds],
        );
      })(dataThresholds) as Perhaps<Expression>);

  // If createOneLayerPerThreshold is true, create a layer per dataThreshold
  // If false, create a single layer for all thresholds
  const layers = zipWith(
    (
      namedMapboxExpression: NamedMapboxExpression | string,
      nextNamedMapboxExpression: NamedMapboxExpression | string,
    ) => {
      const isNamedMapboxExpression = is(Object, namedMapboxExpression);
      const {
        name,
        iconSizeExpression,
        expression: iconImageExpression,
        iconConfigSize: overrideIconConfigSize,
        dataThresholds,
      } = (
        isNamedMapboxExpression
          ? namedMapboxExpression
          : {
              name: undefined,
              iconSizeExpression: undefined,
              expression: namedMapboxExpression,
              iconConfigSize: undefined,
              dataThresholds: undefined,
            }
      ) as NamedMapboxExpression;
      // The layer id must add the iconImageExpression name in case there are multiple layers being
      // created with the same mapboxLayerId but different iconImageExpressions
      const id = name
        ? `${mapboxLayerId}-${(namedMapboxExpression as NamedMapboxExpression).name}`
        : mapboxLayerId;
      // If iconImageExpression is an object, a NamedMapboxExpression, filter trainGroupIconConfigs
      // to take those matching the iconImageExpression. This is for the createOneLayerPerThreshold = true case
      // to make sure we don't list the same icons in multiple layers
      const matchingTrainGroupIconConfigs = name
        ? filter((trainGroupIconConfig: MapboxIconConfig) => {
            return includes(
              trainGroupIconConfig.name,
              (namedMapboxExpression as NamedMapboxExpression).expression,
            );
          }, trainGroupIconConfigs)
        : trainGroupIconConfigs;

      const dataThreshold = onlyOneValueOrNoneThrow(dataThresholds);
      const dataThresholdValue = dataThreshold?.value;

      const nextDataThresholdValue: DataThreshold = onlyOneValueOrNoneThrow(
        nextNamedMapboxExpression?.dataThresholds || [],
      )?.value;

      const layout = {
        // Resolves the correct icon if defined
        'icon-image': iconImageExpression,
        // The higher value gets z-index priority
        'symbol-sort-key': ['to-number', ['get', featurePropPath]],
        'icon-allow-overlap': true,
        'icon-size': iconSizeExpression || iconSize,
        // Hide the layer if it's dataThreshold.isVisilbe is false or the trainGroup!.activity.isVisible is false
        // TODO we currently never have invisible TrainGroups here
        visibility:
          undefinedToTrue(dataThreshold?.isVisible) && trainGroup!.activity.isVisible
            ? 'visible'
            : 'none',
      };

      return {
        id,
        type: 'symbol',
        source: mapboxSourceName,
        iconConfig: {
          // width, height can be overridden by the trainGroupIconConfig.iconConfigSize if specified
          // this allows an individual icon to be scaled without blurring. Using the iconSizeExpression
          // level != 1 causes blurring
          width: overrideIconConfigSize || iconConfigSize,
          height: overrideIconConfigSize || iconConfigSize,
          iconConfigs: matchingTrainGroupIconConfigs,
        } as MapboxIconsConfig,
        layout,
        ...(isNamedMapboxExpression
          ? {
              filter: compact([
                'all',
                ['>', ['get', featurePropPath], dataThresholdValue],
                nextDataThresholdValue && [
                  '<=',
                  ['get', featurePropPath],
                  nextDataThresholdValue,
                ],
              ]),
            }
          : {}),
      } as MapboxLayer;
    },
    namedMapboxExpressions,
    // The next namedMapboxExpression. The last value is undefined
    append(undefined, slice(1, Infinity, namedMapboxExpressions || [])),
  );
  return clsOrType<MapSourceVisual>(CemitTypename.mapSourceVisual, {
    source,
    layers,
    trainGroup,
  });
};
