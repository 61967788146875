import {PerhapsIfLoading} from '../../../../types/logic/requireIfLoaded.ts';
import {useCemitApiSwrResolveData} from '../../../cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {cond, equals, length, none, props, T} from 'ramda';
import {
  extractOnlyDateInterval,
  setCemitFilterToDateInterval,
} from '../cemitFilterHooks/cemitFilterDateIntervalHooks.ts';
import {useNotLoadingEffect} from '../../../../utils/hooks/useMemoHooks.ts';
import {isWithinInterval, min, parseISO, subMinutes} from 'date-fns';
import {DateFilterOptionTypes} from '../../../../types/organizations/dateFilterOptionTypes.ts';
import {CemitTypename} from '../../../../types/cemitTypename.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {createDateInterval} from '../../../../classes/typeCrud/dateIntervalCrud.ts';
import {clsOrType} from '../../../../appUtils/typeUtils/clsOrType.ts';
import {
  endOfDayForTimezone,
  startOfDayForTimezone,
} from '../../../../utils/datetime/timeUtils.ts';
import {UseApiForAvailableDatesProps} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {headOrThrow, lastOrThrow} from 'utils/functional/functionalUtils.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';

/**
 * TODO For now this just finds out the datetime ranges of schedule data for the organization
 * independent of TrainFormation and CDC
 * @param loading
 * @param organization
 * @param availableDateRanges
 * @param setAvailableDateIntervals
 * @param parentCemitFilter
 * @param cemitFilterWithDateIntervals
 * @param setCemitFilterWithDateIntervals
 * @param dateIntervalDescription
 */
export const useConfiguredApiForTrainFormationAvailableDates = (
  loading: boolean,
  {
    organization,
    availableDateRanges,
    setAvailableDateIntervals,
    parentCemitFilter,
    cemitFilterWithDateIntervals,
    setCemitFilterWithDateIntervals,
    dateIntervalDescription,
  }: PerhapsIfLoading<UseApiForAvailableDatesProps>,
) => {
  // Find out the dates where TrainRun scheduled data exists.
  const response = useCemitApiSwrResolveData(
    loading,
    organization,
    'availableDateRanges',
    {organization},
  );
  const responseLoading = response.isLoading || response.isValidating || !response.data;
  const dateIntervals: Perhaps<DateInterval[]> = response?.data as DateInterval[];
  // TODO Hack. Make the range from the start of AppSettings.earliestDate until the end of the last dateInterval for now.
  // RideComfort doesn't care about what dateIntervals are available
  const availableDateInterval: Perhaps<DateInterval> = length(dateIntervals || [])
    ? createDateInterval(
        CemitTypename.dateInterval,
        parseISO(AppSettings.earliestDate),
        lastOrThrow(dateIntervals).end,
      )
    : undefined;

  return resolveAvailableDateIntervals(loading || responseLoading, {
    organization,
    availableDateRanges,
    setAvailableDateIntervals,
    parentCemitFilter,
    cemitFilterWithDateIntervals,
    setCemitFilterWithDateIntervals,
    incomingAvailableDateIntervals: availableDateInterval
      ? [availableDateInterval]
      : undefined,
    dateIntervalDescription,
  });
};

/**
 * Uses availableDateRanges, incomingAvailableDateIntervals, and dateIntervalDescription
 * to resolve the desired or default DateInterval
 * @param loading
 * @param organization
 * @param availableDateRanges
 * @param setAvailableDateIntervals
 * @param parentCemitFilter
 * @param cemitFilterWithDateIntervals
 * @param setCemitFilterWithDateIntervals
 * @param incomingAvailableDateIntervals
 * @param dateIntervalDescription
 */
export const resolveAvailableDateIntervals = (
  loading: boolean,
  {
    organization,
    availableDateRanges,
    setAvailableDateIntervals,
    parentCemitFilter,
    cemitFilterWithDateIntervals,
    setCemitFilterWithDateIntervals,
    incomingAvailableDateIntervals,
    dateIntervalDescription,
  }: UseApiForAvailableDatesProps,
) => {
  const minutesBackToStart: number = dateIntervalDescription.duration;
  const computeDateInterval = (endDate: Date, startDate: Perhaps<Date> = undefined) => {
    return clsOrType<DateInterval>(CemitTypename.dateInterval, {
      start: startDate || subMinutes(endDate, minutesBackToStart),
      end: endDate,
    });
  };
  const minMaxDateInterval: Perhaps<DateInterval> =
    loading || !length(incomingAvailableDateIntervals)
      ? undefined
      : computeDateInterval(
          lastOrThrow(incomingAvailableDateIntervals).end,
          headOrThrow(incomingAvailableDateIntervals).start,
        );

  const cemitFilterDateInterval = extractOnlyDateInterval(cemitFilterWithDateIntervals);

  useNotLoadingEffect(
    loading || !incomingAvailableDateIntervals,
    (minMaxDateInterval, incomingAvailableDateIntervals, organization) => {
      const now = new Date();
      let updatedDateInterval = cemitFilterDateInterval;

      // If there is no result and no previous cemitFilterDateInterval, set updatedDateInterval to a DateInterval from start minutesBackToStart to now
      if (!minMaxDateInterval) {
        updatedDateInterval = cemitFilterDateInterval || computeDateInterval(now);

        // Call the setter setCemitFilterWithDateIntervals with a CemitFilter updated to a new CemitFilterDateInterval
        setCemitFilterToDateInterval(
          cemitFilterWithDateIntervals,
          setCemitFilterWithDateIntervals,
          updatedDateInterval,
        );

        if (!equals(availableDateRanges, [])) {
          setAvailableDateIntervals([]);
        }
      } else {
        const availableDateInterval: DateInterval = createDateInterval(
          CemitTypename.dateInterval,
          startOfDayForTimezone(
            organization.timezoneStr,
            min([minMaxDateInterval.start, now]),
          ),
          min([
            endOfDayForTimezone(organization.timezoneStr, minMaxDateInterval.end),
            now,
          ]),
        );

        if (!equals(availableDateRanges, incomingAvailableDateIntervals)) {
          setAvailableDateIntervals(incomingAvailableDateIntervals);
        }
        // Depending on the organization configuration, we either set the datetime range to the latest datetime with datetime
        // or set it to the entire available datetime range

        const defaultDateInterval: DateInterval | never = cond([
          [
            equals(DateFilterOptionTypes.allAvailable),
            () => {
              return availableDateInterval;
            },
          ],
          [
            equals(DateFilterOptionTypes.latest),
            () => {
              const endDate = min([
                endOfDayForTimezone(organization.timezoneStr, availableDateInterval.end),
                now,
              ]);
              return computeDateInterval(endDate);
            },
          ],
          [
            T,
            (): never => {
              throw new Error(
                'organization.frontendOptions.dateFilterOptions.defaultDateRange must be one of DateFilterOptionTypes',
              );
            },
          ],
        ])(organization?.frontendOptions?.dateFilterOptions?.defaultDateRange);

        // Only update the dateInterval if none of the previous dateInterval is in the new availableDateInterval
        // Otherwise leave it alone
        if (
          !cemitFilterDateInterval ||
          none(
            (date: Date) => {
              return isWithinInterval(date, availableDateInterval);
            },
            props(['start', 'end'], cemitFilterDateInterval) as [Date, Date],
          )
        ) {
          // Default the datetime interval to the most recent datetime. The user can change this datetime later
          // Call the setter setCemitFilterWithDateIntervals with a CemitFilter updated to a new CemitFilterDateInterval
          setCemitFilterToDateInterval(
            cemitFilterWithDateIntervals,
            setCemitFilterWithDateIntervals,
            defaultDateInterval,
          );
        }
      }
    },
    [minMaxDateInterval, incomingAvailableDateIntervals, organization] as const,
  );
};
