import React, {FC, ReactNode, useContext, useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {cemitLogLocation} from 'monitoring/cemitLogging.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {
  ApplicationAccessControl,
  applicationRegistry,
  ApplicationType,
} from 'pages/auth/utils.ts';
import {hasAccess} from 'appUtils/authentication/authenticationUtils.ts';

/**
 * A component that verifies user authorization for a specific feature or product.
 * It can be used two ways, either as a layout route which will allow for nested
 * routes, or by specifying child trafficSimComponents.
 *
 * Note: Ensure that user is authenticated before rendering this component, otherwise an exception is thrown
 *
 * @param appType Which application this route represents, e.g. "train" or "track"
 * @param redirectPath Path to redirect to if authorization fails
 * @param fallback React component to render if authorization fails, overrides redirectPath
 * @param children Child trafficSimComponents to render when the user has access
 * @throws Error If the user is not authenticated or the application key does not exist
 */
const RequireAccess: FC<{
  app: ApplicationType;
  redirectPath?: string;
  fallback?: ReactNode;
  children?: ReactNode;
  context?: any;
}> = ({
  app,
  redirectPath = '/home',
  fallback = undefined,
  children = undefined,
  context = undefined,
}) => {
  if (!applicationRegistry[app]) {
    throw new Error(`Unknown application type ${app}`);
  }

  const appAccess: ApplicationAccessControl = applicationRegistry[app];

  const {userState} = useContext(UserContext) as UserContextType;
  const location = useLocation();

  useEffect(() => {
    cemitLogLocation(location);
  }, [location.pathname]);

  if (userState.status !== AccessStatus.Authenticated) {
    throw new Error(
      'RequireAccess must not be used with unauthenticated users, ensure AppAccess is part of parent trafficSimComponents',
    );
  }

  const isAuthorized =
    appAccess.accessKey === undefined || hasAccess(appAccess.accessKey, userState);
  // console.log(
  //   `Check access to ${location.pathname}, app=${app}, result=${isAuthorized}`,
  // );

  if (!isAuthorized) {
    if (fallback) return fallback;
    return <Navigate to={redirectPath} replace></Navigate>;
  }

  // User is authenticated and authorized, render the children
  return children ? children : <Outlet {...(context ? {context} : {})} />;
};

export default RequireAccess;
