import {Box, Slide} from '@mui/material';
import React from 'react';
import TrainFilterAndMaybeEquipmentListBoards from './TrainFilterAndMaybeEquipmentListBoards.tsx';

import {ViewSlider} from '../../../types/propTypes/viewSlider.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';

type TrainPageBoardInMapSlideProps = {
  minWidth: string;
};

const TrainPageBoardInMapSlide = ({
  appProps,
  mapProps,
  componentProps: {minWidth},
  children,
}: TrainAppMapDependencyProps & {
  componentProps: TrainPageBoardInMapSlideProps;
  children: typeof TrainFilterAndMaybeEquipmentListBoards;
}) => {
  return (
    <Slide
      mountOnEnter={true}
      unmountOnExit={true}
      direction="right"
      in={appProps.viewSlider != ViewSlider.collapsed}
      onChange={() =>
        appProps.setViewSlider((value) =>
          value != ViewSlider.normal ? ViewSlider.normal : ViewSlider.expanded,
        )
      }
      addEndListener={() => {
        // Set a timeout because the Slide child is not actually unmounted quite yet :/
        // If this is problematic then put a ref on the child and check when its unmounted
        mapProps.trainMap &&
          setTimeout(() => {
            mapProps.trainMap.resize();
          }, 500);
      }}
      sx={{
        minWidth,
        height: '100%',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          maxHeight: '100vh',
          height: '100vh',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        {children}
      </Box>
    </Slide>
  );
};
export default TrainPageBoardInMapSlide;
