import {Box, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {Clear} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * Removes the TrainGroup form the crudTrainGroups
 * @param isSelectedTrainRuns
 * @param trainGroup
 * @param crudTrainGroups
 * @param disabled Default false
 * @param handleRemoveTrainGroupFromFilters
 * @param label Optional label
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupRemoveButton = <T extends TrainGroup>({
  isSelectedTrainRuns,
  trainGroup,
  disabled = false,
  handleRemoveTrainGroupFromFilters,
  label,
}: {
  isSelectedTrainRuns: boolean;
  trainGroup: T;
  disabled: boolean;
  handleRemoveTrainGroupFromFilters: (trainGroup: T) => void;
  label?: string;
}): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Tooltip key="removeButtonTooltip" {...{arrow: true, title: t('remove')}}>
      <Stack {...{spacing: 1, direction: 'row'}}>
        <IconButton
          key="removeButton"
          disabled={disabled}
          color="remove"
          aria-label="remove"
          sx={{
            float: 'right',
            alignItems: 'flex-start',
            p: 0,
          }}
          onClick={() => {
            if (!isSelectedTrainRuns) {
              throw new Error('Not implemented for non-selected runs');
            }
            handleRemoveTrainGroupFromFilters(trainGroup);
          }}
        >
          <Clear />
          {label ? <Typography>{label}</Typography> : undefined}
        </IconButton>
      </Stack>
    </Tooltip>
  );
};
export default TrainGroupRemoveButton;
