import {useTranslation} from 'react-i18next';
import {trainRouteOrGroupName} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import TrainRunDescription from 'components/apps/trainAppComponents/chooserComponents/trainRunChooserComponents/TrainRunDescription.tsx';
import {Button} from '@mui/material';
import {Size} from 'types/layout/size';
import React from 'react';
import {ifElse} from 'ramda';
import {CemitTypename} from 'types/cemitTypename.ts';
import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {scopedFilteredTrainGroup} from 'appUtils/trainAppUtils/scope/trainPropsScope.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {SingleTrainGroupTrainDependencyProps} from 'types/propTypes/trainPropTypes/trainTrainAppDependencyProps.d.ts';
import {trainDataFriendlyDatetimeFormatString} from 'utils/datetime/timeUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';

/***
 * Scoped to, trainProps.trainGroupSingleTrainRunProps.trainGroup.singleTrainRun
 * Renders a description of the TrainRun or a loading component if dependencies are loading
 * @param trainProps
 * @param componentProps
 * @param buttons
 * @param showTrainRunLine
 * @param trainDetailSize
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunDescriptionContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {buttons, showTrainRunLine, trainDetailSize},
}: SingleTrainGroupTrainDependencyProps & {
  componentProps: {
    buttons: (typeof Button)[];
    showTrainRunLine: boolean;
    trainDetailSize: Size;
  };
}): JSX.Element => {
  const {t} = useTranslation();
  const filteredTrainGroup: TrainGroup = scopedFilteredTrainGroup(trainProps);
  // The destination TimetabledPassingTime of the TrainRun
  const destinationScheduledStopPoint = filteredTrainGroup.endScheduledStopPoint;

  const {
    trainRouteLabel,
  }: {
    trainRouteLabel: undefined | string;
  } = ifElse(
    Boolean,
    () => {
      const trainRun = trainProps.trainGroupSingleTrainRunProps.trainGroup.singleTrainRun;

      const trainRouteLabel: Perhaps<string> =
        showTrainRunLine &&
        implementsCemitTypeViaClass(
          CemitTypename.trainRouteGroupDerived,
          trainProps.trainRouteGroupProps.trainRouteOrGroup,
        )
          ? trainRouteOrGroupName({t}, filteredTrainGroup.trainRoute)
          : destinationScheduledStopPoint.name;
      return {trainRouteLabel};
    },
    () => ({}),
  )(doesOrganizationHaveServiceLines(organizationProps));

  return (
    <TrainRunDescription
      {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: {
          trainGroup: filteredTrainGroup,
          destinationScheduledStopPoint,
          buttons,
          trainRouteLabel,
          dateAndOrTimeFormatString: trainDataFriendlyDatetimeFormatString,
          isTrainGroupDetailLine: Boolean(
            trainProps.trainGroupSingleTrainRunProps.trainGroup,
          ),
          isBaseline: trainProps.trainGroupSingleTrainRunProps.trainGroup
            ? Boolean(
                trainProps.trainGroupSingleTrainRunProps.trainGroup.activity.isBaseline,
              )
            : false,
          trainDetailSize,
        },
      }}
    />
  );
};

export default TrainRunDescriptionContainer;
