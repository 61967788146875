import {Cemited} from 'types/cemited';
import {CemitTypename} from 'types/cemitTypename.ts';
import {ifElse, is} from 'ramda';
import {CemitedClass} from 'classes/cemitAppCemitedClasses/cemitedClass.ts';
import {toBoolean} from 'utils/functional/functionalUtils.ts';
import {ClassFromObj} from 'types/classes';
import {cemitTypeObjectAsClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {typeObject} from './typeObject.ts';

/**
 * Instantiate t as a class instance if a class matching typename exists.
 * Otherwise return t as an object with __typename = typename added.
 * If instantateDeep is specified, nested objects are converted to classes
 * @param typename
 * @param t
 */
export const clsOrType = <T extends Cemited>(
  typename: CemitTypename,
  t: T | Omit<WritablePart<T>, '__typename'>,
): T => {
  if (!t) {
    throw new Error('t is undefined.');
  }
  if (!typename) {
    throw new Error(
      'typename is undefined and probably needs to be defined in cemitTypename.ts',
    );
  }
  if (is(CemitedClass as new (...args: any[]) => any, t)) {
    // Already a CemitedClass
    return t as T;
  }
  const typedObject: T = typeObject(typename, t);
  // We cast to T because ClassFromObj<T> is not considered by the compiler to extend T, event though it does
  return ifElse(
    toBoolean,
    (classFromObj: ClassFromObj<T>) => {
      return new classFromObj(typedObject);
    },
    () => {
      // No class exists, return the object with a __typename set
      return typedObject;
    },
  )(cemitTypeObjectAsClass<T>(typedObject, true));
};

// Just types an object to please Typescript when we don't want to force the interface to implemented Cemited
export const ts = <T>(t: T): T => {
  if (!t) {
    throw new Error('t is undefined.');
  }
  return t as T;
};
