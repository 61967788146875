import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {RailwayLine, RailwayLineMinimized} from '../../types/railways/railwayLine';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {FeatureCollection} from 'geojson';
import {Track} from '../../types/railways/track';
import {ScheduledStopPoint} from '../../types/stops/scheduledStopPoint';
import {Timetable} from '../../types/timetables/timetable';
import {LineCategory} from '../../types/railways/lineCategory.ts';
import {IdentifiedClass} from '../cemitAppCemitedClasses/identifiedClass.ts';
import {TrackRoute} from 'types/railways/trackRoute';

export class RailwayLineMinimizedClass
  extends IdentifiedClass
  implements RailwayLineMinimized
{
  constructor(obj: RailwayLineMinimized) {
    // Omit getter only property.
    // setters from the constructor
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.railwayLineMinimized;
}

export class RailwayLineClass extends RailwayLineMinimizedClass implements RailwayLine {
  constructor(obj: RailwayLine) {
    // Omit getter only property.
    // setters from the constructor
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.railwayLineMinimized;

  lineCategory?: LineCategory;
  timetable?: Timetable;
  trackRoutes?: TrackRoute[];
  geojson: FeatureCollection;
  country?: string;
  referenceScheduledStopPoint?: ScheduledStopPoint;
}
