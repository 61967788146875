/**
 * Uses the Apollo-style __typename property to identify instanc types
 */
import {curry, eqProps, propOr} from 'ramda';
import {Cemited} from '../../types/cemited';

export const typenameEquals = curry((typename: string, instance: Cemited): boolean => {
  // propEq didn't work here. I don't know why
  const eq = propOr(undefined, '__typename', instance) === typename;
  return eq;
});

/**
 * Returns true if both instances have the same __typenmae
 * @param instance1
 * @param instance2
 */
export const typenamesEqual = (instance1: Cemited, instance2: Cemited): boolean => {
  return eqProps('__typename', instance1, instance2)
};


