export const colorHue = {
  /* Call to Action, Important Information */
  gold: {
    50: 'rgb(255,251,237)' /*---hex: #FFFBED*/,
    100: 'rgb(255,239,185)' /*---hex: #FFEFB9*/,
    200: 'rgb(255,232,151)' /*---hex: #FFE897*/,
    300: 'rgb(255,224,116)' /*---hex: #FFE074*/,
    400: 'rgb(255,217,82)' /*---hex: #FFD952*/,
    500: 'rgb(229,195,73)' /*---hex: #E5C349*/,
    600: 'rgb(204,173,65)' /*---hex: #CCAD41*/,
    700: 'rgb(178,151,57)' /*---hex: #B29739*/,
    800: 'rgb(127,108,41)' /*---hex: #7F6C29*/,
    900: 'rgb(102,86,32)' /*---hex: #665620*/,
  },
  blue: {
    50: 'rgb(236,242,255)' /*---hex: #ECF2FF*/,
    100: 'rgb(179,206,255)' /*---hex: #B3CEFF*/,
    200: 'rgb(142,181,255)' /*---hex: #8EB5FF*/,
    300: 'rgb(104,157,255)' /*---hex: #689DFF*/,
    400: 'rgb(67,133,255)' /*---hex: #4385FF*/,
    500: 'rgb(60,119,229)' /*---hex: #3C77E5*/,
    600: 'rgb(53,106,204)' /*---hex: #356ACC*/,
    700: 'rgb(46,93,178)' /*---hex: #2E5DB2*/,
    800: 'rgb(33,66,127)' /*---hex: #21427F*/,
    900: 'rgb(26,53,102)' /*---hex: #1A3566*/,
  },
  /* Functional colors */
  red: {
    50: 'rgb(254,238,239)' /*---hex: #FEEEEF*/,
    100: 'rgb(253,204,207)' /*---hex: #FDCCCF*/,
    200: 'rgb(252,171,175)' /*---hex: #FCABAF*/,
    300: 'rgb(251,120,127)' /*---hex: #FB787F*/,
    400: 'rgb(250,87,96)' /*---hex: #FA5760*/,
    500: 'rgb(225,78,86)' /*---hex: #E14E56*/,
    600: 'rgb(200,69,76)' /*---hex: #C8454C*/,
    700: 'rgb(175,60,67)' /*---hex: #AF3C43*/,
    800: 'rgb(125,43,48)' /*---hex: #7D2B30*/,
    900: 'rgb(75,26,28)' /*---hex: #4B1A1C*/,
  },
  orange: {
    50: 'rgb(255,242,233)' /*---hex: #FFF2E9*/,
    100: 'rgb(255,216,189)' /*---hex: #FFD8BD*/,
    200: 'rgb(255,191,145)' /*---hex: #FFBF91*/,
    300: 'rgb(255,152,79)' /*---hex: #FF984F*/,
    400: 'rgb(255,127,36)' /*---hex: #FF7F24*/,
    500: 'rgb(229,114,32)' /*---hex: #E57220*/,
    600: 'rgb(204,101,28)' /*---hex: #CC651C*/,
    700: 'rgb(178,88,25)' /*---hex: #B25819*/,
    800: 'rgb(127,63,18)' /*---hex: #7F3F12*/,
    900: 'rgb(76,38,10)' /*---hex: #4C260A*/,
  },

  green: {
    50: 'rgb(236,249,240)' /*---hex: #ECF9F0*/,
    100: 'rgb(180,231,195)' /*---hex: #B4E7C3*/,
    200: 'rgb(143,219,165)' /*---hex: #8FDBA5*/,
    300: 'rgb(106,207,135)' /*---hex: #6ACF87*/,
    400: 'rgb(69,196,105)' /*---hex: #45C469*/,
    500: 'rgb(62,176,94)' /*---hex: #3EB05E*/,
    600: 'rgb(55,156,84)' /*---hex: #379C54*/,
    700: 'rgb(48,137,73)' /*---hex: #308949*/,
    800: 'rgb(34,98,52)' /*---hex: #226234*/,
    900: 'rgb(20,58,31)' /*---hex: #143A1F*/,
  },
  /* Data Visualization Colors */
  teal: {
    50: 'rgb(229,255,246)' /*---hex: #E5FFF6*/,
    100: 'rgb(178,255,229)' /*---hex: #B2FFE5*/,
    200: 'rgb(102,255,204)' /*---hex: #66FFCC*/,
    300: 'rgb(51,255,187)' /*---hex: #33FFBB*/,
    400: 'rgb(0,255,170)' /*---hex: #00FFAA*/,
    500: 'rgb(0,229,153)' /*---hex: #00E599*/,
    600: 'rgb(0,204,136)' /*---hex: #00CC88*/,
    700: 'rgb(0,178,118)' /*---hex: #00B276*/,
    800: 'rgb(0,127,85)' /*---hex: #007F55*/,
    900: 'rgb(0,76,51)' /*---hex: #004C33*/,
  },
  limeGreen: {
    50: 'rgb(247,255,238)' /*---hex: #F7FFEE*/,
    100: 'rgb(231,255,204)' /*---hex: #E7FFCC*/,
    200: 'rgb(208,255,153)' /*---hex: #D0FF99*/,
    300: 'rgb(193,255,119)' /*---hex: #C1FF77*/,
    400: 'rgb(178,255,86)' /*---hex: #B2FF56*/,
    500: 'rgb(160,229,77)' /*---hex: #A0E54D*/,
    600: 'rgb(142,204,68)' /*---hex: #8ECC44*/,
    700: 'rgb(124,178,60)' /*---hex: #7CB23C*/,
    800: 'rgb(89,127,43)' /*---hex: #597F2B*/,
    900: 'rgb(53,76,25)' /*---hex: #354C19*/,
  },
  ultramarine: {
    50: 'rgb(241,242,255)' /*---hex: #F1F2FF*/,
    100: 'rgb(214,217,255)' /*---hex: #D6D9FF*/,
    200: 'rgb(187,193,255)' /*---hex: #BBC1FF*/,
    300: 'rgb(147,155,255)' /*---hex: #939BFF*/,
    400: 'rgb(120,131,255)' /*---hex: #7883FF*/,
    500: 'rgb(108,117,229)' /*---hex: #6C75E5*/,
    600: 'rgb(96,104,204)' /*---hex: #6068CC*/,
    700: 'rgb(84,91,178)' /*---hex: #545BB2*/,
    800: 'rgb(60,65,127)' /*---hex: #3C417F*/,
    900: 'rgb(36,39,76)' /*---hex: #24274C*/,
  },
  purple: {
    50: 'rgb(247,241,254)' /*---hex: #F7F1FE*/,
    100: 'rgb(231,213,252)' /*---hex: #E7D5FC*/,
    200: 'rgb(208,171,250)' /*---hex: #D0ABFA*/,
    300: 'rgb(192,243,248)' /*---hex: #C08FF8*/,
    400: 'rgb(177,116,247)' /*---hex: #B174F7*/,
    500: 'rgb(159,104,222)' /*---hex: #9F68DE*/,
    600: 'rgb(141,92,197)' /*---hex: #8D5CC5*/,
    700: 'rgb(123,81,172)' /*---hex: #7B51AC*/,
    800: 'rgb(88,58,123)' /*---hex: #583A7B*/,
    900: 'rgb(53,34,74)' /*---hex: #35224A*/,
  },
  pink: {
    50: 'rgb(254,237,248)' /*---hex: #FEEDF8*/,
    100: 'rgb(252,201,235)' /*---hex: #FCC9EB*/,
    200: 'rgb(249,148,215)' /*---hex: #F994D7*/,
    300: 'rgb(247,113,202)' /*---hex: #F771CA*/,
    400: 'rgb(245,78,189)' /*---hex: #F54EBD*/,
    500: 'rgb(220,70,170)' /*---hex: #DC46AA*/,
    600: 'rgb(196,62,151)' /*---hex: #C43E97*/,
    700: 'rgb(171,54,132)' /*---hex: #AB3684*/,
    800: 'rgb(122,39,94)' /*---hex: #7A275E*/,
    900: 'rgb(73,23,56)' /*---hex: #491738*/,
  },
  tangerine: {
    50: 'rgb(255,244,241)' /*---hex: #FFF4F1*/,
    100: 'rgb(255,223,213)' /*---hex: #FFDFD5*/,
    200: 'rgb(255,191,171)' /*---hex: #FFBFAB*/,
    300: 'rgb(255,170,143)' /*---hex: #FFAA8F*/,
    400: 'rgb(255,149,115)' /*---hex: #FF9573*/,
    500: 'rgb(229,134,103)' /*---hex: #E58667*/,
    600: 'rgb(204,119,92)' /*---hex: #CC775C*/,
    700: 'rgb(178,104,80)' /*---hex: #B26850*/,
    800: 'rgb(127,74,57)' /*---hex: #7F4A39*/,
    900: 'rgb(76,44,34)' /*---hex: #4C2C22*/,
  },
  /* Background Colors Text Colors */
  grey: {
    50: 'rgb(255,255,255)' /*---hex: #FFFFFF*/,
    100: 'rgb(226,229,231)' /*---hex: #E2E5E7*/,
    200: 'rgb(196,200,203)' /*---hex: #C4C8CB*/,
    300: 'rgb(137,145,150)' /*---hex: #899196*/,
    400: 'rgb(97,107,115)' /*---hex: #616B73*/,
    500: 'rgb(58,71,80)' /*---hex: #3A4750*/,
    600: 'rgb(52,64,72)' /*---hex: #344048*/,
    700: 'rgb(46,57,64)' /*---hex: #2E3940*/,
    800: 'rgb(35,43,48)' /*---hex: #232B30*/,
    900: 'rgb(23,28,32)' /*---hex: #171C20*/,
  },
};
