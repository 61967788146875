import {Box, Stack} from '@mui/material';
import React from 'react';
import {addIndex, map} from 'ramda';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import CemitFilterTrainGroupOnlyTrainFormationContainer, {
  CemitFilterTrainGroupOnlyTrainFormationContainerProps,
} from './CemitFilterTrainGroupOnlyTrainFormationContainer.tsx';
import {CemitFilterTrainFormationsViewProps} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsViewProps.ts';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';

/**
 * List of TrainGroupOnlyTrainFormation instances
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainFormationsViewList = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<CemitFilterTrainFormationsViewProps>): JSX.Element => {
  return (
    <Box
      key="trainsComponent"
      {...{
        spacing: 1,
        sx: {
          minWidth: 0,
          flex: 1,
          position: 'relative',
          minHeight: 'auto',
          maxWidth: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingLeft: '8px',
        },
      }}
    >
      {addIndex(map)(
        (trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation, index: number) => {
          const childComponentProps =
            ts<CemitFilterTrainGroupOnlyTrainFormationContainerProps>({
              ...componentProps,
              trainGroupOnlyTrainFormation,
            });
          return (
            <CemitFilterTrainGroupOnlyTrainFormationContainer
              key={index.toString()}
              {...{
                appProps,
                trainProps: trainProps,
                componentProps: childComponentProps,
              }}
            />
          );
        },
        trainProps.trainGroupOnlyTrainFormationProps?.trainGroupOnlyTrainFormations || [],
      )}
    </Box>
  );
};
export default CemitFilterTrainFormationsViewList;
