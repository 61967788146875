import {useTranslation} from 'react-i18next';
import {useStateWhatDependenciesAreLoading} from 'async/whatDependenciesAreLoading/whatDependenciesAreLoadingHooks.ts';
import {ReactNode, useMemo, useState} from 'react';
import {CemitAppProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppProps';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from 'async/trainAppAsync/trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from 'async/trainAppAsync/trainAppDependencies/trainDependencyUnitConfig.ts';
import {is, when} from 'ramda';
import CemitAppDependency from 'async/cemitAppAsync/CemitAppDependency.tsx';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {useWindowDimensions} from 'utils/hooks/domHooks.ts';

interface CemitAppDependencyProps {
  appProps: CemitAppProps;
}

export interface ChildrenDependencyProps {
  // Can optional accept the appProps generated by CemitAppDependency
  renderChildren?: (props: CemitAppDependencyProps) => ReactNode;
  children?: ReactNode;
}

/**
 * Provides AppProps to children if children is type  ((props: CemitAppDependencyProps) => ReactNode);
 * Otherwise just renders children
 * @param renderChildren
 * @param children
 * @constructor
 */
export const CemitAppPropsDependency = ({
  renderChildren,
  children,
}: ChildrenDependencyProps) => {
  const {t} = useTranslation();
  // Lets developers view what is loading in Dependency components
  // by typing Window.whatIsLoading in the console
  const [_whatDependenciesAreLoading, setWhatDependenciesAreLoading] =
    useStateWhatDependenciesAreLoading();

  // Not currently used by CemitAppProps, but conforms to the AppProps interface
  const [activeViews, setActiveViews] = useState(undefined);

  const windowSize = useWindowDimensions();
  const displayProps = useMemo(() => {
    return {
      windowSize,
    };
  }, [windowSize]);

  // The template CemitFilter, extended by other Dependencies
  const cemitFilterApp: CemitFilter = useMemoClsOrType<CemitFilter>(
    CemitTypename.cemitFilter,
    {
      // Top-level, no parent
      parent: undefined,
      // These are used for embedded CemitFilters that are for a particular model
      modelTypename: undefined,
      allPass: [],
    },
    [],
  );

  const appProps: CemitAppProps = useMemo(() => {
    return clsOrType<CemitAppProps>(CemitTypename.trainAppProps, {
      displayProps,
      loading: false,
      distanceUnit: 'km',
      t,
      setWhatDependenciesAreLoading,
      activeViews,
      setActiveViews,
      cemitFilterApp,
    });
  }, [t]);

  const whatChangedObject = appProps;
  const whatIsLoading = useWhatIsLoading(
    false,
    isLoadingStringOfDependencyUnit(CemitAppDependency.name),
    CemitAppDependency.name,
    {},
    [],
    appProps.setWhatDependenciesAreLoading,
  );
  useWhatChangedLoadingExplanation(
    whatIsLoading,
    whatChangedObject,
    'CemitAppDependency',
  );

  // Return the simple children or if a function as a render function receiving appProps
  return when(
    is(Function),
    (renderChildren: ({appProps}: CemitAppDependencyProps) => ReactNode) => {
      return renderChildren({appProps});
    },
  )(renderChildren || children);
};
export default CemitAppPropsDependency;
