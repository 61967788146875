import React, {useRef, useState} from 'react';
import {Stack} from '@mui/material';
import {addIndex, any, map, omit, pick, prop, values, zip, zipWith} from 'ramda';
import {useResizeObserver} from 'usehooks-ts';
import {Size} from 'types/layout/size';
import {useDebouncedCallback} from 'use-debounce';
import {MakeOptional} from '@mui/x-charts/models/helpers';
import {pieArcLabelClasses, PieChart, PieSeriesType, PieValueType} from '@mui/x-charts';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {
  CEMIT_ALERT_CRITICAL,
  CEMIT_ALERT_NO_DATA,
  CEMIT_ALERT_NORMAL,
  CEMIT_ALERT_WARNING,
} from 'theme/cemitColors.ts';
import {unlessLoadingValue} from 'utils/componentLogic/loadingUtils.ts';
import {AlertBaseGauge, AlertBaseGauge} from 'types/alerts/alertGauge';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {AttributeAlertLevelEnum} from 'types/alerts/attributeAlertLevelEnum.ts';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig.d.ts';

/**
 * Shows a donut chart of the ride comfort warning levels
 * @param loading
 * @param alertTypeConfig
 * @param alertGauge
 * @param otherAlertGauges
 */
const AlertGaugeComponent = ({
  loading,
  alertTypeConfig,
  alertGauge,
  otherAlertGauges,
}: {
  loading: boolean;
  alertTypeConfig: AlertTypeConfig;
  alertGauge: AlertBaseGauge;
  otherAlertGauges: AlertBaseGauge[];
}) => {
  const {t}: {t: TFunction} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  const onResize = useDebouncedCallback(setSize, 200);
  useResizeObserver({
    ref,
    onResize,
  });
  const smallestSize = Math.min(size.width, size.height);
  const radius = Math.max(0, smallestSize / 2 - 5);

  const mainSeries = seriesFromAlertGauge(
    loading,
    t,
    radius,
    radius / 1.5,
    radius - 5,
    alertTypeConfig,
    alertGauge,
  );
  const otherSeries = addIndex(map)((otherAlertGauge: AlertBaseGauge, index: number) => {
    const innerRadius = radius / (index + 2.5);
    const outerRadius = innerRadius + 5;
    return seriesFromAlertGauge(
      loading,
      t,
      radius,
      innerRadius,
      outerRadius,
      alertTypeConfig,
      otherAlertGauge,
    );
  }, otherAlertGauges || []);
  const series = [mainSeries, ...otherSeries];

  return (
    <Stack
      {...{
        ref,
        direction: 'row',
        sx: {
          justifyContent: 'center',
          width: '100%',
          height: 'fit-content',
          flex: 2,
          p: 0.25,
        },
      }}
    >
      {size.width ? (
        <PieChart
          {...{
            series,
            slotProps: {
              legend: {
                hidden: true,
              },
            },
            sx: {
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontWeight: 'bold',
              },
            },
            width: smallestSize,
            height: smallestSize,
          }}
        />
      ) : undefined}
    </Stack>
  );
};
AlertGaugeComponent.displayName = 'AlertGaugeComponent';
export default AlertGaugeComponent;

/**
 * Creates an MUI PieChart for the given alertGauge
 * @param loading
 * @param t
 * @param radius
 * @param innerRadius The innerRadius of the series in the PieChart
 * @param outerRadius The outerRadius of the series in the PieChart
 * @param alertTypeConfig
 * @param alertGauge
 */
const seriesFromAlertGauge = <T extends AlertBaseGauge>(
  loading: boolean,
  t: TFunction,
  radius: number,
  innerRadius: number,
  outerRadius: number,
  alertTypeConfig: AlertTypeConfig<T>,
  alertGauge: AlertBaseGauge,
): MakeOptional<PieSeriesType<MakeOptional<PieValueType, 'id'>>, 'type'> => {
  // Take the three AlertGaugeValue.value of AlertGauge: normal, warning, and error
  const updatedData = unlessLoadingValue(loading, () => {
    // The attributes to show in the gauge
    const attributeAlertLevelEnums = omit(
      [AttributeAlertLevelEnum.none as string],
      alertTypeConfig.attributeAlertLevelEnum,
    );
    // The colors for each
    const attributeAlertLevelColors = map(
      (alertLevelConfig: AttributeAlertLevelConfig<T>) => {
        return alertLevelConfig.color;
      },
      alertTypeConfig.attributeAlertLevelConfigs,
    );

    const attributes = values(attributeAlertLevelEnums);
    const alertGaugeLimited = pick(attributes, alertGauge);
    const _data = map(prop('value'), values(alertGaugeLimited));

    const anyPositive = any((datum: number) => datum > 0, _data);
    const colors = anyPositive ? attributeAlertLevelColors : [CEMIT_ALERT_NO_DATA];
    const dataValues = anyPositive ? _data : [1];
    // Labels are in the form ALERT_LEVEL (TIME PERIOD)
    const labels = map(
      (label: string) => {
        return `${t(label)} (${t(alertGauge.periodEnum)})`;
      },
      anyPositive ? attributes : ['noData'],
    );

    const colorsAndLabels = zip(colors, labels!);
    const data = zipWith(
      (value, [color, label]) => {
        return {value, label, color};
      },
      dataValues,
      colorsAndLabels,
    );
    return data;
  });
  return {
    innerRadius,
    outerRadius,
    cx: radius,
    // Don't set cy since the height can be greater than the radius, let it center itself
    highlightScope: {faded: 'global', highlighted: 'item', innerRadius: 30},
    highlighted: {innerRadius: 0},
    faded: {color: 'gray'},
    data: updatedData || [{value: 0, label: 'Loading', color: '#D0D0D060'}],
  };
};
