import { Vehicle } from '../../types/trains/vehicle';
import { CemitTypename } from '../../types/cemitTypename.ts';
import {clsOrType} from "../../appUtils/typeUtils/clsOrType.ts";

export const createVehicle = (vehcile: Vehicle) => {
  return clsOrType<Vehicle>(CemitTypename.vehicle,
    // TODO crud bogies, etc., here.
    vehcile
  );
};
