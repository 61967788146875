import React, {SyntheticEvent, useState} from 'react';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {getCemitAuthApiUrl} from 'apis/cemitApis/apiConfig.ts';
import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {cemitColors} from 'theme/cemitColors.ts';
import {useTranslation} from 'react-i18next';

const AuthFormComponent = () => {
  const {t} = useTranslation();
  const [authFormState, setAuthFormState] = useState({
    email: '',
    password: '',
    feedback: '',
    status: -1,
    spinner: false,
  });

  const onChangeListener = (e: SyntheticEvent) =>
    setAuthFormState({
      ...authFormState,

      [`${e.target.id}`]: e.target.value,
    });

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const authApiBaseUrl = getCemitAuthApiUrl();
    const useDynamicUrl = process.env.REACT_DYNAMIC_APP_URL === 'true';
    setAuthFormState((prevState) => ({...prevState, spinner: true}));
    const response = await fetch(`${authApiBaseUrl}/makejwt`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: authFormState.email,
        url: useDynamicUrl ? AppSettings.urlPrefix : process.env.REACT_APP_URL,
      }),
    });

    const jsonResponse = await response.json();
    setAuthFormState((prevState) => {
      return {
        ...prevState,
        spinner: false,
        feedback: jsonResponse.message,
        status: response.status,
      };
    });
  };
  return (
    <Box
      {...{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',

          h1: {
            textShadow: '2px 1px rgba(0, 0, 0, 0.15)',
            padding: '20px 10px',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '18px',
          },

          p: {
            textShadow: '2px 1px rgba(0, 0, 0, 0.15)',
            padding: '0px 10px 20px 10px',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '14px',
          },
          input: {
            margin: '5px',
          },
        },
      }}
    >
      <Stack
        {...{
          spacing: 1,
          sx: {
            padding: '70px',
            borderRadius: '5px',
            position: 'relative',
            display: 'flex',
            width: '500px',
            flexDirection: 'column',
          },
        }}
      >
        <Typography {...{variant: 'h1'}}>Login with one click</Typography>
        <Typography {...{color: 'secondary'}}>
          Submit your email, and we will send you a magic link to login
        </Typography>
        <TextField
          {...{
            label: t('email'),
            variant: 'standard',
            onChange: onChangeListener,
            placeholder: t('email'),
            id: 'email',
          }}
        />

        <Stack {...{direction: 'row', spacing: 2}}>
          <Button
            key="button"
            {...{
              variant: 'outlined',
              onClick: (e: SyntheticEvent) => {
                submit(e);
              },
              disabled: !authFormState.email.length,
            }}
          >
            <Typography {...{color: 'secondary'}}>{t('login')}</Typography>
          </Button>
          {authFormState.spinner && (
            <LoaderWithText key="loader" {...{spinner: authFormState.spinner}} />
          )}
        </Stack>
        {authFormState.feedback.length ? (
          <Box
            {...{
              sx: {
                borderRadius: '5px',
                color: '#fff',
                padding: '10px',
                width: '340px',
                position: 'relative',
                bottom: 0,
                fontSize: '12px',
                fontWeight: '600',
                margin: '10px 5px',
                background:
                  authFormState.status != 200
                    ? `${cemitColors.red}`
                    : `${cemitColors.green}`,
              },
              id: 'login-feedback',
              status: authFormState.status,
            }}
          >
            {authFormState.feedback}
          </Box>
        ) : undefined}
      </Stack>
    </Box>
  );
};

export default AuthFormComponent;
