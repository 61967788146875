import {FrontendView} from './frontendView.ts';
import {FrontendViewGroup} from '../../../../types/frontendViews/frontendViewGroup';
import {CemitTypename} from '../../../../types/cemitTypename.ts';
import {ListRelationType} from '../../../../types/logic/listRelationshipType.ts';

import {clsOrType} from '../../../../appUtils/typeUtils/clsOrType.ts';

/**
 * Ways to list TrainFormations or TrainGroupOnlyTrainFormations
 */
export const trainFormationsGrouping = clsOrType<FrontendViewGroup>(
  CemitTypename.frontendViewGroup,
  {
    frontendViews: [
      FrontendView.trainFormationList,
      FrontendView.trainFormationTable,
      FrontendView.trainFormationMenu,
    ] as FrontendView[],
    listRelationshipType: ListRelationType.mutuallyExclusive,
    defaultFrontendViewOrViews: FrontendView.trainFormationList,
  },
);

/**
 * Ways to list TrainGroups
 */
export const trainGroupsGrouping = clsOrType<FrontendViewGroup>(
  CemitTypename.frontendViewGroup,
  {
    frontendViews: [
      FrontendView.trainGroupList,
      FrontendView.trainGroupTable,
      // Currently being used as the hidden view
      FrontendView.trainGroupMenu,
    ] as FrontendView[],
    listRelationshipType: ListRelationType.mutuallyExclusive,
    defaultFrontendViewOrViews: FrontendView.trainGroupList,
  },
);
