import {TFunction} from 'i18next';

export enum PeriodEnum {
  today = 'today',
  week = 'week',
  month = 'month',
}

// TODO I think these labels are from the API, thus we don't use translation keys instead
export interface Periods {
  Today: PeriodEnum;
  Week: PeriodEnum;
  Month: PeriodEnum;
}

export const periodLabelToEnum: Periods = {
  Today: PeriodEnum.today,
  Week: PeriodEnum.week,
  Month: PeriodEnum.month,
} as Periods;
