import {addIndex, fromPairs, keys, map} from 'ramda';
import {AttributeAlertLevel} from 'types/alerts/attributeAlertLevelEnums';

import {EnumStringType} from 'types/alerts/enumStringType.ts';

/**
 * The priority of the enumType, which matches the order they are declared in
 */
export const alertAlertLevelPriority = (
  enumType: EnumStringType,
): Record<AttributeAlertLevel, number> => {
  const attributeAlertLevels = keys(enumType);

  return fromPairs(
    addIndex(map)(
      (attributeAlertLevel: EnumStringType, index: number) => [
        attributeAlertLevel,
        index,
      ],
      attributeAlertLevels,
    ),
  );
};
