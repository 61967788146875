import React, {useEffect, useState} from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SecondaryButton from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/GenericButtons/SecondaryButton/SecondaryButton.tsx';
import PrimaryButton from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/GenericButtons/PrimaryButton/PrimaryButton.tsx';
import {restrictionTypesKeys} from 'pages/trafficsim/api/data/consts.ts';
import {CircularProgress} from '@mui/material';
import {STATION_LOCATIONS} from 'pages/trafficsim/trafficSimComponents/track/utils/stations.ts';

const styles = {
  formControl: {
    margin: '4px 0',
  },
  button: {
    margin: '8px 4px 4px 0',
  },
  drawerContent: {
    padding: '8px',
    width: '280px',
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  textField: {
    fontSize: '0.7rem',
  },
  inputRoot: {
    fontSize: '0.7rem',
    height: '20px',
  },
  labelRoot: {
    fontSize: '0.7rem',
  },
};

const filter = createFilterOptions();

const AddLimitation = ({
  isNetworkCallActive = false,
  onMoveToCoord = (coordinates) => {},
  showPositionMarker = (status) => {},
  clearEditData = () => {},
  onCloseDrawer = () => {},
  onCreateRestriction = (restrictionDataObject) => {},
  onUpdateRestriction = (restrictionDataObject) => {},
  dataToEdit,
}) => {
  const checkEditDataString = (value) => (value ? value : '');
  const checkEditDataNull = (value) => (value ? value : null);
  const stations = STATION_LOCATIONS;

  const [selectedRestrictionType, setSelectedRestrictionType] = useState(
    checkEditDataString(dataToEdit.selectedRestrictionType),
  );
  const [statPlas, setStatPlas] = useState(checkEditDataNull(dataToEdit.statPlas));
  const [slutPlat, setSlutPlat] = useState(checkEditDataNull(dataToEdit.slutPlat));
  const [berordaVaxlar, setBerordaVaxlar] = useState(
    checkEditDataString(dataToEdit.berordaVaxlar),
  );
  const [orsakTillBegransning, setOrsakTillBegransning] = useState(
    checkEditDataString(dataToEdit.orsakTillBegransning),
  );
  const [notering, setNotering] = useState(checkEditDataString(dataToEdit.notering));
  const [begransningStatus, setBegransningStatus] = useState(
    dataToEdit.begransningStatus ? dataToEdit.begransningStatus : 'current',
  );
  const [authorizedBy, setAuthorizedBy] = useState(
    checkEditDataString(dataToEdit.authorizedBy),
  );
  const [hastighet, setHastighet] = useState(checkEditDataString(dataToEdit.hastighet));
  const [isTilFurtherNoticeChecked, setIsTilFurtherNoticeChecked] = useState(true);

  const [restrictionStartDay, setRestrictionStartDay] = useState(
    checkEditDataString(dataToEdit.restrictionStartDay),
  );
  const [restrictionEndDay, setRestrictionEndDay] = useState(
    checkEditDataString(dataToEdit.restrictionEndDay),
  );
  const [restrictionStartDayTime, setRestrictionStartDayTime] = useState(
    checkEditDataString(dataToEdit.restrictionStartDayTime),
  );
  const [restrictionEndDayTime, setRestrictionEndDayTime] = useState(
    checkEditDataString(dataToEdit.restrictionEndDayTime),
  );
  const [error, setErrorStatus] = useState(false);
  const [errorLog, setErrorLog] = useState({});

  useEffect(() => {
    if (Object.keys(dataToEdit).length > 0) {
      const coordinates = dataToEdit.coordinates;
      onMoveToCoord(coordinates);
    }
    showPositionMarker(true);
    return () => {
      showPositionMarker(false);
    };
  }, []);

  const handleSelectBegransningType = (event) => {
    setErrorStatus(false);
    setSelectedRestrictionType(event.target.value);
  };

  const handleChangeBegransningStatus = (event) => {
    setErrorStatus(false);
    setBegransningStatus(event.target.value);
  };

  const checkEntry = (entry, entryName) => {
    if (entry && entry !== '') {
      delete errorLog[entryName];
      return true;
    }
    errorLog[entryName] = `${entryName} måste anges`;
    return false;
  };

  const showError = () => {
    setErrorStatus(true);
    setErrorLog(errorLog);
  };

  const validateEntries = () => {
    setErrorStatus(false);
    if (
      checkEntry(selectedRestrictionType, 'Begränsning') &&
      checkEntry(statPlas, 'StatPlas') &&
      checkEntry(slutPlat, 'SlutPlat') &&
      checkEntry(restrictionStartDay, 'Beraknad tidsperiod') &&
      checkEntry(restrictionStartDayTime, 'Beraknad tid') &&
      checkEntry(authorizedBy, '"Begransning inlagd på begaran av"') &&
      checkEntry(begransningStatus, 'Begränsning Status')
    ) {
      if (selectedRestrictionType === restrictionTypesKeys.speedLimit) {
        if (!checkEntry(hastighet, 'Hastighetsbegransning')) return false;
      }

      if (!isTilFurtherNoticeChecked) {
        return (
          checkEntry(restrictionEndDay, 'Beraknad tidsperiod') &&
          checkEntry(restrictionEndDayTime, 'Beraknad tid')
        );
      }

      return true;
    }
    Object.entries(errorLog).forEach(([id, str]) => console.log(str));
    return false;
  };

  const restrictionOptions = [
    {value: 'closedTrack', label: 'Avstängt spår'},
    {value: 'backProhibition', label: 'Backförbud'},
    {value: 'speedLimit', label: 'Hastighetsbegränsning'},
    {value: 'other', label: 'Annan begränsning'},
  ];

  return (
    <div style={styles.drawerContent}>
      <FormControl sx={{m: 0, minWidth: 260, marginBottom: 1}} size="small">
        <InputLabel id="restriction-select-label" sx={{fontSize: '12px'}}>
          Ange begränsning
        </InputLabel>
        <Select
          labelId="restriction-select-label"
          id="restriction-select"
          value={selectedRestrictionType}
          onChange={handleSelectBegransningType}
          label="Ange begransning"
          sx={{fontSize: '12px'}}
        >
          {restrictionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} sx={{fontSize: '12px'}}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box>
        <Typography variant="subtitle2">Plats/riktning</Typography>

        <Autocomplete
          value={statPlas}
          onChange={(event, newValue) => {
            setErrorStatus(false);
            if (typeof newValue === 'string') {
              setStatPlas(newValue);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setStatPlas(newValue.inputValue);
            } else {
              setStatPlas(newValue);
              if (
                newValue !== null &&
                Array.isArray(newValue.coordinates) &&
                newValue.coordinates.length > 1
              ) {
                const coordinates = newValue.coordinates;
                onMoveToCoord(coordinates);
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={stations}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          sx={{margin: '8px 4px 4px 0'}}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Startplats" />}
          margin="dense"
          fullWidth
        />

        <Autocomplete
          value={slutPlat}
          onChange={(event, newValue) => {
            setErrorStatus(false);

            if (typeof newValue === 'string') {
              setSlutPlat(newValue);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setSlutPlat(newValue.inputValue);
            } else {
              setSlutPlat(newValue);
              if (
                newValue !== null &&
                Array.isArray(newValue.coordinates) &&
                newValue.coordinates.length > 1
              ) {
                const coordinates = newValue.coordinates;
                onMoveToCoord(coordinates);
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={stations}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          sx={{margin: '8px 4px 4px 0'}}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Slutplats" />}
          margin="dense"
          fullWidth
        />

        <Box style={{textAlign: 'right'}}>
          <SecondaryButton
            color="primary"
            size="small"
            onClick={() => {
              if (Object.keys(dataToEdit).length > 0) {
                const coordinates = dataToEdit.coordinates;
                onMoveToCoord(coordinates);
                return;
              }

              if (slutPlat) {
                const {coordinates} = slutPlat;
                onMoveToCoord(coordinates);
                return;
              }

              if (statPlas) {
                const {coordinates} = statPlas;
                onMoveToCoord(coordinates);
              }
            }}
          >
            Visa
          </SecondaryButton>
        </Box>
      </Box>
      {selectedRestrictionType &&
        selectedRestrictionType === restrictionTypesKeys.speedLimit && (
          <Box>
            <Typography variant="subtitle2">Största tillåtna hastighet (km/h)</Typography>
            <TextField
              margin="dense"
              fullWidth
              InputProps={{style: styles.inputRoot}}
              InputLabelProps={{style: styles.labelRoot}}
              value={hastighet}
              onChange={(event) => {
                setHastighet(event.target.value);
              }}
            />
          </Box>
        )}
      <Box>
        <Typography variant="subtitle2">Berörda växlar</Typography>
        <TextField
          margin="dense"
          fullWidth
          InputProps={{style: styles.inputRoot}}
          InputLabelProps={{style: styles.labelRoot}}
          value={berordaVaxlar}
          onChange={(event) => {
            setBerordaVaxlar(event.target.value);
          }}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Orsak till begränsning</Typography>
        <TextField
          value={orsakTillBegransning}
          onChange={(event) => {
            setOrsakTillBegransning(event.target.value);
          }}
          margin="dense"
          fullWidth
          InputProps={{style: styles.inputRoot}}
          InputLabelProps={{style: styles.labelRoot}}
        />
      </Box>
      <Box style={{marginTop: '30px'}}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              label="Beräknad tidsperiod"
              type="date"
              fullWidth
              value={restrictionStartDay}
              InputLabelProps={{
                shrink: true,
                style: styles.labelRoot,
              }}
              InputProps={{style: styles.inputRoot}}
              onChange={(event) => {
                setErrorStatus(false);
                setRestrictionStartDay(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label=""
              type="time"
              fullWidth
              value={restrictionStartDayTime}
              InputLabelProps={{
                shrink: true,
                style: styles.labelRoot,
              }}
              InputProps={{style: styles.inputRoot}}
              onChange={(event) => {
                setErrorStatus(false);
                setRestrictionStartDayTime(event.target.value);
              }}
            />
          </Grid>
        </Grid>

        {!isTilFurtherNoticeChecked && (
          <Grid style={{marginTop: '8px'}} container spacing={1} alignItems="center">
            <Grid item xs>
              <TextField
                label="Beraknad sluttidsperiod"
                type="date"
                fullWidth
                value={restrictionEndDay}
                InputLabelProps={{
                  shrink: true,
                  style: styles.labelRoot,
                }}
                InputProps={{style: styles.inputRoot}}
                onChange={(event) => {
                  setErrorStatus(false);
                  setRestrictionEndDay(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label=""
                type="time"
                fullWidth
                value={restrictionEndDayTime}
                InputLabelProps={{
                  shrink: true,
                  style: styles.labelRoot,
                }}
                InputProps={{style: styles.inputRoot}}
                onChange={(event) => {
                  setErrorStatus(false);
                  setRestrictionEndDayTime(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}

        <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTilFurtherNoticeChecked}
                  onChange={() => {
                    setErrorStatus(false);
                    setIsTilFurtherNoticeChecked(!isTilFurtherNoticeChecked);
                  }}
                  size="small"
                />
              }
              label={<Typography style={{fontSize: '14px'}}>Tills vidare</Typography>}
              style={styles.labelRoot}
            />
          </FormGroup>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2">Begränsning inlagd på begäran av:</Typography>
        <TextField
          margin="dense"
          fullWidth
          InputProps={{style: styles.inputRoot}}
          InputLabelProps={{style: styles.labelRoot}}
          value={authorizedBy}
          onChange={(event) => {
            setErrorStatus(false);
            setAuthorizedBy(event.target.value);
          }}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Notering</Typography>
        <TextField
          margin="dense"
          fullWidth
          InputProps={{style: styles.inputRoot}}
          InputLabelProps={{style: styles.labelRoot}}
          value={notering}
          onChange={(event) => {
            setNotering(event.target.value);
          }}
        />
      </Box>

      <FormControl component="fieldset" size="small">
        <FormLabel component="legend" style={styles.labelRoot}>
          Status
        </FormLabel>
        <RadioGroup
          aria-label="status"
          name="status"
          value={begransningStatus}
          onChange={handleChangeBegransningStatus}
        >
          <FormControlLabel
            value="current"
            control={<Radio size="small" />}
            label={
              <Typography style={{fontSize: '14px'}}>Aktuell begränsning</Typography>
            }
          />
          <FormControlLabel
            value="upcoming"
            control={<Radio size="small" />}
            label={
              <Typography style={{fontSize: '14px'}}>Kommande begränsning</Typography>
            }
          />
        </RadioGroup>
      </FormControl>

      {error && (
        <div
          style={{
            display: 'flex',
            color: 'red',
            marginTop: '8px',
            justifyContent: 'flex-start',
          }}
        >
          <Box>
            <Typography variant="subtitle2">
              Fel i formuläret - rätta till och försök igen:
            </Typography>
            {Object.entries(errorLog).map(([id, errorStr]) => (
              <li key={id}>{errorStr}</li>
            ))}
          </Box>
        </div>
      )}

      <div style={{display: 'flex', marginTop: '8px', justifyContent: 'flex-end'}}>
        {isNetworkCallActive && <CircularProgress />}

        <PrimaryButton
          variant="contained"
          size="small"
          style={{marginRight: '5px'}}
          onClick={() => {
            if (validateEntries()) {
              if (Object.keys(dataToEdit).length > 0) {
                onUpdateRestriction({
                  id: dataToEdit.id,
                  slutPlat,
                  statPlas,
                  berordaVaxlar,
                  orsakTillBegransning,
                  notering,
                  begransningStatus,
                  authorizedBy,
                  restrictionStartDay,
                  restrictionStartDayTime,
                  restrictionEndDay,
                  restrictionEndDayTime,
                  selectedRestrictionType,
                  hastighet,
                });
                return;
              }
              onCreateRestriction({
                slutPlat,
                statPlas,
                berordaVaxlar,
                orsakTillBegransning,
                notering,
                begransningStatus,
                authorizedBy,
                restrictionStartDay,
                restrictionStartDayTime,
                restrictionEndDay,
                restrictionEndDayTime,
                selectedRestrictionType,
                hastighet,
              });
              return;
            }
            showError();
          }}
        >
          Spara
        </PrimaryButton>
        <PrimaryButton
          variant="outlined"
          size="small"
          onClick={() => {
            onCloseDrawer();
            showPositionMarker(false);
            clearEditData();
          }}
        >
          Avbryt
        </PrimaryButton>
      </div>
    </div>
  );
};

export default AddLimitation;
