import {ListItemButton, Stack} from '@mui/material';
import React from 'react';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * Displays a TrainGroup with an optional color picker to set trainGroup.activity.isActiveColor via
 * it's crudList
 * @param trainGroup
 * @param timezoneStr
 * @param serviceLineColor
 * @param updateTrainGroupAfterColorChange
 * @param showColorPicker
 * @constructor
 */
const TrainGroupDescriptionCompact = ({
  trainGroup,
  timezoneStr,
  serviceLineColor,
  updateTrainGroupAfterColorChange,
  showColorPicker = false,
}: {
  trainGroup: TrainGroup;
  timezoneStr: String;
  serviceLineColor: string;
  updateTrainGroupAfterColorChange: (trainGroup: TrainGroup) => void;
  showColorPicker: boolean;
}) => {
  /// TODO New compact view
  return undefined;
  // return (
  //   <ListItemButton
  //     {...{
  //       sx: {padding: '4px'},
  //     }}
  //   >
  //     <Stack
  //       key={trainGroup.id}
  //       {...{
  //         spacing: 1,
  //         sx: {
  //           fontSize: '12px',
  //         },
  //       }}
  //     >
  //       <TrainGroupTableTrainRoute
  //         key="trainGroupTrainRoute"
  //         {...{trainGroup, serviceLineColor}}
  //       />
  //
  //       <Stack key="trainGroupDatetime" {...{direction: 'column', spacing: 0}}>
  //         <TrainGroupTableDepartureDate key="trainGroupTrainRoute" {...{trainGroup}} />
  //         <TrainGroupDatetimes key="TrainGroupDatetimes" {...{trainGroup, timezoneStr}} />
  //       </Stack>
  //     </Stack>
  //   </ListItemButton>
  //);
};

TrainGroupDescriptionCompact.displayName = 'TrainGroupDescriptionCompact';
export default TrainGroupDescriptionCompact;
