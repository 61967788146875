import {format} from 'date-fns';
import {equals, ifElse, includes, isNil, map, prop, propOr, unless} from 'ramda';
import {shouldClusterIfOverlapsGreaterOrEqualPriorityStops} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import React, {useEffect, useState} from 'react';
import {extremes} from 'utils/functional/functionalUtils.ts';
import {findMapped, strPath} from '@rescapes/ramda';
import TrainLineStation from 'components/apps/trainAppComponents/trainLineComponents/TrainLineStation.tsx';
import {referenceStopDistanceForTrainRouteOrGroup} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import useElementSizeWithRef from 'utils/hooks/domHooks.ts';
import {ScheduledStopPointAndMaybeDateTimeDerived} from '../../../../types/stops/scheduledStopPointAndMaybeTime';
import {SetOffsetLeft} from '../../../../types/layout/setOffsetLeft';
import {TrainProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {ScheduledStopPoint} from '../../../../types/stops/scheduledStopPoint';
import {Size} from '../../../../types/layout/size';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';

import {TrainGroup} from 'types/trainGroups/trainGroup';

/***
 * A Station on the TrainLine
 * @param scheduledStopPointAndMaybeDateTime The scheduledStopPointAndMaybeDateTime/station
 * @param stopsWithOffsetLefts Prevents overlapping stops by minimizing those too close to others
 * with higher priority
 * @param appProps
 * @param trainRun
 * @param measureDistanceFromDistance
 * @param offsetLeft  undefined unless spaceGeospatially is true. If we are not spacing geospationally, rather with
 * flex, this value is calculated by the station dots with useOffsetLeft, which calls setOffsetLeft
 * @param setOffsetLeft
 * @param trainRouteOrRunInterval
 * @param limitedStations
 * @param  {Boolean} recalculateOffsetLefts Toggles between true and folse to tell the TrainLineStation
 * to recompute its offsetLeft and call setOffsetLefts
 * @param [spaceGeospatially] Default false. If true, position the TrainLineStation absolutely
 * @param parentWidth The width of the parent component
 * @param distanceRange Used to trigger clustering
 * based on the
 * @param stationPosition 'start', 'end' or undefined if the station is in the middle and not at the extremes
 * @returns {JSX.Element}
 * @constructor
 */
const TrainLineStationContainer = ({
  appProps,
  trainProps,
  componentProps: {
    offsetLeft,
    scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    setOffsetLeft,
    recalculateOffsetLefts,
    scheduledStopPointAndMaybeDateTime,
    trainGroup,
    measureDistanceFromDistance,
    limitedStations,
    spaceGeospatially,
    parentWidth,
    distanceRange,
    isTrainRouteLine,
    stationPosition,
    hoveredScheduledStopPoint,
    setHoveredScheduledStopPoint,
    trainDetailSize,
  },
}: {
  appProps: TrainAppProps;
  trainProps: TrainProps;
  componentProps: {
    offsetLeft: number;
    scheduledStopPointsAndMaybeDateTimesWithOffsetLefts: ScheduledStopPointAndMaybeDateTimeDerived[];
    setOffsetLeft: SetOffsetLeft;
    recalculateOffsetLefts: Date;
    scheduledStopPointAndMaybeDateTime: ScheduledStopPointAndMaybeDateTimeDerived;
    trainGroup: TrainGroup;
    measureDistanceFromDistance: number | undefined;
    limitedStations: boolean;
    spaceGeospatially: boolean;
    parentWidth: number;
    distanceRange: DistanceRange;
    isTrainRouteLine: boolean;
    stationPosition: StartOrEndPosition;
    hoveredScheduledStopPoint: ScheduledStopPoint;
    setHoveredScheduledStopPoint: StateSetter<ScheduledStopPoint>;
    trainDetailSize: Size;
  };
}) => {
  /*
  const [{ isOverStop }, dropStop] = useDrop(
    () => ({
      accept: [
        ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER,
        ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER,
        ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER
      ],
      canDrop: always(false),
      collect: monitor => {
        return {
          isOverStop: monitor.isOver()
        };
      }
    }),
    []
  );
   */

  const [ref, {width: refWidth}, readRef] = useElementSizeWithRef();

  const [shouldCluster, setShouldCluster] = useState(false);

  useEffect(() => {
    const id = prop('id', scheduledStopPointAndMaybeDateTime);
    // Test if we should cluster and never cluster the first and last stops
    const _offsetLeft = spaceGeospatially ? offsetLeft : readRef?.offsetLeft;
    const should =
      refWidth &&
      !isNil(_offsetLeft) &&
      !includes(
        id,
        map(
          strPath('scheduledStopPointAndMaybeDateTime.id'),
          extremes(scheduledStopPointsAndMaybeDateTimesWithOffsetLefts),
        ),
      )
        ? shouldClusterIfOverlapsGreaterOrEqualPriorityStops(
            {
              // If spaceGeospatially, calculate componentWidth as a fraction of the parent.
              // For even flex spacing, we can rely on the component width
              componentWidth: spaceGeospatially
                ? (100 * refWidth) / parentWidth
                : refWidth,
              scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
              spaceGeospatially,
            },
            {
              scheduledStopPointAndMaybeDateTime,
              offsetLeft: _offsetLeft,
            },
          )
        : false;
    setShouldCluster(should);
  }, [
    readRef?.offsetLeft,
    refWidth,
    parentWidth,
    distanceRange,
    scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
  ]);

  const scheduledStopPointStopName =
    scheduledStopPointAndMaybeDateTime.scheduledStopPoint.shortName;

  // If a TrainRoute, show distances, else show the departure time

  const scheduledStopPointStopTimeOrDistance = ifElse(
    ({isTrainRouteLine}) => isTrainRouteLine,
    ({trainRouteOrGroup}) => {
      if (scheduledStopPointAndMaybeDateTime.distanceLabel) {
        // Pseudo stops
        return scheduledStopPointAndMaybeDateTime.distanceLabel;
      }
      const referenceStopDistance = referenceStopDistanceForTrainRouteOrGroup(
        trainRouteOrGroup,
        scheduledStopPointAndMaybeDateTime.scheduledStopPoint,
        trainProps.railwayLineProps.railwayLines,
      );
      const measureFrom: string = referenceStopDistance
        ? Math.abs(
            referenceStopDistance - scheduledStopPointAndMaybeDateTime.routeDistance,
          ).toFixed(0)
        : scheduledStopPointAndMaybeDateTime.routeDistance.toFixed(0);
      // Convert to km rounded and display with a + if not 0
      const distance = unless(
        (value: number) => equals(0, Math.round(value)),
        (value) => `+${value}`,
      )(Math.round(parseFloat(measureFrom) / 1000));
      return `${distance}km`;
    },
    () => {
      // Choose the arrive time if the departure time doesn't exist
      // We could favor the arrival time sometimes, but it's not that important
      const stationDateTime = findMapped(
        (prop: string) => {
          return propOr(
            undefined,
            prop,
            scheduledStopPointAndMaybeDateTime.timetabledPassingDatetime,
          );
        },
        ['departureDatetime', 'arrivalDatetime'],
      );
      // stationDateTime is only defined if this station/stop is part of the TrainRun's TrainRoute
      return stationDateTime ? format(stationDateTime, 'HH:mm') : undefined;
    },
  )({isTrainRouteLine, trainRouteOrGroup, trainGroup});

  return (
    <TrainLineStation
      {...{
        ref,
        appProps,
        trainProps,
        componentProps: {
          scheduledStopPoint: scheduledStopPointAndMaybeDateTime,
          hoveredScheduledStopPoint,
          setHoveredScheduledStopPoint,
          scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
          trainGroup,
          measureDistanceFromDistance,
          offsetLeft,
          setOffsetLeft,
          recalculateOffsetLefts,
          trainRouteOrRunInterval,
          limitedStations,
          spaceGeospatially,
          parentWidth,
          distanceRange,
          refWidth,
          shouldCluster,
          scheduledStopPointStopName,
          scheduledStopPointStopTimeOrDistance,
          stationPosition,
          trainDetailSize,
        },
      }}
    />
  );
};
export default TrainLineStationContainer;
