import {lensProp} from 'ramda';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {useCallback} from 'react';
import {setClassOrType} from '../functional/cemitTypenameFunctionalUtils.ts';
import {useCustomLocalStorageDefaulted} from './useCustomLocalStorage.ts';
import {useNotEffectUpdateCemitFiltersParentIfNeeded} from 'async/trainAppAsync/trainAppHooks/cemitFilterHooks/cemitFilterParentHooks.ts';
import {addCemitFilters} from 'appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {Cemited} from 'types/cemited';
import {CemitFilterProps} from 'types/cemitFilters/cemitFilterProps';
import {CemitFilterConfig} from 'types/cemitFilters/cemitFilterConfig';
import {LocalStorageProps} from 'types/cemitFilters/localStorageProps.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';

/**
 * Calls useCustomLocalStorage and wraps the setter in a callback
 * to set the filter with the current parentCemitFilter as the parent.
 * T is the underlying type used in the filter, such as DateInterval
 * @param loading
 * @param cemitFilterLocalStorageProps
 * @param parentCemitFilter
 * @param loading
 * @param cemitFilterConfig
 * @param filterProps
 */
export const useCustomLocalStorageForCemitFilter = <MT extends Cemited>(
  loading = false,
  cemitFilterLocalStorageProps: LocalStorageProps<MT>,
  parentCemitFilter: CemitFilter,
  cemitFilterConfig: CemitFilterConfig,
  filterProps?: CemitFilterProps,
): [CemitFilter<MT>, StateSetter<CemitFilter<MT>>] => {
  const {
    isCemitFilter,
    evalCemitFilter,
    extractCemitFilter,
    cemitFilterTypename,
    rightSideExpression,
  } = cemitFilterConfig;

  const {
    localStorageKey,
    parserArgument = [],
    customParser,
    serializer,
    rehydrate,
    expiry,
  } = cemitFilterLocalStorageProps;

  const defaultSerializer = (cemitFilter: CemitFilter<MT>) => {
    // Extract the filters to serialize
    return extractCemitFilter(cemitFilter, filterProps);
  };

  /**
   * By Default create a CemitFilter containing the cemitFiltersForType
   * @param untypedCemitFilterForTypes
   */
  const defaultRehydrate = (
    untypedCemitFilterForTypes: CemitFilter<MT>[] | CemitFilter<MT>,
  ) => {
    const cemitFilterForTypes: CemitFilter<MT>[] = clsOrTypes<MT>(
      cemitFilterTypename,
      untypedCemitFilterForTypes,
    );
    const childCemitFilter = addCemitFilters(
      isCemitFilter,
      rightSideExpression,
      parentCemitFilter,
      cemitFilterForTypes,
      filterProps,
    );
    if (Array.isArray(childCemitFilter)) {
      throw new Error('defaultRehydrate resulted in an array instead of a CemitFilter');
    }

    return childCemitFilter;
  };
  const [cemitFilter, _setCemitFilter] = useCustomLocalStorageDefaulted<CemitFilter<MT>>(
    {
      loading,
      parserArgument,
      customParser,
      serializer: serializer || defaultSerializer,
      rehydrate: rehydrate || defaultRehydrate,
      expiry,
    },
    localStorageKey,
    // TODO this should be something like, but we don't support empty filters.
    // So use [], which works because it results in the parentFilter being returned unmodified
    // it in defaultRehydrate for now
    //clsOrType<CemitFilter<MT>>(cemitFilterTypename, {}),
    [],
  );

  // Custom setter to always use current parentCemitFilter as the parent
  const setCemitFilter = useCallback(
    (value: CemitFilter) => {
      console.log(`setCemitFilter: ${localStorageKey}`);
      const cemitFilterWithUpdatedParentFilter = setClassOrType(
        lensProp('parent'),
        parentCemitFilter,
        value,
      );
      // Add a name for debugging ease
      const cemitFilterWithName = setClassOrType(
        lensProp('name'),
        localStorageKey,
        cemitFilterWithUpdatedParentFilter,
      );
      _setCemitFilter(cemitFilterWithName);
    },
    [parentCemitFilter],
  );

  // Keep cemitFilter synced with changes to the parent
  useNotEffectUpdateCemitFiltersParentIfNeeded(
    loading,
    parentCemitFilter,
    cemitFilter,
    setCemitFilter,
    isCemitFilter,
  );

  return [cemitFilter, setCemitFilter];
};
