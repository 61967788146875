import {useMemo} from 'react';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {AlertTypeConfigVisibleAttributeAlertLevelEnum} from 'types/alerts/attributeAlertLevelEnums';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';

/**
 * Return the alertTypeConfigVisibleAttributeAlertLevelEnum of trainProps.alertConfigProps.alertTypeConfigVisibleAttributeAlertLevelEnums
 * that matches the alertTypeKey of the current alertTypeConfig in  trainProps.alertConfigProps.alertTypeConfig. Also
 * @param trainProps
 */
export const useMemoCurrentAlertTypeConfigVisibleAttributeAlertLevelEnum = (
  trainProps: TrainProps,
): AlertTypeConfigVisibleAttributeAlertLevelEnum => {
  return useMemo((): AlertTypeConfigVisibleAttributeAlertLevelEnum => {
    // Get the index of the current  trainProps.alertConfigProps.alertTypeConfig.alertTypeKey in
    // prevAlertTypeConfigVisibleAttributeAlertLevelEnums
    const alertTypeConfigVisibleAttributeAlertLevelEnums =
      trainProps.alertConfigProps.alertTypeConfigVisibleAttributeAlertLevelEnums!;
    return findOrThrow<AlertTypeConfigVisibleAttributeAlertLevelEnum>(
      (
        alertTypeConfigVisibleAttributeAlertLevelEnum: AlertTypeConfigVisibleAttributeAlertLevelEnum,
      ): boolean => {
        return (
          trainProps.alertConfigProps.alertTypeConfig.alertTypeKey ===
          alertTypeConfigVisibleAttributeAlertLevelEnum.alertTypeKey
        );
      },
      alertTypeConfigVisibleAttributeAlertLevelEnums,
    );
  }, [trainProps.alertConfigProps.alertTypeConfigVisibleAttributeAlertLevelEnums]);
};
