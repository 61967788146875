import {TrainRouteOrGroup} from '../../../types/trainRouteGroups/trainRouteOrGroup';
import {find, map, uniqBy} from 'ramda';
import {TrainRoute} from '../../../types/trainRouteGroups/trainRoute';
import {ServiceLine} from '../../../types/trainRouteGroups/serviceLine';
import {idsEqual} from "../../../utils/functional/functionalUtils.ts";

/**
 * Returns the unique ServiceLines of the TrainRouteOrGroup, either the single ServiceLine of the
 * TrainRouteGroup or TrainRoute or the ServiceLine of each TrainRoute of TrainRouteGroup.
 * @param trainRouteOrGroup
 */
export const serviceLinesOfTrainRouteOrGroup = (trainRouteOrGroup: TrainRouteOrGroup): ServiceLine[] => {
  return uniqBy(
    (serviceLine: ServiceLine): string => {
      return serviceLine.id;
    },
    trainRouteOrGroup.serviceLine ? [trainRouteOrGroup.serviceLine] : map(
      (trainRoute: TrainRoute): ServiceLine => {
        return trainRoute.serviceLine;
      },
      trainRouteOrGroup.trainRoutes
    )
  );
};
export const serviceLineOfTrainRouteOrGroup = (
  serviceLines: ServiceLine[],
  trainRouteOrGroup: TrainRouteOrGroup
) => {
  return trainRouteOrGroup.serviceLine ?
    find((serviceLine: ServiceLine): boolean => {
        return idsEqual(serviceLine, trainRouteOrGroup.serviceLine)
      },
      serviceLines
    ) :
    undefined
}
