import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {SyntheticEvent} from 'react';
import {Stack} from '@mui/material';
import {SxProps} from '@mui/system';
import {ArrowCircleDown, ArrowCircleUp} from '@mui/icons-material';
import {ForwardBackward} from 'types/navigation/directionEnum..ts';
import {
  VisionSpatialNavigatorItem,
  VisionSpatialNavigatorItemProps,
} from 'pages/vision/visionComponents/navigation/VisionSpatialNavigatorItem.tsx';
import {VisionTrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/VisionTrainAppTrainComponentDependencyProps';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';

export interface VisionSpatialNavigatorProps extends CemitComponentProps {
  handleSpatialNavigatorClick: (
    event: SyntheticEvent,
    direction: ForwardBackward,
  ) => void;
  iconSx?: Perhaps<SxProps>;
}

/**
 * Forward/Backward buttons to move along the track
 * @param appProps
 * @param organizationProps
 * @param componentProps
 * @constructor
 */
export const VisionSpatialNavigator = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: VisionTrainAppTrainComponentDependencyProps<VisionSpatialNavigatorProps>) => {
  const visionProps = trainProps.visionProps;
  const {handleSpatialNavigatorClick, iconSx} = componentProps;
  return (
    <Stack
      {...{
        direction: 'column',
        spacing: 1,
        sx: {
          minWidth: 0,
          minHeight: 0,
          overflow: 'hidden',
          width: '100%',
          justifyContent: 'flex-end',
        },
      }}
    >
      <VisionSpatialNavigatorItem
        {...{
          componentProps: ts<VisionSpatialNavigatorItemProps>({
            direction: ForwardBackward.forward,
            meterDelta: visionProps.visionConfig.meterDelta,
            icon: ArrowCircleUp,
            handleSpatialNavigatorClick,
            iconSx,
          }),
        }}
      />
      <VisionSpatialNavigatorItem
        {...{
          componentProps: {
            direction: ForwardBackward.backward,
            meterDelta: visionProps.visionConfig.meterDelta,
            icon: ArrowCircleDown,
            handleSpatialNavigatorClick,
            iconSx,
          },
        }}
      />
    </Stack>
  );
};
export default VisionSpatialNavigator;
