import {Box, IconButton, Tooltip} from '@mui/material';
import {Update} from '@mui/icons-material';
import React from 'react';
import {cemitColors} from '../../../../../../theme/cemitColors.ts';
import {TrainProps} from '../../../../../../types/propTypes/trainPropTypes/trainProps';

import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {updateCemitFilterDateIntervalIfChosenDateDiffers} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {setCemitFilterToDateInterval} from 'async/trainAppAsync/trainAppHooks/cemitFilterHooks/cemitFilterDateIntervalHooks.ts';

/**
 * Button to update the time to now
 * @param trainProps
 * @constructor
 */
const AlertNowButton = ({trainProps}: {trainProps: TrainProps}): JSX.Element => {
  return (
    <Tooltip key="calendarDayTooltip" arrow title={'Update to current time'}>
      <Box>
        <IconButton
          {...{
            edge: 'end',
            sx: {
              color: cemitColors.yellow,
            },
            onClick: () => {
              // Update the time to now
              updateCemitFilterDateIntervalIfChosenDateDiffers(
                trainProps.trainFormationDateProps.dateIntervalDescription,
                new Date(),
                trainProps.trainFormationDateProps.cemitFilterWithDateIntervals!,
                (dateInterval: DateInterval) => {
                  setCemitFilterToDateInterval(
                    trainProps.trainFormationDateProps.cemitFilterWithDateIntervals!,
                    trainProps.trainFormationDateProps.setCemitFilterWithDateIntervals!,
                    dateInterval,
                  );
                },
              );
            },
          }}
        >
          <Update
            {...{
              // Disable if the start and datetime match mostRecentDate
              color: 'secondary',
            }}
          />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
export default AlertNowButton;
