import { always, compose, cond, is, join, T } from 'ramda';
import { formatISO } from 'date-fns';
import { mapObjToValues } from '@rescapes/ramda';

export const urlSearchParamsToQueryString = (query: URLSearchParams): string => {
  return join('&', mapObjToValues(
      compose(
        pair => join('=', pair),
        ([key, value]) => {
          return [key, value];
        }
      ),
      Array.from<[string, string]>(query.entries())
    )
  );
};
export const urlEncodeParamValue = (value: any) => {
  return cond([
    // @ts-ignore
    [is(Date), value => {
      return encodeURIComponent(formatISO(value as Date));
    }],
    [T, always(value)]
  ])(value);
};