import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {
  cemitFilterTrainRouteGroupRightSideExpression,
  evalCemitFilterTrainRouteGroup,
  extractTrainRouteGroupsFromCemitFilter,
  isCemitFilterTrainRouteGroup,
} from 'appUtils/cemitFilterUtils/cemitFilterTrainRouteGroupUtils.ts';
import {when} from 'ramda';
import {idsEqual, onlyOneValueOrNoneThrow} from 'utils/functional/functionalUtils.ts';
import {removeExcessiveInstancesFromFilterAndAddNew} from 'appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {TrainRoute} from '../../../../types/trainRouteGroups/trainRoute';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {CemitFilterTrainRouteGroup} from '../../../../types/cemitFilters/cemitFilterTrainRouteGroup';
import {TrainRouteOrGroup} from '../../../../types/trainRouteGroups/trainRouteOrGroup';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {TrainRouteGroup} from '../../../../types/trainRouteGroups/trainRouteGroup';
import {CemitFilter} from '../../../../types/cemitFilters/cemitFilter';
import {CemitTypename} from '../../../../types/cemitTypename.ts';
import {CemitFilterTrainRouteGroupProps} from '../../../../types/cemitFilters/cemitFilterTrainRouteGroupProps';
import {clsOrType} from '../../../../appUtils/typeUtils/clsOrType.ts';

/**
 * Updates/Creates a cemitFilterWithTrainRouteGroup, which is like a
 * CemitFilter but can be used to set the interval of multiple TrainRuns at the same time
 * @param loading
 * @param parentCemitFilter
 * @param trainRouteGroups
 * @param trainRoutes
 * @param trainRouteOrGroup
 * @param cemitFilterWithTrainRouteGroup
 * @param setCemitFilterWithTrainRouteGroup
 */
export const useNotLoadingUpdateOrCreateCemitFilterTrainRouteGroup = (
  loading: boolean,
  parentCemitFilter: Perhaps<CemitFilter>,
  trainRouteGroups: Perhaps<TrainRouteGroup[]>,
  trainRoutes: Perhaps<TrainRoute[]>,
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup>,
  cemitFilterWithTrainRouteGroup: Perhaps<CemitFilter>,
  setCemitFilterWithTrainRouteGroup: StateSetter<Perhaps<CemitFilter>>,
) => {
  useNotLoadingEffect(
    loading,
    () => {
      // Reset the cemitFilter to its parent whenever the parent changes
      // The filter will be set to the new trainRouteOrGroup in useUpdateOrCreateCemitFilterTrainRoute
      if (trainRouteOrGroup) {
        setCemitFilterWithTrainRouteGroup(
          clsOrType<CemitFilterTrainRouteGroup>(
            CemitTypename.cemitFilterTrainRouteGroup,
            parentCemitFilter,
          ),
        );
      }
    },
    [trainRouteOrGroup, parentCemitFilter],
  );

  const dependencies = [
    trainRouteGroups,
    trainRoutes,
    trainRouteOrGroup,
    cemitFilterWithTrainRouteGroup,
    parentCemitFilter,
  ] as const;
  useNotLoadingEffect(
    loading,
    (
      trainRouteGroups,
      trainRoutes,
      trainRouteOrGroup,
      cemitFilterWithTrainRouteGroup,
      parentCemitFilter,
    ) => {
      when(
        (cemitFilterTrainRouteGroup: CemitFilter) => {
          // Check if the TrainRouteOrGroup actually changed
          const existingTrainRouteOrGroup = onlyOneValueOrNoneThrow(
            extractTrainRouteGroupsFromCemitFilter(
              cemitFilterTrainRouteGroup,
              clsOrType<CemitFilterTrainRouteGroupProps>(
                CemitTypename.cemitFilterTrainRouteGroupProps,
                {trainRouteGroups, trainRoutes},
              ),
            ),
          );
          return (
            !existingTrainRouteOrGroup ||
            !idsEqual(existingTrainRouteOrGroup, trainRouteOrGroup)
          );
        },
        () => {
          // Add the TrainRouteOrGroup, removing any previous one, as only one is allowed for now
          const updatedCemitFilter = removeExcessiveInstancesFromFilterAndAddNew<
            CemitFilterTrainRouteGroup,
            CemitFilterTrainRouteGroupProps
          >(
            isCemitFilterTrainRouteGroup,
            evalCemitFilterTrainRouteGroup,
            extractTrainRouteGroupsFromCemitFilter,
            cemitFilterTrainRouteGroupRightSideExpression,
            CemitTypename.cemitFilterTrainRouteGroup,
            parentCemitFilter,
            [trainRouteOrGroup],
            clsOrType<CemitFilterTrainRouteGroupProps>(
              CemitTypename.cemitFilterTrainRouteGroupProps,
              {
                trainRoutes,
                trainRouteGroups,
              },
            ),
            1,
          );
          setCemitFilterWithTrainRouteGroup(updatedCemitFilter);
        },
      )(cemitFilterWithTrainRouteGroup);
    },
    dependencies,
  );
};
