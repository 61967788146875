import {ReactElement, useMemo, useRef, useState} from 'react';
import {useNotLoadingEffectTrainMap} from 'async/cemitAppAsync/cemitAppHooks/mapHooks/trainMapHooks.ts';
import {Map} from 'mapbox-gl';
import {includes} from 'ramda';
import {getOrganizationViews} from 'utils/organization/organizationUtils.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useWhatIsLoading} from '../../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../../trainDependencyUnitConfig.ts';
import {MapSourceChangeStatus} from 'types/mapbox/mapSourceChangeStatus';
import {MapProps} from 'types/propTypes/mapPropTypes/mapProps';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';

/**
 * Loads/Updates Mapbox data into mapProps
 * Depends directly on TrainRoutes at trainProps.trainRouteGroups
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainMapDependency = ({
  appProps,
  organizationProps,
  trainProps,
  renderChildren,
  loading,
}: Required<TrainAppTrainDependencyProps>): ReactElement<
  Required<TrainAppTrainDependencyProps>
> => {
  const isTrainMapUsed =
    !loading &&
    includes(FrontendView.map, getOrganizationViews(organizationProps.organization));
  const inactive = loading || !isTrainMapUsed;

  // The Mapbox map. Avoid use of the variable name map since it is reserved for th emap function
  const [trainMap, setTrainMap] = useState<Map | undefined>(undefined);
  // Tracks loading of mapbox
  const [trainMapLoading, setTrainMapLoading] = useState<boolean>(true);
  // Tracks the mounting of the mapbox map after loading
  const [isTrainMapMounted, setIsTrainMapMounted] = useState<boolean>(false);
  // Documents creation and update of Mapbox sources so listeners can react
  // These should only be checked by code that needs to take a Mapbox action as a result, such as zooming or
  // rerendering layers of another source to make sure the z-order is correct.
  const [changeStatuses, setChangeStatuses] = useState<MapSourceChangeStatus[]>([]);
  // Reference to the trainMap's container so we know when React has rendered it
  const trainMapContainer = useRef();

  const mapProps = useMemoClsOrType<MapProps>(
    CemitTypename.mapProps,
    {
      trainMapContainer,
      trainMap,
      setTrainMap,
      trainMapLoading,
      setTrainMapLoading,
      isTrainMapMounted,
      setIsTrainMapMounted,
      changeStatuses,
      setChangeStatuses,
    },
    [trainMap, trainMapLoading, isTrainMapMounted, changeStatuses],
  );

  // Load the map when not load and the map container has been rendered in the browser
  useNotLoadingEffectTrainMap(
    loading || inactive || !trainMapContainer.current,
    organizationProps,
    isTrainMapMounted,
    setTrainMap,
    setTrainMapLoading,
    setIsTrainMapMounted,
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainMapDependency.name),
    TrainMapDependency.name,
    {
      trainMap,
      trainMapLoading,
      isTrainMapMounted,
    },
    [trainMap, trainMapLoading, isTrainMapMounted],
    appProps.setWhatDependenciesAreLoading,
    !(isTrainMapUsed && (loading || trainMapLoading || !isTrainMapMounted)),
  );

  const mapPropsMerged = useMemo(() => {
    return {
      loading: whatIsLoading.loading,
      whatIsLoading,
      ...mapProps,
    };
  }, [mapProps, whatIsLoading]);

  return renderChildren({
    appProps,
    organizationProps,
    mapProps: mapPropsMerged,
    trainProps,
  });
};

TrainMapDependency.displayName = 'TrainMapDependency';
export default TrainMapDependency;
// export default memo(
//   TrainMapDependency,
//   (prevProps, currentProps) => {
//   const appPropsEqual = prevProps.appProps == currentProps.appProps;
//   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
//   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
//   return appPropsEqual && orgPropsEqual && trainPropsEqual;
// }
//);
