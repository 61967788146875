import {Perhaps} from 'types/typeHelpers/perhaps';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {WeekYear} from 'types/datetime/weekYear';
import {CemitTypename} from 'types/cemitTypename.ts';
import {applySpec} from 'ramda';
import {
  addWeeks,
  endOfISOWeek,
  getISOWeek,
  getYear,
  setISOWeek,
  startOfISOWeek,
} from 'date-fns';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Create a WeekYear instance from a date
 * @param date
 */
export const weekYearInitFromDate = (date: Date) => {
  return clsOrType<WeekYear>(
    CemitTypename.weekYear,
    applySpec({
      // ISO week number
      week: getISOWeek,
      // 4-digit year
      year: getYear,
      // The start to end of the week
      dateInterval: (date) => {
        return clsOrType<DateInterval>(
          CemitTypename.dateInterval,
          applySpec({start: startOfISOWeek, end: endOfISOWeek})(date),
        );
      },
    })(date),
  );
};

/**
 * Init a WeekYear instance from params
 * @param year
 * @param week
 */
export const weekYearInitFromParams = (
  year: Perhaps<string | number>,
  week: Perhaps<string | number>,
): WeekYear => {
  const givenDateOrNow =
    year && week
      ? setISOWeek(new Date(parseInt(year), 0, 1), parseInt(week))
      : new Date();
  return weekYearInitFromDate(givenDateOrNow);
};
