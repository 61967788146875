import React from 'react';
import {SvgIcon} from '@mui/material';
import {lighten} from '@mui/material/styles/index.js';

/**
 * Creates a front-facing train icon
 * @param fill
 * @param fontSize
 * @param opacity
 * @constructor
 */
export const TrainFacingRealtimeSvgIcon = ({
  fill = 'white',
  fontSize = 'medium',
  opacity = 1,
}) => {
  return (
    <SvgIcon {...{fontSize}}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 8.25C6.3675 8.25 5.28 8.61 4.8225 9H13.17C12.72 8.61 11.6325 8.25 9 8.25ZM4.5 16.875C4.5 17.4975 5.0025 18 5.625 18H12.375C12.9975 18 13.5 17.4975 13.5 16.875V14.25H4.5V16.875Z"
          fill={fill}
        />
        <path
          d="M9.125 17.25C9.74632 17.25 10.25 16.7463 10.25 16.125C10.25 15.5037 9.74632 15 9.125 15C8.50368 15 8 15.5037 8 16.125C8 16.7463 8.50368 17.25 9.125 17.25Z"
          fill="black"
        />
        <path
          d="M9 6.75C6 6.75 3 7.125 3 9.75V16.875C3 18.3225 4.1775 19.5 5.625 19.5L4.5 20.625V21H6L7.5 19.5H10.5L12 21H13.5V20.625L12.375 19.5C13.8225 19.5 15 18.3225 15 16.875V9.75C15 7.125 12 6.75 9 6.75ZM9 8.25C11.6325 8.25 12.72 8.61 13.1775 9H4.8225C5.28 8.61 6.3675 8.25 9 8.25ZM4.5 10.5H8.25V12.75H4.5V10.5ZM13.5 16.875C13.5 17.4975 12.9975 18 12.375 18H5.625C5.0025 18 4.5 17.4975 4.5 16.875V14.25H13.5V16.875ZM13.5 12.75H9.75V10.5H13.5V12.75Z"
          fill="black"
        />
        <path
          d="M14 3V4.27314C17.1626 4.27314 19.7269 6.8374 19.7269 10H21C21 6.1356 17.8644 3 14 3ZM14 5.54627V6.81941C15.7545 6.81941 17.1806 8.2455 17.1806 10H18.4537C18.4537 7.5392 16.4608 5.54627 14 5.54627Z"
          fill={lighten(fill, 0.3)}
        ></path>
      </svg>
    </SvgIcon>
  );
};

TrainFacingRealtimeSvgIcon.displayProps = 'TrainFacingRealtimeSvgIcon';
export default TrainFacingRealtimeSvgIcon;
