import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainAppComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppComponentDependencyProps';
import AlertErrorLevelComponent, {
  AlertErrorLevelComponentProps,
} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertErrorLevelComponent.tsx';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import AlertErrorLevelsComponent from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertErrorLevelsComponent.tsx';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Stack} from '@mui/material';
import {map, zipWith} from 'ramda';
import {AlertBaseGauge, AlertGaugeByTimePeriod} from 'types/alerts/alertGauge';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig';

export interface TrainGroupAlertStatusProps {
  trainGroup: TrainGroup;
}

/**
 * Shows collapsed or expanded alert summaries for each trainGroups in the list of trainGroups
 * @param loading
 * @param componentProps
 * @constructor
 */
const TrainGroupAlertStatus = ({
  loading,
  componentProps,
}: TrainAppComponentDependencyProps<TrainGroupAlertStatusProps>) => {
  const trainGroup: Perhaps<TrainGroup> = componentProps?.trainGroup;
  if (
    loading ||
    trainGroup?.alertScopeSummaryProps?.alertTrainGroupProps?.alertGraphqlStatus !==
      LoadingStatusEnum.complete
  ) {
    return (
      <LoaderWithText
        {...{sx: {minHeight: trainGroup?.actvity?.isListingExpanded ? '45px' : '15px'}}}
      />
    );
  }

  const alertGauges: AlertBaseGauge[] = map(
    (alertGaugesByTimePeriod: AlertGaugeByTimePeriod) => {
      return alertGaugesByTimePeriod.today;
    },
    trainGroup!.alertScopeSummaryProps!.alertTrainGroupProps!.alertGaugesByTimePeriod!,
  );

  const isExpanded = trainGroup.activity.isListingExpanded;
  const alertErrorLevelComponents = map((alertGauge: AlertBaseGauge) => {
    return (
      <AlertErrorLevelsComponent
        key={alertGauge.alertTypeConfig.label}
        {...{
          loading,
          trainGroup,
          alertGauge,
          alertErrorLevelConfigs: alertGauge.alertTypeConfig!.attributeAlertLevelConfigs,
          // Only show most severe alert that has a non-zero value
          // or else show the lowest level or none
          // An accordion control expands TrainGroupAlertStatus
          expandable: true,
          isExpanded,
        }}
      />
    );
  }, alertGauges);
  return <Stack {...{direction: 'row'}}>{alertErrorLevelComponents}</Stack>;
};
export default TrainGroupAlertStatus;
