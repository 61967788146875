import React from 'react';

export const ErrorSvgIcon = ({
  fill = '#ff0000',
  width = '100%',
  height = '100%',
  trainGroupFill = undefined,
}) => {
  const independentColor = '#000000';
  const trainGroupFillOrDefault = trainGroupFill || independentColor;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={width}
      height={height}
      id="svg2"
      viewBox="0 0 497 463"
    >
      <path
        style={{
          opacity: 1,
          fill,
          fillOpacity: 1,
          fillRule: 'evenodd',
          ...(trainGroupFill
            ? {
                stroke: trainGroupFill,
              }
            : {}),
          strokeWidth: 40,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="path2199"
        d="M 55.224355,159.5521 A 209.65717,199.05055 0 1 1 55.176569,159.66303"
        transform="translate(-0.7544786,-3.4580688)"
      />
      <path
        style={{
          fill: trainGroupFillOrDefault,
          fillRule: 'evenodd',
          stroke: 'none',
          strokeWidth: '1px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
          fillOpacity: 1.0,
        }}
        d="M 246.99619,112.27504 C 246.99619,112.27504 220.83324,110.15371 213.76217,129.2456 C 206.69111,148.33748 251.23883,301.77965 251.23883,301.77965 C 251.23883,301.77965 292.65628,142.86914 282.35153,124.29585 C 274.1535,109.51972 247.70329,112.27504 246.99619,112.27504 z "
        id="path4141"
      />
      <path
        style={{
          opacity: 1,
          fill: trainGroupFillOrDefault,
          fillOpacity: 1.0,
          fillRule: 'evenodd',
          stroke: 'none',
          strokeWidth: 40,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="path5114"
        d="M 233.97767,335.55437 A 8.485281,8.8388348 0 1 1 233.97574,335.5593"
        transform="matrix(3.2495812,0,0,3.1596047,-535.93362,-723.41075)"
      />
    </svg>
  );
};
ErrorSvgIcon.displayProps = 'ErrorSvgIcon';
export default ErrorSvgIcon;
