import {TFunction} from 'i18next';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  AlertTypeKey,
  AlertTypeLabels,
  AlertTypeShortLabels,
} from 'types/alerts/alertTypeKey.ts';
import {
  rideComfortAlertLevelToLabel,
  RideComfortAttributeAlertSValue,
  rideComfortLevelToAttribute,
} from 'types/alerts/rideComfort/rideComfortAlertLevel.ts';
import {rideComfortErrorLevelConfigs} from 'types/alerts/rideComfort/rideComfortErrorLevelConfigs.ts';
import {rideComfortDataThresholds} from 'types/alerts/rideComfort/rideComfortDataThresholds.ts';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';
import {rideComfortTrainGroupIconConfigs} from 'types/alerts/rideComfort/rideComfortTrainGroupIconConfigs.ts';
import {AlertRideComfortGauge} from 'types/alerts/rideComfort/rideComfortGauge';

export const rideComfortAlertConfig = (
  t: TFunction,
): AlertTypeConfig<AlertRideComfortGauge> => {
  return clsOrType<AlertTypeConfig<AlertRideComfortGauge>>(
    CemitTypename.alertTypeConfig,
    {
      alertTypeKey: AlertTypeKey.alertPointId,
      alertLevelToAttribute: rideComfortLevelToAttribute,
      alertLevelToLabel: rideComfortAlertLevelToLabel(t),
      label: t(AlertTypeLabels.alertPointId),
      labelShort: t(AlertTypeShortLabels.alertPointId),
      attributeAlertLevelConfigs: rideComfortErrorLevelConfigs(t),
      criticalSValueLevel: RideComfortAttributeAlertSValue.critical,
      dataThresholds: rideComfortDataThresholds(t),
      warningLevels: [RideComfortAttributeAlertLevel.warning],
      criticalLevels: [RideComfortAttributeAlertLevel.critical],
      attributeAlertLevelEnum: RideComfortAttributeAlertLevel,
      defaultVisibleAttributeAlertLevels: [
        RideComfortAttributeAlertLevel.warning,
        RideComfortAttributeAlertLevel.critical,
      ],

      propsResolver: (node) => {
        return {};
      },
      unit: 'GENERIC',
      levelAllValueGreaterThan: 0.5,
    },
  );
};
