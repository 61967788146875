/**
 * Alert point id values used in the graphql queries
 */
export enum AlertTypeKey {
  // Overall
  alertPointId = 'alertPointId',
  // Train specific
  roughRideTrainPointId = 'trainPointId',
  // Track specific
  roughRideTrackPointId = 'trackPointId',
  // Hunting
  huntingPointId = 'huntingPointId',
  // (Emergency) Braking
  brakingPointId = 'brakingPointId',
}

/**
 * Labels for each alert types. The values are translated to the local language
 */
export enum AlertTypeLabels {
  alertPointId = 'rideComfort',
  // Train specific
  roughtRideTrainPointId = 'roughRideTrain',
  // Track specific
  roughRideTrackPointId = 'roughRideTrack',
  // Hunting
  huntingPointId = 'hunting',
  // (Emergency) Braking
  brakingPointId = 'braking',
}
export enum AlertTypeShortLabels {
  alertPointId = 'rideComfortShort',
  // Train specific
  trainPointId = 'roughRideTrainShort',
  // Track specific
  trackPointId = 'roughRideTrackShort',
  // Hunting
  huntingPointId = 'huntingShort',
  // (Emergency) Braking
  brakingPointId = 'brakingShort',
}
