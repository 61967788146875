import React, {createRef, forwardRef, MutableRefObject, ReactElement} from 'react';
import {Box, Stack} from '@mui/material';
import {concat, equals, ifElse} from 'ramda';
import {DisplayProps} from '../../../types/propTypes/displayProps';
import {SxProps} from '@mui/system';
import SplitPane, {Pane} from 'react-split-pane';
import {CEMIT_GRAY} from '../../../theme/cemitColors.ts';
import {toArrayIfNot} from '../../../utils/functional/functionalUtils.ts';

type SplitPaneProps = {
  split: 'vertical' | 'horizontal';
  displayProps: DisplayProps;
  defaultSize: (input: void | number) => number;
  minSize: string;
  maxSize?: string;
  sx: SxProps;
  firstPaneSx: SxProps;
  secondPaneSx: SxProps;
  // Thew two panes
  children: [React.ReactNode, React.ReactNode];
  onChange: ({
    firstMeasure,
    lastMeasure,
    secondaryAxisMeasure,
  }: {
    firstMeasure: number;
    lastMeasure: number;
    secondaryAxisMeasure: number;
  }) => void;
};
type SplitPaneStackRef = MutableRefObject<typeof Stack>;
/**
 * Creates a Split-pane based on https://tinloof.com/blog/how-to-make-your-own-splitpane-react-component-with-0-dependencies
 * Can be horizontal with direction='row' or vertical with direction='column'
 * @param defaultSize Optional starting width of the left element
 * @param minSize Defaults to 25%
 * @param children
 * @returns {JSX.Element}
 */
const SplitPaneWrapper = forwardRef<SplitPaneStackRef, SplitPaneProps>(
  (
    {
      split,
      defaultSize,
      minSize,
      onChange,
      children,
      sx = {},
      firstPaneSx = {},
      secondPaneSx = {},
    }: SplitPaneProps,
    ref: React.ForwardedRef<SplitPaneStackRef>,
  ) => {
    const marginSize = '5px';
    const disabled = false;
    const splitPaneRef = createRef<SplitPaneStackRef>();
    const {secondaryOrganizationAxisMeasure} = ifElse(
      equals('horizontal'),
      () => {
        return {
          secondaryOrganizationAxisMeasure: 'organizationHeight',
        };
      },
      () => {
        return {
          secondaryOrganizationAxisMeasure: 'organizationWidth',
        };
      },
    )(split);

    return (
      <Box
        {...{
          ref: splitPaneRef,
          sx: concat(
            [
              {
                minWidth: 0,
                minHeight: 0,
                flex: 2,
                overflow: 'hidden',
                // Required by SplitPane
                position: 'relative !important',
                width: '100%',
                height: '100%',
              },
            ],
            toArrayIfNot(sx),
          ),
        }}
      >
        <SplitPane
          {...{
            // We currently only care about the lastMeasure here, meaning the right or bottom component and the secondaryAxisMeasure, which would
            // height from horizontal split panes and
            onChange: (size) => {
              onChange({
                lastMeasure: size,
                secondaryMeasure: ref?.current?.[secondaryOrganizationAxisMeasure],
              });
            },
            spacing: 0.1,
            split,
            maxSize: '100%',
            minSize: 0,
            defaultSize,
            paneStyle: {
              minWidth: 0,
              minHeight: 0,
            },
            resizerStyle: {
              backgroundColor: CEMIT_GRAY,
              opacity: 0.5,
              zIndex: 1,
              boxSizing: 'border-box',
              backgroundClip: 'padding-box',
              ...(equals('horizontal', split)
                ? {
                    minHeight: '2px',
                    height: '2px',
                    marginTop: marginSize,
                    marginBottom: marginSize,
                    //margin: '-5px 0',
                    //borderTop: '5px solid rgba(255, 255, 255, 0)',
                    //borderBottom: '5px solid rgba(255, 255, 255, 0)',
                    cursor: 'row-resize',
                    width: '100%',
                    // '&:hover': {
                    //   borderTop: '5px solid rgba(0, 0, 0, 0.5)',
                    //   borderBottom: '5px solid rgba(0, 0, 0, 0.5)'
                    // }
                  }
                : {}),
              ...(equals('vertical', split)
                ? {
                    minWidth: '2px',
                    width: '2px',
                    marginLeft: marginSize,
                    marginRight: marginSize,
                    //margin: '0 -5px',
                    //borderLeft: '5px solid rgba(255, 255, 255, 0)',
                    //borderRight: '5px solid rgba(255, 255, 255, 0)',
                    cursor: 'col-resize',
                    // '&:hover': {
                    //   borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
                    //   borderRight: '5px solid rgba(0, 0, 0, 0.5)'
                    // }
                  }
                : {}),
              ...(disabled
                ? {
                    cursor: 'not-allowed',
                    //'&:hover': { borderColor: 'transparent' }
                  }
                : {}),
            },
          }}
        >
          <SplitPaneFirst {...{sx: firstPaneSx}}>{children[0]}</SplitPaneFirst>
          <SplitPaneLast {...{sx: secondPaneSx}}>{children[1]}</SplitPaneLast>
        </SplitPane>
      </Box>
    );
  },
);

const SplitPaneFirst = function SplitPaneFirst({
  sx,
  children,
}: {
  sx: SxProps;
  children: ReactElement;
}) {
  return (
    <Box
      {...{
        sx: concat(
          [
            {
              display: 'flex',
              minHeight: '0',
              minWidth: '0',
              width: '100%',
              height: '100%',
              // Hide overflow and allow all scrolling. Children can decide if they are scrollable
              overflow: 'hidden',
              overflowX: 'auto' /**/,
              overflowY: 'auto' /**/,
            },
          ],
          toArrayIfNot(sx),
        ),
      }}
    >
      <Pane
        {...{
          className: 'pane',
          style: {minWidth: 0},
          children,
        }}
      />
    </Box>
  );
};

const SplitPaneLast = function SplitPaneFirst({
  sx,
  children,
}: {
  sx: SxProps;
  children: ReactElement;
}) {
  return (
    <Box
      {...{
        sx: concat(
          [
            {
              display: 'flex',
              minHeight: '0',
              minWidth: '0',
              width: '100%',
              height: '100%',
              // Hide overflow and allow all scrolling. Children can decide if they are scrollable
              overflow: 'hidden',
              overflowX: 'auto',
              overflowY: 'auto',
            },
          ],
          toArrayIfNot(sx),
        ),
      }}
    >
      <Pane
        {...{
          style: {minWidth: 0, minHeight: 0},
          className: 'pane',
          children,
        }}
      />
    </Box>
  );
};

SplitPaneLast.displayName = 'SplitPaneLast';

SplitPaneWrapper.displayName = 'SplitPaneWrapper';
export default SplitPaneWrapper;
