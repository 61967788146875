import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Stack, Tooltip, Typography} from '@mui/material';
import {cemitColors} from 'theme/cemitColors.ts';
import React from 'react';
import {ApplicationNavigationDerived} from '../../../types/navigation/applicationNavigation';
import {Property} from 'csstype';
import {compact, mapObjToValues} from 'utils/functional/functionalUtils.ts';
import {any, filter, find, map, values} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';

type CemitAppNavigationItemsProps = {
  apps: Record<string, ApplicationNavigationDerived>;
  currentPath: string;
};
/**
 * Shows the navigation items given in apps
 * @param apps Configured apps to show
 * @param currentPath
 */
export const CemitAppNavigationItems = ({
  apps,
  currentPath,
}: CemitAppNavigationItemsProps) => {
  const {t} = useTranslation();
  const applicationNavigationDeriveds: ApplicationNavigationDerived[] = values(apps);

  // If the selected item is exclusive, only show it
  const exclusive: Perhaps<ApplicationNavigationDerived> =
    find<ApplicationNavigationDerived>(
      (applicationNavigationDerived: ApplicationNavigationDerived): boolean => {
        const selected: boolean =
          applicationNavigationDerived.routeRegexp.test(currentPath);
        return Boolean(selected && applicationNavigationDerived.hideAllOthersWhenActive);
      },
      applicationNavigationDeriveds,
    );
  const filteredApplicationNavigationDerived: ApplicationNavigationDerived[] = exclusive
    ? [exclusive]
    : applicationNavigationDeriveds;

  // Get items that aren't hidden, or just the exclusive item
  const items: JSX.Element[] = compact(
    map(
      ({
        hasAccess,
        name,
        to,
        routeRegexp,
        Icon,
        isVisible,
        isEnabled,
      }: ApplicationNavigationDerived): Perhaps<JSX.Element> => {
        const selected: boolean = routeRegexp.test(currentPath);
        const color: Property.Color = selected
          ? cemitColors.orange
          : isEnabled && hasAccess
            ? 'white'
            : 'gray';
        const navButtonWrapper: React.ReactNode = (
          <Tooltip
            key={name}
            {...{
              arrow: true,
              title: hasAccess ? name : `${t('contactCemitForAccessTo')} ${name}`,
            }}
          >
            <Stack
              direction="column"
              key={name}
              {...{
                sx: {
                  alignItems: 'center',
                  borderLeft: selected
                    ? `3px ${cemitColors.orange} solid`
                    : `3px transparent solid`,
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.15)',
                  },
                  p: {
                    color,
                    fontSize: '10px',
                    textAlign: 'center',
                    paddingBottom: '5px',
                  },
                  '*': {
                    cursor: hasAccess ? 'cursor' : 'not-allowed',
                  },
                },
              }}
            >
              <Box
                {...{
                  sx: {
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    marginTop: '8px',
                    // TODO Move this
                    img: {
                      width: '30px',
                    },
                  },
                }}
              >
                <Icon fill={color} stroke={color} />
              </Box>
              <Typography {...{sx: {color}, variant: 'subtitle2'}}>{name}</Typography>
            </Stack>
          </Tooltip>
        );

        return isVisible ? (
          isEnabled && hasAccess && !exclusive ? (
            <Link key={name} to={to}>
              {' '}
              {navButtonWrapper}
            </Link>
          ) : (
            navButtonWrapper
          )
        ) : undefined;
      },
      filteredApplicationNavigationDerived,
    ),
  );
  return (
    <Stack
      {...{
        sx: {
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          alignItems: 'center',
          justifyContent: 'start',
          position: 'relative',
        },
      }}
    >
      {items}
    </Stack>
  );
};
