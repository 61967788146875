import {Perhaps} from 'types/typeHelpers/perhaps';
import {MinimizedOrLoaded} from 'types/cemitTypes/minimizedOrLoaded';
import {
  Organization,
  OrganizationLoaded,
  OrganizationMinimized,
} from 'types/organizations/organization.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {anyPass, equals, map} from 'ramda';
import {fromZonedTime} from 'date-fns-tz';
import {getLocalTimeZoneStr} from 'utils/datetime/timeUtils.ts';

/**
 * Return true if the organization timezone matches the browser timezone right now
 * @param loading
 * @param organization
 * @param chosenTimeZone Optionally compare the organization's timezone to this timezone
 */
export const useNotLoadingDoesLocalTimezoneMatchOrganization = (
  loading: boolean,
  organization: Perhaps<MinimizedOrLoaded<OrganizationMinimized, OrganizationLoaded>>,
  chosenTimeZone: Perhaps<string> = undefined,
) => {
  const date = new Date();
  const localTimeZoneStr = chosenTimeZone || getLocalTimeZoneStr();
  return useNotLoadingMemo(
    loading || !organization,
    (organization: Organization, _date: number) => {
      const timezoneStr = organization.timezoneStr;

      // Does the organization timezone match the browser's timezone
      return anyPass([
        // Name match
        (strs: [string, string]) => equals(...strs),
        // Offest match
        (strs: [string, string]) => {
          return equals(
            ...(map((str) => fromZonedTime(date, str), strs) as [Date, Date]),
          );
        },
      ])([timezoneStr, localTimeZoneStr]);
    },
    [organization, date.getDate()] as const,
  );
};
