import {
  TrainRoute,
  TrainRouteMinimized,
  TrainRouteDerived,
} from 'types/trainRouteGroups/trainRoute';
import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {ScheduledStopPoint} from 'types/stops/scheduledStopPoint';
import {ServiceLineMinimized} from 'types/trainRouteGroups/serviceLine';
import {TrainDistanceInterval} from 'types/distances/trainInterval';
import {TrackRoute} from 'types/railways/trackRoute';
import {DirectionType} from 'types/trainRouteGroups/directionType.ts';
import {OrderedRoutePoint} from 'types/trainRouteGroups/orderedRoutePoint';
import {Track} from 'types/railways/track';
import {Feature, LineString} from 'geojson';
import {
  bboxOfTrackRouteMemoized,
  trackLineSegmentsMemoized,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import {BBox} from 'geojson';
import {omit} from 'ramda';
import {IdentifiedClass} from '../cemitAppCemitedClasses/identifiedClass.ts';

export class TrainRouteMinimizedClass
  extends IdentifiedClass
  implements TrainRouteMinimized
{
  constructor(obj: TrainRouteMinimized) {
    // Omit getter only property.
    // setters from the constructor
    const objWithoutTrainRoutes = omit(['trainRoutes'], obj);
    super(objWithoutTrainRoutes);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainRouteMinimized;
  shortName?: string;
  reverseTrainRouteId?: Perhaps<string>;
  endScheduledStopPoint: ScheduledStopPoint;
  serviceLine: ServiceLineMinimized;
  startScheduledStopPoint: ScheduledStopPoint;

  /**
   * Conforms to the TrainRouteOrGroup interface
   */
  get trainRoutes(): [this] {
    return [this];
  }
}

export class TrainRouteClass extends TrainRouteMinimizedClass implements TrainRoute {
  constructor(obj: TrainRoute) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainRoute;
  orderedRailwayTrackRoutes: TrackRoute[];
  trackRoute: TrackRoute;
  version?: number;
  directionType?: DirectionType;
  orderedRoutePoints: OrderedRoutePoint[];
  routeDistance: number;
  orderedTracks: [Track];

  get lineSegments(): Feature<LineString>[] {
    return trackLineSegmentsMemoized(this.trackRoute);
  }

  get boundingBox(): BBox {
    return bboxOfTrackRouteMemoized(this.trackRoute);
  }
}

export class TrainRouteDerivedClass extends TrainRouteClass implements TrainRouteDerived {
  constructor(obj: TrainRouteDerived) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainRouteDerived;
  trainDistanceInterval?: TrainDistanceInterval | undefined;
  startScheduledStopPoint?: ScheduledStopPoint | undefined;
  endScheduledStopPoint?: ScheduledStopPoint | undefined;
}
