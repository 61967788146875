import {Cemited} from '../../types/cemited';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {map} from 'ramda';
import {clsOrType} from './clsOrType.ts';

/**
 * Calls classifyOrTypeObject on each of ts
 * @param typename
 * @param ts
 */
export const clsOrTypes = <T extends Cemited>(
  typename: CemitTypename,
  ts: (T | Omit<T, '__typename'>)[],
): T[] => {
  return map((t: T) => clsOrType<T>(typename, t), ts);
};
