/**
 * Describes Mapbox operations on sources
 */
export enum ChangeTypeEnum {
  // The source has been created
  created='created',
  // The source has last been updated with new data
  updated='updated',
  // The source was not updated because nothing changed
  unchanged='unchanged',
}