import {compose, concat, includes, keys, map, omit, without} from 'ramda';
import {trainApi} from './trainApiImplementation.ts';
import {
  TrainApiAvailableDatesRoute,
  TrainApiFormationsRoute,
  TrainApiOrganizationRoute,
  TrainApiRailwayLinesRoute,
  TrainApiServiceLinesRoute,
  TrainApiTrainRouteGroupsRoute,
  TrainApiTrainRoutesRoute,
  TrainApiTrainRunRequestParams,
  TrainApiTrainRunsRequestProps,
  TrainApiTrainRunsRoute,
} from 'types/apis/trainApi';
import {
  CemitApiRequest,
  CemitApiResponseResolver,
  CemitApiResponseResolverProps,
  CemitApiUrlResolver,
} from 'types/apis/cemitApi';
import {ServiceLine} from 'types/trainRouteGroups/serviceLine';
import {RailwayLine, RailwayLineResponse} from 'types/railways/railwayLine';
import {TrainRoute, TrainRouteResponse} from 'types/trainRouteGroups/trainRoute';
import {
  TrainRouteGroup,
  TrainRouteGroupResponse,
} from 'types/trainRouteGroups/trainRouteGroup';
import {TrainFormation, TrainFormationOutput} from 'types/trains/trainFormation';
import {TrainRun, TrainRunResponseProps} from 'types/trainRuns/trainRun';
import {cemitApiGetUrl} from '../apiConfig.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {createTrainRunFromResponse} from 'classes/typeCrud/trainRunCrud.ts';
import {createTrainGroupSingleTrainRunFromTrainRunResponse} from 'classes/typeCrud/trainGroupCrud.ts';
import {dateIntervalResponsesToDateIntervals} from 'utils/datetime/dateUtils.ts';
import {DateIntervalResponseData} from 'types/dateIntervalResponseData';
import {
  createTrainRoute,
  createTrainRouteMinimized,
} from 'classes/typeCrud/trainRouteCrud.ts';
import {createTrainRouteGroup} from 'classes/typeCrud/trainRouteGroupCrud.ts';
import {
  OrganizationLoaded,
  OrganizationMinimized,
  OrganizationResponseData,
} from 'types/organizations/organization.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';

import {DataVisualizationOption} from 'types/organizations/dataVisualizationOption.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {Error} from '@mui/icons-material';
import {createTrainFormation} from 'classes/typeCrud/trainFormationCrud.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {classifyObject} from 'appUtils/typeUtils/classifyObject.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {
  createTrainGroupOnlyTrainFormationFromTrainFormation,
  trainFormationResponseToTrainGroupOnlyTrainFormation,
} from 'classes/typeCrud/trainGroupOnlyTrainFormationCrud.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {completeLoadingStatus} from 'utils/loading/loadingStatusUtils.ts';
import {createEmptyRealtimeScopeProps} from 'classes/typeCrud/realtimeScopePropsCrud.ts';
import {createEmptyRideComfortScopeProps} from 'classes/typeCrud/rideComfortScopePropsCrud.ts';
import {createTrainGroupActivityAsInactive} from 'classes/typeCrud/trainGroupActivityCrud.ts';

/**
 * Resolves a Organization instance from the API
 * @param organization
 */
export const trainApiOrganizationsResponseResolver: CemitApiResponseResolver<
  TrainApiOrganizationRoute,
  OrganizationLoaded,
  OrganizationResponseData
> = ({data: organizations}: {data: OrganizationResponseData[]}): OrganizationLoaded[] => {
  return clsOrTypes<OrganizationLoaded>(
    CemitTypename.organization,
    map((organization: OrganizationResponseData) => {
      const views = concat(
        // Removed the disabled from all possibley views
        without(organization.frontendOptions.disabledViews || [], keys(FrontendView)),
        // Special views of the Organization, usually experimental or custom applications/trafficSimComponents
        organization.frontendOptions.views || [],
      );
      return {
        ...omit(['frontendOptions'], organization),
        frontendOptions: {
          ...omit(['views', 'disabledViews'], organization.frontendOptions),
          views,
          // TOOD temporarily hard coding trainRoute organizations to rmse and speed
          // and wheelscan organizations to timeSeries
          dataVisualizationOptions: includes(FrontendView.trainRoute, views)
            ? [DataVisualizationOption.rmse, DataVisualizationOption.speed]
            : [DataVisualizationOption.timeSeries],
        },
      } as OrganizationLoaded;
    }, organizations),
  );
};

/**
 * Resolves a Organization instance from the API
 * @param organization
 */
export const trainApiOrganizationsAdminOnlyResponseResolver: CemitApiResponseResolver<
  TrainApiOrganizationRoute,
  OrganizationLoaded,
  OrganizationResponseData
> = ({
  data: organizations,
}: {
  data: OrganizationResponseData[];
}): OrganizationMinimized[] => {
  return clsOrTypes<OrganizationMinimized>(
    CemitTypename.organizationMinimized,
    map((organization: OrganizationResponseData) => {
      return organization as OrganizationMinimized;
    }, organizations),
  );
};

/**
 * Queries for TrainRuns and puts them into TrainGroups
 * @returns {String|undefined} The url or undefined if not enough props are present to create a url.
 * Null indicates to SWR that no fetch should occur
 */
export const trainApiTrainRunsUrl: CemitApiUrlResolver<TrainApiTrainRunsRoute> = (
  trainApiRoute: TrainApiTrainRunsRoute,
  path?: string,
): Perhaps<string> => {
  return cemitApiGetUrl(trainApi, trainApiRoute, path);
};

/**
 * Resolves TrainRun instances from the API, with or without SensorDataPoints
 * @param availableDateStrings
 */
export const trainApiTrainRunsResponseResolver: CemitApiResponseResolver<
  TrainApiTrainRunsRoute
> = ({
  data: trainRuns,
  error,
  request,
}: {
  data: TrainRunResponseProps[];
  error: Error;
  request: CemitApiRequest<TrainApiTrainRunsRequestProps, TrainApiTrainRunRequestParams>;
}): TrainGroup[] => {
  if (
    request.path == 'withSensorDataPoints' ||
    request.path == 'withTrainFormationSensorDataPoints'
  ) {
    return trainApiTrainGroupWithSensorDataPointsResponseResolver({
      data: trainRuns,
      error,
      request,
    });
  } else {
    // TrainRun response
    // TODO We currently only support TrainGroupSingleTrainRun, not TrainGroup.
    // We don't yet support multiple TrainRuns per TrainGroup
    return clsOrTypes<TrainGroupSingleTrainRun>(
      CemitTypename.trainGroupSingleTrainRun,
      map((trainRunResponse: TrainRunResponseProps): TrainGroup => {
        // Create a TrainGroup with a single TrainRun
        return compose<[TrainRunResponseProps], TrainRun, TrainGroup>(
          (trainRunResponse: TrainRunResponseProps) => {
            return createTrainGroupSingleTrainRunFromTrainRunResponse(trainRunResponse);
          },
          (trainRunResponse: TrainRunResponseProps) => {
            return createTrainRunFromResponse(
              {isPreconfigured: request.props?.isPreconfigured || false},
              trainRunResponse,
            );
          },
        )(trainRunResponse);
      }, trainRuns),
    );
  }
};

/**
 * Handle Detail queryies that return SensorDataPoints
 * @param trainRunResponseProps
 * @param error
 * @param request
 */
export const trainApiTrainGroupWithSensorDataPointsResponseResolver: CemitApiResponseResolver<
  TrainApiTrainRunsRoute
> = ({
  data: trainRunResponseProps,
  error,
  request,
}: {
  data: TrainRunResponseProps[];
  error: Error;
  request: CemitApiRequest<TrainApiTrainRunsRequestProps, TrainApiTrainRunRequestParams>;
}): TrainGroup[] => {
  // TODO Hack. We currently query for TrainFormation sensor data here as well as TrainRuns
  // The interface is unified by TrainGroup on the frontend but not yet on the backend, which
  // does not have a concept of TrainGroup. Thus we instantiate TrainGroupOnlyTrainFormation
  // if request
  if (
    implementsCemitTypeViaClass(
      CemitTypename.trainGroupOnlyTrainFormation,
      request.props.sensorDataTrainGroup!.trainGroup,
    )
  ) {
    // TrainFormations to TrainGroupOnlyTrainFormations
    return clsOrTypes<TrainGroupOnlyTrainFormation>(
      CemitTypename.trainGroupOnlyTrainFormation,
      map((trainRunResponse: TrainRunResponseProps): TrainGroupOnlyTrainFormation => {
        // Create a TrainGroupOnlyTrainFormation
        return trainFormationResponseToTrainGroupOnlyTrainFormation(trainRunResponse);
      }, trainRunResponseProps),
    );
  } else if (
    implementsCemitTypeViaClass(
      CemitTypename.trainGroupSingleTrainRun,
      request.props.sensorDataTrainGroup!.trainGroup,
    )
  ) {
    const trainGroupSingleTrainRuns: TrainGroupSingleTrainRun[] = map(
      (trainRunResponse: TrainRunResponseProps): TrainGroupSingleTrainRun => {
        // TrainRuns to TrainGroupSingleTrainRuns
        return clsOrType<TrainGroupSingleTrainRun>(
          CemitTypename.trainGroupSingleTrainRun,
          {
            ...request.props.sensorDataTrainGroup!.trainGroup,
            sensorDataPoints: trainRunResponse.sensorDataPoints,
          },
        );
      },
      trainRunResponseProps,
    );
    return trainGroupSingleTrainRuns;
  } else {
    throw new Error(
      `Unexpected type ${request.props.sensorDataTrainGroup.trainGroup.__typename}`,
    );
  }
};

/**
 * Queries for the available dates of TrainRuns matching the given TrainRoute or TrainRouteGroup
 * @param trainApiRoute
 * @returns {String} The trains-api Url
 */
export const trainApiAvailableDatesUrl: CemitApiUrlResolver<
  TrainApiAvailableDatesRoute
> = (trainApiRoute: TrainApiAvailableDatesRoute): Perhaps<string> => {
  // Get the available datetime ranges for a TrainRouteOrGroup or for a TrainFormation
  return cemitApiGetUrl(trainApi, trainApiRoute);
};

/**
 * Resolves Date Interval instances from the API
 * @param availableDateStrings
 */
export const trainApiAvailableDatesResponseResolver: CemitApiResponseResolver<
  TrainApiTrainRoutesRoute,
  DateInterval[]
> = ({
  data: dateIntervalResponses,
}: {
  data: DateIntervalResponseData[];
}): DateInterval[] => {
  return dateIntervalResponsesToDateIntervals(dateIntervalResponses);
};

/**
 * Queries for the ServiceLines of the organization.operator
 * @param trainApiRoute
 * @returns {String} The trains-api Url
 */
export const trainApiServiceLinesUrl: CemitApiUrlResolver<TrainApiServiceLinesRoute> = (
  trainApiRoute: TrainApiServiceLinesRoute,
): Perhaps<string> => {
  // Get the TrainRuns in the given datetime range
  return cemitApiGetUrl(trainApi, trainApiRoute);
};

/**
 * Resolves ServiceLine instances from the API
 * @param serviceLines
 */
export const trainApiServiceLinesResponseResolver: CemitApiResponseResolver<
  TrainApiServiceLinesRoute,
  ServiceLine[]
> = ({data: serviceLines}: {data: any[]}): ServiceLine[] => {
  return serviceLines as ServiceLine[];
};

/**
 * Builds a url to query for the Railway lines
 * @param trainApiRoute
 * @returns {String} The trains-api Url
 */
export const trainApiRailwayLinesUrl: CemitApiUrlResolver<TrainApiRailwayLinesRoute> = (
  trainApiRoute: TrainApiRailwayLinesRoute,
  path?: string,
): Perhaps<string> => {
  return cemitApiGetUrl(trainApi, trainApiRoute, path);
};

/**
 * Resolves RailwayLine instances from the API
 * @param railwayLines
 * @param request
 */
export const trainApiRailwayLinesResponseResolver: CemitApiResponseResolver<
  TrainApiRailwayLinesRoute,
  RailwayLine[],
  RailwayLineResponse[]
> = ({
  data: railwayLines,
  request,
}: CemitApiResponseResolverProps<TrainApiRailwayLinesRoute>): RailwayLine[] => {
  // Handle the detail or root request
  const cemitTypeName =
    request.path == 'detail'
      ? CemitTypename.railwayLine
      : CemitTypename.railwayLineMinimized;
  return clsOrTypes<RailwayLine>(cemitTypeName, railwayLines);
};

/**
 * Builds a url to query for the TrainRoutes of the given ServiceLines
 * @param trainApiRoute
 * @returns {String} The trains-api Url or undefined if no serviceLines are given
 */
export const trainApiTrainRoutesUrl: CemitApiUrlResolver<TrainApiTrainRoutesRoute> = (
  trainApiRoute: TrainApiTrainRoutesRoute,
): Perhaps<string> => cemitApiGetUrl(trainApi, trainApiRoute);

/**
 * Resolves minimized TrainRoutes
 * @param trainRoutes
 */
export const trainApiMinimizedTrainRoutesResponseResolver: CemitApiResponseResolver<
  TrainApiTrainRoutesRoute,
  TrainRouteResponse[]
> = ({data: trainRoutes}: {data: TrainRouteResponse[]}): TrainRoute[] => {
  return map<TrainRouteResponse, TrainRoute>(
    (trainRouteResponse: TrainRouteResponse): TrainRoute => {
      return createTrainRouteMinimized(trainRouteResponse);
    },
    trainRoutes,
  );
};

export const trainApiDetailsTrainRoutesResponseResolver: CemitApiResponseResolver<
  TrainApiTrainRoutesRoute,
  TrainRouteResponse[]
> = ({data: trainRoutes}: {data: TrainRouteResponse[]}): TrainRoute[] => {
  return clsOrTypes<TrainRoute>(
    CemitTypename.trainRoute,
    map<TrainRouteResponse, TrainRoute>(
      (trainRouteResponse: TrainRouteResponse): TrainRoute => {
        return createTrainRoute(trainRouteResponse);
      },
      trainRoutes,
    ),
  );
};

/**
 * Builds a url to query trains-api for TrainRouteGroups
 * @param trainApiRoute
 * @returns {String} The trains-api Url
 */
export const trainApiTrainRouteGroupsUrl: CemitApiUrlResolver<
  TrainApiTrainRouteGroupsRoute
> = (trainApiRoute: TrainApiTrainRouteGroupsRoute): Perhaps<string> => {
  return cemitApiGetUrl(trainApi, trainApiRoute);
};

/**
 *
 * @param trainRouteGroups
 */
export const trainApiTrainRouteGroupsResponseResolver: CemitApiResponseResolver<
  TrainApiTrainRouteGroupsRoute,
  TrainRouteGroup[]
> = ({data: trainRouteGroups}: {data: TrainRouteGroupResponse[]}): TrainRouteGroup[] => {
  return clsOrTypes<TrainRouteGroup>(
    CemitTypename.trainRouteGroup,
    map<TrainRouteGroupResponse, TrainRouteGroup>(
      (trainRouteGroupResponse: TrainRouteGroupResponse) => {
        return createTrainRouteGroup(trainRouteGroupResponse);
      },
      trainRouteGroups,
    ),
  );
};

/**
 * TrainFormations are always wrapped in a TrainGroup that has no specific TrainRuns
 * @param trainFormations
 */
export const trainApiTrainFormationsResponseResolver: CemitApiResponseResolver<
  TrainApiFormationsRoute,
  TrainFormation[]
> = ({
  data: _trainFormations,
}: {
  data: TrainFormationOutput[];
}): TrainGroupOnlyTrainFormation[] => {
  return map(
    (trainFormationOutput: TrainFormationOutput): TrainGroupOnlyTrainFormation => {
      const trainFormation: TrainFormation = createTrainFormation(trainFormationOutput);
      return createTrainGroupOnlyTrainFormationFromTrainFormation(trainFormation);
    },
    _trainFormations,
  );
};
