import {callAPI} from 'pages/trafficsim/api/net/api.ts';
import {lazy} from 'react';

const Debug_data_19_06_2024 = lazy(
  () =>
    process.env.REACT_FEATURE_TRACK_SIM_DEBUG &&
    import(
      'pages/trafficsim//config/gothenburg/debug_data_snapshots/debug_data_19_06_2024.ts'
    ),
);

export const addRestriction = async (data) => {
  try {
    const path = '/api/v1/add/restriction';
    return await callAPI(path, 'POST', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const removeRestriction = async (restrictionID) => {
  try {
    // Write the event first to the local storage then make
    // HTTP request

    const path = `/api/v1/remove/restriction/${restrictionID}`;
    const apiJSONResponse = await callAPI(path, 'DELETE', {});
    // console.log(JSON.stringify(apiJSONResponse))

    // if request fails retry again after 3 trials the rollback the data
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const getCurrentRestrictions = async () => {
  try {
    // Write the event first to the local storage then make
    // HTTP request

    const path = `/api/v1/current/restrictions`;
    // console.log(JSON.stringify(apiJSONResponse))
    // update local storage here;

    return await callAPI(path, 'GET', {});
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const getUpcomingRestrictions = async () => {
  try {
    // Write the event first to the local storage then make
    // HTTP request

    const path = `/api/v1/upcoming/restrictions`;
    // console.log(JSON.stringify(apiJSONResponse))
    // update local storage here;

    return await callAPI(path, 'GET', {});
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const getMapData = async (useDebugData = false) => {
  if (useDebugData) return Debug_data_19_06_2024;
  try {
    const path = `/api/v1/preview/map/data`;
    return await callAPI(path, 'GET', {});
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateRemoteFeedPointData = async (feedPointId, data) => {
  try {
    const path = `/api/v1/feedpoint/${feedPointId}`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateRemoteOrphanFeedPointData = async (data) => {
  try {
    const path = `/api/v1/batch/feedpoints`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateBatchRemoteSectionData = async (data) => {
  try {
    const path = `/api/v1/batch/sections`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateRemoteSectionData = async (id, data) => {
  try {
    const path = `/api/v1/section/${id}`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateRemoteStationData = async (id, data) => {
  try {
    const path = `/api/v1/station/${id}`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateBatchRemoteStationData = async (data) => {
  try {
    const path = `/api/v1/batch/stations`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const deleteRemoteRestriction = async (data) => {
  try {
    const path = `/api/v1/restriction/${data.id}`;
    return await callAPI(path, 'DELETE', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const updateRemoteRestriction = async (data) => {
  try {
    const path = `/api/v1/restriction/${data.id}`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const fetchRemoteRestrictionHistory = async () => {
  try {
    const path = `/api/v1/restrictions/event/log`;
    return await callAPI(path, 'GET');
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const searchRemoteRestrictionHistory = async (data) => {
  try {
    const path = `/api/v1/search/restrictions/event/log`;
    return await callAPI(path, 'POST', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};

export const batchUpdateRemoteMapObjects = async (data) => {
  try {
    const path = `/api/v1/batch/update/map_objects`;
    return await callAPI(path, 'PUT', data);
  } catch (e) {
    console.log(`API error ${e}`);
    throw e;
  }
};
