import {
  compose,
  cond,
  equals,
  forEach,
  fromPairs,
  identity,
  lensProp,
  map,
  mergeRight,
  over,
  pick,
  prop,
  T,
  values,
} from 'ramda';
import {compact} from '@rescapes/ramda';
import {getType} from '@turf/invariant';
import center from '@turf/center';
import {TrainProps} from '../../types/propTypes/trainPropTypes/trainProps';
import {
  ChartPayloadItem,
  ChartPayloadItemDerived,
  ChartPayloadItemMinimized,
} from '../../types/dataVisualizations/chartPayloadItem';
import {Map} from 'mapbox-gl';
import {FeatureCollection, Feature, Point} from 'geojson';
import {singlePointFeatureFromPayload} from '../dataFeatures/dataFeaturePayloadUtils.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {CEMIT_ORANGE, CEMIT_YELLOW} from '../../theme/cemitColors.ts';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {differenceInMilliseconds, differenceInSeconds, sub} from 'date-fns';
import {performanceWrapper} from '../profiling/profilingUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {
  CHART_HOVER_TRAIN_GROUP_LAYER_PREFIX,
  CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX,
} from 'config/appConfigs/trainConfigs/trainConfig.ts';

/**
 * Responds to a map or chart hover,
 * telling the app what feature is hovered over for the given TrainGroup
 * @param appProps
 * @param trainProps
 * @param payloadItems
 * @returns
 */
export const updateHoverFeatureFromPayloadItems = (
  {
    trainProps,
  }: {
    trainProps: TrainProps;
  },
  payloadItems: ChartPayloadItemDerived[],
): void => {
  const feature = singlePointFeatureFromPayload(payloadItems);

  const trainGroup = payloadItems?.[0]?.dataFeatureCollection.dataFeatureSet.trainGroup;
  if (trainGroup) {
    trainProps.trainGroupSingleTrainRunProps.chartMapInteractionProps!.setHoverFeature(
      (existing: Feature) => {
        const incoming = {[trainGroup.id]: feature};
        // Merge new keys with the current values to handle hovering over multiple TrainGroups
        const merged = mergeRight(existing, incoming);
        return values(merged);
      },
    );
  }
};

/**
 * Responds to hover ending on the map and clears the hover feature for the given TrainGroup
 */
export const removeCursorFromTrainMap = (
  setHoverFeature: StateSetter<Perhaps<Feature>>,
) => {
  setHoverFeature(() => {
    // TODO not sure if this is correct
    return undefined;
  });
};

export const removeCursorFromChart = (trainMap: Perhaps<Map>) => () => {
  if (!trainMap) {
    return;
  }
  if (trainMap.getSource(CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX)) {
    trainMap.removeLayer(CHART_HOVER_TRAIN_GROUP_LAYER_PREFIX);
    trainMap.removeSource(CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX);
  }
};

/**
 * When the user hovers on the chart,
 * update the hoverFeature with updateHoverFeatureFromPayloadItems and
 * update the layer on the trainMap to match the user's hover position on the chart.
 * @parma payload  Contains the most recent payload from hovering on the dataVisualizations
 * @param trainMap
 * @returns {(function(*): void)|*}
 */
export const createMoveCursorAlongChartLine = (
  trainMap: Map,
  activeTrainGroups: TrainGroup[],
): ((
  {
    trainProps,
  }: {
    trainProps: TrainProps;
  },
  payloadItems: ChartPayloadItemDerived[],
) => void) => {
  return (
    {
      trainProps,
    }: {
      trainProps: TrainProps;
    },
    payloadItems: ChartPayloadItemDerived[],
  ) => {
    const trainGroup = payloadItems?.[0]?.dataFeatureCollection.dataFeatureSet.trainGroup;
    if (trainGroup) {
      // Update the chart's TrainRunLine hash mark
      updateHoverFeatureFromPayloadItems({trainProps}, payloadItems);
    }

    // Get the Point Feature from each payload item
    const featureCollection: FeatureCollection | undefined =
      featureCollectionFromPayload(payloadItems);
    if (!featureCollection) {
      return;
    }

    forEach((activeTrainGroup: TrainGroup) => {
      // Update the data if the source exists
      if (trainMap.getSource(CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX)) {
        // @ts-ignore
        trainMap.getSource(CHART_HOVER_SOURCE).setData(featureCollection);
        return;
      }
      // Otherwise create the source and layer
      trainMap.addSource(CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX, {
        type: 'geojson',
        data: featureCollection,
      });
      trainMap.addLayer({
        id: CHART_HOVER_TRAIN_GROUP_LAYER_PREFIX,
        type: 'circle',
        source: CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX,
        paint: {
          'circle-color': 'rgba(0,0,0,0)',
          'circle-radius': 13,
          'circle-stroke-color': CEMIT_YELLOW,
          'circle-stroke-width': 2.5,
        },
      });
    }, activeTrainGroups);
  };
};
const addIdToFeature = (feature: Feature) => {
  return over(lensProp('id'), (id: string) => id || '1', feature);
};

/**
 * Creates a FeatureCollection from the first item of a recharts payload. If the payload has no items, undefined is returned
 * @param payloadItems
 * @returns {*|{features: *[], type: string}}
 */
export const singleItemFeatureCollectionFromPayload = (
  payloadItems: ChartPayloadItem[],
) => {
  const feature = singlePointFeatureFromPayload(payloadItems);
  return (
    feature && {
      type: 'FeatureCollection',
      // Set an id if needed for picky geojson viewers
      features: [addIdToFeature(feature)],
    }
  );
};
/**
 * Convert the payloadItems[*].payload features to a FeatureCollection
 * @param payloadItems
 * @returns A FeatureCollection or undefined if payloadItems is undefined
 */
export const featureCollectionFromPayload = (
  payloadItems: ChartPayloadItem[],
): FeatureCollection | undefined => {
  return payloadItems
    ? {
        type: 'FeatureCollection',
        features: map((payloadItem) => {
          return compose(addIdToFeature, prop('payload'))(payloadItem);
        }, payloadItems),
      }
    : undefined;
};

/**
 * Converts the payloadItem.paylaod feature to a Point feature if it isn't one, preserving
 * the properties, id, and centroid of the feature
 * @param payloadItem
 * @returns {*}
 */
export const pointFeatureFromPayLoadItem = (
  payloadItem: ChartPayloadItemMinimized,
): Feature<Point> => {
  // Converts the feature to a Point feature, preserving the given attributes if defined
  const featureToPoint = (feature: Feature) => {
    return center(feature, pick(['properties', 'centroid', 'id'], feature));
  };
  // Return the point or the center Point of a LineString or Polygon

  return cond([
    [compose(equals('Polygon'), getType), featureToPoint],
    [compose(equals('Linestring'), getType), featureToPoint],
    // Leave it alone
    [compose(equals('Point'), getType), identity],
    [
      T,
      (feature: Feature) => {
        throw Error(
          `Feature expected to be a Polygon or Point, but got a ${getType(feature)}`,
        );
      },
    ],
  ])(payloadItem.payload);
};
