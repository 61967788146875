import {Expression} from 'mapbox-gl';
import {ascend, chain, concat, length, map, sortWith} from 'ramda';
import {
  DataThreshold,
  DataThresholdStyles,
  StepValue,
  ZoomLevelValue,
} from '../../types/dataVisualizations/dataThreshold';
import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * Given a strProp that resolves to a string and a fragment string, creates a Mapbox property expression
 * to test if the fragment is in the evaluated strExpression
 * @param fragment A Mapbox Expression
 * @param strProp
 * @return The resulting Mapbox Expression
 */
export const mapboxIncludesStrProp = (fragment: Expression, strProp: string) => {
  return mapboxIncludes(fragment, ['get', strProp]);
};

/**
 * Calls in on an expression that evaluates to a string to see if the given string fragment is in it
 * @param fragment
 * @param strExpr
 * @return The expression
 */
export const mapboxIncludes = (fragment: Expression, strExpr: Expression): Expression => {
  return ['in', fragment, strExpr];
};

/***
 * Creates an equality expression for the given prop and value
 * @param prop
 * @param value The prop value or Mapbox Expression
 * @return The Expression
 */
export const mapboxPropEq = (
  prop: string,
  value: string | number | undefined,
): Expression => {
  return ['==', ['get', prop], value];
};

/**
 * Creates a Mapbox case statement corresponding to an if/else
 * @param expression The Mapbox expression to test for truth
 * @param trueExpression An expression to evaluate on true, such as a simple string value to return
 * @param falseExpression An expression to evalutate on true, such as a simple string value to return
 * @return Expression
 */
export const mapboxIfElse = (
  expression: Expression,
  trueExpression: Expression,
  falseExpression: Expression,
): Expression => {
  return ['case', expression, trueExpression, falseExpression];
};

/**
 * Creates Mapbox extrusion color levels based on the dataThresholds
 * @param dataThresholds Data thresholds. It's assumed
 * that the dataThresdholds are ordered numerically from lowest to highest (e.g. meaning from acceptable to unacceptable)
 * @param attribute
 * @param noDefault Don't start with a default value in the array. This is always true if a fallback is given
 * @param fallback
 * @returns {(number|*|string|number)[]}
 */
export const dataThresholdsToMapboxAttributeLevels = (
  dataThresholds: DataThreshold[],
  attribute: keyof DataThresholdStyles = 'color',
  noDefault: boolean = false,
  fallback: Perhaps<number | string> = undefined,
): (number | string | ZoomLevelValue[])[] => {
  // Creates [
  // value1, // stop expression only
  // dataThreshold1 value2
  // dataThreshold1 color
  // dataThreshold2 value
  // dataThreshold2 color
  // ...,
  // fallback // match expression only
  // ]
  return [
    // If the value is 0 to dataThresholds[0].value, output dataThresholds[0].style[attribute]
    ...(!(noDefault || fallback) ? [dataThresholds[0].style[attribute]] : []),
    // Iterate through dataThresholds and map to [value, color], which
    // we use chain to flatten for Mapbox's ridiculous syntax
    ...chain((dataThreshold) => {
      return [dataThreshold.value, dataThreshold.style[attribute]];
    }, dataThresholds),
    // If a fallback is needed for a match expression
    ...(fallback ? [fallback] : []),
  ];
};
/**
 * Creates stops based on the dataThresholds and the zoom and attribute
 * https://blog.mapbox.com/introducing-data-driven-styling-in-mapbox-gl-js-f273121143c3
 * @param dataThresholds
 * @param attribute
 */
export const dataThresholdsToMapBoxZoomAttributeLevels = (
  dataThresholds: DataThreshold[],
  attribute: keyof DataThresholdStyles = 'color',
) => {
  // For stops,
  // Creates [
  //     [{zoom: 8, value: 0}, 5],
  //     [{zoom: 8, value: 1}, 10],
  //     [{zoom: 11, value: 0}, 20],
  //     [{zoom: 11, value: 1}, 40],
  //     [{zoom: 16, value: 0}, 80],
  //     [{zoom: 16, value: 1}, 160]
  // ]
  // where value is the stop value and the final number in each array is the desired value of the
  // specified property, such as circle-radius, color, etc
  const values: StepValue[] = chain((dataThreshold: DataThreshold) => {
    return map((attributeValue: ZoomLevelValue) => {
      return [
        {zoom: attributeValue.zoom, value: dataThreshold.value},
        attributeValue.outputValue,
      ] as StepValue;
    }, dataThreshold.style[attribute] as ZoomLevelValue[]);
  }, dataThresholds);
  // Make sure all values below a singleton dataThreshold.value are not shown
  const allValues =
    length(dataThresholds) == 1
      ? concat(values, [
          // [{zoom: 0, value: dataThresholds[0].value - 0.01}, 0],
          // [{zoom: 22, value: dataThresholds[0].value - 0.01}, 0],
        ])
      : values;
  // Sort ascending by zoom and then value
  return sortWith([
    ascend((value: StepValue) => {
      return value[0].zoom;
    }),
    ascend((value: StepValue) => {
      return value[0].value;
    }),
  ])(allValues);
};
