import { OrderedRoutePoint, OrderedRoutePointDerived } from '../../types/trainRouteGroups/orderedRoutePoint';
import { cemitedConstructor } from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import { CemitTypename } from '../../types/cemitTypename.ts';
import { int } from 'utrie/dist/types/Trie';
import { RoutePoint, RoutePointDerived } from '../../types/trainRouteGroups/routePoint';
import { RailwayLineMinimized } from '../../types/railways/railwayLine';
import { ScheduledStopPoint } from '../../types/stops/scheduledStopPoint';

import {IdentifiedClass} from "../cemitAppCemitedClasses/identifiedClass.ts";

export class RoutePointClass extends IdentifiedClass implements RoutePoint {

  constructor(obj: RoutePoint) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.routePoint;
  version: number;
  railwayLines: RailwayLineMinimized[];
  scheduledStopPoint: ScheduledStopPoint;
}

export class RoutePointDerivedClass extends RoutePointClass implements RoutePointDerived {
  constructor(obj: RoutePointDerived) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.routePointDerived;
  routeDistance: number;
}

export class OrderedRoutePointClass extends IdentifiedClass implements OrderedRoutePoint {

  constructor(obj: OrderedRoutePoint) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.orderedRoutePoint;
  version?: int;
  order: int;
  routePoint: RoutePoint;
}

export class OrderedRoutePointDerivedClass extends OrderedRoutePointClass implements OrderedRoutePointDerived {

  constructor(obj: OrderedRoutePointDerived) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.orderedRoutePointDerived;
  routePoint: RoutePointDerived;
}
