import {alpha, Components} from '@mui/material';
import {Theme} from '@mui/system';
import LinkBehavior from 'theme/LinkBehavior.tsx';
import {CEMIT_GRAY_ON_DARK, CEMIT_WHITE, CEMIT_YELLOW} from 'theme/cemitColors.ts';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiLink: {
    // https://mui.com/material-ui/guides/routing/
    defaultProps: {
      component: LinkBehavior,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        color: CEMIT_WHITE,
        fontSize: 12,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: CEMIT_WHITE,
        '&:hover': {
          backgroundColor: CEMIT_GRAY_ON_DARK,
        },
      },
    },
  },
  MuiSvgIcon: {
    variants: [
      {
        // @ts-expect-error meh
        props: {variant: 'tiny'},
        style: {
          fontSize: 20,
          margin: 0,
        },
      },
    ],
  },
  MuiAccordion: {
    styleOverrides: {
      // Name of the slot
      root: {
        padding: 0,
        backgroundColor: 'inherit',
        boxShadow: 'none',
        ':before': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      // Name of the slot
      root: {
        padding: 0,
        minHeight: 0,
        boxShadow: 'none',
        // The moves the expander to be left of the label
        flexDirection: 'row-reverse',
      },
      expandIconWrapper: {
        color: CEMIT_WHITE,
      },
      content: {
        margin: 0,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        px: 0,
        color: CEMIT_WHITE,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        color: CEMIT_WHITE,
        '&.Mui-selected': {
          backgroundColor: alpha(CEMIT_YELLOW, 0.25),
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        justifyContent: 'left',
        color: CEMIT_WHITE,
        '&.Mui-active': {
          color: CEMIT_WHITE,
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        flexDirection: 'row',
        color: CEMIT_WHITE,
        '&.Mui-active': {
          color: CEMIT_WHITE,
          '.MuiTableSortLabel-icon': {
            color: CEMIT_WHITE,
          },
        },
        '&:hover': {
          color: CEMIT_WHITE,
        },
      },
      icon: {
        color: CEMIT_WHITE,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        textAlign: 'left',
        color: CEMIT_WHITE,
        padding: '5px',
      },
      head: {color: CEMIT_WHITE},
      body: {color: CEMIT_WHITE},
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        '& .MuiLinearProgress-bar': {
          transition: 'none',
        },
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {},
      bar: {
        borderRadius: 5,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        color: CEMIT_WHITE,
        '& .MuiTablePagination-toolbar': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        color: CEMIT_WHITE,
        '& .MuiTablePagination-toolbar': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      selectLabel: {
        color: CEMIT_WHITE,
      },
      selectIcon: {
        color: CEMIT_WHITE,
      },
      menuItem: {
        color: CEMIT_WHITE,
      },
    },
  },
  MuiCssBaseline: {
    '@global': {
      body: {
        transition: 'background-color 0.3s ease, color 0.3s ease',
      },
    },
  },
};
