import {equals, includes, is, map, length} from 'ramda';
import React, {SyntheticEvent, useMemo, useState} from 'react';
import {Button, Stack, Typography, Menu, MenuItem} from '@mui/material';
import {Timer} from '@mui/icons-material';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {cemitColors} from 'theme/cemitColors.ts';
import {TFunction} from 'i18next';
import {PeriodEnum} from 'types/alerts/periodEnum.ts';

/**
 * Chooser to select an interval between which to show alerts leading up to
 * the time chosen in the DateTime picker component (e.g. 24hr, 1hr, or 15min)
 * @param t The translation function
 * @param dateIntervalDescription
 * @param setDateIntervalDescription
 * @param dateIntervalDescriptions
 * @param trainRunIntervalDescription
 * @param setAlertTimePeriodForMap
 * @constructor
 */
const AlertIntervalPicker = ({
  t,
  dateIntervalDescription,
  setDateIntervalDescription,
  dateIntervalDescriptions,
  trainRunIntervalDescription,
  setAlertTimePeriodForMap,
}: {
  t: TFunction;
  dateIntervalDescription: DateIntervalDescription;
  setDateIntervalDescription: StateSetter<DateIntervalDescription>;
  dateIntervalDescriptions: DateIntervalDescription[];
  trainRunIntervalDescription: DateIntervalDescription;
  setAlertTimePeriodForMap: StateSetter<DateIntervalDescription>;
}) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = (
    _e: SyntheticEvent,
    dateIntervalDescription: DateIntervalDescription,
  ) => {
    setAnchorEl(undefined);
    // if interval comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, dateIntervalDescription)) {
      // setAlertTimePeriodForMap needs to be set back to the default if
      // the shortest interval is chosen since the shortest only shows the shortest period in the chart
      if (
        dateIntervalDescription.allowsPeriods &&
        !includes(PeriodEnum.week, dateIntervalDescription.allowsPeriods)
      ) {
        setAlertTimePeriodForMap(PeriodEnum.today);
      }
      setDateIntervalDescription(dateIntervalDescription);
    }
  };
  const intervalComponents = useMemo<JSX.Element[]>(() => {
    return map(
      (interval: DateIntervalDescription) => {
        const label = `${interval.label} ${!interval.shortenDurationText ? `(${interval.durationText})` : ''}`;
        return (
          <MenuItem
            key={interval.label}
            {...{
              disabled:
                Boolean(trainRunIntervalDescription) ||
                equals(interval, dateIntervalDescription),
              onClick: (e: SyntheticEvent) => onClose(e, interval),
            }}
          >
            {label}
          </MenuItem>
        );
      },
      // Only one option if trainRunIntervalDescription
      trainRunIntervalDescription
        ? [trainRunIntervalDescription]
        : dateIntervalDescriptions,
    );
  }, [dateIntervalDescriptions, dateIntervalDescription, trainRunIntervalDescription]);
  const buttionIntervalDescription =
    trainRunIntervalDescription || dateIntervalDescription;
  const label = `${buttionIntervalDescription.label} ${!buttionIntervalDescription.shortenDurationText ? `(${buttionIntervalDescription.durationText})` : ''}`;

  return (
    <Stack
      {...{
        sx: {
          alignSelf: 'self-start',
          // Don't let the width change when a different values is selected
          minWidth: '185px',
          maxWidth: '185px',
        },
      }}
    >
      <Typography
        {...{
          sx: {
            color: cemitColors.white,
            fontSize: '0.8rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
          },
        }}
      >
        {t('interval')}
      </Typography>
      {length(intervalComponents) == 1 ? (
        <Stack
          {...{
            direction: 'row',
            spacing: 1,
            sx: {alignItems: 'center', minHeight: '36px'},
          }}
        >
          <Timer key="icon" {...{color: 'secondary'}} />
          <Typography key="icon" {...{color: 'secondary'}}>
            {label}
          </Typography>
        </Stack>
      ) : (
        <Button
          key="button"
          {...{
            sx: {justifyContent: 'left', paddingLeft: 0},
            id: 'interval-button',
            color: 'secondary',
            'aria-controls': open ? 'interval-positioned-menu' : undefined,
            'aria-haspopup': 'true',
            'aria-expanded': open ? 'true' : undefined,
            onClick: handleClick,
          }}
        >
          <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center'}}}>
            <Timer key="icon" />
            <Typography key="label">{label}</Typography>
          </Stack>
        </Button>
      )}
      <Menu
        key="menu"
        {...{
          id: 'formation-positioned-menu',
          'aria-labelledby': 'formation-button',
          anchorEl,
          open,
          onClose,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {intervalComponents}
      </Menu>
    </Stack>
  );
};
AlertIntervalPicker.displayName = 'AlertIntervalPicker';
export default AlertIntervalPicker;
