import {TrainGroupTableProps} from 'types/propTypes/trainPropTypes/trainGroupTableProps';
import {lensPath, map, pick} from 'ramda';
import {alpha, List, ListItem, Pagination, Paper} from '@mui/material';
import * as React from 'react';
import {memo} from 'react';
import {CEMIT_WHITE, CEMIT_YELLOW} from '../../../../../theme/cemitColors.ts';
import {SingleTrainGroupTrainProps} from '../../../../../types/propTypes/trainPropTypes/trainProps';
import TrainGroupSingleTrainRunContainer from '../../chooserComponents/trainRunChooserComponents/TrainGroupSingleTrainRunContainer.tsx';
import {setClassOrType} from '../../../../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {TrainRunComponentProps} from '../../chooserComponents/trainRunChooserComponents/TrainGroupSingleTrainRun.tsx';
import {CemitTypename} from '../../../../../types/cemitTypename.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {PartialCemited} from '../../../../../types/cemited';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';

/**
 * A list of TrainGroupSingleTrainRuns
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainGroupsViewList = memo(
  ({
    appProps,
    organizationProps,
    trainProps,
    componentProps,
  }: TrainAppTrainComponentDependencyProps<TrainGroupTableProps>) => {
    const {handleClickTableRow, onPageChange, isSelected, visibleRows, page, pageCount} =
      componentProps;

    const listRows = map((trainGroup: TrainGroup) => {
      const isItemSelected = isSelected(trainGroup);

      // Set the trainProps.filteredTrainGroupProps.filteredTrainGroup to trainGroup
      // This scopes the child trafficSimComponents to the TrainGroupSingleTrainRun instance
      const singleTrainGroupTrainProps: SingleTrainGroupTrainProps =
        clsOrType<SingleTrainGroupTrainProps>(
          CemitTypename.singleTrainFormationTrainProps,
          setClassOrType(
            lensPath(['filteredTrainGroupProps', 'filteredTrainGroup']),
            trainGroup,
            trainProps,
          ),
        );

      const trainRunComponentProps = clsOrType<PartialCemited<TrainRunComponentProps>>(
        CemitTypename.trainRunComponentProps,
        pick(['serviceLineIdToColor'], componentProps),
      );
      return (
        <ListItem
          key={trainGroup.id}
          {...{
            disableGutters: true,
            sx: {
              borderBottom: '1px solid rgba(255, 255, 255, 0.25)',
              padding: 0,
              cursor: 'pointer',
              color: 'secondary',
              backgroundColor: isItemSelected ? alpha(CEMIT_YELLOW, 0.25) : undefined,
              // // selected and (selected + hover) states
              // '&& .Mui-selected, && .Mui-selected:hover': {
              //   bgcolor: 'red',
              //   '&, & .MuiListItemIcon-root': {
              //     color: alpha(CEMIT_YELLOW, 0.5),
              //   },
              // },
              // hover states
              '& .MuiListItemButton-root:hover': {
                bgcolor: alpha(CEMIT_YELLOW, 0.25),
                '&, & .MuiListItemIcon-root': {
                  color: alpha(CEMIT_YELLOW, 0.25),
                },
              },
            },
            onClick: (_event: React.MouseEvent<HTMLTableRowElement>) => {
              handleClickTableRow(trainGroup);
            },
          }}
        >
          <TrainGroupSingleTrainRunContainer
            key={trainGroup.id}
            {...{
              appProps,
              organizationProps,
              trainProps: singleTrainGroupTrainProps,
              componentProps: trainRunComponentProps,
            }}
          />
        </ListItem>
      );
    }, visibleRows);
    return (
      <Paper
        style={{
          overflow: 'auto' /**/,
          fontSize: '0.875rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <List>{listRows}</List>
        {pageCount > 1 ? (
          <Pagination
            {...{
              color: 'primary',
              sx: {
                '& .MuiPaginationItem-root': {
                  color: CEMIT_WHITE,
                },
              },
              count: pageCount,
              page: page + 1,
              size: 'small',
              onChange: (
                event: React.MouseEvent<HTMLButtonElement> | undefined,
                newPage: number,
              ) => {
                // Comes in 1-based instead of 0-based
                onPageChange(event, newPage - 1);
              },
            }}
          />
        ) : undefined}
      </Paper>
    );
  },
);
CemitFilterTrainGroupsViewList.displayName = 'TrainGroupList';
export default CemitFilterTrainGroupsViewList;
