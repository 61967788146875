import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {useCemitApiSwrResolveData} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {ServiceLine} from '../../../../types/trainRouteGroups/serviceLine';
import {OrganizationLoaded} from '../../../../types/organizations/organization.ts';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';

/**
 * Sets the service lines from organization.lines whenever the organization changes
 * TODO this might go to the API in the future if organization only retures ids
 * @param loading Do nothing if true
 * @param organization The organization
 * @param setServiceLines Setter for the ServiceLines
 */
export const useConfiguredApiForServiceLines = ({
  loading,
  organization,
  setServiceLines,
}: {
  loading: boolean;
  organization: Perhaps<OrganizationLoaded>;
  setServiceLines: StateSetter<ServiceLine[]>;
}) => {
  const {
    data: serviceLines,
    isLoading,
    isValidating,
  } = useCemitApiSwrResolveData(loading, organization, 'serviceLines', {organization});
  useNotLoadingEffect(
    loading || isLoading || isValidating,
    () => {
      if (serviceLines) {
        setServiceLines(serviceLines);
      }
    },
    [serviceLines],
  );
};
