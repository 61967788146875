import {TrainRun} from 'types/trainRuns/trainRun';
import {CemitedClass} from 'classes/cemitAppCemitedClasses/cemitedClass.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {cemitedConstructor} from 'classes/cemitAppCemitedClasses/cemitClassConstructor.ts';
import {ScheduledStopPoint} from 'types/stops/scheduledStopPoint';
import {JourneyPattern} from 'types/trainRouteGroups/journeyPattern';
import {ServiceLine} from 'types/trainRouteGroups/serviceLine';
import {TrainRunSource} from 'types/trainRuns/trainRunSource';
import {TimetabledPassingDateTime} from 'types/timetables/timetabledPassingDateTime';
import {TrainFormation, TrainFormationMinimized} from 'types/trains/trainFormation';
import {TrainRouteMinimized} from 'types/trainRouteGroups/trainRoute';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * Basic implementation of a TrainRun
 */
export class TrainRunClass extends CemitedClass implements TrainRun {
  declare __typename: CemitTypename.trainRun;

  constructor(obj: TrainRun) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  get trainRoute(): TrainRouteMinimized {
    // This is a minimum version of TrainRoute to reference the full version
    // TOOD we should be able set the journeyPattern and trainRoute to fully loaded versions
    return clsOrType<TrainRouteMinimized>(
      CemitTypename.trainRouteMinimized,
      this.journeyPattern.trainRoute,
    );
  }

  arrivalDatetime: Date;
  departureDatetime: Date;
  endScheduledStopPoint: ScheduledStopPoint;
  id: string;
  journeyPattern: JourneyPattern;
  serviceLine: ServiceLine;
  source: TrainRunSource;
  sourceId: string;
  startScheduledStopPoint: ScheduledStopPoint;
  timetabledPassingDatetimes: TimetabledPassingDateTime[];
  trainFormation: Perhaps<TrainFormationMinimized | TrainFormation>;
}
