/**
 * Shows the start and end of the current TrainPage route.
 * In the future it will be possible to click the arrow to reverse the route
 * and click the start and end stations to change them
 * @param trainProps
 * @param trainProps.trainRouteGroupProps
 * @param trainProps.trainRouteGroupProps.trainRoute
 * @param componentProps.reverseFormation Function to reverse the route when the arrow is clicked
 * @param componentProps.chooseFormation Function to bring up to choose the route
 * @returns {JSX.Element}
 */
import React from 'react';
import TrainFormationMenu from './TrainFormationMenu.tsx';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {CemitComponentProps} from '../../../../../types/propTypes/cemitComponenProps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

export interface TrainFormationChooserProps extends CemitComponentProps {
  trainGroupFormations: TrainGroupOnlyTrainFormation[];
  chosenTrainGroupOnlyTrainFormations: TrainGroupOnlyTrainFormation[];
  handleAddTrainGroupOnlyTrainFormationToFilter: (
    trainGroupFormation: TrainGroupOnlyTrainFormation,
  ) => void;
  // Show only this as selected among chosenTrainGroupOnlyTrainFormations
  scopedTrainGroup: Perhaps<TrainGroupOnlyTrainFormation>;
  // Show an add icon and have the selected item from the menu be added to chosenTrainGroupOnlyTrainFormations
  addTrainGroupMode: Perhaps<boolean>;
  // Max number of TrainGroups allowed. addTrainGroupMode is disabled
  maximumAllowed: Perhaps<number>;
  // Disables the chooser
  disabled: Perhaps<boolean>;
}

/***
 * Shows a menu that allows adding a Formation to the FormationFilter
 * @constructor
 */
const TrainFormationChooser = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<TrainFormationChooserProps>): JSX.Element => {
  return (
    <TrainFormationMenu
      {...{
        appProps,
        trainProps,
        componentProps,
      }}
    />
  );
};

export default TrainFormationChooser;
