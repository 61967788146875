import {useTranslation} from 'react-i18next';
import {map} from 'ramda';
import {CemitAppNavigationItems} from 'components/apps/cemitAppComponents/CemitAppNavigationItems.tsx';
import React, {useContext, useMemo} from 'react';
import {
  ApplicationNavigation,
  ApplicationNavigationDerived,
} from 'types/navigation/applicationNavigation';
import {
  hasAccess,
  hasApplicationAccess,
} from 'appUtils/authentication/authenticationUtils.ts';
import {applicationNavigationConfig} from 'config/appConfigs/appConfig.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {compact, mergeRightIfDefined} from 'utils/functional/functionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

type CemitAppNavigationItemsContainerProps = {
  currentPath: string;
};

/**
 * @param currentPath
 * @return {JSX.Element}
 * @constructor
 */
const CemitAppNavigationItemsContainer = ({
  currentPath,
}: CemitAppNavigationItemsContainerProps) => {
  const {t} = useTranslation();
  const {userState} = useContext(UserContext) as UserContextType;
  if (userState.status !== AccessStatus.Authenticated) return <></>;

  const applicationNavigations: ApplicationNavigation[] = applicationNavigationConfig(t);

  // Get all the apps, unless applicationNavigation.isHiddenWithoutAccessKey is true
  // and the user doesn't have access. The apps are enabled or disabled based on access
  const apps: Record<string, ApplicationNavigation> = useMemo(() => {
    return compact(
      map<ApplicationNavigation, Perhaps<ApplicationNavigationDerived>>(
        (applicationNavigation: ApplicationNavigation): ApplicationNavigationDerived => {
          // Is admin or user has access
          const doesHaveAccess = applicationNavigation.accessKey
            ? hasAccess(applicationNavigation.accessKey, userState)
            : true;
          // Ignores admin access
          const doesHaveApplicationAccess = applicationNavigation.accessKey
            ? hasApplicationAccess(applicationNavigation.accessKey, userState)
            : true;
          if (
            applicationNavigation?.isHiddenWithoutAccessKey &&
            !doesHaveApplicationAccess
          ) {
            return undefined;
          }
          return clsOrType<ApplicationNavigation>(
            CemitTypename.applicationNavigation,
            mergeRightIfDefined(applicationNavigation, {
              hasAccess: doesHaveAccess,
            }),
          );
        },
        applicationNavigations,
      ),
    );
  }, [userState]);

  return (
    <CemitAppNavigationItems
      {...{
        currentPath,
        apps,
      }}
    />
  );
};
export default CemitAppNavigationItemsContainer;
