import {colorHue} from 'theme/colors/colorHue/colorHue.ts';

export const TextColor = {
  // Light Mode

  light: {
    text: {
      primary: colorHue.grey[800],
      secondary: colorHue.grey[700],
      tertiary: colorHue.grey[400],
      link: colorHue.grey[800],
      destructive: colorHue.red[500],
      reversed: colorHue.grey[50],
      light: colorHue.grey[50],
      dark: colorHue.grey[800],
      gold: colorHue.gold[700],
    },
  },
  // Dark Mode
  dark: {
    text: {
      primary: colorHue.grey[100],
      secondary: colorHue.grey[300],
      tertiary: colorHue.grey[200],
      link: colorHue.gold[300],
      destructive: colorHue.red[400],
      reversed: colorHue.grey[800],
      light: colorHue.grey[50],
      dark: colorHue.grey[800],
      gold: colorHue.gold[600],
    },
  },
};
