import {CemitFilterTypeTest} from '../../../../types/cemitFilters/cemitFilterTypeTest';
import {CemitFilterTypeEval} from '../../../../types/filters/filterTypeEval';
import {CemitFilter} from '../../../../types/cemitFilters/cemitFilter';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {useNotLoadingEffect} from '../../../../utils/hooks/useMemoHooks.ts';
import {mergeCemitFilterOnParentUpdate} from '../../../../appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {equals, head} from 'ramda';
import {PerhapsIfLoading} from '../../../../types/logic/requireIfLoaded.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {extractDateIntervals} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {dumpDateInterval} from 'utils/datetime/dateUtils.ts';

/**
 * Updates cemitFilter to have the given parentCemitFilter with setCemitFilter if
 * cemitFilter.parent does not shallow equals parentCemitFilter.
 * The parentCemitFilter is then merged in using mergeCemitFilters
 * @param loading
 * @param parentCemitFilter
 * @param cemitFilter
 * @param setCemitFilter
 */
export const useNotEffectUpdateCemitFiltersParentIfNeeded = (
  loading: boolean,
  parentCemitFilter: Perhaps<CemitFilter>,
  cemitFilter: PerhapsIfLoading<typeof loading, CemitFilter>,
  setCemitFilter: StateSetter<CemitFilter>,
) => {
  // Whenever the parentCemitFilter is different than cemitFilter.parent, update this CemitFilter
  useNotLoadingEffect(
    loading || !cemitFilter,
    (parentCemitFilter, cemitFilter) => {
      if (
        cemitFilter.parent == parentCemitFilter ||
        equals(cemitFilter.parent, parentCemitFilter)
      ) {
        return;
      }
      const updatedCemitFilter = mergeCemitFilterOnParentUpdate(
        parentCemitFilter,
        cemitFilter,
      );
      if (!equals(updatedCemitFilter, cemitFilter)) {
        if ((cemitFilter.name || '').startsWith('trainGroupsFilterTrainFormation-')) {
          const dateInterval = head(extractDateIntervals(parentCemitFilter));
          console.debug(
            `Updating CemitFilter: ${cemitFilter.name} from parent ${parentCemitFilter.name} with DateInterval ${dateInterval ? dumpDateInterval(dateInterval) : 'undefined'}`,
          );
        }
        setCemitFilter(updatedCemitFilter);
      }
    },
    [parentCemitFilter, cemitFilter] as const,
  );
};
