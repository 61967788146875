import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {cemitedConstructor} from 'classes/cemitAppCemitedClasses/cemitClassConstructor.ts';
import {Error} from '@mui/icons-material';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {collectionDevicesOfTrainFormation} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainFormationUtils.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {EquipmentAttributeSensorDataDateInterval} from 'types/trainGroups/equipmentAttributeSensorDataTrainGroup';
import {SensorDataPoint} from 'types/sensors/sensorDataPoint';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainGroupSensorDataGeojson} from 'types/trainGroups/trainGroupSensorDataGeojson';
import {TrainGroupClass} from 'classes/trainAppCemitedClasses/trainGroupClasses.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {TrainGroupAlertGeojson} from 'types/trainGroups/trainGroupAlertGeojson.ts';

/**
 * Basic implementation of a TrainGroup
 */
export class TrainGroupOnlyTrainFormationClass
  extends TrainGroupClass
  implements TrainGroupOnlyTrainFormation
{
  constructor(obj: TrainGroupOnlyTrainFormation) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  get singleTrainRun(): never {
    throw Error('Type TrainGroupOnlyTrainFormation has no TrainRuns');
  }

  get trainRuns(): never {
    throw Error('Type TrainGroupOnlyTrainFormation has no TrainRuns');
  }

  /**
   * Return's the given TrainRun's collection devices along with the vehicle they are on.
   *
   * @param trainGroup
   * @returns {[Object]} Objects keyed by collectionDevice and vehicle
   */
  get trainGroupCollectionDevices(): TrainFormationCollectionDevice[] {
    return collectionDevicesOfTrainFormation(this.trainFormation, this.dateInterval);
  }

  localizedName(_timezoneStr?: Perhaps<string>): string {
    return this.trainFormation!.name!;
  }

  declare __typename: CemitTypename.trainGroupOnlyTrainFormation;

  localUpdateDate: Date = new Date();
  localUpdateVersion: number = 0;
  equipmentAttributeDateIntervals?:
    | EquipmentAttributeSensorDataDateInterval[]
    | undefined;
  sensorDataPoints?: Record<string, SensorDataPoint[]> | undefined;

  sensorDataDateIntervals?: DateInterval[] | undefined;

  sourceKey?: string | undefined;
  email?: string | undefined;

  error: any;
  errorDate: Date;
  sensorDataGeojson: Perhaps<TrainGroupSensorDataGeojson>;
  alertGeojson: Perhaps<TrainGroupAlertGeojson>;
  id: string;
  activeDateInterval?: DateInterval;
}
