import React from 'react';
import { SvgProps } from './SvgProps.ts';

export const BudgetIcon = ({ fill }: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 32 32"
    fill={fill}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7033 3.48281H15.0252V25.5519H18.7033V3.48281ZM10.207 9.00008H13.8851V25.5519H10.207V9.00008ZM5.38876 12.6783H9.06693V25.5519H5.38876V12.6783ZM17.7021 25.8895V25.8752H5.38876V26.5648L17.1996 26.5993C18.0612 28.1223 19.7018 29.151 21.584 29.151C24.3605 29.151 26.6112 26.9125 26.6112 24.151C26.6112 21.3896 24.3605 19.151 21.584 19.151C20.6189 19.151 19.7172 19.4215 18.9516 19.8904V20.8442C19.5909 20.3469 20.3781 20.0291 21.2365 19.9668V25.1805L25.323 26.025C24.6309 27.4014 23.2004 28.3464 21.5479 28.3464C19.8417 28.3464 18.3721 27.339 17.7077 25.8895H17.7021Z"
      fill={fill}
    />
  </svg>
);
