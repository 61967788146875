import {always, lensPath, propOr, when} from 'ramda';
import TrainRunLineReadyContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLineReadyContainer.tsx';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {unlessLoadingValue} from 'utils/componentLogic/loadingUtils.ts';
import {Size} from '../../../../types/layout/size';
import {SxProps} from '@mui/system';
import React from 'react';
import {TrainDistanceInterval} from '../../../../types/distances/trainInterval';
import {mergeTrainRouteOrGroup} from '../../../../appUtils/trainAppUtils/trainAppTypeMerging/trainRouteOrGroupMerging.ts';
import {getDeepestTrainServiceScope} from '../../../../appUtils/trainAppUtils/scope/trainPropsScope.ts';
import {TrainDerivedProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {CemitTypename} from '../../../../types/cemitTypename.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {TrainRouteOrGroup} from '../../../../types/trainRouteGroups/trainRouteOrGroup';
import {useNotLoadingMemo} from '../../../../utils/hooks/useMemoHooks.ts';
import {toBoolean} from '../../../../utils/functional/functionalUtils.ts';
import {overClassOrType} from '../../../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {clsOrType} from '../../../../appUtils/typeUtils/clsOrType.ts';
import {TrainDependencyDerivedProps} from 'types/propTypes/trainPropTypes/trainTrainAppDependencyProps.d.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * TODO not used, out of date
 *  Displays a TrainPage Run as a line with the stops and time of stops
 *  in the context of a TrainRouteOrGroup or TrainGroup
 *  Alternatively displays a Route as a line with the stops and the distance of the stops
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param spaceGeospatially
 * @param onlyStopsNearInterval
 * @param showLimitedDistanceRange
 * @param showTrainRunOrGroupIntervalBars
 * @param isTrainRouteLine
 * @param isTrainGroupDetailLine
 * @param trainDetailSize
 * @param orientation
 * @param sx
 * @param sxTrainRunOrGroupIntervalBar
 * @constructor
 */
export const TrainRunLineContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {
    spaceGeospatially,
    onlyStopsNearInterval,
    limitedTrainDistanceInterval,
    showTrainRunOrGroupIntervalBars,
    isTrainRouteLine,
    isTrainGroupDetailLine,
    trainDetailSize,
    orientation = 'horizontal',
  },
  sx = [],
  sxTrainRunOrGroupIntervalBar = [],
}: TrainDependencyDerivedProps & {
  componentProps: {
    spaceGeospatially: boolean;
    onlyStopsNearInterval: boolean;
    limitedTrainDistanceInterval: TrainDistanceInterval;
    showTrainRunOrGroupIntervalBars: boolean;
    isTrainRouteLine: boolean;
    isTrainGroupDetailLine: boolean;
    trainDetailSize: Size;
    orientation: string;
  };
  sx: SxProps;
  sxTrainRunOrGroupIntervalBar: SxProps;
}) => {
  const [_cemitTypename, trainRouteOrGroupOrTrainGroup]: [
    CemitTypename,
    Perhaps<TrainRouteOrGroup> | TrainGroup,
  ] = getDeepestTrainServiceScope(trainProps);
  const loading = !trainRouteOrGroupOrTrainGroup;

  // Get the current hover feature (from TrainMap) for the TrainGroupLine (isTrainGroupDetailLine) or TrainRoute (isTrainRouteLine)
  const hoverFeature = unlessLoadingValue(loading, () => {
    return propOr(
      undefined,
      trainRouteOrGroupOrTrainGroup.id,
      trainProps.trainGroupSingleTrainRunProps.chartMapInteractionProps?.hoverFeature,
    );
  });

  // Limits the distance of trainProps.trainRouteGroupProps.trainRouteOrGroup.trainDistanceInterval
  // to limitedTrainDistanceInterval
  const trainPropsWithTrainRunOrGroupInterval: Perhaps<TrainDerivedProps> =
    useNotLoadingMemo(loading, (): TrainDerivedProps => {
      // No matter the scope, send the trainRouteOrGroupWithLimitTrainDistanceInterval to the children if it exists
      const trainRouteOrGroupWithLimitTrainDistanceInterval = mergeTrainRouteOrGroup(
        trainProps.trainRouteGroupProps!.trainRouteOrGroup!,
        {trainDistanceInterval: limitedTrainDistanceInterval},
      );
      return when(
        always(toBoolean(trainRouteOrGroupWithLimitTrainDistanceInterval)),
        (trainProps: TrainDerivedProps) => {
          return clsOrType<TrainDerivedProps>(
            trainProps.__typename,
            overClassOrType(
              lensPath(['trainRouteGroupProps', 'trainRouteOrGroup']),
              (trainRouteOrGroup) => {
                return mergeTrainRouteOrGroup(
                  trainRouteOrGroup,
                  trainRouteOrGroupWithLimitTrainDistanceInterval,
                );
              },
              trainProps,
            ),
          );
        },
      )(trainProps);
    }, [
      trainProps.trainRouteGroupProps?.trainRouteOrGroup,
      limitedTrainDistanceInterval,
    ]);

  if (loading) {
    return <TrainRunLineLoading />;
  } else {
    return (
      <TrainRunLineReadyContainer
        {...{
          appProps,
          organizationProps,
          trainProps: trainPropsWithTrainRunOrGroupInterval,
          componentProps: {
            spaceGeospatially,
            onlyStopsNearInterval,
            limitedTrainDistanceInterval,
            showTrainRunOrGroupIntervalBars,
            isTrainRouteLine,
            isTrainGroupDetailLine,
            hoverFeature,
            trainDetailSize,
            orientation,
          },
          sx,
          sxTrainRunOrGroupIntervalBar,
        }}
      />
    );
  }
};

export default TrainRunLineContainer;
const TrainRunLineLoading = () => {
  return <LoaderWithText text="loadingTrainRunLine" />;
};
