import {map, omit} from 'ramda';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {
  OrderedRoutePoint,
  OrderedRoutePointResponse,
} from '../../types/trainRouteGroups/orderedRoutePoint';
import {
  TrainRoute,
  TrainRouteMinimized,
  TrainRouteResponse,
  TrainRouteResponseMinimized,
} from '../../types/trainRouteGroups/trainRoute';
import {RoutePoint} from '../../types/trainRouteGroups/routePoint';
import {createOrderedRoutePoint} from './orderedRoutePointCrud.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {clsOrTypes} from '../../appUtils/typeUtils/clsOrTypes.ts';

/**
 * Classifies a minimized TrainRouteResponse
 * @param trainRouteResponse
 */
export const createTrainRouteMinimized = (
  trainRouteResponse: TrainRouteResponseMinimized,
): TrainRouteMinimized => {
  return clsOrType<TrainRouteMinimized>(
    CemitTypename.trainRouteMinimized,
    trainRouteResponse,
  );
};

/**
 * Creates a TrainRoute from a TrainRouteResponse
 * @param trainRouteResponse
 */
export const createTrainRoute = (trainRouteResponse: TrainRouteResponse): TrainRoute => {
  return clsOrType<TrainRoute>(CemitTypename.trainRoute, {
    ...omit<TrainRouteResponse, 'orderedRoutePoints'>(
      ['orderedRoutePoints'],
      trainRouteResponse,
    ),
    // This just types OrderedRoutePoints and their RoutePoints
    orderedRoutePoints: clsOrTypes<OrderedRoutePoint>(
      CemitTypename.orderedRoutePoint,
      map<
        OrderedRoutePointResponse,
        Omit<OrderedRoutePointResponse, 'routePoint'> & {
          routePoint: RoutePoint;
        }
      >((orderedRoutePoint) => {
        return createOrderedRoutePoint(orderedRoutePoint);
      }, trainRouteResponse.orderedRoutePoints),
    ),
    // Matches the interface of TrainRouteGroup
    get trainRoutes(): [TrainRoute] {
      return [this];
    },
  } as TrainRoute);
};
