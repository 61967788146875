import React from 'react';

export const WarningSvgIcon = ({
  fill = '#ff0000',
  width = '100%',
  height = '100%',
  trainGroupFill = undefined,
}) => {
  trainGroupFill = trainGroupFill || fill;
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 386 334"
    >
      <path
        d="m354.25 310.09-336.8-0.6l168.9-291.5z"
        fill={trainGroupFill}
        stroke={trainGroupFill}
        strokeLinejoin="round"
        strokeWidth="25"
      />
      <path
        d="m185.75 81.889c-2.6 0.1-5 1.6-6.3 3.9l-104.4 183.1c-1.4 2.4-1.4 5.4 0 7.8s3.9 3.9 6.7 3.9l208.2 0.4c2.8 0 5.3-1.5 6.7-3.9 1.4-2.5 1.4-5.5 0-7.9l-103.8-183.4c-1.5-2.6-4.2-4.1-7.1-3.9z"
        fill={fill}
      />
      <path
        transform="matrix(.9943 0 0 1.0001 .94554 -.021396)"
        d="m189.52 220.49h-5.3c-0.6-9.4-2.4-20.7-5.6-33.9l-6.3-26.9c-3.8-16.1-5.7-27.6-5.7-34.6 0-14.1 6.7-21.1 20.1-21.1 5.9 0 10.8 1.9 14.6 5.8s5.8 8.9 5.8 14.9c0 6.4-2.1 18.1-6.1 35l-6.5 26.9c-2.2 9.4-3.9 20.8-5 33.9zm-2.5 17.7c6.3 0 11.1 2.1 14.5 6.4 3.5 4.2 5.2 8.8 5.2 13.6 0 6.4-2.1 11.4-6.3 14.8-4.1 3.5-8.6 5.2-13.4 5.2-6.3 0-11.2-2.1-14.6-6.3-3.4-4.3-5.1-8.8-5.1-13.7 0-5.6 1.9-10.4 5.7-14.2 3.8-3.9 8.4-5.8 14-5.8z"
        stroke={trainGroupFill}
      />
    </svg>
  );
};
WarningSvgIcon.displayProps = 'WarningSvgIcon';
export default WarningSvgIcon;
