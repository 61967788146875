import {DependencyList, EffectCallback, useEffect, useMemo} from 'react';
import {DefaultIfLoading} from '../../types/logic/requireIfLoaded.ts';

export type REQUIRED_ITEMS<D extends readonly any[]> = {
  [Property in keyof D]: Exclude<D[Property], undefined>;
};
/**
 * Calls useMemo with the given memoFunc and dependencies but returns
 * undefined without calling the memoFunc if loading is True.
 * loading is always concatted to the dependencies
 * @param loading
 * @param memoFunc The func called by useMemo if loading is false. Called with the array of dependencies
 * so that they can be used instead of closed variables and thus be typed as defined, not Perhaps<defined>
 * @param [dependencies] Default [] The useMemo dependencies, not including loaidng
 * @param defaultValue
 * @returns
 */
export const useNotLoadingMemo = <T, D extends readonly any[], DEFAULT extends any = any>(
  loading: boolean,
  memoFunc: (...dependencies: REQUIRED_ITEMS<D>) => T,
  dependencies: D,
  defaultValue?: DEFAULT,
): DefaultIfLoading<typeof loading, T, typeof defaultValue> => {
  return useMemo<DefaultIfLoading<typeof loading, T, DEFAULT>>((): DefaultIfLoading<
    typeof loading,
    T,
    DEFAULT
  > => {
    if (loading) {
      return defaultValue as DefaultIfLoading<true, T, typeof defaultValue>;
    }
    return memoFunc(...(dependencies as REQUIRED_ITEMS<D>)) as Required<T>;
  }, [loading, ...(dependencies || [])]);
};

/**
 * Calls useEffect with the given effectFunc and dependencies.
 * Returns before calling effectFunc if loading is false
 * @param loading
 * @param effectFunc The func called by useMemo if loading is false
 * @param dependencies Default [], the useMemo dependencies, not including loading
 * @returns {VoidFunction}
 *
 */
export const useNotLoadingEffect = <D extends readonly any[] = any[]>(
  loading: boolean,
  // TODO I want each item of the array required but don't know how to define that
  effectFunc: (...dependencies: REQUIRED_ITEMS<D>) => ReturnType<EffectCallback>,
  dependencies: D,
): void => {
  if ('undefined' === typeof loading) {
    throw Error('loading prop must be either truthy or false, got undefined');
  }
  return useEffect(() => {
    if (loading) {
      return;
    }
    // TODO I want each item of the array required but don't know how to define that
    return effectFunc(...(dependencies as REQUIRED_ITEMS<D>));
  }, [loading, ...(dependencies || [])]);
};

/**
 * Calls the given function in an effect with dependencies that only runs if loading is false and calls setter on its results
 * The intention of this function is to separate effects from non-mutating code
 * @param loading If true, do nothing
 * @param [inProcessSetter] Optional setter that is called with true when the loading is false and effect
 * begins and called with false when the effect ends.

 * @param func The non-mutating function to call
 * @param props props for func
 * @param setter The setter function called with the result of func. This can be a useState setter or
 * a custom mutation function, like for setting layers on a Mapbox map
 * @param dependencies useEffect dependencies
 */
export const useNotLoadingSetterEffect = <P extends Record<string, any>, R extends any>(
  {
    loading,
    inProcessSetter,
  }: {loading: boolean; inProcessSetter?: (value: boolean) => void},
  func: (props: P) => R,
  props: P,
  setter: (value: R) => void,
  dependencies: DependencyList,
) => {
  useNotLoadingEffect(
    loading,
    () => {
      if (inProcessSetter) {
        inProcessSetter(true);
      }
      const result = func(props);
      setter(result);
      if (inProcessSetter) {
        inProcessSetter(false);
      }
    },
    dependencies,
  );
};
