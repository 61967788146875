import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {any} from 'ramda';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';

/**
 * Returns true if either the incoming value  alertScopeProps.alertTrainGroupProps.alertGraphqlStatus
 * or existing value alertScopeProps.scopedTrainGroup.alertScopeProps?.alertTrainGroupProps.alertGraphqlStatus
 * is not LoadingStatusEnum.complete
 * @param alertScopeProps
 */
export const alertIncomingOrExistingStatusIsIncomplete = (
  alertScopeProps: AlertScopeProps,
) => {
  return any(
    (status: LoadingStatusEnum): boolean => {
      return status !== LoadingStatusEnum.complete;
    },
    [
      alertScopeProps.alertTrainGroupProps.alertGraphqlStatus,
      alertScopeProps.scopedTrainGroup.alertScopeProps?.alertTrainGroupProps
        .alertGraphqlStatus,
    ],
  );
};
