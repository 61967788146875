import React, {useContext, useMemo, useRef, useState} from 'react';
import TrafficSimMap from 'pages/trafficsim/trafficSimComponents/track/v2/TrafficSimMap.tsx';
import TrafficSimHeaderBar from 'pages/trafficsim/trafficSimComponents/navigation/header/TrafficSimHeaderBar.tsx';
import TrafficSimNavigationBar from 'pages/trafficsim/trafficSimComponents/navigation/navigation/TrafficSimNavigationBar.tsx';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {resolveTrafficSimMapStyleUrl} from 'config/appConfigs/appSettings.ts';

import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import {Stack, useMediaQuery} from '@mui/material';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrafficSimAppProps} from 'types/trafficsim/trafficSimAppProps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrafficSimPageConfig} from 'types/trafficsim/trafficSimConfig';
import {Perhaps} from 'types/typeHelpers/perhaps';

const TrafficSimAppContainer = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  const {themeName} = useContext(CemitThemeContext);
  const mapRef = useRef(null);

  const {theme} = useContext(CemitThemeContext);

  const [selectedPage, setSelectedPage] =
    useState<Perhaps<TrafficSimPageConfig>>(undefined);
  const [isSubpageMenuOpen, setIsSubpageMenuOpen] = useState<boolean>(false);
  const [expandedPages, setExpandedPages] = useState<Record<string, boolean>>({});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  // Initial states and functions to manage them are defined.
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeSubPage, setActiveSubPage] = useState<string>('');
  const [searchEntries, setSearchEntries] = useState<string[]>([]);

  const trafficSimAppProps = useMemo(() => {
    return clsOrType<TrafficSimAppProps>(CemitTypename.trafficSimAppProps, {
      ...appProps,
      selectedPage,
      setSelectedPage,
      isSubpageMenuOpen,
      setIsSubpageMenuOpen,
      expandedPages,
      setExpandedPages,
      isMobile,
      mobileMenuOpen,
      setMobileMenuOpen,
      drawerOpen,
      setDrawerOpen,
      activeSubPage,
      setActiveSubPage,
      searchEntries,
      setSearchEntries,
    });
  }, [
    appProps,
    selectedPage,
    isSubpageMenuOpen,
    expandedPages,
    isMobile,
    mobileMenuOpen,
  ]);

  const mapboxStyleUrl = resolveTrafficSimMapStyleUrl(themeName);
  return (
    <Stack
      key="trafficSimAll"
      {...{
        sx: {
          minWidth: 0,
          minHeight: 0,
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
        },
      }}
    >
      <TrafficSimHeaderBar
        key="trafficSimHeaderBar"
        {...{
          appProps,
          organizationProps,
          componentProps: {searchEntries, mapRef},
        }}
      />
      <TrafficSimNavigationBar
        key="trafficSimNavigationBar"
        {...{
          appProps: trafficSimAppProps,
          organizationProps,
        }}
      />
      <TrafficSimMap
        key="trafficSimMap"
        {...{
          drawerOpen,
          setDrawerOpen,
          activeSubPage,
          setActiveSubPage,
          mapStyle: mapboxStyleUrl,
          mapRef,
          setSearchEntries,
        }}
      />
    </Stack>
  );
};

export default TrafficSimAppContainer;
