import {Cemited} from '../../types/cemited';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {useMemo} from 'react';
import {clsOrType} from './clsOrType.ts';
import {values} from 'ramda';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';

/**
 * If a single class implementation exists for typename, instantiate the class from the given object t.
 * Throws if no or multiple class implementations exist, and returns an object with the typename if the no
 * class is defined
 * @param typename
 * @param t
 * @param overrideDependencies If specified, use these dependencies instead of t, since t might
 * be an object that is created in every render
 */
export const useMemoClsOrType = <T extends Cemited>(
  typename: CemitTypename,
  t: T | Omit<WritablePart<T>, '__typename'>,
  overrideDependencies: Perhaps<any[]> = undefined,
): T => {
  return useMemo<T>(() => {
    return clsOrType<T>(typename, t);
  }, [typename, ...(overrideDependencies || values(t))]);
};

/**
 * When memoizes clsOrTypes for when ts don't change or when overrideDependencies is specified and none change
 * @param typename
 * @param ts
 * @param overrideDependencies
 */
export const useMemoClsOrTypes = <T extends Cemited>(
  typename: CemitTypename,
  ts: T[] | Omit<WritablePart<T>, '__typename'>[],
  overrideDependencies: Perhaps<any[]> = undefined,
): T[] => {
  return useMemo<T[]>(() => {
    return clsOrTypes<T>(typename, ts);
  }, [typename, ...(overrideDependencies || values(ts))]);
};
