import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';
import {SketchPicker} from 'react-color';
import {Box, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {PaletteOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {Property} from 'csstype';

export interface ColorPickerProps {
  color: Property.Color;
  handleChangeComplete: (color: undefined | Property.Color) => void;
  disabled: boolean;
  label?: string;
}

/**
 * Shows a ColorPicker with an optional label
 * @param _color
 * @param handleChangeComplete
 * @param disabled
 * @param label
 * @constructor
 */
const ColorPicker = ({
  color: _color,
  handleChangeComplete,
  disabled = false,
  label,
}: ColorPickerProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const timer = useRef();
  const [color, setColor] = useState(_color);
  useEffect(() => {
    if (_color !== color) {
      setColor(_color);
    }
  }, [_color]);
  const {t} = useTranslation();
  const handleClick = (e: SyntheticEvent) => {
    clearTimeout(timer.current);

    if (e.detail === 1) {
      timer.current = setTimeout(() => {
        setDisplayColorPicker(!displayColorPicker);
      }, 200);
    } else if (e.detail === 2) {
      handleChangeComplete(undefined);
    }
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    handleChangeComplete(color);
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  return (
    <Box>
      <Tooltip arrow title={t('chooseColor')}>
        <IconButton
          key="colorButton"
          {...{
            sx: {
              p: 0,
            },
            disabled,
            'aria-label': 'colorButton',
            onClick: handleClick,
          }}
        >
          <Stack {...{direction: 'row', spacing: 1}}>
            <PaletteOutlined
              {...{
                variant: 'subtitle2',
                sx: {color},
              }}
            />
            {label && <Typography {...{color: 'secondary'}}>{label}</Typography>}
          </Stack>
        </IconButton>
      </Tooltip>

      {displayColorPicker ? (
        <Box
          {...{
            sx: {
              // Fixed allows the picker to be bleed over its parent and over the map
              position: 'fixed',
              zIndex: 10000,
            },
          }}
        >
          <Box
            {...{
              sx: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              },
              onClick: handleClose,
            }}
          />
          <SketchPicker
            {...{
              color,
              onChange: handleChange,
            }}
          />
        </Box>
      ) : undefined}
    </Box>
  );
};
export default ColorPicker;
