import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig.d.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TFunction} from 'i18next';
import {
  CEMIT_ALERT_CRITICAL,
  CEMIT_ALERT_NORMAL,
  CEMIT_ALERT_WARNING,
} from 'theme/cemitColors.ts';
import {AlertBaseGauge} from 'types/alerts/alertGauge';
import {BrakingGauge} from 'types/alerts/braking/brakingGauge';
import {darken, lighten} from '@mui/material/styles/index.js';
import NormalBrakingSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/NormalBrakingSvgIcon.tsx';
import HardBrakingSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/HardBrakingSvgIcon.tsx';
import EmergencyBrakingSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/EmergencyBrakingSvgIcon.tsx';
import FullEmergencyBrakingSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/FullEmergencyBrakingSvgIcon.tsx';
import {BrakingAttributeAlertLevel} from 'types/alerts/braking/brakingAttributeAlertLevel.ts';

export const brakingErrorLevelConfigs = <T extends AlertBaseGauge>(
  t: TFunction,
): AttributeAlertLevelConfig<BrakingGauge>[] => {
  return clsOrTypes<AttributeAlertLevelConfig<AlertBaseGauge>>(
    CemitTypename.alertErrorLevelConfig,
    [
      {
        attributeAlertLevel: BrakingAttributeAlertLevel.softBraking,
        label: t('softBraking'),
        imageSvgComponent: NormalBrakingSvgIcon,
        color: lighten(CEMIT_ALERT_NORMAL, 0.5),
      },
      {
        attributeAlertLevel: BrakingAttributeAlertLevel.normalBraking,
        label: t('normalBraking'),
        imageSvgComponent: NormalBrakingSvgIcon,
        color: CEMIT_ALERT_NORMAL,
      },
      {
        attributeAlertLevel: BrakingAttributeAlertLevel.hardBraking,
        label: t('hardBraking'),
        imageSvgComponent: HardBrakingSvgIcon,
        color: CEMIT_ALERT_WARNING,
      },
      {
        attributeAlertLevel: BrakingAttributeAlertLevel.emergencyBraking,
        label: t('emergencyBraking'),
        imageSvgComponent: EmergencyBrakingSvgIcon,
        color: CEMIT_ALERT_CRITICAL,
      },
      {
        attributeAlertLevel: BrakingAttributeAlertLevel.fullEmergencyBraking,
        label: t('fullEmergencyBraking'),
        imageSvgComponent: FullEmergencyBrakingSvgIcon,
        color: darken(CEMIT_ALERT_CRITICAL, 0.5),
      },
    ],
  );
};
