import {
  TrainDerivedProps,
  TrainLoadedProps,
  TrainProps,
} from 'types/propTypes/trainPropTypes/trainProps';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {FilteredTrainGroupProps} from 'types/propTypes/trainPropTypes/filteredTrainGroupProps.d.ts';
import {RailwayLineMinimizedProps} from 'types/propTypes/trainPropTypes/railwayLineProps';
import {ServiceLineProps} from 'types/propTypes/trainPropTypes/serviceLineProps';
import {TrainGroupOnlyTrainFormationProps} from 'types/propTypes/trainPropTypes/trainGroupOnlyTrainFormationProps';
import {
  TrainGroupSingleTrainRunLoadedProps,
  TrainGroupSingleTrainRunProps,
} from 'types/propTypes/trainPropTypes/trainGroupSingleTrainRunProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {PropStatusClass} from './propStatusClass.ts';
import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {DateProps} from 'types/propTypes/trainPropTypes/dateProps';
import {TrainRouteGroupProps} from 'types/propTypes/trainPropTypes/trainRouteOrGroupProps';
import {AlertConfigProps} from 'types/alerts/alertConfigProps.ts';
import {AlertProps} from 'types/alerts/alertProps.ts';

export class TrainPropsClass
  extends PropStatusClass<TrainPropsClass, TrainLoadedPropsClass, TrainDerivedPropsClass>
  implements TrainProps
{
  filteredTrainGroupProps: FilteredTrainGroupProps;
  trainRouteGroupProps: TrainRouteGroupProps;
  trainGroupSingleTrainRunProps: TrainGroupSingleTrainRunProps;
  railwayLineProps: RailwayLineMinimizedProps;
  serviceLineProps: ServiceLineProps;
  trainFormationDateProps: DateProps;
  trainGroupOnlyTrainFormationProps: TrainGroupOnlyTrainFormationProps;
  cemitFilter: CemitFilter;
  alertConfigProps: AlertConfigProps;
  alertProps: AlertProps[];

  constructor(obj: TrainProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  get maybeAsLoaded(): Perhaps<TrainGroupSingleTrainRunLoadedProps> {
    return undefined;
  }

  get maybeAsDerived(): Perhaps<TrainGroupSingleTrainRunProps> {
    return undefined;
  }
}

export class TrainLoadedPropsClass extends TrainPropsClass implements TrainLoadedProps {
  constructor(obj: TrainLoadedProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}

export class TrainDerivedPropsClass
  extends TrainLoadedPropsClass
  implements TrainDerivedProps
{
  constructor(obj: TrainDerivedProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}
