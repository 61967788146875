import {IconButton, Tooltip} from '@mui/material';
import {ErrorOutline, WarningAmber} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {equals, head, lensPath, map, set} from 'ramda';
import {ERROR_NO_SENSOR_DATA} from 'apis/cemitApis/trainApi/trainApiImplementation.ts';

/**
 * Shows that an error occurred downloading data for hthe TrainGroup from the crudTrainGroups
 * Hitting the button attempts to reload the data
 * @param isSelectedTrainRuns
 * @param trainGroups
 * @param crudTrainGroups
 * @param [disabled] Default false
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupErrorButton = ({trainGroups, crudTrainGroups, disabled = false}) => {
  const {t} = useTranslation();
  const error = head(trainGroups).singleTrainRun.error;
  const noSensorDataPointsError = equals(error, ERROR_NO_SENSOR_DATA);
  const Icon = noSensorDataPointsError ? WarningAmber : ErrorOutline;
  const props = {
    disabled,
    color: 'error',
    'aria-label': 'error',
    sx: {
      float: 'right',
      alignItems: 'flex-start',
      p: 0,
    },
  };
  return (
    <Tooltip
      arrow
      {...{
        title: t(error),
      }}
    >
      {noSensorDataPointsError ? (
        <Icon key="errorIcon" {...props} />
      ) : (
        <IconButton
          key="errorButton"
          {...{
            ...props,
            onClick: () => {
              // Set trainRun.retry
              crudTrainGroups.updateOrCreateAll(
                map((trainGroup) => {
                  return set(lensPath(['singleTrainRun', 'retry']), true, trainGroup);
                }, trainGroups),
              );
            },
          }}
        >
          <Icon />
        </IconButton>
      )}
    </Tooltip>
  );
};
export default TrainGroupErrorButton;
