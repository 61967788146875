import {TFunction} from 'i18next';
import {VisionProps} from 'types/propTypes/trainPropTypes/visionPropTypes/visionProps';
import {Stack, Typography} from '@mui/material';
import React from 'react';

export interface VisionCurrentValuesProps {
  t: TFunction;
  visionProps: VisionProps;
}

/**
 * Shows the values returned from the API for the Vision App
 * @param t
 * @param visionProps
 * @constructor
 */
export const VisionCurrentValues = ({t, visionProps}: VisionCurrentValuesProps) => {
  if (!visionProps.visionMeta) {
    return (
      <Stack
        {...{
          direction: 'column',
          sx: {justifyContent: 'space-between', width: '100%'},
        }}
      >
        {`${t('noMatchingImages')} at ${(visionProps.trackDistanceInMeters / 1000).toFixed(3)} km`}
      </Stack>
    );
  }
  return (
    <Stack
      {...{
        direction: 'column',
        sx: {justifyContent: 'space-between', width: '100%'},
      }}
    >
      <Stack
        {...{
          direction: 'row',
          sx: {width: '100%', justifyContent: 'space-between'},
        }}
      >
        <Typography
          {...{variant: 'subtitle2', sx: {fontWeight: 'normal', color: 'grey'}}}
        >
          {t('km').toUpperCase()}
        </Typography>
        <Typography
          {...{
            variant: 'subtitle2',
            color: 'secondary',
            sx: {fontWeight: 'normal'},
          }}
        >
          {`${(visionProps?.visionMeta?.trackDistanceInMeters / 1000).toFixed(3)}`}
        </Typography>
      </Stack>
      <Stack
        {...{
          direction: 'row',
          sx: {width: '100%', justifyContent: 'space-between'},
        }}
      >
        <Typography
          {...{
            variant: 'subtitle2',
            sx: {fontWeight: 'normal', color: 'grey'},
          }}
        >
          {t('locationLatLon')}
        </Typography>
        <Typography
          {...{
            variant: 'subtitle2',
            color: 'secondary',
            sx: {fontWeight: 'normal'},
          }}
        >
          {`${visionProps.visionMeta.trackGeoPosition.lat.toFixed(5)}/${visionProps.visionMeta.trackGeoPosition.lon.toFixed(5)}`}
        </Typography>
      </Stack>
    </Stack>
  );
};
