import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {FormControlLabel, Switch} from '@mui/material';
import React from 'react';
import {CEMIT_WHITE} from 'theme/cemitColors.ts';

const AccelerationToggle = ({appProps}: {appProps: TrainAppProps}) => {
  return (
    <FormControlLabel
      key="formControlLabel"
      {...{
        sx: {
          color: CEMIT_WHITE,
        },
        color: 'secondary',
        // checked indicates to use Y only acceleration. Off is the default XYZ
        onChange: (e) => {
          const {
            target: {checked},
          } = e;
          e.stopPropagation();
          appProps.setChosenAcceleration(checked ? 'y' : 'xyz');
        },
        control: <Switch checked={appProps.chosenAccelerationKey === 'y'} />,
        label: appProps.t('sidewaysAccelerationOnly'),
      }}
    />
  );
};

AccelerationToggle.displayName = 'AccelerationToggle';
export default AccelerationToggle;
