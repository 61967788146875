import {ReactElement, useMemo} from 'react';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import client from 'async/graphql/cemitGraphqlClient.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {RealtimeTrainGroupProps} from 'types/realtimeTrain/realtimeTrainGroupProps.ts';
import {
  useNotLoadingEffectSetRealtimeSignals,
  useNotLoadingMemoCreateTrainGroupRealtimeTrainScopePropsSets,
  useNotLoadingTrainGroupsForRealtimeTrain,
} from 'async/realtimeTrainAsync/realtimeTrainGroupHooks.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {RealtimeTrainScopeProps} from 'types/realtimeTrain/realtimeTrainScopeProps';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';
import {RealtimeTrainProps} from 'types/realtimeTrain/realtimeTrainProps.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {RealtimeTrainConfigProps} from 'types/realtimeTrain/realtimeTrainConfigProps.ts';
import {SUBSCRIPTION_QUERY} from 'pages/softPrio/softPrioGraphqlQueries/TrainAlertSub.ts';
import {RealtimeTrainSubscriptionData} from 'types/realtimeTrain/realtimeTrainSubscriptionData';
import {useSubscription} from 'utils/hooks/useSubscription.tsx';

const RealtimeTrainDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement<
  Required<TrainAppMapDependencyProps>
> => {
  const signals: RealtimeTrainSubscriptionData[] =
    useSubscription<RealtimeTrainSubscriptionData>(
      client,
      SUBSCRIPTION_QUERY,
      organizationProps.organization.realtimeSpaceId,
      appProps.realtimeIsActive,
    );

  const activeTrainGroups: Perhaps<TrainGroup[]> =
    trainProps.trainGroupActivityProps?.activeTrainGroups;

  const realtimeTrainGroupPropsPartial = useMemo(() => {
    return clsOrType<RealtimeTrainGroupProps>(CemitTypename.realtimeTrainGroupProps, {
      realtimeTrainGraphqlStatus: LoadingStatusEnum.notLoaded,
    });
  }, [signals]);

  const trainGroupsForRealtimeTrain: Perhaps<TrainGroup[]> =
    useNotLoadingTrainGroupsForRealtimeTrain(loading, activeTrainGroups);

  // Combines trainProps.realtimeTrainConfigProps with each activeTrainGroup to create
  // a RealtimeTrainScopeProps for each active TrainGroup
  const realtimeTrainScopePropSets: Perhaps<RealtimeTrainScopeProps[]> =
    useNotLoadingMemoCreateTrainGroupRealtimeTrainScopePropsSets(
      loading,
      appProps,
      organizationProps,
      trainProps,
      realtimeTrainGroupPropsPartial,
      signals,
      trainGroupsForRealtimeTrain,
    );

  /**
   * Picks up new signals each active TrainGroup
   * and saves them to the TrainGroup using trainGroupCrudList
   */
  useNotLoadingEffectSetRealtimeSignals(
    loading || !realtimeTrainScopePropSets,
    realtimeTrainScopePropSets,
    // No realtimeTrainConfig props at the moment
    useMemo(() => {
      return {} as RealtimeTrainConfigProps;
    }, [signals]),
    resolveActiveTrainGroupCrudList(trainProps),
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(RealtimeTrainDependency.name),
    RealtimeTrainDependency.name,
    {
      realtimeTrainScopePropSets,
    },
    [realtimeTrainScopePropSets],
    appProps.setWhatDependenciesAreLoading,
    // If !realtimeIsActive, then everything needed is loaded
    realtimeTrainScopePropSets || !appProps.realtimeIsActive,
  );

  const realtimeTrainScopeProps = useMemoClsOrType<RealtimeTrainProps>(
    CemitTypename.realtimeTrainGroupProps,
    {
      whatIsLoading,
      realtimeTrainScopePropSets,
    },
  );

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'realtimeTrainProps',
    realtimeTrainScopeProps,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainProps,
    'RealtimeTrainDependency',
    'realtimeTrainProps',
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};

export default RealtimeTrainDependency;
//export default memo(
//  RealtimeTrainDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
