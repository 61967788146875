import Menu from '@mui/material/Menu/index.js';
import MenuItem from '@mui/material/MenuItem/index.js';
import React, {SyntheticEvent, useState} from 'react';
import {head, is, lensPath, map, length} from 'ramda';
import {IconButton, Stack} from '@mui/material';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import TrainSidewaysSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/trainSidewaysSvgIcon.tsx';
import TrainGroupDescriptionCompact from './TrainGroupDescriptionCompact.tsx';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import ColorPicker from 'components/pickers/colorPicker.tsx';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {Property} from 'csstype';
import {CEMIT_WHITE} from 'theme/cemitColors.ts';

export interface TrainGroupMenuProps extends CemitComponentProps {
  handleClickMenuItem: (
    _event: React.MouseEvent<HTMLTableRowElement>,
    trainGroup: TrainGroup,
  ) => void;
  isSelected: (trainGroup: TrainGroup) => boolean;
  trainGroups: TrainGroup[];
  timezoneStr: string;
  serviceLineIdToColor: Record<string, string>;
  updateTrainGroupAfterColorChange: (trainGroup: TrainGroup) => void;
  showColorPicker: boolean;
  selectedTrainGroups: TrainGroup[];
}

/**
 * Menu to choose from the available TrainGroups
 * @param componentProps
 * @constructor
 */
const TrainGroupMenu = (componentProps: TrainGroupMenuProps): JSX.Element => {
  const {
    handleClickMenuItem,
    isSelected,
    trainGroups,
    timezoneStr,
    serviceLineIdToColor,
    updateTrainGroupAfterColorChange,
    showColorPicker,
    selectedTrainGroups,
  } = componentProps;

  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | undefined>(
    undefined,
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (_e: SyntheticEvent, trainGroup: TrainGroup) => {
    setAnchorEl(undefined);
    // if trainGroup comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, trainGroup)) {
      handleClickMenuItem(trainGroup);
    }
  };
  const activeTrainGroup =
    length(selectedTrainGroups) == 1 ? head(selectedTrainGroups) : undefined;

  return (
    <Stack {...{direction: 'row', sx: {justifyContent: 'left'}}}>
      <Stack {...{spacing: 1, sx: {paddingX: '6px'}}}>
        {showColorPicker && activeTrainGroup ? (
          <ColorPicker
            key="colorPicker"
            {...{
              color: activeTrainGroup?.activity?.isActiveColor,
              handleChangeComplete: (color: Property.Color) => {
                const modifiedTrainGroup = setClassOrType(
                  lensPath(['activity', 'isActiveColor']),
                  color,
                  activeTrainGroup,
                );
                updateTrainGroupAfterColorChange(modifiedTrainGroup);
              },
            }}
          ></ColorPicker>
        ) : undefined}
        <IconButton
          key="button"
          size="large"
          id="trainGroup-button"
          aria-controls={open ? 'trainGroup-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            padding: '0',
          }}
        >
          <TrainSidewaysSvgIcon
            {...{
              fontSize: 'large',
              fill: activeTrainGroup?.activity?.isActiveColor || CEMIT_WHITE,
            }}
          />
        </IconButton>
      </Stack>
      <Menu
        key="menu"
        id="trainGroup-positioned-menu"
        aria-labelledby="trainGroup-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {map((trainGroup: TrainGroup) => {
          const serviceLineColor = serviceLineIdToColor[trainGroup.serviceLine.id];
          return (
            <MenuItem
              key={trainGroup.id}
              disabled={isSelected(trainGroup)}
              onClick={(e: SyntheticEvent) => handleClose(e, trainGroup)}
            >
              {
                <TrainGroupDescriptionCompact
                  {...{
                    trainGroup,
                    timezoneStr,
                    serviceLineColor,
                    updateTrainGroupAfterColorChange,
                    showColorPicker,
                  }}
                />
              }
            </MenuItem>
          );
        }, trainGroups)}
      </Menu>
    </Stack>
  );
};
export default TrainGroupMenu;
