import {parseISO} from 'date-fns';
import {compose, identity, ifElse, is, prop, propOr, uniqBy, when} from 'ramda';
import {
  East,
  LinearScale,
  North,
  NorthEast,
  NorthWest,
  South,
  SouthEast,
  SouthWest,
  SvgIconComponent,
  TimerOutlined,
  West,
} from '@mui/icons-material';
import {Grid, Stack} from '@mui/material';
import rhumbBearing from '@turf/rhumb-bearing';
import {strPathOr} from '@rescapes/ramda';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  angleToCardinalDirection,
  createPropertyPathGridItems,
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainRunningCharacteristicsComponents/sensorDataPointDescriptionComponentUtils.tsx';
import CaptionTypography from 'components/atoms/typography/CaptionTypography.tsx';
import {ChartDataConfig} from 'types/dataVisualizations/chartDataConfig.ts';
import {ChartPayloadItemComplete} from 'types/dataVisualizations/chartPayloadItem';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {Feature, Point} from 'geojson';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {formattedDateTimeMaybeTimezone} from 'utils/datetime/timeUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {useNotLoadingRailwayKeyToNameLookup} from 'async/trainAppAsync/trainAppHooks/typeHooks/railwayLineHooks.ts';
import {RailIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/railIcon.tsx';

const directionIconLookup: Record<string, SvgIconComponent> = {
  N: North,
  S: South,
  E: East,
  W: West,
  NW: NorthWest,
  SW: SouthWest,
  SE: SouthEast,
  NE: NorthEast,
};

export interface SensorDataPointDescriptionProps extends CemitComponentProps {
  dataPathsConfigs: ChartDataConfig[];
  otherDisplayDataPaths: ChartDataConfig[];
  propertyPathConfigs: Perhaps<string[]>;
  chartPayloadItem: ChartPayloadItemComplete;
}

/**
 * Returns a description of the sensorDataPoint for use on a chart or graph
 * @returns {JSX.Element}
 */
const SensorDataPointDescription = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<SensorDataPointDescriptionProps>): JSX.Element => {
  const {
    dataPathsConfigs,
    otherDisplayDataPaths,
    propertyPathConfigs = undefined,
    chartPayloadItem,
    sx,
  } = componentProps;
  const railwayDistancePropertyPath = chartPayloadItem.railwayDistancePropertyPath;

  const {t} = useTranslation();
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);
  const {
    payload: data,
    dataKey,
    trainGroup,
    color,
    referenceStop,
    timePropertyPath,
    distancePropertyPath,
  } = chartPayloadItem;
  const timezoneStr = organizationProps.organization.timezoneStr;

  const formattedDateTime = timePropertyPath
    ? compose(
        (date: Date) => {
          return formattedDateTimeMaybeTimezone(date, timezoneStr);
        },
        (time) => {
          return when(is(String), (time) => parseISO(time))(time);
        },
        (data) => strPathOr(undefined, timePropertyPath, data),
      )(data)
    : undefined;
  const formattedDistance = distancePropertyPath
    ? strPathOr(undefined, distancePropertyPath, data)
    : undefined;
  const relativePosition = organizationHasServiceLines
    ? strPathOr('N/A', railwayDistancePropertyPath, data)
    : undefined;

  // Resolve the RailwayLine name
  const railwayLineName = when(identity, (railwayKey: string) => {
    return propOr(
      railwayKey,
      railwayKey,
      useNotLoadingRailwayKeyToNameLookup(trainProps),
    );
  })(data.properties?.railway);
  const railwayLineDistance = `${(relativePosition / 1000).toFixed(1)} ${t('km')}`;

  // Combine the dataPathConfigs and propertyPathConfigs, which have the same format
  const propertyOrDataPathConfigs: ChartDataConfig[] = uniqBy(
    (chartDataConfig: ChartDataConfig) => {
      // This can be a path or function, but functions will probably always be unique
      return prop('yAxisDataPath', chartDataConfig);
    },
    [...(dataPathsConfigs || []), ...(propertyPathConfigs || [])],
  );
  const propertyPathGridItems = createPropertyPathGridItems(
    propertyOrDataPathConfigs,
    dataKey,
    data,
    otherDisplayDataPaths,
  );

  /* The position and direction from the reference stop */
  const customComponent = ifElse(
    identity,
    () => {
      const direction: Perhaps<string> =
        referenceStop &&
        angleToCardinalDirection(
          rhumbBearing(referenceStop.geojson, data as Feature<Point>),
        );
      const directionIcon: Perhaps<JSX.Element> = when(
        Boolean,
        (direction: Perhaps<string>) => {
          const DirectionIcon = directionIconLookup[direction!];
          return (
            <DirectionIcon
              key="icon"
              {...{
                variant: 'tiny',
              }}
            />
          );
        },
      )(direction);
      const directionComponent: Perhaps<JSX.Element> = directionIcon && (
        <Grid key="directionIcon" {...{item: true}}>
          <Stack {...{direction: 'row', spacing: 0}}>
            {directionIcon!}
            <CaptionTypography
              key="caption"
              {...{
                sx: {
                  fontSize: '2em',
                },
              }}
            >
              {' '}
              {t(direction.toLowerCase())}{' '}
            </CaptionTypography>
          </Stack>
        </Grid>
      );
      return directionComponent;
    },
    () => {},
  )(organizationHasServiceLines);

  return (
    <Stack
      key="pointData"
      {...{
        sx: [
          {
            fontSize: '5px',
            color: '#fff',
            marginBottom: '5px',
            padding: '5px',
          },
          ...toArrayIfNot(sx),
        ],
      }}
    >
      {/* The Formation Datetime of the Point */}
      {formattedDateTime ? (
        <Grid key="formationDatetime" {...{container: true, spacing: 0.25}}>
          <Grid key="icon" {...{item: true}}>
            <TimerOutlined {...{variant: 'tiny', htmlColor: color}} />
          </Grid>
          <Grid key="dateTime" {...{item: true}}>
            <CaptionTypography>{formattedDateTime}</CaptionTypography>
          </Grid>
        </Grid>
      ) : undefined}
      {railwayLineName ? (
        <Grid key="railwayLineName" {...{container: true, spacing: 0.25}}>
          <Grid key="icon" {...{item: true}}>
            <RailIcon {...{variant: 'tiny', fill: color}} />
          </Grid>
          <Grid key="railwayLineName" {...{item: true}}>
            <CaptionTypography>
              {railwayLineName} ({railwayLineDistance})
            </CaptionTypography>
          </Grid>
          <Grid key="directionIcon" {...{item: true}}>
            {customComponent}
          </Grid>
        </Grid>
      ) : undefined}
      {formattedDistance ? (
        <Grid key="formattedDistance" {...{container: true, spacing: 0.25}}>
          <Grid key="icon" {...{item: true}}>
            <LinearScale {...{variant: 'tiny', htmlColor: color}} />
          </Grid>
          <Grid key="totalDistance" {...{item: true}}>
            <CaptionTypography>
              {`${t('totalDistance')}: ${(formattedDistance / 1000).toFixed(0)} km`}
            </CaptionTypography>
          </Grid>
        </Grid>
      ) : undefined}
      {customComponent}
      {/* The configured property paths */}
      {propertyPathGridItems}
    </Stack>
  );
};
export default SensorDataPointDescription;
