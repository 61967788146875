import {
  extractAndEvaluateMatchingFilters,
  idsFromEqualsOrIncludes,
  removeExcessiveInstancesFromFilterAndAddNew,
} from 'appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {chain, equals, find, lensProp, map, prop} from 'ramda';
import {TrainFormation} from '../../types/trains/trainFormation';
import {CemitFilterTrainFormation} from '../../types/cemitFilters/cemitFilterTrainFormation';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {CemitFilterProps} from '../../types/cemitFilters/cemitFilterProps';
import {evalFilter} from './cemitFilterEval.ts';
import {
  CemitFilterTrainFormationProps,
  CemitFilterTrainGroupOnlyFormationProps,
} from '../../types/cemitFilters/cemitFilterTrainFormationProps.ts';
import {
  findByIdOrThrow,
  findByIdOrUndefined,
  mapValuesToCollectionItemsByPropPath,
  toArrayIfNot,
} from '../../utils/functional/functionalUtils.ts';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {overClassOrType} from '../../utils/functional/cemitTypenameFunctionalUtils.ts';
import {CemitFilter} from '../../types/cemitFilters/cemitFilter';
import {TRAIN_GROUP_MAX_ACTIVE_COUNT} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {clearOrganizationLocalStorage} from 'config/appConfigs/appSettings.ts';

/**
 * Returns true if the object represents a Formation filter in the form
 any: [{
 equals: [
 value or { view: { lensPath: ['prop', 'path', 'to', 'formation', 'id'] } }
 new Date('2022-09-01'),
 { view: { lensPath: ['trainRun', 'formation', 'id] } }
 ],
 ...
 }]
 * @param obj
 * @returns {{return}}
 */
export const isCemitFilterTrainFormation = (obj: CemitFilter) => {
  return equals(CemitTypename.cemitFilterTrainFormation, obj.__typename);
};

/**
 * Evals a CemitFilterTrainFormation, meaning
 * @param cemitFilterTrainFormation
 * @param props
 * @returns {*}
 */
export const evalCemitFilterTrainFormation = (
  cemitFilterTrainFormation: CemitFilterTrainFormation,
  props: CemitFilterProps,
): CemitFilter => {
  return overClassOrType(
    lensProp('equals'),
    (equalsFilter) => {
      return evalFilter(equalsFilter, props);
    },
    cemitFilterTrainFormation,
  );
};

/**
 * Extract the CemitFilterTrainformations from cemitFilter
 * @param cemitFilter
 * @param props
 */
export const extractCemitFilterTrainFormations = (
  cemitFilter: CemitFilter,
  props: CemitFilterTrainFormationProps,
): CemitFilterTrainFormation[] => {
  return extractAndEvaluateMatchingFilters<TrainFormation, CemitFilterTrainFormation>(
    isCemitFilterTrainFormation,
    evalCemitFilterTrainFormation,
    cemitFilter,
    props,
  );
};
/**
 * Extracts the TrainFormations of cemitFilter using props.trainGroupOnlyTrainFormations and
 * then maps each TrainFormation to the corresponding TrainGroupOnlyTrainFormations in
 * props.trainGroupOnlyTrainFormations
 * @param cemitFilter
 * @param props
 */
export const extractTrainFormationsAsTrainGroups = (
  cemitFilter: CemitFilter,
  props: CemitFilterTrainGroupOnlyFormationProps,
): TrainGroupOnlyTrainFormation[] => {
  const trainFormations = extractTrainFormations(cemitFilter, props);

  return mapValuesToCollectionItemsByPropPath(
    (trainGroupOnlyFormation: TrainGroupOnlyTrainFormation) => {
      return trainGroupOnlyFormation.trainFormation.id;
    },
    props.trainGroupOnlyTrainFormations,
    (trainFormation: TrainFormation) => {
      return trainFormation.id;
    },
    trainFormations,
  );
};
/**
 * Extracts the TrainFormations from the filter
 * @param t
 * @param cemitFilter
 * @param props Required to match formations by id
 */
export const extractTrainFormations = (
  cemitFilter: CemitFilter,
  props: CemitFilterTrainFormationProps,
): TrainFormation[] => {
  const trainFormations = [
    ...(props.trainFormations || []),
    // If undefined this returns and empty array
    ...toArrayIfNot(props.trainFormation),
    ...map<TrainGroupOnlyTrainFormation, TrainFormation>(
      prop('trainFormation'),
      toArrayIfNot(props.trainGroupOnlyTrainFormations),
    ),
  ];

  const trainFormationEvaluatedFilters = extractAndEvaluateMatchingFilters(
    isCemitFilterTrainFormation,
    evalCemitFilterTrainFormation,
    cemitFilter,
    props,
  );
  return chain((trainFormationEvaluatedFilter) => {
    // Get the trainFormation ids listed in equals or includes expressions
    const trainFormationIds = idsFromEqualsOrIncludes(trainFormationEvaluatedFilter);
    return map((trainFormationId: string) => {
      // Map them to their values if the values exist in trainFormations
      const value = findByIdOrUndefined(trainFormations, trainFormationId);
      // TODO if admins get bad ids in their local storage, clear cache items and reload
      if (!value) {
        clearOrganizationLocalStorage();
        location.reload();
      }
      return value;
    }, trainFormationIds);
  }, trainFormationEvaluatedFilters);
};

/**
 * Given a filter that represents a Date range comparison, convert it to
 * a readable string
 * @param cemitFilter
 * @param props
 * @param props.formations Available formations. Required to resolve the formation label from the id
 * @returns {[String]} Returns a label for each datetime range comparison.
 * These can be combined as needed by the caller
 */
export const extractLabelsForTrainFormations = (
  cemitFilter: CemitFilterTrainFormation,
  props: CemitFilterTrainFormationProps,
): string[] => {
  const trainFormations: TrainFormation[] = extractTrainFormations(cemitFilter, props);
  return map((trainFormation: TrainFormation) => {
    // Extract the vehicles from the Formation and return the vehicle name
    // Separate the two vehicle names by a slash for now
    // return join('/', namesOfTrainFormationExtremeVehicles(formation));
    return trainFormation.name;
  }, trainFormations);
};

/**
 * Adds an equals or includes filter to a TrainFormation ids of trainGroupOnlyTrainFormations
 * @param cemitFilter
 * @param setCemitFilter
 * @param trainGroupOnlyTrainFormations One or many formation instances
 * @param props
 * @param maximumAllowed
 * @returns {*}
 */
export const addTrainFormationsToCemitFilter = (
  cemitFilter: CemitFilter,
  setCemitFilter: StateSetter<CemitFilter>,
  trainGroupOnlyTrainFormations: Partial<TrainGroupOnlyTrainFormation>[],
  props: CemitFilterTrainGroupOnlyFormationProps,
  maximumAllowed: number = TRAIN_GROUP_MAX_ACTIVE_COUNT,
): CemitFilter => {
  const trainFormations = map(prop('trainFormation'), trainGroupOnlyTrainFormations);
  // If there are more that maximumAllowed with the addition of trainFormations, all others will be removed from
  // the filter
  const updatedCemitFilter = removeExcessiveInstancesFromFilterAndAddNew<
    CemitFilterTrainFormation,
    CemitFilterTrainFormationProps
  >(
    isCemitFilterTrainFormation,
    evalCemitFilterTrainFormation,
    extractTrainFormations,
    cemitFilterTrainFormationRightSideExpression,
    CemitTypename.cemitFilterTrainFormation,
    cemitFilter,
    trainFormations,
    props,
    maximumAllowed,
  );
  setCemitFilter(updatedCemitFilter);
  return updatedCemitFilter;
};

// TODO this doesn't do anything. The idea is that it tells the server
// or something how to evaluate the filter against a known list
export const cemitFilterTrainFormationRightSideExpression = {
  view: {lensPath: ['trainGroup', 'trainFormation', 'id']},
};
