import {unless} from 'ramda';
import React, {createElement, isValidElement} from 'react';
import {Button, Tooltip} from '@mui/material';
import {
  CemitComponentProps,
  OnlyComponentProps,
} from '../../../types/propTypes/cemitComponenProps';
import {SvgIconComponent} from '@mui/icons-material';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';

export interface TrainPageSliderButtonSideProps extends CemitComponentProps {
  componentKey: string;
  'aria-label': string;
  iconComponent?: SvgIconComponent;
  defaultIcon?: SvgIconComponent;
  onClick: () => void;
  tooltipMessage: string;
}

/**
 * Creates the button, icon and functionality a side of TrainPageSliderButton
 */
export const TrainPageSliderSideButton = ({
  componentProps,
}: OnlyComponentProps<TrainPageSliderButtonSideProps>) => {
  const iconComponent: SvgIconComponent = unless(
    isValidElement,
    (component) => {
      return createElement(component, {}) as SvgIconComponent;
    },
    componentProps.iconComponent || componentProps.defaultIcon,
  );
  return (
    <Tooltip
      key={componentProps.componentKey}
      {...{arrow: true, title: componentProps.tooltipMessage}}
    >
      <Button
        key={componentProps.componentKey}
        {...{
          color: 'secondary',
          'aria-label': componentProps['aria-label'],
          sx: [
            {
              minWidth: '30px',
              minHeight: '30px',
              width: '30px',
              height: '30px',
              borderRadius: '100%',
              zIndex: 100,
              alignItems: 'center',
            },
            ...toArrayIfNot(componentProps.sx || []),
          ],
          onClick: componentProps.onClick,
        }}
      >
        {' '}
        {iconComponent}{' '}
      </Button>
    </Tooltip>
  );
};
