import {
  chain,
  concat,
  identity,
  join,
  length,
  lensProp,
  map,
  over,
  prop,
  sortBy,
  uniq,
  when,
} from 'ramda';
import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {RailwayLine, RailwayLineMinimized} from '../../../../types/railways/railwayLine';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {ServiceLine} from '../../../../types/trainRouteGroups/serviceLine';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {useCemitApiSwrResolveData} from '../../../cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {OrganizationLoaded} from '../../../../types/organizations/organization.ts';
import {PerhapsIfLoading} from '../../../../types/logic/requireIfLoaded.ts';
import {TrainApiRailwayLinesRoute} from '../../../../types/apis/trainApi';
import {idListsEqual} from '../../../../utils/functional/functionalUtils.ts';
import {idsAndTypenamesEqual} from 'utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * Resolves the RailwayLines from the ServiceLines and optional organization.extraRailwayLines
 * TODO this might go to the API in the future if organization only retures ids
 * @param loading Do nothing if true
 * @param organization
 * @param serviceLines
 * @param railwayLines
 * @param setRailwayLines RailayLines setter
 */
export const useConfiguredApiForRailwayLines = (
  loading: boolean,
  organization: PerhapsIfLoading<typeof loading, OrganizationLoaded>,
  serviceLines: PerhapsIfLoading<typeof loading, ServiceLine[]>,
  railwayLines: PerhapsIfLoading<typeof loading, RailwayLine[]>,
  setRailwayLines: StateSetter<Perhaps<RailwayLine[]>>,
) => {
  const extraRailwayLines: Perhaps<RailwayLine[]> = organization?.extraRailwayLines;

  const railwayLineIds = useNotLoadingMemo(
    loading || !length(serviceLines),
    (serviceLines, extraRailwayLines) => {
      // Get the unique RailwayLines of the ServiceLines
      const minimizedRailwayLines = concat(
        chain<ServiceLine, RailwayLineMinimized>(prop('railwayLines'), serviceLines),
        extraRailwayLines || [],
      );

      return sortBy(identity, uniq(map(prop('id'), minimizedRailwayLines)));
    },
    [serviceLines, extraRailwayLines],
  );

  const {
    data: railwayLinesLoaded,
    isLoading,
    isValidating,
  } = useCemitApiSwrResolveData(
    loading || !length(serviceLines),
    organization,
    'railwayLines',
    railwayLineIds ? {id_in_: railwayLineIds} : {},
  );
  useNotLoadingEffect(
    loading || isLoading || isValidating,
    () => {
      // Only setRailwayLines if ids change. We don't want to override those set by
      // useConfiguredApiForDetailedRailwayLines
      if (!idListsEqual(railwayLinesLoaded, railwayLines)) {
        setRailwayLines(railwayLinesLoaded);
      }
    },
    [railwayLinesLoaded, railwayLines],
  );
};

/**
 * Resolves the Detailed RailwayLines from railwayLines
 * TODO this might go to the API in the future if organization only retures ids
 * @param loading Do nothing if true
 * @param organization
 * @param railwayLines
 * @param setRailwayLines RailwayLines setter
 */
export const useConfiguredApiForDetailedRailwayLines = (
  loading: boolean,
  organization: PerhapsIfLoading<typeof loading, OrganizationLoaded>,
  railwayLines: PerhapsIfLoading<typeof loading, (RailwayLineMinimized | RailwayLine)[]>,
  setRailwayLines: StateSetter<Perhaps<RailwayLine[]>>,
) => {
  // TODO since we currently never change railwayLines for an organization,
  // this will only run once per organization.
  const {
    data: railwayLinesLoaded,
    isLoading,
    isValidating,
  } = useCemitApiSwrResolveData<TrainApiRailwayLinesRoute>(
    loading,
    organization,
    'railwayLines',
    railwayLines ? {id_in_: map(prop('id'), railwayLines)} : {},
    'detail',
  );

  useNotLoadingEffect(
    loading || isLoading || isValidating,
    () => {
      // If the API downloads, completely replace the minimum RailwayLines.
      // TODO This should work with a reference comparison, but does not
      if (!idsAndTypenamesEqual(railwayLinesLoaded, railwayLines)) {
        const railwayLineWithHydratedGeojson: RailwayLine[] = map(
          (railwayLine: RailwayLine): RailwayLine => {
            return over(
              lensProp('geojson'),
              (coordinatePairs: [[number, number], [number, number]]) => {
                return {
                  type: 'FeatureCollection',
                  // Make 2-segment linestrings from the points.
                  // This allows us to hover over a track section on the map and draw parallel tracks
                  features: map((coordinates: [[number, number], [number, number]]) => {
                    return {
                      type: 'Feature',
                      id: join('_', chain(identity, coordinates)),
                      properties: {},
                      geometry: {type: 'LineString', coordinates},
                    };
                  }, coordinatePairs),
                };
              },
              railwayLine,
            ) as RailwayLine;
          },
          railwayLinesLoaded!,
        );
        setRailwayLines(railwayLineWithHydratedGeojson);
      }
    },
    [railwayLinesLoaded, railwayLines],
  );
};
