import React, {useMemo} from 'react';
import {always, compose, cond, equals, join, map, prop, sortBy, T, uniqBy} from 'ramda';
import {cemitColors} from 'theme/cemitColors.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {TrainFormation} from 'types/trains/trainFormation';
import {CollectionDevice} from 'types/sensors/collectionDevice';
import {compact} from '@rescapes/ramda';
import CemitFilterTrainGroupOnlyTrainFormationComponent, {
  CemitFilterTrainGroupOnlyTrainFormationComponentProps,
} from './CemitFilterTrainGroupOnlyTrainFormationComponent.tsx';
import {idsInclude} from 'utils/functional/functionalUtils.ts';
import {ViewSlider} from 'types/propTypes/viewSlider.ts';
import {extractTrainFormationsAsTrainGroups} from 'appUtils/cemitFilterUtils/cemitFilterTrainFormationUtils.ts';
import {CemitFilterTrainFormationsViewProps} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsViewProps.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';

export interface CemitFilterTrainGroupOnlyTrainFormationContainerProps
  extends CemitFilterTrainFormationsViewProps {
  trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation;
}

/**
 * Shows one TrainGroupONlyTrainFormation instance in a list
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainGroupOnlyTrainFormationContainer = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<CemitFilterTrainGroupOnlyTrainFormationContainerProps>): JSX.Element => {
  const {activeTrainGroupFormations, crudTrainGroupOnlyTrainFormations} =
    trainProps.trainGroupOnlyTrainFormationProps;
  const {trainGroupOnlyTrainFormation} = componentProps;

  const collectionDevices: CollectionDevice[] = useMemo(() => {
    const trainFormationCollectionDevices: TrainFormationCollectionDevice[] =
      trainGroupOnlyTrainFormation.trainGroupCollectionDevices;
    // TODO We currently only expect one CDC in this context, but there could be more per TrainFormation
    const collectionDevices: CollectionDevice[] = map(
      (trainFormationCollectionDevice: TrainFormationCollectionDevice) => {
        return trainFormationCollectionDevice.collectionDevice;
      },
      trainFormationCollectionDevices,
    );
    return compose(
      sortBy(prop('name')),
      uniqBy<CollectionDevice, string>(prop('id')),
    )(collectionDevices);
  }, [trainGroupOnlyTrainFormation]);

  /**
   * When a CemitFilterTrainGroupOnlyTrainFormationComponent is clicked, its TrainGroupOnlyTrainFormation is activated
   * and all others in crudTrainGroupOnlyTrainFormations are deactivated unless shift is held. If it is already
   * active, it is deactivated
   */
  const onClick = () => {
    const trainGroupOnlyTrainFormations = extractTrainFormationsAsTrainGroups(
      trainProps.trainGroupOnlyTrainFormationProps!.cemitFilterWithTrainFormations!,
      {
        trainGroupOnlyTrainFormations:
          trainProps.trainGroupOnlyTrainFormationProps.crudTrainGroupOnlyTrainFormations!
            .list,
      },
    );
    // Remove if it's in the filter
    if (idsInclude(trainGroupOnlyTrainFormation, trainGroupOnlyTrainFormations)) {
      componentProps.handleRemoveTrainGroupOnlyTrainFormationFromFilters(
        trainGroupOnlyTrainFormation,
      );
    }
    // Else add
    else {
      componentProps.handleAddTrainGroupOnlyTrainFormationToFilter(
        trainGroupOnlyTrainFormation,
      );
    }
  };

  const trainFormation: TrainFormation = trainGroupOnlyTrainFormation!.trainFormation!;
  const trainFormationLabel = `${appProps.t('train')}: ${trainFormation.name}`;
  const collectionDeviceLabel = `${appProps.t('cdc')}: ${join(', ', map(prop('name'), collectionDevices))}`;

  const iconSx = {
    // Only color when active
    color: trainGroupOnlyTrainFormation?.activity?.isActive
      ? trainGroupOnlyTrainFormation?.activity?.isActiveColor
      : cemitColors.white,
  };
  const backgroundColor = cond([
    // When the trainGroupOnlyTrainFormation, use the highlight color
    [
      (trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation) => {
        return idsInclude(trainGroupOnlyTrainFormation, activeTrainGroupFormations);
      },
      always(cemitColors.transparentWhite50),
    ],
    // When the view is collapsed, color it transparent white so it's not obscurred by the map
    [
      () => {
        return equals(appProps.viewSlider, ViewSlider.collapsed);
      },
      always(cemitColors.transparentWhite),
    ],
    // No background
    [T, always(undefined)],
  ])(trainGroupOnlyTrainFormation);
  // Fill the background if the trainGroupOnlyTrainFormation is active
  const stackSx = useMemo(() => {
    return compact({backgroundColor});
  }, [activeTrainGroupFormations, trainGroupOnlyTrainFormation, backgroundColor]);

  return (
    <CemitFilterTrainGroupOnlyTrainFormationComponent
      {...{
        appProps,
        trainProps,
        componentProps: {
          iconSx,
          stackSx,
          onClick,
          trainFormationLabel,
          collectionDeviceLabel,
          trainGroupOnlyTrainFormation,
        } as CemitFilterTrainGroupOnlyTrainFormationComponentProps,
      }}
    />
  );
};
export default CemitFilterTrainGroupOnlyTrainFormationContainer;
