// Map the railway abbreviations in the SensorDataPoint Feature Railway property to the full name
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {RailwayLineMinimized} from 'types/railways/railwayLine';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {fromPairs, map} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * Returns a lookup of each RailwayLine from the apiKey property to the RailwayLine.name
 * This helps resolve the full name from SensorDataPoints that have the apiKey value
 * @param trainProps
 */
export const useNotLoadingRailwayKeyToNameLookup = (
  trainProps: TrainProps,
): Perhaps<Record<string, string>> => {
  return useNotLoadingMemo(
    trainProps.railwayLineProps.loading,
    (railwayLines) => {
      return fromPairs(
        map<RailwayLineMinimized, [string, string]>(
          (railwayLine: RailwayLineMinimized) => {
            return [railwayLine.apiKey, railwayLine.shortName || railwayLine.name];
          },
          railwayLines,
        ),
      );
    },
    [trainProps.railwayLineProps.railwayLines] as const,
  );
};
