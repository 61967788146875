import {CemitTypename} from '../../types/cemitTypename.ts';
import {
  CemitFilterClass,
  CemitFilterDateIntervalClass,
  CemitFilterDateRecurrenceClass,
  CemitFilterDateRecurrenceDateClass,
  CemitFilterDateRecurrenceDayClass,
  CemitFilterDateRecurrenceTimeClass,
  CemitFilterTrainAttributeAlertChartClass,
  CemitFilterTrainAttributeAlertClass,
  CemitFilterTrainFormationClass,
  CemitFilterTrainRouteGroupClass,
} from '../trainAppCemitedClasses/cemitAppCemitFilterClasses.ts';
import {
  TrainRouteGroupClass,
  TrainRouteGroupMinimizedClass,
  TrainRouteGroupDerivedClass,
  TrainRouteOrGroupTrackDataClass,
  TrainRouteOrGroupTrackDataDerivedClass,
} from '../trainAppCemitedClasses/trainRouteGroupClasses.ts';
import {
  OrderedRoutePointClass,
  OrderedRoutePointDerivedClass,
  RoutePointClass,
  RoutePointDerivedClass,
} from '../trainAppCemitedClasses/routePointClasses.ts';
import {
  TrainGroupClass,
  TrainGroupIncompleteClass,
  TrainGroupMinimizedClass,
} from 'classes/trainAppCemitedClasses/trainGroupClasses.ts';
import {
  TrainFormationClass,
  TrainFormationMinimizedClass,
} from '../trainAppCemitedClasses/trainFormationClasses.ts';
import {
  ServiceLineClass,
  ServiceLineMinimizedClass,
} from '../trainAppCemitedClasses/serviceLineClasses.ts';
import {
  RailwayLineClass,
  RailwayLineMinimizedClass,
} from '../trainAppCemitedClasses/railwayLineClasses.ts';
import {
  TrainDerivedPropsClass,
  TrainLoadedPropsClass,
  TrainPropsClass,
} from '../trainAppCemitedClasses/trainPropsClasses.ts';
import {
  RailwayLineDerivedPropsClass,
  RailwayLineLoadedPropsClass,
  RailwayLineMinimizedPropsClass,
} from '../trainAppCemitedClasses/railwayLinePropClasses.ts';
import {DatePropsClass} from '../trainAppCemitedClasses/datePropsClasses.ts';
import {CemitedClass} from './cemitedClass.ts';
import {memoizeWith} from 'ramda';
import {
  UserStateFailedClass,
  UserStateLoadedClass,
  UserStateMinimizedClass,
} from '../trainAppCemitedClasses/userStateClasses.ts';
import {TrainGroupSingleTrainRunClass} from 'classes/trainAppCemitedClasses/trainGroupSingleTrainRunClass.ts';
import {TrainGroupOnlyTrainFormationClass} from 'classes/trainAppCemitedClasses/trainGroupOnlyTrainFormationClass.ts';
import {
  TrainRouteClass,
  TrainRouteDerivedClass,
  TrainRouteMinimizedClass,
} from 'classes/trainAppCemitedClasses/trainRouteClasses.ts';
import {SensorDataTrainGroupClass} from 'classes/trainAppCemitedClasses/sensorDataTrainGroupClass.ts';
import {TrainRunClass} from 'classes/trainAppCemitedClasses/trainRunClass.ts';
import {WeekYearClass} from 'classes/datetime/weekYearClass.ts';

export const cemitAppCemitedClassesManifest = memoizeWith(
  () => 'true',
  (): Record<CemitTypename, typeof CemitedClass | (typeof CemitedClass)[]> => {
    /**
     * Manifest of CemitTypename to one or more Cemited classes.
     */
    return {
      [CemitTypename.trainRoute]: TrainRouteClass,
      [CemitTypename.trainRouteDerived]: TrainRouteDerivedClass,
      [CemitTypename.trainRouteGroup]: TrainRouteGroupClass,
      [CemitTypename.trainRouteGroupDerived]: TrainRouteGroupDerivedClass,
      [CemitTypename.trainRouteOrGroup]: [TrainRouteClass, TrainRouteGroupClass],
      [CemitTypename.trainRouteOrGroupDerived]: [
        TrainRouteDerivedClass,
        TrainRouteGroupDerivedClass,
      ],
      [CemitTypename.trainRouteOrGroupMinimized]: [
        TrainRouteMinimizedClass,
        TrainRouteGroupMinimizedClass,
      ],
      [CemitTypename.trainRouteMinimized]: TrainRouteMinimizedClass,
      [CemitTypename.trainRouteGroupMinimized]: TrainRouteGroupMinimizedClass,
      [CemitTypename.cemitFilter]: CemitFilterClass,
      [CemitTypename.cemitFilterDateRecurrence]: CemitFilterDateRecurrenceClass,
      [CemitTypename.cemitFilterDateRecurrenceDate]: CemitFilterDateRecurrenceDateClass,
      [CemitTypename.cemitFilterDateRecurrenceTime]: CemitFilterDateRecurrenceTimeClass,
      [CemitTypename.cemitFilterDateRecurrenceDay]: CemitFilterDateRecurrenceDayClass,
      [CemitTypename.cemitFilterDateInterval]: CemitFilterDateIntervalClass,
      [CemitTypename.cemitFilterTrainRouteGroup]: CemitFilterTrainRouteGroupClass,
      [CemitTypename.cemitFilterTrainFormation]: CemitFilterTrainFormationClass,
      [CemitTypename.cemitFilterTrainAttributeAlert]: CemitFilterTrainAttributeAlertClass,
      [CemitTypename.cemitFilterTrainAttributeAlertChart]:
        CemitFilterTrainAttributeAlertChartClass,
      [CemitTypename.trainRouteOrGroupTrackData]: TrainRouteOrGroupTrackDataClass,
      [CemitTypename.trainRouteOrGroupTrackDataDerived]:
        TrainRouteOrGroupTrackDataDerivedClass,
      [CemitTypename.trainGroupOnlyTrainFormation]: TrainGroupOnlyTrainFormationClass,
      [CemitTypename.trainGroupSingleTrainRun]: TrainGroupSingleTrainRunClass,
      [CemitTypename.trainGroup]: TrainGroupClass,
      [CemitTypename.routePoint]: RoutePointClass,
      [CemitTypename.routePointDerived]: RoutePointDerivedClass,
      [CemitTypename.orderedRoutePoint]: OrderedRoutePointClass,
      [CemitTypename.orderedRoutePointDerived]: OrderedRoutePointDerivedClass,
      [CemitTypename.trainGroupMinimized]: TrainGroupMinimizedClass,
      [CemitTypename.trainGroupIncomplete]: TrainGroupIncompleteClass,
      [CemitTypename.trainFormationMinimized]: TrainFormationMinimizedClass,
      [CemitTypename.trainFormation]: TrainFormationClass,
      [CemitTypename.serviceLineMinimized]: ServiceLineMinimizedClass,
      [CemitTypename.serviceLine]: ServiceLineClass,
      [CemitTypename.railwayLineMinimized]: RailwayLineMinimizedClass,
      [CemitTypename.railwayLine]: RailwayLineClass,
      [CemitTypename.trainProps]: TrainPropsClass,
      [CemitTypename.trainLoadedProps]: TrainLoadedPropsClass,
      [CemitTypename.trainDerivedProps]: TrainDerivedPropsClass,
      [CemitTypename.railwayLineMinimizedProps]: RailwayLineMinimizedPropsClass,
      [CemitTypename.railwayLineLoadedProps]: RailwayLineLoadedPropsClass,
      [CemitTypename.railwayLineDerivedProps]: RailwayLineDerivedPropsClass,
      [CemitTypename.dateProps]: DatePropsClass,
      [CemitTypename.userStateMinimized]: UserStateMinimizedClass,
      [CemitTypename.userStateFailed]: UserStateFailedClass,
      [CemitTypename.userStateLoaded]: UserStateLoadedClass,
      [CemitTypename.sensorDataTrainGroup]: SensorDataTrainGroupClass,
      [CemitTypename.trainRun]: TrainRunClass,
      [CemitTypename.weekYear]: WeekYearClass,
    };
  },
);
