import {SWRConfig} from 'swr';
import {compose, includes, lensProp, map, set, when} from 'ramda';
import {TRAIN_API_DATE_ATTRIBUTES} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import React from 'react';
import {fetcher} from 'appUtils/apiUtils/apiUtils.ts';

/**
 * Deserialize keys that include 'datetime' and 'time' back to a Date start_date, 'source:datetime', departureDatetime, arrivalDatetime, medianDatetime
 * @param jsonKey
 * @param value
 * @return {*}
 */
export const reviver = (jsonKey: string, value: any) => {
  return when(
    () => {
      return includes(jsonKey, TRAIN_API_DATE_ATTRIBUTES);
    },
    (date) => {
      return Array.isArray(date)
        ? map((date) => {
            return new Date(date);
          }, date)
        : new Date(date);
    },
  )(value);
};

export type FetcherOptions = {
  abortController?: AbortController;
  retries?: number;
  timeout?: number | undefined;
  requestMethod: 'POST';
};

const TrainSWRContainer = ({
  abortController,
  children,
}: {
  abortController: AbortController;
  children: React.ReactNode;
}) => {
  return (
    <SWRConfig
      {...{
        value: {
          revalidateOnFocus: false,
          fetcher: (...props) => {
            return fetcher({abortController}, ...props);
          },
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
export default TrainSWRContainer;
