import { Typography } from '@mui/material';
import { CollectionDevice } from '../../../../../types/sensors/collectionDevice';
import React from 'react';

/**
 * Shows the name and position of a collectionDevice as a simple label
 * @param collectionDevice
 * @returns {JSX.Element}
 */
const CollectionDevice = ({ collectionDevice }: {collectionDevice: CollectionDevice}): JSX.Element => {
  return <Typography variant='caption'>
    {collectionDevice.name}
  </Typography>;
};
export default CollectionDevice