import {anyImplementCemitType} from '../cemitAppCemitedClasses/cemitClassResolvers.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {
  RailwayLineDerivedProps,
  RailwayLineLoadedProps,
  RailwayLineMinimizedProps,
} from '../../types/propTypes/trainPropTypes/railwayLineProps';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {RailwayLine, RailwayLineMinimized} from '../../types/railways/railwayLine';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {PropStatusClass} from './propStatusClass.ts';
import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';

export class RailwayLineMinimizedPropsClass
  extends PropStatusClass<
    RailwayLineMinimizedPropsClass,
    RailwayLineLoadedPropsClass,
    RailwayLineDerivedPropsClass
  >
  implements RailwayLineMinimizedProps
{
  railwayLines: Perhaps<RailwayLineMinimized[]>;
  setRailwayLines: StateSetter<Perhaps<RailwayLineMinimized[]>>;

  constructor(obj: RailwayLineMinimizedProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }
  /**
   * For RailwayLineProps, the Loaded implementor we check is
   * railwayLines, which must be RailwayLine.
   * If they are return  RailwayLineProps to RailwayLinePropsDetail
   */
  get maybeAsLoaded(): Perhaps<RailwayLineLoadedProps> {
    if (
      anyImplementCemitType<RailwayLine>(CemitTypename.railwayLine, this.railwayLines)
    ) {
      return clsOrType<RailwayLineLoadedProps>(
        CemitTypename.railwayLineLoadedProps,
        this,
      );
    }
    return undefined;
  }

  /**
   * No derived version yet
   */
  get maybeAsDerived(): Perhaps<RailwayLineLoadedProps> {
    return undefined;
  }
}

export class RailwayLineLoadedPropsClass
  extends RailwayLineMinimizedPropsClass
  implements RailwayLineLoadedProps
{
  __typename: CemitTypename;

  constructor(obj: RailwayLineLoadedProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}

export class RailwayLineDerivedPropsClass
  extends RailwayLineLoadedPropsClass
  implements RailwayLineDerivedProps
{
  constructor(obj: RailwayLineDerivedProps) {
    super(obj);
    cemitedConstructor(obj, this);
  }
}
