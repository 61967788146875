import React from 'react';

export const EmergencyBrakingSvgIcon = ({
  fill = '#000000',
  width = '100%',
  height = '100%',
  trainGroupFill = undefined,
}) => {
  trainGroupFill = trainGroupFill || fill;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10C10.4477 10 10 10.4477 10 11C10 11.5523 10.4477 12 11 12ZM11 14C12.6569 14 14 12.6569 14 11C14 9.34315 12.6569 8 11 8C9.34315 8 8 9.34315 8 11C8 12.6569 9.34315 14 11 14Z"
        fill={trainGroupFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C7.08693 4 4 7.08174 4 10.7794C4 12.6404 4.7724 14.3314 6.04265 15.5655L3.25531 18.4345C1.25299 16.4891 0 13.78 0 10.7794C0 4.77961 4.97194 0 11 0V4Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9151 9.81625C18.9713 10.2055 19 10.6011 19 11C19 15.445 15.5036 19 11.2492 19C9.10979 19 6.64883 17.8051 5.22383 16.3101L3.77612 17.69C5.5275 19.5274 8.49286 21 11.2492 21C16.6607 21 21 16.4962 21 11C21 10.2927 20.9265 9.59313 20.7844 8.91204C20.2321 9.32388 19.5998 9.63446 18.9151 9.81625ZM12.1918 3.05492C12.3782 2.36876 12.694 1.73591 13.1115 1.18423C12.1177 0.977898 11.0491 0.909136 9.91693 1.00348L10.083 2.99658C10.822 2.93499 11.5264 2.95796 12.1918 3.05492Z"
        fill={fill}
      />
      <path d="M19.3001 2.69998H15.7001V6.29998H19.3001V2.69998Z" fill={fill} />
      <path
        d="M17.5 0C15.016 0 13 2.016 13 4.5C13 6.984 15.016 9 17.5 9C19.984 9 22 6.984 22 4.5C22 2.016 19.984 0 17.5 0ZM17.5 8.1C15.5155 8.1 13.9 6.4845 13.9 4.5C13.9 2.5155 15.5155 0.9 17.5 0.9C19.4845 0.9 21.1 2.5155 21.1 4.5C21.1 6.4845 19.4845 8.1 17.5 8.1Z"
        fill={fill}
      />
    </svg>
  );
};
EmergencyBrakingSvgIcon.displayProps = 'EmergencyBrakingSvgIcon';
export default EmergencyBrakingSvgIcon;
