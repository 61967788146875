import {VisionAppTrainDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppTrainDependencyProps';
import {Stack} from '@mui/material';
import VisionWeekYearChooser from 'pages/vision/visionComponents/choosers/VisionWeekYearChooser.tsx';
import TimestampPicker from 'pages/vision/visionComponents/choosers/TimestampChooser.tsx';
import React from 'react';

/**
 * Choose week/year and timestamp based on the results returned from the api in visionProps.visionMetas
 * @param appProps
 * @param organizationProps
 * @param visionProps
 * @constructor
 */
export const VisionChoosers = ({
  appProps,
  organizationProps,
  trainProps,
}: VisionAppTrainDependencyProps) => {
  return (
    <Stack {...{spacing: 1, sx: {width: '100%', padding: 0}}}>
      <VisionWeekYearChooser {...{appProps, trainProps}} />
      <TimestampPicker {...{appProps, organizationProps, trainProps}} />
    </Stack>
  );
};
