import React from 'react';
import {Tooltip, tooltipClasses} from '@mui/material';
import {styled} from '@mui/styles';

const StyledTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme, customWidth}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.surfaceContrast,
    color: theme.palette.common.greyLight,
    maxWidth: customWidth || 220,
    fontSize: theme.typography.pxToRem(10),
  },
}));

const CustomTooltip = ({title, children, customWidth, ...props}) => {
  return (
    <StyledTooltip title={title} {...props}>
      {children}
    </StyledTooltip>
  );
};

export default CustomTooltip;
