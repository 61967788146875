import {
  ChartDataCommonConfigFinalized,
  ChartDataConfigFinalized,
} from 'types/dataVisualizations/chartDataConfig.ts';
import {
  DataFeatureCollection,
  DataFeatureCollectionWithBackReference,
} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import React, {ReactNode, useMemo} from 'react';
import {addIndex, chain, map} from 'ramda';
import {camelCaseAndRemoveSpaces} from 'utils/functional/functionalUtils.ts';
import {Box, Stack, Typography} from '@mui/material';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {ReferenceLine} from 'recharts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {DataFeatureSet} from 'types/dataVisualizations/dateFeatureSet';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';

/**
 * Creates a flat list of ChartComponents
 * from chartDataCommonConfigFinalized.chartDataConfigFinalizeds[*].dataFeatureSets
 * @param chartDataCommonConfigFinalized
 * @param areaName
 * @param label
 */
export const useMemoCreateChartComponents = (
  chartDataCommonConfigFinalized: ChartDataCommonConfigFinalized,
  areaName: (label: string, dataFeatureCollection: DataFeatureCollection) => string,
  label: string,
): Perhaps<JSX.Element[]> => {
  return useMemo(() => {
    return (
      chartDataCommonConfigFinalized.chartDataConfigFinalizeds &&
      chain((chartDataConfigFinalized: ChartDataConfigFinalized) => {
        const ChartComponent = chartDataConfigFinalized.chartDataConfig.chartType;
        if (!ChartComponent) {
          throw new Error(
            'chartDataConfigFinalized.chartDataConfig.chartType must be specified',
          );
        }
        const chartProps = chartDataConfigFinalized.chartDataConfig.chartProps || {};
        const {yAxisDataPath} = chartDataConfigFinalized.chartDataConfig;
        // One Area per DataFeatureSet and per DataFeatureCollection.
        // Each DataFeatureCollection of which represents the combination of one TrainGroup
        // and one set of DataFeatureCollections. The latter can be the geojson of the TrainRouteOrGroup
        // with sensor attributes or for a single AttributeAlert, the TimeSeries history data or trend data
        // of all AttributeAlerts
        // Resolve the dataFeatureCollection of the activeChartDataKey

        return chain((dataFeatureSet: DataFeatureSet) => {
          const dataFeatureCollections = dataFeatureSet.dataFeatureCollections;

          return map((dataFeatureCollection: DataFeatureCollectionWithBackReference) => {
            if (!dataFeatureCollection.isVisible) {
              return undefined;
            }
            const showGradients =
              chartDataConfigFinalized!.chartDataConfig.chartDataCategoryConfig
                .showGradients;
            const dataKey = yAxisDataPath;
            const fill = showGradients
              ? `url(#linearGradient${camelCaseAndRemoveSpaces(dataFeatureCollection.label)})`
              : 'none';
            return (
              <ChartComponent
                key={`${dataFeatureCollection.label}${label}`}
                {...{
                  margin: {top: 20},
                  dot: false,
                  activeDot: false,
                  data: dataFeatureCollection.features,
                  name: areaName(label, dataFeatureCollection),
                  dataKey,
                  stroke: dataFeatureCollection.color,
                  fill,
                  ...chartProps,
                }}
              />
            );
          }, dataFeatureCollections);
        }, chartDataConfigFinalized.dataFeatureSets);
      }, chartDataCommonConfigFinalized.chartDataConfigFinalizeds)
    );
  }, [chartDataCommonConfigFinalized]);
};
/**
 * Creates SVG Gradients for the chart lines if configured
 * @param chartDataConfigFinalizeds
 * @param dataFeatureCollections
 */
export const useMemoGradientDefs = (
  chartDataConfigFinalizeds: ChartDataConfigFinalized[],
  dataFeatureCollections: DataFeatureCollection[],
): JSX.Element => {
  return useNotLoadingMemo(
    !dataFeatureCollections,
    (dataFeatureCollections) => {
      return (
        <defs>
          {/* Stores the clip area for panning and zooming */}
          {/*<RechartsClipPaths ref={clipPathRefs} />*/}

          {/* These are the linear gradients drawn under each graph.
              We create one for each dataFeatureSet.dataFeatureCollections since
              one datFeatureCollection is used per line on the graph
            */}
          {chartDataConfigFinalizeds &&
            map((dataFeatureCollection: DataFeatureCollectionWithBackReference) => {
              const showGradients =
                dataFeatureCollection.chartDataConfigFinalized!.chartDataConfig
                  .chartDataCategoryConfig.showGradients;
              return showGradients ? (
                <linearGradient
                  key={dataFeatureCollection.label}
                  {...{
                    id: `linearGradient${camelCaseAndRemoveSpaces(dataFeatureCollection.label)}`,
                    x1: '0',
                    y1: '0',
                    x2: '0',
                    y2: '1',
                  }}
                >
                  <stop
                    {...{
                      offset: '0%',
                      stopColor: dataFeatureCollection.color,
                      stopOpacity: 0.2,
                    }}
                  />
                  <stop
                    {...{
                      offset: '95%',
                      stopColor: dataFeatureCollection.color,
                      stopOpacity: 0,
                    }}
                  />
                </linearGradient>
              ) : undefined;
            }, dataFeatureCollections)}
        </defs>
      );
    },
    [dataFeatureCollections],
  );
};
/**
 * The Chart header with possible custom controls
 * @param chartDataCommonConfigFinalized
 * @param label
 */
export const useMemoChartHeaderLabelAndControls = (
  chartDataCommonConfigFinalized: ChartDataCommonConfigFinalized,
  label: string,
): JSX.Element => {
  return useMemo(() => {
    return (
      <Stack direction="row" spacing={3}>
        <Stack
          {...{
            direction: 'row',
            sx: {
              alignItems: 'center',
            },
          }}
        >
          <Typography color="secondary.main">{label}</Typography>
        </Stack>
        {
          // Custom controls. Wrapped in a box with a unique key and chained together
          addIndex(chain)(
            (chartDataConfigFinalized: ChartDataConfigFinalized, i: number) => {
              return addIndex(map)((customControl: ReactNode, j: number) => {
                return <Box key={`customControl${i + 1}-${j + 1}`}>{customControl}</Box>;
              }, chartDataConfigFinalized.chartDataConfig.customControls || []);
            },
            chartDataCommonConfigFinalized.chartDataConfigFinalizeds,
          )
        }
      </Stack>
    );
  });
};
/**
 * Chart reference lines if configured
 * @param appProps
 * @param chartDataConfigFinalizeds
 */
export const useMemoReferenceLines = (
  appProps: TrainAppProps,
  chartDataConfigFinalizeds: ChartDataConfigFinalized,
): (typeof ReferenceLine)[] => {
  return useMemo(() => {
    // Extract chartDataConfigFinalizeds from each chartDataConfigFinalized if defined
    return chain((chartDataConfigFinalized: ChartDataConfigFinalized) => {
      const referenceLines = chartDataConfigFinalized.chartDataConfig.referenceLines;
      return referenceLines
        ? referenceLines(appProps, chartDataConfigFinalized.dataFeatureSets)
        : [];
    }, chartDataConfigFinalizeds);
  }, [chartDataConfigFinalizeds]);
};
