import {useQuery} from 'react-query';

import {Datastream} from 'pages/sandbox/types/datastream';

const fetchDataStream = async (datastreamId: string): Promise<Datastream> => {
  const headerUser: string = process.env.REACT_APP_X_USER_EMAIL!;
  const headerUserToken: string = process.env.REACT_APP_X_USER_TOKEN!;

  const response = await fetch(
    `https://iot-rest-prod.urban.io/api/v1/datastream/${datastreamId}`,
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-User-Email': headerUser,
        'X-User-Token': headerUserToken,
      },
    },
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const useDataStreamsForSandBox = (datastreamIds: string[]) => {
  const queryResults = useQuery(
    ['dataStreams', datastreamIds],
    async () => {
      const results = await Promise.allSettled(
        datastreamIds.map((datastreamId) => fetchDataStream(datastreamId)),
      );
      const data = results
        .filter((result) => result.status === 'fulfilled')
        .map((result: PromiseFulfilledResult<Datastream>) => result.value);
      const errors = results
        .filter((result) => result.status === 'rejected')
        .map((result: PromiseRejectedResult) => result.reason);
      return {data, errors};
    },
    {
      enabled: datastreamIds.length > 0, // Only fetch if there are datastreamIds
      retry: false, // Disable automatic retries
    },
  );
  return {
    data: queryResults.data?.data,
    errors: queryResults.data?.errors,
    isLoading: queryResults.isLoading,
    refetch: queryResults.refetch,
  };
};

export default useDataStreamsForSandBox;
