import {TFunction} from 'i18next';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  DataThreshold,
  DataThresholdStyles,
  ZoomLevelValue,
} from 'types/dataVisualizations/dataThreshold';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {BrakingAttributeAlertLevel} from 'types/alerts/braking/brakingAttributeAlertLevel.ts';
import {
  CEMIT_ALERT_CRITICAL,
  CEMIT_ALERT_NORMAL,
  CEMIT_ALERT_WARNING,
} from 'theme/cemitColors.ts';
import {BrakingAttributeAlertSValue} from 'types/alerts/braking/brakingAlertLevel.ts';

export const brakingDataThresholds = (t: TFunction) => {
  return clsOrTypes<DataThreshold>(CemitTypename.dataThreshold, [
    {
      sourceKey: BrakingAttributeAlertLevel.softBraking,
      label: t('softBraking'),
      // Below BrakingAttributeAlertSValue.warning
      value: BrakingAttributeAlertSValue.softBraking,
      style: {
        color: CEMIT_ALERT_NORMAL,
        icon: 'softBraking',
        size: [
          {zoom: 0, outputValue: 1} as ZoomLevelValue,
          {zoom: 22, outputValue: 1} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
    {
      sourceKey: BrakingAttributeAlertLevel.normalBraking,
      label: t('normalBraking'),
      // Below BrakingAttributeAlertSValue.warning
      value: BrakingAttributeAlertSValue.normalBraking,
      style: {
        color: CEMIT_ALERT_NORMAL,
        icon: 'normalBraking',
        size: [
          {zoom: 0, outputValue: 1} as ZoomLevelValue,
          {zoom: 22, outputValue: 1} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
    {
      sourceKey: BrakingAttributeAlertLevel.hardBraking,
      label: t('hardBraking'),
      // from RideComfortAttributeAlertSValue.warning to RideComfortAttributeAlertSValue.critical
      value: BrakingAttributeAlertSValue.hardBraking,
      iconConfigSize: 12,
      style: {
        color: CEMIT_ALERT_WARNING,
        icon: 'hardBraking',
        size: [
          // For value warning, show these outputValues for circle-radius at each zoom level
          // TODO Keep the outputValue at 1 to prevent blurriness. We use the Svg image holder size
          // to make this icon bigger without blurring
          {zoom: 0, outputValue: 1} as ZoomLevelValue,
          {zoom: 22, outputValue: 1} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
    {
      sourceKey: BrakingAttributeAlertLevel.emergencyBraking,
      label: t('emergencyBraking'),
      // All values above RideComfortAttributeAlertSValue.critical
      value: BrakingAttributeAlertSValue.emergencyBraking,
      iconConfigSize: 15,
      style: {
        color: CEMIT_ALERT_CRITICAL,
        // TODO This icon name must match BrakingAttributeAlertLevel.emergencyBraking because of the way
        // the code matches values to icons. It shouldn't be this way
        icon: 'emergencyBraking',
        size: [
          // For value error, show these outputValues for circle-radius at each zoom level
          // TODO Keep the outputValue at 1 to prevent blurriness. We use the Svg image holder size
          // to make this icon bigger without blurring
          {zoom: 0, outputValue: 1} as ZoomLevelValue,
          {zoom: 22, outputValue: 1} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
    {
      sourceKey: BrakingAttributeAlertLevel.fullEmergencyBraking,
      label: t('fullEmergencyBraking'),
      // All values above RideComfortAttributeAlertSValue.critical
      value: BrakingAttributeAlertSValue.fullEmergencyBraking,
      iconConfigSize: 15,
      style: {
        color: CEMIT_ALERT_CRITICAL,
        // TODO This icon name must match BrakingAttributeAlertLevel.fullEergencyBraking because of the way
        // the code matches values to icons. It shouldn't be this way
        icon: 'fullEmergencyBraking',
        size: [
          // For value error, show these outputValues for circle-radius at each zoom level
          // TODO Keep the outputValue at 1 to prevent blurriness. We use the Svg image holder size
          // to make this icon bigger without blurring
          {zoom: 0, outputValue: 1} as ZoomLevelValue,
          {zoom: 22, outputValue: 1} as ZoomLevelValue,
        ],
      } as Partial<DataThresholdStyles>,
    },
  ]);
};
