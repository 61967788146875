import {ReactElement, useMemo, useState} from 'react';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useWhatIsLoading} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {TrainMapSensorLayerProps} from 'types/propTypes/mapPropTypes/trainMapSensorLayerProps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {
  useNotLoadingEffectUpdateAlertLayerForRealtimeTrainMap,
  UseUpdate3dForRealtimeTrainMapHookProps,
} from 'async/realtimeTrainAsync/realtimeTrainLayersHooks.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';

/***
 * Loads/Updates MapData layers dependent on sensor data
 * Depends directly on TrainGroupSensor props in trainProps.trainGroupSingleTrainRunProps.geojsonProps and TrainMap props
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const RealtimeTrainMapSensorLayerDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement<
  Required<TrainAppMapDependencyProps>
> => {
  const [areRealtimeLayersUpdating, setAreRealtimeLayersUpdating] = useState(false);

  const hookProps: UseUpdate3dForRealtimeTrainMapHookProps =
    useNotLoadingMemo(loading, () => {
      return {
        trainMap: mapProps.trainMap,
        featurePropPath: 'numericValue',
        areRealtimeLayersUpdating,
        setAreRealtimeLayersUpdating,
      } as UseUpdate3dForRealtimeTrainMapHookProps;
    }, [areRealtimeLayersUpdating, mapProps.trainMap]);

  // Sets the Mapbox source/layers. This data is only stored in Mapbox and not stored in the active TrainGroups
  useNotLoadingEffectUpdateAlertLayerForRealtimeTrainMap(
    loading || !appProps.realtimeIsActive,
    {
      appProps,
      organizationProps,
      trainProps,
      mapProps,
      hookProps,
    },
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(RealtimeTrainMapSensorLayerDependency.name),
    RealtimeTrainMapSensorLayerDependency.name,
    {
      areRealtimeLayersUpdating: !areRealtimeLayersUpdating,
    },
    [areRealtimeLayersUpdating],
    appProps.setWhatDependenciesAreLoading,
  );

  const trainMapSensorLayerProps: TrainMapSensorLayerProps =
    useMemoClsOrType<TrainMapSensorLayerProps>(CemitTypename.trainMapSensorProps, {
      whatIsLoading,
      areLayersUpdating: areRealtimeLayersUpdating,
      setAreLayersUpdating: setAreRealtimeLayersUpdating,
    });

  // TODO create a useMemoMergeMapProps so we can track if any mapProps are loading and why
  const mapPropsMerged = useMemo(() => {
    return {
      trainMapSensorLayerProps,
      ...mapProps,
    };
  }, [mapProps, areRealtimeLayersUpdating]);

  return renderChildren({
    appProps,
    organizationProps,
    mapProps: mapPropsMerged,
    trainProps,
  });
};

RealtimeTrainMapSensorLayerDependency.displayName =
  'RealtimeTrainMapSensorLayerDependency';
export default RealtimeTrainMapSensorLayerDependency;
//export default memo(
//  RealtimeTrainMapSensorLayerDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
