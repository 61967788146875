import {colorHue} from 'theme/colors/colorHue/colorHue.ts';

export const MapColors = {
  // Light Mode

  light: {
    map: {
      white: colorHue.grey[100],
      grey: colorHue.grey[500],
      greyLight: colorHue.grey[600],
      green: colorHue.green[700],
      orange: colorHue.gold[400],
      gold: colorHue.gold[500],
      red: colorHue.red[400],
      blue: colorHue.blue[500],
      purple: colorHue.purple[500],
      pink: colorHue.pink[700],
      ultramarine: colorHue.ultramarine[500],
      limeGreen: colorHue.limeGreen[700],
      tangerine: colorHue.tangerine[600],
    },
  },
  // Dark Mode
  dark: {
    map: {
      white: colorHue.grey[50],
      grey: colorHue.grey[200],
      greyLight: colorHue.grey[300],
      green: colorHue.green[500],
      orange: colorHue.gold[300],
      gold: colorHue.gold[300],
      red: colorHue.red[400],
      blue: colorHue.blue[400],
      purple: colorHue.purple[400],
      pink: colorHue.pink[400],
      ultramarine: colorHue.ultramarine[400],
      limeGreen: colorHue.limeGreen[400],
      tangerine: colorHue.tangerine[400],
    },
  },
};
