import {mapObjToValues} from '@rescapes/ramda';
import {format, isValid, subMinutes} from 'date-fns';
import {
  extractAndEvaluateMatchingFilters,
  updateFilterTypeInFilters,
} from 'appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {
  both,
  chain,
  complement,
  equals,
  filter,
  head,
  is,
  join,
  length,
  lensProp,
  map,
  propOr,
  props as rProps,
  when,
} from 'ramda';
import {evalFilter} from 'appUtils/cemitFilterUtils/cemitFilterEval.ts';
import {CemitFilterDateInterval} from 'types/cemitFilters/cemitFilterDateInterval';
import {CemitFilterProps} from 'types/cemitFilters/cemitFilterProps';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {TFunction} from 'i18next';
import {typenameEquals} from '../typeUtils/typenameUtils.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {consolidateIntervals} from 'utils/ranges/rangeUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {createDateInterval} from 'classes/typeCrud/dateIntervalCrud.ts';
import {
  overClassOrType,
  setClassOrType,
} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {memoizedWithClassifyOrTypeObject} from '../typeUtils/memoizedWithClassifyOrTypeObject.ts';
import {trainDataFriendlyDateFormatString} from 'utils/datetime/timeUtils.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';
import {onlyOneValueOrNoneThrow} from 'utils/functional/functionalUtils.ts';

export const cemitFilterDateIntervalRightSideExpression = {
  view: {lensPath: ['trainRun', 'departureDatetime']},
};
/**
 * Returns true if the object represents a Date range comparison
 * @param obj
 * @returns {{return}}
 */
export const isCemitFilterDateInterval = (obj: CemitFilter): boolean => {
  return typenameEquals(CemitTypename.cemitFilterDateInterval, obj);
};

/**
 * Converts the first argument of each comparison function to a value if
 * it is an expression like a view
 * @param cemitFilterDateInterval
 * @param props
 * @returns {*}
 */
export const evalCemitFilterDateInterval = (
  cemitFilterDateInterval: CemitFilterDateInterval,
  props: CemitFilterProps,
): CemitFilterDateInterval => {
  if (!propOr(false, 'and', cemitFilterDateInterval)) {
    return cemitFilterDateInterval;
  }

  return overClassOrType(
    //@ts-ignore
    lensProp('and'),
    // We ignore the keys here: lt, lte, gt, gte since we can't evaluate fully
    (andObj: [CemitFilter, ...any[]]) => {
      return map(([comparatorFirstValue, ...rest]: [CemitFilter, ...any[]]) => {
        return [
          when(
            // If the first comparator argument is an object, evaluate it as
            // a filter
            both(complement(Array.isArray), is(Object)),
            (comparatorFirstValue: CemitFilter) => {
              // Must be a view that resolves to a datetime
              return evalFilter(comparatorFirstValue, props);
            },
          )(comparatorFirstValue),
          ...rest,
        ];
      }, andObj);
    },
    cemitFilterDateInterval,
  );
};

/**
 * Extracts DateInterval filters from the give cemitFilter
 */
export const extractCemitFilterDateIntervals = (
  cemitFilter: CemitFilter,
  props: Perhaps<CemitFilterProps> = undefined,
): CemitFilterDateInterval[] => {
  const dateIntervalEvaluatedFilters = extractAndEvaluateMatchingFilters(
    isCemitFilterDateInterval,
    evalCemitFilterDateInterval,
    cemitFilter,
    props,
  );
  return dateIntervalEvaluatedFilters;
};

/**
 * Extracts dateIntervals from the cemitFilter in the form {start: datetime, end: datetime}
 * @param cemitFilter
 * @param props
 * @returns {[{start, end}, ...]}
 */
export const extractDateIntervals = (
  cemitFilter: CemitFilter,
  props: CemitFilterProps = {},
): DateInterval[] => {
  // The cemitFilter passed in might already be a CemitFilterDateInterval or a top-level filter
  const dateIntervalEvaluatedFilters: CemitFilterDateInterval[] =
    extractCemitFilterDateIntervals(cemitFilter, props);
  return map(
    (dateIntervalEvaluatedFilter: CemitFilterDateInterval) => {
      const [start, end] = mapObjToValues(([comparatorFirstValue]: [Date]) => {
        // TODO we could evaluate the comparator here and return inclusive or exclusive along wiht the value
        return comparatorFirstValue;
      }, dateIntervalEvaluatedFilter['and']);
      return createDateInterval(CemitTypename.dateInterval, start, end);
    },
    // Ignore CemitFilterDateIntervals that haven't been initialized
    filter((dateIntervalEvaluatedFilter) => {
      return propOr(false, 'and', dateIntervalEvaluatedFilter);
    }, dateIntervalEvaluatedFilters),
  );
};

/**
 * Given a filter that represents a Date range comparison, convert it to
 * a readable string
 * @param t
 * @param cemitFilter
 * @param props
 * @returns {[String]} Returns a label for each datetime range comparison.
 * These can be combined as needed by the caller
 */
export const extractLabelsForDateIntervals = (
  {
    t,
  }: {
    t: TFunction;
  },
  cemitFilter: CemitFilterDateInterval,
  props: CemitFilterProps,
) => {
  const dateIntervals = extractDateIntervals(cemitFilter, props);
  return map((dateInterval: DateInterval) => {
    return dateIntervalLabel({t}, dateInterval);
  }, dateIntervals);
};

/**
 * Creates a label for the datetime range in the form t('between') start datetime t('and') end datetime
 * @param t
 * @param dateInterval
 * @returns {*}
 */
export const dateIntervalLabel = (
  {
    t,
  }: {
    t: TFunction;
  },
  dateInterval: DateInterval,
) => {
  const friendlyDateTimeStrings = map(
    (date: Date) => {
      return format(date, trainDataFriendlyDateFormatString);
    },
    rProps(['start', 'end'], dateInterval),
  );
  return join(' ', [join(` ${t('to')} `, friendlyDateTimeStrings)]);
};

/**
 * Primitively adds a new dateIntervalFilters the top level of the cemitFilter
 * If dates already exists, this takes the intersection of the old and new dates.
 * The purpose of this is that there is first a datetime range selected based on the available dates of the
 * TrainFormations (which might be infinite) and then the user might want to reduce that range further
 * to the dates available for the selected TrainRouteOrGroup
 * @param cemitFilter The CemitFilterDateInterval filter whose any property we want to set to a single array value
 * this is currently always a filter at the top filter allPass level
 * @param incomingDateIntervalsFilters One DateIntervalFilter or list of DateIntervalFilter to add
 * @param props
 * @returns {*}
 */
export const mergeDateFilterRanges = (
  cemitFilter: CemitFilter,
  incomingDateIntervalsFilters: CemitFilterDateInterval[],
  props: CemitFilterProps = {},
): CemitFilterDateInterval => {
  const updateFunc = (existingOrEmptyDateIntervalsFilter: CemitFilterDateInterval) => {
    const existingDateIntervals = extractDateIntervals(
      existingOrEmptyDateIntervalsFilter,
      props,
    );
    const incomingDateIntervals = chain(
      (incomingDateIntervalsFilter: CemitFilterDateInterval) => {
        return extractDateIntervals(incomingDateIntervalsFilter, props);
      },
      incomingDateIntervalsFilters,
    );
    const dateIntervals = consolidateIntervals<DateInterval>(
      existingDateIntervals,
      incomingDateIntervals,
    );
    // If the dateIntervals don't overlap, ignore the existing and take the incoming
    // TODO This is because the existing defaulted to today's datetime. Cleanup later
    const dateInterval =
      length(dateIntervals) > 1 ? head(incomingDateIntervals) : head(dateIntervals);
    const mergedDatedRangeFilter = dateInterval
      ? createDateIntervalFilter(dateInterval)
      : existingOrEmptyDateIntervalsFilter;
    return mergedDatedRangeFilter;
  };
  // Find the position of the CemitFilterDateInterval in the top-level allPass array. If no CemitFilterDateInterval
  // is found in allPass, a new CemitFilterDateInterval is created at the end of that array
  const updatedCemitedFilter = updateFilterTypeInFilters(
    cemitFilter,
    isCemitFilterDateInterval,
    updateFunc,
    CemitTypename.cemitFilterDateInterval,
    props,
  );
  if (!length(extractDateIntervals(updatedCemitedFilter))) {
    // If no dateIntervals exist, return the parent instead of an empty filter
    return cemitFilter;
  }
  // Set the parent to cemitFilter of the new filter
  return setClassOrType(lensProp('parent'), cemitFilter, updatedCemitedFilter);
};

/**
 * Creates a new CemitFilterDateInterval
 * @param dateInterval
 * @param dateInterval.start
 * @param dateInterval.end
 * @returns
 */
export const createDateIntervalFilter = (
  dateInterval: DateInterval,
): CemitFilterDateInterval => {
  if (!dateInterval.__typename) {
    throw new Error('dateInterval must be marked with a __typename');
  }
  if (!(isValid(dateInterval.start) && isValid(dateInterval.end))) {
    throw new Error('dateInterval has InvalidDates');
  }
  return memoizedWithClassifyOrTypeObject(
    CemitTypename.cemitFilterDateInterval,
    {
      and: {
        gte: [dateInterval.start, cemitFilterDateIntervalRightSideExpression],
        lte: [dateInterval.end, cemitFilterDateIntervalRightSideExpression],
      },
    },
    // Cache based on this
    [dateInterval],
  );
};

/**
 * Updates the CemitFilter to the chosenDate if it is different than the current date in the CemitFilter
 * @param dateIntervalDescription
 * @param chosenDate
 * @param cemitFitlerWithDateIntervals
 * @param setCemitFitlerWithDateIntervals
 */
export const updateCemitFilterDateIntervalIfChosenDateDiffers = (
  dateIntervalDescription: DateIntervalDescription,
  chosenDate: Date,
  cemitFitlerWithDateIntervals: CemitFilter,
  setCemitFitlerWithDateIntervals: (dateInterval: DateInterval) => void,
) => {
  if (!isNaN(chosenDate as number)) {
    // The interval of time before chosenDate is determined by
    // trainProps.rideComfortConfigProps!.dateIntervalDescription!.duration
    const computedStartDate: Date = subMinutes(
      chosenDate,
      dateIntervalDescription!.duration!,
    );
    const chosenDateInterval = createDateInterval(
      CemitTypename.dateInterval,
      computedStartDate,
      chosenDate,
    );
    const dateInterval: Perhaps<DateInterval> = onlyOneValueOrNoneThrow(
      extractDateIntervals(cemitFitlerWithDateIntervals),
    );
    if (!dateInterval || !equals(dateInterval, chosenDateInterval)) {
      setCemitFitlerWithDateIntervals(chosenDateInterval);
    }
  }
};
