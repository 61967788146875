import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';

import {IdentifiedClass} from '../cemitAppCemitedClasses/identifiedClass.ts';
import {
  UserStateFailed,
  UserStateInitial,
  UserStateLoaded,
  UserStateMinimized,
} from 'types/userState/userState';
import {AccessStatus} from 'utils/userTypes.ts';
import {Access} from '../../types/authentication/access';
import {OrganizationLoaded} from '../../types/organizations/organization.ts';

export class UserStateMinimizedClass
  extends IdentifiedClass
  implements UserStateMinimized
{
  constructor(obj: UserStateMinimized) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.userStateMinimized;
}

export class UserStateInitialClass
  extends UserStateMinimizedClass
  implements UserStateInitial
{
  constructor(obj: UserStateInitial) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  status: AccessStatus.Initial;
  __typename = CemitTypename.userStateInitial;
}

export class UserStateFailedClass
  extends UserStateMinimizedClass
  implements UserStateFailed
{
  constructor(obj: UserStateFailed) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  status: AccessStatus.Invalid;
  error?: string;
  sourceKey: string;
  __typename = CemitTypename.userStateFailed;
}

export class UserStateLoadedClass
  extends UserStateMinimizedClass
  implements UserStateLoaded
{
  constructor(obj: UserStateLoaded) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  status: AccessStatus.Authenticated;
  email: string;
  access: Access;
  organization: OrganizationLoaded;
  __typename = CemitTypename.userStateLoaded;
}
