import {
    extractAndEvaluateMatchingFilters,
    updateFilterTypeInFilters
} from 'appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {
    all,
    any,
    both,
    chain,
    compose,
    concat,
    cond,
    difference,
    eqProps,
    equals,
    filter,
    find,
    flatten,
    identity,
    includes,
    is,
    join,
    length,
    lensProp,
    map,
    omit,
    prop,
    propOr,
    range,
    slice,
    sort,
    sortBy,
    T,
    when
} from 'ramda';
import { DateRecurrence } from '../../types/datetime/dateRecurrence.d.ts';
import { TFunction } from 'i18next';
import { CemitFilterDateRecurrence } from '../../types/cemitFilters/cemitFilterDateRecurrence';
import { CemitFilter } from '../../types/cemitFilters/cemitFilter.d.ts';
import { CemitFilterProps } from '../../types/cemitFilters/cemitFilterProps';
import { findOrThrow, toArrayIfNot } from '../../utils/functional/functionalUtils.ts';
import { CemitTypename } from '../../types/cemitTypename.ts';
import { DateRecurrenceType } from '../../types/datetime/dateRecurrenceTypeEnum.ts';
import { overClassOrType } from '../../utils/functional/cemitTypenameFunctionalUtils.ts';

export const cemitFilterDateRecurrenceRightSideExpression = { view: { lensPath: ['trainRun', 'departureDatetime'] } }

/**
 * Primitively checks if a filter has an includes or equals key
 * and if so if the second argument is a call to getDay|getDate
 * to determine if the filter is a dateRecurrence
 * Currently supports searching for days of MONDAY - SUNDAY, WEEKEND, WEEKDAY or 1st or every month
 * but not things like 'every 3rd Wednesday`
 * {
 includes: [
 {
 flatten: [MONDAY, TUESDAY, WEEKEND]
 },
 or equals: MONDAY
 or equals: 15
 {
 call: [
 'getDay'|'getDate',
 cemitFilterDateRecurrenceRightSideExpression
 }
 ]
 }
 */
export const isCemitFilterDateRecurrence = (obj: CemitFilter) => {
    return equals('DateRecurrenceFilter', obj.__typename);
};

/**
 * Partially evaluates a datetime recurrence
 * Currently this just flattens unflat days like weekends
 * @param cemitFilterDateRecurrence
 * @returns {*}
 */
export const evalCemitFilterDateRecurrence = (cemitFilterDateRecurrence: CemitFilterDateRecurrence) => {
    // Get the top-level filter attribute
    const topLevelKey = find((prop: string) => propOr(undefined, prop, cemitFilterDateRecurrence), ['includes', 'equals']);

    return overClassOrType(
        lensProp(topLevelKey),
        // We ignore the keys here: lt, lte, gt, gte since we can't evaluate fully
        ([flattenOrArrayOrValue, ...rest]: [any, ...any[]]) => {
            // Flatten unflat days or dates
            const daysOrDates = when(
                // Flatten the array if the flatten function is given
                both(is(Object), propOr(false, 'flatten')),
                (obj: Record<string, any>) => flatten(obj['flatten'])
            )(flattenOrArrayOrValue);
            return [daysOrDates, ...rest];
        },
        cemitFilterDateRecurrence
    );
};

/**
 * Extract DateRecurrences from the filter
 * @param cemitFilter
 * @param props
 * @returns {[Object]} A list of { type: dayOrDateOrTime, values: daysOrDatesOrTimes };
 */
export const extractCemitFilterDateRecurrences = (cemitFilter: CemitFilter, props: CemitFilterProps): DateRecurrence[]  => {
    const cemitFilterDateRecurrenceFilters: CemitFilterDateRecurrence[] = extractAndEvaluateMatchingFilters(
        isCemitFilterDateRecurrence, evalCemitFilterDateRecurrence ,
        cemitFilter, props
    );
    return map<CemitFilterDateRecurrence, DateRecurrence>(
        (cemitFilterDateRecurrenceFilter: CemitFilterDateRecurrence) => {

            // CemitFilterDateRecurrence must have includes or equals at the top-level
            // @ts-ignore need to ensure find is not undefined
            const topLevelKey: 'includes' | 'equals' = findOrThrow(
                // @ts-ignore
                (prop: string) => propOr(undefined, prop, cemitFilterDateRecurrenceFilters), ['includes', 'equals']
            );


            const [daysOrDatesOrTimes, callFunc] = cemitFilterDateRecurrenceFilter[topLevelKey];
            // Get the first argument of the callFunc to see if it's a getDay or getDate or getTime
            const dayOrDateOrTime = callFunc['call'][0];
            return { type: cemitFilterDateRecurrenceFilter.type , attribute: dayOrDateOrTime, values: daysOrDatesOrTimes } as DateRecurrence;
        },
        cemitFilterDateRecurrenceFilters
    );
};

/**
 * Given a filter that represents a Date range comparison, convert it to
 * a readable string
 * @param t
 * @param cemitFilter
 * @param props
 * @returns {[String]} Returns a label for each datetime range comparison.
 * These can be combined as needed by the caller
 */
export const extractLabelsForDateRecurrences = ({ t }: {
                                                    t: TFunction
                                                }, cemitFilter: CemitFilter, props: CemitFilterProps
): string[] => {
    const dateRecurrenceDayDateOrTimeSets = extractCemitFilterDateRecurrences(cemitFilter, props);
    return map(
        dateRecurrenceDayDateOrTimeSet => {
            return dateRecurrenceLabel({ ...dateRecurrenceDayDateOrTimeSet, t });
        },
        dateRecurrenceDayDateOrTimeSets
    );
};

/**
 * Gets the label for the DateRecurrence
 * @param type 'getDate', 'getDay', or 'getTime'
 * @param dayDateOrTimes
 * @param t The translation function
 * @returns {String}
 */
export const dateRecurrenceLabel = ({ type, values: dayDateOrTimes, t }: {
    type: DateRecurrenceType,
    values: DateRecurrence,
    t: TFunction
}) => {
    const dayOrDateLabel: string[] = cond([

        [equals('getDay'), () => {
            // get the key for the day Sunday...Saturday

            return compose(
                // Combine weekend and weekdays with remaining days
                ({ labels, dayDateOrTimes }: { labels: string, dayDateOrTimes: string[] }) => {

                    return concat(labels, map<string, string>((day: string) => t(`day${day}s`), dayDateOrTimes));
                },
                when(

                    // Combine weekdays if all 5 present
                    ({ dayDateOrTimes }: { dayDateOrTimes: string[] }) => all(day => includes(day, dayDateOrTimes), range(1, 6)),
                    ({ labels, dayDateOrTimes }: { labels: string[], dayDateOrTimes: string[] }) => ({
                        labels: concat(labels, [t('dayWeekdays')]),

                        attributeAlerts: difference(dayDateOrTimes, range(1, 6))
                    })
                ),

                when(
                    // Combine weekend if both present
                    ({ dayDateOrTimes }: {
                        dayDateOrTimes: string[]

                    }) => all((day: string) => includes(day, dayDateOrTimes), [0, 6]),
                    ({ dayDateOrTimes }: { dayDateOrTimes: number[] }) => ({
                        labels: [t('dayWeekends')],
                        attributeAlerts: difference<number>(dayDateOrTimes, [0, 6])
                    })
                )

            )({ attributeAlerts: flatten(dayDateOrTimes), labels: [] });
        }],

        [equals('getDate'), () => {
            // Get the ordinal for the month number (e.g. 1st, 3rd, 23rd)

            return map((date: number) => cond([
                    [(date: number) => equals(1, date % 10), (date: number) => `${date}${t('first')}`],
                    [(date: number) => equals(2, date % 10), (date: number) => `${date}${t('second')}`],
                    [(date: number) => equals(3, date % 10), (date: number) => `${date}${t('third')}`],
                    // Add more ordinal overrides if other languages need them
                    [T, (date: number) => `${date}${t('ordinalSuffixes')}`]
                ])(date),
                dayDateOrTimes);
        }],

        [equals('getTime'), () => {
            // get the key for the day Sunday...Saturday
            // TODO this shouldn't come in with a type and values
            // why is it different than getDay?
            return map(
                // Remove the timezone. TODO Hacky
                (time: string) => slice(0, 6, time),
                chain((obj: DateRecurrence) => {
                    return is(Object, obj) ? prop('values', obj) : toArrayIfNot(obj);

                }, dayDateOrTimes)
            );
        }]
    ])(type) as string[];

    // Use labels based on the type of getDayOrDateTime
    return join(' ',
        cond([
            [equals('getDate'), () => {
                return [t('everyDate'), join(', ', dayOrDateLabel), 'of the month'];
            }],
            [equals('getDay'), () => {
                return [t('onDays'), join(', ', dayOrDateLabel)];
            }],
            [equals('getTime'), () => {
                return [join(', ', dayOrDateLabel)];
            }]
        ])(type)
    );
};

/**
 * TODO replace with removeCemitFiltersOfType
 * Removes the DateRecurrences specified by id from the cemitFilter
 * @param cemitFilter
 * @param dateRecurrences A single dateRecurrence or array of dateRecurrences to remove
 * @param props
 * @returns {*}
 */
export const removeDateRecurrenceFilters = (cemitFilter: CemitFilter, dateRecurrences: DateRecurrence, props: CemitFilterProps) => {
    const dateRecurrenceAsArray = toArrayIfNot(dateRecurrences);
    const updateFunc = (existingDateRecurrencesFilter: CemitFilterDateRecurrence) => {
        const removed: CemitFilterDateRecurrence = overClassOrType(
            lensProp('any'),
            existingFilters => {

                return filter(
                    existingFilter => {
                        const topLevelKey: string = findOrThrow((prop: string) => propOr(undefined, prop, existingFilter), ['includes', 'equals']);
                        return !any(
                            (dateRecurrence: DateRecurrence) => {
                                // If any of the dateRecurrences match the existingFilter on type and value remove the existingFilter

                                return equals(dateRecurrence.attribute, existingFilter[topLevelKey][1].call[0]) &&
                                    equals(
                                        // @ts-ignore
                                        sortBy(identity, flatten(dateRecurrence.values)),
                                        // @ts-ignore
                                        sortBy(identity, flatten(existingFilter[topLevelKey][0]))
                                    );
                            },
                            dateRecurrenceAsArray
                        );
                    },
                    existingFilters
                );
            },
            existingDateRecurrencesFilter
        );
        // Clear the empty any if empty
        return removed.any && !length(removed.any) ? omit(['any'], removed) : removed;
    };
    return updateFilterTypeInFilters(
        cemitFilter,
        isCemitFilterDateRecurrence,
        updateFunc,
        CemitTypename.cemitFilterDateRecurrence,
        props
    );
};


/**
 * Creates a DateRecurrenceDateFilter for one or move dates of the month (0-31)
 * @param values
 * @returns
 */
export const createDateRecurrenceDateFilter = (values: string | string[]) => {
    return {
        __typename: CemitTypename.cemitFilterDateRecurrence,
        includes: [
            toArrayIfNot(values),
            {
                call: [
                    'getDate',
                    cemitFilterDateRecurrenceRightSideExpression
                ]
            }
        ]
    };
};

/**
 * Creates a DateRecurrenceDayFilter for a day or day grouping, like [0,6]
 * for weekends and [1,2,3,4,5] for the weekdays
 * @param valuesSets Sets of numbers, e.g. [[0,6], 3]
 * for weekends and Weendesdays
 * @returns {{__typename: string, includes: *[][]}}
 */
export const createDateRecurrenceDayFilter = (valuesSets: (number[] | number)[]): CemitFilterDateRecurrence => {
    return {
        __typename: CemitTypename.cemitFilterDateRecurrence,
        includes: [
            map(toArrayIfNot, valuesSets),
            {

                call: [
                    'getDay',
                    cemitFilterDateRecurrenceRightSideExpression
                ]
            }
        ]
    };
};

/**
 * Creates a DateRecurrenceDateFilter for one or move time
 * Times are in the format HH:mmXXXX where XXXX is the timezone (e.g. 02:00)
 * @param values
 * @returns
 */
export const createDateRecurrenceTimeFilter = (values: string[]): CemitFilterDateRecurrence => {
    return {
        __typename: CemitTypename.cemitFilterDateRecurrence,
        includes: [
            toArrayIfNot(values),
            {

                call: [
                    'getTime',
                    cemitFilterDateRecurrenceRightSideExpression
                ]
            }
        ]
    } as CemitFilterDateRecurrence;
};

/**
 * Returns true if the give day of week or time or datetime recurrence is
 * in the list of dateRecurrences
 * @param dateRecurrenceOrDay
 * @param dateRecurrences
 * @returns {*}
 */
export const matchesDateRecurrence = (dateRecurrenceOrDay: DateRecurrence, dateRecurrences: DateRecurrence[]) => {
    const matchingDateRecurrencesByType = filter(
        eqProps('type', dateRecurrenceOrDay),
        dateRecurrences
    );
    const matchingDateRecurrencesValues = chain(
        (matchingDateRecurrenceByType: DateRecurrence) => {

            return sort(identity, flatten(matchingDateRecurrenceByType.values));
        },
        matchingDateRecurrencesByType
    );

    const values = sort(identity, toArrayIfNot(dateRecurrenceOrDay.values));

    return all(

        (value: DateRecurrence) => {

            return includes(value, matchingDateRecurrencesValues);
        },
        values
    );
};
