import {SvgIconTypeMap} from '@mui/material';
import {createElement, FunctionComponent, SVGFactory} from 'react';
import {renderToString} from 'react-dom/server';
import {OverridableComponent} from '@mui/types';

/**
 * Convert an svg string to a Base64Encode data url for use, for instance, by Mapbo
 * @param svg
 */

export const svgStringToBase64Encoded = (svg: string) => {
  const base64 = btoa(svg);
  return `data:image/svg+xml;base64,${base64}`;
};
/**
 * Convert and SgvIcon or svg component to a base 64-encoded data url string
 * @param svgIconComponent
 * @param props
 */
export const svgIconComponentToBase64Encoded = (
  svgIconComponent:
    | SVGFactory
    | (OverridableComponent<SvgIconTypeMap> & {muiName: string})
    | FunctionComponent,
  props: any = {},
): string => {
  const svgString = renderToString(createElement(svgIconComponent, props));
  return svgStringToBase64Encoded(svgString);
};
