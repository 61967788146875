import {ReactElement, useCallback, useMemo, useState} from 'react';
import {
  extractOnlyDateInterval,
  setCemitFilterToDateInterval,
  useCemitFilterDateIntervalsLocalStorage,
  useNotLoadingMemoCDateIntervalFromCemitFilter,
} from '../../trainAppHooks/cemitFilterHooks/cemitFilterDateIntervalHooks.ts';
import {PerhapsIfLoading} from 'types/logic/requireIfLoaded.ts';
import {useConfiguredApiForTrainFormationAvailableDates} from '../../trainAppHooks/trainApiHooks/trainApiAvailableDateIntervalHooks.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {useWhatIsLoading} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {DateProps} from 'types/propTypes/trainPropTypes/dateProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {dumpDateInterval} from 'utils/datetime/dateUtils.ts';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {UseApiForAvailableDatesProps} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {equals} from 'ramda';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';

import {configuredDateIntervalDescriptions} from 'config/appConfigs/trainConfigs/configuredDateIntervalDescriptions.ts';
import {useCustomLocalStorageDefaulted} from 'utils/hooks/useCustomLocalStorage.ts';
import {updateCemitFilterDateIntervalIfChosenDateDiffers} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';

/**
 * Loads/Updates datetime props into trainProps.trainFormationDateProps
 * Depends directly on trainRouteGroupProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainFormationDateDependency = ({
  appProps,
  organizationProps,
  trainProps,
  renderChildren,
  loading,
}: Required<TrainAppTrainDependencyProps>): ReactElement<
  Required<TrainAppTrainDependencyProps>
> => {
  const parentCemitFilter = trainProps.cemitFilter;

  const dateIntervalDescriptions: DateIntervalDescription[] = useMemo<
    DateIntervalDescription[]
  >(() => {
    return configuredDateIntervalDescriptions(appProps);
  }, []);

  // The interval description based on the user's chosen interval. This is independent of any
  // selected TrainGroup's interval
  const [dateIntervalDescription, _setDateIntervalDescription] =
    useCustomLocalStorageDefaulted<DateIntervalDescription>(
      {
        loading,
      },
      AppSettings.localStorage.dateIntervalDescription,
      dateIntervalDescriptions[0],
    );
  const setDateIntervalDescription = useCallback((value) => {
    _setDateIntervalDescription(value);
  }, []);

  const [availableDateRanges, setAvailableDateIntervals] =
    useState<Perhaps<DateInterval[]>>(undefined);

  // Store a CemitFilter that combines the parent cemitFilterWithTrainRouteGroup with DateIntervalFilters
  const [
    cemitFilterWithTrainFormationDateIntervals,
    _setCemitFilterWithTrainFormationDateIntervals,
  ] = useCemitFilterDateIntervalsLocalStorage(
    // No dependencies to load from the cache
    false,
    AppSettings.localStorage.trainGroupFilterTrainFormationDateInterval,
    parentCemitFilter,
  );
  const setCemitFilterWithTrainFormationDateIntervals = useCallback(
    (value: CemitFilter) => {
      const existingDateInterval = extractOnlyDateInterval(
        cemitFilterWithTrainFormationDateIntervals,
      );
      const incomingDateInterval = extractOnlyDateInterval(value);
      if (!equals(existingDateInterval, incomingDateInterval)) {
        console.debug(
          `Updating CemitFilterWithTrainFormationDateIntervals \nexisting: ${
            existingDateInterval
              ? dumpDateInterval(
                  existingDateInterval,
                  organizationProps.organization.timezoneStr,
                )
              : 'undefined'
          }, \nincoming: ${incomingDateInterval && organizationProps?.organization?.timezoneStr ? dumpDateInterval(incomingDateInterval, organizationProps.organization.timezoneStr) : 'undefined'}`,
        );
      }
      if (Array.isArray(value)) {
        throw Error('value is an array. This should never happen');
      }
      return _setCemitFilterWithTrainFormationDateIntervals(value);
    },
    [organizationProps.organization, cemitFilterWithTrainFormationDateIntervals],
  );

  // Update the cemitFilterWithDateIntervals if the intervalDescription changes
  useNotLoadingEffect(
    loading,
    (
      dateIntervalDescription: DateIntervalDescription,
      cemitFilterWithTrainFormationDateIntervals: CemitFilter,
    ) => {
      const dateInterval = extractOnlyDateInterval(
        cemitFilterWithTrainFormationDateIntervals,
      );
      if (dateInterval) {
        updateCemitFilterDateIntervalIfChosenDateDiffers(
          dateIntervalDescription,
          dateInterval!.end,
          cemitFilterWithTrainFormationDateIntervals,
          (dateInterval: DateInterval) => {
            setCemitFilterToDateInterval(
              cemitFilterWithTrainFormationDateIntervals!,
              setCemitFilterWithTrainFormationDateIntervals!,
              dateInterval,
            );
          },
        );
      }
    },
    [dateIntervalDescription, cemitFilterWithTrainFormationDateIntervals] as const,
  );
  // Memoized call to extract the single dateRange from cemitFilterWithDateIntervals
  const dateInterval: Perhaps<DateInterval> =
    useNotLoadingMemoCDateIntervalFromCemitFilter(
      loading,
      cemitFilterWithTrainFormationDateIntervals,
    );

  // Tracks the modal to create a DateInterval filter
  const [choosingDateInterval, setChoosingDateInterval] = useState<boolean>(false);

  // Get the dates where there is SensorData available and set the date of
  // cemitFilterWithDateIntervals it if needed
  useConfiguredApiForTrainFormationAvailableDates(loading, {
    organization: organizationProps.organization,
    availableDateRanges,
    setAvailableDateIntervals,
    parentCemitFilter,
    cemitFilterWithDateIntervals: cemitFilterWithTrainFormationDateIntervals,
    setCemitFilterWithDateIntervals: setCemitFilterWithTrainFormationDateIntervals,
    dateIntervalDescription,
  } as PerhapsIfLoading<
    Required<TrainAppTrainDependencyProps>['loading'],
    UseApiForAvailableDatesProps
  >);

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainFormationDateDependency.name),
    TrainFormationDateDependency.name,
    {
      availableDateRanges,
      dateInterval,
      cemitFilterWithTrainFormationDateIntervals,
      dateIntervalDescription,
    },
    [
      availableDateRanges,
      dateInterval,
      cemitFilterWithTrainFormationDateIntervals,
      dateIntervalDescription,
    ],
    appProps.setWhatDependenciesAreLoading,
  );

  const childProps = useMemoClsOrType<DateProps>(CemitTypename.dateProps, {
    whatIsLoading,
    dateInterval,
    availableDateRanges,
    cemitFilterWithDateIntervals: cemitFilterWithTrainFormationDateIntervals,
    setCemitFilterWithDateIntervals: setCemitFilterWithTrainFormationDateIntervals,
    choosingDateInterval,
    dateIntervalDescription,
    setDateIntervalDescription,
    dateIntervalDescriptions,
    setChoosingDateInterval,
  });

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainFormationDateProps',
    childProps,
    cemitFilterWithTrainFormationDateIntervals,
  );
  if (cemitFilterWithTrainFormationDateIntervals?.allPass?.length > 2) {
    throw new Error('Debug: Expected <= 1 filter');
  }

  // useWhatChangedLoadingExplanation(
  //   whatIsLoading,
  //   {
  //     trainProps,
  //     ...mapKeys((k) => `tp_${k}`, trainProps),
  //     trainPropsMerged,
  //     ...mapKeys((k) => `tpm_${k}`, trainPropsMerged),
  //   },
  //   'TrainFormationDateDependency',
  // );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
  });
};
TrainFormationDateDependency.displayName = 'TrainFormationDateDependency';
export default TrainFormationDateDependency;
//export default memo(TrainFormationDateDependency);
