import {useState} from 'react';
import {ServiceLine} from 'types/trainRouteGroups/serviceLine';
import {useConfiguredApiForServiceLines} from '../../trainAppHooks/trainApiHooks/trainApiServiceLineHooks.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {PartialCemited} from 'types/cemited';

import {ServiceLineProps} from 'types/propTypes/trainPropTypes/serviceLineProps';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {memoizedWithClassifyOrTypeObject} from 'appUtils/typeUtils/memoizedWithClassifyOrTypeObject.ts';
import {TrainAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppOrganizationDependencyProps.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';

/**
 * Loads/Updates ServiceLine props
 * Depends directly on organizationProps
 * @param appProps
 * @param organizationProps
 * @param children
 * @return {*}
 * @constructor
 */
const ServiceLineDependency = ({
  appProps,
  organizationProps,
  renderChildren,
  loading,
}: Required<TrainAppOrganizationDependencyProps<TrainAppTrainDependencyProps>>): any => {
  const parentCemitFilter = appProps.cemitFilterApp;
  // The service lines of the organization
  const [serviceLines, setServiceLines] = useState<ServiceLine[] | undefined>(undefined);

  // We don't require anything to be in TrainGroups, because not every TrainRoute has
  // a baseline TrainRun, which would otherwise be the default active TrainGroup
  useConfiguredApiForServiceLines({
    loading,
    organization: organizationProps.organization,
    setServiceLines,
  });

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(ServiceLineDependency.name),
    ServiceLineDependency.name,
    {serviceLines},
    [serviceLines],
    appProps.setWhatDependenciesAreLoading,
  );

  const childProps = useMemoClsOrType<ServiceLineProps>(CemitTypename.serviceLineProps, {
    loading: whatIsLoading.loading,
    whatIsLoading,
    serviceLines,
    setServiceLines,
  });

  const trainProps = memoizedWithClassifyOrTypeObject<PartialCemited<TrainProps>>(
    CemitTypename.trainProps,
    {},
    [],
  );
  // Create the TrainProps. Child Dependencies create merged versions of this with their own props
  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    CemitTypename.trainProps,
    'serviceLineProps',
    childProps,
    // Pass this in because it is the highest level filter
    parentCemitFilter,
  );
  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainPropsMerged,
    'ServiceLineDependency',
  );

  return renderChildren<TrainAppTrainDependencyProps>({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
  });
};
ServiceLineDependency.displayName = 'ServiceLineDependency';
export default ServiceLineDependency;
//export default memo(ServiceLineDependency);
