import TrainChartDisplay from 'components/apps/trainAppComponents/trainAppChartComponents/TrainChartDisplay.tsx';
import {unlessLoadingValue} from 'utils/componentLogic/loadingUtils.ts';
import {railwayLineSourceKeyToReferenceScheduledStopPoint} from 'appUtils/cemitAppUtils/cemitSourceLayerUtils/railwayLineSourceLayerUtils.ts';
import {ChartDataConfig} from 'types/dataVisualizations/chartDataConfig.ts';
import React from 'react';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {doActiveTrainGroupsHaveTrainRuns} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {undefinedToTrue} from 'utils/functional/functionalUtils.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';

const TrainChartDisplayContainer = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  componentProps: {dataPathsConfigs, otherDisplayDataPaths},
}: TrainAppMapDependencyProps & {
  componentProps: {
    dataPathsConfigs: ChartDataConfig[];
    otherDisplayDataPaths: ChartDataConfig[];
  };
}) => {
  const loading = doesOrganizationHaveServiceLines(organizationProps)
    ? undefinedToTrue(
        trainProps.trainGroupSingleTrainRunProps?.trainGroupChartProps?.loading,
      )
    : undefinedToTrue(trainProps.trainGroupSingleTrainRunProps.loading);

  const trainDetailSize = undefined; // TODO wire up later useResizeObserver();

  // This is only used if organizationHasServiceLines
  // and the trainProps.trainGroupActivityProps.activeTrainGroups are not TrainGroupOnlyTrainFormation
  const referenceStopLookup = unlessLoadingValue(
    loading ||
      !doActiveTrainGroupsHaveTrainRuns(
        trainProps.trainGroupActivityProps.activeTrainGroups,
      ),
    () => {
      return railwayLineSourceKeyToReferenceScheduledStopPoint(
        trainProps.railwayLineProps.railwayLines,
      );
    },
  );

  return (
    <TrainChartDisplay
      {...{
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        componentProps: {
          loading,
          referenceStopLookup,
          dataPathsConfigs,
          trainDetailSize,
          otherDisplayDataPaths,
        },
      }}
    />
  );
};
export default TrainChartDisplayContainer;
