import React, {memo, useMemo} from 'react';
import {CemitTypename} from 'types/cemitTypename.ts';
import CemitFilterTrainGroupsContainer from '../../cemitFilterComponents/cemitFilterTrainGroupComponents/CemitFilterTrainGroupsContainer.tsx';
import {Stack} from '@mui/material';
import CemitFilterTrainFormationsContainer, {
  CemitFilterTrainFormationsContainerProps,
} from '../../cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsContainer.tsx';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {FrontendViewType} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {compact} from 'utils/functional/functionalUtils.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TRAIN_GROUP_MAX_ACTIVE_COUNT} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import TrainGroupTypeChooser, {
  TrainGroupTypeChooserProps,
} from 'components/apps/trainAppComponents/chooserComponents/trainGroupSingleTrainRunChooserComponents/trainGroupTypeChooser.tsx';

/**
 * Container listing TrainGroups. A TrainGroup can represent one or more
 * TrainRuns that have commonalities
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainGroupsContainer = memo(
  ({
    appProps,
    organizationProps,
    trainProps,
  }: TrainAppTrainDependencyProps): JSX.Element => {
    // Get active long representations, removing those that are not loaded yet or are not active
    // according to appProps.frontendViews
    const sortedTrainGroupLongContainers = useMemo(() => {
      return compact([
        <CemitFilterTrainFormationsContainer
          key="cemitFilterTrainFormationsContainer"
          {...{
            appProps,
            trainProps,
            componentProps: clsOrType<CemitFilterTrainFormationsContainerProps>(
              CemitTypename.cemitFilterTrainFormationsContainerProps,
              {
                maximumAllowed: TRAIN_GROUP_MAX_ACTIVE_COUNT,
                // Only allow 1 TrainFormation for now
                acceptTypes: [FrontendViewType.large],
              },
            ),
          }}
        />,
        trainProps.filteredTrainGroupProps.loading ? undefined : (
          <CemitFilterTrainGroupsContainer
            key="cemitFilterTrainGroupsContainer"
            {...{
              appProps,
              organizationProps,
              trainProps,
              componentProps: {
                acceptTypes: [FrontendViewType.large],
              },
            }}
          />
        ),
      ]);
    }, [appProps, organizationProps, trainProps]);
    return (
      <Stack
        {...{
          spacing: 1,
          sx: {
            minWidth: 0,
            minHeight: 0,
            overflow: 'auto',
            position: 'relative',
            overflowY: 'auto',
            alignItems: 'left',
          },
        }}
      >
        {/* Choose between TrainGroupOnlyTrainFormations and TrainGroupSingleTrainRuns of for the long component */}
        <TrainGroupTypeChooser
          key="trainGroupTypeChooser"
          {...{
            appProps,
            trainProps,
            componentProps: useMemoClsOrType<TrainGroupTypeChooserProps>(
              CemitTypename.trainGroupTypeChooserProps,
              {},
            ),
          }}
        />
        {sortedTrainGroupLongContainers}
      </Stack>
    );
  },
);
export default TrainGroupsContainer;
