export const apiUrlBase = `https://iot.dimensionfour.io/graph/`;
/**
 * Helper for getting a catalogue item with a path
 * from url, removing any query params from the variables
 */
export function safePathQuery({variables, ...rest}) {
  if (variables && 'path' in variables) {
    const safePath = (variables.path || '')
      .split('?')[0]
      .split('#')[0]
      .replace(/\/$/, '');

    return {
      variables: {
        ...variables,
        path: safePath,
      },
      ...rest,
    };
  }

  return {
    variables,
    ...rest,
  };
}

export async function simplyFetchFromGraph({uri = `${apiUrlBase}`, query, variables}) {
  const body = JSON.stringify(safePathQuery({query, variables}));

  const headers = {
    'content-type': 'application/json',
    'x-tenant-id': process.env.NEXT_PUBLIC_D4_TENANT_ID,
    'x-tenant-key': process.env.NEXT_PUBLIC_D4_TENANT_KEY,
  };
  const response = await fetch(uri, {
    method: 'post',
    headers,
    body,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.json();
}
