import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {
  all,
  any,
  filter,
  find,
  indexBy,
  length,
  lensProp,
  map,
  mergeRight,
  mergeWithKey,
  prop,
  values,
} from 'ramda';
import {
  outstandingTrainGroupSensorDataForDistanceIntervalAndRange,
  trainGroupsHaveServiceLinesAndTrainRouteGroups,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {trainGroupHasSensorData} from 'appUtils/trainAppUtils/trainGroupSensorDataUtils/trainGroupSensorDataUtils.ts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {idsEqual, listsShallowEqual} from 'utils/functional/functionalUtils.ts';
import {DistanceRange} from 'types/distances/distanceRange';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';
import {CrudList} from 'types/crud/crudList';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';

/**
 * Check that all activeTrainGroupsWithoutErrors have the SensorDataPoints that were loaded in loadedSensorDataTrainGroups
 * @param loading
 * @param organizationProps
 * @param trainProps
 * @param loadedSensorDataTrainGroups
 * @param activeTrainGroupsWithoutErrors
 * @param compareFunction
 */
export const useNotLoadingMemoSensorDataPointsSyncedToActive = (
  loading: boolean,
  organizationProps: OrganizationProps,
  trainProps: TrainProps,
  loadedSensorDataTrainGroups: TrainGroup[],
  activeTrainGroupsWithoutErrors: TrainGroup[],
  compareFunction: (
    loadedSensorDataTrainGroup: TrainGroup,
    trainGroup: TrainGroup,
  ) => boolean,
) => {
  // We aren't ready until minimumTrainRunsWithSensorDataPoints has the data and it's merged into
  // the TrainGroups so it shows up in activeTrainGroups[*].trainRun.sensorDataPoints
  return useNotLoadingMemo(
    loading || !activeTrainGroupsWithoutErrors,
    () => {
      return all<TrainGroup>((trainGroup: TrainGroup): boolean => {
        const loadedSensorDataTrainGroup = find((loadedSensorDataTrainGroup) => {
          return idsEqual(loadedSensorDataTrainGroup, trainGroup);
        }, loadedSensorDataTrainGroups);

        // TODO remove or incorporate into the compare function.
        // if (
        //   trainGroupsHaveServiceLinesAndTrainRouteGroups(
        //     organizationProps,
        //     activeTrainGroupsWithoutErrors,
        //   )
        // ) {
        //   // If we have TrainRoutes, we need to check that all sensor data has been downloaded for the distance interval and ranges and that have been requested
        //   // For example if distance interval 100m and the entire train route range has been downloaded, but distance interval 50m for a small interval is
        //   // pending, this returns true
        //   const distanceRangesRemainingToBeLoaded: DistanceRange[] =
        //     outstandingTrainGroupSensorDataForDistanceIntervalAndRange(
        //       trainProps.trainRouteGroupProps.trainRouteOrGroup,
        //       loadedSensorDataTrainGroups,
        //       trainGroup,
        //     );
        //   return (
        //     trainGroupHasSensorData(trainGroup) &&
        //     !length(distanceRangesRemainingToBeLoaded)
        //   );
        // } else {
        // Use the compare function to make sure the loaded the sensorDataDateIntervals match
        return (
          loadedSensorDataTrainGroup &&
          compareFunction(loadedSensorDataTrainGroup, trainGroup)
        );
        //}
      }, activeTrainGroupsWithoutErrors);
    },
    [loadedSensorDataTrainGroups, activeTrainGroupsWithoutErrors],
  );
};

/**
 * Checks if any sensor data is still loading
 * @param loading
 * @param organizationHasServiceLines
 * @param loadingSensorDataTrainGroups
 * @param loadedSensorDataTrainGroups
 */
export const useMemoAdditionalSensorDataIsLoading = (
  loading: boolean,
  organizationHasServiceLines: boolean,
  loadingSensorDataTrainGroups: SensorDataTrainGroup[],
  loadedSensorDataTrainGroups: TrainGroup[],
): Perhaps<boolean> => {
  // Test if more sensor data has been requested
  return useNotLoadingMemo(
    loading,
    () => {
      const errorFreeLoadedSensorDataTrainGroups = filter(
        (loadedSensorDataTrainGroup: TrainGroup) => !loadedSensorDataTrainGroup.error,
        loadedSensorDataTrainGroups,
      );

      if (!length(errorFreeLoadedSensorDataTrainGroups)) {
        return false;
      }

      return any((loadingSensorDataTrainGroup: SensorDataTrainGroup) => {
        return !loadingSensorDataTrainGroup?.loadingStatus?.complete;
      }, loadingSensorDataTrainGroups);
    },
    [loadingSensorDataTrainGroups, loadedSensorDataTrainGroups],
  );
};

/**
 * Syncs the trainGroup of each sensorDataTrainGroups to the given CrudList
 * @param loading
 * @param inScopeCrudTrainGroups
 * @param sensorDataTrainGroups
 * @param setSensorDataTrainGroups
 */
export const useNotLoadingSyncSensorDataTrainGroups = (
  loading: boolean,
  inScopeCrudTrainGroups: CrudList<TrainGroup>,
  sensorDataTrainGroups: Perhaps<SensorDataTrainGroup[]>,
  setSensorDataTrainGroups: StateSetter<Perhaps<SensorDataTrainGroup[]>>,
) => {
  // We must keep updatedLoadedSensorDataTrainGroups[*].trainGroup up to date
  useNotLoadingEffect(
    loading!,
    (inScopeCrudTrainGroups, sensorDataTrainGroups) => {
      // Update the trainGroup of each loadedSensorDataTrainGroups that got updated
      const lookup = indexBy(prop('id'), inScopeCrudTrainGroups.list);
      const updatedSensorDataTrainGroups = map(
        (sensorDataTrainGroup: SensorDataTrainGroup) => {
          const id = sensorDataTrainGroup.trainGroup.id;
          return lookup[id] != sensorDataTrainGroup.trainGroup
            ? setClassOrType(lensProp('trainGroup'), lookup[id], sensorDataTrainGroup)
            : sensorDataTrainGroup;
        },
        sensorDataTrainGroups!,
      );
      if (!listsShallowEqual(sensorDataTrainGroups, updatedSensorDataTrainGroups)) {
        // Merge in the updates. We want to keep those that are not currently in scope
        setSensorDataTrainGroups(
          (existingSensorDataTrainGroups: SensorDataTrainGroup) => {
            const merged = mergeRight(
              indexBy(prop('id'), existingSensorDataTrainGroups),
              indexBy(prop('id'), updatedSensorDataTrainGroups),
            );
            const mergedList = values(merged);
            return mergedList;
          },
        );
      }
    },
    [inScopeCrudTrainGroups, sensorDataTrainGroups],
  );
};
