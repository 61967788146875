//  https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect

import {useEffect, useRef} from 'react';
import {REQUIRED_ITEMS, useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {concat} from 'ramda';

/**
 * Version of useHasChanged that does nothing is loading is true
 * @param loading
 * @param toValue Called with no arguments to produce the value if not loading
 * @param [otherDependencies] Default [] Other dependencies to check in addition to loading and value
 * @returns {boolean} Whether the value has changed. Returns undefined if loading
 */
export const useNotLoadingHasChanged = <D extends readonly any[]>(
  loading: boolean,
  toValue: (...dependencies: REQUIRED_ITEMS<D>) => string,
  otherDependencies: D = [],
) => {
  const value = loading ? undefined : toValue(...otherDependencies);
  const prevVal = useNotLoadingPrevious(loading, value, otherDependencies);
  return loading ? undefined : prevVal !== value;
};

/**
 * Tracks the value of something
 * @param value
 * @returns {*}
 */
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Stores the value of the previous value and updates whenever loading and values or otherDependencies change
 * @param loading
 * @param value
 * @param otherDependencies Default []
 * @returns {*}
 */
export const useNotLoadingPrevious = (
  loading: boolean,
  value: any,
  otherDependencies: any[] = [],
) => {
  const ref = useRef();
  useNotLoadingEffect(
    loading,
    () => {
      ref.current = value;
    },
    concat([value], otherDependencies),
  );
  return ref.current;
};
