import {compose, join, length, map, prop} from 'ramda';
import {useNotLoadingHasChanged} from 'utils/hooks/useHasChanged.ts';
import {ReactElement, useMemo} from 'react';
import {TrainGroupActivityProps} from 'types/propTypes/trainPropTypes/trainGroupSingleTrainRunProps';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {useNotLoadingEffectSyncToAnyAreActive} from 'async/trainAppAsync/trainAppHooks/trainApiHooks/activeViewHooks.ts';
import {useMemoActiveTrainGroupsWithoutErrors} from 'async/trainAppAsync/trainAppHooks/typeHooks/activeTrainGroupHooks.ts';

/**
 * ActiveTrainGroupsDependency determines what the active TrainGroups are, currently either
 * trainProps.trainGroupSingleTrainRunProps.activeTrainGroupSingleTrainRuns or if empty
 * trainProps.trainGroupOnlyTrainFormationProps.activeTrainGroupFormations
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const ActiveTrainGroupsDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement => {
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);

  // The active TrainGroupSingleTrainRuns or if none the active TrainGroupOnlyTrainFormations
  // This always mirrors one of the active TrainGroup lists, even when in a loading state.
  // That way, trafficSimComponents that depend on activeTrainGroups don't go blank just because something
  // in trainProps.trainGroupSingleTrainRunProps or trainProps.trainGroupOnlyTrainFormationProps is updating
  const activeTrainGroups: Perhaps<TrainGroup[]> = useMemo(() => {
    return length(
      trainProps.trainGroupSingleTrainRunProps.activeTrainGroupSingleTrainRuns || [],
    )
      ? trainProps.trainGroupSingleTrainRunProps.activeTrainGroupSingleTrainRuns
      : trainProps.trainGroupOnlyTrainFormationProps.activeTrainGroupFormations || [];
  }, [
    trainProps.trainGroupOnlyTrainFormationProps.activeTrainGroupFormations,
    trainProps.trainGroupSingleTrainRunProps.activeTrainGroupSingleTrainRuns,
  ]);

  const detailFrontendView =
    length(activeTrainGroups || []) &&
    implementsCemitTypeViaClass(
      CemitTypename.trainGroupSingleTrainRun,
      activeTrainGroups![0],
    )
      ? FrontendView.detailTrainGroup
      : FrontendView.detailTrainFormation;
  // Adds/Removed the FrontendViews.detailTrainGroup to appProps.activeViews based on activeTrainGroups
  // This indicates that there is an active TrainGroupSingleTrainRun or failing that an active TrainGroupOnlyTrainFormation
  // It does not indicate that there is an active TrainRun
  useNotLoadingEffectSyncToAnyAreActive(
    loading,
    appProps,
    organizationProps,
    [
      // Activate the detail view and the list view.
      detailFrontendView,
    ],
    activeTrainGroups,
  );

  // Some functionality can ignore the erred TrainGroups, such as graphs and maps
  const activeTrainGroupsWithoutErrors = useMemoActiveTrainGroupsWithoutErrors({
    loading: loading || !activeTrainGroups,
    activeTrainGroups,
  });

  // Take a hash of the activeTrainGroups ids
  // to tell us if the actual instances change, not just distance ranges, so we can update mapbox sources
  const trainGroupsHaveChanged = useNotLoadingHasChanged(
    loading || !activeTrainGroups,
    (activeTrainGroups): string => {
      return compose<[TrainGroup[]], string[], string>(
        join(','),
        map(prop('id')),
      )(activeTrainGroups);
    },
    [activeTrainGroups] as const,
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(ActiveTrainGroupsDependency.name),
    ActiveTrainGroupsDependency.name,
    {
      activeTrainGroups,
    },
    [activeTrainGroups],
    appProps.setWhatDependenciesAreLoading,
    // If we have ServiceLines, activeTrainGroups must be defined and no localLoadingProps can be true
    !organizationHasServiceLines || Boolean(activeTrainGroups),
  );

  const trainGroupActivityProps = useMemoClsOrType<TrainGroupActivityProps>(
    CemitTypename.trainGroupActivityProps,
    {
      whatIsLoading,
      instancesChanged: trainGroupsHaveChanged,
      activeTrainGroups,
      activeTrainGroupsWithoutErrors,
    },
  );

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainGroupActivityProps',
    trainGroupActivityProps,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainGroupActivityProps,
    trainPropsMerged,
    'ActiveTrainGroupsDependency',
  );

  // We don't require anything to be in TrainGroups, because not every TrainRoute has
  // a baseline TrainRun, which would otherwise be the default active TrainGroup
  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};
ActiveTrainGroupsDependency.displayName = 'ActiveTrainGroupsDependency';
export default ActiveTrainGroupsDependency;
//export default memo(ActiveTrainGroupsDependency);
