import React, {useState} from 'react';
import {HistoricalData, SandboxCardProps, Datastream} from './types/index.ts';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
} from '@mui/material';
import {
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material';

const SandboxCard: React.FC<SandboxCardProps> = ({datastreamId, Datastream}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const getStatusColor = (level: number) => {
    if (level >= 60) return '#4caf50'; // Green for good
    if (level >= 30 && level < 60) return '#ffc107'; // Yellow for fair
    return '#f44336'; // Red for bad
  };
  const data = Datastream;

  const {
    position: sandboxName,
    cluster: sandBoxCluster,
    location: sandBoxLocation,
    operators,
  } = data.datastream.labels;
  const {value: sandboxCurrentValue, status: sandBoxStatus} =
    data.datastream.latest_reading;
  const sandBoxOperator = operators[0];
  const historicalDataSandBox: HistoricalData[] = [
    {date: 'Previous Month', level: data.datastream.last_30_days_previous},
    {date: 'Month', level: data.datastream.last_30_days},
    {date: 'Last Week', level: data.datastream.last_7_days},
  ];
  const convertName = (sandBoxName: string): string => {
    const parts = sandBoxName.split(/[\s-]+/);
    let part1 = '';
    let part2 = '';

    // Check the first part and convert based on the value
    switch (parts[0]) {
      case 'A':
        part1 = '+100';
        break;
      case 'B':
        part1 = '+200';
        break;
      default:
        part1 = '';
    }

    // Handle the "HOPPER" case with the next part being the number
    const hopperIndex = parts.indexOf('HOPPER');
    if (hopperIndex !== -1 && hopperIndex + 1 < parts.length) {
      const hopperNumber = parts[hopperIndex + 1];

      switch (`HOPPER ${hopperNumber}`) {
        case 'HOPPER 1':
          part2 = 'Wheel Position 6';
          break;
        case 'HOPPER 2':
          part2 = 'Wheel Position 3';
          break;
        default:
          part2 = '';
      }
    }

    // Combine the parts into a single string
    return `${part1} ${part2}`.trim();
  };

  return (
    <Card elevation={3} sx={{height: '100%'}}>
      <CardContent sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <Grid container component={Paper} spacing={2} sx={{flexGrow: 1}}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton size="small" onClick={toggleDrawer}>
                <ExpandMoreIcon />
              </IconButton>
              <Typography variant="subtitle2" align="center">
                {convertName(sandboxName)}
              </Typography>
            </Box>
            <Divider />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
          >
            <Box
              position="relative"
              width="80%"
              height="100px"
              border="2px solid #2B2525"
              borderRadius="4px"
              boxShadow="5px 5px 5px #2B2525"
              my={1}
              flex="1 0 auto"
            >
              <Box
                position="absolute"
                bottom={0}
                left={0}
                width="100%"
                height={`${sandboxCurrentValue}%`}
                bgcolor={getStatusColor(sandboxCurrentValue)}
                display="flex"
                alignItems="flex-end"
                justifyContent="center"
                pb={0.5}
              >
                <Typography variant="body2" sx={{fontWeight: 'bold', color: '#000'}}>
                  {`${sandboxCurrentValue}%`}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" align="center" color="textPrimary">
              Current Level
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
              <Box width={250}>
                <List>
                  <ListItem>
                    <ListItemText primary={sandboxName} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Status: ${sandBoxStatus}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Location: ${sandBoxLocation}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Cluster: ${sandBoxCluster}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Operator: ${sandBoxOperator}`} />
                  </ListItem>
                </List>
              </Box>
            </Drawer>

            <Box flex="1 0 auto" display="flex" flexDirection="column">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  width={500}
                  height={300}
                  data={historicalDataSandBox}
                  margin={{top: 5, right: 30, left: 20, bottom: 5}}
                  barSize={3} // Adjust this value to make the bars slimmer
                >
                  <XAxis type="number" dataKey="level" />
                  <YAxis type="category" dataKey="date" width={70} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="level">
                    {historicalDataSandBox.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getStatusColor(entry.level)} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SandboxCard;
