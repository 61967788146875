import {Button, ButtonProps, Tooltip} from '@mui/material';
import {emptyObj} from 'utils/functional/functionalUtils.ts';
import React from 'react';
import {CemitComponentProps, SxSlots} from 'types/propTypes/cemitComponenProps';
import {SxProps} from '@mui/system';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {CEMIT_GRAY, CEMIT_WHITE, CEMIT_YELLOW} from 'theme/cemitColors.ts';

export interface TrainGroupTypeOptionSxSlots extends SxSlots {
  button?: SxProps;
  icon?: SxProps;
}

export interface TrainGroupTypeOptionProps
  extends CemitComponentProps<TrainGroupTypeOptionSxSlots> {
  icon: JSX.Element;
  descriptionKey: string;
  titleKey: string;
  onClick: () => void;
  disabled: boolean;
  buttonProps: ButtonProps;
}

const TrainGroupOption = ({
  appProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<TrainGroupTypeOptionProps>) => {
  const sxSlots: Perhaps<TrainGroupTypeOptionSxSlots> = componentProps.sxSlots;
  const Icon = componentProps.icon;
  return (
    <Tooltip {...{arrow: true, title: appProps.t(componentProps.descriptionKey)}}>
      <span>
        <Button
          {...{
            onClick: componentProps.onClick,
            ...(componentProps.buttonProps || emptyObj),
            sx: {
              '&:disabled': {
                backgroundColor: CEMIT_YELLOW,
                color: 'black',
              },
            },
            disabled: componentProps.disabled,
            startIcon: (
              <Icon
                {...{
                  fill: componentProps.disabled ? CEMIT_GRAY : CEMIT_WHITE,
                }}
              />
            ),
            size: 'small',
          }}
        >
          {appProps.t(componentProps.titleKey)}
        </Button>
      </span>
    </Tooltip>
  );
};
TrainGroupOption.displayName = 'TrainGroupOption';
export default TrainGroupOption;
