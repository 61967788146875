import {Stack} from '@mui/material';
import VisionImageViewer from 'pages/vision/visionComponents/VisionImageViewer.tsx';
import {useMemo} from 'react';
import VisionSpatialNavigator from 'pages/vision/visionComponents/navigation/VisionSpatialNavigator.tsx';
import {visionNavigatorProps} from 'async/visionAppAsync/visionApiHooks.ts';
import {VisionAppTrainDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppTrainDependencyProps';

/**
 * THe Vision App
 * @param appProps
 * @param organizationProps
 * @param visionProps
 * @constructor
 */
const VisionImagePane = ({
  appProps,
  organizationProps,
  trainProps,
}: VisionAppTrainDependencyProps) => {
  const visionProps = trainProps.visionProps;
  const visionSpatialNavigatorProps = useMemo(() => {
    return visionNavigatorProps(visionProps);
  }, [visionProps.visionMeta, visionProps.visionConfig.meterDelta]);

  return (
    <Stack
      {...{
        sx: {
          minWidth: 0,
          minHeight: 0,
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          height: '100%',
        },
      }}
    >
      <VisionImageViewer {...{appProps, organizationProps, trainProps}} />
      <VisionSpatialNavigator
        {...{
          appProps,
          organizationProps,
          trainProps,
          componentProps: visionSpatialNavigatorProps,
        }}
      />
    </Stack>
  );
};
export default VisionImagePane;
