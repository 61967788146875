import React, {FC, ReactNode} from 'react';
import {useAuthenticate} from 'async/authentication/authenticationHooks.ts';

/**
 * Authenticate user from token and render children
 */
const Authentication: FC<{children: ReactNode}> = ({children}) => {
  // Calls authenticate if the token is set and sets the userState on success
  useAuthenticate();

  return <>{children}</>;
};

export default Authentication;
