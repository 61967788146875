import {strPathEq} from '@rescapes/ramda';
import {nameOfRoutePointStopNamePath} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import {routePointsOfTrainRouteOrGroup} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import {addIndex, always, find, identity, ifElse, lensIndex, map, prop, set} from 'ramda';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {findOrThrow} from '../../../../utils/functional/functionalUtils.ts';
import {RoutePoint} from '../../../../types/trainRouteGroups/routePoint';
import {TrainRoute} from '../../../../types/trainRouteGroups/trainRoute';

/**
 * Creates the props for each needed TrainLineStation
 * @param loading Returns undefined if true
 * @param scheduledStopPointsAndMaybeDateTimes
 * @param offsetLefts
 * @param recalculateOffsetLefts
 * @param scheduledStopPointsAndMaybeDateTimesWithOffsetLefts
 * @param trainRoute
 * @param trainRun
 * @param trainRouteOrRunInterval
 * @param onlyStopsNearInterval
 * @param spaceGeospatially
 * @param width
 * @param distanceRange
 * @param setsetOffsetLefts
 * @returns {*}
 */
const useMemoTrainLineStationProps = ({
  loading,
  trainRoute,
  trainRun,
  trainRouteOrRunInterval,
  scheduledStopPointsAndMaybeDateTimes,
  offsetLefts,
  recalculateOffsetLefts,
  scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
  onlyStopsNearInterval,
  spaceGeospatially,
  width,
  distanceRange,
  setOffsetLefts,
}) => {
  return useNotLoadingMemo(loading, () => {
    // Find the distance to the reference stop (e.g. Oslo S) if it is defined on the route
    const measureDistanceFromDistance = ifElse(
      prop('measureDistancesFrom'),
      (trainRoute: TrainRoute) => {
        const routePoint: RoutePoint = findOrThrow<RoutePoint>(
          (routePoint) =>
            strPathEq(
              nameOfRoutePointStopNamePath,
              trainRoute.measureDistancesFrom,
              routePoint,
            ),
          routePointsOfTrainRouteOrGroup(trainRoute),
        );
        return routePoint.routeDistance;
      },
      always(undefined),
    )(trainRoute);

    return addIndex(map)((scheduledStopPointAndMaybeDateTime, index) => {
      return {
        // Pseudo end-stations lack a label
        componentKey:
          scheduledStopPointAndMaybeDateTime.shortName ||
          scheduledStopPointAndMaybeDateTime.routeDistance,
        offsetLeft: spaceGeospatially ? offsetLefts[index] : undefined,
        scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
        setOffsetLeft:
          // If we position with flex, not geospatially, we rely on the stations' computed positions
          // to set offsetLefts, which are are used to draw the lines between stations.
          // If we are spacing geospatially, we already know what the offests are
          spaceGeospatially
            ? identity
            : (offsetLeft) => {
                return setOffsetLefts((offsetLefts) => {
                  return set(lensIndex(index), offsetLeft, offsetLefts);
                });
              },
        recalculateOffsetLefts,
        scheduledStopPointAndMaybeDateTime,
        singleTrainRun: trainRun,
        trainRoute,
        measureDistanceFromDistance,
        trainRouteOrRunInterval,
        limitedStations: onlyStopsNearInterval,
        spaceGeospatially,
        parentWidth: width,
        distanceRange,
      };
    }, scheduledStopPointsAndMaybeDateTimes);
  }, [
    trainRoute,
    trainRun,
    trainRouteOrRunInterval,
    scheduledStopPointsAndMaybeDateTimes,
    offsetLefts,
    scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    onlyStopsNearInterval,
    spaceGeospatially,
    width,
    distanceRange,
  ]);
};
export default useMemoTrainLineStationProps;
