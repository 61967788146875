import React from 'react';

export const NormalBrakingSvgIcon = ({
  fill = '#000000',
  width = '100%',
  height = '100%',
  trainGroupFill = undefined,
}) => {
  trainGroupFill = trainGroupFill || fill;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10C10.4477 10 10 10.4477 10 11C10 11.5523 10.4477 12 11 12ZM11 14C12.6569 14 14 12.6569 14 11C14 9.34315 12.6569 8 11 8C9.34315 8 8 9.34315 8 11C8 12.6569 9.34315 14 11 14Z"
        fill={trainGroupFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C7.08693 4 4 7.08174 4 10.7794C4 12.6404 4.7724 14.3314 6.04265 15.5655L3.25531 18.4345C1.25299 16.4891 0 13.78 0 10.7794C0 4.77961 4.97194 0 11 0V4Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 11C19 6.56576 15.4525 2.5491 10.083 2.99658L9.91693 1.00348C16.5474 0.450916 21 5.49317 21 11C21 16.4962 16.6607 21 11.2492 21C8.49286 21 5.5275 19.5274 3.77612 17.69L5.22383 16.3101C6.64883 17.8051 9.10979 19 11.2492 19C15.5036 19 19 15.445 19 11Z"
        fill={fill}
      />
    </svg>
  );
};
NormalBrakingSvgIcon.displayProps = 'NormalBreakingSvgIcon';
export default NormalBrakingSvgIcon;
