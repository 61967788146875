import { OrganizationProps } from '../../../types/propTypes/organizationPropTypes/organizationProps';
import { TrainProps } from '../../../types/propTypes/trainPropTypes/trainProps';
import {
  MapboxIconConfig,
  MapboxIconsConfig,
  MapboxLayer,
  MapSourceVisual
} from '../../../types/mapbox/mapSourceVisual';
import { join } from 'ramda';
import {
  SCHEDULED_STOP_POINT_LAYER_PREFIX,
  SCHEDULED_STOP_POINT_SOURCE_PREFIX
} from '../../../config/appConfigs/cemitAppConfigs/railwayLineConfig.ts';
import { mapboxGeojsonSource } from '../../../utils/map/mapboxSourceUtils.ts';
import stationSvgInline from '../../../assets/dataUrlIcons/subway.svg';
import { CEMIT_WHITE } from '../../../theme/cemitColors.ts';
import { CemitTypename } from '../../../types/cemitTypename.ts';
import { combineFeatureCollections } from '../../../utils/geojson/geojsonUtils.ts';
import {clsOrType} from "../../typeUtils/clsOrType.ts";

/**
 * Extracts nodes that represent ScheduledStopPoints from  organizationProps.organization.stopFeatureCollections
 * @param organizationProps
 * @param trainProps
 */
export const scheduledStopPointMapboxSourcesAndLayersSets = (
  { organizationProps }:
    { organizationProps: OrganizationProps }
): MapSourceVisual[] => {

  const scheduledStopPointGeojson = combineFeatureCollections(
    organizationProps.organization.stopFeatureCollections
  );

  const organizationId = organizationProps.organization.id;
  const scheduledStopPointSourceName = join('-', [SCHEDULED_STOP_POINT_SOURCE_PREFIX, organizationId]);
  const scheduledStopPointLayerId = join('-', [SCHEDULED_STOP_POINT_LAYER_PREFIX, organizationId]);

  const scheduledStopPointSource = mapboxGeojsonSource({
    sourceName: scheduledStopPointSourceName,
    featureCollection: scheduledStopPointGeojson
  });

  // Draw the track layer if not the baseline run. The baseline
  // is already represented by the actual track
  const scheduledStopPointLayer: MapboxLayer = {
    id: scheduledStopPointLayerId,
    type: 'symbol',
    source: scheduledStopPointSourceName,
    iconConfig: {
      width: 20,
      height: 20,
      iconConfigs: [{ name: 'station', svg: stationSvgInline } as MapboxIconConfig]
    } as MapboxIconsConfig,
    paint: {
      'text-color': CEMIT_WHITE
    },
    layout: {
      'text-size': {
        'stops': [
          [0, 0],
          [9, 0],
          [10, 10],
          [13, 14]
        ]
      },
      'text-justify': 'auto',
      'text-rotation-alignment': 'auto',
      'text-field': ['get', 'name'],
      'icon-image': 'station',
      'icon-anchor': 'center',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0, 0,
        8, 1
      ],
      'text-offset': [0.5, 0.5],
      'text-variable-anchor': ['top-left']
    }
  };

  return [clsOrType<MapSourceVisual>(CemitTypename.mapSourceVisual, {
    source: scheduledStopPointSource,
    layers: [scheduledStopPointLayer]
  })];
};
