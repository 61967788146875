import React from 'react';
import {Button} from '@mui/material';
import {styled} from '@mui/styles';

const StyledSecondaryButton = styled(Button)(({theme, size}) => ({
  backgroundColor: theme.palette.background.surfaceContrast,
  color: theme.palette.common.greyLight,
  boxShadow: 'none',
  padding:
    size === 'extra small'
      ? '1px 8px'
      : size === 'small'
        ? '2px 10px'
        : size === 'large'
          ? '8px 20px'
          : '6px 15px',
  fontSize:
    size === 'extra small'
      ? '0.625rem'
      : size === 'small'
        ? '0.75rem'
        : size === 'large'
          ? '1rem'
          : '0.875rem',
  textTransform: 'none',
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.surfaceModerate,
  },
}));

const SecondaryButton = ({size = 'medium', ...props}) => {
  return <StyledSecondaryButton size={size} {...props} />;
};

export default SecondaryButton;
