import {ButtonGroup} from '@mui/material';
import {CemitComponentProps, SxSlots} from 'types/propTypes/cemitComponenProps';
import {SxProps} from '@mui/system';
import React from 'react';
import {CemitTypename} from 'types/cemitTypename.ts';
import {emptyObj} from 'utils/functional/functionalUtils.ts';
import {FrontendViewGroupSelection} from 'types/frontendViews/frontendViewGroupSelection';
import TrainGroupOptionContainer, {
  TrainGroupTypeOptionContainerProps,
} from './TrainGroupOptionContainer.tsx';
import {
  frontendViewGroupSelectionDisplayConfigs,
  trainFormationGroupSelectionPrimaryDisplay,
  trainFormationGroupSelectionSecondaryDisplay,
  trainGroupSelectionPrimaryDisplay,
  trainGroupSelectionSecondaryDisplay,
} from 'components/apps/trainAppComponents/chooserComponents/trainGroupSingleTrainRunChooserComponents/trainGroupTypeChooserConfig.ts';
import {FrontendViewGroupSelectionDisplayConfig} from 'types/frontendViews/frontendViewGroupSelectionDisplay';
import {filter, map} from 'ramda';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {Train} from '@mui/icons-material';
import TrainSidewaysSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/trainSidewaysSvgIcon.tsx';

export interface TrainGroupTypeChooserSxSlots extends SxSlots {
  button?: SxProps;
  icon?: SxProps;
}

export interface TrainGroupTypeChooserProps
  extends CemitComponentProps<TrainGroupTypeChooserSxSlots> {}

/**
 * Allows choosing between TrainGroupOnlyTrainFormations and
 * TrainGroupSingleTrainRuns as the primary list. When one is selected,
 * the other is shown in a secondary mode. TrainGroupOnlyTrainFormations'
 * secondary mode is a menu. TrainGroupSingleTrainRuns' secondary mode is
 * TBD
 */
const TrainGroupTypeChooser = ({
  appProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<TrainGroupTypeChooserProps>) => {
  const setActiveViewsOfFrontendViewGroup = (
    frontendViewGroupSelection: FrontendViewGroupSelection,
  ) => {
    // Set the other options to secondary
    const otherConfigs = filter(
      (
        frontendViewGroupSelectionDisplayConfig: FrontendViewGroupSelectionDisplayConfig,
      ) => {
        return (
          frontendViewGroupSelection !=
          frontendViewGroupSelectionDisplayConfig.primary.frontendViewGroupSelection
        );
      },
      frontendViewGroupSelectionDisplayConfigs,
    );
    // Set the others to their secondary view
    const seondaryFrontendViewGroupSelections = map(
      (
        frontendViewGroupSelectionDisplayConfig: FrontendViewGroupSelectionDisplayConfig,
      ) => {
        return frontendViewGroupSelectionDisplayConfig.secondary
          .frontendViewGroupSelection;
      },
      otherConfigs,
    );
    // Active the primary view in frontendViewGroupSelection,
    // activeate the seconary view in each seondaryFrontendViewGroupSelections
    appProps.setActiveViewsOfFrontendViewGroups([
      frontendViewGroupSelection,
      ...seondaryFrontendViewGroupSelections,
    ]);
  };

  return (
    <ButtonGroup
      {...{variant: 'text', color: 'secondary', sx: componentProps.sx || emptyObj}}
    >
      {/* The TrainFormations */}
      <TrainGroupOptionContainer
        {...{
          appProps,
          componentProps: useMemoClsOrType<TrainGroupTypeOptionContainerProps>(
            CemitTypename.trainGroupTypeOptionProps,
            {
              ...componentProps,
              icon: Train,
              titleKey: 'formations',
              descriptionKey: 'viewingFormations',
              primaryFrontendViewGroupSelectionDisplay:
                trainFormationGroupSelectionPrimaryDisplay,
              secondaryFrontendViewGroupSelectionDisplay:
                trainFormationGroupSelectionSecondaryDisplay,
              setActiveViewsOfFrontendViewGroup,
            },
            [appProps],
          ),
        }}
      />
      {/* The TrainGroups */}
      {undefined && (
        <TrainGroupOptionContainer
          {...{
            appProps,
            componentProps: useMemoClsOrType<TrainGroupTypeOptionContainerProps>(
              CemitTypename.trainGroupTypeOptionProps,
              {
                ...componentProps,
                icon: TrainSidewaysSvgIcon,
                titleKey: 'runs',
                descriptionKey: 'viewRuns',
                primaryFrontendViewGroupSelectionDisplay:
                  trainGroupSelectionPrimaryDisplay,
                secondaryFrontendViewGroupSelectionDisplay:
                  trainGroupSelectionSecondaryDisplay,
                setActiveViewsOfFrontendViewGroup,
              },
              [appProps],
            ),
          }}
        />
      )}
    </ButtonGroup>
  );
};

TrainGroupTypeChooser.displayName = 'TrainGroupTypeChooser';
export default TrainGroupTypeChooser;
