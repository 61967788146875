import {Stack, Typography} from '@mui/material';
import SensorDataPointStatsComponentContainer, {
  SensorDataPointStatsComponentContainerProps,
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainRunningCharacteristicsComponents/SensorDataPointStatsComponentContainer.tsx';
import {cond} from 'ramda';
import {useTranslation} from 'react-i18next';
import {ChartDataConfig} from 'types/dataVisualizations/chartDataConfig.ts';
import {ScheduledStopPoint} from 'types/stops/scheduledStopPoint';
import {ChartPayloadItem} from 'types/dataVisualizations/chartPayloadItem';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {SxProps} from '@mui/system';
import React from 'react';
import {
  hasNonZeroLength,
  lengthAsBoolean,
  toArrayIfNot,
} from 'utils/functional/functionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import AlertChartContainer from 'components/charts/stackedChart/AlertChartContainer.tsx';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import ChartDescriptionTitlesContainer from 'components/charts/stackedChart/ChartDescriptionTitlesContainer.tsx';
import {useNotLoadingActiveSensorDataEligibleTrainGroups} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';

/**
 * This container is populated from the chart and map
 * The previous data is maintained until the user clears the window
 * so that the data does not disappear when the user hovers away from the
 * chart or maps
 * @returns {JSX.Element}
 * @constructor
 */
const AlertChartAndHoverInfoContainer = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  componentProps: {
    trainGroups,
    dataPathsConfigs,
    referenceStopLookup,
    mostRecentTooltipPayload,
    setMostRecentTooltipPayload,
    otherDisplayDataPaths,
  },
  sx,
}: TrainAppMapDependencyProps & {
  componentProps: {
    trainGroups: TrainGroup[];
    dataPathsConfigs: ChartDataConfig[];
    referenceStopLookup: Record<string, ScheduledStopPoint>;
    mostRecentTooltipPayload: Perhaps<ChartPayloadItem[]>;
    setMostRecentTooltipPayload: StateSetter<Perhaps<ChartPayloadItem[]>>;
    otherDisplayDataPaths: ChartDataConfig[];
  };
  sx: SxProps;
}): JSX.Element => {
  const {t} = useTranslation();

  const activeTrainGroups: Perhaps<TrainGroup[]> =
    trainProps.trainGroupActivityProps.activeTrainGroups;

  // Hide hover info if none of the give TrainGroups are eligible for showing sensor data
  const showHoverInfo = lengthAsBoolean(
    useNotLoadingActiveSensorDataEligibleTrainGroups(!trainGroups, trainGroups) || [],
  );

  const hoverForDataLabel = 'chooseDataAndInterval';
  const helpText: Perhaps<JSX.Element> = showHoverInfo
    ? cond([
        [
          (mostRecentTooltipPayload: Perhaps<ChartPayloadItem[]>) => {
            return !hasNonZeroLength(mostRecentTooltipPayload);
          },
          () => {
            return (
              <Stack
                key="hoverForDataLabel"
                {...{
                  direction: 'row',
                  spacing: 1,
                }}
              >
                <Typography color="secondary">{t(hoverForDataLabel)}</Typography>
              </Stack>
            );
          },
        ],
      ])(mostRecentTooltipPayload)
    : undefined;

  return (
    <Stack
      key="titleAlertChartAndStatsAndAddButton"
      {...{
        direction: 'row',
        width: '100%',
        minWidth: '100%',
        overflow: 'auto',
      }}
    >
      <Stack
        key="titleAlertChartAndStats"
        {...{
          spacing: 1,
          sx: [
            {
              width: '100%',
              minHeight: '250px',
              overflow: 'auto',
              flexGrow: 100,
            },
            ...toArrayIfNot(sx),
          ],
        }}
      >
        <ChartDescriptionTitlesContainer
          {...{
            appProps,
            organizationProps,
            trainProps,
            componentProps: {activeTrainGroups},
          }}
        />

        <AlertChartContainer
          {...{
            appProps,
            organizationProps,
            trainProps,
            mapProps,
          }}
        />
        {showHoverInfo ? (
          <SensorDataPointStatsComponentContainer
            key="sensorDataPointStatsComponent"
            {...{
              appProps,
              organizationProps,
              trainProps,
              componentProps: {
                activeTrainGroups,
                chartPayloadItems: mostRecentTooltipPayload || [],
                dataPathsConfigs,
                referenceStopLookup,
                otherDisplayDataPaths,
              } as SensorDataPointStatsComponentContainerProps,
            }}
          />
        ) : undefined}
        {helpText}
      </Stack>
    </Stack>
  );
};

export default AlertChartAndHoverInfoContainer;
