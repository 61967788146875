import {convertLength, Coord, Units} from '@turf/helpers';
import * as R from 'ramda';
import {
  always,
  chain,
  compose,
  cond,
  equals,
  filter,
  ifElse,
  init,
  is,
  join,
  last,
  length,
  map,
  prop,
  props,
  reduce,
  reduced,
  sortBy,
  subtract,
  T,
  unless,
} from 'ramda';
import along from '@turf/along';
import lineSlice from '@turf/line-slice';
import turfLength from '@turf/length';
import {DISTANCE_RANGE_PRECISION} from 'apis/cemitApis/trainApi/trainApiImplementation.ts';
import {Point} from 'mapbox-gl';
import {consolidateIntervals} from '../ranges/rangeUtils.ts';
import {DistanceRange} from '../../types/distances/distanceRange';
import {Feature, LineString, Point} from 'geojson';
import {LineString} from 'geojson';

// Converts from abbreviated units that we use to the identifiers that turfjs expects
export const TurfUnitLookup: Record<string, Units> = {
  cm: 'centimeters',
  m: 'meters',
  km: 'kilometers',

  feet: 'feet',
  miles: 'miles',
};

/**
 * Uses turfjs to convert a value from on unit to another. Rounds to the
 * given precision
 * @param config
 * @param config.from Convert from value, e.g. 'km', 'miles'
 * @param config.to Convert to value, e.g. 'km', 'miles'
 * @param [config.precision] Default 2 the precision of the returned string
 * @param value The value in the from units
 * @returns {String} The value converted and round to the give pr
 */
export const convertDistanceWithTurf = (
  {
    from,
    to,
    precision = 2,
  }: {
    from: string;
    to: string;
    precision: number;
  },
  value: number,
): string => {
  // Convert the units unless they are the same

  return unless(
    () => equals(from, to),
    compose(
      (value: number) => value.toFixed(precision),
      (value: number) =>
        convertLength(
          value,
          ...[from, to].map((p: string) => {
            return TurfUnitLookup[p];
          }),
        ),
    ),
  )(value);
};

/**
 * Given a LineString Feature and a list of data items, use turf to place
 * points along the line at the distance given by the
 * distanceProp
 * @param props
 * @param props.lineStringFeature
 * @param props.distanceProp
 * @param props.data
 * @returns {[(Feature<Point> | Point)]} A turf point for each data item. Properties form the data item are placed under
 * the point feature's properties
 */
export const placePointsAlongLineString = ({
  lineStringFeature,
  distanceProp,
  data,
}: {
  lineStringFeature: Feature<LineString>;
  distanceProp: string;
  data: Record<string, number>[];
}): Feature<Point> => {
  const options = {units: TurfUnitLookup['km']};

  return R.map(
    (datum: Record<string, number>) =>
      R.set(
        R.lensProp('properties'),
        datum,
        along(lineStringFeature, datum[distanceProp], options),
      ),
    data,
  );
};

/**
 * Returns the distance along line from a start coord on the line to an end point
 * @param units [Default] 'kilometers'
 * @param line
 * @param start Start point on the line
 * @param end End point on the line
 * @returns {Number} The distance in the given units
 */
export const distanceAlongLine = ({
  units = 'kilometers',
  line,
  start,
  end,
}: {
  units: Units;
  line: LineString | Feature<LineString>;
  start: Coord;
  end: Coord;
}) => {
  const slice = lineSlice(start, end, line);
  return turfLength(slice, {units});
};

/**
 * If the range.end is less than or equal the range.start, call the correction functions to adjust start and end
 * as needed
 * @param adjustStart Unary function called with range if end is <= start. The returned value becomes start
 * @param adjustEnd Unary function called with range if end is <= start. The returned value becomes end
 * @param [start] Defaults to 0 if undefined
 * @param [end] Defaults to 0 if undefined
 * @returns {{start, end}} The given range if it was valid or the adjusted range
 */
export const correctInvalidRange = (
  {
    adjustStart = prop('start'),
    adjustEnd = prop('end'),
  }: {
    adjustStart: (range: DistanceRange) => number;
    adjustEnd: (range: DistanceRange) => number;
  },
  {start = 0, end = 0}: {start: number; end: number},
): DistanceRange => {
  const range = {start, end};
  return end <= start ? {start: adjustStart(range), end: adjustEnd(range)} : range;
};

type PropertyResolvingFunction = (props: Properties) => any;
/**
 * Returns true or false depending on whether the feature's prop property is within the distance range
 * @param distanceRange  The distance range in a unit matching prop
 * @param distanceRange.start
 * @param distanceRange.end
 * @param prop The feature prop in feature.properties. If a function, called with feature.properties
 * and returns the value
 * @param feature A geojson feature
 * @returns {boolean} True if the feature is within the range
 */
export const featureWithinDistanceRange = (
  {
    distanceRange,
    prop,
  }: {
    distanceRange: DistanceRange;
    prop: string | PropertyResolvingFunction;
  },
  feature: Feature,
): boolean => {
  const value = ifElse(
    is(Function),
    (f: PropertyResolvingFunction) => f(feature.properties),
    (prop: string) => {
      if (!feature.properties) {
        throw new Error(' feature.properties cannot be undefined');
      }
      return feature.properties[prop];
    },
  )(prop);
  return value >= (distanceRange.start || 0) && value <= (distanceRange.end || Infinity);
};

/**
 * Find the differences between the given range and the list of range, combining the resulting overlapping differences
 * and returning discrete ranges
 * @param orderedDistanceRanges Already loaded ranges to compare with range.
 * @param distanceRange The range to test for missing ranges in orderedRanges
 * @returns {[DistanceRange]} The DistanceRanges
 */
export const rangeDifferenceWithOrderedRanges = (
  orderedDistanceRanges: DistanceRange[],
  distanceRange: DistanceRange,
): DistanceRange[] => {
  const roundedDistanceRange = roundDistanceRange({}, distanceRange);
  // If nothing is in orderedRanges, return the entirety of range
  if (!length(orderedDistanceRanges)) {
    return [roundedDistanceRange];
  }
  const differenceRanges = reduce(
    (differenceRanges: DistanceRange[], currentRange: DistanceRange) => {
      const lastDifferenceRange = last(differenceRanges);
      if (!lastDifferenceRange) {
        // If it's the first, take the part of the range tha doesn't intersect
        const differences = rangeDifferences(currentRange, roundedDistanceRange);
        // If there is no difference in range then return empty without evaluating the remaining orderedRanges
        return !length(differences) ? reduced([]) : differences;
      } else {
        // Find the difference between the last result of the previous call and the currentRange
        const currenDifferenceRanges = rangeDifferences(
          currentRange,
          lastDifferenceRange,
        );
        // If there is no difference in range then return empty without evaluating the remaining orderedRanges
        // Concat the previous differenceRanges with the new differenceRanges, unioning the last of the former with the first of the latter
        // since they might be the same if nothing overlapped
        return !length(currenDifferenceRanges)
          ? reduced([])
          : [...init(differenceRanges), ...currenDifferenceRanges];
      }
    },
    [],
    orderedDistanceRanges,
  );
  // Round the distanceRanges
  return map((differenceRange: DistanceRange) => {
    return roundDistanceRange({}, differenceRange);
  }, differenceRanges);
};

/**
 * Round the given distanceRange start/end values to the given decimal precision, default DISTANCE_RANGE_PRECISION
 * @param precision Default DISTANCE_RANGE_PRECISION
 * @param distanceRange
 * @returns {*}
 */
export const roundDistanceRange = (
  {
    precision = DISTANCE_RANGE_PRECISION,
  }: {
    precision?: number;
  },
  distanceRange: DistanceRange,
): DistanceRange => {
  return map((distance: number) => {
    return parseFloat(distance.toFixed(precision));
  }, distanceRange);
};

/**
 * Returns the union of the two ranges as an array of one or two ranges
 * If the ranges overlap, one is returned. If they don't overlap, two
 * are returned
 * @param range1
 * @param range2
 * @returns {DistanceRange[]}
 */
export const distanceRangeUnion = (
  range1: DistanceRange,
  range2: DistanceRange,
): DistanceRange[] => {
  const intersection = rangeIntersection(range1, range2);
  if (intersection || range1.end === range2.start || range2.end == range1.start) {
    return [
      {
        start: Math.min(range1.start, range2.start),
        end: Math.max(range1.end, range2.end),
      },
    ];
  } else {
    return sortBy(prop('start'), [range1, range2]);
  }
};

/**
 * Intersects the ranges and returns the intersection
 * @param range1
 * @param range2
 * @returns {DistanceRange | undefined}
 */
export const rangeIntersection = (
  range1: DistanceRange,
  range2: DistanceRange,
): DistanceRange | undefined => {
  const maxStart = Math.max(...map(prop('start'), [range1, range2]));
  const minEnd = Math.min(...map(prop('end'), [range1, range2]));
  return minEnd > maxStart ? {start: maxStart, end: minEnd} : undefined;
};

/**
 * Returns the range or ranges of range2 that are not part of range1
 * @param range1
 * @param range2
 * @returns {DistanceRange[]|undefined} Returns 0 ranges
 * if all of range2 is in range 1. Returns 1 range if the ranges overlap
 * or range1 is inside of range 2 but shares a min or max
 * Returns 2 ranges if range1 is fully inside of range2.
 */
export const rangeDifferences = (
  range1: DistanceRange,
  range2: DistanceRange,
): DistanceRange[] => {
  return compose(
    // Reject pairs that are equal.
    filter((distanceRange: DistanceRange) => {
      return !equals(...props(['start', 'end'], distanceRange));
    }),
    cond([
      [
        // If there is not intersection, return range2
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) =>
          !rangeIntersection(range1, range2),
        ({range2}: {range2: DistanceRange}) => [range2],
      ],
      [
        // range1 fully enclosed in range2, return the non-overlapping ends of range2
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) =>
          range1.start >= range2.start && range1.end <= range2.end,
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) => [
          {
            start: range2.start,
            end: range1.start,
          },
          {start: range1.end, end: range2.end},
        ],
      ],
      [
        // range2 fully enclosed in range1, return empty
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) =>
          range2.start >= range1.start && range2.end <= range1.end,
        () => [],
      ],
      [
        // range2 overlaps range1 on the left
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) =>
          range2.end >= range1.end,
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) => [
          {
            start: range1.end,
            end: range2.end,
          },
        ],
      ],
      [
        // range2 overlaps range1 on the right
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) =>
          range2.start <= range1.start,
        ({range1, range2}: {range1: DistanceRange; range2: DistanceRange}) => [
          {
            start: range2.start,
            end: range1.start,
          },
        ],
      ],
      [
        T,
        () => {
          throw new Error('Given ranges do not match any expected conditions');
        },
      ],
    ]),
  )({range1, range2});
};

/**
 * Removes overlapping maybeSubsetDistanceRanges of distanceRange.
 * @param distanceRange
 * @param maybeSubsetDistanceRanges One or more distanceRanges that might overlap or be a subset
 * of distanceRange
 * @returns {DistanceRange[]}
 */
export const removeSubsetRanges = (
  distanceRange: DistanceRange,
  maybeSubsetDistanceRanges: DistanceRange[],
): DistanceRange[] => {
  return consolidateIntervals<DistanceRange>(
    chain((maybeSubsetDistanceRange: DistanceRange) => {
      const differences = rangeDifferences(maybeSubsetDistanceRange, distanceRange);
      // Return the differences or the full distanceRange

      return unless(length, always(distanceRange))(differences);
    }, maybeSubsetDistanceRanges),
    [],
  );
};

export const hashDistanceRange = (distanceRange: DistanceRange): string => {
  return join(',', props(['start', 'end'], distanceRange));
};

/**
 * Returns true if the distance range distance is zero
 * @param distanceRange
 * @returns {boolean} True if start and end are equal
 */
export const distanceRangeIsZero = (distanceRange: DistanceRange): boolean => {
  return !subtract(...props(['start', 'end'], distanceRange));
};
