import {trainPropsWithFilteredCrudOnTrainProps} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import TrainGroupLine from 'components/apps/trainAppComponents/chooserComponents/trainRunChooserComponents/TrainGroupLine.tsx';
import {userTrainRunIcons} from 'components/apps/trainAppComponents/chooserComponents/trainRunChooserComponents/trainGroupChooserUtils.tsx';
import {useTranslation} from 'react-i18next';
import {Size} from 'types/layout/size';
import {SingleTrainGroupTrainDependencyProps} from 'types/propTypes/trainPropTypes/trainTrainAppDependencyProps.d.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * A TrainGroupLine represents the
 *
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param trainGroup
 * @param isSelectedTrainRuns
 * @param toggleTrainGroupVisibility
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupLineContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {isSelectedTrainRuns, toggleTrainGroupVisibility, trainDetailSize},
}: SingleTrainGroupTrainDependencyProps & {
  componentProps: {
    isSelectedTrainRuns: boolean;
    toggleTrainGroupVisibility: (trainGroup: TrainGroup) => void;
    trainDetailSize: Size;
  };
}): JSX.Element => {
  const {t} = useTranslation();

  const trainGroup: TrainGroup = trainProps.trainGroupSingleTrainRunProps.trainGroup;
  const icons = userTrainRunIcons({
    trainProps,
    trainGroup,
    toggleTrainGroupVisibility,
    isSelectedTrainRuns,
    t,
  });

  const color = trainGroup.activity.isActiveColor;

  const sxTrainRunOrGroupIntervalBar = isSelectedTrainRuns
    ? {
        backgroundColor: color,
      }
    : {};

  const filteredTrainProps = trainPropsWithFilteredCrudOnTrainProps(trainProps);

  return (
    <TrainGroupLine
      key="userTrainRunLine"
      {...{
        appProps,
        organizationProps,
        trainProps: filteredTrainProps,
        componentProps: {
          buttons: icons,
          isSelectedTrainRuns,
          toggleTrainGroupVisibility,
          color,
          trainDetailSize,
        },
        sxTrainRunOrGroupIntervalBar,
      }}
    />
  );
};
export default TrainGroupLineContainer;
