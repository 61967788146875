import {CemitApiUrlResolver} from "../../../types/apis/cemitApi";
import {TrainApiOrganizationRoute, TrainApiTrainRouteGroupsRoute} from "../../../types/apis/trainApi";
import {Perhaps} from "../../../types/typeHelpers/perhaps";
import {cemitApiGetUrl} from "../apiConfig.ts";
import {trainApi} from "./trainApiImplementation.ts";

/**
 * Builds a url to query trains-api for Formations
 * @param trainApiRoute
 * @returns {String} The trains-api Url
 */
export const trainApiTrainFormationsRequestResolver: CemitApiUrlResolver<TrainApiTrainRouteGroupsRoute> = (trainApiRoute: TrainApiTrainRouteGroupsRoute): Perhaps<string> => {
    return cemitApiGetUrl(
        trainApi,
        trainApiRoute
    );
};
/**
 * Queries for the logged in Organization
 */
export const trainApiOrganizationUrl: CemitApiUrlResolver<
    TrainApiOrganizationRoute,
    any,  // TODO
    any,  // TODO
    any,  // TODO
    any,  // TODO
    any,  // TODO
    any  // TODO
> = (trainApiRoute: TrainApiOrganizationRoute): Perhaps<string> => {
    // Returns the organization config of the authenticated user
    // TODO Fix to get organization from auth
    return cemitApiGetUrl(
        trainApi,
        trainApiRoute
    );
};
