import React from 'react';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import AlertNowButton from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertNowButton.tsx';
import AlertIntervalPicker from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertIntervalPicker.tsx';
import AlertTypeConfigPicker from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertTypePickerComponent.tsx';
import {getLocalTimeZoneStr} from 'utils/datetime/timeUtils.ts';
import {TrainDependencyDerivedProps} from 'types/propTypes/trainPropTypes/trainTrainAppDependencyProps.d.ts';
import {parseISO} from 'date-fns';
import AlertExportExcelButtonContainer from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/alertExportComponents/AlertExportExcelButtonContainer.tsx';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {updateCemitFilterDateIntervalIfChosenDateDiffers} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {setCemitFilterToDateInterval} from 'async/trainAppAsync/trainAppHooks/cemitFilterHooks/cemitFilterDateIntervalHooks.ts';
import {useNotLoadingDoesLocalTimezoneMatchOrganization} from 'utils/hooks/timeHooks.ts';
import {map} from 'ramda';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs/index.js';
import {useTheme} from '@mui/styles';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(getLocalTimeZoneStr());
/**
 * Options choosers for Alert
 * @param componentProps
 * @constructor
 */
export const AlertChoosersComponent = ({
  appProps,
  organizationProps,
  trainProps,
}: TrainDependencyDerivedProps): JSX.Element => {
  const {
    trainRunIntervalDescription,
    alertTypeConfigs,
    alertTypeConfig,
    setAlertTypeConfig,
    setAlertTimePeriodForMap,
  } = trainProps.alertConfigProps;
  const {dateIntervalDescription, setDateIntervalDescription, dateIntervalDescriptions} =
    trainProps.trainFormationDateProps;

  const theme = useTheme();

  const chosenTimezoneMatchesOrganization =
    useNotLoadingDoesLocalTimezoneMatchOrganization(
      false,
      organizationProps.organization,
      appProps.currentTimezone,
    );

  return (
    <Stack
      {...{
        direction: 'row',
        spacing: 1,
        sx: {
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          minWidth: 0,
          width: '100%',
          minHeight: 0,
          height: '100%',
        },
      }}
    >
      <AlertTypeConfigPicker
        {...{
          t: appProps.t,
          alertTypeConfig,
          setAlertTypeConfig,
          alertTypeConfigs,
        }}
      />
      <AlertIntervalPicker
        {...{
          t: appProps.t,
          dateIntervalDescription,
          setDateIntervalDescription,
          dateIntervalDescriptions,
          trainRunIntervalDescription,
          setAlertTimePeriodForMap: setAlertTimePeriodForMap,
        }}
      />

      <Stack
        {...{
          spacing: 1,
          direction: 'row',
          sx: {
            alignItems: 'center',
            minWidth: 0,
            minHeight: 0,
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            height: '100%',
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack {...{direction: 'row', spacing: 0.5}}>
            <DateTimePicker
              {...{
                timezone: appProps.currentTimezone,
                ampm: false,
                // Don't show future dates
                disableFuture: true,
                // TODO hack. We don't know the earliest date for Alert since there is more data
                // there than we have for SensorDataPoints from train-api
                minDate: dayjs.utc(parseISO(AppSettings.earliestDate)),
                // TODO This is being ignored
                //timezone: organizationProps.organization.timezoneStr,
                label: appProps.t('showDataUntil'),
                value: dayjs.utc(trainProps.trainFormationDateProps?.dateInterval?.end),
                onChange: (dayJs: Dayjs) => {
                  const chosenDate: Date = dayJs.toDate();
                  updateCemitFilterDateIntervalIfChosenDateDiffers(
                    trainProps.trainFormationDateProps.dateIntervalDescription,
                    chosenDate,
                    trainProps.trainFormationDateProps.cemitFilterWithDateIntervals!,
                    (dateInterval: DateInterval) => {
                      setCemitFilterToDateInterval(
                        trainProps.trainFormationDateProps.cemitFilterWithDateIntervals!,
                        trainProps.trainFormationDateProps
                          .setCemitFilterWithDateIntervals!,
                        dateInterval,
                      );
                    },
                  );
                },
                slotProps: {
                  textField: {
                    size: 'large',
                    sx: {
                      '& input': {
                        height: '100%',
                      },
                    },
                  },
                },
              }}
            />
            {!chosenTimezoneMatchesOrganization ? (
              <ToggleButtonGroup
                {...{
                  value: appProps.currentTimezone,
                  size: 'small',
                  exclusive: true,
                  fullWidth: false,
                  onChange: (
                    _event: React.MouseEvent<HTMLElement>,
                    newTimezone: string,
                  ) => {
                    if (newTimezone != null) {
                      appProps.setCurrentTimezone(newTimezone);
                    }
                  },
                  sx: {
                    [`& .${toggleButtonGroupClasses.grouped}`]: {
                      //margin: theme.spacing(0.5),
                      border: 0,
                      //borderRadius: theme.shape.borderRadius,
                      [`&.${toggleButtonGroupClasses.disabled}`]: {
                        border: 0,
                      },
                    },
                  },
                }}
              >
                {map(
                  (timezoneName: string) => (
                    <ToggleButton
                      key={timezoneName}
                      {...{
                        value: timezoneName,
                        disabled: timezoneName == appProps.currentTimezone,
                        sx: {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    >
                      {timezoneName}
                    </ToggleButton>
                  ),
                  [getLocalTimeZoneStr(), organizationProps.organization.timezoneStr],
                )}
              </ToggleButtonGroup>
            ) : undefined}
          </Stack>
        </LocalizationProvider>

        {
          // We only show the now button when a TrainRunIntervalDescription is not fixing the daterange
          trainProps.alertConfigProps.trainRunIntervalDescription ? undefined : (
            <AlertNowButton {...{trainProps}} />
          )
        }

        {/* TODO FIX {<AlertExportExcelButtonContainer {...{trainProps}} />}*/}
      </Stack>
    </Stack>
  );
};
