const BASE_URL = "https://track-simulator-api-0cdb9a3bfd80.herokuapp.com"
// const BASE_URL = "http://localhost:8000"


export const callAPI = async (path, method, data) => {

    const apiUrl = `${BASE_URL}${path}`;

    const config = method === 'GET' ? {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        }, // Convert the data to a JSON string
    } : {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: method === 'GET' ? null : JSON.stringify(data), // Convert the data to a JSON string
    }

    const response = await fetch(apiUrl, config);

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }


    return response.json().catch(() => {
        return response
    });
}
