import {TFunction} from 'i18next';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  AlertTypeKey,
  AlertTypeLabels,
  AlertTypeShortLabels,
} from 'types/alerts/alertTypeKey.ts';
import {
  huntingAlertLevelToLabel,
  HuntingAttributeAlertSValue,
  huntingLevelToAttribute,
  huntingLevelToAttributeForType,
} from 'types/alerts/hunting/huntingAlertLevel.ts';
import {huntingErrorLevelConfigs} from 'types/alerts/hunting/huntingErrorLevelConfigs.ts';
import {HuntingAttributeAlertLevel} from 'types/alerts/hunting/huntingAttributeAlertLevel.ts';
import {huntingDataThresholds} from 'types/alerts/hunting/huntingDataThresholds.ts';
import {huntingTrainGroupIconConfigs} from 'types/alerts/hunting/huntingTrainGroupIconConfigs.ts';
import {HuntingGauge} from 'types/alerts/hunting/huntingGauge';

export const huntingAlertTypeConfig = (t: TFunction): AlertTypeConfig<HuntingGauge> => {
  return clsOrType<AlertTypeConfig<HuntingGauge>>(CemitTypename.alertTypeConfig, {
    alertTypeKey: AlertTypeKey.huntingPointId,
    alertLevelToAttribute: huntingLevelToAttribute,
    alertLevelToAttributeForTypeKeys: huntingLevelToAttributeForType,
    alertLevelToLabel: huntingAlertLevelToLabel(t),
    label: t(AlertTypeLabels.huntingPointId),
    labelShort: t(AlertTypeShortLabels.huntingPointId),
    attributeAlertLevelConfigs: huntingErrorLevelConfigs(t),
    criticalSValueLevel: HuntingAttributeAlertSValue.hunting,
    warningLevels: [HuntingAttributeAlertLevel.warning],
    criticalLevels: [HuntingAttributeAlertLevel.critical],
    dataThresholds: huntingDataThresholds(t),
    attributeAlertLevelEnum: HuntingAttributeAlertLevel,
    defaultVisibleAttributeAlertLevels: [
      HuntingAttributeAlertLevel.warning,
      HuntingAttributeAlertLevel.critical,
    ],

    propsResolver: (node) => {
      return {
        speed: node.metadata.Speed,
        energy: node.data.rawValue,
      };
    },
    unit: 'DECIBELS',
    levelAllValueGreaterThan: 0.15,
  });
};
