import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainRun} from 'types/trainRuns/trainRun';
import {cemitedConstructor} from 'classes/cemitAppCemitedClasses/cemitClassConstructor.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {ServiceLineMinimized} from 'types/trainRouteGroups/serviceLine';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {Error} from '@mui/icons-material';
import {collectionDevicesOfTrainFormation} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainFormationUtils.ts';
import {equals, join, length} from 'ramda';
import {findOrThrow, headOrThrow} from 'utils/functional/functionalUtils.ts';
import {TrainRoute, TrainRouteDerived} from 'types/trainRouteGroups/trainRoute';
import {asCemitedClassOrThrow} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {TrainGroupClass} from 'classes/trainAppCemitedClasses/trainGroupClasses.ts';
import {formatInTimeZone} from 'date-fns-tz';
import {trainDataSimpleDateTimeStringWithTimeZone} from 'utils/datetime/timeUtils.ts';

/**
 * Basic implementation of a TrainGroupSingleTrainRun
 */
export class TrainGroupSingleTrainRunClass
  extends TrainGroupClass
  implements TrainGroupSingleTrainRun
{
  declare __typename: CemitTypename.trainGroup;
  trainRuns: TrainRun[] = [];

  constructor(obj: TrainGroupSingleTrainRun) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  get dateInterval(): DateInterval {
    return clsOrType<DateInterval>(CemitTypename.dateInterval, {
      start: this.singleTrainRun.departureDatetime,
      end: this.singleTrainRun.arrivalDatetime,
    });
  }

  /**
   * For now, the DateInterval of the TrainGroupSingleTrainRun that we query for data
   * is always the same as its departureDatetime to arrivalDatetime
   */
  get activeDateInterval(): DateInterval {
    return this.dateInterval;
  }

  get serviceLine(): ServiceLineMinimized {
    return this.singleTrainRun.journeyPattern.trainRoute.serviceLine;
  }

  /**
   * Return's the TrainGroup's single TrainRun's TrainFormationCollectionDevices
   *
   * @param trainGroup
   * @returns {[Object]} Objects keyed by collectionDevice and vehicle
   */
  get trainGroupCollectionDevices(): TrainFormationCollectionDevice[] {
    if (!this.trainFormation) {
      throw Error(
        `TrainGroup of type ${this.__typename} does not have a single TrainFormation`,
      );
    }
    // Send the dateTimeRange range to allow only collection devices that were in use during the dateTimeRange
    return collectionDevicesOfTrainFormation(this.trainFormation, this.dateInterval);
  }

  // We currently expect only on TrainRun per TrainGroup
  // TODO
  get singleTrainRun(): TrainRun | never {
    if (length(this.trainRuns || []) > 1) {
      throw Error(
        `Got more than the currently one expected TrainRun in trainRuns: ${length(this.trainRuns!)} `,
      );
    }
    return headOrThrow(this.trainRuns!);
  }

  // The TrainRoute with derived matching that of the only TrainRun
  get singleTrainRunTrainRoute(): TrainRoute {
    // If this is a preconfigured TrainGroup that has had it's TrainRoute overridden to match the currently active TrainRoute
    const trainRouteId = this.overrideTrainRoute
      ? this.overrideTrainRoute.id
      : this.singleTrainRun!.trainRoute.id;
    const trainRoutes = this.trainRouteOrGroup!.trainRoutes;
    return findOrThrow<TrainRoute>((trainRoute: TrainRoute) => {
      return equals(trainRouteId, trainRoute.id);
    }, trainRoutes);
  }

  /**
   * Returns the singleTrainRunTrainRoute if it's typename is CemitTypename.trainRouteDerived,
   * meaning the derived data has been calculated
   */
  get singleTrainRunTrainRouteLoaded(): TrainRouteDerived | never {
    const trainRoute: TrainRoute = this.singleTrainRunTrainRoute!;
    return asCemitedClassOrThrow<TrainRouteDerived>(CemitTypename.trainRoute, trainRoute);
  }

  localizedName(timezoneStr: string): string {
    const trainRun = this.singleTrainRun!;
    return join(';', [
      this.trainRouteOrGroup.shortName,
      formatInTimeZone(
        trainRun.departureDatetime,
        timezoneStr,
        trainDataSimpleDateTimeStringWithTimeZone,
      ),
      formatInTimeZone(
        trainRun.arrivalDatetime,
        timezoneStr,
        trainDataSimpleDateTimeStringWithTimeZone,
      ),
    ]);
  }
}
