import {Stack, Typography} from '@mui/material';
import React from 'react';
import {TFunction} from 'i18next';
import {TrackRouteMinimized} from 'types/railways/trackRoute';

export interface VisionSidebarTitleProps {
  t: TFunction;
  trackRoute: TrackRouteMinimized;
}

/**
 * Shows the Track name and direction for the Vision App
 * @param t
 * @param trackRoute
 * @constructor
 */
const VisionSidebarTitle = ({t, trackRoute}: VisionSidebarTitleProps) => {
  return (
    <Stack {...{direction: 'column'}}>
      <Typography {...{color: 'secondary', sx: {fontWeight: 'bold'}}}>
        {trackRoute.name}
      </Typography>
      <Typography {...{color: 'secondary', sx: {fontWeight: 'bold'}}}>
        {t(trackRoute.direction)}
      </Typography>
    </Stack>
  );
};

export default VisionSidebarTitle;
