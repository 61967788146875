import {equals, is, map} from 'ramda';
import React, {SyntheticEvent, useMemo, useState} from 'react';
import {Button, Menu, MenuItem, Stack, Typography} from '@mui/material';
import {CemitAppOrganizationComponentDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationComponentDependencyProps';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {cemitColors} from 'theme/cemitColors.ts';
import {VisionTimestamp} from 'types/datetime/visionTimestamp';
import {format} from 'date-fns';
import {
  trainDataFriendlyDatetimeFormatString,
  trainDataFriendlyDatetimeWithSecondsFormatString,
} from 'utils/datetime/timeUtils.ts';
import {VisionAppTrainDependencyProps} from 'types/propTypes/appPropTypes/visionAppPropTypes/visionAppTrainDependencyProps';
import {VisionMeta} from 'types/vision/visionMeta';
import {KeyboardArrowDown} from '@mui/icons-material';
import {weekYearInitFromDate} from 'classes/typeCrud/weekYearCrud.ts';
import {WeekYear} from 'types/datetime/weekYear';

/**
 * Choose a VisionMeta by its timestamp
 * @param appProps
 * @param visionProps
 * @constructor
 */
const VisionTimestampChooser = ({
  appProps,
  trainProps,
}: VisionAppTrainDependencyProps) => {
  const {t} = appProps;
  const visionProps = trainProps.visionProps;
  const [anchorEl, setAnchorEl] = useState(undefined);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = (_e: SyntheticEvent, visionMeta: VisionMeta) => {
    setAnchorEl(undefined);
    // if timestamp comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, visionMeta)) {
      visionProps.setVisionMeta(visionMeta);
      // TODO Temp set the weekYear to the current image's week
      const weekYear: WeekYear = weekYearInitFromDate(visionMeta.timestamp);
      visionProps.setWeekYear(weekYear);
    }
  };
  const timestampComponents = useMemo<JSX.Element[]>(() => {
    return map((visionMeta: VisionMeta) => {
      return (
        <MenuItem
          key={visionMeta.timestamp}
          {...{
            disabled: equals(visionMeta.timestamp, visionProps.visionMeta?.timestamp),
            onClick: (e: SyntheticEvent) => onClose(e, visionMeta),
          }}
        >
          {format(visionMeta.timestamp, trainDataFriendlyDatetimeWithSecondsFormatString)}
        </MenuItem>
      );
    }, visionProps.visionMetas);
  }, [visionProps.visionMetas, visionProps.visionMeta]);

  return visionProps.visionMeta ? (
    <Stack
      {...{
        sx: {width: '100%', alignSelf: 'self-start'},
      }}
    >
      <Typography
        {...{
          sx: {
            color: cemitColors.white,
            fontSize: '0.8rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
          },
        }}
      >
        {t('availableImageTimestamp')}
      </Typography>

      <Button
        key="button"
        {...{
          id: 'timestamp-button',
          fullWidth: true,
          variant: 'outlined',
          color: 'secondary',
          'aria-controls': open ? 'timestamp-positioned-menu' : undefined,
          'aria-haspopup': 'true',
          'aria-expanded': open ? 'true' : undefined,
          onClick: handleClick,
          endIcon: <KeyboardArrowDown />,
        }}
      >
        <Stack
          {...{direction: 'row', spacing: 1, sx: {width: '100%', alignItems: 'center'}}}
        >
          <Typography key="label">
            {format(
              visionProps.visionMeta.timestamp,
              trainDataFriendlyDatetimeWithSecondsFormatString,
            )}
          </Typography>
        </Stack>
      </Button>
      <Menu
        key="menu"
        {...{
          id: 'timestamp-positioned-menu',
          'aria-labelledby': 'timestamp-button',
          anchorEl,
          open,
          onClose,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {timestampComponents}
      </Menu>
    </Stack>
  ) : undefined;
};
export default VisionTimestampChooser;
