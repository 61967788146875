import React, { ErrorInfo } from 'react';
import { Box } from '@mui/material';

const ErrorBoundaryFallback = ({ error, info }: {error: Error, info: ErrorInfo}) => {
  console.error({ error, errorInfo: info });
  // Do something with the error
  // E.g. log to an error logging organization here
  return <Box sx={{
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#22292e',
    color: 'white',
    button: {
      margin: '20px',
      border: '#f6c90e 1px solid',
      borderRadius: '5px',
      padding: '10px',
      color: '#f6c90e'
    }
  }}>
    <p>
      Oops, something went wrong. Try reloading page or check if url is
      wrong
      {/* Check browser console */}
    </p>
    <button onClick={() => location.reload()}>
      Click to reload page
    </button>
    {' '}
  </Box>;
};

export default ErrorBoundaryFallback;