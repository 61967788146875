import {shallowEquals} from 'utils/functional/functionalUtils.ts';
import {always, cond, equals, T} from 'ramda';
import {
  concatReplacingMatchesWithRight,
  mergeWithKeyExistingAndIncoming,
  mergeWithKeyExistingAndIncomingListsByGroupingProp,
} from 'appUtils/typeUtils/mergeTypeUtils.ts';
import {
  TrainGroupsGrouping,
  TrainGroupsGroupingCollection,
} from 'types/trainGroups/trainGroupsGroupingCollection';
import {TrainGroupMinimized} from 'types/trainGroups/trainGroup';

/**
 *
 * Updates the SensorDataPoints for the TrainGroup, sorting existing with incoming
 * @param existingTrainGroup
 * @param incomingTrainGroup
 * @returns {*}
 */
export const mergeTrainGroupGroupingCollection = <T extends TrainGroupMinimized>(
  existingTrainGroup: TrainGroupsGroupingCollection<T>,
  incomingTrainGroup: TrainGroupsGroupingCollection<T>,
): TrainGroupsGroupingCollection<T> => {
  return mergeWithKeyExistingAndIncoming(
    (
      key: keyof TrainGroupsGroupingCollection<T>,
      existingPropValue: any,
      incomingPropValue: any,
    ) => {
      return cond([
        // If references equal, just take the incoming prop value
        [
          shallowEquals,
          (_a: any, b: any) => {
            return b;
          },
        ],
        [always(equals('trainGroupsByGroupings', key)), mergeTrainGroupGroupings],
        // Default to mergeDeep for other attributes
        [
          T,
          (_a: any, _b: any) => {
            throw new Error(
              'All attributes should be shallowly equal except trainGroupsByGroupings',
            );
          },
        ],
      ])(existingPropValue, incomingPropValue);
    },
    existingTrainGroup,
    incomingTrainGroup,
  );
};
/**
 * Merge the existing and incoming TrainGroupsGroupings collections when they
 * come from existing and incoming TrainGroupsGroupingCollections.
 * groupingCemitTypename and trainGroupCemitTypename must match for all
 * @returns The filterTrainGroups, maybe with cachedTrainGroups merged in
 * @param existingTrainGroupGroupings
 * @param incomingTrainGroupGroupings
 */
export const mergeTrainGroupGroupings = <T extends TrainGroupMinimized>(
  existingTrainGroupGroupings: TrainGroupsGrouping<T>[],
  incomingTrainGroupGroupings: TrainGroupsGrouping<T>[],
): TrainGroupsGrouping<T>[] => {
  return mergeWithKeyExistingAndIncomingListsByGroupingProp(
    'groupingId',
    (_id: string, existing: TrainGroupsGrouping<T>, incoming: TrainGroupsGrouping<T>) => {
      return mergeTrainGroupGrouping(existing, incoming);
    },
    existingTrainGroupGroupings,
    incomingTrainGroupGroupings,
  );
};

export const mergeTrainGroupGrouping = <T extends TrainGroupMinimized>(
  existingTrainGroupGrouping: TrainGroupsGrouping<T>,
  incomingTrainGroupGrouping: TrainGroupsGrouping<T>,
): TrainGroupsGrouping<T> => {
  return mergeWithKeyExistingAndIncoming(
    (
      key: keyof TrainGroupsGrouping<T>,
      existingTrainGroupsGrouping: TrainGroupsGrouping<T>,
      incomingTrainGroupsGrouping: TrainGroupsGrouping<T>,
    ) => {
      return cond([
        // If references equal, just take the incoming prop value
        [
          shallowEquals,
          (_a: any, b: any) => {
            return b;
          },
        ],
        [always(equals('trainGroups', key)), concatReplacingMatchesWithRight],
        // Default to mergeDeep for other attributes
        [
          T,
          (_a: any, _b: any) => {
            throw new Error(
              'All attributes should be shallowly equal except trainGroups',
            );
          },
        ],
      ])(existingTrainGroupsGrouping, incomingTrainGroupsGrouping);
    },
    existingTrainGroupGrouping,
    incomingTrainGroupGrouping,
  );
};
