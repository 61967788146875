import {TFunction} from 'i18next';
import {rideComfortAlertConfig} from 'types/alerts/rideComfort/rideComfortAlertConfig.ts';
import {rideComfortRoughRideTrackConfig} from 'types/alerts/rideComfort/rideComfortRoughRideTrackConfig.ts';
import {huntingAlertTypeConfig} from 'types/alerts/hunting/huntingAlertTypeConfig.ts';
import {rideComfortRoughRideTrainConfig} from 'types/alerts/rideComfort/rideComfortRoughRideTrainConfig.ts';
import {brakingAlertTypeConfig} from 'types/alerts/braking/brakingAlertTypeConfig.ts';

/**
 * The available AlertTypeConfigs
 * @param t The translation function is passed to translate all labels to the local language
 */
export const alertTypeConfigs = (t: TFunction) => {
  return [
    rideComfortAlertConfig(t),
    rideComfortRoughRideTrackConfig(t),
    rideComfortRoughRideTrainConfig(t),
    huntingAlertTypeConfig(t),
    brakingAlertTypeConfig(t),
  ];
};
