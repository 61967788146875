import {Stack, Tooltip, Typography} from '@mui/material';
import {STATION_DOT_HEIGHT} from 'theme/train/trainThemeConstants.ts';
import {always, cond, equals, join, pick, T} from 'ramda';
import {compact} from '@rescapes/ramda';
import TrainLineStationDotContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainLineStationDotContainer.tsx';
import React, {forwardRef, RefObject} from 'react';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {TrainProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {ScheduledStopPoint} from '../../../../types/stops/scheduledStopPoint';
import {ScheduledStopPointStopTimeOrDistance} from '../../../../types/stops/scheduledStopPointStopTimeOrDistance';
import {Size} from '../../../../types/layout/size';

import {TrainGroup} from '../../../../types/trainGroups/trainGroup';

/***
 * A Station on the TrainLine
 * with higher priority
 * @param componentProps
 * @param scheduledStopPointStopTimeOrDistance The scheduledStopPointAndMaybeDateTime/station with
 * the time or distance merged in
 * @param componentProps.offsetLeft
 * @param componentProps.setOffsetLeft
 * @param componentProps.trainRouteOrRunInterval
 * @param componentProps.limitedStations
 * @param  {Number} componentProps.recalculateOffsetLefts Timestamp updated to trigger new calculation
 * @param [componentProps.spaceGeospatially] Default false. If true, position the TrainLineStation absolutely
 * based on the
 * @param componentProps.stationPosition Default undefined. 'start' or 'end' if the station is at one of the extremes of the
 * visible stations
 * @param componentProps.trainDetailSize width and height props of the ancestor container, used to adjust
 * station offsetLefts when the panel size changes
 * @param refWidth width or the forwarded ref
 * @returns {JSX.Element}
 * @constructor
 */
const TrainLineStation = forwardRef(
  (
    {
      appProps,
      trainProps,
      componentProps: {
        offsetLeft,
        setOffsetLeft,
        recalculateOffsetLefts,
        trainGroup,
        limitedStations,
        spaceGeospatially,
        shouldCluster,
        scheduledStopPointStopName,
        scheduledStopPointStopTimeOrDistance,
        stationPosition,
        scheduledStopPoint,
        hoveredScheduledStopPoint,
        setHoveredScheduledStopPoint,
        trainDetailSize,
        refWidth,
      },
    }: {
      appProps: TrainAppProps;
      trainProps: TrainProps;
      componentProps: {
        offsetLeft: number;
        setOffsetLeft: StateSetter<number>;
        recalculateOffsetLefts: Date;
        trainGroup: TrainGroup;
        limitedStations: boolean;
        spaceGeospatially: boolean;
        shouldCluster: boolean;
        scheduledStopPointStopName: string;
        scheduledStopPointStopTimeOrDistance: ScheduledStopPointStopTimeOrDistance;
        stationPosition: StartOrEndPosition;
        scheduledStopPoint: ScheduledStopPoint;
        hoveredScheduledStopPoint: ScheduledStopPoint;
        setHoveredScheduledStopPoint: (
          scheduledStopPoint: ScheduledStopPoint | undefined,
        ) => void;
        trainDetailSize: Size;
        refWidth: number;
      };
    },
    ref: RefObject<React.ReactNode>,
  ) => {
    const withOrZero = refWidth || 0;

    // TODO move this logic to the TrainLineStationContainer and use
    // MUI-style embedded sx: {...styles, subComponent: {...styles}, ...}
    // alignSelf the station dot in the flex flow based on whether it is at/in the start/end/middle of the TrainRunLine
    const alignSelf = cond([
      [equals('start'), always('self-start')],
      [equals('end'), always('self-end')],
      [T, always('auto')],
    ])(stationPosition);

    const textAlign = cond([
      [equals('start'), always('left')],
      [equals('end'), always('right')],
      [T, always('center')],
    ])(stationPosition);

    // Move the station over half it's width if at the ends
    const marginLeft = cond([
      [equals('start'), always('auto')],
      [equals('end'), always(`${-withOrZero}px`)],
      [T, always(`${withOrZero / -2}px`)],
    ])(stationPosition);

    // Change the diameter of the station dot if it is the start or end of the selected TrainRouteGroup or TrainRoute
    const diameter = STATION_DOT_HEIGHT;

    const overlapStyle = equals(scheduledStopPoint, hoveredScheduledStopPoint)
      ? {
          bgcolor: 'trainRunLineStationDot.action.isOver',
        }
      : {};

    return (
      <Tooltip
        arrow
        sx={{minWidth: 0, width: '100%'}}
        placement="top"
        title={join(
          ' ',
          compact([scheduledStopPointStopName, scheduledStopPointStopTimeOrDistance]),
        )}
        onMouseOver={() => setHoveredScheduledStopPoint(scheduledStopPoint)}
        onMouseLeave={() => setHoveredScheduledStopPoint(undefined)}
      >
        {
          //@ts-ignore
          <Stack
            key="TrainLineStationStack"
            {...{
              ref,
              spacing: 1,
              sx: [
                {
                  minWidth: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  // Make sure the station names don't make the flex item too big
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
                // If spaceGeospatially,
                // position the stack absolutely and place based on the station's
                // distance along the trainRouteOrRunInterval
                spaceGeospatially
                  ? {
                      position: 'absolute',
                      left: `${offsetLeft}%`,
                      visibility: refWidth ? 'visible' : 'hidden',
                      marginLeft,
                    }
                  : {},
              ],
            }}
          >
            {/* @ts-ignore too complicated for now */}
            <TrainLineStationDotContainer
              key="trainLineStationDot"
              {...{
                appProps,
                trainProps,
                componentProps: {
                  diameter,
                  setOffsetLeft,
                  recalculateOffsetLefts,
                  trainGroup,
                  trainDetailSize,
                },
                sx: [
                  {
                    alignSelf,
                    bgcolor: scheduledStopPointStopTimeOrDistance
                      ? 'trainRunLineStationDot.main'
                      : 'trainRunLineStationDot.action.isDisabled',
                    ...(scheduledStopPointStopTimeOrDistance
                      ? {}
                      : {borderColor: 'secondary.disabled', border: 1}),
                  },
                  // Overrides colors on hover
                  overlapStyle,
                ],
              }}
            />
            <Stack
              {...{
                sx: {
                  color: scheduledStopPointStopTimeOrDistance
                    ? 'trainRunLineStationDot.main'
                    : 'secondary.disabled',
                  textAlign,
                  minWidth: 0,
                  width: '100%',
                  ...pick(['color'], overlapStyle),
                },
              }}
            >
              <Typography
                key="label"
                sx={{
                  // If clustering hide the label
                  visibility: shouldCluster ? 'hidden' : 'visible',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                }}
                variant="caption"
              >
                {scheduledStopPointStopName}
              </Typography>
              <Typography
                key="departureDatetime"
                sx={{
                  visibility: shouldCluster ? 'hidden' : 'visible',
                }}
                variant="caption"
              >
                {scheduledStopPointStopTimeOrDistance || '-'}
              </Typography>
            </Stack>
          </Stack>
        }
      </Tooltip>
    );
  },
);
TrainLineStation.displayName = 'TrainLineStation';
export default TrainLineStation;
