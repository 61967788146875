import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {CemitApiLoadingStatus} from '../../types/apis/cemitApiLoadingStatus.ts';
import {
  filter,
  includes,
  indexBy,
  join,
  length,
  lensProp,
  map,
  mergeWith,
  prop,
  set,
  values,
  when,
} from 'ramda';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {Identified} from '../../types/identified';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {compact} from '../functional/functionalUtils.ts';
import {mergeTrainGroup} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {SensorDataTrainGroup} from '../../types/trainGroups/sensorDataTrainGroup';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * A completed loading status
 */
export const completeLoadingStatus = {
  complete: true,
  loading: false,
  retry: false,
  error: false,
  errorDate: undefined,
};
/**
 * For instances that have a property that implements TrainGroupLoadingStatus, this finds the instances that
 * have !instance[loadingStatusProperty].complete &&  !loadingStatus(instance).loading and sets the loading status to true.
 * Then it calls the instancesSetter with the updates
 * @param loadingStatusProperty
 * @param instances
 * @param instancesSetter
 * @returns Only returns the instances that were updated.
 */
export const updateIncompletesToLoading = <T extends Identified>(
  loadingStatusProperty: keyof T,
  instances: T[],
  instancesSetter: StateSetter<Perhaps<T[]>>,
) => {
  const loadingStatus = (instance: T) => {
    return instance[loadingStatusProperty] as CemitApiLoadingStatus;
  };
  // We need to load ids that are not complete but not yet loading
  const needLoadingInstances = filter((instance: T) => {
    return !loadingStatus(instance).loading && !loadingStatus(instance).complete;
  }, instances);

  // Update the loading status true if complete is false.
  // complete will have been marked false whenever new props prompts API loading are modified
  // such as dateIntervals, distanceRanges, etc
  const updatedNeedLoadingInstances: T[] = map((instance) => {
    return when(
      (instance: T) => {
        return !loadingStatus(instance).loading || !loadingStatus(instance).complete;
      },
      (instance: T) => {
        return setClassOrType<T, CemitApiLoadingStatus>(
          lensProp(loadingStatusProperty),
          clsOrType<CemitApiLoadingStatus>(CemitTypename.loadingStatus, {
            loading: true,
            complete: false,
          }),
          instance,
        );
      },
    )(instance);
  }, instances);

  if (length(needLoadingInstances)) {
    instancesSetter((existingInstances) => {
      // Set the loadingStatus and combine sensorDataIntervals of what has already loaded
      // with what needs to load
      const merged = mergeWith(
        (existing: SensorDataTrainGroup, incoming: SensorDataTrainGroup) => {
          return mergeTrainGroup(existing, incoming);
        },
        indexBy(prop('id'), existingInstances || []),
        indexBy(prop('id'), updatedNeedLoadingInstances),
      );
      return values(merged);
    });
  }
  const needLoadingInstanceIds = map(prop('id'), needLoadingInstances);
  return filter(
    ({id}: T) => includes(id, needLoadingInstanceIds),
    updatedNeedLoadingInstances,
  );
};

/**
 * Dump the LoadingStatus for debugging
 * @param loadingStatus
 */
export const dumpLoadingStatus = (loadingStatus: CemitApiLoadingStatus) => {
  const errorStatus = loadingStatus.error
    ? `Error: ${loadingStatus.error}, Error Date: ${loadingStatus.errorDate}`
    : undefined;
  return join(
    ', ',
    compact([
      `Loading Status: Complete: ${loadingStatus.complete}, Loading: ${loadingStatus.loading}`,
      errorStatus,
    ]),
  );
};
