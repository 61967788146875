export const restrictionTypesKeys = {
    closedTrack: "closedTrack",
    backProhibition: "backProhibition",
    speedLimit: "speedLimit",
    other: "other",
};

export const restrictionTypes = {
    'closedTrack': "Avstängt spår",
    'backProhibition': "Backförbud",
    'speedLimit': "Hastighetsbegränsning",
    'other': "Annan begränsning",
};

export const REACT_BASE_APP_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? window.location.origin : window.location.hostname
export const CEMIT_AUTH_API = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'  ?  " https://auth.api.internal.cemit.digital/api" : "https://auth.api.cemit.digital/api";
