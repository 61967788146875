import {IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {VisibilityOutlined, VisibilityOffOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * Shows a visibility button to toggle a TrainGroup's visibility and an optional label next to the button
 * @param trainGroup
 * @param toggleTrainGroupVisibility
 * @param disabled
 * @param label
 * @constructor
 */
const TrainGroupVisibilityButton = ({
  trainGroup,
  toggleTrainGroupVisibility,
  disabled = false,
  label,
}: {
  trainGroup: TrainGroup;
  toggleTrainGroupVisibility: (trainGroup: TrainGroup) => void;
  disabled?: boolean;
  label?: string;
}) => {
  const {t} = useTranslation();
  // If label is present, this is assumed to be a menu item, so make the icon the state that will occur by clicking.
  // If a status button, make the icon the current state
  const {Icon, tooltipKey} = trainGroup?.activity?.isVisible
    ? {
        Icon: label ? VisibilityOffOutlined : VisibilityOutlined,
        tooltipKey: 'hideTrainGroup',
      }
    : {
        Icon: label ? VisibilityOutlined : VisibilityOffOutlined,
        tooltipKey: 'showTrainGroup',
      };

  const button = (
    <IconButton
      key="visibilityButton"
      {...{
        'aria-label': t(tooltipKey),
        disabled,
        sx: {
          float: 'right',
          alignItems: 'flex-start',
          color: trainGroup.activity.isActiveColor,
          p: 0,
        },
        onClick: () => {
          toggleTrainGroupVisibility(trainGroup);
        },
      }}
    >
      <Stack {...{spacing: 1, direction: 'row'}}>
        <Icon />
        {label && <Typography {...{color: 'secondary'}}>{label}</Typography>}
      </Stack>
    </IconButton>
  );

  return disabled ? (
    button
  ) : (
    <Tooltip key="tooltipVisualize" arrow title={t(tooltipKey)}>
      {button}
    </Tooltip>
  );
};

export default TrainGroupVisibilityButton;
