import {join} from 'ramda';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * The Svg data url used for a combination of one iconName and one trainGroup
 * Each TrainGroup needs its own data url per iconName in order to color it according to the trainGroup.activity.activityColor
 * @param iconPrefix
 * @param trainGroup
 */
export const svgDataUrlIconNameForIconOfTrainGroup = (
  iconPrefix: string,
  trainGroup: TrainGroup,
): string => {
  return join('-', [iconPrefix, trainGroup.id]);
};
