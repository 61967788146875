import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {AlertConfigProps} from 'types/alerts/alertConfigProps.ts';
import {AlertTrainGroupProps} from 'types/alerts/alertTrainGroupProps.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';

export const createEmptyRideComfortScopeProps = (): AlertScopeProps => {
  return clsOrType<AlertScopeProps>(CemitTypename.alertScopeProps, {
    alertConfigProps: clsOrType<AlertConfigProps>(CemitTypename.alertConfigProps, {}),
    alertTrainGroupProps: clsOrType<AlertTrainGroupProps>(
      CemitTypename.alertTrainGroupProps,
      {},
    ),
  });
};
