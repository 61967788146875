import {Stack, Typography} from '@mui/material';
import React from 'react';
import {
  always,
  and,
  cond,
  filter,
  find,
  head,
  identity,
  length,
  map,
  reverse,
  T,
} from 'ramda';
import AlertErrorLevelComponent, {
  AlertErrorLevelComponentProps,
} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertErrorLevelComponent.tsx';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {AlertBaseGauge} from 'types/alerts/alertGauge';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig.d.ts';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';

export interface AlertErrorLevelsComponentProps extends CemitComponentProps {
  trainGroup: TrainGroup;
  alertGauge: AlertBaseGauge;
  showCount: boolean;
  direction: 'column' | 'row';
  showAlertTitles: boolean;
  alertErrorLevelConfigs: AttributeAlertLevelConfig[];
  // Only show most severe alert that has a non-zero value
  // or else show the lowest level or none
  // An accordion control expands TrainGroupAlertStatus
  expandable: boolean;
  isExpanded: boolean;
}

/**
 * Shows warning levels for Alert based on the given AlertGauge. Shows the percent of each warning
 * and optionally the number of times each warning occurred
 * @param loading
 * @param trainGroup
 * @param alertGauge
 * @param showCount
 * @param direction The layout of the alerts
 * @param showAlertTitles
 * @param alertErrorLevelConfigs Configures which alerts ar shown
 * @param expandable
 * @param isExpanded
 * @constructor
 */
const AlertErrorLevelsComponent = ({
  loading,
  trainGroup,
  alertGauge,
  showCount = false,
  direction = 'column',
  showAlertTitles = true,
  alertErrorLevelConfigs,
  expandable,
  isExpanded,
}: AlertErrorLevelsComponentProps) => {
  const collapsed: boolean = expandable && !isExpanded;
  const expanded: boolean = expandable && isExpanded;

  // If the component is expandable, only show alert values that are non-zero
  const finalAlertErrorLevelConfigs: AttributeAlertLevelConfig[] = useNotLoadingMemo(
    loading,
    (alertErrorLevelConfigs, alertGauge: AlertBaseGauge, collapsed) => {
      // Find the highest level non-zero alert if collapsed
      const filteredAlertErrorLevelConfigs: AttributeAlertLevelConfig[] = cond([
        [
          always(collapsed),
          (alertErrorLevelConfigs: AttributeAlertLevelConfig) => {
            return [
              find((alertErrorLevelConfig: AttributeAlertLevelConfig): boolean => {
                return alertGauge[alertErrorLevelConfig.attributeAlertLevel].count > 0;
              }, reverse(alertErrorLevelConfigs)) || head(alertErrorLevelConfigs),
            ];
          },
        ],
        [
          always(expanded),
          (alertErrorLevelConfigs: AttributeAlertLevelConfig) => {
            // If the normal level is 100, just show that, even when expanded
            if (
              alertGauge[head(alertErrorLevelConfigs).attributeAlertLevel].value == 100
            ) {
              return [head(alertErrorLevelConfigs)];
            } else {
              // Return non-zero levels
              return filter(
                (alertErrorLevelConfig: AttributeAlertLevelConfig): boolean => {
                  return alertGauge[alertErrorLevelConfig.attributeAlertLevel].count > 0;
                },
                alertErrorLevelConfigs,
              );
            }
          },
        ],
        [T, identity],
      ])(alertErrorLevelConfigs);

      const nonZeroAlertLevels: AttributeAlertLevelConfig[] = filter(
        (filteredAlertErrorLevelConfig: AttributeAlertLevelConfig) => {
          return alertGauge[filteredAlertErrorLevelConfig.attributeAlertLevel].count > 0;
        },
        filteredAlertErrorLevelConfigs,
      );

      // Are there no alerts
      const noAlerts: boolean = length(nonZeroAlertLevels) == 0;
      // Are there only normal alerts
      const noNonNormalAlerts: boolean =
        length(nonZeroAlertLevels) == 1 &&
        nonZeroAlertLevels[0].attributeAlertLevel == 'normal';

      return cond([
        [
          always(and(expandable, noAlerts)),
          (
            _filteredAlertErrorLevelConfigs: AttributeAlertLevelConfig[],
          ): AttributeAlertLevelConfig[] => {
            // TODO make a None AttributeAlertLevelConfig
            return [alertErrorLevelConfigs[0]];
          },
        ],
        [
          always(and(expandable, noNonNormalAlerts)),
          (
            _filteredAlertErrorLevelConfigs: AttributeAlertLevelConfig[],
          ): AttributeAlertLevelConfig => {
            return nonZeroAlertLevels;
          },
        ],
        [T, identity],
      ])(filteredAlertErrorLevelConfigs);
    },
    [alertErrorLevelConfigs, alertGauge, collapsed],
  );

  const levels: JSX.Element[] = map(
    ({
      attributeAlertLevel,
      label,
      imageSvgComponent,
      color,
    }: AttributeAlertLevelConfig) => {
      return (
        <AlertErrorLevelComponent
          key={attributeAlertLevel}
          {...{
            loading,
            componentProps: ts<AlertErrorLevelComponentProps>({
              alertGauge,
              trainGroup,
              showCount,
              attributeAlertLevel,
              label,
              imageSvgComponent,
              color,
              iconSize: 15,
              labelVariant: 'subtitle2',
              showAlertTitles,
              showValue: direction,
              expandable,
              isExpanded,
            }),
          }}
        />
      );
    },
    finalAlertErrorLevelConfigs || [],
  );

  // In collapsed mode, show the alert type label. Otherwise show the alert level label
  const alertTypeLabel: string = alertGauge?.alertTypeConfig?.labelShort || '';
  return (
    <Stack
      {...{
        direction: 'column',
        sx: {
          width: '100%',
          flex: 1,
          minWidth: 0,
        },
      }}
    >
      {alertTypeLabel && (
        <Typography key={alertTypeLabel} {...{fontSize: '10px', color: 'secondary'}}>
          {`${alertTypeLabel}${expanded ? '%' : ''}`}
        </Typography>
      )}
      <Stack
        key="iconLevels"
        {...{
          direction,
          sx: {
            minWidth: 0,
            minHeight: 0,
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            justifyContent: 'start',
          },
        }}
      >
        {levels}
      </Stack>
    </Stack>
  );
};
AlertErrorLevelsComponent.displayName = 'AlertErrorLevelsComponent';
export default AlertErrorLevelsComponent;
