import {useNotLoadingEffect} from '../../../../utils/hooks/useMemoHooks.ts';
import {TrainProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {extractTrainRouteGroupsFromCemitFilter} from '../../../../appUtils/cemitFilterUtils/cemitFilterTrainRouteGroupUtils.ts';
import {extractTrainFormations} from '../../../../appUtils/cemitFilterUtils/cemitFilterTrainFormationUtils.ts';
import {extractDateIntervals} from '../../../../appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {DateRecurrence} from '../../../../types/datetime/dateRecurrence.d.ts';
import {extractCemitFilterDateRecurrences} from '../../../../appUtils/cemitFilterUtils/cemitFilterDateRecurrenceUtils.ts';
import {equals} from 'ramda';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {TrainGroupFilterProps} from 'types/propTypes/trainPropTypes/trainGroupFilterProps.d.ts';
import {TrainFormation} from '../../../../types/trains/trainFormation';
import {TrainGroup} from 'types/trainGroups/trainGroup';

export type UseNotLoadingSetFilterAndFilteredTrainGroupsProps = {
  setFilteredTrainGroups: StateSetter<Perhaps<TrainGroup[]>>;
  setFilterProps: StateSetter<Perhaps<TrainGroupFilterProps>>;
  filterProps: Perhaps<TrainGroupFilterProps>;
};

/**
 * Given the trainProps.cemitFilter and trainRoute and trainRouteGroups, extract the filter props
 * that are needed to query for FilteredTrainGroups
 * @param loading
 * @param trainProps
 * @param hookProps
 */
export const useNotLoadingSetFilterAndFilteredTrainGroups = (
  loading: boolean,
  trainProps: TrainProps,
  hookProps: UseNotLoadingSetFilterAndFilteredTrainGroupsProps,
) => {
  // We use cemitFilterWithDateRecurrences because it's currently the most customized filter in the dependency chain
  const parentFilter = trainProps.cemitFilter;
  // TODO hack. The parent filter doesn't have the correct TrainRouteOrGroup. Fix later
  const trainRoute = trainProps.trainRouteGroupProps.trainRouteOrGroup;
  const dependencies = [
    parentFilter,
    trainProps.trainRouteGroupProps.trainRoutes,
    trainProps.trainRouteGroupProps.trainRouteGroups,
    // Hack
    trainRoute,
  ] as const;

  useNotLoadingEffect(
    loading,
    (parentFilter, trainRoutes, trainRouteGroups, trainRoute) => {
      // TODO This filter is wrong
      // const [trainRoute] = extractTrainRouteGroupsFromCemitFilter(parentFilter, {
      //   trainRouteGroups,
      //   trainRouteGroups,
      // });
      const trainFormations: TrainFormation[] = extractTrainFormations(parentFilter, {
        trainGroupOnlyTrainFormations:
          trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations,
      });

      // TODO this filter is wrong
      //const [dateInterval] = extractDateIntervals(parentFilter, {});
      const [dateInterval] = extractDateIntervals(
        trainProps.trainFormationDateProps.cemitFilterWithDateIntervals,
        {},
      );
      const dateRecurrences: DateRecurrence[] = extractCemitFilterDateRecurrences(
        parentFilter,
        {},
      );

      if (
        !equals(hookProps.filterProps, {
          trainRoute,
          trainFormations,
          dateInterval,
          dateRecurrences,
        })
      ) {
        // Only setFilterProps if something actually changed since this forces an API call to get the TrainGroups
        hookProps.setFilterProps({
          trainRoute,
          trainFormations,
          dateInterval,
          dateRecurrences,
        });
        hookProps.setFilteredTrainGroups(undefined);
      }
    },
    dependencies,
  );
};
