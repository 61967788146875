// The default RideComfort Errors to show
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig.d.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TFunction} from 'i18next';
import {HuntingGauge} from 'types/alerts/hunting/huntingGauge';
import {
  CEMIT_ALERT_CRITICAL,
  CEMIT_ALERT_NORMAL,
  CEMIT_ALERT_WARNING,
} from 'theme/cemitColors.ts';
import NormalSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/normalSvgIcon.tsx';
import WarningSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/warningSvgIcon.tsx';
import ErrorSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/errorSvgIcon.tsx';

export const huntingErrorLevelConfigs = (
  t: TFunction,
): AttributeAlertLevelConfig<HuntingGauge>[] => {
  return clsOrTypes<AttributeAlertLevelConfig<HuntingGauge>>(
    CemitTypename.alertErrorLevelConfig,
    [
      {
        attributeAlertLevel: 'normal',
        label: t('normal'),
        imageSvgComponent: NormalSvgIcon,
        color: CEMIT_ALERT_NORMAL,
      },
      {
        attributeAlertLevel: 'warning',
        label: t('warning'),
        imageSvgComponent: WarningSvgIcon,
        color: CEMIT_ALERT_WARNING,
      },
      {
        attributeAlertLevel: 'critical',
        label: t('critical'),
        imageSvgComponent: ErrorSvgIcon,
        color: CEMIT_ALERT_CRITICAL,
      },
    ],
  );
};
