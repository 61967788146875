import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {useTranslation} from 'react-i18next';
import {CemitTypename} from 'types/cemitTypename.ts';
import {CemitAppProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppProps';

export const minAppProps = (): CemitAppProps => {
  const {t} = useTranslation();
  return clsOrType<CemitAppProps>(CemitTypename.trainAppProps, {
    loading: false,
    t,
    distanceUnit: 'km',
  });
};
