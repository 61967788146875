import { equals, find } from 'ramda';
import {
  ChartDataCommonConfig,
  ChartDataConfigWithCustomControls
} from '../../types/dataVisualizations/chartDataConfig.ts';
import { Perhaps } from '../../types/typeHelpers/perhaps';

/**
 * Resolves the commonConfig of the chartDataConfig if it has one
 * @param chartDataConfig
 */
export const resolveCommonConfig = (chartDataConfig: ChartDataConfigWithCustomControls): Perhaps<ChartDataCommonConfig> => {
  return find((commonConfig: ChartDataCommonConfig) => {
      return equals(
        chartDataConfig.chartDataConfigGroupingKey,
        commonConfig.chartDataConfigGroupingKey
      );
    },
    chartDataConfig.chartDataCategoryConfig?.commonConfigs || []
  )
}