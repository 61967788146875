import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {equals, filter} from 'ramda';
import {lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import {CrudList} from 'types/crud/crudList';

/**
 * Updated the given trainGroupsCrudList if the activeTrainGroups have activity changes
 * @param loading
 * @param activeTrainGroups
 * @param trainGroupsCrudList
 */
export const useNotLoadingUpdateCrudIfActivityChanges = <
  T extends TrainGroup = TrainGroup,
>(
  loading: boolean,
  activeTrainGroups: Perhaps<T[]>,
  trainGroupsCrudList: Perhaps<CrudList<T>>,
) => {
  // Save the updates to activeTrainGroupSingleTrainRuns if anything in activity changed
  useNotLoadingEffect(
    loading || !trainGroupsCrudList,
    (activeTrainGroups, crudTrainGroups) => {
      const needUpdates = filter((activeTrainGroup: T): boolean => {
        const existing = crudTrainGroups!.lookup[activeTrainGroup.id];
        return !equals(existing.activity, activeTrainGroup.activity);
      }, activeTrainGroups!);
      if (lengthAsBoolean(needUpdates)) {
        crudTrainGroups!.updateOrCreateAll(needUpdates!);
      }
    },
    [activeTrainGroups, trainGroupsCrudList] as const,
  );
};
