import React, {SyntheticEvent} from 'react';
import {filter, map, startsWith} from 'ramda';
import {IconButton, Stack, Tooltip} from '@mui/material';
import {ZoomInMap} from '@mui/icons-material';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TrainAppMapComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';
import {mapboxZoomToSources} from 'utils/map/mapboxSourceUtils.ts';
import {ALERT_TRAIN_GROUP_LAYER_PREFIX} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {MapboxLayer, MapSourceInfo, MapSourceVisual} from 'types/mapbox/mapSourceVisual';

export interface ZoomToAlertsProps extends CemitComponentProps {}

/**
 * A menu for selected Alert filter options
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
export const ZoomToAlerts = ({
  appProps,
  trainProps,
  mapProps,
  componentProps,
}: TrainAppMapComponentDependencyProps<ZoomToAlertsProps>) => {
  const handleClick = (_event: SyntheticEvent) => {
    const trainMap = mapProps.trainMap;
    // TODO we don't store MapSourceVisuals, so we need to derive them here
    const layers = filter((layer: MapboxLayer) => {
      return startsWith(ALERT_TRAIN_GROUP_LAYER_PREFIX, layer.id);
    }, mapProps.trainMap.getStyle().layers);
    const alertSourceVisuals: MapSourceVisual[] =
      mapProps.mapLayerProps.trainMapSensorLayerProps.alertSourceVisuals;
    mapboxZoomToSources(trainMap, alertSourceVisuals, 0, 0);
  };
  return (
    <Tooltip
      {...{
        arrow: true,
        title: appProps.t('zoomToAlerts'),
      }}
    >
      <Stack
        {...{
          direction: 'row',
          spacing: 1,
          sx: {justifyContent: 'left', alignItems: 'start'},
        }}
      >
        <IconButton
          key="button"
          {...{
            size: 'large',
            sx: {
              m: 0,
              p: 0,
              borderRadius: 0,
            },
            id: 'zoomToAlerts-button',
            onClick: handleClick,
          }}
        >
          <ZoomInMap {...{sx: componentProps?.iconSx}} />
        </IconButton>
      </Stack>
    </Tooltip>
  );
};
