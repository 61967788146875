import {
  always,
  chain,
  compose,
  concat,
  cond,
  equals,
  find,
  ifElse,
  indexBy,
  indexOf,
  join,
  length,
  lensIndex,
  map,
  mergeWith,
  none,
  pick,
  prop,
  props,
  T,
  values,
  when,
} from 'ramda';
import {
  hasNonZeroLength,
  headOrThrow,
  idsEqual,
  mergeRightIfDefined,
  toArrayIfNot,
} from 'utils/functional/functionalUtils.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {OrganizationLoaded} from 'types/organizations/organization.ts';
import {cemitApiResolveData} from 'appUtils/apiUtils/apiUtils.ts';
import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';
import {mergeTrainGroup} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {requestedSensorDataTrainGroupMergeAndMarkComplete} from './trainApiTrainGroupSensorDataQueryHandling.ts';
import {
  TrainApiTrainRunsRequestProps,
  TrainRoutOrGroupSensorDataRequestProps,
} from 'types/apis/trainApi';
import {overClassOrTypeList} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';

/**
 * Requests sensor data for a TrainGroup, such as SensorDataPoints from on-board sensors or
 * sensor data for stationary measurement equipment, such as a wheel scanner.
 */
export const queryApiForTrainGroupWithSensorPoints = (
  organization: OrganizationLoaded,
  requestedSensorDataTrainGroup: SensorDataTrainGroup,
  setLoadingSensorDataTrainGroups: StateSetter<TrainGroup[]>,
  setLoadedSensorDataTrainGroups: StateSetter<SensorDataTrainGroup[]>,
) => {
  const requestProps = queryApiForTrainGroupWithSensorPointsRequestProps(
    organization,
    requestedSensorDataTrainGroup,
  );
  // TODO we currently have different api calls for TrainGroupOnlyTrainFormation and TrainGroupSingleTrainRun
  const routeKey = cond([
    [
      (trainGroup: TrainGroup) =>
        implementsCemitTypeViaClass(
          CemitTypename.trainGroupOnlyTrainFormation,
          trainGroup,
        ),
      always('withTrainFormationSensorDataPoints'),
    ],
    [
      (trainGroup: TrainGroup) =>
        implementsCemitTypeViaClass(CemitTypename.trainGroupSingleTrainRun, trainGroup),
      always('withSensorDataPoints'),
    ],
    [
      T,
      (trainGroup) => {
        throw new Error(`Unexpected type: ${trainGroup.__typename}`);
      },
    ],
  ])(requestedSensorDataTrainGroup.trainGroup);

  // Call the API
  cemitApiResolveData(
    false,
    {timeout: 40000},
    organization,
    'trainRuns',
    requestProps,
    routeKey,
  )
    .then(({data: responseTrainGroups}: {data: TrainGroup}) => {
      // We currently only request one TrainGroup with sensor data at a time
      const responseTrainGroup: SensorDataTrainGroup = headOrThrow(
        toArrayIfNot(responseTrainGroups),
      );

      // TODO temporary for the attributeAlerts, request them immediate after by trainRunDetails.
      // We might change this later, but the attributeAlerts represent the TrainGroup sensor data so it makes
      // sense to request them here.
      // TODO Move to another useEffect and load as needed
      return organization.serviceLines
        ? // Nothing to do
          {data: responseTrainGroup}
        : // Load the Wheelscan attribute alert data
          queryApiForAttributeAlertSensorDataTrainGroups(
            organization,
            requestedSensorDataTrainGroup,
            responseTrainGroup,
          );
    })
    .then(({data: responseTrainGroup}: {data: SensorDataTrainGroup}) => {
      // Note errors.
      const isError = responseTrainGroup.error;

      // Merge the loaded data wth setLoadingSensorDataTrainGroups
      // This will be detected and mirrored in the trainGroupCrud list
      processTrainGroupSensorData(
        organization.serviceLines,
        requestedSensorDataTrainGroup,
        responseTrainGroup,
        setLoadingSensorDataTrainGroups,
        setLoadedSensorDataTrainGroups,
        isError,
        ['loadingStatus'],
        organization.timezoneStr,
      );
    });
};

/**
 * Calculate requests props queryApiForTrainGroupWithSensorPoints
 * @param organization
 * @param requestedSensorDataTrainGroup
 */
const queryApiForTrainGroupWithSensorPointsRequestProps = (
  organization: OrganizationLoaded,
  requestedSensorDataTrainGroup: SensorDataTrainGroup,
) => {
  // Calculate the API params
  return clsOrType<TrainApiTrainRunsRequestProps>(
    CemitTypename.trainApiTrainRunsRequestProps,
    {
      sensorDataTrainGroup: requestedSensorDataTrainGroup,
      ...(organization.serviceLines
        ? {
            dateInterval: requestedSensorDataTrainGroup.trainGroup.activeDateInterval,
            trainRouteGroupProps: clsOrType<TrainRoutOrGroupSensorDataRequestProps>(
              CemitTypename.trainRoutOrGroupSensorDataRequestProps,
              {
                // TODO Fix types
                distanceResolution:
                  requestedSensorDataTrainGroup?.distanceResolution?.toString(),
                // If distanceRanges is defined, split them into a string of start, end so that the API can handle them
                // If undefined, we want to query for the TrainRun's entire range at this distanceResolution
                // this results in 'start1,end1,start2,end2,...'
                ...(requestedSensorDataTrainGroup.distanceRanges
                  ? {
                      distanceRanges_in: join(
                        ',',
                        chain(
                          props(['start', 'end']),
                          (requestedSensorDataTrainGroup as SensorDataTrainGroup)
                            .distanceRanges!,
                        ),
                      ),
                    }
                  : {}),
              },
            ),
          }
        : {}),
    },
  );
};

/**
 * Queries for sensor data from a particular piece of equipment, such as a wheel. This is a more granular
 * query than queryApiForTrainGroupWithSensorPoints, because queryApiForTrainGroupWithSensorPoints
 * is for the entire TrainGroup, which is currently a single TrainSet.
 * @param trainGroupId The TrainRun id to request SensorDataPoints for
 * @param distanceResolution The current distanceResolution (e.g. 100 for 100 meters between points)
 * @param requestDistanceRanges List of distance ranges for the distance interval instruction the
 * API what range the user wants to look at relative to meters from the start of the TrainRoute to the end.
 * E.g. [{start: 0, end: 89000}]
 * @param trainRun Currently just for missing data handling
 * @param trainGroupSensorDataDistanceIntervalAndRangeSet: Keeps track of loading of sensorDataPoints for trainRuns
 in the form [{ trainGroupId, distanceResolution, distanceRanges }, ...]
 where distanceResolution is the distance interval we requested SensorDataPoints at, such as 100 meters or 50 meters,
 and distanceRanges are the ranges requested at that interval. They are always in order by trainRoute distance
 and consolidated to not overlap.
 Each distanceResolution object can be marked with distanceRangeErrors containing distanceRange
 requests that errored.
 * @param setTrainGroupSensorDataDistanceIntervalAndRangeSets Only used on error to force a rerequest
 */

/**
 * Merging setter code for setLoadedSensorDataTrainGroups
 * Returns a setter for setLoadedSensorDataTrainGroups
 * @param organizationHasServiceLines
 * @param completedSensorDataTrainGroup
 * @returns
 */
export const setterForSetLoadedSensorDataTrainGroups =
  (
    organizationHasServiceLines: boolean,
    completedSensorDataTrainGroup: SensorDataTrainGroup,
  ) =>
  (
    existingLoadedSensorDataTrainGroups: SensorDataTrainGroup[],
  ): SensorDataTrainGroup[] => {
    // Set the sensor data status props that were used to successfully load data on sensorDataTrainGroup
    const updatedExistingLoadedSensorDataTrainGroups = completedSensorDataTrainGroup.error
      ? existingLoadedSensorDataTrainGroups
      : mergeInLoadedSensorDataStatusProps(
          organizationHasServiceLines,
          existingLoadedSensorDataTrainGroups,
          completedSensorDataTrainGroup,
        );

    // Merge what we just fetched with the existing
    const mergedLoadedTrainGroupsWithSensorDataStatus = compose(
      (mergedById) => {
        // Removed the ids
        return values(mergedById);
      },
      ([a, b]) => {
        return mergeWith(
          (
            existingTrainGroup: SensorDataTrainGroup[],
            incomingTrainGroup: SensorDataTrainGroup[],
          ) => {
            // Return the success case or other errors, the latter where we merge the error prop in
            return mergeTrainGroup(existingTrainGroup, incomingTrainGroup);
          },
          a,
          b,
        );
      },
      // Index by TrainGroup id so we can merge
      (trainGroupsWithSensorDataStatuses) => {
        return map(
          (trainGroupsWithSensorDataStatuse) =>
            indexBy(prop('id'), trainGroupsWithSensorDataStatuse),
          trainGroupsWithSensorDataStatuses,
        );
      },
    )([updatedExistingLoadedSensorDataTrainGroups, [completedSensorDataTrainGroup]]);
    return mergedLoadedTrainGroupsWithSensorDataStatus;
  };

/**
 * Process data or errors for TrainGroups with TrainRoute or non-TrainRoute based sensor data.
 * Merges the distanceResolutions and distanceRanges requested with the response data and returns
 * those
 * @param requestedSensorDataTrainGroup
 * @param responseSensorDataTrainGroup
 * @param setLoadingSensorDataTrainGroups
 * @param setLoadedSensorDataTrainGroups
 * @param isError
 * @param statusLensPath
 * @param timezoneStr
 */
export const processTrainGroupSensorData = (
  organizationHasServiceLines: boolean,
  requestedSensorDataTrainGroup: SensorDataTrainGroup,
  responseSensorDataTrainGroup: SensorDataTrainGroup,
  setLoadingSensorDataTrainGroups: StateSetter<TrainGroup[]>,
  setLoadedSensorDataTrainGroups: StateSetter<SensorDataTrainGroup[]>,
  isError: boolean,
  statusLensPath: string[],
  timezoneStr: string,
): void => {
  // When an isError or noDataEver occurs
  const updatedRequestedTrainGroupWithSensorDataStatus: SensorDataTrainGroup = ifElse(
    // TODO Allow noDataEver for now
    (_) => isError, //or(isError, noDataEver),
    (requestedTrainGroupWithSensorDataStatus: SensorDataTrainGroup) => {
      // TODO For errors, just warn and mark complete so the system doesn't get stuck.
      // There used to be error handling for failed or empty query results,
      // but that logic needs to be rebuilt
      // Mark requestedTrainGroupWithSensorDataStatus as completed
      // and setLoadingSensorDataTrainGroups
      console.warn(
        `An error occurred dowloading sensorData for ${requestedSensorDataTrainGroup.trainGroup.name}`,
      );
      return requestedSensorDataTrainGroupMergeAndMarkComplete(
        requestedTrainGroupWithSensorDataStatus,
        setLoadingSensorDataTrainGroups,
        statusLensPath,
        timezoneStr,
      );
    },
    (requestedTrainGroupWithSensorDataStatus: SensorDataTrainGroup) => {
      // Mark requestedTrainGroupWithSensorDataStatus as completed
      // and setLoadingSensorDataTrainGroups
      return requestedSensorDataTrainGroupMergeAndMarkComplete(
        requestedTrainGroupWithSensorDataStatus,
        setLoadingSensorDataTrainGroups,
        statusLensPath,
        timezoneStr,
      );
    },
  )(requestedSensorDataTrainGroup);

  // Merge in the downloaded responseSensorDataTrainGroup.sensorDataPoints
  const completeRequestAndResponseSensorDataTrainGroup: SensorDataTrainGroup = clsOrType(
    updatedRequestedTrainGroupWithSensorDataStatus.__typename,
    mergeRightIfDefined(updatedRequestedTrainGroupWithSensorDataStatus, {
      sensorDataPoints: responseSensorDataTrainGroup.sensorDataPoints,
    }),
  );

  // Call the setter to mark the loadingTrainGroups as completes
  // completeRequestAndResponseSensorDataTrainGroup is merged into
  // the corresponding loadedSensorDataTrainGroup, particularly the sensorDataDateIntervals and sensorDataPoints
  // are combined with the existing
  setLoadedSensorDataTrainGroups(
    setterForSetLoadedSensorDataTrainGroups(
      organizationHasServiceLines,
      completeRequestAndResponseSensorDataTrainGroup,
    ),
  );
};

/**
 * When new sensor data comes back in a response, we update requestedSensorDataTrainGroup
 * to include the prop values that were used to request the sensor data for the given requestedSensorDataTrainGroup
 * @param organizationHasServiceLines
 * @param existingLoadedSensorDataTrainGroups
 * @param completedSensorDataTrainGroup
 */
const mergeInLoadedSensorDataStatusProps = (
  organizationHasServiceLines: boolean,
  existingLoadedSensorDataTrainGroups: SensorDataTrainGroup[],
  completedSensorDataTrainGroup: SensorDataTrainGroup,
) => {
  // Find the matching loaded object in existingLoadedSensorDataTrainGroups if it has
  // ever been loaded with sensor data. Otherwise just create an identified TrainGroupWithSensorDataStatus
  const existingLoadedTrainGroupsWithSensorDataStatus =
    find((existingLoadedTrainGroupsWithSensorDataStatus) => {
      return idsEqual(
        existingLoadedTrainGroupsWithSensorDataStatus,
        completedSensorDataTrainGroup,
      );
    }, existingLoadedSensorDataTrainGroups) ||
    (pick(['id'], completedSensorDataTrainGroup) as Partial<SensorDataTrainGroup>);

  const existingIndex = indexOf(
    existingLoadedTrainGroupsWithSensorDataStatus,
    existingLoadedSensorDataTrainGroups,
  );
  // Find the existing index, if any
  const index = when(equals(-1), () => length(existingLoadedSensorDataTrainGroups))(
    existingIndex,
  );

  return overClassOrTypeList(
    // Replace the value at index or add to the end of the list
    lensIndex(index),
    (existing) => {
      // Merge in incoming sensorDataDateIntervals or distanceResolutionsAndRanges
      // sensorDataPoints, and for the Wheelscan app equipmentAttributeDateIntervals
      return existing
        ? mergeTrainGroup(existing, completedSensorDataTrainGroup)
        : completedSensorDataTrainGroup;
    },
    // Add an undefined slot to fill if existingIndex is undefined
    concat(existingLoadedSensorDataTrainGroups, existingIndex == -1 ? [undefined] : []),
  );
};
