import { useState, useCallback } from 'react';
import { read, utils, WorkBook } from 'xlsx';

interface ExcelData {
  detektor: string;          // detektor
  X: number;                 // X
  Y: number;                 // Y
  riktning: number;          // riktning
  tagID: string;             // tag-ID
  tagtyp: number;            // tagtyp
  sequenceNumber: number;    // sekv.nr
  radioNumber: number;       // radionr
  functionID: number;        // funk-ID
  object: string;            // objekt
  objectName: string;        // objektnamn
  equipmentRoom: string;     // apparatrum
  equipmentRoomName: string; // apparatrumsnamn
  source: number;            // källa
}
const useExcelToGeoJson = (fileUrl: string) => {
  const [pointsGeoJsonData, setPointsGeoJsonData] = useState<any>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const convertExcelToGeoJson = useCallback(async () => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch Excel file: ${response.statusText}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      const workbook: WorkBook = read(arrayBuffer, { type: 'array' });
      parseExcelData(workbook);
    } catch (error) {
      console.error('Error fetching or parsing Excel file:', error);
    }
  }, [fileUrl]);

  const parseExcelData = useCallback((workbook: WorkBook) => {
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json: any[] = utils.sheet_to_json(worksheet, { header: 1 });

    // Remove header row and process data
    const header = json[0] as string[];
    const rows = json.slice(1);

    const formattedData: ExcelData[] = rows.map((row) => ({
      detektor: row[header.indexOf('detektor')],
      X: parseFloat(row[header.indexOf('X')]),
      Y: parseFloat(row[header.indexOf('Y')]),
      riktning: parseInt(row[header.indexOf('riktning')], 10),
      tagID: row[header.indexOf('tag-ID')],
      tagtyp: parseInt(row[header.indexOf('tagtyp')], 10),
      sequenceNumber: parseInt(row[header.indexOf('sekv.nr')], 10),
      radioNumber: parseInt(row[header.indexOf('radionr')], 10),
      functionID: parseInt(row[header.indexOf('funk-ID')], 10),
      object: row[header.indexOf('objekt')],
      objectName: row[header.indexOf('objektnamn')] || '',
      equipmentRoom: row[header.indexOf('apparatrum')],
      equipmentRoomName: row[header.indexOf('apparatrumsnamn')],
      source: parseInt(row[header.indexOf('källa')], 10)
    }));

    const geoJson = {
      type: 'FeatureCollection',
      features: formattedData.map((item) => ({
        type: 'Feature',
        properties: {
          detector: item.detektor,
          direction: item.riktning,
          tagID: item.tagID,
          tagType: item.tagtyp,
          sequenceNumber: item.sequenceNumber,
          radioNumber: item.radioNumber,
          functionID: item.functionID,
          object: item.object,
          objectName: item.objectName,
          equipmentRoom: item.equipmentRoom,
          equipmentRoomName: item.equipmentRoomName,
          source: item.source,
        },
        geometry: {
          type: 'Point',
          coordinates: [item.X, item.Y],
        },
      })),
    };

    setPointsGeoJsonData(geoJson);
    setDataLoaded(true); // Mark data as loaded
  }, []);

  return { pointsGeoJsonData, dataLoaded, convertExcelToGeoJson };
};

export default useExcelToGeoJson;