import {ReactElement, useMemo, useState} from 'react';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {useConfiguredApiForTrainFormations} from 'async/trainAppAsync/trainAppHooks/trainApiHooks/trainApiFormationHooks.ts';
import {useNotLoadingSyncCrudListToTrainFormationFilter} from 'async/trainAppAsync/trainAppHooks/cemitFilterHooks/cemitFilterTrainFormationHooks.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainGroupOnlyTrainFormationProps} from 'types/propTypes/trainPropTypes/trainGroupOnlyTrainFormationProps';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {isLoadingStringOfDependencyUnit} from 'async/trainAppAsync/trainAppDependencies/trainDependencyUnitConfig.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from 'async/trainAppAsync/trainAppHooks/loadingExplanationHooks.ts';
import {
  useEffectCreateCrudTrainGroups,
  useNotLoadingMemoActiveTrainGroups,
  useStorageTrainGroupsGroupingCollection,
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {CrudList} from 'types/crud/crudList';
import {all, equals, filter, find, head, lensPath, set} from 'ramda';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {addTrainFormationsToCemitFilter} from 'appUtils/cemitFilterUtils/cemitFilterTrainFormationUtils.ts';
import {
  CemitFilterTrainFormationProps,
  CemitFilterTrainGroupOnlyFormationProps,
} from 'types/cemitFilters/cemitFilterTrainFormationProps.ts';
import {
  TrainGroupsGrouping,
  TrainGroupsGroupingCollection,
} from 'types/trainGroups/trainGroupsGroupingCollection';
import {useCustomLocalStorageForCemitFilter} from 'utils/hooks/useCustomLocalStorageForCemitFilter.ts';
import {TrainFormation} from 'types/trains/trainFormation';
import {LocalStorageProps} from 'types/cemitFilters/localStorageProps.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {resolveCemitFilterConfig} from 'appUtils/cemitFilterUtils/cemitFilterConfigs.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {extractDateIntervals} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {TRAIN_GROUP_MAX_ACTIVE_COUNT} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {useNotLoadingUpdateCrudIfActivityChanges} from 'async/trainAppAsync/trainAppHooks/trainGroupCrudListHooks.ts';

/**
 * Loads/Updates Formations (trains sets) into trainProps.formationProps
 * Depends directly on organizationProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children Render prop function to render dependents
 * @return {Object} Rendered child trafficSimComponents
 */
const TrainFormationDependency = ({
  appProps,
  organizationProps,
  trainProps,
  renderChildren,
  loading,
}: Required<TrainAppTrainDependencyProps>): ReactElement<
  Required<TrainAppTrainDependencyProps>
> => {
  const parentCemitFilter: CemitFilter = trainProps.cemitFilter;

  // TrainPage TrainFormations (trainset)
  // A TrainFormation can be represented by a TrainFormations or by something that implements TrainGroupOnlyTrainFormation,
  // namely TrainGroup
  const [trainGroupOnlyTrainFormations, setTrainGroupOnlyTrainFormations] =
    useState<Perhaps<TrainGroupOnlyTrainFormation[]>>(undefined);
  // const setTrainGroupOnlyTrainFormations = useCallback(
  //   (
  //     value:
  //       | Perhaps<TrainGroupOnlyTrainFormation[]>
  //       | ((
  //           list: Perhaps<TrainGroupOnlyTrainFormation[]>,
  //         ) => Perhaps<TrainGroupOnlyTrainFormation[]>),
  //   ) => {
  //     _setTrainGroupOnlyTrainFormations(value);
  //   },
  //   [],
  // );
  // id-based lookup of trainGroupOnlyTrainFormations
  const [trainGroupOnlyTrainFormationLookup, setTrainGroupOnlyTrainFormationLookup] =
    useState<Perhaps<Record<string, TrainGroupOnlyTrainFormation>>>(undefined);

  // Store a CemitFilter that combines the parent cemitFilterWithTrainRouteGroup
  // This defaults to its parent filter
  const [cemitFilterWithTrainFormations, setCemitFilterWithTrainFormations] =
    useCustomLocalStorageForCemitFilter<TrainFormation>(
      !trainGroupOnlyTrainFormations,
      clsOrType<LocalStorageProps<TrainFormation>>(CemitTypename.localStorageProps, {
        localStorageKey: AppSettings.localStorage.trainGroupFilterTrainFormation,
      }),
      parentCemitFilter,
      resolveCemitFilterConfig(CemitTypename.cemitFilterTrainFormation),
      clsOrType<CemitFilterTrainFormationProps>(
        CemitTypename.cemitFilterTrainFormationProps,
        {trainGroupOnlyTrainFormations},
      ),
    );

  // Storage of TrainGroupOnlyTrainFormations grouped by organization id.
  // This keeps a cache of crudTrainGroupOnlyTrainFormations.list items, which includes the
  // activity: {isActive} value of each so that we know what instance(s) the user had active
  const [
    trainGroupsOnlyTrainFormationGroupingCollection,
    setTrainGroupsOnlyTrainFormationGroupingCollection,
  ] = useStorageTrainGroupsGroupingCollection<TrainGroupOnlyTrainFormation>(
    organizationProps,
    trainProps,
    {
      cacheKey: AppSettings.localStorage.trainGroupOnlyTrainFormations,
      groupingCemitTypename: CemitTypename.organization,
      trainGroupCemitTypename: CemitTypename.trainGroupOnlyTrainFormation,
      restoreFilter: (
        trainGroupsGroupingCollection: TrainGroupsGroupingCollection<TrainGroup>,
      ) => {
        // Get the trainGroups of the Organization that were rehydrated
        const trainGroupsGrouping: Perhaps<TrainGroupsGrouping<TrainGroup>> = find(
          (trainGroupsByGrouping: TrainGroupsGrouping<TrainGroup>) => {
            return (trainGroupsByGrouping.groupingId = organizationProps.organization.id);
          },
          trainGroupsGroupingCollection.trainGroupsByGroupings,
        );
        if (!trainGroupsGrouping) {
          return;
        }
        const trainGroupOnlyTrainFormations = trainGroupsGrouping.trainGroups;

        // Find those that are active
        const activeTrainGroupOnlyTrainFormations: TrainGroup[] = filter(
          (trainGroupOnlyTrainFormation: TrainGroup) => {
            return Boolean(trainGroupOnlyTrainFormation?.activity?.isActive);
          },
          trainGroupOnlyTrainFormations,
        );

        // Set the filter to the active
        addTrainFormationsToCemitFilter(
          cemitFilterWithTrainFormations,
          setCemitFilterWithTrainFormations,
          activeTrainGroupOnlyTrainFormations,
          clsOrType<CemitFilterTrainGroupOnlyFormationProps>(
            CemitTypename.cemitFilterTrainGroupOnlyFormationProps,
            {
              trainGroupOnlyTrainFormations,
            },
          ),
        );
      },
    },
  );

  const [crudTrainGroupOnlyTrainFormations, setCrudTrainGroupOnlyTrainFormations] =
    useState<Perhaps<CrudList<TrainGroupOnlyTrainFormation>>>(undefined);

  // crudTrainGroupOnlyTrainFormations are in a loading state until each item's
  // activeDateInterval matches the DateInterval in cemitFilterWithTrainFormations
  const crudTrainGroupOnlyTrainFormationsHaveActiveCurrentDateInterval =
    useNotLoadingMemo(
      loading,
      (crudTrainGroupOnlyTrainFormations, cemitFilterWithTrainFormations) => {
        const dateInterval = head(extractDateIntervals(cemitFilterWithTrainFormations));
        return all((trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation) => {
          return equals(dateInterval, trainGroupOnlyTrainFormation.activeDateInterval);
        }, crudTrainGroupOnlyTrainFormations.list);
      },
      [crudTrainGroupOnlyTrainFormations, cemitFilterWithTrainFormations] as const,
    );

  // Bootstrap trainGroupOnlyTrainFormationProps for activeTrainGroupFormations
  const bootstrappedTrainProps = useMemo(() => {
    return set(
      lensPath([
        'trainGroupOnlyTrainFormationProps',
        'crudTrainGroupOnlyTrainFormations',
      ]),
      crudTrainGroupOnlyTrainFormations,
      trainProps,
    );
  }, [trainProps, crudTrainGroupOnlyTrainFormations]);

  const activeTrainGroupFormations: Perhaps<TrainGroupOnlyTrainFormation[]> =
    useNotLoadingMemoActiveTrainGroups<TrainGroupOnlyTrainFormation>(
      loading || !crudTrainGroupOnlyTrainFormations,
      bootstrappedTrainProps,
      TRAIN_GROUP_MAX_ACTIVE_COUNT,
    );
  // Update any activeTrainGroupFormations in crudTrainGroupOnlyTrainFormations whose activity property changed
  useNotLoadingUpdateCrudIfActivityChanges(
    loading,
    activeTrainGroupFormations,
    crudTrainGroupOnlyTrainFormations,
  );

  // Tracks the modal to create a Formations filter
  const [choosingTrainFormations, setChoosingTrainFormations] = useState<boolean>(false);

  //defaultAccelerationThresholds({ t: appProps.t });

  // Download the TrainFormations into TrainRungGroupOnlyTrainFormations,
  // merging trainGroupsOnlyTrainFormationGroupingCollection's data in localStorage
  // to mark an item as {activity: {isActive: true}} if the user previously had a selected
  // TrainRungGroupOnlyTrainFormations
  useConfiguredApiForTrainFormations(
    organizationProps.loading,
    organizationProps.organization,
    cemitFilterWithTrainFormations,
    ['alert_status', 'distance'], //,'train_type',
    trainGroupOnlyTrainFormations,
    setTrainGroupOnlyTrainFormations,
    trainGroupsOnlyTrainFormationGroupingCollection,
  );

  // Sync the items in crudTrainGroupOnlyTrainFormations.list to cemitFilterWithTrainFormations,
  // including the activeDateInterval of each instance and which instance(s) are currently active
  const cemitFilterSyncedWithParent = useNotLoadingMemo(
    loading,
    (cemitFilterWithTrainFormations, parentCemitFilter) => {
      return cemitFilterWithTrainFormations.parent == parentCemitFilter;
    },
    [cemitFilterWithTrainFormations, parentCemitFilter],
  );
  useNotLoadingSyncCrudListToTrainFormationFilter(
    loading ||
      !trainGroupOnlyTrainFormations ||
      !crudTrainGroupOnlyTrainFormations ||
      !cemitFilterSyncedWithParent,
    cemitFilterWithTrainFormations,
    crudTrainGroupOnlyTrainFormations,
  );

  // Manages changes to the trainGroupOnlyTrainFormations
  useEffectCreateCrudTrainGroups(
    organizationProps.organization,
    false,
    trainGroupOnlyTrainFormations,
    setTrainGroupOnlyTrainFormations,
    setCrudTrainGroupOnlyTrainFormations,
    trainGroupOnlyTrainFormationLookup,
    setTrainGroupOnlyTrainFormationLookup,
    undefined,
    setTrainGroupsOnlyTrainFormationGroupingCollection,
  );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainFormationDependency.name),
    TrainFormationDependency.name,
    {
      trainGroupOnlyTrainFormations,
      crudTrainGroupOnlyTrainFormations,
      cemitFilterWithTrainFormations,
      crudTrainGroupOnlyTrainFormationsHaveActiveCurrentDateInterval,
      cemitFilterSyncedWithParent,
    },
    [
      trainGroupOnlyTrainFormations,
      crudTrainGroupOnlyTrainFormations,
      cemitFilterWithTrainFormations,
      crudTrainGroupOnlyTrainFormationsHaveActiveCurrentDateInterval,
      cemitFilterSyncedWithParent,
    ],
    appProps.setWhatDependenciesAreLoading,
  );

  const trainGroupOnlyTrainFormationProps = useMemoClsOrType<
    Omit<TrainGroupOnlyTrainFormationProps, 'loading'>
  >(CemitTypename.trainGroupOnlyTrainFormationProps, {
    whatIsLoading,
    trainGroupOnlyTrainFormations,
    setTrainGroupOnlyTrainFormations,
    crudTrainGroupOnlyTrainFormations,
    setCrudTrainGroupOnlyTrainFormations,
    activeTrainGroupFormations,
    cemitFilterWithTrainFormations,
    setCemitFilterWithTrainFormations,
    choosingTrainFormations,
    setChoosingTrainFormations,
  });

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainGroupOnlyTrainFormationProps',
    trainGroupOnlyTrainFormationProps,
    cemitFilterWithTrainFormations,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainGroupOnlyTrainFormationProps,
    'TrainFormationDependency',
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
  });
};
TrainFormationDependency.displayName = 'FormationDependency';
export default TrainFormationDependency;
// export default memo(TrainFormationDependency, (prevProps, currentProps) => {
//   const appPropsEqual = prevProps.appProps == currentProps.appProps;
//   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
//   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
//   return appPropsEqual && orgPropsEqual && trainPropsEqual;
// });
