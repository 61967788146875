import {compact} from '@rescapes/ramda';
import {
  all,
  chain,
  complement,
  filter,
  find,
  forEach,
  indexBy,
  length,
  map,
  mergeWith,
  none,
  omit,
  prop,
  propEq,
  propOr,
  unless,
  values,
} from 'ramda';

import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {updateSensorDataOfCrudTrainGroupsPreMerge} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {SensorDataTrainGroup} from '../../../../types/trainRuns/trainRun';
import {CrudList} from 'types/crud/crudList';
import {
  dateIntervalDifferences,
  dateIntervalIsWithinOtherDateInterval,
  dumpDateIntervals,
} from 'utils/datetime/dateUtils.ts';
import {idsEqual, toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {mergeLoadingStatus} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/loadingStatusMerging.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroupSensorDataToLoadTrainRouteProps} from 'types/trainRouteGroups/trainGroupSensorDataToLoadTrainRouteProps';
import {SensorDataDistanceIntervalWithRangeSet} from 'types/distances/sensorDataDistanceInterval';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Uses minimum TrainRuns with sensorDataPoints downloaded to minimumTrainRunsWithSensorDataPoints to updated
 * The TrainGroups
 * @param loading
 * @param compareFunction
 * @param crudTrainGroups
 * @param loadedSensorDataTrainGroups
 */
export const useSetTrainGroupsCompletedLoadingSensorData = (
  loading: boolean,
  compareFunction: (
    loadedSensorDataTrainGroup: SensorDataTrainGroup,
    trainGroup: TrainGroup,
  ) => boolean,
  crudTrainGroups: CrudList<TrainGroup>,
  loadedSensorDataTrainGroups: Perhaps<SensorDataTrainGroup[]>,
) => {
  useNotLoadingEffect(loading, () => {
    const loadedSensorDataTrainGroupsById = indexBy(
      prop('id'),
      loadedSensorDataTrainGroups || [],
    );

    const areTrainGroupsSynced =
      !length(loadedSensorDataTrainGroups || []) ||
      all((crudTrainGroup: TrainGroup): boolean => {
        const loadedSensorDataTrainGroup: SensorDataTrainGroup = propOr(
          undefined,
          crudTrainGroup.id,
          loadedSensorDataTrainGroupsById,
        );
        if (!loadedSensorDataTrainGroup) {
          // Nothing has just loaded for trainGroup
          return true;
        }

        return compareFunction(loadedSensorDataTrainGroup, crudTrainGroup);
      }, crudTrainGroups.list);

    // Done
    if (areTrainGroupsSynced) {
      return;
    }

    // Set the SensorDataPoints of crudTrainGroups.list items to the SensorDataPoints of loadedSensorDataTrainGroups
    // LoadedSensorDataTrainGroups has all SensorDataPoints that have been loaded been removed by removeClearableSensorData
    const trainGroupsToMerge = updateSensorDataOfCrudTrainGroupsPreMerge(
      crudTrainGroups,
      loadedSensorDataTrainGroups!,
    );

    // Update those that have changed. Don't merge with mergeTrainGroup since we selectively merged
    // with sensorDataDateIntervals

    crudTrainGroups.updateOrCreateAllNoMerge(trainGroupsToMerge);
  }, [loadedSensorDataTrainGroups, crudTrainGroups]);
};

/**
 * TODO remove
 * If trainRun.retries > trainRun.retryIndex,
 * clear distanceRanges that errored for the given distanceResolution or clear dateIntervals for the given dateInterval
 * from trainGroupSensorDataDistanceIntervalAndRangeSet so we can request. Errors are marked with the distanceResolution
 * object's distanceRangeErrors property , which is undefineded in the return value
 * @returns {*}
 * @param trainGroup
 * @param trainGroupSensorDataToLoadTrainRouteProps
 */
// const resetTrainGroupSensorDataDistanceIntervalAndRangeSetsThatErrored = (
//   trainGroup: SensorDataTrainGroup,
//   trainGroupSensorDataToLoadTrainRouteProps: TrainGroupSensorDataToLoadTrainRouteProps[],
// ): TrainGroupSensorDataToLoadTrainRouteProps[] => {
//   // If there is no error downloading this trainRun or no request to retry, return
//   if (!trainGroup.error || !trainGroup.loadingStatus?.retry) {
//     return trainGroupSensorDataToLoadTrainRouteProps;
//   }
//
//   const trainRunIndex = findIndex(
//     (trainGroupDistanceIntervalAndRangeSet: SensorDataTrainGroup) => {
//       return equals(trainGroup.id, trainGroupDistanceIntervalAndRangeSet.id);
//     },
//     trainGroupSensorDataToLoadTrainRouteProps,
//   );
//
//   // Look for distanceRangeErrors and clear them to force a retry
//   return overClassOrType(
//     lensPath([trainRunIndex, 'distanceResolutionWithRangeSets']),
//     (distanceResolutionWithRangeSets: SensorDataDistanceIntervalWithRangeSet[]) => {
//       return map(
//         (distanceResolutionWithRangeSet: SensorDataDistanceIntervalWithRangeSet) => {
//           const distanceRangeHashes = keys(
//             distanceResolutionWithRangeSet.distanceRangeErrors,
//           );
//           // Remove distanceRanges marked as errored in distanceRangeErrors and clear distanceRangeErrors
//           return mergeRightIfDefined(distanceResolutionWithRangeSet, {
//             distanceRanges: compact(
//               map((distanceRange: DistanceRange) => {
//                 // If we have an exact match, remove this distanceRange by returning undefined
//                 if (includes(hashDistanceRange(distanceRange), distanceRangeHashes)) {
//                   return undefined;
//                 } else if (length(distanceResolutionWithRangeSet.distanceRangeErrors)) {
//                   // If any distanceResolutionObj.distanceRangeErrors that overlap distanceRange, take the
//                   // non-intersecting ranges of distanceRange
//                   return removeSubsetRanges(
//                     distanceRange,
//                     distanceResolutionWithRangeSet.distanceRangeErrors,
//                   );
//                 }
//                 return {
//                   distanceRange,
//                 };
//               }, distanceResolutionWithRangeSet.distanceRanges || []),
//             ),
//             distanceRangeErrors: undefined,
//           });
//         },
//         distanceResolutionWithRangeSets,
//       );
//     },
//     trainGroupSensorDataToLoadTrainRouteProps,
//   );
// };

/**
 * TODO no longer used, remove
 * Updates loadingSensorDataTrainGroups to account for new props that have been requested
 * @param loadingSensorDataTrainGroups
 * @param activeTrainGroups
 * @param trainGroupSensorDataToLoadTrainRouteProps Defined if the TrainGroups are TrainGroupSingleTrainRuns and not
 * TrainGroupOnlyTrainFormations
 */
// export const calculateTrainGroupTrainRouteSensorDataToLoad = (
//   loadingSensorDataTrainGroups: SensorDataTrainGroup[],
//   activeTrainGroups: TrainGroup[],
//   trainGroupSensorDataToLoadTrainRouteProps?: TrainGroupSensorDataToLoadTrainRouteProps,
// ): SensorDataTrainGroup[] => {
//   // Load the sensorDataPoints for activeTrainGroups that lack them and aren't already loading
//   return compact(
//     map((trainGroup: TrainGroup) => {
//       const trainGroupId = trainGroup.id as string;
//
//       // Clear errored distanceRange or dateInterval requests to retry to the requests if the use has opted to retry
//       // and thus increased trainRun.retries beyond trainRun.retryIndex
//       const maybeUpdatedTrainRunIdsRequestedWithSensorDataPoints =
//         resetTrainGroupSensorDataDistanceIntervalAndRangeSetsThatErrored(
//           trainGroup,
//           trainGroupSensorDataToLoadTrainRouteProps,
//         );
//
//       // If the TrainRun has a loading error, and the user isn't retrying, return nul to omit this
//       // from trainRunDataToLoading
//       if (propOr(false, 'error', trainGroup) && !propOr(false, 'retry', trainGroup)) {
//         return undefined;
//       }
//
//       // Convert from the distanceRange of the TrainRouteGroup to the actual TrainRoute of the TrainRun
//       // if the user is viewing a TrainRouteGroup and not an individual TrainRoute
//       const convertedDistanceRange = convertDistanceRangeFromTrainRouteGroupToTrainRoute(
//         {
//           aggregateTrainRouteOrGroup:
//             trainGroupSensorDataToLoadTrainRouteProps.trainRouteOrGroup,
//           trainRoute: trainGroup.singleTrainRunTrainRouteLoaded,
//         },
//         trainGroupSensorDataToLoadTrainRouteProps.trainRouteOrGroup.trainDistanceInterval
//           .distanceRange,
//       );
//
//       const trainRouteOrGroup = mergeTrainRouteOrGroup(
//         trainGroupSensorDataToLoadTrainRouteProps.trainRouteOrGroup,
//         {
//           trainDistanceInterval: mergeTrainDistanceInterval(
//             trainGroupSensorDataToLoadTrainRouteProps.trainRouteOrGroup
//               .trainDistanceInterval,
//             {distanceRange: convertedDistanceRange},
//           ),
//         },
//       );
//       const trainGroupWithMaybeTrainDistanceIntervalLimited = mergeTrainGroup(
//         trainGroup,
//         {trainRouteOrGroup},
//       );
//
//       // Find out what distanceRanges we need to request for the distanceResolution
//       // by comparing what we have with what is requested
//       const {distanceResolution, distanceRanges, consolidatedDistanceRanges} =
//         userTrainRunDistanceRangeAndIntervalDifference(
//           propOr({}, trainGroupId, maybeUpdatedTrainRunIdsRequestedWithSensorDataPoints),
//           trainGroupWithMaybeTrainDistanceIntervalLimited,
//         );
//       // Query only if we have resolved at least one distanceRanges that we need to download
//       return length(distanceRanges)
//         ? ({
//             id: trainGroupId,
//             distanceResolutionWithRangeSets: [
//               distanceResolution,
//               distanceRanges,
//               consolidatedDistanceRanges,
//             ] as SensorDataDistanceIntervalWithRangeSet[],
//           } as SensorDataTrainGroup)
//         : // Nothing needs to be downloaded for this TrainRun
//           undefined;
//     }, activeTrainGroups),
//   ) as SensorDataTrainGroup[];
// };

/**
 * Returns filtered activeTrainGroups with each TrainGroup's
 * dateBasedDataStatus updated to include newly requested dateIntervals.
 * If there are new dateIntervals that the TrainGroup hasn't previously loaded,
 * then dateBasedDataStatus.complete is set to false
 * @param loadingSensorDataTrainGroups
 * @param activeTrainGroups
 * @param dateIntervals
 * @returns The TrainR
 */
export const calculateTrainGroupDateIntervalSensorDataToLoad = (
  loadingSensorDataTrainGroups: SensorDataTrainGroup[],
  activeTrainGroups: TrainGroup[],
): SensorDataTrainGroup[] => {
  return compact(
    map((activeTrainGroup) => {
      // See if we have an existing loading instance for this activeTrainGroup
      // Otherwise create one based on the activeTrainGroup
      const loadingSensorDataTrainGroup: SensorDataTrainGroup =
        find((loadingSensorDataTrainGroup: SensorDataTrainGroup) => {
          return idsEqual(loadingSensorDataTrainGroup, activeTrainGroup);
        }, loadingSensorDataTrainGroups) ||
        clsOrType<SensorDataTrainGroup>(CemitTypename.sensorDataTrainGroup, {
          trainGroup: activeTrainGroup,
          loadingStatus: {complete: true, loading: false},
          sensorDataDateIntervals: [],
        });

      // Find which of dateIntervals are missing in loadingSensorDataTrainGroup
      const missingDateIntervals = filter(
        (dateInterval: DateInterval) => {
          return none((otherDateInterval: DateInterval) => {
            return dateIntervalIsWithinOtherDateInterval(otherDateInterval, dateInterval);
          }, loadingSensorDataTrainGroup.sensorDataDateIntervals || []);
        },
        [activeTrainGroup.activeDateInterval],
      );
      if (!length(missingDateIntervals)) {
        // Nothing is missing
        return undefined;
      }

      // Assign only the fragments to sensorDataDateIntervals so we know what needs loading.
      const missingDateIntervalFragments: DateInterval[] = chain(
        (missingDateInterval: DateInterval) => {
          return dateIntervalDifferences(
            missingDateInterval,
            loadingSensorDataTrainGroup.sensorDataDateIntervals,
          );
        },
        missingDateIntervals,
      );

      // Return a SensorDataTrainGroup with just the missing DateIntervals
      return clsOrType<SensorDataTrainGroup>(CemitTypename.sensorDataTrainGroup, {
        ...loadingSensorDataTrainGroup,
        sensorDataDateIntervals: missingDateIntervalFragments,
        loadingStatus: mergeLoadingStatus(loadingSensorDataTrainGroup.loadingStatus, {
          complete: false,
        }),
      });
    }, activeTrainGroups),
  );
};

/***
 * Calculates SensorDataTrainGroup based on what distanceResolution distanceRanges need to load for
 * each TrainGroup. The SensorDataTrainGroups are later examined to find out what ranges are missing
 * and need to load, if any
 * @param sensorDataTrainGroups
 * @param trainRouteGroupProps
 */
export const trainGroupRouteBasedSensorDataToLoad = (
  sensorDataTrainGroups: SensorDataTrainGroup[],
  trainRouteGroupProps: TrainGroupSensorDataToLoadTrainRouteProps,
): SensorDataTrainGroup[] => {
  const mergedTrainGroupSensorDataDistanceIntervalAndRangesStatuses =
    clsOrTypes<SensorDataTrainGroup>(
      CemitTypename.distanceResolutionAndRangeSensorDataTrainGroup,
      values(
        mergeWith(
          (
            existingForTrainRun: SensorDataDistanceIntervalWithRangeSet,
            newForTrainRun: SensorDataDistanceIntervalWithRangeSet,
          ) => {
            // For matching trainGroupIds combine the newly requested distancesRanges with the old for each distanceResolution
            const {id, distanceResolution, consolidatedDistanceRanges} = newForTrainRun;
            return [
              // Remove the matching distanceResolution from existing
              // @ts-ignore
              ...filter(
                complement(propEq(distanceResolution, 'distanceResolution')),
                existingForTrainRun,
              ),
              // Use consolidatedDistanceRanges, the combined values data already loaded and the new data loaded
              // for this distanceResolution
              {
                id,
                distanceResolution: distanceResolution,
                distanceRanges: consolidatedDistanceRanges,
              },
            ];
          },
          indexBy(prop('id'), trainRouteGroupProps.sensorDataTrainGroups),
          indexBy(prop('id'), sensorDataTrainGroups),
        ),
      ),
    );

  // Force new trainGroupId entries that didn't merge with existing to be arrays
  return map((trainRunDataToLoad) => {
    return toArrayIfNot(
      unless(Array.isArray, omit(['consolidatedDistanceRanges']), trainRunDataToLoad),
    );
  }, mergedTrainGroupSensorDataDistanceIntervalAndRangesStatuses);
};
/**
 * Of loadingSensorDataTrainGroups, returns those that are net yet loading and need to load,
 * creating a subclass instance either SensorDataTrainGroups that are limited
 * to the DistanceIntervals (e.g. 100m, 50m, etc) and DistanceRanges (e.g. for 20km mark to 100km mark of the route)
 * or SensorDataTrainGroups that are limited to the DateIntervals that need to load
 * @param loading
 * @param organizationProps
 * @param trainProps
 * @param activeTrainGroups
 * @param loadingSensorDataTrainGroups
 */
export const useMemoCalculateTrainGroupSensorDataToLoad = (
  loading: boolean,
  organizationProps: OrganizationProps,
  trainProps: TrainProps,
  activeTrainGroups: TrainGroup[],
  loadingSensorDataTrainGroups: SensorDataTrainGroup[],
): SensorDataTrainGroup[] => {
  return useNotLoadingMemo(
    loading || !activeTrainGroups,
    (activeTrainGroups, loadingSensorDataTrainGroups) => {
      // Merges loadingSensorDataTrainGroups with activeTrainGroups that have new dateIntervals
      // This preserves the loading/error/complete statuses loadingSensorDataTrainGroups of
      // loadingSensorDataTrainGroups while also allowing us to add dateIntervals that have been requested
      const eligibleActiveTrainGroups = filter((trainGroup: TrainGroup) => {
        return !trainGroup.trainFormation!.disableSensorData;
      }, activeTrainGroups);
      const sensorDataTrainGroups: SensorDataTrainGroup[] =
        calculateTrainGroupDateIntervalSensorDataToLoad(
          loadingSensorDataTrainGroups,
          eligibleActiveTrainGroups,
        );

      if (length(sensorDataTrainGroups)) {
        forEach((sensorDataTrainGroup: SensorDataTrainGroup) => {
          console.debug(
            `For ${sensorDataTrainGroup.trainGroup.localizedName(organizationProps.organization.timezoneStr)}, maybe need SensorData for: ${dumpDateIntervals(
              sensorDataTrainGroups[0].sensorDataDateIntervals,
              organizationProps.organization.timezoneStr,
            )}`,
          );
        }, sensorDataTrainGroups);
      }

      return sensorDataTrainGroups;
    },
    [activeTrainGroups, loadingSensorDataTrainGroups] as const,
  );
};
