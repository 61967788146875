import {always, ifElse, join} from 'ramda';
import {compact} from '@rescapes/ramda';
import {endOfDay, startOfDay, subMilliseconds} from 'date-fns';
import {format, formatInTimeZone, getTimezoneOffset, utcToZonedTime} from 'date-fns-tz';
import {Perhaps} from 'types/typeHelpers/perhaps';

export const durationText = (duration) => {
  const hourLabel = ifElse(
    (hours) => hours > 0,
    (hours) => `${hours} ${hours > 1 ? 'Hours' : 'Hour'}`,
    always(undefined),
  )(Math.floor(duration / 60));
  const minuteLabel = ifElse(
    (minutes) => minutes > 0,
    (minutes) => `${minutes} Minutes`,
    always(undefined),
  )(Math.floor(duration % 60));
  return join(', ', compact([hourLabel, minuteLabel]));
};

export const trainDataFriendlyDateFormatString = 'ccc LLL d, yyyy';
export const trainDataFriendlyDatetimeFormatString = `${trainDataFriendlyDateFormatString} HH:mm`;
export const trainDataFriendlyDatetimeWithSecondsFormatString = `${trainDataFriendlyDateFormatString} HH:mm:ss`;
export const trainDataFriendlyTimeFormatString = 'HH:mm';
export const trainDataFriendlyTimeFormatStringWithTimeZone = 'HH:mm z';
export const trainDataSimpleDateString = 'yyyy-MM-dd';
export const trainDataSimpleDateTimeString = 'yyyy-MM-dd HH:mm';
export const trainDataSimpleDateTimeStringWithTimeZone = 'yyyy-MM-dd HH:mm z';
export const trainDataTimeZone = 'z';
export const trainDataFriendlyDatetimeFormatWithTimezoneString = `${trainDataFriendlyDateFormatString} HH:mm XXXXX`;

/**
 * The local timezone string
 */
export const getLocalTimeZoneStr = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatInTimeZoneUnlessLocal = (
  localTimezoneMatchesOrganization: boolean,
  date: Date,
  timezoneStr: string,
  localFormatStr: string,
  remoteFormatStr: string,
) => {
  return localTimezoneMatchesOrganization
    ? formatInTimeZone(date, timezoneStr, localFormatStr)
    : formatInTimeZone(date, timezoneStr, remoteFormatStr);
};

export const millisecondsBetweenLocalAndTimezone = (timezoneStr: string, date: Date) => {
  // This returns the amount of milliseconds the operator's timezone is ahead of GMT
  const millisecondsOffsetOfOperator = getTimezoneOffset(timezoneStr, date);
  // This returns how many minute GMT is behind the local timzezone in minutes. Negate and convert to milliseconds
  const millisecondsOffsetLocal = -date.getTimezoneOffset() * 60000;
  // The offset from the local timezone to that of the operator
  const adjustedMilliseconds = millisecondsOffsetOfOperator - millisecondsOffsetLocal;
  return adjustedMilliseconds;
};

/**
 * Start of day of date for the given timezone.
 * If the given timezone is ahead of UTC, it will subtract its offset from startOfDay(date), where
 * startOfDays is in the local timezone so we must account for the difference between the local timezone
 * and the given timezoneStr
 * @param timezoneStr
 * @param date
 */
export const startOfDayForTimezone = (timezoneStr: string, date: Date): Date => {
  const start = startOfDay(date);
  const milliseconds = millisecondsBetweenLocalAndTimezone(timezoneStr, date);
  return subMilliseconds(start, milliseconds);
};

/**
 * End of day of date for the given timezone.
 * If the given timezone is ahead of UTC, it will subtract its offset from startOfDay(date), where
 * startOfDays is in the local timezone so we must account for the difference between the local timezone
 * and the given timezoneStr
 * @param timezoneStr
 * @param date
 */
export const endOfDayForTimezone = (timezoneStr: string, date: Date): Date => {
  const end = endOfDay(date);
  const milliseconds = millisecondsBetweenLocalAndTimezone(timezoneStr, date);
  return subMilliseconds(end, milliseconds);
};

/**
 * The date in the given timezone if a timezoneStr is defined
 * @param date
 * @param timezoneStr
 */
export const formattedDateTimeMaybeTimezone = (
  date: Date,
  timezoneStr: Perhaps<string>,
) => {
  return timezoneStr
    ? formatInTimeZone(date, timezoneStr, trainDataSimpleDateTimeStringWithTimeZone)
    : format(date, trainDataFriendlyDatetimeFormatString);
};
