import {filter, indexBy, length, prop} from 'ramda';
import {ReactElement} from 'react';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainGroupGeojsonProps} from 'types/propTypes/trainPropTypes/trainGroupGeojsonProps';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';

import {TrainGroup} from 'types/trainGroups/trainGroup';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {areSensorDataDateIntervalsAreUpToDate} from 'appUtils/trainAppUtils/trainGroupSensorDataUtils/trainGroupSensorDataUtils.ts';
import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * TODO this is currently mostly commented out because RideComfort data is producing geojson for the map,
 * SensorData is not
 * Keeps track of TrainGroup geojson
 * Depends directly on TrainGroups' sensor props, since the geojson data includes
 * data from the sensors in its properties
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @returns {*}
 * @constructor
 */
const TrainGroupGeojsonDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement => {
  const haveServiceLines = doesOrganizationHaveServiceLines(organizationProps);

  // Take the actives TrainGroups that don't have download errors
  const activeTrainGroupsWithoutErrors: Perhaps<TrainGroup[]> =
    trainProps.trainGroupActivityProps?.activeTrainGroupsWithoutErrors;

  // Call areSensorDataDateIntervalsAreUpToDate
  const trainRunGeojsonLoaded = useNotLoadingMemo(
    loading,
    (activeTrainGroupsWithoutErrors, loadedSensorDataTrainGroups): boolean => {
      const activeTrainGroupsConfiguredToDownloadSensorData = filter(
        (activeTrainGroupsWithoutError: TrainGroup) => {
          return !activeTrainGroupsWithoutError.trainFormation!.disableSensorData;
        },
        activeTrainGroupsWithoutErrors,
      );
      const trainGroupLookup = indexBy(
        prop('id'),
        activeTrainGroupsConfiguredToDownloadSensorData,
      );
      const activeUpToDateLoadedSensorDataTrainGroups: SensorDataTrainGroup[] = filter(
        (loadedSensorDataTrainGroup: SensorDataTrainGroup): boolean => {
          const activeCrudTrainGroup: Perhaps<TrainGroup> = prop(
            loadedSensorDataTrainGroup.id,
            trainGroupLookup,
          );
          return (
            activeCrudTrainGroup &&
            areSensorDataDateIntervalsAreUpToDate(
              loadedSensorDataTrainGroup,
              activeCrudTrainGroup,
            )
          );
        },
        loadedSensorDataTrainGroups,
      );

      return (
        length(activeTrainGroupsConfiguredToDownloadSensorData) ==
        length(activeUpToDateLoadedSensorDataTrainGroups)
      );
    },
    [
      activeTrainGroupsWithoutErrors,
      trainProps.trainGroupSingleTrainRunProps.sensorProps.loadedSensorDataTrainGroups,
    ],
  );

  // Transform's the geojson to the correct position parallel to the track if the activeTrainGroupsWithoutErrors
  // have TrainRuns
  // useUpdateTrainGroupTransformedGeojsons(
  //   loading ||
  //     !haveServiceLines ||
  //     !doActiveTrainGroupsHaveTrainRuns(activeTrainGroupsWithoutErrors) ||
  //     !length(activeTrainGroupsWithoutErrors) ||
  //     !trainRunGeojsonLoaded,
  //
  //   trainProps.trainRouteGroupProps.trainRouteOrGroup,
  //   activeTrainGroupsWithoutErrors,
  //   trainProps.trainGroupSingleTrainRunProps.crudTrainGroups,
  //   loadedSensorDataTrainGroupsOfActiveTrainGroups,
  //   dateInterval,
  // );

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainGroupGeojsonDependency.name),
    TrainGroupGeojsonDependency.name,
    {
      trainRunGeojsonLoaded,
      // areLoadedSensorDataTrainGroupsOfActiveTrainGroupsSynced,
    },
    [
      loading,
      trainRunGeojsonLoaded,
      // areLoadedSensorDataTrainGroupsOfActiveTrainGroupsSynced,
    ],
    appProps.setWhatDependenciesAreLoading,
  );

  const geojsonProps = useMemoClsOrType<Omit<TrainGroupGeojsonProps, 'loading'>>(
    CemitTypename.trainGroupGeojsonProps,
    {
      whatIsLoading,
    },
  );
  const trainPropsMerged: TrainProps = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainGroupSingleTrainRunProps.geojsonProps',
    geojsonProps,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    geojsonProps,
    'TrainGroupGeojsonDependency',
  );

  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};

TrainGroupGeojsonDependency.displayName = 'TrainGroupGeojsonDependency';
export default TrainGroupGeojsonDependency;

//export default memo(
// TrainGroupGeojsonDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
