import {CrudList} from 'types/crud/crudList';
import {RealtimeTrainGroupProps} from 'types/realtimeTrain/realtimeTrainGroupProps.ts';
import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {
  compose,
  descend,
  equals,
  filter,
  forEach,
  head,
  includes,
  lensProp,
  map,
  pick,
  sortWith,
} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {RealtimeTrainScopeProps} from 'types/realtimeTrain/realtimeTrainScopeProps';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {RealtimeTrainConfigProps} from 'types/realtimeTrain/realtimeTrainConfigProps.ts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {RealtimeTrainSignal} from 'types/realtimeTrain/signalData';
import {collectionDevicesOfTrainFormation} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainFormationUtils.ts';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';

/**
 * Creates RealtimeTrainScopeProps for each activeTrainGroup
 * which can be a TrainGroup or TrainGroupOnlyTrainFormation depending on whether a TrainRun or only
 * a TrainFormation has been selected by the user
 * @param loading
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param realtimeTrainGroupInitialProps
 * @param signals
 * @param activeTrainGroups
 */
export const useNotLoadingMemoCreateTrainGroupRealtimeTrainScopePropsSets = (
  loading: boolean,
  appProps: TrainAppProps,
  organizationProps: OrganizationProps,
  trainProps: TrainProps,
  realtimeTrainGroupInitialProps: RealtimeTrainGroupProps,
  signals: Perhaps<RealtimeTrainSignal[]>,
  activeTrainGroups: Perhaps<TrainGroup[]>,
): RealtimeTrainScopeProps[] => {
  const realtimeTrainConfigProps = trainProps.realtimeTrainConfigProps;
  return useNotLoadingMemo(
    loading || !organizationProps.organization.realtimeSpaceId || !signals,
    (
      realtimeTrainConfigProps,
      realtimeTrainGroupInitialProps,
      activeTrainGroups,
      signals,
    ) => {
      const realtimeTrainScopePropSets = map((activeTrainGroup: TrainGroup) => {
        const trainFormationCollectionDevices: TrainFormationCollectionDevice[] =
          collectionDevicesOfTrainFormation(activeTrainGroup.trainFormation!);
        const collectionDeviceNames: string[] = map(
          (trainFormationColletionDevice: TrainFormationCollectionDevice) => {
            return `cdc${trainFormationColletionDevice.collectionDevice.name}`;
          },
          trainFormationCollectionDevices,
        );

        // Get the latest Signal for the TrainGroup by matching the incoming signal data to collection device sourceKey
        const latestSignalForTrainGroup = compose(
          (signals: RealtimeTrainSignal[]) => head(signals),
          (signals: RealtimeTrainSignal[]) => {
            return sortWith(
              [
                descend((signal: RealtimeTrainSignal) => {
                  return signal.signalAdded.timestamp;
                }),
              ],
              signals,
            );
          },
          (signals: RealtimeTrainSignal[]): RealtimeTrainSignal[] => {
            return filter((signal: RealtimeTrainSignal): boolean => {
              return includes(signal.signalAdded.point.name, collectionDeviceNames);
            }, signals);
          },
        )(signals);

        const realtimeTrainGroupProps = compose(
          (realtimeTrainGroupInitialProps: RealtimeTrainGroupProps) => {
            // Set complete as soon as we get a signal
            // TODO we should expire the signal if it gets to stale and set this back to needsToLoad
            return latestSignalForTrainGroup
              ? setClassOrType<RealtimeTrainGroupProps>(
                  lensProp('realtimeTrainGraphqlStatus'),
                  LoadingStatusEnum.complete,
                  realtimeTrainGroupInitialProps,
                )
              : realtimeTrainGroupInitialProps;
          },
          (realtimeTrainGroupInitialProps: RealtimeTrainGroupProps) => {
            return setClassOrType<RealtimeTrainGroupProps>(
              lensProp('signal'),
              latestSignalForTrainGroup,
              realtimeTrainGroupInitialProps,
            );
          },
        )(realtimeTrainGroupInitialProps);
        const realtimeTrainScopeProps = clsOrType<RealtimeTrainScopeProps>(
          CemitTypename.realtimeTrainScopeProps,
          {
            // scopedTrainGroup.realtimeTrainScopeProps.realtimeTrainGroupProps represents our existing values
            scopedTrainGroup: activeTrainGroup,
            // The current non-TrainGroup-specific configuration
            realtimeTrainConfigProps,
            realtimeTrainGroupProps,
          },
        );

        return realtimeTrainScopeProps;
      }, activeTrainGroups);
      return realtimeTrainScopePropSets;
    },
    [
      realtimeTrainConfigProps,
      realtimeTrainGroupInitialProps,
      activeTrainGroups,
      signals,
    ] as const,
  );
};

/**
 * For each realtimeTrainScopeProps in realtimeTrainScopePropSets, downloads RealtimeTrain data
 * if realtimeTrainScopeProps.realtimeTrainGroupProps is not up-to-date with realtimeTrainScopeProps.realtimeTrainConfigProps,
 * meaning the latter has been changed by user action and the former has stale or no RealtimeTrain data
 * @param loading
 * @param realtimeTrainScopePropSets
 * @param realtimeTrainConfigProps
 * @param trainGroupCrudList
 */
export const useNotLoadingEffectSetRealtimeSignals = (
  loading: boolean,
  realtimeTrainScopePropSets: Perhaps<RealtimeTrainScopeProps[]>,
  realtimeTrainConfigProps: RealtimeTrainConfigProps,
  trainGroupCrudList: CrudList<TrainGroup>,
) => {
  const {realtimeTrainTimePeriodForMap, realtimeTrainAlertType} =
    realtimeTrainConfigProps;
  const queryRealtimeTrainApiAndMutateDependencies = [
    realtimeTrainScopePropSets,
    realtimeTrainTimePeriodForMap,
    realtimeTrainAlertType,
  ] as const;

  // Copy new Signal data in each realtimeTrainScopeProps to its scopedTrainGroup and save
  // the changes with trainGroupCrudList
  // Phase 2, LoadingStatusEnum.needsToLoad => LoadingStatusEnum.loading
  useNotLoadingEffect(
    loading,
    (realtimeTrainScopePropSets) => {
      forEach((realtimeTrainScopeProps: RealtimeTrainScopeProps) => {
        const activeTrainGroup = realtimeTrainScopeProps.scopedTrainGroup;
        const realtimeTrainGroupProps = realtimeTrainScopeProps.realtimeTrainGroupProps;

        // Did the signal update for this TrainGroup
        const didChange: boolean = !equals(
          activeTrainGroup.realtimeTrainScopeProps?.realtimeTrainGroupProps.signal,
          realtimeTrainGroupProps?.signal,
        );
        if (!didChange) {
          return;
        }
        // Simple copy realtimeTrainScopeProps.realtimeTrainGroupProps to activeTrainGroups.realtimeTrainScopeProps
        // whenever it changes
        const updatedActiveTrainGroup: TrainGroup = setClassOrType(
          lensProp('realtimeTrainScopeProps'),
          realtimeTrainScopeProps,
          activeTrainGroup,
        );
        const omitted = pick(['id', 'realtimeTrainScopeProps'], updatedActiveTrainGroup);
        // No merging needed since we are only overwriting realtimeTrainScopeProps
        trainGroupCrudList.updateOrCreateAllNoMerge([omitted]);
      }, realtimeTrainScopePropSets);
    },
    [realtimeTrainScopePropSets, realtimeTrainAlertType] as const,
  );
};

/***
 * Converts the given activeTrainGroups to TrainGroups based on
 * changes to 'id', 'activeDateInterval', 'realtimeTrainScopeProps', and 'activity'
 * activity is included so we catch changes to the TrainGroup.activity.(isActiveColor and isVisible)
 * @param loading
 * @param activeTrainGroups
 */
export const useNotLoadingTrainGroupsForRealtimeTrain = (
  loading: boolean,
  activeTrainGroups: Perhaps<TrainGroup[]>,
): Perhaps<TrainGroup[]> => {
  const activeTrainGroupToTrainGroup = (activeTrainGroup: TrainGroup): TrainGroup => {
    return pick(
      ['id', 'activeDateInterval', 'realtimeTrainScopeProps', 'activity'],
      activeTrainGroup,
    ) as TrainGroup;
  };
  // Create a minimized version of activeTrainGroups for RealtimeTrain so that we only detect changes
  // to activeTrainGroups that are relevant to RealtimeTrain
  // I can't think of any other way to detected limited changes other than jsonifying
  const hashedActiveTrainGroupForRealtimeTrains = useNotLoadingMemo(
    loading,
    (activeTrainGroups) => {
      return JSON.stringify(
        map<TrainGroup, Partial<TrainGroup>>(
          activeTrainGroupToTrainGroup,
          activeTrainGroups,
        ),
      );
    },
    [activeTrainGroups] as const,
  );
  // Only call if something relevant changed
  const trainGroups: Perhaps<TrainGroup[]> = useNotLoadingMemo(
    loading,
    () => {
      return activeTrainGroups;
    },
    [hashedActiveTrainGroupForRealtimeTrains] as const,
  );
  return trainGroups;
};
