import {length, map} from 'ramda';
import {correctPayload} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {
  ChartPayloadItem,
  ChartPayloadItemDerived,
} from 'types/dataVisualizations/chartPayloadItem';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {CemitTypename} from 'types/cemitTypename.ts';
import {DataFeatureCollection} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';

import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {memo, ReactNode, useEffect} from 'react';
import {compact} from 'utils/functional/functionalUtils.ts';

export interface SensorDataPointsCustomTooltipProps extends CemitComponentProps {
  // [{Object}] payload Is the data passed from recharts' Tooltip component
  // We iterate through the payload and show labels for each item. Normally
  // there is only zero or one object in the payload
  payloadHash: string;
  chartPayloadItems: ChartPayloadItem[];
  nameToDataFeatureCollectionLookup: Record<string, DataFeatureCollection>;
  mostRecentTooltipPayload: ChartPayloadItem[];
  setMostRecentTooltipPayload: StateSetter<ChartPayloadItem[]>;
  activeChartDataKey: string;
  xValueOfHoveredItem: number;
  trainGroups: TrainGroup[];
}

/**
 * Creates a Recharts CustomTooltip but React.portals it to component referenced by chartAndMapDataContainerRef
 * so that we show the tooltip in a component instead of as a popup that blocks the view of the graphs
 * The hook is called when payloadItems or trainGroups changes. We could download more sensorDataPoints
 * and change trainGroups without changing the payload, so we must check trainGroups
 * of the comparisonLabelPair, where t is the translator
 * @return Always returns undefined
 */
const SensorDataPointsCustomTooltip = memo(
  ({
    appProps,
    trainProps,
    componentProps,
  }: {
    appProps: TrainAppProps;
    trainProps: TrainProps;
    componentProps: SensorDataPointsCustomTooltipProps;
  }): ReactNode => {
    useEffect(() => {
      setMostRecentTooltipPayloadIfNeeded({appProps, componentProps});
    }, [componentProps.payloadHash]);
    return undefined;
  },
  (
    prevProps: {
      appProps: TrainAppProps;
      trainProps: TrainProps;
      componentProps: SensorDataPointsCustomTooltipProps;
    },
    nextProps: {
      appProps: TrainAppProps;
      trainProps: TrainProps;
      componentProps: SensorDataPointsCustomTooltipProps;
    },
  ) => {
    return prevProps.componentProps.payloadHash == nextProps.componentProps.payloadHash;
  },
);

export const setMostRecentTooltipPayloadIfNeeded = ({
  appProps,
  componentProps,
}: {
  appProps: TrainAppProps;
  componentProps: SensorDataPointsCustomTooltipProps;
}) => {
  const {
    chartPayloadItems,
    nameToDataFeatureCollectionLookup,
    setMostRecentTooltipPayload,
    xValueOfHoveredItem,
    trainGroups,
  } = componentProps;

  if (
    length(chartPayloadItems)
    // Ignore if this payload is not from the active chart
    // TODO This needs to be configured in the chart config
    //equals(headOrThrow(chartPayloadItems).dataKey, activeChartDataKey)
  ) {
    // Set the xValueOfHoveredItem value on each payloadItem so we can later determine which payloadItem
    // is the active line on the graph
    const payloadWithXValueOfHoveredItemItems: Perhaps<
      ChartPayloadItem | ChartPayloadItemDerived
    >[] = compact(
      map((chartPayloadItem: ChartPayloadItem) => {
        // Get the corresponding dataFeatureCollection using the name lookup
        const dataFeatureCollection: DataFeatureCollection =
          nameToDataFeatureCollectionLookup?.[chartPayloadItem.name];
        if (!dataFeatureCollection) {
          // TODO this is currently expected when we are in a loading state
          // console.warn(`No DataFeatureCollection matched ${chartPayloadItem.name}`);
          return undefined;
        }
        return clsOrType<ChartPayloadItemDerived>(CemitTypename.chartPayloadItemDerived, {
          ...chartPayloadItem,
          // This can be undefined
          xValueOfHoveredItem,
          // This cannot be undefined
          dataFeatureCollection,
        });
      }, chartPayloadItems),
    );

    // Give up if an error occurred
    if (!payloadWithXValueOfHoveredItemItems) {
      return;
    }

    // TODO this is redundant with the correctPayload in setMostRecentPayloadMergingPrevious but is
    // needed to set the trainGroups
    const correctedPayloadItems: ChartPayloadItem[] = correctPayload(
      appProps,
      trainGroups,
      payloadWithXValueOfHoveredItemItems,
    );
    // TODO this used to be setMostRecentPayloadMergingPrevious See diff
    // I removed it to save time
    setMostRecentTooltipPayload(correctedPayloadItems);
  }
};

export default SensorDataPointsCustomTooltip;
