import React, {useEffect} from 'react';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {useTranslation} from 'react-i18next';
import {isNil, unless} from 'ramda';
import CemitAppPropsDependency, {
  ChildrenDependencyProps,
} from 'async/cemitAppAsync/CemitAppPropsDependency.tsx';

interface TitleWIthCemitAppDependencyProps extends ChildrenDependencyProps {
  appTitleKey?: string;
}

/**
 * Wraps all Cemit Applications entry points by setting up a valid title and providing translation
 * and setWhatDependenciesAreLoading in appProps: CemitAppProps to the children
 * Some apps, like Train, have a more complex Dependency, like TrainAppDependency, that they use instead of this
 *
 * @param appTitle Title of the application, e.g. "Train"
 */
const CemitAppDependency = ({
  appTitleKey,
  renderChildren,
  children,
}: TitleWIthCemitAppDependencyProps) => {
  const {t} = useTranslation();

  // Use the appEnv as the subtitle if in non-production mode
  const subTitle = AppSettings.appEnv !== 'production' ? ` (${AppSettings.appEnv})` : '';

  // Translate the appTitleKey if supplied
  const appTitle = unless(isNil, t)(appTitleKey);
  useEffect(() => {
    document.title = appTitle
      ? `${appTitle} | Cemit Visualizer${subTitle}`
      : `Cemit Visualizer${subTitle}`;
  }, [appTitle]);

  return (
    <CemitAppPropsDependency {...{renderChildren}}>{children}</CemitAppPropsDependency>
  );
};

export default CemitAppDependency;
