import {scaleLinear} from 'd3-scale';
import {extremes} from 'utils/functional/functionalUtils.ts';
import {
  addIndex,
  always,
  any,
  compose,
  concat,
  equals,
  filter,
  findIndex,
  fromPairs,
  has,
  head,
  ifElse,
  indexBy,
  last,
  length,
  lensPath,
  lt,
  lte,
  map,
  not,
  prop,
  propOr,
  set,
  slice,
  subtract,
  times,
  uniq,
  when,
  zipWith,
} from 'ramda';
import {compact} from '@rescapes/ramda';
import {pseudoScheduledStopPointAtDistanceAlongRoute} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/timetabledPassingTimeUtils.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {unlessLoadingValue} from 'utils/componentLogic/loadingUtils.ts';
import {useMemoScheduledStopPointsOfTrainRouteOrGroup} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainRouteHooks.ts';

import {
  ScheduledStopPointAndMaybeDateTime,
  ScheduledStopPointAndMaybeDateTimeDerived,
} from '../../../types/stops/scheduledStopPointAndMaybeTime';
import {
  RouteDistanceWithOffsetLeft,
  RouteDistanceWithOffsetLeftRequired,
} from '../../../types/distances/routeDistanceWithOffsetLeft';
import {TrainRoute} from '../../../types/trainRouteGroups/trainRoute';
import {RailwayLine} from '../../../types/railways/railwayLine';
import {
  ScheduledStopPoint,
  ScheduledStopPointDerived,
} from '../../../types/stops/scheduledStopPoint';
import {StopGap} from '../../../types/stops/stopGap';
import {CrudList} from '../../../types/crud/crudList';
import {
  SingleTrainGroupTrainProps,
  TrainProps,
} from '../../../types/propTypes/trainPropTypes/trainProps';
import {Perhaps} from '../../../types/typeHelpers/perhaps';
import {TimetabledPassingDateTime} from '../../../types/timetables/timetabledPassingDateTime';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {isTrainRouteOrGroupScope} from '../scope/trainPropsScope.ts';
import {TrainDistanceInterval} from '../../../types/distances/trainInterval';
import {TrainRouteGroupDerived} from '../../../types/trainRouteGroups/trainRouteGroup';
import {DistanceRange} from '../../../types/distances/distanceRange';
import {useFilterCrudList} from '../../../utils/hooks/crudHooks.ts';
import {scheduledStopPointOfTimeTabledPassingDatetime} from 'appUtils/trainAppUtils/trainGroupUtils/trainGroupRouteBasedUtils.ts';
import {clsOrType} from '../../typeUtils/clsOrType.ts';
import {TrainGroup} from '../../../types/trainGroups/trainGroup';

/**
 * Memoized call that zips stops with their offsetLefts
 * @param loading return undefined if true
 * @param visibleScheduledStopPointsAndMaybeDateTimes
 * @param offsetLefts
 * @returns {[Object]} Objects where each has {visiblescheduledStopPointAndMaybeDateTime, routeDistance, offsetLeft} routeDistance is scheduledStopPoint.routeDistance
 */
export const useMemoZipVisibleScheduledStopPointsAndMaybeTimesWithOffsetLefts = (
  loading: boolean,
  visibleScheduledStopPointsAndMaybeDateTimes: ScheduledStopPointAndMaybeDateTime[],
  offsetLefts: number[],
): ScheduledStopPointAndMaybeDateTimeDerived[] | undefined => {
  return useNotLoadingMemo<
    ScheduledStopPointAndMaybeDateTimeDerived[]
  >(loading, (): ScheduledStopPointAndMaybeDateTimeDerived[] => {
    return zipWith<
      ScheduledStopPointAndMaybeDateTime,
      number,
      ScheduledStopPointAndMaybeDateTimeDerived
    >(
      (
        scheduledStopPointAndMaybeDateTime: ScheduledStopPointAndMaybeDateTime,
        offsetLeft: number,
      ): ScheduledStopPointAndMaybeDateTimeDerived => {
        return {
          scheduledStopPointAndMaybeDateTime,
          routeDistance: scheduledStopPointAndMaybeDateTime.routeDistance,
          offsetLeft: offsetLeft || 0,
        } as ScheduledStopPointAndMaybeDateTimeDerived;
      },
      visibleScheduledStopPointsAndMaybeDateTimes,
      offsetLefts,
    );
  }, [visibleScheduledStopPointsAndMaybeDateTimes, offsetLefts]);
};

/**
 * Resolve the offsetLeft of the component based on its distance along the station line
 * @param routeDistancesWithOffsetLefts
 */
export const resolveOffsetLeft =
  (routeDistancesWithOffsetLefts: RouteDistanceWithOffsetLeftRequired[]) =>
  (distance: number) => {
    // Don't allow negative distances, although the user can moverDrag the bar beyond the start and end of the station line.
    // The distance is also forced to be no greater than that of the last station. The latter applies when we
    // are measuring the distance of the right side of the TrainRunOrGroupInterval bar
    if (!length(routeDistancesWithOffsetLefts)) {
      throw Error('routeDistancesWithOffsetLefts cannot be empty');
    }

    const validDistance = compose(
      (distance: number) => {
        return Math.min(
          (
            last<RouteDistanceWithOffsetLeft>(
              routeDistancesWithOffsetLefts,
            ) as RouteDistanceWithOffsetLeft
          ).routeDistance,
          distance,
        );
      },
      (distance: number) => {
        return Math.max(0, distance);
      },
    )(distance);

    const maxIndex = length(routeDistancesWithOffsetLefts) - 1;
    // The distance is between this index and the one before it
    const lastIndex = compose(
      (index: number) => when(equals(0), () => 1)(index),
      (index) => when(equals(-1), () => maxIndex)(index),
    )(
      addIndex<RouteDistanceWithOffsetLeft>(
        // @ts-ignore addIndex doesn't accept this
        findIndex<RouteDistanceWithOffsetLeft>,
      )(({routeDistance}: RouteDistanceWithOffsetLeft, index: number) => {
        return (
          lt(validDistance, routeDistance) ||
          (index === maxIndex && equals(validDistance, routeDistance))
        );
      }, routeDistancesWithOffsetLefts),
    );
    const {
      routeDistance: routeDistance1,
      offsetLeft: offsetLeft1,
    }: {
      routeDistance: number;
      offsetLeft: number;
    } = routeDistancesWithOffsetLefts[lastIndex - 1];

    const {routeDistance: routeDistance2, offsetLeft: offsetLeft2} =
      routeDistancesWithOffsetLefts[lastIndex];
    const ratio = (validDistance - routeDistance1) / (routeDistance2 - routeDistance1);
    return offsetLeft1 + ratio * (offsetLeft2 - offsetLeft1);
  };

/**
 * Resolves the distance between two of routeDistancesWithOffsetLefts
 * @param routeDistancesWithOffsetLefts
 * @param trainDistanceInterval
 * @param spaceGeospatially
 */
export const resolveDistance =
  (
    routeDistancesWithOffsetLefts: RouteDistanceWithOffsetLeftRequired[],
    trainDistanceInterval: TrainDistanceInterval,
    spaceGeospatially: boolean,
  ) =>
  (offset: number): number => {
    if (spaceGeospatially) {
      // We have a percent offset, so we need to convert it to the trainDistanceInterval.distanceRange
      // or underlyingRoute distanceRange
      const distanceRange = {start: 0, end: trainDistanceInterval.distance!};
      return (offset / 100) * (distanceRange.end - distanceRange.start);
    }

    // If not spaceGeospatially, we must reolve based on the station offsets: routeDistancesWithOffsetLefts
    // The offset is between this index and the one before it. If it's beyond the last offset because the user
    // dragged off the line, then take the last offset
    // @ts-ignore Typing too complex with compose
    const {index: lastIndex, offset: offsetUpdated} = compose(
      // Set to 1 if less than the first index and update the offset to match
      ({index, offset}) =>
        when(
          ({index}) => equals(0, index),
          () => ({
            index: 1,
            // @ts-ignore Typing too complex with compose
            offset: routeDistancesWithOffsetLefts[0].offsetLeft,
          }),
          // @ts-ignore Typing too complex with compose
        )({index, offset}),

      // Set to length - 1 if too far right and update the offset to match
      ({index, offset}) =>
        when(
          ({index}) => equals(-1, index),
          () => {
            const indexAdjusted = length(routeDistancesWithOffsetLefts) - 1;
            return {
              index: indexAdjusted,
              // @ts-ignore Typing too complex with compose
              offset: routeDistancesWithOffsetLefts[indexAdjusted].offsetLeft,
            };
          },
          // @ts-ignore Typing too complex with compose
        )({index, offset}),
      (index) => ({index, offset}),
      (routeDistancesWithOffsetLefts) =>
        findIndex(({offsetLeft}) => {
          return lt(offset, offsetLeft);
        }, routeDistancesWithOffsetLefts),
    )(routeDistancesWithOffsetLefts);
    const {routeDistance: routeDistance1, offsetLeft: offsetLeft1} =
      routeDistancesWithOffsetLefts[lastIndex - 1];
    const {routeDistance: routeDistance2, offsetLeft: offsetLeft2} =
      routeDistancesWithOffsetLefts[lastIndex];
    const ratio = (offsetUpdated - offsetLeft1) / (offsetLeft2 - offsetLeft1);
    return routeDistance1 + ratio * (routeDistance2 - routeDistance1);
  };

/**
 * Given stops of a trainRun or trainRoute, returns
 * the stops within that interval plus an optional buffer of stops
 * @param config
 * @param [config.buffer] Default 0 The number of stops before
 * and after those matching the trainRouteOrGroup to add.
 * @param [config.includeIntervalEndsAsStops] Default false. If true, include the ends of the
 * trainRouteOrGroup as pseudo-stops if not matching a real timetabledPassingTime
 * @param scheduledStopPoints
 * @param trainRouteOrGroupMaybeLimited
 * @returns {*}
 */
export const scheduledStopPointsOfTrainRouteGroupMaybeLimited = (
  {
    buffer = 0,
    includeIntervalEndsAsStops = false,
    railwayLines,
  }: {
    buffer?: number;
    includeIntervalEndsAsStops?: boolean;
    railwayLines: RailwayLine[];
  },
  scheduledStopPoints: ScheduledStopPointDerived[],
  trainRouteOrGroupMaybeLimited: TrainRouteGroupDerived,
): ScheduledStopPoint[] => {
  const trainDistanceInterval = trainRouteOrGroupMaybeLimited.trainDistanceInterval;
  // Find the first timetabledPassingTime that is equal the start/within the distanceRange
  const firstStopWithinIntervalIndex = findIndex((stop) => {
    return lte(
      Math.round(trainDistanceInterval.distanceRange.start),
      Math.round(stop.routeDistance),
    );
  }, scheduledStopPoints);
  // Find the first timetabledPassingTime that is outside the distanceRange. If no match make it Infinity
  const index: number = findIndex((stop: ScheduledStopPointDerived) => {
    return lt(
      Math.round(trainDistanceInterval.distanceRange.end),
      Math.round(stop.routeDistance),
    );
  }, scheduledStopPoints);
  const firstStopWithoutIntervalIndex = when<number, number>(
    equals(-1),
    always(Infinity),
  )(index);

  // Get the max of 0 and the index of the first match plus the buffer
  const max = Math.max(0, firstStopWithinIntervalIndex - buffer);
  const scheduledStopPointBeforeFirst: ScheduledStopPoint | undefined =
    max > 0 ? scheduledStopPoints[max - 1] : undefined;
  // Get the min of stop length and the first place we didn't find a match (or Infinity) plus the buffer
  const min = Math.min(
    scheduledStopPoints.length,
    firstStopWithoutIntervalIndex + buffer,
  );
  const limitedScheduledStopPoints = slice(max, min, scheduledStopPoints);
  const scheduledStopPointAfterLast: ScheduledStopPoint | undefined =
    min < length(scheduledStopPoints) - 1
      ? scheduledStopPoints[min]
      : // TODO This should maybe be undefined, but then we have to handle undefined below
        last(scheduledStopPoints);

  // Add pseudo end stops if includeIntervalEndsAsStops is true and a real end scheduledStopTime isn't visible
  const routeDistanceLookup = indexBy(prop('routeDistance'), limitedScheduledStopPoints);
  const stopAtDistanceAlongRouteIfNeeded = (
    distance: number,
    nextScheduledStopPoint: ScheduledStopPoint | undefined,
  ): boolean => {
    return has(distance.toString(), routeDistanceLookup)
      ? undefined
      : pseudoScheduledStopPointAtDistanceAlongRoute(
          {
            trainRouteOrGroup: trainRouteOrGroupMaybeLimited,
            nextScheduledStopPoint,
            railwayLines,
          },
          distance,
        );
  };

  return when(
    always(includeIntervalEndsAsStops),
    (limitedScheduledStopPoints: ScheduledStopPoint[]) => {
      return compact([
        stopAtDistanceAlongRouteIfNeeded(
          trainDistanceInterval.distanceRange.start,
          scheduledStopPointBeforeFirst,
        ),
        ...limitedScheduledStopPoints,
        stopAtDistanceAlongRouteIfNeeded(
          trainDistanceInterval.distanceRange.end,
          scheduledStopPointAfterLast,
        ),
      ]);
    },
  )(limitedScheduledStopPoints) as ScheduledStopPoint[];
};

/**
 * For TrainRunLines with data-thresholds stops visible, this calculates where the gaps are so we can show
 * dotted lines between those stations
 * @param loading Return undefined if true
 * @param areOffsetLeftsReady
 * @param offsetLefts
 * @param routeScheduledStopPoints
 * @param visibleStops
 * @returns {[Object]} A list of objects, each with a pair of stops and a property gap that is true
 * if there are hidden stops between them. Also returns the offset left for the first of stops
 */
export const useMemoCreateStopGaps = ({
  loading,
  offsetLefts,
  routeScheduledStopPoints,
  visibleStops,
}: {
  loading: boolean;
  offsetLefts: number[];
  routeScheduledStopPoints: ScheduledStopPoint[];
  visibleStops: ScheduledStopPoint[];
}): StopGap[] | undefined => {
  return useNotLoadingMemo<StopGap[]>(loading, () => {
    const scheduledStopPointIdToIndex = fromPairs<number>(
      addIndex<ScheduledStopPoint>(
        map<ScheduledStopPoint, [string, number]>(
          (
            routeScheduledStopPoint: ScheduledStopPoint,
            index: number,
          ): [string, number] => {
            return [prop('id', routeScheduledStopPoint), index];
          },
          routeScheduledStopPoints,
        ),
      ),
    );

    return addIndex(zipWith)(
      (
        stops1: ScheduledStopPoint,
        stops2: ScheduledStopPoint,
        index: number,
      ): StopGap => {
        const scheduledStopPointPair: [ScheduledStopPoint, ScheduledStopPoint] = [
          stops1,
          stops2,
        ];
        const gap = compose(
          not,
          // See if they are adjacent
          equals(1),
          Math.abs,
          // Take the difference of the index of the stops
          (indices: [number, number]) => {
            return subtract(...indices);
          },
          map((scheduledStopPoint: ScheduledStopPoint) => {
            return scheduledStopPointIdToIndex[scheduledStopPoint.id];
          }),
        )(scheduledStopPointPair);
        return {
          stops: scheduledStopPointPair,
          // The x offset of the timetabledPassingTime along the TrainRunLine
          offsetLefts: slice(index, index + 2, offsetLefts),
          // If the stops have adjacent indices in routesStops, there is no gap
          // the stops are adjacent, there is not gap
          gap,
        };
      },
      slice(0, -1, visibleStops),
      slice(1, Infinity, visibleStops),
    );
  }, [offsetLefts, routeScheduledStopPoints, visibleStops]);
};

/**
 * Calculate the geospatial position of a timetabledPassingTime. This is used when we want to space stops by distance,
 * not evenly along the TrainRunLine
 * @param distanceRange
 * @param scheduledStopPoint
 * @returns {number}
 */
export const calculateGeospatialLeftOffsetOfStop = (
  distanceRange: DistanceRange,
  scheduledStopPoint: ScheduledStopPointDerived,
): number => {
  return calculatePercentageOfDistanceRange(
    distanceRange,
    scheduledStopPoint.routeDistance,
  );
};

/**
 * Given an unnormalized distance like a ScheduledStopPoint routeDistance or an offsetLeft,
 * calculates the percent value of the point relative to the given distance range.
 * If the point is before the start of the distanceRange, 0 is returned. If beyond the end of
 * the distance range, 100 is returned. Otherwise a value between 0 and 100 is returned
 * @param distanceRange
 * @param unnormalizedValue
 * @returns {Number} The percent number
 */
export const calculatePercentageOfDistanceRange = (
  distanceRange: DistanceRange,
  unnormalizedValue: number,
): number => {
  if (unnormalizedValue < distanceRange.start) {
    return 0;
  } else if (unnormalizedValue > distanceRange.end) {
    return 100;
  } else {
    const result =
      (100 * (unnormalizedValue - distanceRange.start)) /
      (distanceRange.end - distanceRange.start);
    return result;
  }
};

/**
 * Given a trainRouteOrGroup and all routeScheduledStopPoints that the interval might cover, calculate
 * the stops to show on the TrainRunLine
 * @param config
 * @param [config.buffer] Default 0 The number of stops before
 * and after those matching the trainDistanceInterval to add.
 * @param [config.removeIntermediate] Default true. Remove the intermediate stops of the interval
 * that aren't adjacent to the ends of the interval. This means that two stops are shown at each end of the interval
 * plus the fist and last timetabledPassingTime of the entire route. If false, then all stops of the interval are displayed plus
 * the first and last timetabledPassingTime of the entire route
 * @param [config.includeEndStops] Default true. If false don't show the end stops of the route
 * unless part of the TrainRunOrGroupInterval. Instead return pseudo stops where the interval ends are.
 * @param routeScheduledStopPoints All stops of the route
 * @param trainRouteOrGroup The TrainRunOrGroup in scope
 * @param trainRouteOrGroup.trainDistanceInterval The trainDistanceInterval.distanceRnage is used to calculate the
 * eligible stops by matching with each timetabledPassingTime's timetabledPassingTime.routeDistance
 * @returns {Object} The visible stops to show on the TrainRunLine
 */
export const visibleScheduledStopPointsOfTrainRouteOrGroupWithDistanceInterval = (
  {
    buffer = 0,
    removeIntermediate = true,
    includeEndStops = true,
    railwayLines,
  }: {
    buffer?: number;
    removeIntermediate?: boolean;
    includeEndStops?: boolean;
    trainRoute?: TrainRoute;
    railwayLines: RailwayLine[];
  },
  routeScheduledStopPoints: ScheduledStopPointDerived[],
  trainRouteOrGroup: TrainRouteGroupDerived,
): ScheduledStopPoint[] => {
  return compose(
    // Include the first and last timetabledPassingTime of the route if includeEndStops is true
    (intervalStops: ScheduledStopPoint[]) => {
      return when(always(includeEndStops), (intervalStops: ScheduledStopPoint[]) => {
        return uniq<ScheduledStopPoint>([
          head<ScheduledStopPoint>(routeScheduledStopPoints),
          ...intervalStops,
          last<ScheduledStopPoint>(routeScheduledStopPoints),
        ] as ScheduledStopPoint[]);
      })(intervalStops) as ScheduledStopPoint[];
    },
    // When removeIntermediate, just take the two stops at each end of the interval
    (scheduledStopPoints: ScheduledStopPoint[]) => {
      return when(
        always(removeIntermediate),
        (scheduledStopPoints: ScheduledStopPoint[]) => {
          return uniq(
            concat(
              slice(0, 2, scheduledStopPoints),
              slice(-2, Infinity, scheduledStopPoints),
            ),
          );
        },
      )(scheduledStopPoints);
    },
    (trainRouteOrGroup: TrainRouteGroupDerived) => {
      return scheduledStopPointsOfTrainRouteGroupMaybeLimited(
        {
          buffer,
          includeIntervalEndsAsStops: !includeEndStops,
          trainRouteOrGroup,
          railwayLines,
        },
        routeScheduledStopPoints,
        trainRouteOrGroup,
      );
    },
  )(trainRouteOrGroup);
};

/**
 * Calculates the geospatial offset for each scheduledStopPoint if spaceGeospatially is true,
 * else return undefined for all
 * @param spaceGeospatially
 * @param limitedTrainDistanceInterval
 * and normalizes the visible stops to fit the full line
 * @param distanceRange
 * @param distanceRange.start
 * @param distanceRange.end
 * @param scheduledStopPoints ScheduledStopPoint instances
 * @returns An array of offset amounts without a unit or array of undefineds or undefined
 */
export const initStopOffsetLefts = (
  spaceGeospatially: boolean,
  limitedTrainDistanceInterval: TrainDistanceInterval,
  distanceRange: DistanceRange,
  scheduledStopPoints: ScheduledStopPoint[],
): number[] | undefined[] => {
  return ifElse<[ScheduledStopPoint[]], number[], undefined[]>(
    () => spaceGeospatially,
    (scheduledStopPoints: ScheduledStopPointDerived[]): number[] => {
      // If we are spacing geospatially, we can already calculate the absolute positions of the timetabledPassingTime
      const stopOffsetLefts = map((scheduledStopPoint) => {
        return calculateGeospatialLeftOffsetOfStop(distanceRange, scheduledStopPoint);
      }, scheduledStopPoints);
      const updatedStopOffsetLefts: number[] = when<number[], number[]>(
        (): boolean =>
          spaceGeospatially && Boolean(limitedTrainDistanceInterval?.distanceRange),
        (stopOffsetLefts) => {
          // If we aren't showing the line from the start to end of the TrainRoute, we have to scale the stopOffsetLefts such that
          // they span from 0 to 100
          const scale = scaleLinear().domain(extremes(stopOffsetLefts)).range([0, 100]);
          return map((offsetLeft) => {
            return scale(offsetLeft);
          }, stopOffsetLefts);
        },
      )(stopOffsetLefts);
      return updatedStopOffsetLefts;
    },
    (scheduledStopPoints) => {
      // Otherwise create an empty array and let the flex-based stations tell us their offsets
      return times(() => undefined, scheduledStopPoints.length);
    },
  )(scheduledStopPoints);
};

/**
 * Simplistic function to indicate if scheduledStopPoint is too close to others and needs to be clustered (i.e. minimized)
 * because it is lower prioirty
 * @param componentWidth
 * @param scheduledStopPointsAndMaybeDateTimesWithOffsetLefts
 * @param spaceGeospatially  If true be much more aggressive about clustering, since points are probably close
 * together for close stops. Otherwise don't cluster unless the size of the station is too small to make an
 * the schedule time partially invisible
 * @param scheduledStopPointAndMaybeDateTime
 * @param offsetLeft
 * @returns boolean
 */
export const shouldClusterIfOverlapsGreaterOrEqualPriorityStops = (
  {
    componentWidth,
    scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    spaceGeospatially,
  }: {
    componentWidth: number;
    scheduledStopPointsAndMaybeDateTimesWithOffsetLefts: ScheduledStopPointAndMaybeDateTimeDerived[];
    spaceGeospatially: boolean;
  },
  {
    scheduledStopPointAndMaybeDateTime,
    offsetLeft,
  }: {
    scheduledStopPointAndMaybeDateTime: ScheduledStopPointAndMaybeDateTimeDerived;
    offsetLeft: number;
  },
): boolean => {
  // If geospatial, look for close trafficSimComponents and prioritize by station priority
  if (spaceGeospatially) {
    const closeScheduledStopPointsAndMaybeTimesWithOffsets = filter(
      ({
        scheduledStopPointAndMaybeDateTime: otherScheduledStopPointAndMaybeDateTime,
        offsetLeft: otherOffsetLeft,
      }) => {
        return (
          scheduledStopPointAndMaybeDateTime !==
            otherScheduledStopPointAndMaybeDateTime &&
          Math.abs(otherOffsetLeft - offsetLeft) < componentWidth
        );
      },
      scheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    );
    // Return true if anything is close that has at least scheduledStopPoint's priority
    return any(({priority: priority}): boolean => {
      return (priority || 0) >= (scheduledStopPointAndMaybeDateTime.priority || 0);
    }, closeScheduledStopPointsAndMaybeTimesWithOffsets);
  } else {
    // Otherwise just make sure the component is wide enough to show the time and a reasonable abbreviation
    // Don't ever hide the end or reference stops, which currently are the only stops above 0 priority
    return scheduledStopPointAndMaybeDateTime.priority === 0 && componentWidth < 40;
  }
};

/**
 * Sets trainProps at crudTrainGroupsLensPath to filteredCrudTrainGroups.
 * This is used to created a modified version of trainProps where the property
 * at crudTrainGroupsLensPath is limited to the single item of filteredCrudTrainGroups
 * @param crudTrainGroupsLensPath
 * @param filteredCrudTrainGroups
 * @param trainProps
 */
export const setFilteredCrudOnTrainProps = <
  S extends TrainProps,
  K0 extends keyof S = keyof S,
  K1 extends keyof S[K0] = keyof S[K0],
  K2 extends keyof S[K0][K1] = keyof S[K0][K1],
  K3 extends keyof S[K0][K1][K2] = keyof S[K0][K1][K2],
>(
  trainProps: S,
  crudTrainGroupsLensPath: [K0] | [K0, K1] | [K0, K1, K2] | [K0, K1, K2, K3],
  filteredCrudTrainGroups: CrudList<TrainGroup>,
): S => {
  return set(crudTrainGroupsLensPath, filteredCrudTrainGroups, trainProps);
};

/**
 * Modifies trainProps.trainGroupSingleTrainRunProps.crudTrainGroups.list to only contain
 * trainProps.trainGroupSingleTrainRunProps.trainGroup.
 * TODO This won't be needed when is replaced by a proper local cache system
 * @param trainProps
 */
export const trainPropsWithFilteredCrudOnTrainProps = ({
  trainProps,
}: {
  trainProps: SingleTrainGroupTrainProps;
}): SingleTrainGroupTrainProps => {
  // TODO The TrainGroup is currently limited to having only one TrainRun
  // In, the future TrainGroupLineContainer can support groups of multiple TrainRuns
  const trainRun: TrainGroup =
    trainProps.trainGroupSingleTrainRunProps.trainGroup.singleTrainRun;

  // Filter by the TrainRun
  const filteredCrudTrainGroups: CrudList<TrainGroup> = useFilterCrudList(
    (trainGroup: TrainGroup) => equals(trainGroup.id, trainRun.id),
    trainProps.trainGroupSingleTrainRunProps.crudTrainGroups,
  );
  if (length(filteredCrudTrainGroups.list) === 0) {
    throw Error(
      `No TrainGroup found in crudTrainGroups matching trainRun id ${trainRun.id}`,
    );
  }
  const filteredTrainProps: SingleTrainGroupTrainProps = setFilteredCrudOnTrainProps({
    filteredCrudTrainGroups,
    trainProps,
  });
  return filteredTrainProps;
};

/**
 * Gets the ScheduledStopPoints of the trainProps.trainRouteGroupProps.trainRoute if isTrainRouteLine is true
 * or the ScheduledStopPoints with TimetabledPassingTimes from the trainProps.trainRouteGroupProps.trainRun
 * @param loading
 * @param trainProps
 * @param isTrainRouteLine
 * @returns {{}|*} Array of Objects that are scheduledStopPointsOfTrainRoute for isTrainRoute==true and
 * scheduledStopPoints with their corresponding timetabledPassingTime merged in for TrainRuns
 */
export const trainRunLineScheduledStopPointsAndMaybeDatetimes = ({
  loading,
  trainProps,
}: {
  loading: boolean;
  trainProps: TrainProps;
}): Perhaps<ScheduledStopPoint[] | ScheduledStopPointAndMaybeDateTimeDerived[]> => {
  return unlessLoadingValue<
    ScheduledStopPoint[] | ScheduledStopPointAndMaybeDateTimeDerived[]
  >(loading, () => {
    const trainRouteOrGroup = trainProps.trainRouteGroupProps.trainRouteOrGroup;
    const scheduledStopPointsOfTrainRoute: ScheduledStopPoint[] =
      useMemoScheduledStopPointsOfTrainRouteOrGroup(trainRouteOrGroup);

    // If this isn't a TrainRouteLine, return the ScheduledStopPoints and the corresponding TimetabledPassingTimes
    // of the TrainRun. If the TrainRun has a TrainRoute that is shorter than the longest TrainRoute of the
    // trainRouteOrGroup, certain timetabledPassingTimes will be undefined
    return ifElse<
      ScheduledStopPointAndMaybeDateTime[],
      ScheduledStopPointAndMaybeDateTime[],
      ScheduledStopPointAndMaybeDateTimeDerived[]
    >(
      always(isTrainRouteOrGroupScope(trainProps)),
      (scheduledStopPointsOfTrainRoute: ScheduledStopPoint[]) => {
        return map<ScheduledStopPoint, ScheduledStopPointAndMaybeDateTime>(
          (
            scheduledStopPoint: ScheduledStopPoint,
          ): ScheduledStopPointAndMaybeDateTime => {
            return clsOrType<ScheduledStopPointAndMaybeDateTime>(
              CemitTypename.scheduledStopPointAndMaybeDateTime,
              {
                scheduledStopPoint,
              },
            );
          },
          scheduledStopPointsOfTrainRoute,
        );
      },
      (scheduledStopPointsOfTrainRoute: ScheduledStopPoint[]) => {
        // Create a collection of {...scheduledStopPoint, timetabledPassingDatetime}
        // We don't have a TrainRun if we are displaying the TrainRoute, so just scheduledStopPoints without times
        const scheduledStopPointIdToTimetabledPassingDatetime = indexBy(
          compose(prop('id'), scheduledStopPointOfTimeTabledPassingDatetime),
          trainProps.filteredTrainGroupProps.trainGroup!.singleTrainRun
            .timetabledPassingDateTimes,
        );
        return map<ScheduledStopPoint, ScheduledStopPointAndMaybeDateTimeDerived>(
          (
            scheduledStopPoint: ScheduledStopPoint,
          ): ScheduledStopPointAndMaybeDateTimeDerived => {
            const timetabledPassingDatetime: TimetabledPassingDateTime = propOr(
              undefined,
              scheduledStopPoint.id,
              scheduledStopPointIdToTimetabledPassingDatetime,
            );
            return clsOrType<ScheduledStopPointAndMaybeDateTimeDerived>(
              CemitTypename.scheduledStopPointAndMaybeDateTimeDerived,
              {
                scheduledStopPoint,
                timetabledPassingDatetime,
              },
            );
          },
          scheduledStopPointsOfTrainRoute,
        );
      },
    )(scheduledStopPointsOfTrainRoute);
  });
};
