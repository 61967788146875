import React, {FC, ReactNode, useState} from 'react';
import {UserContext} from './UserContext.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {UserStateCombined, UserStateInitial} from 'types/userState/userState';
import {CemitTypename} from 'types/cemitTypename.ts';

const UserContextProvider: FC<{children: ReactNode}> = ({children}) => {
  const [userState, _setUserState] = useState<UserStateCombined>(
    clsOrType<UserStateInitial>(CemitTypename.userStateInitial, {
      status: 'initial',
    }),
  );
  const setUserState = (value) => {
    _setUserState(value);
  };

  return (
    <UserContext.Provider value={{userState, setUserState}}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
