import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AccessResponse } from '../types/authentication/access';
import { AppSettings } from '../config/appConfigs/appSettings.ts';

/**
 * Sentry initialization for the CemitType application
 */
export const sentryInit = () => {
  if (!process.env.REACT_SENTRY_DSN) {
    return;
  }
  Sentry.init({
    dsn: process.env.REACT_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: AppSettings.appEnv,
    release: AppSettings.appRelease,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05
  });
};

/**
 * Sets the user base on the Authentication response
 * @param json
 */
export const setUserFromAuthenticationResponse = (json: AccessResponse) => {
  if (json?.message?.email) {
    Sentry.setUser({ email: json.message.email });
  }
}
