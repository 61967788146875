import {cond, forEachObjIndexed, identity, is, map, T as rT} from 'ramda';
import {Cemited} from '../../types/cemited';
import {CemitedClass} from './cemitedClass.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';

/**
 * Default constructor for Cemited types. Copies each value in obj to instance
 * if the corresponding property has a setter or is writable
 * @param obj
 * @param instance
 */
export const cemitedConstructor = <T extends Cemited>(obj: T, instance: T) => {
  const descriptorObj = Object.getOwnPropertyDescriptors(obj);
  const descriptorInstance = Object.getOwnPropertyDescriptors(instance);
  forEachObjIndexed((descriptor: PropertyDescriptor, attribute: keyof T): void => {
    if ((descriptor.set || descriptor.writable) && Object.hasOwn(obj, attribute)) {
      const valueAsMaybeClass: keyof T = cond([
        // When it's got a __typename but hasn't been made into a class yet
        [
          (value: any) => {
            return (
              value && Object.hasOwn(value, '__typename') && !is(CemitedClass, value)
            );
          },
          (value: any) => {
            return clsOrType(value.__typename, value);
          },
        ],
        [
          (value: any) => {
            return Array.isArray(value);
          },
          (values: any[]) => {
            return map((valueItem: any) => {
              return Object.hasOwn(valueItem, '__typename') &&
                !is(CemitedClass, valueItem)
                ? clsOrType(valueItem.__typename, valueItem)
                : valueItem;
            }, values);
          },
        ],
        [rT, identity],
      ])(obj[attribute]);
      try {
        instance[attribute] = valueAsMaybeClass;
      } catch (e) {
        // TODO these are getters that fail. Find a way to detect getters
      }
    }
  }, descriptorObj);
};
