import React, {RefObject, useContext, useEffect, useMemo, useState} from 'react';
import ConfiguredComposedChart, {
  ConfiguredComposedChartProps,
} from 'components/charts/stackedChart/ConfiguredComposedChart.tsx';
import {
  always,
  chain,
  cond,
  equals,
  fromPairs,
  head,
  length,
  lensProp,
  map,
  prop,
  T,
} from 'ramda';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {
  ChartDataCommonConfigFinalized,
  ChartDataConfig,
  ChartDataConfigFinalized,
} from 'types/dataVisualizations/chartDataConfig.ts';
import {ActivePayload} from 'types/dataVisualizations/activePayload';

import {TrainGroupChartProps} from 'types/propTypes/trainPropTypes/trainGroupChartProps';

import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {Typography} from '@mui/material';
import {DataFeatureSet} from 'types/dataVisualizations/dateFeatureSet';
import {
  DataFeature,
  DataFeatureCollection,
  DataFeatureCollectionWithBackReference,
} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import {compact, undefinedToTrue} from 'utils/functional/functionalUtils.ts';
import {strPathOr} from '@rescapes/ramda';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {ChartMapInteractionProps} from 'types/propTypes/trainPropTypes/chartMapInteractionProps';
import {ChartMapInteractionContext} from 'async/trainAppAsync/trainAppDependencies/sensorDependencies/ChartMapInteractionDependency.tsx';
import {overClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';

export interface ConfiguredComposedChartContainerProps extends CemitComponentProps {
  yAxisWidth: number;
  index: number;
  dataPathsConfigs: ChartDataConfig[];
  labelYAxis: string;
  trainDataPathStackedChartRefWidth: number;
  customControls: React.ReactNode[];
  chartDataCommonConfigFinalized: ChartDataCommonConfigFinalized;
  nameToDataFeatureCollectionLookup: Record<string, DataFeatureCollection>;
  forwardedRef: RefObject<any>;
  chartAndMapDataContainerRef: RefObject<any>;
}

/***
 *
 * @param appProps
 * @param componentProps
 * @returns {JSX.Element}
 * @constructor
 */
const ConfiguredComposedChartContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {
    index,
    trainDataPathStackedChartRefWidth,
    yAxisWidth,
    chartDataCommonConfigFinalized,
    sx,
    forwardedRef: ref,
    chartAndMapDataContainerRef,
  },
}: TrainAppTrainComponentDependencyProps<ConfiguredComposedChartContainerProps>): Perhaps<JSX.Element> => {
  const chartMapInteractionProps: Perhaps<ChartMapInteractionProps> = useContext<
    Perhaps<ChartMapInteractionProps>
  >(ChartMapInteractionContext);

  const loading = doesOrganizationHaveServiceLines(organizationProps)
    ? undefinedToTrue(
        trainProps.trainGroupSingleTrainRunProps?.trainGroupChartProps?.loading,
      )
    : false;

  const {chartDataCommonConfig, chartDataConfigFinalizeds} =
    chartDataCommonConfigFinalized;

  const chartProps: TrainGroupChartProps =
    trainProps.trainGroupSingleTrainRunProps.trainGroupChartProps!;

  const loadingExplanation =
    trainProps.trainGroupSingleTrainRunProps.geojsonProps?.whatIsLoading
      ?.loadingExplanation;

  const yAxisDomainOfChartDataConfig = chartDataCommonConfig.yAxisDomain;

  // Set yAxisDomain to the default for the dataPath, e.g. 0 to 2 for Y-acceleration
  const [yAxisDomain, setYAxisDomain] = useState(yAxisDomainOfChartDataConfig);
  useEffect(() => {
    if (!equals(yAxisDomain, yAxisDomainOfChartDataConfig)) {
      setYAxisDomain(yAxisDomainOfChartDataConfig);
    }
  }, [yAxisDomainOfChartDataConfig]);

  const [xValueOfHoveredItem, setXValueOfHoveredItem] = useState<number | undefined>(
    undefined,
  );
  const onMouseEnter = (state: ActivePayload) => {
    if (!state?.activePayload) {
      return;
    }
    setXValueOfHoveredItem(parseFloat(state.activeLabel));
    const payloadItem = head(state.activePayload);

    // TODO chartDataKey should be related to the chartDataConfigs. chartDataKey was never set
    if (payloadItem && !equals(chartProps.activeChartDataKey, payloadItem.dataKey)) {
      // Indicate that this chart is active and that we should ignore the payload from other chart
      chartProps.setActiveChartDataKey(payloadItem.dataKey);
    }
  };

  const onMouseMove = (state: ActivePayload) => {
    if (!state?.activePayload) {
      return;
    }
    setXValueOfHoveredItem(parseFloat(state.activeLabel));
  };

  // const {
  //   clipPathRefs,
  //   xPadding,
  //   onChartMouseDown,
  //   onChartMouseUp,
  //   setWrapperRef,
  //   onChartMouseMove
  // } = useZoomAndPan({
  //   chartLoaded: !loading
  // });

  // The areaName is used to name each Area component. We create a lookup from area name to DataFeatureCollection
  // so we can resolve the DataFeatureCollection of the payloadItems in SensorDataPointsCustomTooltip
  const areaName = (
    label: string,
    dataFeatureCollection: DataFeatureCollection,
  ): string => {
    return `${dataFeatureCollection.label}, ${label}`;
  };

  // Flatten dataFeatureCollections but backref where configs
  const dataFeatureCollections: DataFeatureCollection[] = useNotLoadingMemo(
    loading,
    (chartDataConfigFinalizeds) => {
      return chain((chartDataConfigFinalized: ChartDataConfigFinalized) => {
        return chain((dataFeatureSet: DataFeatureSet) => {
          return compact(
            map(
              (dataFeatureCollection: DataFeatureCollection) => {
                if (!dataFeatureCollection.isVisible) {
                  return undefined;
                }
                return clsOrType<DataFeatureCollectionWithBackReference>(
                  CemitTypename.dataFeatureCollectionWithBackReference,
                  {
                    // Keep the features empty while loading, so we don't show old features or make the graph flash
                    ...overClassOrType(
                      lensProp('features'),
                      (features) => (loading ? [] : features),
                      dataFeatureCollection,
                    ),
                    dataFeatureSet,
                    chartDataConfigFinalized: chartDataConfigFinalized,
                  },
                );
              },
              prop('dataFeatureCollections', dataFeatureSet),
            ),
          );
        }, chartDataConfigFinalized.dataFeatureSets);
      }, chartDataConfigFinalizeds);
    },
    [chartDataConfigFinalizeds],
  );

  const nameToDataFeatureCollectionLookup: Record<string, DataFeatureCollection> =
    useNotLoadingMemo(loading, () => {
      return fromPairs(
        map((dataFeatureCollection: DataFeatureCollection) => {
          return [
            areaName(
              chartDataCommonConfigFinalized.chartDataCommonConfig.label,
              dataFeatureCollection,
            ),
            dataFeatureCollection,
          ];
        }, dataFeatureCollections),
      );
    }, [dataFeatureCollections]);

  // Get all occurring x values so we can calculate the axis
  const xValues: Perhaps<number[]> = useNotLoadingMemo(
    loading,
    (chartDataConfigFinalizeds, xAxisDataPath) => {
      return chain((chartDataConfigFinalized: ChartDataConfigFinalized) => {
        return chain((dataFeatureSet: DataFeatureSet) => {
          return chain((dataFeatureCollection: DataFeatureCollection) => {
            if (!dataFeatureCollection.isVisible) {
              return [];
            }
            return map((feature: DataFeature) => {
              return strPathOr(undefined, xAxisDataPath, feature);
            }, dataFeatureCollection.features);
          }, dataFeatureSet.dataFeatureCollections);
        }, chartDataConfigFinalized.dataFeatureSets);
      }, chartDataConfigFinalizeds);
    },
    [
      chartDataConfigFinalizeds,
      chartDataCommonConfigFinalized.chartDataCommonConfig.xAxisDataPath,
    ],
  );
  const [valueMin, valueMax] = useNotLoadingMemo(
    loading || !xValues,
    (xValues) => {
      return [Math.min(...xValues), Math.max(...xValues)];
    },
    [xValues],
    [0, 0],
  );

  const maybeLoaderComponent = useMemo(
    () => {
      return doesOrganizationHaveServiceLines(organizationProps)
        ? cond([
            [
              prop('loading'),
              () => {
                return (
                  <LoaderWithText
                    key="loadingChart"
                    {...{
                      text: '',
                      spinner: true,
                      loadingExplanation,
                    }}
                  />
                );
              },
            ],
            [
              // Show the no data message
              ({xValues}) => {
                return !length(xValues);
              },
              () => {
                return (
                  <Typography
                    key="label2"
                    {...{
                      sx: {color: 'secondary.main'},
                      variant: 'subtitle2',
                    }}
                  >
                    {appProps.t('noDataRideComfort')}
                  </Typography>
                );
              },
            ],
            [T, always(undefined)],
          ])({loading, xValues})
        : undefined;
    },
    [loading, xValues] as const,
  );

  return (
    <ConfiguredComposedChart
      {...{
        appProps,
        trainProps,
        componentProps: clsOrType<ConfiguredComposedChartProps>(
          CemitTypename.configuredComposedChartProps,
          {
            maybeLoaderComponent,
            yAxisWidth,
            index,
            activeChartDataKey: chartProps.activeChartDataKey,
            onMouseEnter,
            onMouseMove,
            onMouseOver: onMouseEnter,
            onMouseLeave: () => {
              // Do nothing here. Leave the activeChartDataKey as the last value.
              // Otherwise we keep toggling between undefined and defined
            },
            xValueOfHoveredItem,
            mostRecentTooltipPayload: chartMapInteractionProps?.mostRecentTooltipPayload,
            setMostRecentTooltipPayload:
              chartMapInteractionProps?.setMostRecentTooltipPayload,
            chartDataCommonConfigFinalized,
            nameToDataFeatureCollectionLookup,
            valueMin,
            valueMax,
            areaName,
            sx,
            forwardedRef: ref,
            chartAndMapDataContainerRef,
            dataFeatureCollections,
          },
        ),
      }}
    />
  );
};
ConfiguredComposedChartContainer.displayName = 'StackedAreaChartContainer';
export default ConfiguredComposedChartContainer;
