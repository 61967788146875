import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {IconButton, Tooltip} from '@mui/material';
import {CrudList} from 'types/crud/crudList';
import {lensPath, over} from 'ramda';
import {TrainAppComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppComponentDependencyProps';
import {useTranslation} from 'react-i18next';
import {SyntheticEvent} from 'react';

export interface CemitFilterTrainGroupExpanderProps {
  trainGroup: TrainGroup;
  crudTrainGroups: CrudList<TrainGroup>;
  // Is expanding needed
  hasAnythingToShow: boolean;
}

/**
 * Expand or contract a TrainGroup in the left side list
 * @param loading
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainGroupExpander = ({
  loading,
  componentProps,
}: TrainAppComponentDependencyProps<CemitFilterTrainGroupExpanderProps>) => {
  if (loading) {
    return undefined;
  }
  const {trainGroup, crudTrainGroups} = componentProps;
  const {t} = useTranslation();

  /**
   * Toggle trainGroup.activity.isListingExpanded between true and false and persist the state using crudTrainGroups
   */
  const handleClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    const updatedTrainGroup: TrainGroup = over<TrainGroup, boolean>(
      lensPath(['activity', 'isListingExpanded']),
      (isListingExpanded: boolean): boolean => {
        return !isListingExpanded;
      },
      trainGroup,
    );
    crudTrainGroups.updateOrCreate(updatedTrainGroup);
  };

  return componentProps.hasAnythingToShow ? (
    <Tooltip
      {...{
        arrow: true,
        title: t(
          trainGroup.activity.isListingExpanded
            ? 'collapseAlertSummary'
            : 'expandAlertSummary',
        ),
      }}
    >
      <IconButton
        key="cemitFilterTrainGroupExpanderButton"
        {...{
          sx: {padding: 0},
          color: 'secondary',
          onClick: handleClick,
        }}
      >
        {trainGroup.activity.isListingExpanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    </Tooltip>
  ) : undefined;
};
export default CemitFilterTrainGroupExpander;
