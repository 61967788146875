import React from 'react';
import {Grid, Paper, Typography} from '@mui/material';

const ReserveFeedpoints = ({closedSections}) => {
  const items = [
    ['F-0103', 'F-7601', 'F-6901'],
    ['F-0702', 'F-7401', 'F-8601'],
  ];

  return (
    <Paper elevation={1} style={{padding: '10px', width: '280px'}}>
      {items.map((row, index) => (
        <Grid container key={index} spacing={1}>
          {row.map((item, index) => (
            <Grid item xs={4} key={index} style={{margin: '2px 0'}}>
              <Typography variant="caption" component="div">
                {item}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ))}
    </Paper>
  );
};

export default ReserveFeedpoints;
