import {unlessLoadingValue} from 'utils/componentLogic/loadingUtils.ts';
import {always, compose, concat, equals, length, prop, sortBy, uniqBy, when} from 'ramda';
import {
  useMemoCreateStopGaps,
  useMemoZipVisibleScheduledStopPointsAndMaybeTimesWithOffsetLefts,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import {compact} from '@rescapes/ramda';
import {useNotLoadingDidMountEffect} from 'utils/hooks/domHooks.ts';
import {useMemoVisibleScheduledStopPoints} from 'async/trainAppAsync/trainAppHooks/typeHooks/scheduledStopPointHooks.ts';
import {useInitStopOffsetLefts} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainRunLineHooks.ts';
import {TrainDerivedProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {
  ScheduledStopPointAndMaybeDateTime,
  ScheduledStopPointAndMaybeDateTimeDerived,
} from '../../../../types/stops/scheduledStopPointAndMaybeTime';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {ScheduledStopPoint} from '../../../../types/stops/scheduledStopPoint';
import {LoadingExplanation} from '../../../../types/async/loadingExplanation';
import {TrainRouteOrGroupLineFinalizedProps} from 'types/propTypes/trainPropTypes/trainRouteOrGroupLineProps.d.ts';
import {StopGap} from '../../../../types/stops/stopGap';
import {useWhatIsLoading} from '../../../../async/trainAppAsync/trainAppHooks/loadingExplanationHooks.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';

type TrainRunLineReadyHookProps = {
  loading: boolean;
  trainProps: TrainDerivedProps;
  appProps: TrainAppProps;
  trainRouteOrGroupLineProps: TrainRouteOrGroupLineFinalizedProps;
  scheduledStopPointsAndMaybeDateTimes: Perhaps<
    ScheduledStopPoint[] | ScheduledStopPointAndMaybeDateTimeDerived[]
  >;
  offsetLefts: number[];
  setOffsetLefts: StateSetter<number[]>;
  setRecalculateOffsetLefts: StateSetter<Date>;
};

type TrainRunLineReadyProps = {
  loadingExplanation: LoadingExplanation;
  visibleScheduledStopPointsAndMaybeDateTimes: ScheduledStopPointAndMaybeDateTime[];
  visibleScheduledStopPointsAndMaybeDateTimesWithOffsetLefts: ScheduledStopPointAndMaybeDateTimeDerived[];
  distanceRange: number;
  stopGaps: StopGap[];
  areOffsetLeftsReady: boolean;
};

/**
 * Hook-dependent props  of TrainRUnLineReadyContainer
 * @param loading
 * @param trainProps
 * @param trainRoute
 * @param scheduledStopPointsAndMaybeDateTimes
 * @param spaceGeospatially
 * @param onlyStopsNearInterval
 * @param isTrainRouteLine
 * @param offsetLefts
 * @param setOffsetLefts
 * @param setRecalculateOffsetLefts
 * @returns {{stopGaps: Object[]}}
 */
export const trainRunLineReadyHookProps = ({
  loading,
  appProps,
  trainProps,
  trainRouteOrGroupLineProps,
  scheduledStopPointsAndMaybeDateTimes,
  offsetLefts,
  setOffsetLefts,
  setRecalculateOffsetLefts,
}: TrainRunLineReadyHookProps) => {
  const {showLimitedDistanceRange, spaceGeospatially, distanceRange} =
    trainRouteOrGroupLineProps;
  // Determine which ScheduledStopPoints of the TrainRoute
  // or TimetabledPassingTimes of a TrainRun (ScheduledStopPoints with times) are visible on the TrainRunLine
  // If onlyStopsNearInterval, calculate which TimetabledPassingTime instances are visible
  // based on the routeDistance of each timetabledPassingTime and the trainRouteOrRunInterval.
  // We also need to include the first and last TimetabledPassingTimes for when the user drags the bar beyond
  // the start and end unless includeEndStops is false
  const visibleScheduledStopPointsAndMaybeDateTimes = useMemoVisibleScheduledStopPoints({
    loading,
    trainProps,
    trainRouteOrGroupLineProps,
  });

  // Offset lefts need to be set to absolutely based on spaceGeospatially or initialized to an array of undefined
  // if not spaceGeospatially. In the latter case the TrainLineStationDots fill this array once they know
  // their flex position.
  // If the distanceRange changes and we have a showLimitedDistanceRange, we recalculate the offset lefts, in
  // which case the if not spaceGeospatially, the offets are undefineded an TrainLineStationDots are informed
  // that the need to set their offset again in this array:w
  useInitStopOffsetLefts({
    loading,
    trainRouteOrGroupLineProps,
    scheduledStopPointsAndMaybeDateTimes: visibleScheduledStopPointsAndMaybeDateTimes,
    setOffsetLefts,
    offsetLefts,
  });

  // Don't calculate stopGaps until our offsetLeftsLength have been set based on visibleScheduledStopPoints in TrainLineStations
  // Always true when geospatially spacing stops
  const areOffsetLeftsReady = unlessLoadingValue(loading, () =>
    equals(
      length(compact(offsetLefts || [])),
      length(visibleScheduledStopPointsAndMaybeDateTimes),
    ),
  );

  // After mounting, every time distanceRange changes, reset or recompute the offset lefts
  useNotLoadingDidMountEffect(loading, () => {
    // We don't need to reset unless we have a limited distance range or space geospatially because the stations don't move.
    if (!areOffsetLeftsReady || showLimitedDistanceRange || spaceGeospatially) {
      // Flip the toggle to tell children to recalculate their offsetLeft
      setRecalculateOffsetLefts(new Date());
    }
  }, [distanceRange, visibleScheduledStopPointsAndMaybeDateTimes]);

  // Create an array to hold the leftOffsets of each of the station dots on the line
  // We need these to draw the line itself between the start and end station,
  // and we need to convert the kilometer distances to TrainLineStation on the line based on the position of each station.
  const visibleScheduledStopPointsAndMaybeDateTimesWithOffsetLefts =
    useMemoZipVisibleScheduledStopPointsAndMaybeTimesWithOffsetLefts(
      loading || !offsetLefts,
      visibleScheduledStopPointsAndMaybeDateTimes,
      offsetLefts,
    );

  // List of timetabledPassingTime pairs and whether there is a gap in the TrainRunLine
  // because we always show the first and last station along with the
  // stations in the TrainGroup
  // We need the offsetLefts values to be ready so that we know start and stop points of the stop gaps
  const stopGaps = useMemoCreateStopGaps({
    loading: !areOffsetLeftsReady,
    offsetLefts,
    //
    // Add the pseudo stops at the ends when spaceGeospatially
    routeScheduledStopPoints: when(
      always(spaceGeospatially),
      (timetabledPassingDatetimes) => {
        return compose(
          // Sort by route distance
          sortBy(prop('routeDistance')),
          uniqBy(prop('id')),
          // Combine all visible scheduledStopPointsAndMaybeDateTimes (stops) with the stops of the route
          (routeScheduledStopPoints) => {
            return concat(
              visibleScheduledStopPointsAndMaybeDateTimes,
              routeScheduledStopPoints,
            );
          },
        )(timetabledPassingDatetimes);
      },
    )(scheduledStopPointsAndMaybeDateTimes),
    visibleStops: visibleScheduledStopPointsAndMaybeDateTimes,
  });
  const loadingExplanation = useWhatIsLoading(
    loading,
    'loading',
    'trainRunLineReadyProps',
    {
      areOffsetLeftsReady,
      offsetLefts,
      visibleScheduledStopPointsAndMaybeDateTimes,
    },
    [areOffsetLeftsReady, offsetLefts, visibleScheduledStopPointsAndMaybeDateTimes],
    appProps.setWhatDependenciesAreLoading,
  );

  return {
    loadingExplanation,
    visibleScheduledStopPointsAndMaybeDateTimes,
    visibleScheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    distanceRange,
    stopGaps,
    areOffsetLeftsReady,
  } as TrainRunLineReadyProps;
};
