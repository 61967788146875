import {equals, filter, map, omit, values} from 'ramda';
import {accelerationConfig} from './dataConfig.tsx';
import AccelerationToggle from '../../../components/charts/chartControls/AccelerationToggle.tsx';
import {createElement} from 'react';
import {ChartDataCategoryConfig} from '../../../types/dataVisualizations/chartDataCategoryConfig';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {ChartDataConfig} from '../../../types/dataVisualizations/chartDataConfig.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {clsOrType} from '../../../appUtils/typeUtils/clsOrType.ts';
import {Area, Line} from 'recharts';
import {filterWithKeys} from '@rescapes/ramda';
import {distanceOrTimeTickFormatter} from '../../../utils/chart/distanceOrTimeTickFormatter.ts';
import {
  AccelerationConfig,
  AccelerationOptions,
} from '../../../types/dataVisualizations/accelerationConfig';

/**
 * Acceleration Data Config. TODO remove commonChartDataCategoryConfig
 *  and instead configure as in commonConfigs
 *  @param appProps
 *  @param commonChartDataCategoryConfig
 */
export const accelerationDataConfig = ({
  appProps,
  commonChartDataCategoryConfig = {},
}: {
  appProps: TrainAppProps;
  commonChartDataCategoryConfig: Partial<ChartDataCategoryConfig>;
}): ChartDataCategoryConfig => {
  const limitedAccelerationConfigs: AccelerationConfig[] = filter(
    (accelerationConfigChoice: AccelerationConfig) => {
      return accelerationConfigChoice == appProps.chosenAcceleration;
    },
    values(omit(['__typename'], accelerationConfig)) as AccelerationConfig[],
  );

  return clsOrType<ChartDataCategoryConfig>(CemitTypename.chartCategoryDataConfig, {
    ...commonChartDataCategoryConfig,
    category: 'speed',
    showGradients: true,

    // Mean XYZ acceleration and Y acceleration
    configs: map(({derivedProp, label}: AccelerationConfig) => {
      return clsOrType<ChartDataConfig>(CemitTypename.chartDataConfig, {
        // TODO Placeholder
        dataFeatureCollectionSourceTypename: CemitTypename.trainGroup,
        chartType: Area,
        // TODO Belongs in the CommonConfig
        xAxisDataPath: appProps.isTimeSelected ? 'properties.time' : 'properties.meters',
        yAxisDataPath: `properties.${derivedProp}`,
        label: appProps.t(label),
        labelYAxis: 'm/s²',
        yAxisDomain: equals('accYMean', derivedProp) ? [-1, 1] : [0, 2],
        labelXAxis: appProps.isTimeSelected
          ? ''
          : `${appProps.t('totalDistance')} (${appProps.t('km')})`,
        // Only show the Acceleration chart that matches the current setting
        isVisible: ({appProps}) => {
          return equals(derivedProp, appProps.chosenAcceleration.derivedProp);
        },
        // For the X axis Time vs Distance case
        xAxisTickFormatter: distanceOrTimeTickFormatter(appProps.isTimeSelected, false),
        alwaysShow: true,
      });
    }, limitedAccelerationConfigs),
    customControls: [
      // TODO DistanceOrTimeToggle applies to Velocity too and should be in the common config
      //createElement(DistanceOrTimeToggle, {appProps}),
      createElement(AccelerationToggle, {appProps}),
    ],
    showGradients: true,
  });
};
