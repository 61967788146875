import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import StationLine from 'components/apps/trainAppComponents/chooserComponents/trainRunChooserComponents/StationLine.tsx';
import React from 'react';
import {StopGap} from '../../../../../types/stops/stopGap';
import {TrainProps} from '../../../../../types/propTypes/trainPropTypes/trainProps';

/**
 * The line of stations, either oriented horizontally or vertically
 * The top is based on half the height of the dots below minus half the line's own height. Thus STATION_DOT_HEIGHT/2 - 3px/2
 stopGaps can't be calculated until TrainLineStations are rendered because they set their offsetLeft, which
 stopGaps depends on
 * @param loading Show spinner if loading
 * @param loadingExplanation Debug object to explain why the container is loading
 * @param isOver
 * @param stopGaps
 * @param spaceGeospatially Ignored if orientation is vertical.
 * If true we are spacing absolutely by percent. Otherwise we space
 * based on the given stopGaps[*].offsetLefts, which are in pixels.
 * @param orientation Default 'horizontal'. Show the station line horizontally or vertically
 * @returns {JSX.Element}
 * @constructor
 */
const StationLineContainer = ({
  loading,
  loadingExplanation,
  trainProps,
  isTrainGroupDetailLine,
  isOver,
  stopGaps,
  spaceGeospatially,
  orientation = 'horizontal',
}: {
  loading: boolean;
  loadingExplanation: LoadingExplanation;
  trainProps: TrainProps;
  isTrainGroupDetailLine: boolean;
  isOver: boolean;
  stopGaps: StopGap[];
  spaceGeospatially: boolean;
  orientation: string;
}) => {
  return loading ? (
    <LoaderWithText {...{text: 'loadingStationLine', loadingExplanation}} />
  ) : (
    <StationLine
      {...{
        trainGroup: isTrainGroupDetailLine
          ? trainProps.trainGroupSingleTrainRunProps.trainGroup
          : undefined,
        isOver,
        stopGaps,
        spaceGeospatially,
        orientation,
      }}
    />
  );
};
export default StationLineContainer;
