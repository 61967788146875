import {
  applyDeepWithKeyWithRecurseArraysAndMapObjs,
  reqStrPathThrowing,
} from '@rescapes/ramda';
import {
  evalCemitFilterDateInterval,
  isCemitFilterDateInterval,
} from 'appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {
  evalCemitFilterDateRecurrence,
  isCemitFilterDateRecurrence,
} from 'appUtils/cemitFilterUtils/cemitFilterDateRecurrenceUtils.ts';
import {cond, identity, lensProp, map, pathOr, T} from 'ramda';
import {isEquals, isView} from 'appUtils/cemitFilterUtils/cemitFilterAtoms.ts';
import {CemitFilter} from '../../types/cemitFilters/cemitFilter.d.ts';
import {CemitFilterProps} from '../../types/cemitFilters/cemitFilterProps';
import {CemitFilterView} from '../../types/cemitFilters/cemitFilterView';
import {CemitFilterEquals} from '../../types/cemitFilters/cemitFilterEquals';
import {
  evalCemitFilterTrainFormation,
  isCemitFilterTrainFormation,
} from './cemitFilterTrainFormationUtils.ts';
import {Cemited} from '../../types/cemited';
import {CemitFilterDateInterval} from '../../types/cemitFilters/cemitFilterDateInterval';
import {CemitFilterDateRecurrence} from '../../types/cemitFilters/cemitFilterDateRecurrence';
import {CemitFilterTrainFormation} from '../../types/cemitFilters/cemitFilterTrainFormation';
import {overClassOrType} from '../../utils/functional/cemitTypenameFunctionalUtils.ts';

/**
 * Partially evaluates the filter for any view that that matches a
 * path in props such that the props values can be substituted in for the view
 * @param cemitFilter
 * @param props
 * @returns {*}
 */
export const evalFilter = <
  T extends Cemited,
  FT extends CemitFilter<T>,
  P extends CemitFilterProps = CemitFilterProps,
>(
  cemitFilter: FT,
  props: P,
): FT => {
  // Evaluate any props in lensPath that aren't trainRUn
  return applyDeepWithKeyWithRecurseArraysAndMapObjs(
    (_l: any, r: any) => r,
    (_key: string, cemitFilter: CemitFilter) => {
      return cond([
        [
          (cemitFilter: CemitFilter) =>
            isCemitFilterDateInterval(cemitFilter, props),
          (cemitFilter: CemitFilter) => {
            // Replace the view with the corresponding props value if not a trainRun view
            return evalCemitFilterDateInterval(
              cemitFilter as CemitFilterDateInterval,
              props,
            );
          },
        ],
        [
          (cemitFilter: CemitFilter) =>
            isCemitFilterDateRecurrence(cemitFilter, props),
          (cemitFilter: CemitFilter) => {
            return evalCemitFilterDateRecurrence(
              cemitFilter as CemitFilterDateRecurrence,
              props,
            );
          },
        ],
        [
          (cemitFilter: CemitFilter) =>
            isCemitFilterTrainFormation(cemitFilter),
          (cemitFilter: CemitFilter) => {
            return evalCemitFilterTrainFormation(
              cemitFilter as CemitFilterTrainFormation,
              props,
            );
          },
        ],
        [
          // A ramda style view function
          (cemitFilter: CemitFilter) => isView(cemitFilter, props),
          (cemitFilterView: CemitFilterView) => {
            // Replace the view with the corresponding props value if not a trainRun view
            return evalView(cemitFilterView, props);
          },
        ],
        [
          // A ramda style equals function
          (cemitFilter: CemitFilter) => isEquals(cemitFilter, props),
          (cemitFilterEquals: CemitFilterEquals) => {
            // Partially eval the arguments of equals. We expect the second to be a trainRun and not get evaluated
            return evalEquals(cemitFilterEquals, props);
          },
        ],
        // Otherwise return obj as is
        [T, identity],
      ])(cemitFilter);
    },
    cemitFilter,
  );
};

/***
 * Processes an object representing a ramda view function.
 * If the view matches a path in props, it converts the view to the value of the
 * props corresponding to the views lensPath. Otherwise is leaves it alone
 * @param cemitFilterView A ramda view function in object form
 * @param props Props to apply to the view function
 * @returns {Object|*} The unchanged view if the lensPath didn't match props
 * Otherwise the prop value corresponding to the viewPath
 */
const evalView = (
  cemitFilterView: CemitFilterView,
  props: CemitFilterProps,
): any => {
  // Assume that view args are a scalar object, not an array of objects
  const viewArgs = reqStrPathThrowing('view', cemitFilterView);
  const lensPath = reqStrPathThrowing('lensPath', viewArgs);
  // Evaluate the corresponding prop path and return
  return pathOr(cemitFilterView, lensPath, props);
};

/**
 * Partially evaluates the two arguments of equals
 * @param cemitFilterEquals
 * @param props
 * @returns {boolean}
 */
const evalEquals = (
  cemitFilterEquals: CemitFilterEquals,
  props: CemitFilterProps,
): CemitFilter => {
  return overClassOrType(
    lensProp('equals'),

    map((cemitFilter: CemitFilter) => {
      return evalFilter(cemitFilter, props);
    }),
    cemitFilterEquals,
  );
};
