import {buildDependencyChain} from 'appUtils/dependencies/dependencyUtils.tsx';
import {visionAppDependencyUnits} from './visionDependencyUnitConfig.ts';
import {TrainAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppOrganizationDependencyProps.ts';

/**
 * VisionDependencyUnits is the top-level dependency of the Vision application.
 * It loads dependencies configure in dependencyUnits, which ends with render the VisionppContainer
 * @param appProps
 * @param organizationProps
 * @returns {*}
 * @constructor
 */
const VisionDependencyUnits = ({
  appProps,
  organizationProps,
}: TrainAppOrganizationDependencyProps): JSX.Element => {
  // Composes *Dependency.jsx containers that handle global API calls via hooks and managing state
  // API calls and state can also occur deeper in the component tree.
  return buildDependencyChain<
    Required<Omit<TrainAppOrganizationDependencyProps, 'renderChildren'>>
  >(visionAppDependencyUnits, {appProps, organizationProps, loading: false});
};
VisionDependencyUnits.displayName = 'VisionDependencyUnits';
export default VisionDependencyUnits;
