import React, {useEffect, useRef, useState} from 'react';
import mapboxgl, {Map, GeoJSONSource, LngLatLike} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {Signal, Feature, DetectorData} from './types';
import {throwUnlessDefined} from './../../utils/functional/functionalUtils.ts';
import {AppSettings} from './../../config/appConfigs/appSettings.ts';
import trainMarker from 'assets/images/softprio/trainPointer.png'; // Adjust the path as necessary
import {useGeoJSONLoader} from './hooks/useGeoJSONLoader.ts';
import geoJsonSoftPrio from './tempFile/softPrioTrack.geojson';
import updatedDetectors from './tempFile/updatedDetectors.geojson';
import directionArrow from './tempFile/directionArrow.png';
import booleanPointOnLine from '@turf/boolean-point-on-line';
import bbox from '@turf/bbox';
import tramIcon from './tempFile/tram.png';
import * as turf from '@turf/turf';
import e from 'express';
interface StatusPageProps {
  signals: Signal[];
  directionPoints: any[]; // Assuming it's a collection of points
  selectedTag: Feature | null;
  selectedCDC: string;
  onAddDetector: (data: DetectorData) => void;
}
interface DirectionPoint {
  type: 'FeatureCollection';
  features: Array<{
    type: 'Feature';
    geometry: {
      type: 'Point';
      coordinates: number[];
    };
    properties: {
      [key: string]: any;
    };
  }>;
}
const {mapboxStyle} = AppSettings;
const MAPBOX_TOKEN = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN);

const addTrackOutlineLayer = (map: Map, geojsonData: any) => {
  map.addSource('track-outline', {
    type: 'geojson',
    data: geojsonData,
  });

  map.addLayer({
    id: 'track-outline-layer',
    type: 'line',
    source: 'track-outline',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#008000', // Change to desired color
      'line-width': 4,
    },
  });

  const geojsonBounds = bbox(geojsonData);
  map.fitBounds(geojsonBounds as [number, number, number, number], {animate: false});
};

const addLineLayer = (map: Map) => {
  map.addSource('line-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'line-layer',
    type: 'line',
    source: 'line-source',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 4,
    },
  });
};

const addCustomMarkersLayer = (map: Map) => {
  map.addSource('custom-markers', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id: 'custom-markers',
    type: 'symbol',
    source: 'custom-markers',
    layout: {
      'icon-image': 'custom-marker', // Reference to the image in the sprite
      'icon-size': 1.0, // Adjust the size as needed
      'text-field': ['get', 'title'],
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
    },
    paint: {
      'text-color': '#000000', // Adjust text color if necessary
    },
  });
};

const addDirectionPointsLayer = (map: Map, directionPoints: any) => {
  if (!map.hasImage('direction-icon')) {
    map.loadImage(directionArrow, (error, image) => {
      if (error) {
        console.error('Error loading image:', error);
        return;
      }
      map.addImage('direction-icon', image);

      map.addSource('direction-points', {
        type: 'geojson',
        data: directionPoints,
      });

      map.addLayer({
        id: 'direction-points-layer',
        type: 'symbol',
        source: 'direction-points',
        layout: {
          'icon-image': 'direction-icon',
          'icon-size': 0.05, // Adjust the size as needed
          'icon-rotate': ['get', 'direction'], // Rotation value from the feature properties
          'icon-allow-overlap': true,
          'text-anchor': 'top',
        },
      });
    });
  }
};
const clusteredLayer = (map: Map, directionPoints: DirectionPoint): void => {
  if (!map.hasImage('direction-icon')) {
    map.loadImage(directionArrow, (error, image) => {
      if (error) {
        console.error('Error loading image:', error);
        return;
      }
      if (!image) {
        console.error('Image is undefined.');
        return;
      }
      map.addImage('direction-icon', image);

      map.addSource('clusteredpoints', {
        type: 'geojson',
        data: directionPoints,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'clusteredpoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'clusteredpoints',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'clusteredpoints',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'direction-icon',
          'icon-size': 0.05, // Adjust the size as needed
          'icon-rotate': ['get', 'direction'], // Rotation value from the feature properties
          'icon-allow-overlap': true,
          'text-anchor': 'top',
        },
      });

      map.on('click', 'unclustered-point', (e) => {
        const features = e.features;
        if (!features || features.length === 0) return;

        const feature = features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const properties = feature.properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              text-align: left;
              padding: 8px;
            }
            .separator td {
              border-top: 1px solid #ddd;
            }
          </style>
          <table>
            <tr><th>Detector</th><td>${properties.detector}</td></tr>
            <tr class="separator"><td colspan="2"></td></tr>
            <tr><th>Direction</th><td>${properties.direction}</td></tr>
            <tr><th>Equipment Room</th><td>${properties.equipmentRoom}</td></tr>
            <tr><th>Equipment Room Name</th><td>${properties.equipmentRoomName}</td></tr>
            <tr><th>Function ID</th><td>${properties.functionID}</td></tr>
            <tr><th>Object</th><td>${properties.object}</td></tr>
            <tr><th>Object Name</th><td>${properties.objectName}</td></tr>
            <tr><th>Radio Number</th><td>${properties.radioNumber}</td></tr>
            <tr><th>Sequence Number</th><td>${properties.sequenceNumber}</td></tr>
            <tr><th>Tag ID</th><td>${properties.tagID}</td></tr>
            <tr><th>Tag Type</th><td>${properties.tagType}</td></tr>
          </table>
        `,
          )
          .addTo(map);
      });
    });
  }
};
const clusteredLayerUpdated = (map: Map, directionPoints: DirectionPoint): void => {
  if (!map.hasImage('direction-icon')) {
    map.loadImage(directionArrow, (error, image) => {
      if (error) {
        console.error('Error loading image:', error);
        return;
      }
      if (!image) {
        console.error('Image is undefined.');
        return;
      }
      map.addImage('direction-icon', image);

      map.addSource('clusteredpointss', {
        type: 'geojson',
        data: directionPoints,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: 'clusterss',
        type: 'circle',
        source: 'clusteredpoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      });

      map.addLayer({
        id: 'cluster-counts',
        type: 'symbol',
        source: 'clusteredpointss',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      map.addLayer({
        id: 'unclustered-points',
        type: 'symbol',
        source: 'clusteredpoints',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'direction-icon',
          'icon-size': 0.05, // Adjust the size as needed
          'icon-rotate': ['get', 'direction'], // Rotation value from the feature properties
          'icon-allow-overlap': true,
          'text-anchor': 'top',
        },
      });

      map.on('click', 'unclustered-points', (e) => {
        const features = e.features;
        if (!features || features.length === 0) return;

        const feature = features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const properties = feature.properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              text-align: left;
              padding: 8px;
            }
            .separator td {
              border-top: 1px solid #ddd;
            }
          </style>
          <table>
            <tr><th>Detector</th><td>${properties.detector}</td></tr>
            <tr class="separator"><td colspan="2"></td></tr>
            <tr><th>Direction</th><td>${properties.direction}</td></tr>
            <tr><th>Equipment Room</th><td>${properties.equipmentRoom}</td></tr>
            <tr><th>Equipment Room Name</th><td>${properties.equipmentRoomName}</td></tr>
            <tr><th>Function ID</th><td>${properties.functionID}</td></tr>
            <tr><th>Object</th><td>${properties.object}</td></tr>
            <tr><th>Object Name</th><td>${properties.objectName}</td></tr>
            <tr><th>Radio Number</th><td>${properties.radioNumber}</td></tr>
            <tr><th>Sequence Number</th><td>${properties.sequenceNumber}</td></tr>
            <tr><th>Tag ID</th><td>${properties.tagID}</td></tr>
            <tr><th>Tag Type</th><td>${properties.tagType}</td></tr>
          </table>
        `,
          )
          .addTo(map);
      });
    });
  }
};

const StatusPage: React.FC<StatusPageProps> = ({
  directionPoints,
  selectedTag,
  selectedCDC,
  onAddDetector,
}) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  const {geojsonData, loading, error} = useGeoJSONLoader({url: geoJsonSoftPrio});
  const {
    geojsonData: geojsonDataForDetectors,
    loading: loadingDetectors,
    error: errorDetectors,
  } = useGeoJSONLoader({
    url: updatedDetectors,
  });
  const markerRefForTram = useRef<mapboxgl.Marker | null>(null);
  const [isInitialize, setIsInitialized] = useState<boolean>(false);
  const [isPrevTag, setIsPrevTag] = useState<Feature | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null);
  const [selectedPoint, setSelectedPoint] = useState<number[][] | null>(null);
  // // Aligned Points //
  // const [alignedPoints, setAlignedPoints] = useState<DirectionPoint[] | null>(null);
  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    if (
      mapContainer.current &&
      !map.current &&
      geojsonData &&
      directionPoints &&
      geojsonDataForDetectors
    ) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [0, 0], // Initial center, will be updated later
        zoom: 15,
      });

      map.current.on('load', () => {
        if (geojsonData) {
          addTrackOutlineLayer(map.current!, geojsonData);
          addLineLayer(map.current!);
          addCustomMarkersLayer(map.current!);
          //addDirectionPointsLayer(map.current!, directionPoints);
          clusteredLayer(map.current!, geojsonDataForDetectors);
          setIsInitialized(true);
          //working Code for final version //

          // let updatedTagPointsGeoJSON = JSON.parse(JSON.stringify(directionPoints));
          // const storedTagPoints = localStorage.getItem('updatedTagPoints');
          // let updatedTagPoints;

          // if (storedTagPoints) {
          //   // If found in localStorage, parse and use it
          //   updatedTagPoints = JSON.parse(storedTagPoints);
          //   addDirectionPointsLayer(map.current!, updatedTagPoints);
          // } else {
          //   // If not found, perform the computation
          //   updatedTagPoints = {...updatedTagPointsGeoJSON}; // Assuming updatedTagPointsGeoJSON is available

          //   updatedTagPoints.features.forEach((tagPoint) => {
          //     let closestPointOnLine;
          //     let minDistance = Infinity;
          //     const tagPointCoordinates = tagPoint.geometry.coordinates;

          //     geojsonData.features.forEach((feature) => {
          //       const {coordinates} = feature.geometry;

          //       // For MultiLineString, coordinates is an array of arrays
          //       coordinates.forEach((line) => {
          //         const lineSegment = turf.lineString(line);
          //         const nearestPoint = turf.nearestPointOnLine(
          //           lineSegment,
          //           turf.point(tagPointCoordinates),
          //         );

          //         if (nearestPoint) {
          //           const distance = turf.distance(
          //             turf.point(tagPointCoordinates),
          //             nearestPoint,
          //           );
          //           if (distance < minDistance) {
          //             minDistance = distance;
          //             closestPointOnLine = nearestPoint.geometry.coordinates;
          //           }
          //         }
          //       });
          //     });

          //     // Update the tagPoint coordinates only if a closer point was found
          //     if (closestPointOnLine) {
          //       tagPoint.geometry.coordinates = closestPointOnLine;
          //     }
          //   });

          //   // Store the updatedTagPoints in localStorage
          //   localStorage.setItem('updatedTagPoints', JSON.stringify(updatedTagPoints));
          // }
        }
      });
    }
    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [geojsonData, directionPoints, geojsonDataForDetectors]);
  useEffect(() => {
    if (isInitialize) {
      if (selectedTag) {
        setIsPrevTag(selectedTag);
        const popup = new mapboxgl.Popup()
          .setLngLat(selectedTag.geometry.coordinates)
          .setHTML(`<h3>Detector:${selectedTag.properties.detector}</h3>`)
          .addTo(map.current!);

        map.current!.flyTo({
          center: selectedTag.geometry.coordinates as LngLatLike,
          zoom: 20,
          essential: true, // Ensures the animation is essential (required for smooth animation)
          speed: 1.5, // Adjust animation speed as needed
          curve: 1.5, // Adjust animation curve as needed
        });
      } else if (isPrevTag) {
        map.current?.flyTo({
          center: isPrevTag.geometry.coordinates as LngLatLike,
          zoom: 12,
          essential: true, // Ensures the animation is essential (required for smooth animation)
          speed: 1.5, // Adjust animation speed as needed
          curve: 1.5, // Adjust animation curve as needed
        });
        setIsPrevTag(null);
      }
    }
  }, [selectedTag, isInitialize]);
  function getRandomIndex(array: [any]) {
    return Math.floor(Math.random() * array.length);
  }
  useEffect(() => {
    if (!map.current || !geojsonData || selectedCDC == '' || selectedCDC === 'NONE')
      return;
    // Select a random line and point if not already selected
    if (selectedLineIndex === null || selectedPoint === null) {
      const randomLineIndex = Math.floor(Math.random() * geojsonData.features.length);
      const randomLine = geojsonData.features[randomLineIndex];
      const coordinates = randomLine.geometry.coordinates;

      const randomIndex = getRandomIndex(coordinates);
      const selectedPoint = coordinates[randomIndex];
      setSelectedLineIndex(randomLineIndex);
      setSelectedPoint(selectedPoint); // This is array of points with lan and lat
      setCurrentIndex(0); // Reset index for the new line
    }
    // Add a marker for each coordinate in the selected line
    const addMarker = (index: number) => {
      // We need to check if there is array of lng, lat array
      if (map.current && selectedPoint && index < selectedPoint.length) {
        const [lng, lat] = selectedPoint[index];
        const el = document.createElement('div');
        el.className = 'custom-marker';
        if (markerRefForTram.current) {
          markerRefForTram.current.remove();
        }
        const newMarker = new mapboxgl.Marker(el)
          .setLngLat([lng, lat])
          .addTo(map.current);
        markerRefForTram.current = newMarker;
        // Update the current index to loop through coordinates
        checkForArrowIntersection([lng, lat]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    };

    const interval = setInterval(() => {
      if (
        selectedPoint &&
        currentIndex <
          geojsonData.features[selectedLineIndex!]?.geometry.coordinates.length
      ) {
        addMarker(currentIndex);
      } else {
        // Finished current line, reset to select a new random line
        setSelectedLineIndex(null);
        setSelectedPoint(null);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [geojsonData, map, currentIndex, selectedLineIndex, selectedPoint, selectedCDC]);

  useEffect(() => {
    return () => {
      if (map.current && map.current.hasImage('custom-marker')) {
        map.current.removeImage('custom-marker');
      }
    };
  }, []);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      const el = document.querySelector('.custom-marker');
      if (el) {
        el.classList.toggle('blink');
      }
    }, 1000);
    return () => clearInterval(blinkInterval);
  }, []);

  const checkForArrowIntersection = (point: [number, number]) => {
    directionPoints.features.forEach((feature: any) => {
      const arrowCoords = feature.geometry.coordinates;
      const distance = mapboxgl.LngLat.convert(point).distanceTo(
        mapboxgl.LngLat.convert(arrowCoords),
      );
      if (distance < 10) {
        onAddDetector(feature.properties);
      }
    });
  };

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{height: '100vh', width: '100vw'}}
    >
      <style>
        {`
          .custom-marker {
            width: 32px;
            height: 32px;
            background-image: url(${tramIcon});
            background-size: cover;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
          .blink {
            animation: blink-animation 1s infinite;
          }
          @keyframes blink-animation {
            0%, 50%, 100% { opacity: 1; }
            25%, 75% { opacity: 0; }
          }
          .direction-marker {
            width: 32px;
            height: 32px;
            background-image: url(${trainMarker});
            background-size: cover;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>
    </div>
  );
};

export default StatusPage;
