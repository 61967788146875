import React from 'react';
import {ThemeProvider} from '@mui/material';
import {QueryClient, QueryClientProvider} from 'react-query';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import {ThemeEnum, themeLookup} from 'theme/lightDarkTheme.ts';
import SandboxTrainListContainer from 'pages/sandbox/SandboxTrainListContainer.tsx';

const queryClient = new QueryClient();

const SandboxAppContainer = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* The rest of your application */}
      <ReactQueryDevtools initialIsOpen={false}>
        <SandboxTrainListContainer {...{appProps, organizationProps}} />
      </ReactQueryDevtools>
    </QueryClientProvider>
  );
};
export default SandboxAppContainer;
