import {Cemited} from 'types/cemited';
import {equals, Lens, map, omit, over, pick, prop, set, sortBy, view} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {CemitTypename} from 'types/cemitTypename.ts';
import {mapMDeep} from '@rescapes/ramda';
import {idListsEqual} from './functionalUtils.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {isVersioned, updateDateAndVersion} from '../versionUtils.ts';

// @ts-ignore. This exact same signature works in over's ts file. Typescript sucks
export function overClassOrType<S extends Cemited, A>(
  lens: Lens<S, A>,
): {
  (fn: (a: A) => A): (value: S) => S;
  (fn: (a: A) => A, value: S): S;
};
export function overClassOrType<S extends Cemited, A>(
  lens: Lens<S, A>,
  fn: (a: A) => A,
): (value: S) => S;
// This must be explicitly listed or Webstorm gets confused
export function overClassOrType<S extends Cemited, A>(
  lens: Lens<S, A>,
  fn: (a: A) => A,
  value: S,
): S;

/**
 * Wraps over in classifyOrTypeObject if S is Cemited.
 * TODO in the future this should throw an error if a non Cemited value is passed in
 * @param lens
 * @param fn
 * @param value
 */
export function overClassOrType<S extends Cemited, A>(
  lens: Lens<S, A>,
  fn: (a: A) => A,
  value: S,
): S {
  const modifiedValue: S = over(lens, fn, value);
  const versionedValue: S = isVersioned(modifiedValue)
    ? updateDateAndVersion<S>(modifiedValue)
    : modifiedValue;
  return clsOrType<S>(value.__typename, versionedValue);
}

/**
 * Omit props of S and wrap with clsOrType
 * @param props
 * @param value
 */
export function omitClassOrType<S extends Cemited, A>(props: (keyof S)[], value: S): S {
  const modifiedValue: Omit<S, keyof S> = omit(props, value);
  const versionedValue: Omit<S, keyof S> = isVersioned(modifiedValue)
    ? updateDateAndVersion<Omit<S, keyof S>>(modifiedValue)
    : modifiedValue;
  return clsOrType<S>(value.__typename, versionedValue as S);
}

/**
 * Pick props of S and wrap with clsOrType
 * @param props
 * @param value
 */
export function pickClassOrType<S extends Cemited, A>(props: (keyof S)[], value: S): S {
  const modifiedValue: Omit<S, keyof S> = pick(props, value);
  const versionedValue: Omit<S, keyof S> = isVersioned(modifiedValue)
    ? updateDateAndVersion<Omit<S, keyof S>>(modifiedValue)
    : modifiedValue;
  return clsOrType<S>(value.__typename, versionedValue as S);
}

export function overClassOrTypeList<S extends Cemited, A>(
  lens: Lens<S, A>,
  fn: (a: A) => A,
  values: S[],
): S[] {
  const modifiedValues: S = over(lens, fn, values);
  return map((value: S) => {
    const versionedValue: S = isVersioned(value) ? updateDateAndVersion<S>(value) : value;
    return clsOrType<S>(value.__typename, versionedValue);
  }, modifiedValues);
}

// This must be explicitly listed or Webstorm gets confused
// @ts-ignore. This exact same signature works in set's ts file.
export function setClassOrType<S extends Cemited, A>(
  lens: Lens<S, A>,
): {
  (fn: (a: A) => A): (value: S) => S;
  (fn: (a: A) => A, value: S): S;
};
export function setClassOrType<S extends Cemited, A>(
  lens: Lens<S, A>,
  a: A,
): (obj: S) => S;
export function setClassOrType<S extends Cemited, A>(lens: Lens<S, A>, a: A, obj: S): S;
/**
 * Wraps set in classifyOrTypeObject if S is Cemited.
 * TODO in the future this should throw an error if a non Cemited value is passed in
 * @param lens
 * @param a
 * @param obj
 */
export function setClassOrType<S extends Cemited, A>(lens: Lens<S, A>, a: A, obj: S): S {
  const modifiedValue: S = set(lens, a, obj);
  const versionedValue = isVersioned(modifiedValue)
    ? updateDateAndVersion(modifiedValue)
    : modifiedValue;
  return clsOrType<S>(versionedValue.__typename, versionedValue);
}

/**
 * Sets all objs at lens to a
 * @param lens
 * @param a
 * @param objs
 */
export function setClassOrTypeList<S extends Cemited, A>(
  lens: Lens<S[], A>,
  a: A,
  objs: S[],
): S {
  const modifiedValues: S[] = set(lens, a, objs);
  return map((modifiedValue: S) => {
    const versionedValue = isVersioned(modifiedValue)
      ? updateDateAndVersion(modifiedValue)
      : modifiedValue;
    return clsOrType<S>(versionedValue.__typename, versionedValue);
  }, modifiedValues);
}

export function setClassOrTypeIfChanged<S extends Cemited, A>(
  lens: Lens<S, A>,
  a: A,
): (obj: S) => S;
export function setClassOrTypeIfChanged<S extends Cemited, A>(
  lens: Lens<S, A>,
  a: A,
  obj: S,
): S;
/**
 * Set obj at lens to a if a does not equal the current value
 * TODO in the future this should throw an error if a non Cemited value is passed in
 * @param lens
 * @param a
 * @param obj
 */
export function setClassOrTypeIfChanged<S extends Cemited, A>(
  lens: Lens<S, A>,
  a: A,
  obj: S,
): S {
  const currentValue = view(lens, obj);
  if (!equals(currentValue, a)) {
    const modifiedValue: S = set(lens, a, obj);
    const versionedValue = isVersioned(modifiedValue)
      ? updateDateAndVersion(modifiedValue)
      : modifiedValue;
    return clsOrType<S>(versionedValue.__typename, versionedValue);
  } else {
    return obj;
  }
}

/**
 * Compares the id attribute of list1 and list2 after sorting each by id. If they are equal,
 * compares the __typename of matching item from each list to make sure they are the same
 * This is used to find out if the second list has subclass instances that differ from the first list
 * because more detailed information has been downloaded or derived for the instances of list2
 * @param list1
 * @param list2
 * @return boolean
 */
export const idsAndTypenamesEqual = <T extends Cemited>(
  list1: Perhaps<T[]>,
  list2: Perhaps<T[]>,
): boolean => {
  const sortedLists: [T[], T[]] = map(sortBy(prop('id')), [list1 || [], list2 || []]) as [
    T[],
    T[],
  ];
  const typenameLists: [CemitTypename[], CemitTypename[]] = mapMDeep(
    2,
    prop('__typename'),
    sortedLists,
  );

  return idListsEqual(...sortedLists) && equals(...typenameLists);
};
