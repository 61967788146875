import {Cemited} from '../../types/cemited';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {cemitTypeObjectAsClass} from '../../classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {typeObject} from './typeObject.ts';
import {deepInstantiate} from './deepInstantiate.ts';
import {clsOrType} from './clsOrType.ts';

/**
 * When a single class implementation is known to exists for typename, instantiate the class from the given object t.
 * Throws if no or multiple class implementations exist
 * @param typename
 * @param t
 */
export const classifyObject = <T extends Cemited>(
  typename: CemitTypename,
  t: T | Omit<WritablePart<T>, '__typename'> | never,
): T => {
  //const typedObject: T = typeObject(typename, t);
  return clsOrType(typename, t);
  // Disable for now too slow
  // return deepInstantiate(cemitTypeObjectAsClass<T>(typedObject)!, typedObject);
};
