import {setUserFromAuthenticationResponse} from 'monitoring/sentry.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {useContext, useEffect} from 'react';
import {
  UserStateCombined,
  UserStateFailed,
  UserStateLoaded,
} from 'types/userState/userState';
import {SetTokenType, useToken} from 'utils/hooks/useTokenHook.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {getCemitAuthApiUrl} from '../../apis/cemitApis/apiConfig.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {Access, AccessResponse} from '../../types/authentication/access';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {StateSetter} from '../../types/hookHelpers/stateSetter';
import {head, includes} from 'ramda';
import {hasAdminAccess} from 'appUtils/authentication/authenticationUtils.ts';
import {ACCESS_LEVELS} from 'pages/trafficsim/utils/access_levels.ts';
import {OrganizationSourceKey} from 'apis/apiOrganizationConfigs/organizationManifest.ts';
import {resolvePartnerOrganizationSourceKeyIfDefined} from 'utils/organization/organizationUtils.ts';

/**
 * Authenticates the user based on the available token and returns the userState and setUserState
 */
export const useAuthenticate = () => {
  const {userState, setUserState} = useContext(UserContext) as UserContextType;
  const [token, setToken] = useToken();
  useEffect(() => {
    // Run authentication process by contacting API, once it is done it will update the user state
    if (token) {
      authenticate({setUserState, token, setToken});
    } else {
      // No token so cannot authenticate, change state of authentication to failed (ie. unathenticated)
      setUnathenticated({setUserState});
    }
  }, [token]);
  return {userState, setUserState};
};

const loadLocalAuthData = (): AccessResponse => {
  if (process.env.REACT_DISABLE_AUTH_CHECK === 'true') {
    let json: AccessResponse;
    if (
      process.env.REACT_APP_FORCED_JWT !== undefined &&
      process.env.REACT_APP_FORCED_JWT != ''
    ) {
      try {
        json = JSON.parse(process.env.REACT_APP_FORCED_JWT);
        console.log('Authenticated from env variable', json);
      } catch (error) {
        console.log('Failed to authenticate from env variable', error);
        json = {
          status: '',
          ok: true,
          message: {},
        };
      }
    } else {
      console.log('Authenticated with hardcoded values (sysadmin)');
      json = {
        status: '',
        ok: true,
        message: {
          id: 'admin',
          email: 'admin@example.com',
          group: 'sysadmin',
          customerId: 'kiwi_rail',
        },
      };
    }
    return json;
  } else {
    throw new Error('loadLocalAuthData() was called when auth check is enabled');
  }
};

/**
 * Authenticates the user from the token and sets a new user state depending
 * on whether it failed or was authenticated.
 * @param setUserState Function to set new user state
 * @param token String containing the JWT token
 * @return
 */
const authenticate = async ({
  setUserState,
  token,
  setToken,
}: {
  setUserState: StateSetter<UserStateCombined>;
  token: string;
  setToken: SetTokenType;
}) => {
  try {
    let json: AccessResponse;
    if (process.env.REACT_DISABLE_AUTH_CHECK !== 'true') {
      const url = `${getCemitAuthApiUrl()}/checkjwt`;
      const response: Response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({token}),
      });
      const responseStatus = response.status;
      if (!response.ok) {
        if (responseStatus === 403) {
          // User could not be authenticated using JWT token

          setUserState(
            clsOrType<UserStateFailed>(CemitTypename.userStateFailed, {
              status: AccessStatus.Invalid,
              error: 'Invalid user',
            }),
          );
        } else {
          // Generic HTTP response status
          if (console.error) {
            console.error('Failed checking JWT, HTTP response: ' + response.statusText);
          }

          setUserState(
            clsOrType<UserStateFailed>(CemitTypename.userStateFailed, {
              status: AccessStatus.Invalid,
              error: 'Authentication error',
            }),
          );
        }
        setToken(undefined);
        return;
      }
      json = await response.json();
      // Log in Sentry
      setUserFromAuthenticationResponse(json);
    } else {
      // This is the code path that can be used for local development
      json = loadLocalAuthData();
    }

    const email = json?.message?.email;
    const group = json?.message?.group ?? '';
    const trackAccess = Array.isArray(json.message?.trackAccess)
      ? json?.message?.trackAccess
      : [];
    const applicationAccess = Array.isArray(json?.message?.tabAccess)
      ? json?.message?.tabAccess
      : [];
    const status =
      email && (group === 'sysadmin' || applicationAccess)
        ? AccessStatus.Authenticated
        : AccessStatus.Invalid;

    // Resolves to one ACCESS_LEVELS, used only for trafficSim
    const trafficSimAccessLevel =
      group === 'sysadmin'
        ? ACCESS_LEVELS.ADMIN
        : head(
            Array.from(applicationAccess).filter(
              (s) => s.substring(0, 9) === 'tracksim_',
            ),
          );

    const is2FARequired = (applicationAccess?.filter((access: string) => access === "require_2fa" ).length > 0)

    setUserState((_userState: UserStateCombined) => {
      const customerId =
        json?.message?.customerId &&
        // Convert partner organization sourceKey to the end-user organization
        resolvePartnerOrganizationSourceKeyIfDefined(json?.message?.customerId);

      return status === AccessStatus.Authenticated
        ? clsOrType<UserStateLoaded>(CemitTypename.userStateLoaded, {
            // Must either be a syadmin or have at least one product available to be considered valid
            status: AccessStatus.Authenticated,
            id: json?.message?.id ?? '',
            sourceKey: customerId,
            email: email ?? '',
            access: clsOrType<Access>(CemitTypename.access, {
              trackAccess: trackAccess,
              applicationAccess: applicationAccess,
              group: group,
              // TODO temp read/write level for trafficsim
              trafficSimAccessLevel,
              is2FARequired
            }),
          })
        : clsOrType<UserStateFailed>(CemitTypename.userStateFailed, {
            // Must either be a syadmin or have at least one product available to be considered valid
            status: AccessStatus.Invalid,
            sourceKey: customerId,
          });
    });

    // environmentAccess isn't set on the user. Ignore this for now
    // if (
    //   json.message.environmentAccess ||
    //   (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'local')
    // ) {
    //   if (
    //     !(
    //       json.message.environmentAccess?.includes(process.env.NODE_ENV) ||
    //       json.message.group === 'sysadmin'
    //     )
    //   ) {
    //     appProps.setToken(undefined)
    //     navigate('/');
    //     return;
    //   }
    // }
  } catch (error) {
    // Generic error, for instance network problems
    if (console.error) {
      console.error('Failed checking JWT: ' + error.message);
    }

    setUserState((userState: UserStateFailed) => {
      return clsOrType<UserStateFailed>(CemitTypename.userStateFailed, {
        status: AccessStatus.Invalid,
        id: 'invalid',
        // TODO sourceKey is currently hard-coded to the environment in OrganizationDependency
        // It should come from the user
        sourceKey: userState?.sourceKey,
        email: 'invalid@example.com',
        // TODO Replaces these with something more sophisticated
        access: clsOrType<Access>(CemitTypename.access, {
          trackAccess: [],
          applicationAccess: [],
          group: '',
        }),
        error: 'Authentication service could not be reached',
      });
    });
    return;
  }
};

const setUnathenticated = ({
  setUserState,
}: {
  setUserState: StateSetter<UserStateCombined>;
}) => {
  setUserState(
    clsOrType<UserStateFailed>(CemitTypename.userStateFailed, {
      status: AccessStatus.Invalid,
      sourceKey: '',
      error: '',
    }),
  );
};
