// The default RideComfort Errors to show
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig.d.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TFunction} from 'i18next';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';
import {
  CEMIT_ALERT_CRITICAL,
  CEMIT_ALERT_NORMAL,
  CEMIT_ALERT_WARNING,
} from 'theme/cemitColors.ts';
import {AlertRideComfortGauge} from 'types/alerts/rideComfort/rideComfortGauge';
import NormalSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/normalSvgIcon.tsx';
import ErrorSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/errorSvgIcon.tsx';
import WarningSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/warningSvgIcon.tsx';

export const rideComfortErrorLevelConfigs = (
  t: TFunction,
): AttributeAlertLevelConfig<AlertRideComfortGauge>[] => {
  return clsOrTypes<AttributeAlertLevelConfig<AlertRideComfortGauge>>(
    CemitTypename.alertErrorLevelConfig,
    [
      {
        attributeAlertLevel: RideComfortAttributeAlertLevel.normal,
        label: t(RideComfortAttributeAlertLevel.normal),
        imageSvgComponent: NormalSvgIcon,
        color: CEMIT_ALERT_NORMAL,
      },

      {
        attributeAlertLevel: RideComfortAttributeAlertLevel.warning,
        label: t(RideComfortAttributeAlertLevel.warning),
        imageSvgComponent: WarningSvgIcon,
        color: CEMIT_ALERT_WARNING,
      },
      {
        attributeAlertLevel: RideComfortAttributeAlertLevel.critical,
        label: t(RideComfortAttributeAlertLevel.critical),
        imageSvgComponent: ErrorSvgIcon,
        color: CEMIT_ALERT_CRITICAL,
      },
    ],
  );
};
