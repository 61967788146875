import {AlertHeatMapDatum} from 'types/alerts/alertHeatMapDatum';
import {filter, map} from 'ramda';
import {Feature, Point} from 'geojson';

/**
 * Just removes values that are undefined and returns the remaining points
 * @param rideComfortHeatMapData
 */
export const getValidPoints = (
  rideComfortHeatMapData: AlertHeatMapDatum[],
): Feature<Point>[] => {
  const validData: AlertHeatMapDatum[] = filter(({numericValue}: AlertHeatMapDatum) => {
    return numericValue != 0 && !isNaN(numericValue);
  }, rideComfortHeatMapData);
  return map((datum: AlertHeatMapDatum) => {
    return datum.point;
  }, validData);
};
