import {ROUTE_LINE_HEIGHT, STATION_DOT_HEIGHT} from 'theme/train/trainThemeConstants.ts';
import {addIndex, always, cond, map, T} from 'ramda';
import {Box} from '@mui/material';
import {strPathOr} from '@rescapes/ramda';
import {StopGap} from '../../../../../types/stops/stopGap';
import React from 'react';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * AreaChart. Use either a ref-setter function or React.createRef().
 * @param trainGroup
 * @param isOver
 * @param stopGaps
 * @param spaceGeospatially Ignored if orientation is vertical.
 * If true we are spacing absolutely by percent. Otherwise we space
 * based on the given stopGaps[*].offsetLefts, which are in pixels
 * @param orientation
 * @returns {JSX.Element}
 * @constructor
 */
const StationLine = ({
  trainGroup,
  isOver,
  stopGaps,
  spaceGeospatially,
}: {
  trainGroup: TrainGroup;
  isOver: boolean;
  stopGaps: StopGap[];
  spaceGeospatially: boolean;
}) => {
  const lines: (typeof Box)[] = addIndex(map)((stopGap: StopGap, index: number) => {
    const [startStationOffsetLeft, endStationOffsetLeft] = stopGap.offsetLefts;
    const unit = spaceGeospatially ? '%' : 'px';

    const left = spaceGeospatially
      ? `${startStationOffsetLeft}${unit}`
      : `calc(${STATION_DOT_HEIGHT} / 2 + ${startStationOffsetLeft}${unit})`;
    const width = spaceGeospatially
      ? `${endStationOffsetLeft - startStationOffsetLeft}${unit}`
      : `calc(${STATION_DOT_HEIGHT} / 2 + ${endStationOffsetLeft - startStationOffsetLeft}${unit})`;

    const color = cond([
      [always(isOver), always('trainRunLine.action.isOver')],
      [strPathOr(false, 'activity.isBaseline'), always('compare.baseline.main')],
      [T, always('trainRunLine.main')],
    ])(trainGroup);
    return (
      <Box
        key={`stationLine${index}`}
        sx={{
          position: 'absolute',
          top: `calc((${STATION_DOT_HEIGHT} / 2) - (${ROUTE_LINE_HEIGHT} / 2))`,
          left,
          width,
          height: ROUTE_LINE_HEIGHT,
          borderBottomWidth: ROUTE_LINE_HEIGHT,
          borderBottomStyle: stopGap.gap ? 'dashed' : 'solid',
          borderBottomColor: color,
        }}
      />
    );
  }, stopGaps);
  // @ts-ignore
  return (
    <Box {...{sx: {width: '100%'}}}>
      {
        // TODO don't understand why lines doesn't work
        lines as unknown as React.ReactNode[]
      }
    </Box>
  );
};
export default StationLine;
