import {WeekYear} from 'types/datetime/weekYear';
import {Stack, Typography} from '@mui/material';
import React from 'react';

/**
 * Uses weekYear.labelComponents to create a formatted week label
 * @param weekYear
 * @constructor
 */
export const WeekYearLabel = ({weekYear}: {weekYear: WeekYear}) => {
  const [weekNumber, dates] = weekYear.labelComponents;
  return (
    <Stack {...{direction: 'row', spacing: 1}}>
      <Typography key="weekYearLabelNumber" {...{sx: {fontWeight: 'bold'}}}>
        {weekNumber}
      </Typography>
      <Typography key="weekYearLabelDates">{dates}</Typography>
    </Stack>
  );
};
