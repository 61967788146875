import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {cond, equals, fromPairs, head, ifElse, length, map, T} from 'ramda';
import {SortDirection} from 'types/sorting/sortDirection';
import {idsEqual} from 'utils/functional/functionalUtils.ts';
import {
  FrontendView,
  FrontendViewType,
} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {isViewActive} from 'appUtils/cemitAppUtils/viewUtils.ts';
import {
  deselectAndDeActivateTrainGroup,
  selectAndActivateTrainGroup,
  selectAndActivateTrainGroups,
} from 'components/apps/trainAppComponents/chooserComponents/trainRunChooserComponents/trainGroupChooserUtils.tsx';
import CemitFilterTrainGroupsViewList from './CemitFilterTrainGroupsViewList.tsx';
import {trainGroupsGrouping} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendViewGroups.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import CemitFilterTrainGroupsViewMenu from './CemitFilterTrainGroupViewMenu.tsx';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainGroupMenuProps} from '../../chooserComponents/trainRunChooserComponents/TrainGroupMenu.tsx';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {ServiceLine} from 'types/trainRouteGroups/serviceLine';
import {TypedCemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

export interface CemitFilterTrainGroupsContainerProps extends TypedCemitComponentProps {
  // The number of instances that can be selected
  maximumAllowed?: Perhaps<number>;
  // Only show the given FrontendViewTypes, so if forcedActiveFrontendView or
  // else head(appProps.activeFrontendViewsOfGroup(trainGroupsGrouping)) doesn't match acceptTypes
  // an error will be thrown. TODO this will probably go away
  acceptTypes?: string[];
  // Force the FrontendView instead of using head(appProps.activeFrontendViewsOfGroup(trainGroupsGrouping))
  forcedActiveFrontendView?: FrontendView;
  // For menu mode, this indicates a TrainGroupOnlyTrainFormation that is in
  // trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations. When the user selects a different
  // TrainGroup, it will replace this one in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations
  scopedTrainGroup?: TrainGroup;
  // If true, no TrainGroupOnlyTrainFormation will be shown as selected, but a plus icon will indicate
  // that a TrainGroup from trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations that are not already
  // in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations
  addTrainGroupMode?: Perhaps<boolean>;
}

/**
 * Returns the compact or long form of a list of TrainGroups
 * based on appProps.activeFrontendViewsOfGroup(trainGroupsGrouping)
 * Returns undefined if * appProps.activeFrontendViewsOfGroup(trainGroupsGrouping) is not in
 * componentProps.acceptTypes if the latter is defined
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainGroupsContainer = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<CemitFilterTrainGroupsContainerProps>): JSX.Element => {
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc');

  const trainGroups: Perhaps<TrainGroup[]> =
    trainProps.trainGroupSingleTrainRunProps?.crudTrainGroups?.list;

  // Create a memoized shallow copy for the table, since the table will change the list order
  const tableTrainGroups: TrainGroup[] = useMemo(() => {
    return trainGroups ? [...trainGroups] : [];
  }, [trainGroups]);

  const isDetailsViewActive = isViewActive(appProps, FrontendView.detailTrainFormation);

  const [page, setPage] = [appProps.trainGroupTablePage, appProps.setTrainGroupTablePage];
  const [rowsPerPage, setRowsPerPage] = [
    appProps.trainGroupTableRowsPerPage,
    appProps.setTrainGroupTableRowsPerPage,
  ];

  const handleSelectAllClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.target.checked) {
      const allTrainGroups = selectAndActivateTrainGroups(trainGroups);
      trainProps.trainGroupSingleTrainRunProps.crudTrainGroups.updateOrCreateAll(
        allTrainGroups,
      );
      return;
    }
  };

  // Added the clicked TrainGroup to selected or remove
  const handleClickTableRow = (chosenTrainGroup: TrainGroup) => {
    const activeTrainGroup = head(
      trainProps.trainGroupActivityProps?.activeTrainGroups || [],
    );
    const updatedTrainGroups: TrainGroup[] = map(
      (trainGroup: TrainGroup) =>
        ifElse(
          (trainGroup: TrainGroup): boolean => {
            // Activate the click item unless already active, in which case deactivate
            return (
              idsEqual(trainGroup, chosenTrainGroup) &&
              !idsEqual(activeTrainGroup, chosenTrainGroup)
            );
          },
          (trainGroup: TrainGroup): TrainGroup => {
            return selectAndActivateTrainGroup(trainGroup);
          },
          (trainGroup: TrainGroup): TrainGroup => {
            return deselectAndDeActivateTrainGroup(trainGroup);
          },
        )(trainGroup),
      tableTrainGroups,
    );

    trainProps.trainGroupSingleTrainRunProps.crudTrainGroups.updateOrCreateAll(
      updatedTrainGroups,
    );
  };

  const onPageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | undefined,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event: React.MouseEvent<HTMLTableRowElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = useCallback((trainGroup: TrainGroup): boolean => {
    return trainGroup.activity?.isActive || false;
  }, []);

  // Change Infinity to the length of the TrainGroups
  const rowsPerPageOrAll = rowsPerPage == Infinity ? length(trainGroups) : rowsPerPage;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPageOrAll - trainGroups.length) : 0;

  const pageCount: number = Math.ceil(length(trainGroups) / (rowsPerPageOrAll || 1));

  // The TrainGroups that show in the table, list, or menu
  // TODO used to be sliceable
  const visibleRows: TrainGroup[] = React.useMemo((): TrainGroup[] => {
    return tableTrainGroups;
  }, [tableTrainGroups, sortDirection]);

  const activeFrontendView =
    componentProps.forcedActiveFrontendView ||
    head(appProps.activeFrontendViewsOfGroup(trainGroupsGrouping));

  const serviceLineIdToColor = useMemo(() => {
    return fromPairs(
      map((serviceLine: ServiceLine) => {
        return [serviceLine.id, serviceLine.color];
      }, trainProps.serviceLineProps.serviceLines),
    );
  }, [trainProps.serviceLineProps.serviceLines]);

  const childComponentProps = clsOrType(CemitTypename.trainGroupTableProps, {
    handleSelectAllClick,
    handleClickTableRow,
    onPageChange,
    onRowsPerPageChange,
    isSelected,
    emptyRows,
    visibleRows,
    sortDirection,
    selected: trainProps.trainGroupActivityProps.activeTrainGroups,
    page,
    rowsPerPageOrAll,
    isDetailsViewActive,
    trainGroups: tableTrainGroups,
    pageCount,
    serviceLineIdToColor,
  });

  const trainGroupMenuProps = clsOrType<TrainGroupMenuProps>(
    CemitTypename.trainGroupMenuProps,
    {
      handleClickMenuItem: (clickedTrainGroup: TrainGroup) => {
        return handleClickTableRow(clickedTrainGroup);
      },
      isSelected,
      trainGroups: visibleRows,
      timezoneStr: organizationProps.organization.timezoneStr,
      serviceLineIdToColor,
      showColorPicker: true,
      updateTrainGroupAfterColorChange: (trainGroup: TrainGroup) => {
        trainProps.trainGroupSingleTrainRunProps.crudTrainGroups.updateOrCreate(
          trainGroup,
        );
      },
    },
    [visibleRows, organizationProps.organization.timezoneStr, isSelected],
  );

  // Return the active view or trainGroupsGrouping
  return cond([
    [
      equals(FrontendView.trainGroupMenu),
      () => {
        return componentProps.acceptTypes &&
          !componentProps.acceptTypes.includes(FrontendViewType.compact) ? undefined : (
          <CemitFilterTrainGroupsViewMenu
            key="compact"
            {...{
              trainProps,
              componentProps: trainGroupMenuProps,
            }}
          />
        );
      },
    ],
    [
      equals(FrontendView.trainGroupList),
      () => {
        return componentProps.acceptTypes &&
          !componentProps.acceptTypes.includes(FrontendViewType.large) ? undefined : (
          <CemitFilterTrainGroupsViewList
            key="large"
            {...{
              appProps,
              trainProps,
              organizationProps,
              componentProps: childComponentProps,
            }}
          />
        );
      },
    ],
    [
      T,
      (frontendView: FrontendView) => {
        throw new Error(`Unexpected frontendView: ${frontendView}`);
      },
    ],
  ])(activeFrontendView);
};
export default CemitFilterTrainGroupsContainer;
// export default memo(CemitFilterTrainGroupsContainer);
