import {shallowEquals} from 'utils/functional/functionalUtils.ts';
import {mergeDeep, pickDeepPaths} from '@rescapes/ramda';
import {
  all,
  always,
  both,
  complement,
  cond,
  equals,
  indexBy,
  is,
  map,
  prop,
  propOr,
  T,
} from 'ramda';

import {consolidateIntervals} from 'utils/ranges/rangeUtils.ts';
import {mergeTrainFormation} from './trainFormationMerging.ts';
import {mergeTrainRouteOrGroup} from './trainRouteOrGroupMerging.ts';
import {mergeDistanceIntervalAndRanges} from '../../cemitAppUtils/cemitAppTypeMerging/distanceResolutionAndRangesMerging.ts';
import {mergeTrainRuns} from './trainRunMerging.ts';
import {
  mergeDeepExistingAndIncoming,
  mergeWithKeyExistingAndIncoming,
} from 'appUtils/typeUtils/mergeTypeUtils.ts';
import {mergeSensorDataPoints} from './sensorDataPointMerging.ts';
import {TrainGroup, TrainGroupMinimized} from 'types/trainGroups/trainGroup';
import {mergeTrainGroupActivity} from 'classes/typeCrud/trainGroupActivityCrud.ts';

// These are currently the only properties that the user can set on a TrainGroup
export const trainGroupUserSettablePropPaths = ['trainFormation.alertStatus', 'activity'];
/**
 *
 *
 * Updates the SensorDataPoints for the TrainGroup, sorting existing with incoming
 * @param existingTrainGroup
 * @param incomingTrainGroup
 * @returns {*}
 */
export const mergeTrainGroup = <
  E extends TrainGroupMinimized,
  I extends TrainGroupMinimized = E,
>(
  existingTrainGroup: E,
  incomingTrainGroup: Partial<I>,
): E & I => {
  return mergeWithKeyExistingAndIncoming(
    (key: keyof TrainGroup, existingPropValue: any, incomingPropValue: any) => {
      return cond([
        // If references equal, just take the incoming prop value
        [
          shallowEquals,
          (_a: any, b: any) => {
            return b;
          },
        ],
        [always(equals('trainRouteOrGroup', key)), mergeTrainRouteOrGroup],
        [always(equals('trainDistanceInterval', key)), mergeDistanceIntervalAndRanges],
        [always(equals('trainFormation', key)), mergeTrainFormation],
        [always(equals('trainRuns', key)), mergeTrainRuns],
        [always(equals('sensorDataDateIntervals', key)), consolidateIntervals],
        [
          always(equals('distanceResolutionsAndRanges', key)),
          mergeDistanceIntervalAndRanges,
        ],
        [always(equals('activity', key)), mergeTrainGroupActivity],
        [always(equals('sensorDataPoints', key)), mergeSensorDataPoints],
        // Default to mergeDeep for other attributes
        [
          T,
          (a: any, b: any) => {
            // TODO we should never mergeDeep anything. It risks being very expensive
            const merged = all(both(complement(Array.isArray), is(Object)), [a, b])
              ? mergeDeep(a, b)
              : b;
            return merged;
          },
        ],
      ])(existingPropValue, incomingPropValue);
    },
    existingTrainGroup,
    incomingTrainGroup,
  );
};

/**
 * Restore the TrainGroup by matching it with a TrainRun
 * @param filteredTrainGroups The list of TrainGroups that are the result of the current filter settings.
 * @param cachedTrainGroups Cached TrainGroups that have {activity: {isActive: true}} or other other
 * settable props. Matching cachedTrainGroups get merged into the corresponding filteredTrainGroups
 * and the copied and modified filteredTrainGroups is returned.
 * @returns The filterTrainGroups, maybe with cachedTrainGroups merged in
 */
export const mergeCachedTrainRunsIntoTrainGroups = <T extends TrainGroupMinimized>(
  filteredTrainGroups: T[],
  cachedTrainGroups: T[],
): T[] => {
  const cachedTrainGroupsById = indexBy(prop('id'), cachedTrainGroups);
  return map((filteredTrainGroup: TrainGroup) => {
    return mergeTrainGroup(
      filteredTrainGroup,
      // Only allow merging in prop paths defined in trainGroupUserSettablePropPaths
      // These are the only props that the user can set
      pickDeepPaths(
        trainGroupUserSettablePropPaths,
        propOr({}, filteredTrainGroup.id, cachedTrainGroupsById),
      ),
    );
  }, filteredTrainGroups);
};
