import React from 'react';
import {Button} from '@mui/material';
import {styled} from '@mui/styles';

const StyledPrimaryButton = styled(Button)(({theme, size, variant}) => {
  const baseStyle = {
    padding: size === 'small' ? '2px 10px' : size === 'large' ? '8px 20px' : '6px 15px',
    fontSize: size === 'small' ? '0.75rem' : size === 'large' ? '1rem' : '0.875rem',
    textTransform: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
  };

  if (variant === 'outlined') {
    return {
      ...baseStyle,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: 'transparent',
    };
  }

  return {
    ...baseStyle,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.greyDark,
  };
});

const PrimaryButton = ({size = 'medium', variant = 'contained', ...props}) => {
  return <StyledPrimaryButton size={size} variant={variant} {...props} />;
};

export default PrimaryButton;
