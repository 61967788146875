import {RideComfortAlertLevels} from 'types/alerts/rideComfort/rideComfortAlertLevels';
import {TFunction} from 'i18next';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';

/**
 * The API marks the alert level with these numbers
 * From graphql: element?.[1]?.node?.metadata?.['s-value'];
 */
export enum RideComfortAttributeAlertSValue {
  // Values from <0.5 are normal/good/ok
  none = 0,
  // Values from >=0.5 to <1.5
  warning = 0.5,
  // Values from >=1.5
  critical = 1.5,
}

/**
 * Maps the levels to the corresponding RideComfortAttributeAlertLevel
 */
export const rideComfortLevelToAttribute: RideComfortAlertLevels = {
  L0: RideComfortAttributeAlertLevel.normal,
  L1: RideComfortAttributeAlertLevel.warning,
  L2: RideComfortAttributeAlertLevel.critical,
  L3: RideComfortAttributeAlertLevel.critical,
} as RideComfortAlertLevels;

export const rideComfortAlertLevelToLabel = (
  t: TFunction,
): Record<keyof RideComfortAlertLevels, string> => {
  return {
    L0: t('green'),
    L1: t('yellow'),
    L2: t('red'),
    L3: t('urgent'),
  } as Record<keyof RideComfortAlertLevels, string>;
};
