import {
  CemitApi,
  CemitApiAttributeTimeSeriesRoute,
  CemitApiAvailableDatesRoute,
  CemitApiOrganizationRoute,
  CemitApiRailwayLinesRoute,
  CemitApiRequest,
  CemitApiServiceLinesRoute,
  CemitApiTrainFormationsRoute,
  CemitApiTrainGroupAlertsRoute,
  CemitApiTrainGroupDetailsRoute,
  CemitApiTrainRouteGroupsRoute,
  CemitApiTrainRoutesRoute,
  CemitApiTrainRunsRoute,
  CemitApiVisionNearbyPictures,
} from 'types/apis/cemitApi';
import {
  trainApiAvailableDatesResponseResolver,
  trainApiAvailableDatesUrl,
  trainApiDetailsTrainRoutesResponseResolver,
  trainApiMinimizedTrainRoutesResponseResolver,
  trainApiOrganizationsAdminOnlyResponseResolver,
  trainApiOrganizationsResponseResolver,
  trainApiRailwayLinesResponseResolver,
  trainApiRailwayLinesUrl,
  trainApiServiceLinesResponseResolver,
  trainApiServiceLinesUrl,
  trainApiTrainFormationsResponseResolver,
  trainApiTrainRouteGroupsResponseResolver,
  trainApiTrainRouteGroupsUrl,
  trainApiTrainRoutesUrl,
  trainApiTrainRunsResponseResolver,
  trainApiTrainRunsUrl,
} from './trainApi.ts';
import {OrganizationLoaded} from 'types/organizations/organization.ts';
import {RequiredDeep} from 'types/typeHelpers/deepRequired';
import {
  always,
  and,
  concat,
  identity,
  ifElse,
  join,
  length,
  map,
  prop,
  sortBy,
  unless,
} from 'ramda';
import {ServiceLine} from 'types/trainRouteGroups/serviceLine';
import {
  trainDateParams,
  trainDateRecurrenceParams,
  trainRunTrainRouteParams,
} from './trainApiTrainRunUtils.ts';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {compactEmpty} from '@rescapes/ramda';
import {
  TrainApiTrainRunRequestParams,
  TrainApiTrainRunsRequestProps,
} from 'types/apis/trainApi';
import {TrainFormation} from 'types/trains/trainFormation';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainApiTrainRunRequestProps} from 'types/trainRuns/trainRun';
import {TrainRoute} from 'types/trainRouteGroups/trainRoute';
import {
  trainApiOrganizationUrl,
  trainApiTrainFormationsRequestResolver,
} from './trainApiUtils.ts';
import {typeObject} from 'appUtils/typeUtils/typeObject.ts';
import {
  compact,
  hasNonZeroLength,
  mergeRightIfDefined,
} from 'utils/functional/functionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

import {
  trainApiVisionNearbyPicturesResponseResolver,
  trainApiVisionRequestResolver,
} from 'apis/cemitApis/trainApi/visionApiResolvers.ts';

export const ERROR_NO_SENSOR_DATA = 'noSensorData';

// DistanceRange start/end distances are round to .02 kilometers to prevent comparison errors
export const DISTANCE_RANGE_PRECISION = 2;

// API statuses
export const statusProps = ['error', 'errorDate', 'retry', 'status', 'loading'];

export const trainApi: CemitApi = {
  baseUrl: process.env.TRAIN_APP_API as string,
  basePath: 'api',
  version: 2,
  slugifyRequestParams: false,
  // Responses from trainApi are camelized already
  camelizeResponse: false,
  routes: {
    organization: typeObject<CemitApiOrganizationRoute>(
      CemitTypename.cemitApiOrganizationRoute,
      {
        routePath: 'organizations',
        request: {
          // RailwayLines expects only the operator id from the organization
          paramsFilter: ({
            organization: {sourceKey},
          }: RequiredDeep<{
            organization: OrganizationLoaded;
          }>) => ({sourceKey}),
        },

        urlResolver: trainApiOrganizationUrl,
        responseResolver: trainApiOrganizationsResponseResolver,
      },
    ),
    organizationAdminOnly: typeObject<CemitApiOrganizationRoute>(
      CemitTypename.cemitApiOrganizationRoute,
      {
        routePath: 'organizations/adminOnlyAllOrganizations',
        request: {},

        urlResolver: trainApiOrganizationUrl,
        responseResolver: trainApiOrganizationsAdminOnlyResponseResolver,
      },
    ),
    availableDateRanges: typeObject<CemitApiAvailableDatesRoute>(
      CemitTypename.cemitApiAvailableDatesRoute,
      {
        routePath: 'trainRuns/withFormationsAvailableDateRanges',
        request: {
          /***
           Request parameters by TrainRoute or TrainRouteGroup and/or by TrainFormation
           ***/
          paramsFilter: ({
            trainRouteOrGroup,
            trainFormation,
            organization,
          }: {
            trainRouteOrGroup: TrainRouteOrGroup;
            trainFormation: TrainFormation;
            organization: OrganizationLoaded;
          }) => {
            return trainRouteOrGroup || trainFormation
              ? mergeRightIfDefined(
                  trainRouteOrGroup ? trainRunTrainRouteParams(trainRouteOrGroup) : {},
                  trainFormation ? {trainFormationId: trainFormation.id} : {},
                )
              : {
                  // The organization is only needed when no other params are given
                  operatorId: organization.operator.id,
                };
          },
        },
        urlResolver: trainApiAvailableDatesUrl,
        responseResolver: trainApiAvailableDatesResponseResolver,
      },
    ),
    serviceLines: typeObject<CemitApiServiceLinesRoute>(
      CemitTypename.cemitApiServiceLinesRoute,
      {
        routePath: 'serviceLines',
        request: {
          // ServiceLines expects only the operator id from the organization
          paramsFilter: ({
            organization: {
              operator: {id},
            },
          }: {
            organization: OrganizationLoaded;
          }) => ({operatorId: id}),
        },
        urlResolver: trainApiServiceLinesUrl,
        responseResolver: trainApiServiceLinesResponseResolver,
      },
    ),
    railwayLines: typeObject<CemitApiRailwayLinesRoute>(
      CemitTypename.cemitApiRailwayLinesRoute,
      {
        routePath: 'railwayLines',
        paths: {
          detail: 'detail',
        },
        request: {
          // RailwayLines expects only the id_in_
          paramsFilter: ({id_in_}: {id_in_: string[]}) => {
            return {
              id_in_: join(',', id_in_),
            };
          },
        },
        urlResolver: trainApiRailwayLinesUrl,
        responseResolver: trainApiRailwayLinesResponseResolver,
      },
    ),
    trainRouteGroups: typeObject<CemitApiTrainRouteGroupsRoute>(
      CemitTypename.cemitApiTrainRouteGroupsRoute,
      {
        routePath: 'trainRouteGroups',
        request: {
          // TrainRouteGroups expects only the operator id from the organization
          paramsFilter: ({
            organization: {
              operator: {id},
            },
          }: RequiredDeep<{
            organization: OrganizationLoaded;
          }>) => ({operatorId: id}),
        },
        urlResolver: trainApiTrainRouteGroupsUrl,
        responseResolver: trainApiTrainRouteGroupsResponseResolver,
      },
    ),
    trainRoutes: typeObject<CemitApiTrainRoutesRoute>(
      CemitTypename.cemitApiTrainRoutesRoute,
      {
        routePath: 'trainRoutes',
        request: {
          // TrainRoutes expects only the operator id from the organization
          paramsFilter: ({serviceLines}: {serviceLines: ServiceLine[]}) => {
            if (!length(serviceLines)) {
              return undefined;
            }
            return {
              serviceLineId_in_: join(',', map(prop('id'), serviceLines)),
            };
          },
        },
        urlResolver: trainApiTrainRoutesUrl,
        responseResolver: trainApiMinimizedTrainRoutesResponseResolver,
      },
    ),
    detailTrainRoutes: typeObject<CemitApiTrainRoutesRoute>(
      CemitTypename.cemitApiTrainRoutesRoute,
      {
        routePath: 'trainRoutes/detail',
        request: {
          // TrainRoutes expects only the operator id from the organization
          paramsFilter: ({trainRoutes}: {trainRoutes: TrainRoute[]}) => {
            if (!length(trainRoutes)) {
              return undefined;
            }
            const ids = sortBy(identity, map(prop('id'), trainRoutes));
            return {id_in_: join(',', ids)};
          },
        },
        urlResolver: trainApiTrainRoutesUrl,
        responseResolver: trainApiDetailsTrainRoutesResponseResolver,
      },
    ),
    trainRuns: typeObject<CemitApiTrainRunsRoute>(CemitTypename.cemitApiTrainRunsRoute, {
      routePath: 'trainRuns',
      paths: {
        // For TrainGroupOnlySingleTrainRun
        withSensorDataPoints: 'withSensorDataPoints',
        // For TrainGroupOnlyTrainFormation
        withTrainFormationSensorDataPoints: 'withTrainFormationSensorDataPoints',
      },
      urlResolver: trainApiTrainRunsUrl,
      request: {
        paramsFilter: (
          props: TrainApiTrainRunsRequestProps,
        ): TrainApiTrainRunRequestParams | undefined => {
          // Combine trainGroupIds and trainGroup.id, defaulting to undefined if empty
          // TODO we only query for one TrainGroup id per request for now
          const allTrainGroupIds = unless<string[], undefined>(
            hasNonZeroLength,
            always(undefined),
          )(
            concat(
              (props.trainGroupIds || []) as string[],
              compact([props.sensorDataTrainGroup?.id]) as string[],
            ),
          );

          const {trainRouteOrGroup} = props.trainRouteGroupProps || {};

          const allDateIntervals: DateInterval[] = compact([
            ...(props.dateIntervals || ([] as DateInterval[])),
            props.dateInterval,
          ]);

          if (!length(allDateIntervals) && !allTrainGroupIds) {
            // We must either be limited to a dateInterval or trainGroupIds to proceed
            return undefined;
          }

          const allTrainFormations = compact([
            ...(props.trainFormations || []),
            ...(props.sensorDataTrainGroup?.trainFormations || []),
          ]);
          const trainFormationIds = length(allTrainFormations)
            ? map(prop('id'), allTrainFormations)
            : undefined;
          const dateParams = trainDateParams(allDateIntervals);

          const dateRecurrenceParams = trainDateRecurrenceParams(props.dateRecurrences);
          const trainRouteParams =
            !allTrainGroupIds && trainRouteOrGroup
              ? trainRunTrainRouteParams(trainRouteOrGroup as TrainRouteOrGroup)
              : {};

          return compactEmpty(
            typeObject<TrainApiTrainRunRequestProps>(
              CemitTypename.trainApiTrainRunRequestProps,
              {
                // Limit TrainRuns to the current TrainRoute or TrainRouteGroup's TrainRoutes
                // This is only required if dateInterval is specified. If allTrainGroupIds is, we don't need a trainRoute
                ...trainRouteParams,
                ...dateParams,
                ...dateRecurrenceParams,
                trainFormationId_in_: trainFormationIds,
                // TODO we don't currently allow trainFormationIds and ids,
                // TrainRuns specified by id don't need to specify their TrainFormation id
                id_in_: ifElse<[string[]], string, undefined>(
                  // When allTrainGroupIds is defined and trainFormationIds is not
                  (allTrainGroupIds: Perhaps<string[]>) => {
                    return and(!trainFormationIds, Boolean(allTrainGroupIds));
                  },
                  (allTrainGroupIds: Perhaps<string[]>) => {
                    return join(',', allTrainGroupIds!);
                  },
                  always(undefined),
                )(allTrainGroupIds),
              },
            ),
          );
        },
      } as CemitApiRequest<TrainApiTrainRunsRequestProps, TrainApiTrainRunRequestParams>,
      responseResolver: trainApiTrainRunsResponseResolver,
    }),
    trainFormations: typeObject<CemitApiTrainFormationsRoute>(
      CemitTypename.cemitApiTrainFormationsRoute,
      {
        routePath: 'trainFormations',
        request: {
          // Formations expects only the operator id from the organization
          paramsFilter: ({
            organization: {
              operator: {id},
            },
          }: {
            organization: OrganizationLoaded;
          }): {
            operatorId: string;
          } => {
            return {operatorId: id};
          },
        },
        urlResolver: trainApiTrainFormationsRequestResolver,
        responseResolver: trainApiTrainFormationsResponseResolver,
      },
    ),
    trainGroupDetails: typeObject<CemitApiTrainGroupDetailsRoute>(
      CemitTypename.cemitApiTrainGroupDetailsRoute,
      {
        routePath: () => {
          throw new Error('train-api does not not implement trainGroupDetails');
        },
      },
    ),
    trainGroupAlerts: typeObject<CemitApiTrainGroupAlertsRoute>(
      CemitTypename.cemitApiTrainGroupAlertsRoute,
      {
        routePath: () => {
          throw new Error('train-api does not not implement trainGroupAlerts');
        },
      },
    ),
    wheelAttributeTimeSeries: typeObject<CemitApiAttributeTimeSeriesRoute>(
      CemitTypename.cemitApiAttributeTimeSeriesRoute,
      {
        routePath: () => {
          throw new Error('train-api does not not implement wheelAttributeTimeSeries');
        },
      },
    ),
  },
};
