import {TFunction} from 'i18next';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  AlertTypeKey,
  AlertTypeLabels,
  AlertTypeShortLabels,
} from 'types/alerts/alertTypeKey.ts';
import {
  rideComfortAlertLevelToLabel,
  RideComfortAttributeAlertSValue,
  rideComfortLevelToAttribute,
} from 'types/alerts/rideComfort/rideComfortAlertLevel.ts';
import {rideComfortErrorLevelConfigs} from 'types/alerts/rideComfort/rideComfortErrorLevelConfigs.ts';
import {rideComfortDataThresholds} from 'types/alerts/rideComfort/rideComfortDataThresholds.ts';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';
import {rideComfortTrainGroupIconConfigs} from 'types/alerts/rideComfort/rideComfortTrainGroupIconConfigs.ts';

export const rideComfortRoughRideTrackConfig = (t: TFunction): AlertTypeConfig => {
  return clsOrType<AlertTypeConfig>(CemitTypename.alertTypeConfig, {
    alertTypeKey: AlertTypeKey.roughRideTrackPointId,
    alertLevelToAttribute: rideComfortLevelToAttribute,
    alertLevelToLabel: rideComfortAlertLevelToLabel(t),
    label: t(AlertTypeLabels.roughRideTrackPointId),
    labelShort: t(AlertTypeShortLabels.trackPointId),
    attributeAlertLevelConfigs: rideComfortErrorLevelConfigs(t),
    criticalSValueLevel: RideComfortAttributeAlertSValue.critical,
    dataThresholds: rideComfortDataThresholds(t),
    warningLevels: [RideComfortAttributeAlertLevel.warning],
    criticalLevels: [RideComfortAttributeAlertLevel.critical],
    attributeAlertLevelEnum: RideComfortAttributeAlertLevel,
    defaultVisibleAttributeAlertLevels: [
      RideComfortAttributeAlertLevel.warning,
      RideComfortAttributeAlertLevel.critical,
    ],

    propsResolver: (node) => {
      return {};
    },
    unit: 'GENERIC',
    levelAllValueGreaterThan: 0.5,
  });
};
