import React, {useState} from 'react';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Layers, SelectAll} from '@mui/icons-material';
import CustomTooltip from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomTooltip.tsx';
import SecondaryButton from './buttons/GenericButtons/SecondaryButton/SecondaryButton.tsx';

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(1),
    minWidth: 150,
    backgroundColor: theme.palette.background.surfaceTertiary,
  },
  buttonGroup: {
    float: 'right',
    padding: theme.spacing(1),
  },
  selectAllButton: {
    textTransform: 'none',
  },

  list: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(0),
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItemIcon: {
    minWidth: '20px',
  },
  listItemText: {
    margin: 0,
  },
}));

const LayerSelector = ({layers, onLayerToggle}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedLayers, setSelectedLayers] = useState(layers.map((layer) => layer.id));

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'layer-selector-popover' : undefined;

  const handleSelectAll = () => {
    const newSelectedLayers =
      selectedLayers.length === layers.length ? [] : layers.map((layer) => layer.id);
    setSelectedLayers(newSelectedLayers);
    onLayerToggle(newSelectedLayers);
  };

  const handleChange = (event, layerId) => {
    const newSelectedLayers = selectedLayers.includes(layerId)
      ? selectedLayers.filter((id) => id !== layerId)
      : [...selectedLayers, layerId];
    setSelectedLayers(newSelectedLayers);
    onLayerToggle(newSelectedLayers);
  };

  return (
    <>
      <div>
        <CustomTooltip
          title={'Klicka för att välja eller avmarkera kartlager.'}
          placement="left"
        >
          <Stack
            direction="row"
            spacing={2}
            aria-describedby={id}
            className={classes.buttonGroup}
          >
            <SecondaryButton
              size="small"
              onClick={handleClick}
              variant="contained"
              disableElevation
              endIcon={<Layers />}
            >
              Lager
            </SecondaryButton>
          </Stack>
        </CustomTooltip>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.popover}>
          <Stack direction="row" spacing={2} justifyContent="flex-start">
            <Button
              className={classes.selectAllButton}
              startIcon={<SelectAll />}
              onClick={handleSelectAll}
              size="small"
              variant="outlined"
              disableElevation
            >
              {selectedLayers.length === layers.length ? 'Avmarkera alla' : 'Välj alla'}
            </Button>
          </Stack>
          <List dense className={classes.list}>
            {layers.map((layer) => (
              <ListItem
                key={layer.id}
                className={classes.listItem}
                button
                onClick={() => handleChange(null, layer.id)}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Checkbox
                    edge="start"
                    checked={selectedLayers.includes(layer.id)}
                    tabIndex={-1}
                    disableRipple
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText primary={layer.name} className={classes.listItemText} />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </>
  );
};

export default LayerSelector;
