import React, {SyntheticEvent, useMemo, useState} from 'react';
import {equals, is, map} from 'ramda';
import TrainRouteOrGroupLabel from 'components/apps/trainAppComponents/chooserComponents/trainRouteChooserComponents/TrainRouteOrGroupLabel.tsx';
import {TrainRoute} from '../../../../../types/trainRouteGroups/trainRoute';
import {TrainRouteOrGroup} from '../../../../../types/trainRouteGroups/trainRouteOrGroup';
import {IconButton, Menu, MenuItem, Stack} from '@mui/material';
import {CemitComponentProps} from '../../../../../types/propTypes/cemitComponenProps';
import {Route} from '@mui/icons-material';
import {ServiceLine} from '../../../../../types/trainRouteGroups/serviceLine';
import {Perhaps} from '../../../../../types/typeHelpers/perhaps';
import {serviceLineOfTrainRouteOrGroup} from 'appUtils/trainAppUtils/serviceLineUtils/serviceLineUtils.ts';

export interface TrainRouteMenu extends CemitComponentProps {
  serviceLines: ServiceLine[];
  trainRoutes: TrainRoute[];
  chooseTrainRouteOrGroup: (trainRouteOrGroup: TrainRouteOrGroup) => void;
  trainRouteOrGroup: TrainRouteOrGroup;
}

/**
 * Displays the label for trainRouteOrGroup as a button, representing the chosen TrainRoute or TrainRouteGroup.
 * Clicking the button opens a menu to allow selecting of the other TrainRoute or TrainRouteGroups
 * @param trainRoutes All availalbe TrainRoutes and TrainRouteGroups
 * @param chooseTrainRouteOrGroup Change the current TrainRoute or TrainRouteGroup
 * @param trainRouteOrGroup The currently chosen TrainRoute or TrainRouteGroup
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainRouteMenu = ({
  serviceLines,
  trainRoutes,
  chooseTrainRouteOrGroup,
  trainRouteOrGroup,
}: TrainRouteMenu): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: SyntheticEvent) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (_e: SyntheticEvent, trainRoute: TrainRouteOrGroup) => {
    setAnchorEl(undefined);
    // if trainRoute comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, trainRoute)) {
      chooseTrainRouteOrGroup(trainRoute as TrainRouteOrGroup);
    }
  };

  // Display each TrainRoute or TrainRouteGroup's label
  const trainRouteOrGroupLabels: JSX.Element[] = map(
    (aTrainRouteOrGroup: TrainRouteOrGroup) => {
      const serviceLine = serviceLineOfTrainRouteOrGroup(
        serviceLines,
        aTrainRouteOrGroup,
      );
      return (
        <MenuItem
          key={aTrainRouteOrGroup.id}
          {...{
            disabled: equals(trainRouteOrGroup, aTrainRouteOrGroup),
            onClick: (e: SyntheticEvent) => handleClose(e, aTrainRouteOrGroup),
            sx: {
              borderBottom: '1px solid rgba(255, 255, 255, 0.25)',
            },
          }}
        >
          <TrainRouteOrGroupLabel
            {...{serviceLine, trainRouteOrGroup: aTrainRouteOrGroup}}
          />
        </MenuItem>
      );
    },
    trainRoutes,
  );

  const serviceLine: Perhaps<ServiceLine> = useMemo(() => {
    return serviceLineOfTrainRouteOrGroup(serviceLines, trainRouteOrGroup);
  }, [trainRouteOrGroup, serviceLines]);
  return (
    <Stack
      {...{
        spacing: 1,
        sx: {
          minWidth: 0,
          minHeight: 0,
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          justifyContent: 'left',
        },
      }}
    >
      <IconButton
        key="button"
        {...{
          id: 'train-route-button',
          color: 'secondary',
          'aria-controls': open ? 'formation-positioned-menu' : undefined,
          'aria-haspopup': 'true',
          'aria-expanded': open ? 'true' : undefined,
          onClick: handleClick,
          sx: {
            borderRadius: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <Stack
          {...{
            direction: 'row',
            spacing: 1,
            sx: {
              width: '100%',
              alignItems: 'center',
            },
          }}
        >
          <Route />
          <TrainRouteOrGroupLabel {...{serviceLine, trainRouteOrGroup}} />
        </Stack>
      </IconButton>
      <Menu
        key="menu"
        id="train-route-positioned-menu"
        aria-labelledby="train-route-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {trainRouteOrGroupLabels}
      </Menu>
    </Stack>
  );
};
