import Menu from '@mui/material/Menu/Menu.js';
import React, {SyntheticEvent, useState} from 'react';
import {includes, is, map} from 'ramda';
import {IconButton, MenuItem, Stack, Typography} from '@mui/material';
import {SettingsSuggest} from '@mui/icons-material';
import {CemitComponentProps} from '../../../types/propTypes/cemitComponenProps';
import {
  Organization,
  OrganizationMinimized,
} from '../../../types/organizations/organization.ts';
import {idsEqual} from 'utils/functional/functionalUtils.ts';
import {OrganizationTrainComponentDependencyProps} from 'types/propTypes/trainPropTypes/organizationTrainAppComponentDependencyProps';

export interface OrganizationMenuProps extends CemitComponentProps {
  chosenOrganization: Organization[];
  handleAddOrganizationToFilter: (organization: OrganizationMinimized) => void;
  label: string;
}

/**
 * Component to choose which Organization is active
 * @returns
 */
const OrganizationMenu = ({
  organizationProps,
  componentProps,
}: OrganizationTrainComponentDependencyProps<OrganizationMenuProps>) => {
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (_e: SyntheticEvent, organization: OrganizationMinimized) => {
    setAnchorEl(undefined);
    // if dateRecurrence comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, organization)) {
      componentProps.handleAddOrganizationToFilter(organizationProps, organization);
    }
  };
  // Create a menu of attribute organizations
  const organizationMenuItems: (typeof MenuItem)[] =
    organizationProps.adminOnlyAllOrganizations
      ? map<Organization, typeof MenuItem>((organization: OrganizationMinimized) => {
          return (
            <MenuItem
              key={organization.id}
              {...{
                disabled: idsEqual(organization, componentProps.chosenOrganization),
                onClick: (e: SyntheticEvent) => onClose(e, organization),
              }}
            >
              {organization.name}
            </MenuItem>
          );
        }, organizationProps.adminOnlyAllOrganizations)
      : [];

  return (
    <Stack {...{color: 'secondary', spacing: 1}}>
      <Stack {...{direction: 'row', spacing: 1}}>
        <IconButton
          key="buttonOrganizationMenu"
          id="alert-attribute-button"
          sx={{padding: 0}}
          color="inherit"
          aria-controls={open ? 'alert-attribute-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <SettingsSuggest />
        </IconButton>
        <Typography {...{color: 'inherit'}}> {componentProps.label} </Typography>
      </Stack>
      <Menu
        key="organizationMenu"
        {...{
          id: 'alert-attribute-positioned-menu',
          'aria-labelledby': 'alert-attribute-button',
          anchorEl,
          open,
          onClose,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {organizationMenuItems}
      </Menu>
    </Stack>
  );
};
export default OrganizationMenu;
