import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {DataFeatureCollection} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import {compact} from '@rescapes/ramda';
import {map, length} from 'ramda';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {onlyOneValueOrThrow} from 'utils/functional/functionalUtils.ts';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroup, TrainGroupSet} from 'types/trainGroups/trainGroup';
import {FeatureCollection, Point} from 'geojson';
import {CEMIT_ORANGE} from 'theme/cemitColors.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {Perhaps} from 'types/typeHelpers/perhaps';

import {SensorDataFeatureProps} from 'types/trainRuns/sensorDataFeature';
import {GeojsonDataFeatureSet} from 'types/railways/geojsonDataFeatureSet.ts';
import {useNotLoadingActiveSensorDataEligibleTrainGroups} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';

/**
 * Set setChartDataFeatureSets to DataFeatureSets made from
 * trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors
 * @param loading
 * @param _appProps
 * @param trainProps
 * @param chartDataFeatureSets
 * @param setChartDataFeatureSets
 */
export const useEffectSetChartDataFeatureCollectionsForTrainGroups = (
  loading: boolean,
  _appProps: TrainAppProps,
  trainProps: TrainProps,
  chartDataFeatureSets: Perhaps<GeojsonDataFeatureSet<TrainGroupSet>[][]>,
  setChartDataFeatureSets: StateSetter<Perhaps<GeojsonDataFeatureSet<TrainGroupSet>[][]>>,
) => {
  const trainGroups: TrainGroup[] = useNotLoadingActiveSensorDataEligibleTrainGroups(
    loading,
    trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors,
  );

  // Creates the single DataFeatureSetMinimized, which is used with setChartDataFeatureSets
  // featureCollections can be null when loading or if the trainGroups change
  const createChartDataFeature = (
    featureCollections: Perhaps<FeatureCollection[]> = undefined,
  ) => {
    return clsOrType<GeojsonDataFeatureSet<TrainGroupSet>>(
      CemitTypename.dataFeatureSetMinimized,
      {
        source: clsOrType<TrainGroupSet>(CemitTypename.trainGroupSet, {trainGroups}),
        sourceTypename: CemitTypename.trainGroupSet,
        dataFeatureCollections: featureCollections,
        timePath: 'time',
        distancePath: 'meters',
        railwayDistancePath: 's',
      },
    );
  };

  const dependencies = [trainGroups];
  useNotLoadingEffect(
    loading,
    (trainGroups, _dateInterval) => {
      // Create a DataFeatureCollection for each TrainGroup.
      // Each ChartDataFeatureSet contains the geojson from the TrainGroup needed to render Acceleration, Velocity, and other charts
      const featureCollections: DataFeatureCollection<
        TrainGroup,
        SensorDataFeatureProps
      >[] = compact(
        map((trainGroup: TrainGroup) => {
          // TODO Currently one an only one sensorDataFeatureCollections is expected
          const sensorDataFeatureCollection: FeatureCollection<Point> =
            onlyOneValueOrThrow(trainGroup.sensorDataGeojson!.featureCollections);
          console.debug(
            `SensorData: For trainGroup ${trainGroup.localizedName()}, version ${trainGroup.localUpdateVersion} setting ${length(sensorDataFeatureCollection)} features on the chart`,
          );

          return clsOrType<DataFeatureCollection<TrainGroup, SensorDataFeatureProps>>(
            CemitTypename.dataFeatureCollection,
            {
              isVisible: trainGroup.activity.isVisible,
              color: (dataFeatureSetColor: string) => {
                return (
                  dataFeatureSetColor || trainGroup.activity.isActiveColor || CEMIT_ORANGE
                );
              },
              source: trainGroup,
              // This name must match the dataConfigAcceleration.ts and dataConfigVelocity.ts, etc, dataFeatureCollectionSourceTypename
              sourceTypename: CemitTypename.trainGroup,
              label: (_dataFeatureSetLabel: string) => {
                // TODO cleanup
                return trainGroup.name || trainGroup.trainFormation.name;
              },
              features: sensorDataFeatureCollection,
            },
          );
        }, trainGroups),
      );
      const chartDataFeature = createChartDataFeature(featureCollections);
      // TODO Using a 2-D array matches the way wheelTimeSeriesHooks stored its ChartDataFeatures
      setChartDataFeatureSets([[chartDataFeature]]);
    },
    dependencies,
  );
};
