import {simplyFetchFromGraph} from 'appUtils/alertUtils/graphqlQueryUtils.ts';
import {
  chain,
  compose,
  equals,
  find,
  join,
  keys,
  map,
  prop,
  zipWith,
  length,
  groupBy,
  split,
  head,
  toPairs,
  tail,
  fromPairs,
} from 'ramda';

import {mapMDeep} from '@rescapes/ramda';
import {Duration, formatISO, subMinutes, subMonths, subWeeks} from 'date-fns';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';
import {AlertGraphqlResponseAlertData} from 'types/alerts/alertMapData';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGauge';
import {AlertDuration} from 'types/alerts/alertDuration';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {AlertLevels} from 'types/alerts/alertLevels';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {PeriodEnum} from 'types/alerts/periodEnum.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {TFunction} from 'i18next';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {VehicleCollectionDevice} from 'types/sensors/vehicleCollectionDevice';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {AlertType} from 'types/alerts/alertType';

const stringifyDate = (date: Date) => {
  return formatISO(date);
};

/**
 * Creates several graphql queries that are sent together to the server.
 * The first 3 are aggregations and the second 3 are individual results with lat/lon
 * @param t
 * @param alertTypeConfig
 * @param pointId
 * @param dateIntervalDescription
 * @param dateIntervalDescription.dateInterval We use the duration along with dateInterval.end to make the start and end date
 * If we are query for a TrainRun, we use dateInterval.start and dateInterval.end
 * @param alertGaugeByTimePeriod
 */
export async function getSingleTrainGroupFullAlertGraphqlData(
  t: TFunction,
  alertTypeConfig: AlertTypeConfig,
  pointId: string,
  dateIntervalDescription: DateIntervalDescription,
  alertGaugeByTimePeriod: keyof AlertGaugeByTimePeriod,
): Promise<Perhaps<AlertGraphqlResponseAlertData>> {
  const dateInterval = dateIntervalDescription.dateInterval;
  const levels: (keyof AlertLevels)[] = keys(alertTypeConfig.alertLevelToAttribute);
  const types: (keyof AlertLevels)[] = keys(
    alertTypeConfig.alertLevelToAttributeForTypeKeys ||
      alertTypeConfig.alertLevelToAttribute,
  );

  const {filteredDurations, calculatedDateInterval, fromFunc, windows} =
    getAlertDateIntervals(
      t,
      dateIntervalDescription,
      alertGaugeByTimePeriod,
      dateInterval,
    );
  const fromDate = calculatedDateInterval.start;
  // Only used for the aggregate.
  const fromForAggregateStringified = stringifyDate(fromDate);
  const toStringified = stringifyDate(dateInterval.end);

  const unit = alertTypeConfig.unit;

  const signalAggregations = chain((duration) => {
    return zipWith(
      (level, type) => {
        // Use the interval of from - chosen duration to from or dateInterval.start if defined
        const [fromDate, toDate] = [fromFunc(duration, dateInterval), dateInterval.end];
        const [fromStr, toStr] = map(stringifyDate, [fromDate, toDate]);
        const seconds = (toDate - fromDate) / 1000;
        const windowToUse = find((win) => {
          return win.value > seconds;
        }, windows);

        return `${level}_${duration.label}:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${windowToUse.label}
      }
    ) {
      count
    }`;
      },
      levels,
      types,
    );
  }, filteredDurations);

  const pointsData = `
  query{
    ${join('\n', signalAggregations)}

  levelAll: signals(
    where: {
    pointId: { _EQ:"${pointId}"}
      _AND: [
        { timestamp: { _GTE: "${fromForAggregateStringified}" } }
        { timestamp: { _LTE: "${toStringified}"} }
      ]
      unit:{_EQ:${unit}}
      data: { numericValue: { _GT: ${alertTypeConfig.levelAllValueGreaterThan} }}
     }

    paginate: { last: 400 }
  ) {
    edges {
      node {
        timestamp
        type
        data {
          rawValue
        }
        metadata
        location {
          lat
          lon
        }
      }
    }
  }
}
  `;
  try {
    const {data} = await simplyFetchFromGraph({
      query: pointsData,
    });
    // Create AlertGraphqlResponseAlertData, backreferencing the alertTypeConfig used for the query
    return clsOrType<AlertGraphqlResponseAlertData>(
      CemitTypename.alertGraphqlResponseAlertData,
      {
        data,
        alertTypeConfig,
      },
    );
  } catch (error) {
    return undefined;
  }
}

/**
 * Creates several graphql queries that are sent together to the server.
 * A queries are number AlertType * number of AlertLevels per AlertType * number of TrainGroup
 * These queries don't return positions, just counts of each alert
 * @param t
 * @param alertScopeProps
 */
export async function getAllTrainGroupsSummaryAlertGraphqlData(
  t: TFunction,
  alertScopeProps: AlertScopeProps[],
): Promise<Perhaps<AlertGraphqlResponseAlertData>> {
  const {alertTypeConfigs} = alertScopeProps.alertConfigProps;

  const signalAggregations: string[] = map(
    (alertTypeConfig: AlertTypeConfig): string[] => {
      const trainFormationCollectionDevices: TrainFormationCollectionDevice[] =
        alertScopeProps.scopedTrainGroup.trainGroupCollectionDevices;
      if (!length(trainFormationCollectionDevices || [])) {
        throw new Error('trainFormationCollectionDevices is undefined or empty');
      }
      const vehicleCollectionDevices: VehicleCollectionDevice[] = map(
        prop('vehicleCollectionDevice'),
        trainFormationCollectionDevices,
      );
      // TODO We are not handling TrainFormations with multiple sensors.
      // We are just using the first
      const vehicleCollectionDevice: VehicleCollectionDevice = findOrThrow(
        compose(Boolean, prop('alertPointId')),
        vehicleCollectionDevices,
      );

      // Get all alertTypeConfigs
      const {alertGaugeTimePeriod, alertTypeConfigs} = alertScopeProps.alertConfigProps;

      // TODO We currently only care about daily totals for getAllTrainGroupsSummaryAlertGraphqlData
      const limitedAlertGaugeTimePeriod = alertGaugeTimePeriod;

      // The AlertIntervalDescription specified by the user for TrainGroupFormationOnly or
      // the AlertIntervalDescription based on the TrainGroup for TrainGroupSingleTrainRun
      const {dateIntervalDescription} = alertScopeProps.alertTrainGroupProps;

      // Perform the query
      const pointId = vehicleCollectionDevice[alertTypeConfig.alertTypeKey];

      const dateInterval = dateIntervalDescription.dateInterval;
      const levels: (keyof AlertLevels)[] = keys(alertTypeConfig.alertLevelToAttribute);
      const types: (keyof AlertLevels)[] = keys(
        alertTypeConfig.alertLevelToAttributeForTypeKeys ||
          alertTypeConfig.alertLevelToAttribute,
      );

      // TODO The calculatedDateInterval is based on the chosen map view, which is confusing
      // but calculatedDateInterval is only used for the aggregate query for heatmap dat
      const {filteredDurations, calculatedDateInterval, fromFunc, windows} =
        getAlertDateIntervals(
          t,
          dateIntervalDescription,
          limitedAlertGaugeTimePeriod,
          dateInterval,
          true,
        );
      const unit = alertTypeConfig.unit;

      const signalAggregations = chain((duration) => {
        return zipWith(
          (level, type) => {
            // Use the interval of from - chosen duration to from or dateInterval.start if defined
            const [fromDate, toDate] = [
              fromFunc(duration, dateInterval),
              dateInterval.end,
            ];
            const [fromStr, toStr] = map(stringifyDate, [fromDate, toDate]);
            const seconds = (toDate - fromDate) / 1000;
            const windowToUse = find((win) => {
              return win.value > seconds;
            }, windows);

            return `${alertTypeConfig.labelShort}_${level}_${duration.label}:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${windowToUse.label}
      }
    ) {
      count
    }`;
          },
          levels,
          types,
        );
      }, filteredDurations);

      return signalAggregations;
    },
    alertTypeConfigs,
  );

  const pointsData = `
  query{
    ${join('\n', signalAggregations)}
}
  `;
  try {
    const {data} = await simplyFetchFromGraph({
      query: pointsData,
    });

    // Create each AlertGraphqlResponseAlertData, backreferencing the alertTypeConfig used for the query
    const _dataByAlertType = groupBy(
      ([key, value]: [string, any]) => head(split('_', key)),
      toPairs(data),
    );
    // Operate on the lists of each object, removing the key from the pair
    const dataByAlertType = map(
      fromPairs,
      mapMDeep(
        2,
        ([key, value]: [string, any]) => {
          return [join('_', tail(split('_', key))), value];
        },
        _dataByAlertType,
      ),
    );

    return clsOrTypes<AlertGraphqlResponseAlertData>(
      CemitTypename.alertGraphqlResponseAlertData,
      map((alertTypeConfig: AlertTypeConfig) => {
        const data = dataByAlertType[alertTypeConfig.labelShort];
        return {
          data,
          alertTypeConfig,
        };
      }, alertTypeConfigs),
    );
  } catch (error) {
    return undefined;
  }
}

/**
 * Creates the query for all alert time periods but with the date interval based on alertTimePeriod
 * If onlyUseChosenAlertTimePeriod is true, only use that time period
 * TODO Clarify
 * @param t
 * @param dateIntervalDescription
 * @param alertTimePeriod
 * @param dateInterval
 * @param onlyUseChosenAlertTimePeriod
 */
export const getAlertDateIntervals = (
  t: TFunction,
  dateIntervalDescription: DateIntervalDescription,
  alertTimePeriod: keyof AlertGaugeByTimePeriod,
  dateInterval: DateInterval,
  onlyUseChosenAlertTimePeriod: boolean = false,
) => {
  // These are only used if dateInterval.start is undefined
  // If dateInterval.start is defined, we use that for the duration along with dateInterval.end
  const durations: AlertDuration[] = [
    {
      label: t(PeriodEnum.today),
      key: PeriodEnum.today,
      func: (date) => {
        // Today uses interval.value minutes to get the minutes up to the chosen DateTime
        return dateIntervalDescription.duration
          ? subMinutes(date, dateIntervalDescription.duration)
          : undefined;
      },
    } as AlertDuration,
    {
      label: t(PeriodEnum.week),
      key: PeriodEnum.week,
      func: (date) => {
        // Ignore interval and get the last week up to the chosen DateTime
        return subWeeks(date, 1);
      },
    } as AlertDuration,
    {
      label: t(PeriodEnum.month),
      key: PeriodEnum.month,
      func: (date) => {
        // Ignore interval and get the last month up to the chosen DateTime
        return subMonths(date, 1);
      },
    } as AlertDuration,
  ];
  const filteredDurations =
    dateIntervalDescription.label == 'Short' ? [durations[0]] : durations;

  // MINUTE_1 up to 24 hours, HOUR_1 up to 7 days, DAY_1 over that
  const windows = [
    {label: 'MINUTE_1', value: 60 * 60 * 24}, // For up to a day
    {label: 'HOUR_1', value: 60 * 60 * 24 * 7}, // For up to 7 days
    {label: 'DAY_1', value: 60 * 60 * 24 * 365}, // For all else
  ];

  const chosenAlertTimePeriod: AlertDuration = findOrThrow<Duration>(
    (filteredDuration: AlertDuration): AlertDuration => {
      return equals(filteredDuration.key, alertTimePeriod);
    },
    filteredDurations,
  );

  // If  duration.func(dateInterval.end) is undefined, we have a TrainRun so use dateInterval.start
  const fromFunc = (duration: AlertDuration, dateInterval: DateInterval): Date =>
    duration.func(dateInterval.end) || dateInterval.start;
  const fromDate = fromFunc(chosenAlertTimePeriod, dateInterval);
  const toDate = dateInterval.end;

  return {
    filteredDurations: onlyUseChosenAlertTimePeriod
      ? [chosenAlertTimePeriod]
      : filteredDurations,
    calculatedDateInterval: clsOrType<DateInterval>(CemitTypename.dateInterval, {
      start: fromDate,
      end: toDate,
    }),
    fromFunc,
    windows,
  };
};
