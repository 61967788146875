import {Dispatch, SetStateAction, useEffect} from 'react';
import useLocalStorageWithEvents from './useLocalStorageWithEvents.ts';
import {useSearchParams} from 'react-router-dom';

export type TokenType = string | undefined;
export type SetTokenType = Dispatch<SetStateAction<string | undefined>>;

/**
 * Hook that provides access to token value and a way to set a new token.
 */
export function useToken(
  initialValue: TokenType = undefined,
): [TokenType, SetTokenType] {
  // Parse the token from the URL or failing that the LocalStorage value stored in appProps.token
  if (process.env.REACT_APP_FORCED_TOKEN !== undefined) {
    const token = process.env.REACT_APP_FORCED_TOKEN;
    // Dummy function as the token is hardcoded
    const setToken: SetTokenType = (value: TokenType) => {};
    return [token, setToken];
  } else {
    const [token, setToken] = useLocalStorageWithEvents<TokenType>(
      'token',
      initialValue,
    );
    const [searchParams, setSearchParams] = useSearchParams();
    let queryToken: string | null = null;
    if (searchParams.has('token')) {
      queryToken = searchParams.get('token');
    }

    // If the query contains a token parameter store it in local storage and then redirect to a url without it
    useEffect(() => {
      if (queryToken) {
        if (queryToken !== token) {
          setToken(queryToken);
        }
        searchParams.delete('token');
        setSearchParams(searchParams);
      }
    }, [queryToken, token]);

    return [queryToken ?? token, setToken];
  }
}
