import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {RealtimeTrainScopeProps} from 'types/realtimeTrain/realtimeTrainScopeProps';

/**
 * Returns true if either the incoming value realtimeTrainScopeProps.realtimeTrainGroupProps.realtimeTrainGraphqlStatus
 * is not complete
 * @param realtimeTrainScopeProps
 */
export const realtimeTrainIncomingOrExistingStatusIsIncomplete = (
  realtimeTrainScopeProps: RealtimeTrainScopeProps,
) => {
  return (
    realtimeTrainScopeProps.realtimeTrainGroupProps.realtimeTrainGraphqlStatus !=
    LoadingStatusEnum.complete
  );
};
