import {
  all,
  ascend,
  cond,
  descend,
  equals,
  identity,
  includes,
  init,
  last,
  length,
  map,
  prop,
  propOr,
  reduce,
  sortWith,
  T,
  uniqBy,
} from 'ramda';
import {distanceRangeUnion} from '../distance/distanceUtils.ts';
import {DistanceRange} from '../../types/distances/distanceRange';
import {onlyOneValueOrThrow} from '../functional/functionalUtils.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {dateIntervalUnion} from '../datetime/dateUtils.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {clsOrTypes} from '../../appUtils/typeUtils/clsOrTypes.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Given existing ranges and new ranges of a type with a start and end, where the latter might be interspersed with the former,
 * sort them by start property and if those are equal by lowest end property
 * @param existingRanges
 * @param newRanges
 * @returns T[]
 */
export const consolidateIntervals = <T extends DistanceRange | DateInterval>(
  existingRanges: Perhaps<T[]>,
  newRanges: Perhaps<T[]>,
): T[] | never => {
  const ranges = [...(existingRanges || []), ...(newRanges || [])];
  if (!length(ranges)) {
    return [] as T[];
  }
  if (!all(propOr(false, '__typename'), ranges)) {
    throw Error('Existing and incoming ranges must have a __typename on each instance');
  }
  const typename = onlyOneValueOrThrow(
    uniqBy(identity, map(prop('__typename'), ranges)),
  ) as CemitTypename.distanceRange | CemitTypename.dateInterval;
  // These functions only differ in that dateIntervalUnion preserves the Date type
  const unionFunction: (existing: T, incoming: T) => T[] = cond([
    [equals(CemitTypename.distanceRange), () => distanceRangeUnion],
    [
      (typename: CemitTypename) =>
        includes(typename, [
          CemitTypename.dateInterval,
          CemitTypename.sensorDataDateInterval,
        ]),
      () => dateIntervalUnion,
    ],
    [
      T,
      () => {
        throw new Error(`Unexpected typename ${typename}`);
      },
    ],
  ])(typename);

  const sortedRanges = sortWith([ascend(prop('start')), descend(prop('end'))])([
    ...(existingRanges || []),
    ...(newRanges || []),
  ]);

  return reduce(
    (accum: T[], range: T) => {
      const previousRange = last(accum);
      if (!previousRange) {
        // First iteration, return the range
        return [clsOrType<T>(typename, range)];
      } else {
        // Otherwise concat the first of accum with the union of the last of accum and range.
        // rangeUnion will produce 1 range if they overlap and two if they don't
        return clsOrTypes<T>(typename, [
          ...init(accum),
          ...unionFunction(last(accum), range),
        ]);
      }
    },
    [],
    sortedRanges,
  );
};
