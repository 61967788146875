import {SvgProps} from './SvgProps.ts';
import React from 'react';

// <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
export const TrafficSimIcon = ({stroke = '#FFFFFF', fill = '#000000'}: SvgProps) => {
  return (
    <svg
      fill={fill}
      height="40px"
      width="40px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <polygon
        color={stroke}
        points="439.6,0 204.9,0 55.4,256 204.9,256 76.9,512 418.2,192 247.5,192 "
      />
    </svg>
  );
};
