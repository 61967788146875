import {Cemited} from "../../types/cemited";
import {CemitTypename} from "../../types/cemitTypename.ts";

export abstract class CemitedClass implements Cemited {
    __typename: CemitTypename;

    protected constructor(obj: Cemited) {
        this.__typename = obj.__typename;
    }
}
