export const SUBSCRIPTION_QUERY = `
  subscription TrainAlert($spaceId: ID ) {
    signalAdded(where: { spaceId: $spaceId }) {
      timestamp
      type
      data {
        numericValue
      }
      point {
        externalId
        name
        space {
          name
        }
      }
      location {
        lon
        lat
      }
    }
  }
`;
