import {
  SingleTrainGroupTrainProps,
  TrainProps,
} from 'types/propTypes/trainPropTypes/trainProps';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  castIfEqualsCemitType,
  implementsCemitTypeViaClass,
} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {TrainGroupSingleProps} from 'types/propTypes/trainPropTypes/trainGroupSingleTrainRunProps';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * Returns the filteredTrainGroup in scope, scopedFilteredTrainGroup(trainProps)
 * @param trainProps
 */
export const scopedFilteredTrainGroup = (
  trainProps: SingleTrainGroupTrainProps,
): TrainGroup => {
  return trainProps.filteredTrainGroupProps.filteredTrainGroup;
};

/**
 * Returns the single TrainGroup in the scope of SingleTrainGroupTrainProps
 * @param trainProps
 */
export const scopedTrainGroup = (trainProps: SingleTrainGroupTrainProps): TrainGroup => {
  return trainProps.trainGroupSingleTrainRunProps.trainGroup;
};

/**
 * Gets the narrowest scope defined in trainProps in terms of a
 * TrainRouteOrGroup -> TrainGroup hierarchy.
 * @param trainProps
 */
export const getDeepestTrainServiceScope = <
  T extends TrainProps = TrainProps,
  TRGP extends TrainGroupSingleProps = TrainGroupSingleProps,
>(
  trainProps: T,
): T['trainRouteGroupProps']['trainRouteOrGroup'] | TRGP['trainGroup'] | never => {
  // If the type of trainProps.trainGroupSingleTrainRunProps is CemitTypename.singleTrainGroupTrainProps,
  // it means that a single TrainGroup is in scope
  const trainGroupSingleProps: Perhaps<TrainGroupSingleProps> =
    castIfEqualsCemitType<TrainGroupSingleProps>(
      CemitTypename.singleTrainGroupTrainProps,
      trainProps.trainGroupSingleTrainRunProps,
    );
  if (trainGroupSingleProps) {
    return trainGroupSingleProps.trainGroup;
  } else if (trainProps.trainRouteGroupProps.organizationHasServiceLines) {
    // Otherwise the scope must be TrainRouteOrGroup
    return trainProps.trainRouteGroupProps.trainRouteOrGroup!;
  } else {
    throw new Error(
      'Expected either a TrainGroup or a TrainRouteOrGroup to be in scope, but neither is. This means that the organization does not defined serviceLines',
    );
  }
};

/**
 * Returns true is the scope is TrainRouteOrGroup, meaning there is no single TrainGroup in scope
 * @param trainProps
 */
export const isTrainRouteOrGroupScope = <T extends TrainProps = TrainProps>(
  trainProps: T,
): boolean | never => {
  return implementsCemitTypeViaClass<TrainRouteOrGroup>(
    CemitTypename.trainRouteOrGroup,
    getDeepestTrainServiceScope(trainProps),
  );
};
