import { range } from 'ramda';
import { format, parseISO } from 'date-fns';

// Date indices used by the Date object
export const [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY] = range(0, 7);
export const [WEEKDAY, WEEKEND] = [range(1, 6), [SUNDAY, SATURDAY]];

/**
 * Combines a datetime with a timeString, using the timeString's, H, M, S, and timezone
 * @param datetime
 * @param timeString
 * @returns {*}
 */
export const dateWithTimeOffset = (datetime: Date, timeString: string) => {
  const str = `${format(datetime, 'yyyy-MM-dd')}T${timeString}`;
  const date = parseISO(str);
  return date;
};


export const pythonISODateFormat = (datetime: Date) => {
  const f = format
  return f(datetime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};
/**
 * Returns just the time and timezone
 * @param datetime
 * @returns {string}
 */
export const localizedTime = (datetime: Date) => {
  return format(datetime, 'HH:mm xx');
};
export const localizedTimeNoTimezone = (datetime: Date) => {
  return format(datetime, 'HH:mm');
};