import {Unidentified} from "./unidentified.ts";
import {Identified, IdentifiedUnsaved} from "../../types/identified";
import {cemitedConstructor} from "./cemitClassConstructor.ts";

export abstract class IdentifiedUnsavedClass extends Unidentified implements IdentifiedUnsaved {
    protected constructor(obj: Identified) {
        super(obj);
        cemitedConstructor(obj, this);
    }

    declare sourceKey: string;
}
