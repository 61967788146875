import {namesOfTrainFormationExtremeVehicles} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainFormationUtils.ts';
import {Chip, Stack} from '@mui/material';
import {join, length, map} from 'ramda';
import React from 'react';

import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';

/**
 * Creates Chips for the Chosen FormationFilters with the ability to remove them from the filter
 * @param chosenFormations The chosen chosenFormations
 * @param handleRemoveFormationFromFilters
 * @returns {JSX.Element}
 * @constructor
 */
export const ChosenTrainFormations = ({
  chosenTrainGroupOnlyTrainFormations,
  handleRemoveTrainGroupOnlyTrainFormationFromFilters,
}: {
  chosenTrainGroupOnlyTrainFormations: TrainGroupOnlyTrainFormation[];
  handleRemoveTrainGroupOnlyTrainFormationFromFilters: (
    trainGroupFormation: TrainGroupOnlyTrainFormation,
  ) => void;
}) => {
  const formationChips = map(
    (trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation) => {
      const trainFormationLabel = trainGroupOnlyTrainFormation.trainFormation.name;
      return (
        <Chip
          key={trainGroupOnlyTrainFormation.id}
          {...{
            color: 'primary',
            label: trainFormationLabel,
            variant: 'outlined',
            onDelete: () => {
              handleRemoveTrainGroupOnlyTrainFormationFromFilters(
                trainGroupOnlyTrainFormation,
              );
            },
          }}
        />
      );
    },
    chosenTrainGroupOnlyTrainFormations,
  );
  return length(formationChips) ? (
    <Stack direction="column" spacing={1}>
      {formationChips}
    </Stack>
  ) : undefined;
};
export default ChosenTrainFormations;
