import * as R from 'ramda';
import {
  all,
  any,
  complement,
  compose,
  curry,
  eqProps,
  equals,
  filter,
  find,
  findIndex,
  fromPairs,
  groupBy,
  has,
  head,
  identity,
  includes,
  indexBy,
  indexOf,
  intersection,
  is,
  isNil,
  join,
  keys,
  last,
  length,
  lt,
  lte,
  map,
  mergeRight,
  mergeWith,
  prop,
  propOr,
  reduceWhile,
  slice,
  sortBy,
  unless,
  when,
  zip,
  zipWith,
} from 'ramda';
import {
  eqStrPath,
  filterWithKeys,
  overDeep,
  reqStrPathThrowing,
  toArrayIfNot as rescapeToArrayIfNot,
  transformKeys,
} from '@rescapes/ramda';
import {Identified} from '../../types/identified';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {ScalarOrList} from '../../types/typeHelpers/listHelpers';

import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {StateSetter} from 'types/hookHelpers/stateSetter';

/**
 * @file functional utilities extracted from Andy's rescape-ramda library
 */

/**
 * Assuming a list that is ordered by the give datetime prop, limit
 * the list to items with dates within the interval inclusive
 * @param interval Standard ISO datetime interval
 * @param interval.start start Date
 * @param interval.end end Date
 * @param datePath The string path to the datetime in orderedList, e.g. 'departureTime', or 'foo.departureTime'
 * @paramorderedList The ordered list
 * @returns {[Object]} The orderedList sliced to match the interval. Can be empty
 */
export const limitByDatePropToDateInterval = (
  {
    interval,
    dateProp,
  }: {
    interval: DateInterval;
    dateProp: string;
  },
  orderedList: any[],
) => {
  // Find the first item greater than/equal to the interval start
  const firstIndex = findIndex((item) => {
    // @ts-ignore No type support for Date
    return lte(interval.start, reqStrPathThrowing(dateProp, item));
  }, orderedList);
  // If nothing matches, return empty
  if (equals(-1, firstIndex)) {
    return [];
  }
  // Find the first item greater than interval end
  const outsideIndex =
    firstIndex +
    // If there is nothing greater, use Infinity to slice to the end
    when(
      equals(-1),
      () => Infinity,
    )(
      findIndex(
        (item) => {
          // @ts-ignore No type support for Date
          return lt(interval.end, reqStrPathThrowing(dateProp, item));
        },
        slice(firstIndex, Infinity, orderedList),
      ),
    );
  // Return the slice, where outsideIndex is excluded. If both are -1, than [] is returned
  return slice(firstIndex, outsideIndex, orderedList);
};

export const extremes = <T>(list: T[]): [T, T] => {
  return [head<T>(list) as T, last<T>(list) as T];
};

/**
 * Compares persisted instances by id
 * @param obj1 first instance to compare
 * @param obj2 instance to compare with obj1
 * @returns {Boolean} True if equal
 */
export const idsEqual = curry(
  (
    obj1: Perhaps<Pick<Identified, 'id'>>,
    obj2: Perhaps<Pick<Identified, 'id'>>,
  ): boolean => {
    return Boolean(obj1 && obj2) && eqProps('id', obj1, obj2);
  },
);

/**
 * Returns true if obj.id matches any obj.id of list
 * @param obj instance to compare
 * @param list list of instances to compare with obj
 */
export const idsInclude = curry(
  (
    obj: Perhaps<Pick<Identified, 'id'>>,
    list: Perhaps<Pick<Identified, 'id'>[]>,
  ): boolean => {
    return (
      Boolean(obj && list) &&
      any((obj2: Identified) => {
        return eqProps('id', obj, obj2);
      }, list || [])
    );
  },
);

/***
 * Tests the length of the lists and id prop of each item for equality
 * The lists items must have objects with ids in the same order
 * @param list1 first list of objets to compare or undefined
 * @param list2 second list of objets to compare of undefined
 * @returns True if equal
 */
export const idListsEqual = <T extends Identified>(
  list1: Perhaps<T[]>,
  list2: Perhaps<T[]>,
): boolean => {
  return propOfListsEqual('id', list1, list2);
};

/**
 * Tests if the equality of a prop of each item from each list, where lists must be the same length and order.
 * @param prop
 * @param list1
 * @param list2
 */
export const propOfListsEqual = <T>(
  prop: keyof T,
  list1: Perhaps<T[]>,
  list2: Perhaps<T[]>,
): boolean => {
  if (!list1 || !list2) {
    return false;
  }
  return (
    equals<number>(
      ...(map<T[], number>(length<T[]>, [list1, list2]) as [number, number]),
    ) &&
    all<boolean>(
      identity<boolean>,
      zipWith<T, T, boolean>(
        (item1: T, item2: T) => {
          return eqProps<T, T>(prop, item1, item2);
        },
        list1,
        list2,
      ),
    )
  );
};

/**
 * Calls idListsEqual after sorting each list by id
 * @param list1
 * @param list2
 */
export const sortThenIdListsEqual = <T extends Identified>(
  list1: Perhaps<T[]>,
  list2: Perhaps<T[]>,
): boolean => {
  const sortedLists = map(
    (list: Perhaps<T[]>): T[] => {
      return sortBy((item: T): string => {
        return prop('id', item);
      }, list || []);
    },
    [list1, list2],
  );
  return idListsEqual<T>(...(sortedLists as [T[], T[]]));
};

/***
 * Tests the length of the lists and strPath to each item for equality
 * The lists items must have objects with ids in the same order
 * @param strPath Dot-separate string path of props in the object, e.g. 'foo.bar.id'
 * @param list1 first list of objets to compare
 * @param list2 second list of objets to compare
 * @returns {Boolean} True if equal
 */
export const strPathListsEqual = <T>(
  strPath: string,
  list1: T[],
  list2: T[],
): boolean => {
  return (
    equals<T>(...(map<T[], number>(length<T[]>, [list1, list2]) as [T, T])) &&
    all<boolean>(
      identity<boolean>,
      zipWith<T, T, boolean>(eqStrPath(strPath), list1, list1),
    )
  );
};

/**
 * Validates that the list has only one item before return the item.
 * TODO This was in @rescapes/rambda but that throws an annoying deprecation warning from folktale
 * @param list
 */
export const onlyOneValueOrThrow = <T extends any>(list: T[]): T => {
  if (length(list) !== 1) {
    throw new Error(`list should be length 1, got length ${length(list)}`);
  }
  return head(list);
};

/**
 * Return the only item or undefined from the list
 * @param list
 * @returns {*}
 */
export const onlyOneValueOrNoneThrow = <T extends any>(
  list: Perhaps<T[]>,
): Perhaps<T> => {
  if (length(list || []) > 1) {
    throw new Error(`list should be length 1, got length ${length(list)}`);
  }
  return head(list || []);
};

/**
 * Returns true if list1 and list2 are both nonundefined and their lengths are equal
 * @param list1
 * @param list2
 * @returns {Boolean}
 */
export const lengthsEqual = (list1: any[], list2: any[]): boolean => {
  return list1 && list2 && equals(length(list1), length(list2));
};

/**
 * Performs a shallow compare of reference instead of ramda's default
 * equals, which is deep
 * @param item1
 * @param item2
 * @returns {boolean} True if the references or primitives are equal
 * based on ===
 */
export const shallowEquals = (item1: any, item2: any): boolean => {
  return item1 === item2;
};
/**
 * Returns true if respective items in the lists are equal by reference
 * @param list1
 * @param list2
 */
export const listsShallowEqual = (list1: any[], list2: any[]): boolean => {
  return (
    lengthsEqual(list1, list2) &&
    reduceWhile(
      (accum) => {
        // If false, short circuit
        return accum;
      },
      (_accum: boolean, [item1, item2]) => {
        return shallowEquals(item1, item2);
      },
      true,
      zip(list1, list2),
    )
  );
};

/**
 * Convert a list to a set of consecutive pairs of list N - 1 compared to the list of length N
 * Throws an error if the List is less than length 2
 * @param list
 */
export const listToPairs = <T extends any, PairType = [T, T]>(list: T[]): PairType[] => {
  if (length(list) < 2) {
    throw new Error('Cannot process a list of length 1 to pairs');
  }
  return zip<T, T>(slice<T>(0, -1, list), slice<T>(1, Infinity, list)) as PairType[];
};

/**
 * Version of head that throws if the list is empty
 * @param list
 */
export const headOrThrow = <T extends any>(list: T[]): T => {
  const item = head(list);
  if (!item) {
    throw Error('List is empty');
  }
  return item;
};

/**
 * Version of last that throws if the list is empty
 * @param list
 */
export const lastOrThrow = <T extends any>(list: T[]): T => {
  const item = last(list);
  if (!item) {
    throw Error('List is empty');
  }
  return item;
};

/**
 * Find the first matching value or throw
 * @param pred
 * @param list
 */
export const findOrThrow = <T>(
  pred: (val: T) => boolean,
  list: readonly T[],
): T | never => {
  const value = find(pred, list);
  if (!value) {
    throw new Error('find did not match anything');
  }
  return value;
};

/**
 * Filters for one and only one value. 0 or more than one matches leads to an Error
 * @param pred
 * @param list
 */
export const findOnlyOneOrThrow = <T extends object>(
  pred: (val: T) => boolean,
  list: readonly T[],
): T | never => {
  const values: readonly T[] = filter(pred, list);
  if (!equals(1, length(values))) {
    throw new Error(
      `Should have found exactly one value. Found: ${JSON.stringify(values)}`,
    );
  }
  return head(values!) as T;
};

/**
 * Find an instance in the list matching idOrInstance or by id. Throws if no match is found
 * @param list List of instances to search
 * @param idOrInstance Either an id string or and Identified instance with an id
 */
export const findByIdOrThrow = <T extends Identified>(
  list: readonly T[],
  idOrInstance: string | T,
): T | never => {
  const id = unless(is(String), (t: T) => t.id, idOrInstance);
  const value: Perhaps<T> = find((t: T) => {
    return equals(t.id, id);
  }, list);
  if (!value) {
    throw new Error(`Could not find id ${id} among ${join(', ', map(prop('id'), list))}`);
  }
  return value;
};

/**
 * Find by idOrInstance in list
 * @param list List of instances with ids
 * @param idOrInstance A string id or an instance with an id
 */
export const findByIdOrUndefined = <T extends Identified>(
  list: readonly T[],
  idOrInstance: string | T,
): T | never => {
  const id = unless(is(String), (t: T) => t.id, idOrInstance);
  return find((t: T) => {
    return equals(t.id, id);
  }, list);
};

/**
 * Returns the first mapped item that is not null
 * @returns {*} list first mapped item value that is not nil
 * @param mappingFunc
 * @param list
 */
export const findMapped = <T, R>(
  mappingFunc: (val: T) => Perhaps<R>,
  list: readonly T[],
): Perhaps<R> => {
  return R.reduceWhile<T, Perhaps<R>>(R.isNil, (_, i) => mappingFunc(i), undefined, list);
};

/**
 * camelCase a string with the first word lowercase
 * @param str
 */
export const camelCase = (str: string) =>
  R.toLower(str).replace(/_(\w|$)/g, (_, x) => x.toUpperCase());

/**
 * Apply cammelize deep on a Record where recursion occurs on values that are objects or arrays
 * @param obj
 */
export const camelizeDeep = (obj: Record<string, any>) => {
  return overDeep(
    // Keep is undefined at the top level
    (_k: string, v: any) => transformKeys(camelCase, v),
    obj,
  );
};
export const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const camelCaseAndRemoveSpaces = (str: string) => {
  return camelCase(str).replace(/\s/g, '');
};

export const slugifyDeep = (obj: Record<string, any>) => {
  return overDeep(
    // Keep is undefined at the top level
    (_k: string, v: any) => {
      return transformKeys(camelToSnakeCase, v);
    },
    obj,
  );
};

/**
 * Groups by the given groupByProp and then checks that each key of the group matches one of groupByPropList
 * and filters out those that do not
 * @param groupByProp a prop of T that resolves to value P or a unary function expecting T and return a value P
 * @param groupByPropList a list of eligible values resolved by groupByProp. Keys not matching this are filtered out
 * @param list
 * @param includeEmptyListForMissingPropValues Default true. Any key of groupByPropList not in the filtered object
 * will be added to the filtered object with an empty array for a value
 */
export const groupByAndFilter = <T, P>(
  groupByProp: string | ((t: T) => P),
  groupByPropList: P[],
  list: T[],
  includeEmptyListForMissingPropValues: boolean = true,
) => {
  return compose(
    (obj) => {
      if (!includeEmptyListForMissingPropValues) {
        return obj;
      }
      // For any value of groupByPropList not in keys of obj, create a key with an empty list
      const keysOfObj = keys(obj);
      return mergeRightIfDefined(
        obj,
        fromPairs(
          map(
            (key) => [key, []],
            filter((key) => !includes(key.toString(), keysOfObj), groupByPropList),
          ),
        ),
      );
    },
    (obj) =>
      filterWithKeys(
        (_list: T[], groupByPropValue: string) =>
          includes(
            groupByPropValue,
            map((i) => i.toString(), groupByPropList),
          ),
        obj,
      ),
    (list) =>
      groupBy(
        // Group by the groupByProp prop or by calling groupByProp
        (item) =>
          is(Function, groupByProp) ? groupByProp(item) : prop(groupByProp, item),
        list,
      ),
  )(list);
};

/**
 * Returns the items ids that match the keys of the lookup
 * @param itemIds
 * @param lookup
 */
export const itemsInLookup = <S extends string>(
  lookup: Record<S, any | boolean>,
  itemIds: S[],
): string[] => {
  return _itemsInLookup(has, lookup, itemIds);
};
export const itemsNotInLookup = <S extends string>(
  lookup: Record<S, any | boolean>,
  itemIds: S[],
): string[] => {
  return _itemsInLookup(complement(has), lookup, itemIds);
};
const _itemsInLookup = <S extends string>(
  pred: (item: S, lookup: Record<S, any | boolean>) => string,
  lookup: Record<S, any | boolean>,
  itemIds: S[],
) => {
  return filter((itemId) => {
    return Boolean(pred(itemId, lookup));
  }, itemIds);
};

/**
 * Returns the items mapped to ids that match the keys of the lookup
 * @param lookup
 * @param mapper
 * @param items
 */
export const mappedItemsInLookup = <T>(
  lookup: Record<string, T | boolean>,
  mapper: (item: T) => string,
  items: T[],
): T[] => {
  return filter((item: T): boolean => {
    return has<string>(mapper(item), lookup);
  }, items);
};

/**
 * Converts anything to Boolean in a typescript friendly way
 * @param t
 */
export const toBoolean = <T>(t: T): boolean => {
  return Boolean(t as boolean);
};

/**
 * Converts an array's length to Boolean in a typescript friendly way
 * @param ts
 */
export const hasNonZeroLength = <T>(ts: Perhaps<T[]>): boolean => {
  return Boolean((ts?.length || 0) as number);
};

/**
 * Converts falsy values to undefined so the value passes an isNil test
 * @param value
 */
export const falsyToUndefined = <T>(value: Perhaps<T>): Perhaps<T> => {
  return Boolean(value) ? value : undefined;
};

/**
 * Converts an undefined to true. This is mainly for loading booleans,
 * so they are true if their dependencies aren't yet defined
 * @param value
 */
export const undefinedToTrue = (value: Perhaps<boolean>): boolean => {
  if (isNil(value)) {
    return true;
  }
  return value;
};

/**
 * Convert a list of Identified to a comma-separated string of ids to send to an API
 * @param list
 */
export const listToIds = (list: Identified[]): string => {
  return join(',', map(prop('id'), list));
};

/**
 * Returns true if any of anyList are in list
 * @param anyList
 * @param list
 */
export const includesAny = <T>(anyList: T[], list: T[]): boolean => {
  return length(intersection(anyList, list)) > 0;
};

/**
 * Returns true if all of allList are in list
 * @param allList
 * @param list
 */
export const includesAll = <T>(allList: T[], list: T[]): boolean => {
  return lengthsEqual(intersection(allList, list), allList);
};

/**
 * Given values and collection, maps each value with valueToCollectionIndexFunc and index each collection
 * with indexingFunc and returns the collection item that matches the mapped value according to the indexingFunc
 * Example:
 * indexingFunc: prop('flavor')
 * collection: [{flavor: 'blueberry'}, {flavor: 'strawberry}, {flavor: 'guava'}]
 * valueToCollectionIndexFunc: prop('fruit')
 * values [{fruit: 'strawberry'}, {fruit: 'blueberry'}]
 * returns [{flavor: 'strawberry}, {flavor: 'blueberry'}]
 * @param indexingFunc
 * @param collection
 * @param valueToCollectionIndexFunc
 * @param values
 */
export const mapValuesToCollectionItemsByPropPath = <C, T>(
  indexingFunc: (c: C) => string,
  collection: C[],
  valueToCollectionIndexFunc: (t: T) => string,
  values: T[],
): C[] | never => {
  const lookup: Record<string, C> = indexBy<C, string>(indexingFunc, collection);

  return map((value: T): C | never => {
    const index: string = valueToCollectionIndexFunc(value);
    const resolved: C = lookup[index];
    if (!resolved) {
      throw Error(
        `Can't map value indexed to ${index} to anything in the collection lookup keys ${join(', ', keys(lookup))}`,
      );
    }
    return resolved;
  }, values);
};

/**
 * Returns the items in incoming not in existing by matching on id
 * @param existingItems
 * @param incomingItems
 */
export const incomingInstancesWithoutMatchingExisting = <T extends Identified>(
  existingItems: T[],
  incomingItems: T[],
): T[] => {
  const lookup = indexBy(prop('id'), existingItems);
  return filter((incomingItem: T) => {
    return !propOr(false, incomingItem.id, lookup);
  }, incomingItems);
};

/**
 * Use instead of {} to prevent React from thinking a prop has changed from {} to {}
 */
export const emptyObj = {};

/**
 * Return itemOrList as an array
 * @param itemOrList
 */
export const toArrayIfNot = <T>(itemOrList: ScalarOrList<T>) => {
  return rescapeToArrayIfNot(itemOrList) as T[];
};

/**
 * Removed null or undefined items from an iterable
 * @param [a] items Items that might have falsy values to remove
 * @returns The compacted items
 * @sig compact:: [a] -> [a]
 * @sig compact:: {k,v} -> {k,v}
 */
export const compact = R.reject(R.isNil);

export const throwUnlessDefined = (value: Perhaps<any>) => {
  if (isNil(value)) {
    throw new Error('Expected value to be defined');
  }
  return value;
};

/**
 * Converts length to Boolean to please Typescript
 * @param value
 * @constructor
 */
export const lengthAsBoolean = (value: Perhaps<any[]>) => {
  return compose(Boolean, length)(value || []);
};

/**
 * mergeRight but if b is a class, ignore b values that are undefined. Useful for merging class instances
 * that default all fields to undefined
 * @param a
 * @param b
 */
export const mergeRightIfDefined = curry((a, b): any => {
  return !b || b.constructor.name == 'Object'
    ? mergeRight(a, b)
    : mergeWith((l, r) => r || l, a, b);
});

export const envVarIsTrue = (envVar: Perhaps<string>) => {
  return 'true' == envVar;
};

/**
 * Map an each of an object's key values to a value and return the array of values
 */
export const mapObjToValues = R.curry((f, obj) => {
  return R.values(R.mapObjIndexed(f, obj));
});

/**
 * Round robin forward or backward based on diff == -1 or 1
 * @param collection
 * @param diff
 * @param prev
 */
export const roundRobin = <T extends any>(
  collection: T[],
  diff: number,
  prev: T,
): string => {
  const index =
    (length(collection) + indexOf(prev, collection) + diff) % length(collection);
  return collection[index];
};
