import {Stack} from '@mui/material';
import {lensPath, set} from 'ramda';
import TrainRunDescriptionContainer from './TrainRunDescriptionContainer.tsx';
import {Size} from '../../../../../types/layout/size';
import React from 'react';
import {
  SingleTrainGroupTrainDependencyProps,
  TrainAppTrainDependencyProps,
} from 'types/propTypes/trainPropTypes/trainTrainAppDependencyProps.d.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 *  A TrainRunLine with specific user settings about the distance range of interest
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param trainProps.trainGroupSingleTrainRunProps
 * @param trainProps.trainGroupSingleTrainRunProps.trainGroup
 * @param componentProps
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupLine = ({
  appProps,
  organizationProps,
  trainProps,
  componentProps: {buttons, trainDetailSize},
}: SingleTrainGroupTrainDependencyProps & {
  componentProps: {
    buttons: React.ReactElement[];
    trainDetailSize: Size;
  };
}) => {
  const trainGroup: TrainGroup = trainProps.trainGroupSingleTrainRunProps.trainGroup;

  // @ts-ignore
  return (
    <Stack
      key={trainGroup.id}
      {...{
        direction: 'row',
      }}
    >
      // @ts-ignore
      <Stack
        key="trainGroup"
        spacing={1}
        sx={{
          width: '100%',
          color: 'secondary.main',
          borderRadius: 2,
          border: 1,
          padding: '4px',
        }}
      >
        {/* Selected TrainRun description */}
        <TrainRunDescriptionContainer
          {...{
            appProps,
            organizationProps,
            trainProps,
            componentProps: {
              buttons,
              trainDetailSize,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default TrainGroupLine;
