import React, {memo, useState} from 'react';
import {reverseTrainRouteOrGroup} from '../../appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import TrainFilterAndMaybeEquipmentListBoards from './trainPageSubContainers/TrainFilterAndMaybeEquipmentListBoards.tsx';
import TrainGroupListBoardCollapsed from './trainPageSubContainers/TrainGroupListBoardCollapsed.tsx';
import TrainPageMap from './trainPageSubContainers/TrainPageMap.tsx';
import TrainPageBoardInMapSlide from './trainPageSubContainers/TrainPageBoardInMapSlide.tsx';
import {includes} from 'ramda';
import {unlessLoadingProps} from '../../utils/componentLogic/loadingUtils.ts';
import {FrontendView} from '../../config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {getOrganizationViews} from '../../utils/organization/organizationUtils.ts';
import TrainPage, {TrainPageProps} from './TrainPage.tsx';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';

import {ViewSlider} from '../../types/propTypes/viewSlider.ts';
import {useMemoClsOrType} from '../../appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {useDebouncedCallback} from 'use-debounce';

/**
 * TrainPage is the top component of the Train application. It shows TrainBoard and possible a TrainMap
 * Within the TrainBoard is a listing of TrainGroups and possibly details about the selected TrainGroups
 * @return {JSX.Element}
 * @constructor
 */
const TrainPageContainer = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
}: TrainAppMapDependencyProps): JSX.Element => {
  // The TrainDetail is the area under the TrainGroup list and possibly the TrainFormation. Its size is
  // adjusted by pulling the horizontal SplitPane divider or by changing the browser window size
  const [trainDetailSize, setTrainDetailSize] = useState({width: 0, height: 0});

  // The TrainFormation size is the optional area right of the TrainGroup list. Its size is
  // adjusted by pulling the horizontal SplitPane divider or by changing the browser window size
  // This isn't currently used for anything since the split pane manages the sizes of its trafficSimComponents
  const [trainFormationSize, setTrainFormationSize] = useState({width: 0, height: 0});

  // Wrap trainDetailSize in useDebounce so that dragging the divider doesn't constantly updated, rather every 100ms
  const debouncedTrainDetailSize = useDebouncedCallback(() => trainDetailSize, 100);
  const debouncedTrainFormationSize = useDebouncedCallback(() => trainFormationSize, 100);

  // Force change trainDetailSize when the divider is moved
  // TrainDetail is the bottom panel of a vertical split
  const onChangeTrainDetailSize = ({
    lastMeasure,
    secondaryMeasure,
  }: {
    lastMeasure: number;
    secondaryMeasure: number;
  }) => {
    setTrainDetailSize({height: lastMeasure, width: secondaryMeasure});
  };

  // TrainFormation is the right panel of horizontal split
  const onChangeTrainFormationSize = ({
    lastMeasure,
    secondaryMeasure,
  }: {
    lastMeasure: number;
    secondaryMeasure: number;
  }) => {
    setTrainFormationSize({width: lastMeasure, height: secondaryMeasure});
  };

  const handleDrawerOpen = () => {
    appProps.setTrainFilterOpen(true);
  };

  const handleDrawerClose = () => {
    appProps.setTrainFilterOpen(false);
  };

  const {trainPageMap, trainPageBoardAndMaybeSlider, trainPageBoardCollapsed} =
    unlessLoadingProps(organizationProps.loading, () => {
      const views: FrontendView[] = getOrganizationViews(organizationProps.organization)!;

      // If the organization has a map or detail page, include the expander/contractor arrow
      const needsTrainMap: boolean = includes(FrontendView.map, views);

      // Is the TrainMap included in the organization's configured views
      const trainPageMap: Perhaps<JSX.Element> = needsTrainMap ? (
        <TrainPageMap
          {...{
            appProps,
            trainProps,
            mapProps,
            componentProps: {},
          }}
        />
      ) : undefined;

      // A vertically stacked list of TrainGroup filters and optionally a list of equipment
      // Equipment has only been used for wheelSets for Mantena thus far
      const trainFilterAndMaybeEquipmentListBoards: JSX.Element =
        appProps.viewSlider != ViewSlider.collapsed ? (
          <TrainFilterAndMaybeEquipmentListBoards
            {...{
              appProps,
              organizationProps,
              trainProps,
              mapProps,
              componentProps: {
                handleDrawerOpen,
                handleDrawerClose,
                trainDetailSize: debouncedTrainDetailSize,
                minimumTrainListAndFormationTopHeight: '30%',
                onChangeTrainDetailSize,

                trainFormationSize: debouncedTrainFormationSize,
                // TODO This is actually the default width, not minimum
                minimumTrainListLeftWidth: '30%',
                onChangeTrainFormationSize,
              },
            }}
          />
        ) : undefined;

      const trainBoardMinWidth =
        appProps.viewSlider == ViewSlider.expanded || !needsTrainMap ? '100%' : '60%';
      const trainPageBoardAndMaybeSlider: JSX.Element =
        needsTrainMap && appProps.viewSlider != ViewSlider.collapsed ? (
          <TrainPageBoardInMapSlide
            {...{
              appProps,
              mapProps,
              componentProps: {minWidth: trainBoardMinWidth},
            }}
          >
            {trainFilterAndMaybeEquipmentListBoards}
          </TrainPageBoardInMapSlide>
        ) : (
          trainFilterAndMaybeEquipmentListBoards
        );

      const trainPageBoardCollapsed: Perhaps<JSX.Element> =
        appProps.viewSlider == ViewSlider.collapsed ? (
          <TrainGroupListBoardCollapsed
            {...{
              appProps,
              organizationProps,
              trainProps,
              componentProps: {
                handleDrawerOpen,
                handleDrawerClose,
                reverseTrainRouteOrGroup,
                trainDetailSize: debouncedTrainDetailSize,
              },
            }}
          />
        ) : undefined;

      return {
        trainPageMap,
        trainPageBoardAndMaybeSlider,
        trainPageBoardCollapsed,
      };
    });

  const childComponentProps = useMemoClsOrType<TrainPageProps>(
    CemitTypename.trainPageProps,
    {
      trainPageBoardAndMaybeSlider,
      trainPageBoardCollapsed,
      trainPageMap,
    },
    [trainPageBoardAndMaybeSlider, trainPageBoardCollapsed, trainPageMap],
  );

  return (
    <TrainPage
      {...{
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        componentProps: childComponentProps,
      }}
    />
  );
};

export default TrainPageContainer;
// export default memo(
//   TrainPageContainer,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
