import {
  compose,
  divide,
  lt,
  map,
  mapObjIndexed,
  multiply,
  omit,
  reduce,
  values,
  when,
} from 'ramda';
import {AlertGraphqlResponseAlertData, AlertLevelDatum} from 'types/alerts/alertMapData';
import {AlertBaseGauge, AlertGaugeByTimePeriod} from 'types/alerts/alertGauge';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {AttributeAlertLevelEnum} from 'types/alerts/attributeAlertLevelEnum.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {AlertPeriodMapData} from 'types/alerts/alertPeriodMapData';
import {totalMapDataValues} from 'appUtils/alertUtils/setMapDataUtils.ts';
import {getTotalCount} from 'appUtils/alertUtils/graphqlResultUtils.ts';

/**
 * Convert the alertGraphqlResponseAlertData to AlertGaugeByTimePeriod based on alertTypeConfig
 * @param alertGraphqlResponseAlertData
 */
export const getAlertGaugeByTimePeriod = (
  alertGraphqlResponseAlertData: Perhaps<AlertGraphqlResponseAlertData>,
): AlertGaugeByTimePeriod => {
  const alertTypeConfig = alertGraphqlResponseAlertData?.alertTypeConfig;
  // Total the returned data from the query
  const alertPeriodMapData: AlertPeriodMapData = totalMapDataValues(
    alertGraphqlResponseAlertData.alertTypeConfig,
    alertGraphqlResponseAlertData,
    getTotalCount,
  );

  const attributeAlertLevelEnums = omit(
    [AttributeAlertLevelEnum.none as string],
    alertGraphqlResponseAlertData.alertTypeConfig.attributeAlertLevelEnum,
  );
  return mapObjIndexed(
    (alertLevelDatum: AlertLevelDatum, periodEnum: AlertGaugeByTimePeriod) => {
      // The dividend is the sum of the each alertLevelDatum value
      const dividend: number = reduce(
        (sum: number, key: string) => {
          return sum + alertLevelDatum[key];
        },
        0,
        values(attributeAlertLevelEnums),
      );
      return clsOrType<AlertBaseGauge>(CemitTypename.alertGauge, {
        alertTypeConfig,
        periodEnum,
        // calculate keys normal, warning, and critical
        ...map(
          (count: number) => {
            return {
              // Divide by the dividend and multiply by 100 when dividend is greater than 0
              value: when(
                lt(0),
                compose(
                  (quotient: number) => multiply(100, quotient),
                  (dividend: number) => divide(count, dividend),
                ),
              )(dividend),
              count,
            };
          },
          omit(['__typename'], alertLevelDatum),
        ),
      });
    },
    alertPeriodMapData,
  );
};
