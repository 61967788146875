import React, {SyntheticEvent, useRef, useState} from 'react';
import {useOffsetLeft} from 'utils/hooks/domHooks.ts';
import TrainLineStationDot from 'components/apps/trainAppComponents/trainLineComponents/TrainLineStationDot.tsx';
import {SetOffsetLeft} from '../../../../types/layout/setOffsetLeft';
import {SxProps} from '@mui/system';
import {Size} from '../../../../types/layout/size';

import {TrainGroup} from '../../../../types/trainGroups/trainGroup';

const EVENT_CODES = [0, 1];

/**
 * The TrainPage Line Station Dot
 * @param sx
 * @param diameter
 * @param displayProps the windowSize used to trigger rerenders
 * @param setOffsetLeft
 * @param recalculateOffsetLefts
 * @param trainDetailSize Tracks changes in size the the TrainLine container when the window
 * size or panel drag occurs
 * @returns {JSX.Element}
 * @constructor
 */
const TrainLineStationDotContainer = ({
  componentProps: {
    diameter,
    setOffsetLeft,
    recalculateOffsetLefts,
    trainGroup,
    trainDetailSize,
  },
  sx,
}: {
  componentProps: {
    diameter: number;
    setOffsetLeft: SetOffsetLeft;
    recalculateOffsetLefts: Date;
    trainGroup: TrainGroup;
    trainDetailSize: Size;
  };
  sx: SxProps;
}) => {
  const stationRef = useRef(undefined);
  const [trainRouteFunctionChooserVisible, setTrainRouteFunctionChooserVisible] =
    useState(false);

  const showTrainRouteFunctionChooser = (e: SyntheticEvent) => {
    if (EVENT_CODES.includes(e.nativeEvent.which)) {
      setTrainRouteFunctionChooserVisible(true);
    }
  };

  const hideTrainRouteFunctionChooser = (e: SyntheticEvent) => {
    if (EVENT_CODES.includes(e.nativeEvent.which)) {
      setTrainRouteFunctionChooserVisible(false);
    }
  };

  useOffsetLeft({
    target: stationRef,
    containerSize: trainDetailSize,
    setOffsetLeft,
    recalculateOffsetLefts,
    trainGroup,
    spaceGeospatially: false,
  });
  return (
    <TrainLineStationDot
      {...{
        sx,
        diameter,
        stationRef,
        showTrainRouteFunctionChooser,
        hideTrainRouteFunctionChooser,
        showMenu: trainRouteFunctionChooserVisible,
      }}
    />
  );
};

export default TrainLineStationDotContainer;
