import { Perhaps } from '../../types/typeHelpers/perhaps';

/**
 * Creates the props by calling propsFunc with no arguments unless loading is true
 * @param loading Returns {} if true
 * @param propsFunc Called with no arguments if loading is false
 * @returns {Object} The result of propsFunc or {}
 */
export const unlessLoadingProps = <T>(loading: boolean, propsFunc: (() => T)): T | Record<string, never> => {
  return loading ? {} : propsFunc();
}

/**
 * Creates the value by calling valueFunc with no arguments unless loading is true
 * @param loading Returns undefined if true
 * @param valueFunc Called with no arguments if loading is false
 * @returns {Object} The result of propsFunc or undefined
 */
export const unlessLoadingValue = <T>(loading: boolean, valueFunc: (() => T)): Perhaps<T> => {
  return loading ? undefined : valueFunc();
};