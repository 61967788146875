import {Box, Stack, Typography} from '@mui/material';
import React, {memo} from 'react';
import TrainFormationsList from '../trainFormationChooserComponents/TrainFormationsList.tsx';
import TrainRouteChooserContainer from '../trainRouteChooserComponents/TrainRouteChooserContainer.tsx';
import TrainGroupsActiveList from './TrainGroupsActiveList.tsx';
import {unlessLoadingProps} from '../../../../../utils/componentLogic/loadingUtils.ts';
import {Size} from '../../../../../types/layout/size';
import LoaderWithText from '../../../../loading/LoaderWithText.tsx';
import {doesOrganizationHaveServiceLines} from '../../../../../utils/organization/organizationUtils.ts';
import {Perhaps} from '../../../../../types/typeHelpers/perhaps';
import {length} from 'ramda';
import {SxProps} from '@mui/system';
import {toArrayIfNot} from '../../../../../utils/functional/functionalUtils.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {useNotLoadingMemo} from '../../../../../utils/hooks/useMemoHooks.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';

interface TrainGroupListContainerProps extends CemitComponentProps {
  handleDrawerOpen: Function;
  handleDrawerClose: Function;
  trainDetailSize: Size;
  sx: SxProps;
}

/**
 * Lists
 * TrainGroupSingleTrainFormations that are active
 * and TrainGroupOnlyTrainFormations that match the current filter
 * @prop {Boolean loading,
 * @prop {Object} sx
 * @prop {Object} trainProps
 * @prop {Object} trainRoute The current TrainRoute
 * @pram {Object} organization
 * @returns {undefined}
 * @constructor
 */
const TrainGroupListContainer = memo(
  ({
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      // Currently unused, since we are using the non drawer filters
      handleDrawerOpen,
      handleDrawerClose,
      trainDetailSize,
      sx,
    },
  }: TrainAppTrainComponentDependencyProps<TrainGroupListContainerProps>): Perhaps<JSX.Element> => {
    // though interaction is disabled while trainProps.trainGroupSingleTrainRunProps.loading
    const crudTrainGroups = trainProps.trainGroupSingleTrainRunProps?.crudTrainGroups;
    const whatIsLoading = trainProps.trainGroupSingleTrainRunProps?.whatIsLoading;
    const trainRouteGroupsLoading = trainProps.trainRouteGroupProps?.loading;

    // TODO we shouldn't inspect trainGroupsLoadingExplanation props other than loading
    // Instead add an updating prop so we can still render this container during an update
    const loadingAndNotUpdatingTrainGroups =
      trainRouteGroupsLoading ||
      (whatIsLoading.loading &&
        whatIsLoading?.loadingExplanation?.trainGroupActivityProps?.activeTrainGroups);

    const trainGroupYardProps = useNotLoadingMemo(
      loadingAndNotUpdatingTrainGroups,
      (crudTrainGroups) => {
        return {
          crudTrainGroups,
          trainDetailSize,
        };
      },
      [trainProps.trainGroupSingleTrainRunProps.crudTrainGroups] as const,
    );

    // Show selected selectedTrainsRuns if configured. Otherwise we just show the list of filtered TrainGroups
    // Render this structure with loading or ready trafficSimComponents below
    const selectedTrainsRunGroups =
      loadingAndNotUpdatingTrainGroups || !length(crudTrainGroups) ? undefined : (
        <Stack
          {...{
            spacing: 2,
            sx: {
              px: 0,
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
            },
          }}
        >
          {loadingAndNotUpdatingTrainGroups ? (
            <LoaderWithText
              {...{
                text: 'loadingSelectedRuns',
                loadingExplanation: whatIsLoading?.loadingExplanation,
              }}
            />
          ) : (
            <TrainGroupsActiveList
              {...{
                appProps,
                organizationProps,
                trainProps,
                componentProps: trainGroupYardProps,
              }}
            />
          )}
        </Stack>
      );

    // The TrainGroups based on the filter
    const filteredTrainGroups = loadingAndNotUpdatingTrainGroups ? undefined : (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <TrainFormationsList
          key="schedule"
          {...{
            appProps,
            organizationProps,
            trainProps,
            componentProps: {
              trainDetailSize,
            },
          }}
        />
      </Box>
    );

    return (
      <Stack
        {...{
          direction: 'column',
          sx: [
            {
              height: '100%',
              overflow: 'hidden',
              overflowY: 'auto',
            },
            ...toArrayIfNot(sx),
          ],
        }}
      >
        {false && doesOrganizationHaveServiceLines(organizationProps) ? (
          <TrainRouteChooserContainer
            key="trainRouteChooser"
            {...{
              // The TrainRoute chooser to reverse the current or select a new one
              trainProps: trainProps,
              componentProps: {
                reverseTrainRouteOrGroup:
                  trainProps.trainRouteGroupProps.reverseTrainRouteOrGroup,
                chooseTrainRouteOrGroup:
                  trainProps.trainRouteGroupProps.chooseTrainRouteOrGroup,
              },
            }}
          />
        ) : undefined}
        {selectedTrainsRunGroups}
        {filteredTrainGroups}
      </Stack>
    );
  },
);

export default TrainGroupListContainer;
