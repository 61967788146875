import {trafficSimOrganizationConfigs} from 'pages/trafficsim/config/trafficSimOrganizationConfigs.ts';
import {TrafficSimConfig, TrafficSimPageConfig} from 'types/trafficsim/trafficSimConfig';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import React, {useContext} from 'react';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';
import {ACCESS_LEVELS} from 'pages/trafficsim/utils/access_levels.ts';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {find, map} from 'ramda';
import {Box, Button, MenuItem} from '@mui/material';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {OrganizationTrafficSimAppComponentDependencyProps} from 'types/propTypes/trafficSimPropTypes/organizationTrafficSimAppComponentDependencyProps';
import {useTheme} from '@mui/styles';
import {Navigate} from 'react-router-dom';
import {
    extractTrafficSimAccess,
    hasTrafficSimAdminAccess
} from "../../../appUtils/authentication/authenticationUtils.ts";
import {TRAFFIC_SIM_PAGE_NAME} from "../utils/page_names.ts";

export interface TrafficSimPageProps extends CemitComponentProps {
  handlePageClick: (page: TrafficSimPageConfig, event: Event) => void;
  handleSubpageSelect: (page: TrafficSimPageConfig, subpage: string) => void;
}

/**
 * Shows the configured TrafficSim pages based on access level
 * @param appProps
 * @param organizationProps
 * @param componentProps
 * @constructor
 */
const TrafficSimMenus = ({
  appProps,
  organizationProps,
  componentProps,
}: OrganizationTrafficSimAppComponentDependencyProps<TrafficSimPageProps>) => {
  const userState: UserStateLoaded = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;
  const theme = useTheme();

  // Get all pages or limited pages if the user only has read-only
  const pages: Perhaps<TrafficSimPageConfig[]> = useNotLoadingMemo(
    organizationProps.loading,
    (organizationProps, userState): Perhaps<TrafficSimPageConfig[]> | never => {
      const organizationSourceKey: string = organizationProps.organization.sourceKey!;
      const trafficSimConfig: Perhaps<TrafficSimConfig> = find(
        (trafficSimConfig: TrafficSimConfig) => {
          return organizationSourceKey === trafficSimConfig.organizationKey;
        },
        trafficSimOrganizationConfigs,
      );
      // Special case for admins who switch to a client that doeesn't have trafficsim.
      if (!trafficSimConfig) {
        return undefined;
      }

      const accessLevel = hasTrafficSimAdminAccess(userState) ? ACCESS_LEVELS.ADMIN : extractTrafficSimAccess(userState);
      const pages: TrafficSimPageConfig[] =
        !accessLevel ||
        accessLevel === ACCESS_LEVELS.READ_ONLY
          ? trafficSimConfig.pages.filter((p) => {
              if (p.name === TRAFFIC_SIM_PAGE_NAME.LIMITATIONS) {
                p.subpages = p.subpages.filter(
                  (subPage: string) => subPage !== TRAFFIC_SIM_PAGE_NAME.ADD_LIMITATION,
                );
              }
              return p;
            })
          : trafficSimConfig.pages;
      return pages;
    },
    [organizationProps, userState] as const,
  );
  // Special case for admins who switch to a client that doesn't have trafficsim.
  if (!organizationProps.loading && !pages) {
    return <Navigate to={'/home'} replace></Navigate>;
  }

  const pageComponents = map((page: TrafficSimPageConfig) => {
    // Subpage menu items appear when clicking a menu as denoted by appProps.expandedPages
    const maybeSubpageMenuItems = appProps.expandedPages[page.name] &&
      appProps.isSubpageMenuOpen && (
        <Box sx={{pl: 4}}>
          {' '}
          {page.subpages.map((subpage) => (
            <MenuItem
              key={subpage}
              onClick={() => componentProps.handleSubpageSelect(page, subpage)}
              sx={{
                backgroundColor: theme.palette.background.navigation,
                color:
                  appProps.activeSubPage === subpage
                    ? theme.palette.text.link
                    : theme.palette.text.tertiary,
              }}
            >
              {appProps.t(subpage)}
            </MenuItem>
          ))}
        </Box>
      );

    return (
      <div key={page.name}>
        <Button
          variant="text"
          color="secondary"
          endIcon={
            appProps.expandedPages[page.name] ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )
          }
          disableElevation
          onClick={(event) => componentProps.handlePageClick(page, event)}
          sx={{
            color:
              appProps.selectedPage === page
                ? theme.palette.text.link
                : theme.palette.text.tertiary,
            '&:hover, &:focus': {
              color: theme.palette.text.link,
              backgroundColor: 'transparent',
            },
          }}
        >
          {appProps.t(page.name)}
        </Button>
        {maybeSubpageMenuItems}
      </div>
    );
  }, pages || []);
  return <>{pageComponents}</>;
};

export default TrafficSimMenus;
