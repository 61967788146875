import {Box, Stack, Typography, Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {RailwayAlertOutlined} from '@mui/icons-material';
import {and, propEq, map, join, length, head} from 'ramda';
import {ServiceLine} from '../../../../../types/trainRouteGroups/serviceLine';
import {SxProps} from '@mui/system';
import React from 'react';
import {TrainRunSource} from '../../../../../types/trainRuns/trainRunSource';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';

/**
 * Description of the TrainRunService
 * @param showTitle Default true
 * @param serviceLine The ServiceLine
 * @param serviceLine.name The ServiceLine name to display
 * @param [source] Optional for the context of a TrainRun to show if the trains data came from
 * sensor data directly and not the NeTEx schedule
 * @param isBaseline
 * @param isBaseline
 * @param sxBox
 * @param sx Optional Style Object
 * @param sxBox
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainRouteOrGroupServiceDescription = ({
  showTitle = true,
  serviceLines,
  source = undefined,
  isBaseline = false,
  sx = {},
  sxBox = {},
}: {
  showTitle: boolean;
  serviceLines: ServiceLine[];
  source: TrainRunSource | undefined;
  isBaseline: boolean;
  sx: SxProps;
  sxBox: SxProps;
}) => {
  const {t} = useTranslation();
  // @ts-ignore
  return (
    <Stack
      {...{
        direction: 'row',
        spacing: 1,
        sx: [{maxHeight: '20px'}, ...toArrayIfNot(sx)],
      }}
    >
      {showTitle && (
        <Box sx={{width: '40px'}}>
          <Typography key="serviceTitle" variant="caption" sx={{color: 'secondary.main'}}>
            {t('service')}
          </Typography>
        </Box>
      )}
      {length(serviceLines) == 1 ? (
        <Typography
          key="serviceLineValue"
          {...{
            variant: 'caption',
            sx: {
              padding: '4px',
              color: 'secondary.main',
              lineHeight: 1,
              verticalAlign: 'middle',
              ...sxBox,
            },
          }}
        >
          {head(serviceLines).name}
        </Typography>
      ) : undefined}
      {
        // If the source is based on sensor data and not the schedule,
        // and this is not a baseline run, show an alert

        and(!isBaseline, propEq('Sensor Data', 'name', source)) && (
          <Box>
            <Tooltip arrow title={t('unscheduledTrainRun')}>
              <RailwayAlertOutlined />
            </Tooltip>
          </Box>
        )
      }
    </Stack>
  );
};
