import {FrontendView} from '../../config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {
  OrganizationLoaded,
  OrganizationMinimized,
} from '../../types/organizations/organization.ts';
import {unlessLoadingValue} from '../componentLogic/loadingUtils.ts';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {findMapped, findOrThrow, lengthAsBoolean} from '../functional/functionalUtils.ts';
import {OrganizationManifest} from '../../types/organizations/organizationManifest';
import {organizationManifests} from '../../apis/apiOrganizationConfigs/organizationManifest.ts';
import {find, includes} from 'ramda';
import {FrontendOptions} from '../../types/organizations/frontendOptions';
import {OrganizationProps} from '../../types/propTypes/organizationPropTypes/organizationProps';
import {OrganizationOrUserStateMinimized} from '../../types/userState/userState';

/**
 * Returns the frontend views configured for given organization if defined and loaded
 * @param organization
 */
export const getOrganizationViews = <T extends OrganizationMinimized>(
  organization: Perhaps<T>,
): Perhaps<FrontendView[]> => {
  return organization && 'frontendOptions' in organization
    ? (organization?.frontendOptions as FrontendOptions)?.views
    : undefined;
};

/**
 * Returns true if the organization has ServiceLines, which means they have TrainRoutes.
 * This is currently used throughout the application for conditional logic
 * Returns undefined if loading is true, meaning we can't say yet whether the organization has serviceLines
 */
export const doesOrganizationHaveServiceLines = (
  organizationProps: OrganizationProps,
): Perhaps<boolean> => {
  return unlessLoadingValue<boolean>(organizationProps.loading, () => {
    return lengthAsBoolean(organizationProps.organization!.serviceLines);
  });
};

/**
 * Returns true if the organization has the FrontendView.alerts, indicating that it can display AttributeAlertActions
 * and other types of alerts about the TrainFormations or TrainGroups
 */
export const doesOrganizationHaveAlertsView = (
  loading: boolean,
  organization: Perhaps<OrganizationLoaded>,
): Perhaps<boolean> => {
  return unlessLoadingValue<boolean>(loading, (): boolean => {
    return includes(FrontendView.alerts, organization!.frontendOptions.views);
  });
};

/**
 * Returns true if the organization has the FrontendView.wheelScan
 */
export const doesOrganizationHaveWheelScan = (
  loading: Perhaps<boolean>,
  organization: Perhaps<OrganizationLoaded>,
): Perhaps<boolean> => {
  return unlessLoadingValue<boolean>(Boolean(loading), (): boolean => {
    return includes(FrontendView.wheelScan, organization!.frontendOptions.views);
  });
};

/**
 * Given an organization with a sourceKey, resolves the matching OrganizationManifest or throws if no match is made
 * @param organizationOrUserState
 */
export const resolveOrganizationManifest = (
  organizationOrUserState: OrganizationOrUserStateMinimized,
): OrganizationManifest | never => {
  const organizationManifest = find((organizationManifest: OrganizationManifest) => {
    return (
      organizationManifest.organization.sourceKey == organizationOrUserState!.sourceKey
    );
  }, organizationManifests);
  if (!organizationManifest) {
    throw new Error(`${organizationOrUserState!.sourceKey} not found in manifest`);
  }
  return organizationManifest;
};

/**
 * Like resolveOrganizationManifest but resolves from a sourceKey
 * @param sourceKey
 */
export const resolveOrganizationManifestFromSourceKey = (
  sourceKey: string,
): OrganizationManifest | never => {
  return findOrThrow((organizationManifest: OrganizationManifest) => {
    return organizationManifest.organization.sourceKey == sourceKey;
  }, organizationManifests);
};

/**
 * Return the organizationManifest.sourceKey of an organizationManifest where its partnerOrganization.sourceKey
 * from organizationManifests if one matches organizationSourceKey. Otherwise return organizationSourceKey. This
 * a partner sourceKey from Google Firebase to resolve to the end user organization that the application expects
 * @param organizationSourceKey
 */
export const resolvePartnerOrganizationSourceKeyIfDefined = (
  organizationSourceKey: string,
): string => {
  return (
    findMapped((organizationManifest: OrganizationManifest): Perhaps<string> => {
      return organizationManifest.partnerOrganization?.sourceKey == organizationSourceKey
        ? organizationManifest.organization.sourceKey
        : undefined;
    }, organizationManifests) || organizationSourceKey
  );
};
