import React, {ReactNode, RefObject, useContext} from 'react';
import {Stack} from '@mui/material';
import TrainRunLineContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLineContainer.tsx';
import TrainDataPathStackedChartContainer from 'components/apps/trainAppComponents/trainAppChartComponents/TrainDataPathStackedChartContainer.tsx';
import AlertChartAndHoverInfoContainer from 'components/charts/stackedChart/AlertChartAndHoverInfoContainer.tsx';
import {StopLookup} from 'types/stops/stopLookup';
import {ChartDataConfig} from 'types/dataVisualizations/chartDataConfig.ts';
import {Size} from 'types/layout/size';
import {isViewActive} from 'appUtils/cemitAppUtils/viewUtils.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {getDeepestTrainServiceScope} from 'appUtils/trainAppUtils/scope/trainPropsScope.ts';
import {TrainAppTrainDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {doActiveTrainGroupsHaveTrainRuns} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import {ChartMapInteractionContext} from 'async/trainAppAsync/trainAppDependencies/sensorDependencies/ChartMapInteractionDependency.tsx';
import {ChartMapInteractionProps} from 'types/propTypes/trainPropTypes/chartMapInteractionProps';
import {useNotLoadingActiveSensorDataEligibleTrainGroups} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';

/**
 * The whole left side, including Ride Comfort and charts
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param dataPathsConfigs
 * @param componentProps
 * @param componentProps.dataPathsConfigs List of dataPathsConfigs. We show a chart for each
 * @param componentProps.customControls Optional list of custom controls to show above the
 * @param componentProps.trainDataPathStackedChartRefWidth
 * @param componentProps.trainDataPathStackedChartRef
 * @returns {JSX.Element}
 * @constructor
 */
const TrainChartDisplay = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  componentProps: {
    loading,
    referenceStopLookup,
    dataPathsConfigs,
    trainDataPathStackedChartRefWidth,
    trainDataPathStackedChartRef,
    trainDetailSize,
    otherDisplayDataPaths,
  },
}: TrainAppMapDependencyProps & {
  componentProps: {
    loading: boolean;
    referenceStopLookup: StopLookup;
    dataPathsConfigs: ChartDataConfig[];
    trainDataPathStackedChartRefWidth: number;
    trainDataPathStackedChartRef: RefObject<typeof TrainDataPathStackedChartContainer>;
    trainDetailSize: Size;
    otherDisplayDataPaths: ChartDataConfig[];
  };
}) => {
  const chartMapInteractionProps: Perhaps<ChartMapInteractionProps> =
    useContext<ChartMapInteractionProps>(ChartMapInteractionContext);

  // The chart y-axis width. Used to make the y-axis label not overlap
  const yAxisWidth = 75;
  // This approximation of the chart position based on yAxisWidth and a bit of buffer
  // to make sure the stops of TrainRunLine match the chart points
  const trainRunLayoutProps = {
    left: `${yAxisWidth - 5}px`,
    width: `${(trainDataPathStackedChartRefWidth || 0) - (yAxisWidth + 10)}px`,
    height: '38px',
  };
  const maybeAdjustableTrainRunLineContainer =
    trainDataPathStackedChartRefWidth &&
    // TODO not used, out-of-date
    isViewActive(appProps, FrontendView.trainRoute) ? (
      <TrainRunLineContainer
        key="trainRunLineAdjustable"
        {...{
          appProps,
          organizationProps,
          trainProps,
          componentProps: {
            showTrainRunOrGroupIntervalBars: true,
            spaceGeospatially: true,
            onlyStopsNearInterval: false,
            isTrainRouteLine: true,
            isTrainGroupDetailLine: false,
            trainDetailSize,
          },
          sx: {...trainRunLayoutProps, minHeight: '80px', maxHeight: '80px'},
        }}
      />
    ) : undefined;

  const showCharts = lengthAsBoolean(
    useNotLoadingActiveSensorDataEligibleTrainGroups(
      false,
      trainProps.trainGroupActivityProps.activeTrainGroups,
    ) || [],
  );
  const readSingleTrainRunLineContainer: Perhaps<ReactNode> =
    trainDataPathStackedChartRefWidth &&
    doActiveTrainGroupsHaveTrainRuns(
      trainProps.trainGroupActivityProps.activeTrainGroups,
    ) ? (
      <TrainRunLineContainer
        key="trainRunLineReadonly"
        {...{
          appProps,
          organizationProps,
          trainProps,
          componentProps: {
            // Limit the distanceRange to what the user has chosen
            showLimitedDistanceRange: loading
              ? undefined
              : getDeepestTrainServiceScope(trainProps).trainDistanceInterval
                  .distanceRange,
            showTrainRunOrGroupIntervalBars: false,
            spaceGeospatially: true,
            onlyStopsNearInterval: false,
            isTrainRouteLine: true,
            isTrainGroupDetailLine: false,
            trainDetailSize,
          },
          sx: {...trainRunLayoutProps, minHeight: '70px'},
        }}
      />
    ) : undefined;

  return (
    <Stack
      key="trainChartDisplay"
      {...{
        spacing: 2,
        sx: {
          position: 'relative',
          minWidth: 0,
          width: '100%',
          height: '100%',
        },
      }}
    >
      <Stack>
        {/* This shows ride comfort, and hover data from charts and maps */}
        <AlertChartAndHoverInfoContainer
          key="chartAndMapDataContainer"
          {...{
            appProps,
            organizationProps,
            trainProps,
            mapProps,
            componentProps: {
              trainGroups:
                trainProps.trainGroupActivityProps.activeTrainGroupsWithoutErrors,
              dataPathsConfigs,
              referenceStopLookup,
              mostRecentTooltipPayload: chartMapInteractionProps.mostRecentTooltipPayload,
              setMostRecentTooltipPayload:
                chartMapInteractionProps.setMostRecentTooltipPayload,
              otherDisplayDataPaths,
            },
          }}
        />
      </Stack>
      {showCharts
        ? [
            // This is the adjustable bar that shows the full train route when they exist
            maybeAdjustableTrainRunLineContainer,
            <TrainDataPathStackedChartContainer
              key="trainDataPathStackedChart"
              {...{
                ref: trainDataPathStackedChartRef,
                appProps,
                organizationProps,
                trainProps,
                componentProps: {
                  yAxisWidth,
                  dataPathsConfigs,
                  trainDataPathStackedChartRefWidth,
                },
              }}
            />,
            // This is the read-only bar that shows the current range of the dataVisualizations
            readSingleTrainRunLineContainer,
          ]
        : undefined}
    </Stack>
  );
};

export default TrainChartDisplay;
