/**
 * Returns true if the object represents a Ramda view
 * @param obj
 * @param props
 * @returns {*}
 */
import { has } from 'ramda';
import { CemitFilter } from '../../types/cemitFilters/cemitFilter.d.ts';

export const isView = (cemitFilter: CemitFilter) => {
  return has('view', cemitFilter);
};
export const isEquals = (cemitFilter: CemitFilter) => {
  return has('equals', cemitFilter);
};