import {
  TrainRouteGroup,
  TrainRouteGroupMinimized,
  TrainRouteGroupDerived,
} from '../../types/trainRouteGroups/trainRouteGroup';
import {cemitedConstructor} from '../cemitAppCemitedClasses/cemitClassConstructor.ts';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {TrainRoute, TrainRouteMinimized} from '../../types/trainRouteGroups/trainRoute';
import {OrderedRoutePointDerived} from '../../types/trainRouteGroups/orderedRoutePoint';
import {
  TrainRouteOrGroupTrackData,
  TrainRouteOrGroupTrackDataDerived,
} from '../../types/trainRouteGroups/trainRouteOrGroup';
import {Feature, LineString, Point} from 'geojson';
import {Track} from '../../types/railways/track';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {ScheduledStopPoint} from '../../types/stops/scheduledStopPoint';
import {TrackRoute} from '../../types/railways/trackRoute';
import {DirectionType} from '../../types/trainRouteGroups/directionType.ts';
import {Operator} from '../../types/operators/operator';
import {ServiceLine} from '../../types/trainRouteGroups/serviceLine';
import {serviceLinesOfTrainRouteOrGroup} from '../../appUtils/trainAppUtils/serviceLineUtils/serviceLineUtils.ts';
import {bboxOfTrackRoutesMemoized} from '../../appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import {map, omit, prop} from 'ramda';
import {CemitedClass} from '../cemitAppCemitedClasses/cemitedClass.ts';
import {IdentifiedClass} from '../cemitAppCemitedClasses/identifiedClass.ts';

export class TrainRouteGroupMinimizedClass
  extends IdentifiedClass
  implements TrainRouteGroupMinimized
{
  constructor(obj: TrainRouteGroupMinimized) {
    // Omit getter only property.
    const objWithoutTrainRoutes = omit(['trainRoutes'], obj);
    super(objWithoutTrainRoutes);
    cemitedConstructor(obj, this);
  }

  reverseTrainRouteGroupId?: any;
  __typename = CemitTypename.trainRouteGroupMinimized;
  trainRoutes: TrainRouteMinimized[];
}

export class TrainRouteGroupClass
  extends TrainRouteGroupMinimizedClass
  implements TrainRouteGroup
{
  constructor(obj: TrainRouteGroup) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainRouteGroup;

  orderedRailwayTrackRoutes: TrackRoute[];
  trackRouteLineString: Feature<LineString>;
  directionType?: DirectionType;
  startScheduledStopPoint?: ScheduledStopPoint;
  endScheduledStopPoint?: ScheduledStopPoint;
  operator?: Operator;
  operatorId?: string;
  serviceLine: ServiceLine;

  // Returns the serviceLine as an array or the unique serviceLines of the trainRouteGroups
  get serviceLines() {
    return serviceLinesOfTrainRouteOrGroup(this as TrainRouteGroup);
  }

  /**
   * The bbox of the combined trackRoute geojsons
   */
  get bbox() {
    return bboxOfTrackRoutesMemoized(map(prop('trackRoute'), this.trainRoutes));
  }

  declare trainRoutes: TrainRoute[];
}

export class TrainRouteGroupDerivedClass
  extends TrainRouteGroupClass
  implements TrainRouteGroupDerived
{
  constructor(obj: TrainRouteGroupDerived) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainRouteGroupDerived;
  orderedRoutePoints: OrderedRoutePointDerived[];
  trackData: TrainRouteOrGroupTrackDataDerived;
}

export class TrainRouteOrGroupTrackDataMinimizedClass
  extends CemitedClass
  implements TrackDataMinimized
{
  constructor(obj: TrackDataMinimized) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trackDataMinimized;
}

export class TrainRouteOrGroupTrackDataClass
  extends TrainRouteOrGroupTrackDataMinimizedClass
  implements TrainRouteOrGroupTrackData
{
  constructor(obj: TrainRouteOrGroupTrackData) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainRouteOrGroupTrackData;
  trackAsLineString: Feature<LineString>;
  orderedTracks: [Track];
}

export class TrainRouteOrGroupTrackDataDerivedClass
  extends TrainRouteOrGroupTrackDataClass
  implements TrainRouteOrGroupTrackDataDerived
{
  constructor(obj: TrainRouteOrGroupTrackDataDerived) {
    super(obj);
    cemitedConstructor(obj, this);
  }

  __typename = CemitTypename.trainRouteOrGroupTrackDataDerived;
  lineSegments: Feature<LineString>[];
  boundingBox: number[];
  center: Feature<Point>;
  referencePointDistance?: Perhaps<number>;
  measureDistancesFrom?: Perhaps<ScheduledStopPoint>;
}
