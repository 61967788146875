import {useDrop} from 'react-dnd';
import {TrainProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {CrudList} from '../../../../types/crud/crudList';
import {TrainRun} from '../../../../types/trainRuns/trainRun';
import {RouteDistanceWithOffsetLeft} from '../../../../types/distances/routeDistanceWithOffsetLeft';
import {ConnectDropTarget} from 'react-dnd/src/types';
import {TrainRunDrop} from '../../../../types/dragNDrop/trainRunDrop';
import {DragItemType} from '../../../../types/dragNDrop/dragItemType.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {
  TrainRouteOrGroup,
  TrainRouteOrGroupDerived,
} from '../../../../types/trainRouteGroups/trainRouteOrGroup';
import {updateTrainGroupTrainDistanceInterval} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {TrainRouteOrGroupLineFinalizedProps} from 'types/propTypes/trainPropTypes/trainRouteOrGroupLineProps.d.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * useDrop hook to accept the moving of a TrainRunLine
 * @param areOffsetLeftsReady
 * @param [crudTrainGroups] Only needed for TrainRunOrGroupIntervalBars specific to the user
 * @param trainRun
 * @param trainRoute
 * @param stopsWithOffsetLefts
 * @returns {*}
 */
export const useTrainRunLineMoverDrop = ({
  trainProps,
  componentProps: {
    isTrainRouteLine,
    areOffsetLeftsReady,
    crudTrainGroups,
    trainRun,
    trainRoute,
    routeDistancesWithOffsetLefts,
  },
}: {
  trainProps: TrainProps;
  componentProps: {
    isTrainRouteLine: boolean;
    areOffsetLeftsReady: boolean;
    crudTrainGroups: CrudList<TrainGroup>;
    trainRun: TrainRun;
    trainRoute: Perhaps<TrainRouteOrGroup | TrainRouteOrGroupDerived>;
    routeDistancesWithOffsetLefts: RouteDistanceWithOffsetLeft[];
  };
}): [TrainRunDrop, ConnectDropTarget] => {
  // TODO not sure about the types here
  return useDrop<TrainRouteOrGroupLineFinalizedProps, TrainRunDrop, TrainRunDrop>(() => {
    return {
      accept: [
        DragItemType.trainDistanceIntervalBarMover,
        DragItemType.trainDistanceIntervalBarRightExpander,
        DragItemType.trainDistanceIntervalBarLeftExpander,
      ],
      drop: (
        {
          trainGroup,
          spaceGeospatially,
          limitedTrainDistanceInterval,
          parentWidth,
        }: TrainRunDrop,
        monitor,
      ) => {
        // If the hook was called before offsetLefts are computed, ignore drop actions
        if (!areOffsetLeftsReady) {
          return;
        }
        // If a drop occurred without altering the offset of the x axis, do nothing
        const offsetDifference = monitor.getDifferenceFromInitialOffset()?.x;
        if (!offsetDifference) return;

        return updateTrainGroupTrainDistanceInterval(trainGroup, {
          spaceGeospatially,
          limitedTrainDistanceInterval,
          parentWidth,
        } as TrainRouteOrGroupLineFinalizedProps);
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    };
  }, [areOffsetLeftsReady, trainRun, crudTrainGroups?.list]);
};
