import React, {ComponentType, createElement, ReactNode} from 'react';
import {addIndex, map} from 'ramda';
import {toArrayIfNot} from '../functional/functionalUtils.ts';

/**
 * creatElement for each of children with the given props
 * @param props
 * @param children
 * @return {*}
 */
export const createChildElements = (
  props: Record<string, any>,
  ...children: React.ReactNode[]
) => {
  return addIndex(map)((child: ReactNode, index: number) => {
    // createElement for one or more children
    return createElement(child, {
      key: child?.name || child?.displayName || index,
      ...props,
    });
  }, toArrayIfNot(children));
};

/**
 * Like createChildElements but can force a rerender by changing the child key with the given prefix
 * @param prefixKey
 * @param props
 * @param children
 */
export const createChildElementsWithKey = (
  prefixKey: string,
  props: Record<string, any>,
  ...children: ComponentType[]
): React.ReactNode => {
  return addIndex(map)((child: ReactNode, index: number) => {
    // createElement for one or more children
    return createElement(child, {
      key: `${prefixKey}${child?.name || child?.displayName || index}`,
      ...props,
    });
  }, children);
};
