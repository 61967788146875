import {PropStatus} from 'types/propTypes/organizationPropTypes/propStatus';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Derived} from 'types/cemitTypes/derived';
import {Loaded} from 'types/cemitTypes/loaded';
import {CemitedClass} from '../cemitAppCemitedClasses/cemitedClass.ts';
import {WhatIsLoading} from 'types/dependencies/whatIsLoading';

export class PropStatusClass<T, LOADED extends T & Loaded, DERIVED extends T & Derived>
  extends CemitedClass
  implements PropStatus<T, LOADED, DERIVED>
{
  loading: boolean;
  whatIsLoading: WhatIsLoading;

  get maybeAsDerived(): Perhaps<DERIVED> {
    return undefined;
  }

  get maybeAsLoaded(): Perhaps<LOADED> {
    return undefined;
  }
}
