import {always, cond, equals, head, ifElse, join, T} from 'ramda';
import React from 'react';
import {
  addTrainFormationsToCemitFilter,
  evalCemitFilterTrainFormation,
  extractLabelsForTrainFormations,
  extractTrainFormationsAsTrainGroups,
  isCemitFilterTrainFormation,
} from 'appUtils/cemitFilterUtils/cemitFilterTrainFormationUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {hasNonZeroLength} from 'utils/functional/functionalUtils.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {
  replaceCemitFiltersOfType,
  useMemoExtractFilterInstanceLabels,
} from 'appUtils/cemitFilterUtils/cemitFilterUtils.ts';
import {CemitFilterTrainFormationProps} from 'types/cemitFilters/cemitFilterTrainFormationProps.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {
  FrontendView,
  FrontendViewType,
} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {trainFormationsGrouping} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendViewGroups.ts';
import CemitFilterTrainFormationsViewMenu from './CemitFilterTrainFormationsViewMenu.tsx';
import CemitFilterTrainFormationsViewList from './CemitFilterTrainFormationsViewList.tsx';
import {clsOrType, ts} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {CemitFilterTrainFormationsViewProps} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsViewProps.ts';

export interface CemitFilterTrainFormationsContainerProps extends CemitComponentProps {
  // The number of instances that can be in the filter.
  maximumAllowed?: Perhaps<number>;
  // If true, a clicked item will be added to trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormation
  // instead of replaces those in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormation if it keeps the
  // total number at or below maximumAllowed
  addWidthModifierKey?: Perhaps<boolean>;
  acceptTypes?: FrontendViewType[];
  // Force the FrontendView instead of using head(appProps.activeFrontendViewsOfGroup(trainGroupsGrouping))
  forcedActiveFrontendView?: FrontendView;
  // For menu mode, this indicates a TrainGroupOnlyTrainFormation that is in
  // trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations. When the user selects a different
  // TrainGroup, it will replace this one in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations
  scopedTrainGroup?: Perhaps<TrainGroupOnlyTrainFormation>;
  // If true, no TrainGroupOnlyTrainFormation will be shown as selected, but a plus icon will indicate
  // that a TrainGroup from trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations that are not already
  // in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations
  addTrainGroupMode?: Perhaps<boolean>;
}

/**
 * A list or menu of TrainGroupOnlyTrainFormation instances, meaning a menu or list of trainsets.
 * trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations is used to determine what TrainGroupOnlyTrainFormation
 * instances are selected.
 * trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations represents the available TrainGroupOnlyTrainFormations
 * to select, which may add or replace that in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations, depending
 * on componentProps.maximumAllowed.
 * If componentProps.addWithModifierKey is true and componentProps.maximumAllowed is greater than the instances
 * in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations, then clicking on a list item with a modifier
 * key will add the item to trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations. Otherwise it will cause
 * the clicked item to replace to item(s) in trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations
 * Returns the compact or long form of a list of TrainGroupOnlyTrainFormation
 * based on appProps.activeFrontendViewsOfGroup(trainFormationsGrouping)
 * Returns undefined if trainProps.trainGroupOnlyTrainFormationProps.loading of if the type of
 * appProps.activeFrontendViewsOfGroup(trainFormationsGrouping) is not in componentProps.acceptTypes if the latter is defined
 */
const CemitFilterTrainFormationsContainer = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<CemitFilterTrainFormationsContainerProps>):
  | undefined
  | React.JSX.Element
  | void => {
  const loading = trainProps.trainGroupOnlyTrainFormationProps.loading;
  if (loading) {
    return undefined;
  }

  const t = appProps.t;
  // The only props the filter needs are the eligible formations so that trainFormationIds can resolve to TrainFormations
  const cemitFilterTrainFormationProps = clsOrType<CemitFilterTrainFormationProps>(
    CemitTypename.cemitFilterTrainFormationProps,
    {
      trainGroupOnlyTrainFormations:
        trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations,
    },
  );

  const cemitFilterWithTrainFormations =
    trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations;
  type CondParams = {
    cemitFilterWithTrainFormations: Perhaps<CemitFilter>;
    scopedTrainGroup: Perhaps<TrainGroupOnlyTrainFormation>;
    addTrainGroupMode: Perhaps<Boolean>;
  };
  // The label can be an add icon, the name of the scopedTrainGroup, or the
  const formationLabelsFromFilter = cond<[CondParams], string[]>([
    [
      // Add Icon
      ({addTrainGroupMode}) => Boolean(addTrainGroupMode),
      () => {
        return [''];
      },
    ],
    [
      // scopedTrainGroup label
      ({scopedTrainGroup}) => Boolean(scopedTrainGroup),
      ({scopedTrainGroup}) => {
        return [scopedTrainGroup!.localizedName()];
      },
    ],
    [
      // Labels from filter
      T,
      ({cemitFilterWithTrainFormations}) => {
        return useMemoExtractFilterInstanceLabels<CemitFilterTrainFormationProps>(
          extractLabelsForTrainFormations,
          cemitFilterWithTrainFormations,
          cemitFilterTrainFormationProps,
        );
      },
    ],
  ])({
    cemitFilterWithTrainFormations,
    scopedTrainGroup: componentProps.scopedTrainGroup,
    addTrainGroupMode: componentProps.addTrainGroupMode,
  } as CondParams);

  const formationsLabel = ifElse<[string[]], string, string>(
    hasNonZeroLength,
    join(', '),
    always(t('allTrainFormations')),
  )(formationLabelsFromFilter);

  /**
   * Add the given TrainGroupOnlyTrainFormation to the cemitFilterWithTrainFormations
   * @param trainGroupOnlyTrainFormation
   */
  const handleAddTrainGroupOnlyTrainFormationToFilter = (
    trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation,
  ): void => {
    addTrainFormationsToCemitFilter(
      cemitFilterWithTrainFormations!,
      trainProps.trainGroupOnlyTrainFormationProps?.setCemitFilterWithTrainFormations!,
      [trainGroupOnlyTrainFormation],
      cemitFilterTrainFormationProps,
      componentProps.maximumAllowed,
    );
  };
  /**
   * Remove the given formation from the cemitFilterWithTrainFormations
   * @param trainGroupOnlyTrainFormation
   */
  const handleRemoveTrainGroupOnlyTrainFormationFromFilters = (
    trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation,
  ) => {
    const updatedCemitFilterFormations = replaceCemitFiltersOfType(
      isCemitFilterTrainFormation,
      evalCemitFilterTrainFormation,
      cemitFilterWithTrainFormations,
      trainGroupOnlyTrainFormation,
      cemitFilterTrainFormationProps,
    );
    trainProps.trainGroupOnlyTrainFormationProps.setCemitFilterWithTrainFormations(
      updatedCemitFilterFormations,
    );
  };

  // Get the chosenFormations in the filter
  const chosenTrainGroupOnlyTrainFormations = extractTrainFormationsAsTrainGroups(
    cemitFilterWithTrainFormations,
    cemitFilterTrainFormationProps,
  );
  const trainGroupFormations =
    trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations;

  const activeFrontendView =
    componentProps.forcedActiveFrontendView ||
    head(appProps.activeFrontendViewsOfGroup(trainFormationsGrouping));
  const childComponentProps = ts<CemitFilterTrainFormationsViewProps>({
    readonly: false,
    formationsLabel,
    trainGroupFormations,
    chosenTrainGroupOnlyTrainFormations,
    addTrainGroupMode: componentProps.addTrainGroupMode,
    maximumAllowed: componentProps.maximumAllowed,
    scopedTrainGroup: componentProps.scopedTrainGroup,
    handleAddTrainGroupOnlyTrainFormationToFilter,
    handleRemoveTrainGroupOnlyTrainFormationFromFilters,
  });

  // Return the active view or trainFormationsGrouping
  return cond([
    [
      equals(FrontendView.trainFormationMenu),
      () => {
        return componentProps.acceptTypes &&
          !componentProps.acceptTypes.includes(FrontendViewType.compact) ? undefined : (
          <CemitFilterTrainFormationsViewMenu
            key="compact"
            {...{
              appProps,
              trainProps,
              componentProps: childComponentProps,
            }}
          />
        );
      },
    ],
    [
      equals(FrontendView.trainFormationList),
      () => {
        return componentProps.acceptTypes &&
          !componentProps.acceptTypes.includes(FrontendViewType.large) ? undefined : (
          <CemitFilterTrainFormationsViewList
            key="large"
            {...{
              appProps,
              trainProps,
              componentProps: childComponentProps,
            }}
          />
        );
      },
    ],
    [
      T,
      (frontendView: FrontendView) => {
        throw new Error(`Unexpected frontendView: ${frontendView}`);
      },
    ],
  ])(activeFrontendView);
};
export default CemitFilterTrainFormationsContainer;
