import {compact, strPathOr} from '@rescapes/ramda';
import {Grid} from '@mui/material';
import {concat, equals, ifElse, includes, is, join, map, when} from 'ramda';
import CaptionTypography from 'components/atoms/typography/CaptionTypography.tsx';
import React from 'react';
import {
  ChartDataConfig,
  ChartDataConfigWithCustomControls,
  RecahrtsDataPathFunc,
  RechartsDataPath,
} from '../../../../../types/dataVisualizations/chartDataConfig.ts';
import {resolveCommonConfig} from '../../../../../utils/dataFeatures/dataConfigUtils.ts';
import {toArrayIfNot} from '../../../../../utils/functional/functionalUtils.ts';

export const angleToCardinalDirection = (angle: number) => {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  return directions[Math.round(((angle + 360) % 360) / 45) % 8];
};

export const createPropertyPathGridItems = (
  propertyOrDataPathConfigs: ChartDataConfigWithCustomControls[],
  dataKey: string,
  data: any,
  otherDisplayDataPaths: ChartDataConfig[],
) => {
  return propertyOrDataPathConfigs
    ? compact(
        map(
          (chartDataConfig: ChartDataConfigWithCustomControls | ChartDataConfig) => {
            const chosenDataPath = equals(dataKey, chartDataConfig.yAxisDataPath);
            const commonConfig = resolveCommonConfig(chartDataConfig);
            const labelYAxis = chartDataConfig.labelYAxis || commonConfig?.labelYAxis;
            // TODO it seems one of these can be an array, but it's not documented in Typescript
            const dataPath: RechartsDataPath | RechartsDataPath[] =
              chartDataConfig.yAxisDataPath || dataKey;
            const values = map((dataPath: RechartsDataPath) => {
              // dataPath can be a function or a string path into the data
              const value = ifElse(
                is(Function),
                (func: RecahrtsDataPathFunc) => {
                  return func(data);
                },
                (dataPath: string) => {
                  return strPathOr('N/A', dataPath, data) as string;
                },
              )(dataPath);

              // TODO hack, use a new property like valueFormatter
              if (
                includes(dataPath, ['properties.meters', 'properties.time']) &&
                chartDataConfig.xAxisTickFormatter
              ) {
                return chartDataConfig.xAxisTickFormatter(value);
              } else {
                return when(is(Number), (value) => {
                  return value.toFixed(chartDataConfig.toFixedValue || 3);
                })(value);
              }
            }, toArrayIfNot(dataPath));
            // Used for chartDataConfig without a common config
            const label =
              chartDataConfig.label ||
              // Used for chartDataConfig with a common config that differs from the label of the common config
              // (the y axis label)
              chartDataConfig.popupLabel ||
              // The common config label
              commonConfig?.label;

            // TODO hack. The non-chosen datapath values should be viewable too with an exand button
            return chosenDataPath || chartDataConfig.alwaysShow ? (
              <Grid key={label} container spacing={1}>
                <Grid item>
                  <CaptionTypography
                    sx={{fontWeight: chosenDataPath ? 'bold' : 'normal'}}
                  >
                    {
                      // Label can be static or takes values as input}
                      when(is(Function), (func) => {
                        return func(values);
                      })(label)
                    }
                  </CaptionTypography>
                </Grid>
                <Grid item>
                  <CaptionTypography>
                    {
                      // Combine the values with the type label (if available)
                      join(
                        ' ',
                        compact([
                          join(
                            ', ',
                            // dataPath can be scalar or an array. If array join with ','
                            values,
                          ),
                          labelYAxis,
                        ]),
                      )
                    }
                  </CaptionTypography>
                </Grid>
              </Grid>
            ) : undefined;
          },
          concat(propertyOrDataPathConfigs, otherDisplayDataPaths),
        ),
      )
    : [];
};
