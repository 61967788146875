import { cond, propOr, T, when } from 'ramda';
import { CemitTypename } from '../../types/cemitTypename.ts';
import { implementsCemitTypeViaClass } from '../cemitAppCemitedClasses/cemitClassResolvers.ts';
import { TrainRouteOrGroup, TrainRouteOrGroupMinimized } from '../../types/trainRouteGroups/trainRouteOrGroup';
import { TrainRouteGroup } from '../../types/trainRouteGroups/trainRouteGroup';
import { TrainRoute } from '../../types/trainRouteGroups/trainRoute';
import { onlyOneValueOrThrow } from '../../utils/functional/functionalUtils.ts';
import {clsOrType} from "../../appUtils/typeUtils/clsOrType.ts";

interface TrainRouteOrGroupTypeMappings {
  trainRoute: CemitTypename,
  trainRouteGroup: CemitTypename,
}

/**
 * Creates a TrainRoute or TrainRouteGroup based on the trainRouteOrGroup.__typename.
 * If trainRouteGroups is passed into trainRouteOrGroup but trainRouteOrGroup is of type trainRoute,
 * then head(trainRouteGroups) is used for the trainRoute
 * @param trainRouteOrGroup
 * @param typeMappings
 * @params typeMappings optionall maps keys trainRoute and trainRouteGroup to child types.
 * Defaults to {
 *  trainRoute: CemitTypename.trainRoute,
 *   trainRouteGroup: CemitTypename.trainRouteGroup
 * }
 */
export const createTrainRouteOrGroup = (
  trainRouteOrGroup: TrainRouteOrGroup | TrainRouteOrGroupMinimized,
  typeMappings: TrainRouteOrGroupTypeMappings = {
    trainRoute: CemitTypename.trainRoute,
    trainRouteGroup: CemitTypename.trainRouteGroup
  }
): TrainRouteOrGroup | never => {

  return <TrainRouteOrGroup>cond([
    [
      (trainRouteOrGroup: TrainRouteOrGroup) => {
        // Does the typename of trainRouteOrGroup represent an interface that implements
        // the interface TrainRouteGroupMinimized
        return implementsCemitTypeViaClass(CemitTypename.trainRouteGroupMinimized, trainRouteOrGroup);
      },
      (trainRouteGroup: TrainRouteOrGroup) => {
        return clsOrType<TrainRouteGroup>(typeMappings.trainRouteGroup, trainRouteGroup);
      }
    ],
    [
      (trainRouteOrGroup: TrainRouteOrGroup) => {
        // Does the typename of trainRouteOrGroup represent an interface that implements
        // the interface TrainRouteMinimized
        return implementsCemitTypeViaClass(CemitTypename.trainRouteMinimized, trainRouteOrGroup);
      },
      (trainRouteOrGroup: TrainRouteOrGroup) => {
        // Use trainRoute.trainRouteGroups[0] as the TrainRoute if defined. Else use trainRouteOrGroup
        const trainRoute = when(
          propOr(undefined, 'trainRoutes'),
          (trainRoute: TrainRoute) => {
            return onlyOneValueOrThrow(trainRoute.trainRoutes);
          }
        )(trainRouteOrGroup);
        return clsOrType<TrainRoute>(typeMappings.trainRoute, trainRoute) as TrainRouteOrGroup;
      }
    ],
    [
      T,
      (trainRouteOrGroup: TrainRouteOrGroup) => {
        throw new Error(`Unexpected typename ${trainRouteOrGroup.__typename}`);
      }
    ]
  ])(trainRouteOrGroup);
};
