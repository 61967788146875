import {BrakingAlertLevels} from 'types/alerts/braking/brakingAlertLevels';
import {AlertLabels} from 'types/alerts/alertLabels';
import {TFunction} from 'i18next';
import {BrakingAttributeAlertLevel} from 'types/alerts/braking/brakingAttributeAlertLevel.ts';

/**
 * The API marks the alert level with these numbers
 * From graphql: element?.[1]?.node?.metadata?.['s-value'];
 */
export enum BrakingAttributeAlertSValue {
  none = 0,
  // Soft braking: Deceleration between 0.2 m/s² and 1.0 m/s²
  softBraking = 0.2,
  // Normal braking: Deceleration between 1.0 m/s² and 1.5 m/s²
  normalBraking = 1.0,
  // Hard braking: Deceleration between 1.5 m/s² and 2.25 m/s²
  hardBraking = 1.5,
  // Emergency braking: Deceleration between 2.25 m/s² and 3.0 m/s²
  emergencyBraking = 2.25,
  // Full Emergency braking: Deceleration greater than 3.0 m/s²
  fullEmergencyBraking = 3.0,
}

export const brakingLevelToAttribute: BrakingAlertLevels = {
  L0: BrakingAttributeAlertLevel.softBraking,
  L1: BrakingAttributeAlertLevel.normalBraking,
  L2: BrakingAttributeAlertLevel.hardBraking,
  L3: BrakingAttributeAlertLevel.emergencyBraking,
  L4: BrakingAttributeAlertLevel.fullEmergencyBraking,
} as BrakingAlertLevels;

export const brakingLevelToAttributeForType: BrakingAlertLevels = {
  'Soft braking': BrakingAttributeAlertLevel.softBraking,
  'Normal braking': BrakingAttributeAlertLevel.normalBraking,
  'Hard Braking': BrakingAttributeAlertLevel.hardBraking,
  'Emergency braking': BrakingAttributeAlertLevel.emergencyBraking,
  'Full Emergency braking': BrakingAttributeAlertLevel.fullEmergencyBraking,
} as BrakingAlertLevels;

export const brakingAlertLevelToLabel = (t: TFunction): AlertLabels => {
  return {
    L0: t('softBraking'),
    L1: t('normalBraking'),
    L2: t('hardBraking'),
    L3: t('emergencyBraking'),
    L4: t('fullEmergencyBraking'),
  };
};
