import {Box, Stack} from '@mui/material';
import {addIndex, always, cond, equals, length, map, T} from 'ramda';
import TrainLineStationContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainLineStationContainer.tsx';
import StationLineContainer from 'components/apps/trainAppComponents/chooserComponents/trainRunChooserComponents/StationLineContainer.tsx';
import React, {MutableRefObject} from 'react';
import {TrainStationLineProps} from 'types/propTypes/trainPropTypes/trainStationLIneProps';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {TrainProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {ScheduledStopPoint} from '../../../../types/stops/scheduledStopPoint';
import HoverFeature from './HoverFeature.tsx';
import {StopGap} from '../../../../types/stops/stopGap';
import {Size} from '../../../../types/layout/size';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {SxProps} from '@mui/system';
import {ConnectDropTarget} from 'react-dnd/src/types';
import {LoadingExplanation} from '../../../../types/async/loadingExplanation';
import {toArrayIfNot} from '../../../../utils/functional/functionalUtils.ts';

/**
 * Defines a TrainRunLine based on a TrainRun or a Route
 * @param loadingExplanation Debugging only. Explains what's not loaded from the parent
 * @param moverDrop
 * @param isMoverOver
 * @param stopGaps
 * @param spaceGeospatially
 * @param ref
 * @param trainLineStationPropSets Props for creating TrainLineStation trafficSimComponents offsetLefts must be initialized to an array
 * of undefined items by useInitStopOffsetLefts in trainRunLineReadyHookProps before this can run
 * @param hoverFeatureComponent Component of the current SensorDataPoint geojson feature that is hovered on the map relevant to this
 * TrainRunLine's TrainRoute or TrainGroup. This is positioned absolutely on the TrainRun line
 * @param sx
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunLine = ({
  appProps,
  trainProps,
  componentProps: {
    loadingExplanation,
    moverDrop,
    isMoverOver,
    stopGaps,
    spaceGeospatially,
    containerRef,
    trainLineStationPropSets,
    hoverFeatureComponent,
    isTrainRouteLine,
    isTrainGroupDetailLine,
    hoveredScheduledStopPoint,
    setHoveredScheduledStopPoint,
    trainDetailSize,
    orientation = 'horizontal',
  },
  sx = [],
}: {
  appProps: TrainAppProps;
  trainProps: TrainProps;
  componentProps: {
    loadingExplanation: LoadingExplanation;
    moverDrop: ConnectDropTarget;
    isMoverOver: boolean;
    stopGaps: StopGap[];
    spaceGeospatially: boolean;
    containerRef: MutableRefObject<typeof Stack>;
    trainLineStationPropSets: TrainStationLineProps[];
    hoverFeatureComponent: typeof HoverFeature;
    isTrainRouteLine: boolean;
    isTrainGroupDetailLine: boolean;
    hoveredScheduledStopPoint: ScheduledStopPoint;
    setHoveredScheduledStopPoint: StateSetter<ScheduledStopPoint>;
    trainDetailSize: Size;
    orientation: string;
  };
  sx: SxProps;
}) => {
  const trainLineStationComponents: (typeof TrainLineStationContainer)[] = addIndex(map)(
    (trainLineStationProps: TrainStationLineProps, index) => {
      return (
        <TrainLineStationContainer
          key={trainLineStationProps.key}
          {...{
            appProps,
            trainProps,
            componentProps: {
              isTrainRouteLine,
              ...trainLineStationProps,
              stationPosition: cond([
                [({index}) => equals(0, index), always('start')],
                [({index, count}) => equals(count - 1, index), always('end')],
                [T, always(undefined)],
              ])({index, count: length(trainLineStationPropSets)}),
              hoveredScheduledStopPoint,
              setHoveredScheduledStopPoint,
              trainDetailSize,
            },
          }}
        />
      );
    },
    trainLineStationPropSets,
  );

  return (
    <Stack
      ref={(node) => {
        moverDrop(node);
        // @ts-ignore don't understand this yet
        containerRef(node);
      }}
      alignItems="center"
      sx={[
        {
          position: 'relative',
          justifyContent: 'center',
          color: 'secondary.main',
        },
        ...toArrayIfNot(sx),
      ]}
    >
      {
        /* This is the horizontal/vertical line. It is positioned absolute, starting from the first station node and ending at the last

      */
        <StationLineContainer
          {...{
            loading: !stopGaps,
            loadingExplanation,
            trainProps,
            isTrainGroupDetailLine,
            isOver: isMoverOver,
            stopGaps,
            spaceGeospatially,
            orientation,
          }}
        />
      }

      {/* alignItems: flex-start This lines the dots up with the TrainRun line */}
      {spaceGeospatially ? (
        <Box
          key="trainLineStations"
          direction="row"
          spacing={0.5}
          sx={{
            position: 'relative',
            zIndex: 2,
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          {trainLineStationComponents}
          {hoverFeatureComponent}
        </Box>
      ) : (
        <>
          <Stack
            key="trainLineStations"
            direction="row"
            spacing={0.5}
            sx={{
              zIndex: 2,
              width: 1,
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            {trainLineStationComponents}
          </Stack>
          {hoverFeatureComponent}
        </>
      )}
    </Stack>
  );
};

export default TrainRunLine;
