import {Link, useNavigate} from 'react-router-dom';
import {Button, Stack, Typography} from '@mui/material';
import {join, map} from 'ramda';
import {capitalize} from '@rescapes/ramda';
import React, {useContext} from 'react';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {useToken} from 'utils/hooks/useTokenHook.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {hasAdminAccess} from 'appUtils/authentication/authenticationUtils.ts';
import {useTranslation} from 'react-i18next';
import {logout} from 'pages/protectedRoute/userUtils.ts';

/**
 * User profile page showing user information and a logout button
 * @param appProps
 * @param organizationProps
 * @returns {JSX.Element}
 * @constructor
 */
const ProfilePage = ({}): JSX.Element => {
  const navigate = useNavigate();
  const [, setToken] = useToken();
  const {userState} = useContext(UserContext) as UserContextType;
  const {t} = useTranslation();

  if (userState.status !== AccessStatus.Authenticated) {
    return <></>;
  }
  const access = userState.access;
  if (!access) {
    return <></>;
  }

  const isAdmin = hasAdminAccess(userState);
  const adminOverride = (defaults: string[]): string[] => {
    return isAdmin ? t('all') : defaults;
  };
  const joinTitleAndValues = (titleKey: string, values: string | string[]) => {
    return join(': ', [t(titleKey), join(', ', toArrayIfNot(values))]);
  };
  const organizationLabel = userState?.organization?.name ?? '';
  const emailLabel = userState.email;
  const applicationAccessLabel = joinTitleAndValues(
    'applicationAccess',
    adminOverride(access.applicationAccess),
  );
  const railwayAccessLabel = joinTitleAndValues(
    'railwayAccess',
    adminOverride(map(capitalize, access.trackAccess)),
  );

  return (
    <Stack
      {...{
        sx: {
          p: 2,
          width: '100%',
          alignItems: 'top',
          justifyContent: 'center',
        },
      }}
    >
      <Stack {...{spacing: 2, sx: {color: 'secondary.main'}, alignItems: 'center'}}>
        <Stack {...{spacing: 2}}>
          <Typography key="title" color="inherit" textAlign={'center'}>
            Logged in as:
          </Typography>
          <Typography key="orgName" color="inherit" textAlign={'center'}>
            {organizationLabel}
          </Typography>
          {emailLabel && (
            <Typography key="accountEmail" color="inherit" textAlign={'center'}>
              {emailLabel}
            </Typography>
          )}
          {AppSettings.appEnv === 'local'
            ? [
                <Typography key="appAccess" color="inherit">
                  {applicationAccessLabel}
                </Typography>,
                <Typography key="railwayAccess" color="inherit">
                  {railwayAccessLabel}
                </Typography>,
              ]
            : []}
        </Stack>
        <Link to="/">
          <Button {...{variant: 'outlined', onClick: () => logout(setToken, navigate)}}>
            {t('logout')}
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default ProfilePage;
