import React from 'react';
import {SvgIcon} from '@mui/material';

/**
 * Creates a front-facing train icon
 * @param fill
 * @param fontSize
 * @param opacity
 * @constructor
 */
export const TrainFacingSvgIcon = ({
  fill = 'white',
  fontSize = 'medium',
  opacity = 1,
}) => {
  return (
    <SvgIcon {...{fontSize}}>
      <svg
        enableBackground="new 0 0 96 96"
        height="96px"
        width="96px"
        viewBox="0 0 96 96"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="scale(4)">
          <path
            fill={fill}
            opacity={opacity}
            d="M12 4c-3.51 0-4.96.48-5.57 1h11.13c-.6-.52-2.05-1-5.56-1M6 15.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V12H6zm9.5-2.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5m-7 0c.83 0 1.5.67 1.5 1.5S9.33 16 8.5 16 7 15.33 7 14.5 7.67 13 8.5 13"
          />
          <circle cx="8.5" cy="14.5" r="1.5" />
          <circle cx="15.5" cy="14.5" r="1.5" />
          <path d="M12 2c-4 0-8 .5-8 4v9.5C4 17.43 5.57 19 7.5 19L6 20.5v.5h2l2-2h4l2 2h2v-.5L16.5 19c1.93 0 3.5-1.57 3.5-3.5V6c0-3.5-4-4-8-4m0 2c3.51 0 4.96.48 5.57 1H6.43c.61-.52 2.06-1 5.57-1M6 7h5v3H6zm12 8.5c0 .83-.67 1.5-1.5 1.5h-9c-.83 0-1.5-.67-1.5-1.5V12h12zm0-5.5h-5V7h5z" />
        </g>
      </svg>
    </SvgIcon>
  );
};

TrainFacingSvgIcon.displayProps = 'TrainGroupSvgIcon';
export default TrainFacingSvgIcon;
