import {ButtonProps} from '@mui/material';
import {shallowEquals} from 'utils/functional/functionalUtils.ts';
import React, {useCallback} from 'react';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import TrainGroupOption, {TrainGroupTypeOptionProps} from './TrainGroupOption.tsx';
import {CemitTypename} from 'types/cemitTypename.ts';
import {useNotLoadingMemoActiveFrontendViewGroupSelectionDisplay} from 'async/trainAppAsync/trainAppHooks/trainApiHooks/activeViewHooks.ts';
import {FrontendViewGroupSelectionDisplay} from 'types/frontendViews/frontendViewGroupSelectionDisplay';
import {FrontendViewGroupSelection} from 'types/frontendViews/frontendViewGroupSelection';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';

export interface TrainGroupTypeOptionContainerProps
  extends CemitComponentProps<TrainGroupTypeOptionContainerProps> {
  icon: JSX.Element;
  descriptionKey: string;
  titleKey: string;
  buttonProps: ButtonProps;
  primaryFrontendViewGroupSelectionDisplay: FrontendViewGroupSelectionDisplay;
  secondaryFrontendViewGroupSelectionDisplay: FrontendViewGroupSelectionDisplay;
  setActiveViewsOfFrontendViewGroup: (
    frontendViewGroupSelection: FrontendViewGroupSelection,
  ) => void;
}

/**
 * TrainGroupOption logic. Determines what style the component should have and builds the callback function
 * to
 * @param appProps
 * @param componentProps
 * @constructor
 */
const TrainGroupOptionContainer = ({
  appProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<TrainGroupTypeOptionContainerProps>) => {
  // Compare to activeViews to see if primaryFrontendViewGroupSelectionDisplay or secondaryFrontendViewGroupSelectionDisplay
  // should be active
  const frontendViewGroupSelectionDisplay =
    useNotLoadingMemoActiveFrontendViewGroupSelectionDisplay(
      appProps.loading,
      [
        componentProps.primaryFrontendViewGroupSelectionDisplay,
        componentProps.secondaryFrontendViewGroupSelectionDisplay,
      ],
      appProps.activeViews!,
    );
  const onClick = useCallback(() => {
    // Activates this and change other options to their secondary view
    componentProps.setActiveViewsOfFrontendViewGroup(
      componentProps.primaryFrontendViewGroupSelectionDisplay.frontendViewGroupSelection,
    );
  }, [appProps]);

  // If primaryFrontendViewGroupSelectionDisplay is being used, we disable the button since the option is already active
  const isDisabled = shallowEquals(
    componentProps.primaryFrontendViewGroupSelectionDisplay.frontendViewGroupSelection,
    frontendViewGroupSelectionDisplay.frontendViewGroupSelection,
  );
  return (
    <TrainGroupOption
      {...{
        appProps,
        componentProps: useMemoClsOrType<TrainGroupTypeOptionProps>(
          CemitTypename.trainGroupTypeOptionProps,
          {
            icon: componentProps.icon,
            titleKey: componentProps.titleKey,
            descriptionKey: componentProps.descriptionKey,
            buttonProps: frontendViewGroupSelectionDisplay.style,
            disabled: isDisabled,
            onClick,
          },
        ),
      }}
    />
  );
};

TrainGroupOptionContainer.displayName = 'TrainGroupOptionContainer';
export default TrainGroupOptionContainer;
