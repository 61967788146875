import {TrainProps} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {TrainGroup} from '../../../../types/trainGroups/trainGroup';
import {useMemo} from 'react';
import {filterCrudList} from '../../../../utils/hooks/crudHooks.ts';
import {idsEqual} from '../../../../utils/functional/functionalUtils.ts';
import {setFilteredCrudOnTrainProps} from '../../../../appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import {view} from 'ramda';
import {Identified} from '../../../../types/identified';
import {Versioned} from '../../../../types/versioning/versioned';

/**
 * Sets trainProps at crudTrainGroupsLensPath to trainGroup
 * so that the trainGroup is the scope of the trainProps
 * @param trainProps
 * @param crudTrainGroupsLensPath
 * @param trainGroup
 */
export const useMemoLimitTrainPropsCrudToTrainGroup = <
  S extends Identified & Versioned,
  K0 extends keyof S = keyof S,
  K1 extends keyof S[K0] = keyof S[K0],
  K2 extends keyof S[K0][K1] = keyof S[K0][K1],
  K3 extends keyof S[K0][K1][K2] = keyof S[K0][K1][K2],
>(
  trainProps: TrainProps,
  crudTrainGroupsLensPath: [K0] | [K0, K1] | [K0, K1, K2] | [K0, K1, K2, K3],
  trainGroup: TrainGroup,
) => {
  return useMemo(() => {
    const filteredCrudTrainGroups = filterCrudList(
      (trainGroup: TrainGroup) => idsEqual(trainGroup, trainGroup),
      view(crudTrainGroupsLensPath, trainProps),
    );
    return setFilteredCrudOnTrainProps(
      trainProps,
      crudTrainGroupsLensPath,
      filteredCrudTrainGroups,
    );
  }, [trainGroup, trainProps]);
};
