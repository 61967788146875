import {compact, memoizedWith} from '@rescapes/ramda';
import {trainGroupSensorDataPointsWithMostData} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import {
  featureCollection as turfFeatureCollection,
  lineString,
  point,
} from '@turf/helpers';
import {
  addIndex,
  append,
  compose,
  equals,
  filter,
  ifElse,
  indexOf,
  last,
  length,
  lensProp,
  map,
  mergeRight,
  omit,
  over,
  reduce,
  set,
  slice,
} from 'ramda';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import lineSlice from '@turf/line-slice';
import turfLength from '@turf/length';
import {headOrThrow, lastOrThrow} from 'utils/functional/functionalUtils.ts';
import {calculateMeanXYZAcceleration} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/derivedAttributeUtils.ts';
import {Feature, LineString, Point, Position} from 'geojson';
import {Feature, GeoJsonProperties} from 'geojson';
import {TrainRoute} from '../../../types/trainRouteGroups/trainRoute';
import {SensorDataPoint} from '../../../types/sensors/sensorDataPoint';
import {trainGroupHasSensorData} from 'appUtils/trainAppUtils/trainGroupSensorDataUtils/trainGroupSensorDataUtils.ts';
import {SensorDataTrainGroup} from '../../../types/trainGroups/sensorDataTrainGroup';
import {Perhaps} from '../../../types/typeHelpers/perhaps';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {clsOrType, ts} from '../../typeUtils/clsOrType.ts';
import {SensorDataPointGeojson} from '../../../types/trainGroups/sensorDataPointGeojson';
import {TrainRouteOrGroup} from '../../../types/trainRouteGroups/trainRouteOrGroup';
import {
  SensorDataFeatureProps,
  SensorDataFeatureWithDerivedProps,
} from '../../../types/trainRuns/sensorDataFeature';
import {isWithinInterval} from 'date-fns';
import {implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';

export interface DerivativePropertiesForSensorDataPointProps {
  fullLineString: Perhaps<Feature<LineString>>;
  accumulatedDistance: number;
  pointIsAlreadyOnLine: boolean;
  features?: Feature<Point>[];
  index: number;
}

/**
 * Creates derived properties for the given SensorDataPoint, namely the meters property which
 * is a calculation of where it is along the TrainRoute and therefore where it shows on dataVisualizations
 * @param lineString
 * @param accumulatedDistance
 * @param pointIsAlreadyOnLine True for TrainGroupOnlyTrainFormation where the line the path the train took
 * For TrainGroupSingleTrainRun the line is the TrainRoute of the Train
 * @param fetatures Use with pointIsAlreadyOnLine instead of splitting the line string
 * @param pointFeature
 *
 * @returns {{slicedLine, properties: {accXZYMean, meters: *}}}
 */
const derivativePropertiesForSensorDataPoint = (
  {
    fullLineString,
    accumulatedDistance,
    pointIsAlreadyOnLine,
    features,
    index,
  }: DerivativePropertiesForSensorDataPointProps,
  pointFeature: Feature<Point>,
): SensorDataFeatureWithDerivedProps => {
  const [meters, slicedLine] = ifElse(
    Boolean,
    () => {
      // If pointIsAlreadyOnLine and features are given, the point is already one of the features
      // Compute the accumulated distance from the start of the features to it
      const slicedFeatures: Feature[] = slice(0, index, features!);
      return ifElse(
        (slicedFeatures: Feature<Point>[]) => length(slicedFeatures) > 1,
        (slicedFeatures: Feature[]) => {
          const lineStringSliced = lineString(
            map(
              (feature: Feature<Point>) => feature.geometry.coordinates,
              slicedFeatures,
            ),
          );
          const meters = turfLength(lineStringSliced, {units: 'meters'});
          return [meters, lineStringSliced];
        },
        () => {
          return [0, undefined];
        },
      )(slicedFeatures);
    },
    () => {
      // Slice the given lineString from its start to the point
      // This line is returned to the next point along with accumulatedDistance
      // If the point is at the start of the line, we get 1 feature and thus are at 0 meters
      // turf's lineSplit is broken, so using this compose instead
      const featureCollection = compose(
        (lines: Feature<LineString>[]) => turfFeatureCollection<LineString>(lines),
        compact,
        (nearestPointOnLine) => {
          // Create one or two new linestrings. Ignore single point strings
          return map(
            (points: [Feature<Point>, Feature<Point>]) => {
              return equals(
                ...(map<Feature<Point>, Position>(
                  (point) => point.geometry.coordinates,
                  points,
                ) as [Position, Position]),
              )
                ? undefined
                : lineSlice(...points, fullLineString);
            },
            // Take line slices
            [
              [
                point(headOrThrow(fullLineString.geometry.coordinates)),
                nearestPointOnLine,
              ],
              [
                nearestPointOnLine,
                point(lastOrThrow(fullLineString.geometry.coordinates)),
              ],
            ],
          );
        },
        (pointFeature) => {
          // Get the index of the nearest point on the line if needed
          return nearestPointOnLine(fullLineString, pointFeature);
        },
      )(pointFeature);
      const slicedLine = last(featureCollection.features);
      // Use the accumulatedDistance and length line from the start of lineString until pointFeature
      // If pointFeature is at the start, then add 0
      const meters =
        accumulatedDistance +
        (length(featureCollection.features) > 1
          ? turfLength(headOrThrow(featureCollection.features), {units: 'meters'})
          : 0);
      return [meters, slicedLine];
    },
  )(pointIsAlreadyOnLine);

  return clsOrType<SensorDataFeatureWithDerivedProps>(
    CemitTypename.sensorDataFeatureWithDerivedProps,
    {
      properties: {
        accXZYMean: calculateMeanXYZAcceleration(pointFeature.properties),
        meters,
      },
      // Return for the next point
      accumulatedDistance: meters,
      slicedLine,
    },
  );
};

/**
 * Returns limited sensorDataPoint based point features
 * Memoized to run for each unique trainRun by its id and the distanceResolutionsAndRanges of SensorDataPoints
 * that have been loaded
 * @param trainRoute
 * @param sensorDataTrainGroup
 */
export const memoizedTrainGroupGeojson = memoizedWith(
  (trainRoute: Perhaps<TrainRoute>, sensorDataTrainGroup: SensorDataTrainGroup) => [
    trainRoute?.id,
    sensorDataTrainGroup.sensorDataDateIntervals,
    sensorDataTrainGroup.sensorDataPoints,
    sensorDataTrainGroup.trainGroup.id,
    sensorDataTrainGroup.trainGroup.activeDateInterval,
  ],
  (trainRoute: Perhaps<TrainRoute>, sensorDataTrainGroup: SensorDataTrainGroup) => {
    const dateInterval = sensorDataTrainGroup.trainGroup.activeDateInterval;
    // TODO currently take the formation with the most sensor points
    const _sensorDataPoints =
      trainGroupSensorDataPointsWithMostData(sensorDataTrainGroup);

    // Inject the SensorDataPoint properties into its geojson point's properties
    const sensorDataPoints: Feature<Point>[] = map<SensorDataPoint, Feature<Point>>(
      (sensorDataPoint: SensorDataPoint): Feature<Point> => {
        return set(
          lensProp('properties'),
          omit(['geojson'], sensorDataPoint),
          sensorDataPoint.geojson,
        );
      },
      _sensorDataPoints,
    );

    // This line only has points that are SensorDataPoints
    const lineFeatureOfAvailableSensorDataPoints =
      length(sensorDataPoints) >= 2
        ? lineString(map((point) => point.geometry.coordinates, sensorDataPoints))
        : undefined;

    // Get the end points of the trainRun based on its stops
    const nearestEndPoints = undefined;
    // TODO Disabled for now
    //   ifElse(
    //   doActiveTrainGroupsHaveTrainRuns,
    //   (trainGroup: TrainGroup) => {
    //     const extremeScheduledStopPointFeatures = doActiveTrainGroupsHaveTrainRuns(
    //       trainGroup,
    //     )
    //       ? map<ScheduledStopPoint, Feature<Point>>(
    //           (scheduledStopPoint: ScheduledStopPoint): Feature<Point> => {
    //             return prop<Feature<Point>>('geojson', scheduledStopPoint);
    //           },
    //           extremes(scheduledStopPointsOfTrainGroup(trainGroup)),
    //         )
    //       : undefined;
    //
    //     // Find the nearest points on the rail line
    //     const nearestEndPoints: Perhaps<[Feature<Point>, Feature<Point>]> =
    //       doActiveTrainGroupsHaveTrainRuns(trainGroup)
    //         ? map<Feature<Point>, Feature<Point>>(
    //             (point: Feature<Point>): Feature<Point> => {
    //               return nearestPointOnLine(trackRoute.geojson, point);
    //             },
    //             extremeScheduledStopPointFeatures!,
    //           )
    //         : undefined;
    //     return nearestEndPoints;
    //   },
    //   always(undefined),
    // )(trainGroup);

    const limitedSensorDataPointFeatures: Feature<Point>[] =
      // TODO disabled for now
      /*ifElse(
      (_sensorDataPoints: Feature<Point>[]) =>
        doActiveTrainGroupsHaveTrainRuns(trainGroup),
      (sensorDataPoints: Feature<Point>[]): Feature<Point>[] => {
        // TODO we can't currently query for SensorDSensorDataPoints in a way that handles late trains because we can't
        // measure what direction along the track the TrainPage is moving. I can't remember why that is
        // Ideally we should get the SensorData that corresponds to the TrainRun, whether on-time or late, from
        // the server and not limit the SensorData here. But we have to limit them here from now or risk getting
        // points from the previous TrainRun that was late and going in the opposite direction on the same track

        // Limit the SensorDataPoints to the TrainRun data-thresholds in case the TrainRun was late
        // and we captured sensorDataPoints that were part of a previous TrainRun
        // Hash so we can inject the points with the properties back into the line later
        const hashToSensorDataPoints = indexBy(
          (point) => point.geometry.coordinates.toString(),
          sensorDataPoints,
        );

        // Slice the lineFeatureOfAvailableSensorDataPoints to the end points of the TrainRun. This is shorter
        // than trackAsLineString if we are missing sensorDataPoints at the ends because the TrainRun was late
        const sensorDataPointsSlicedLine = compose(
          (slice: Feature<LineString>) => {
            // Move the last points of the slice to the closest imupoint if not already matching
            // This step is mostly important when we have to generate fake imupoints at stations because the
            // TrainRun has no available imupoints
            const lastIndex = length(slice.geometry.coordinates) - 1;
            return reduce(
              (slice, index) => {
                return overClassOrType(
                  lensPath(['geometry', 'coordinates', index]),
                  (coord) => {
                    return hashToSensorDataPoints[coord.toString()]
                      ? coord
                      : nearestPointOnLine(
                          lineFeatureOfAvailableSensorDataPoints,
                          point(coord),
                        ).geometry.coordinates;
                  },
                  slice,
                );
              },
              slice,
              [0, lastIndex],
            );
          },
          (
            lineFeatureOfAvailableSensorDataPoints: Feature<LineString>,
          ): Feature<LineString> => {
            return lineSlice(
              ...(nearestEndPoints as [Feature<Point>, Feature<Point>]),
              lineFeatureOfAvailableSensorDataPoints,
            );
          },
        )(lineFeatureOfAvailableSensorDataPoints);

        // Limited
        const limitedSensorDataPointFeatures = compact(
          map((coord) => {
            return hashToSensorDataPoints[coord.toString()];
          }, sensorDataPointsSlicedLine.geometry.coordinates),
        );
        return limitedSensorDataPointFeatures;
      },*/
      // Limit to the current dateInterval TrainGroupTrainFormationOnly
      // (sensorDataPoints: Feature<Point>[]): Feature<Point>[] => {
      // return
      filter((sensorDataPointFeature: Feature<Point>[]) => {
        return isWithinInterval(
          new Date(sensorDataPointFeature.properties.time),
          dateInterval,
        );
      }, sensorDataPoints);
    //},
    //)(sensorDataPoints);

    // If this is a TrainGroupOnlyTrainFormation, The line we are using is the SensorDataPoints
    // Otherwise the line will be the TrainRoute of the TrainGroupSingleTrainRun
    const pointIsAlreadyOnLine = implementsCemitTypeViaClass(
      CemitTypename.trainGroupOnlyTrainFormation,
      sensorDataTrainGroup.trainGroup,
    );

    // Add derived props. Do this efficiently by calculating the position
    // in meters of each sequential point using trackData.trackAsLineString,
    // which we slice to the current point each iteration. This makes the calculation of the meters
    // along the line order n, where n is the number of points.
    const sensorDataPointsWithDerived = addIndex(reduce)(
      (
        {lineString, accumulatedDistance, points},
        pointFeature: Feature<Point>,
        index: number,
      ) => {
        // Get the derived properties of the point and lineString sliced to start at this point
        const {
          slicedLine,
          accumulatedDistance: nextDistance,
          properties: derivedProperties,
        }: SensorDataFeatureWithDerivedProps = derivativePropertiesForSensorDataPoint(
          ts<DerivativePropertiesForSensorDataPointProps>({
            fullLineString: lineString,
            accumulatedDistance,
            pointIsAlreadyOnLine,
            features: limitedSensorDataPointFeatures,
            index,
          }),
          pointFeature,
        );
        return {
          // Use the slicedLine and accumulatedDistance for the next iteration
          lineString: slicedLine,
          accumulatedDistance: nextDistance,
          points: append(
            over(
              lensProp('properties'),
              (properties: GeoJsonProperties): GeoJsonProperties => {
                // @ts-ignore don't understand error
                return mergeRight<GeoJsonProperties, GeoJsonProperties>(
                  properties,
                  derivedProperties,
                );
              },
              pointFeature,
            ),
            points,
          ),
        };
      },
      {
        // TODO This used to be trackData.trackAsLineString and probably needs to be for TrainRuns
        lineString: lineFeatureOfAvailableSensorDataPoints,
        accumulatedDistance: 0,
        points: [],
      },
      limitedSensorDataPointFeatures,
    ).points;

    return clsOrType<SensorDataPointGeojson>(CemitTypename.sensorDataPointGeojson, {
      // TODO I don't know if this is used
      nearestEndPoints,
      // This should already be limited to the TrainRoute extent
      // TODO
      // trackSlicedLine: trackData.trackAsLineString,
      trackSlicedLine: lineFeatureOfAvailableSensorDataPoints,
      // The sensorDataPoints sliced to match the TrainRun in case the trains was late
      sensorDataPointsLine: lineFeatureOfAvailableSensorDataPoints,
      // The limited points with derived properties added
      sensorDataPoints: sensorDataPointsWithDerived,
    });
  },
);

/**
 * Creates memoized geojson of sensor points for the TrainRun
 * Memoized to run for each unique trainRun by its id and the distanceResolutionsAndRanges of SensorDataPoints
 * that have been loaded
 * @param trainGroup. The TrainGroup whose geojson we want to calculate
 * @param sensorDataTrainGroup. A minimal version of the TrainGroup that
 * contains the SensorData instances. We use them to create the geojson
 * @returns limitedPointFeatures
 */
export const memoizedTrainRunFeatureCollectionSensorPoints = memoizedWith(
  (
    trainRouteOrGroup: Perhaps<TrainRouteOrGroup>,
    sensorDataTrainGroup: SensorDataTrainGroup,
  ) => {
    return [
      trainRouteOrGroup?.id,
      sensorDataTrainGroup.sensorDataDateIntervals,
      sensorDataTrainGroup.sensorDataPoints,
      sensorDataTrainGroup.trainGroup.id,
      sensorDataTrainGroup.trainGroup.activeDateInterval,
    ];
  },
  (trainRouteOrGroup: TrainRouteOrGroup, sensorDataTrainGroup: SensorDataTrainGroup) => {
    if (!trainGroupHasSensorData(sensorDataTrainGroup)) {
      return [];
    }

    // Call reclassifyObject in case we arrived through a web worker
    const trainRunGeojson = memoizedTrainGroupGeojson(
      trainRouteOrGroup,
      sensorDataTrainGroup,
    );
    return trainRunGeojson.sensorDataPoints;
  },
);
