import {
  resolveOffsetLeft,
  trainRunLineScheduledStopPointsAndMaybeDatetimes,
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRunLineUtils.ts';
import TrainRunLine from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLine.tsx';
import React, {useMemo, useRef, useState} from 'react';
import useMemoTrainLineStationPropSets from 'components/apps/trainAppComponents/trainLineComponents/trainLineStationsProps.tsx';
import {trainRunLineReadyHookProps} from 'components/apps/trainAppComponents/trainLineComponents/trainRunLineReadyProps.tsx';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {always, length} from 'ramda';
import HoverFeature from 'components/apps/trainAppComponents/trainLineComponents/HoverFeature.tsx';
import {Feature} from 'geojson';
import {
  SingleTrainGroupTrainProps,
  TrainProps,
} from '../../../../types/propTypes/trainPropTypes/trainProps';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {Size} from '../../../../types/layout/size';
import {SxProps} from '@mui/system';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {ScheduledStopPoint} from '../../../../types/stops/scheduledStopPoint';
import {ScheduledStopPointAndMaybeDateTimeDerived} from '../../../../types/stops/scheduledStopPointAndMaybeTime';
import {TrainDistanceInterval} from '../../../../types/distances/trainInterval';
import {useTrainRunLineMoverDrop} from 'async/trainAppAsync/trainAppHooks/dragHooks/trainGroupIntervalDragAndDropHooks.ts';
import {useWhatIsLoading} from '../../../../async/trainAppAsync/trainAppHooks/loadingExplanationHooks.ts';
import {scopedFilteredTrainGroup} from '../../../../appUtils/trainAppUtils/scope/trainPropsScope.ts';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {useDebounceCallback, useResizeObserver} from 'usehooks-ts';
import {useDebouncedCallback} from 'use-debounce';

/**
 * Defines a TrainRunLine
 * @param appProps
 * @param trainProps
 * @param spaceGeospatially
 * @param onlyStopsNearInterval
 * @param showLimitedDistanceRange
 * @param showTrainRunOrGroupIntervalBars
 * @param isTrainRouteLine
 * @param isTrainGroupDetailLine
 * @param hoverFeature
 * @param trainDetailSize
 * @param orientation
 * @param sx
 * @param sxTrainRunOrGroupIntervalBar
 * @constructor
 */
const TrainRunLineReadyContainer = ({
  appProps,
  trainProps,
  componentProps: {
    spaceGeospatially,
    onlyStopsNearInterval,
    limitedTrainDistanceInterval,
    isTrainRouteLine,
    isTrainGroupDetailLine,
    hoverFeature,
    trainDetailSize,
    orientation = 'horizontal',
  },
  sx,
  sxTrainRunOrGroupIntervalBar,
}: {
  appProps: TrainAppProps;
  trainProps: SingleTrainGroupTrainProps;
  componentProps: {
    spaceGeospatially: boolean;
    onlyStopsNearInterval: boolean;
    limitedTrainDistanceInterval: TrainDistanceInterval;
    isTrainRouteLine: boolean;
    isTrainGroupDetailLine: boolean;
    hoverFeature: Feature;
    trainDetailSize: Size;
    orientation: string;
  };
  sx: SxProps;
  sxTrainRunOrGroupIntervalBar: SxProps;
}) => {
  // For now this is always false because TrainRunLineContainer gatekeeps for us
  const loading = false;

  // Store the ScheduledStopPoint that is being hovered
  const [hoveredScheduledStopPoint, setHoveredScheduledStopPoint] = useState(undefined);

  const containerRef = useRef<HTMLDivElement>(undefined);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  const onResize = useDebouncedCallback(setSize, 200);
  useResizeObserver({
    containerRef,
    onResize,
  });

  // Initialize the offsetLefts, we can set the offset if spaceGesopatially is true. If not we init to an empty array
  // and let the TrainLineStation trafficSimComponents call their setOffset left which fills the array with values
  const [offsetLefts, setOffsetLefts] = useState([]);
  // This is updated with the current timestamp every time trafficSimComponents need to recalculate their offset left
  // To report to fill offsetLefts above
  const [recalculateOffsetLefts, setRecalculateOffsetLefts] = useState<Date | undefined>(
    undefined,
  );

  // Undefined for TrainRouteLine
  const trainRoute = trainProps.trainRouteGroupProps.trainRouteOrGroup;
  const trainGroup = scopedFilteredTrainGroup(trainProps)!;

  // Props we can calculate without trainAppHooks
  const scheduledStopPointsAndMaybeDateTimes: Perhaps<
    ScheduledStopPoint[] | ScheduledStopPointAndMaybeDateTimeDerived[]
  > = trainRunLineScheduledStopPointsAndMaybeDatetimes({loading, trainProps});

  // Props we calculate with trainAppHooks
  const {
    loadingExplanation: loadingExplanationFromTrainRunLineReadyHookProps,
    visibleScheduledStopPointsAndMaybeDateTimes,
    visibleScheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    distanceRange,
    stopGaps,
    areOffsetLeftsReady,
  } = trainRunLineReadyHookProps({
    loading,
    appProps,
    trainProps,
    scheduledStopPointsAndMaybeDateTimes,
    spaceGeospatially,
    onlyStopsNearInterval,
    isTrainRouteLine,
    limitedTrainDistanceInterval,
    offsetLefts,
    setOffsetLefts,
    setRecalculateOffsetLefts,
  });

  // Props for creating TrainLineStation trafficSimComponents
  // offsetLefts must be initialized to an array of undefined items by useInitStopOffsetLefts in trainRunLineReadyHookProps
  // before this can run
  const trainLineStationPropSets = useMemoTrainLineStationPropSets({
    loading: loading || !length(offsetLefts),
    trainRoute,
    singleTrainRun: trainGroup.singleTrainRun,
    trainRouteOrRunInterval,
    scheduledStopPointsAndMaybeDateTimes: visibleScheduledStopPointsAndMaybeDateTimes,
    offsetLefts,
    setOffsetLefts,
    recalculateOffsetLefts,
    scheduledStopPointsAndMaybeDateTimesWithOffsetLefts:
      visibleScheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    onlyStopsNearInterval,
    spaceGeospatially,
    width: size.width,
    distanceRange,
  });

  // @ts-ignore
  const hoverFeatureComponent =
    areOffsetLeftsReady && hoverFeature ? (
      <HoverFeature
        key="hoverFeature"
        {...{
          trainProps,
          componentProps: {
            hoverFeature,
            spaceGeospatially,
            resolveOffsetLeft: resolveOffsetLeft({
              // @ts-ignore
              routeDistancesWithOffsetLefts:
                visibleScheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
            }),
          },
          sxTrainRunOrGroupIntervalBar: [
            {zIndex: 3},
            ...toArrayIfNot(sxTrainRunOrGroupIntervalBar),
          ],
        }}
      />
    ) : undefined;

  // Defined the drop functionality
  const [{isOver: isMoverOver}, moverDrop] = useTrainRunLineMoverDrop({
    trainProps,
    componentProps: {
      isTrainRouteLine,
      // @ts-ignore
      areOffsetLeftsReady,
      crudTrainGroups: trainProps.trainGroupSingleTrainRunProps.crudTrainGroups,
      // @ts-ignore
      singleTrainRun: trainRun,
      trainRoute,
      // @ts-ignore
      routeDistancesWithOffsetLefts:
        visibleScheduledStopPointsAndMaybeDateTimesWithOffsetLefts,
    },
  });

  const whatIsLoading = useWhatIsLoading(
    loading,
    'loading',
    TrainRunLineReadyContainer.name,
    {
      loadingTrainRunLineReadyContainer: loading,
      lengthOffsetLefts: length(offsetLefts),
      trainLineStations: trainLineStationPropSets,
      ...loadingExplanationFromTrainRunLineReadyHookProps,
    },
    [
      loading,
      areOffsetLeftsReady,
      offsetLefts,
      trainLineStationPropSets,
      loadingExplanationFromTrainRunLineReadyHookProps,
    ],
    appProps.setWhatDependenciesAreLoading,
    !trainLineStationPropSets,
  );

  return whatIsLoading.loading ? (
    <LoaderWithText
      {...{text: 'loadingTrainRunLine', loadingExplanation: whatIsLoading}}
    />
  ) : (
    <TrainRunLine
      {...{
        appProps,
        trainProps,
        componentProps: {
          loadingExplanation: whatIsLoading,
          moverDrop,
          isMoverOver,
          stopGaps,
          spaceGeospatially,
          containerRef,
          trainLineStationPropSets,
          isTrainRouteLine,
          isTrainGroupDetailLine,
          hoverFeatureComponent,
          hoveredScheduledStopPoint,
          setHoveredScheduledStopPoint,
          trainDetailSize,
          orientation,
        },
        sx,
        sxTrainRunOrGroupIntervalBar,
      }}
    />
  );
};

export default TrainRunLineReadyContainer;
