import {colorHue} from 'theme/colors/colorHue/colorHue.ts';
import {alpha} from '@mui/material';

export const BorderColors = {
  // Light Mode
  light: {
    border: {
      grey: colorHue.grey[700],
      greyLight: alpha(colorHue.grey[700], 0.3),
      gold: colorHue.gold[700],
    },
  },
  // Dark Mode
  dark: {
    border: {
      grey: colorHue.grey[400],
      greyLight: alpha(colorHue.grey[400], 0.5),
      gold: colorHue.gold[500],
    },
  },
};
