import React, {useState, useEffect} from 'react';
import {DirectionPoint, Feature, DetectorData} from './types';
import StatusPage from './StatusPage.tsx';
import tagglist from './tempFile/tagglista20240627.csv';
import useExcelToGeoJson from './hooks/useExcelToGeoJson.ts';

import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material';

const StausPageConatiner: React.FC<{
  spaceId: string | null;
  pointId: string | null;
}> = ({}) => {
  //For Detectors //
  const [detectors, setDetectors] = useState<DetectorData[]>([]);
  const addDetectorData = (newData: DetectorData) => {
    setDetectors((prevDetectors) => [...prevDetectors, newData]);
  };
  const [selectedCdc, setSelectedCdc] = useState<string>(''); // State to hold selected CDC
  const {pointsGeoJsonData, dataLoaded, convertExcelToGeoJson} =
    useExcelToGeoJson(tagglist);
  const [pointGeoJsonDataV, setGeoJsonDataV] = useState<DirectionPoint | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [selectedTag, setSelectedTag] = useState<Feature | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    setSearchTerm(value || '');
  };

  useEffect(() => {
    convertExcelToGeoJson();
  }, [convertExcelToGeoJson]);

  useEffect(() => {
    if (dataLoaded) {
      setGeoJsonDataV(pointsGeoJsonData);
    }
  }, [dataLoaded, pointsGeoJsonData]);

  const cdcToMatch: string[] = ['NONE', 'cdc0502'];
  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedCdc(event.target.value as string);
  };
  // Filter signals based on selected CDC

  const handleAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    value: Feature | null,
  ) => {
    if (value) {
      setSelectedTag(value);
      // Do something with selectedTagID
    } else {
      setSelectedTag(null);
    }
  };
  return (
    <Box sx={{height: '100vh', display: 'flex'}}>
      <Grid container>
        <Grid
          item
          xs={4}
          sx={{overflowY: 'auto', borderRight: 1, borderColor: 'divider', p: 2}}
        >
          {pointGeoJsonDataV && (
            <Autocomplete
              sx={{mt: 2}}
              options={pointGeoJsonDataV.features}
              getOptionLabel={(option) => option.properties.detector}
              value={pointGeoJsonDataV.features.find(
                (feature) => feature.properties.detector === searchTerm,
              )}
              onChange={handleAutocompleteChange}
              onInputChange={handleSearchChange}
              renderInput={(params) => <TextField {...params} label="Search Detector " />}
            />
          )}

          <Typography variant="h6"></Typography>
          <FormControl fullWidth sx={{mt: 2}}>
            <InputLabel id="cdc-dropdown-label">Select CDC</InputLabel>
            <Select
              labelId="cdc-dropdown-label"
              id="cdc-dropdown"
              value={selectedCdc}
              onChange={handleChange}
            >
              {cdcToMatch.map((cdc, index) => (
                <MenuItem key={index} value={cdc}>
                  {cdc}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TableContainer
            component={Paper}
            sx={{maxHeight: 'calc(100vh - 100px)', mt: 2}}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Detector</TableCell>
                  <TableCell>Radio Number</TableCell>
                  <TableCell>Sequence Number</TableCell>
                  <TableCell>Tag ID</TableCell>
                  <TableCell>Tag Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detectors.map((detector, index) => (
                  <TableRow key={index}>
                    <TableCell>{detector.detector}</TableCell>
                    <TableCell>{detector.radioNumber}</TableCell>
                    <TableCell>{detector.sequenceNumber}</TableCell>
                    <TableCell>{detector.tagID}</TableCell>
                    <TableCell>{detector.tagType}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          {/* Pass selected CDC name to MapComponent */}
          {pointGeoJsonDataV && (
            <StatusPage
              directionPoints={pointGeoJsonDataV}
              selectedTag={selectedTag}
              selectedCDC={selectedCdc}
              onAddDetector={addDetectorData}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StausPageConatiner;
