import {useLocation} from 'react-router-dom';
import {Box, Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CemitAppNavigation from 'components/apps/cemitAppComponents/CemitAppNavigation.tsx';
import {cemitColors} from 'theme/cemitColors.ts';
import React from 'react';
import {AppSettings} from '../../../config/appConfigs/appSettings.ts';

type CemitAppProps = {
  children: React.ReactNode;
};

/**
 * The top level App that contains the TrainApp.
 * TODO This level is full of old code and needs to be redone
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const CemitApp = ({children}: CemitAppProps) => {
  const location = useLocation();

  const {t} = useTranslation();
  const currentPath = location?.pathname;
  const appRelease = AppSettings.appRelease;
  const logoTitle = `${t('cemitVisualizer')} - Build: ${appRelease}`;

  return (
    <Box key="cemitAppBox">
      <Stack
        key="cemitAppMainBox"
        {...{
          spacing: 0.5,
          direction: 'row',
          sx: {
            width: '100%',
            height: '100vh',
            maxHeight: '100vh',
            overflowY: 'hidden',
            overflowX: 'hidden',
            background: cemitColors.darkBlue,
          },
        }}
      >
        <CemitAppNavigation
          key="cemitAppNavigation"
          {...{
            currentPath,
            appRelease,
            logoTitle,
          }}
        />
        <Stack
          key="cemitAppChildren"
          {...{
            direction: 'row',
            sx: {
              py: '5px',
              height: '100%',
              maxHeight: '100%',
              width: 'inherit',
              maxWidth: 'inherit',
            },
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CemitApp;
