import Menu from '@mui/material/Menu/index.js';
import MenuItem from '@mui/material/MenuItem/index.js';
import React, {SyntheticEvent, useState} from 'react';
import {always, cond, head, includes, is, length, map, T} from 'ramda';
import {alpha, IconButton, Stack, Tooltip} from '@mui/material';
import {AddCircleOutline, Train} from '@mui/icons-material';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {TrainFormationChooserProps} from './TrainFormationChooser.tsx';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import TrainFormationDescriptionMenuItem from './TrainFormationDescriptionMenuItem.tsx';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {CEMIT_GREEN, CEMIT_GREEN_TRAIN, CEMIT_WHITE} from 'theme/cemitColors.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * Menu to choose from the available Formations
 * @returns {JSX.Element}
 * @constructor
 */
const TrainFormationMenu = ({
  appProps,
  trainProps,
  componentProps,
}: TrainAppTrainComponentDependencyProps<TrainFormationChooserProps>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Perhaps<EventTarget & Element>>(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (
    _e: SyntheticEvent,
    trainGroupFormation: TrainGroupOnlyTrainFormation,
  ) => {
    setAnchorEl(undefined);
    // if formation comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, trainGroupFormation)) {
      componentProps.handleAddTrainGroupOnlyTrainFormationToFilter(trainGroupFormation);
    }
  };
  // Disable if disabled is true or in add mode and the maximum possible chosen TrainGroups is already reached
  const disabled =
    componentProps.disabled ||
    (componentProps.addTrainGroupMode &&
      componentProps.maximumAllowed &&
      length(componentProps.chosenTrainGroupOnlyTrainFormations) ==
        componentProps.maximumAllowed);
  const scopedTrainGroup = componentProps.scopedTrainGroup;

  const tooltipLabel = cond([
    [
      ({addTrainGroupMode}) => {
        return addTrainGroupMode && disabled;
      },
      always('removeTrainFormationBeforeAdding'),
    ],
    [
      ({addTrainGroupMode}) => {
        return addTrainGroupMode;
      },
      always('addTrainFormation'),
    ],
    // If not add mode, the menu is for changing trains. This currently can't be disabled
    [T, always('changeTrain')],
  ])(componentProps);
  return (
    <Tooltip
      {...{
        arrow: true,
        title: appProps.t(tooltipLabel),
      }}
    >
      <Stack
        {...{
          direction: 'row',
          spacing: 1,
          sx: {
            width: '100%',
            height: '35px',
            justifyContent: 'left',
            color: 'red',
            '& p': {color: 'red'},
          },
        }}
      >
        <IconButton
          key="button"
          {...{
            size: 'large',
            id: 'formation-button',
            disabled: Boolean(disabled),
            // Make the add button green
            ...(componentProps.addTrainGroupMode ? {color: 'trainGroupAddButton'} : {}),
            sx: {
              padding: 0,
              ...(componentProps.addTrainGroupMode
                ? {
                    m: 0,
                    borderRadius: 0,
                  }
                : {}),
              // Show the default color if activeTrainGroupOnlyTrainFormation doesn't have a color.
              ...(!componentProps.addTrainGroupMode
                ? {color: scopedTrainGroup?.activity?.isActiveColor}
                : {
                    // TODO tried to add this to the trainGroupAddButton in theme, but it is ignored
                    '&.Mui-disabled': {
                      color: alpha(CEMIT_GREEN, 0.5),
                    },
                  }),
              opacity:
                scopedTrainGroup?.activity?.isVisible || componentProps.addTrainGroupMode
                  ? 1
                  : 0.35,
            },
            'aria-controls': open ? 'formation-positioned-menu' : undefined,
            'aria-haspopup': 'true',
            'aria-expanded': open ? 'true' : undefined,
            onClick: handleClick,
          }}
        >
          {componentProps.addTrainGroupMode ? (
            // Show the add icon
            <AddCircleOutline {...{sx: componentProps.iconSx}} />
          ) : (
            <Train {...{fontSize: 'large'}} />
          )}
        </IconButton>
        <Menu
          key="menu"
          id="formation-positioned-menu"
          aria-labelledby="formation-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {map((trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation) => {
            const itemComponentProps = clsOrType<AlertScopeProps>(
              CemitTypename.alertScopeProps,
              {
                scopedTrainGroup: trainGroupOnlyTrainFormation,
                // Use the RideComfortConfigProps set for this trainGroupOnlyTrainFormation or the global ones
                alertConfigProps:
                  trainGroupOnlyTrainFormation.rideComfortConfigProps ||
                  trainProps.alertConfigProps,
              },
            );
            return (
              <MenuItem
                key={trainGroupOnlyTrainFormation.id}
                {...{
                  disabled: includes(
                    trainGroupOnlyTrainFormation,
                    componentProps.chosenTrainGroupOnlyTrainFormations,
                  ),
                  onClick: (e: SyntheticEvent) => {
                    handleClose(e, trainGroupOnlyTrainFormation);
                  },
                }}
              >
                {
                  <TrainFormationDescriptionMenuItem
                    {...{
                      appProps,
                      trainProps,
                      componentProps: itemComponentProps,
                    }}
                  />
                }
              </MenuItem>
            );
          }, componentProps.trainGroupFormations)}
        </Menu>
      </Stack>
    </Tooltip>
  );
};
export default TrainFormationMenu;
