import {any, lensPath, map, prop, set, values} from 'ramda';
import {ReactElement, useState} from 'react';
import {
  useEffectCreateCrudTrainGroups,
  useNotLoadingMemoActiveTrainGroups,
  useSyncTrainGroupsToStoredAndPreconfigured,
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {CrudList} from 'types/crud/crudList';
import {TrainGroupSingleTrainRunProps} from 'types/propTypes/trainPropTypes/trainGroupSingleTrainRunProps';
import {useMemoMergeTrainProps} from 'appUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {useNotLoadingMemoTrainGroupsMatchCrud} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupDetailHooks.ts';
import {PerhapsCemited} from 'types/cemited';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {TrainGroupSingleTrainRun} from 'types/trainGroups/trainGroupSingleTrainRun';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {useNotLoadingEffectSetLoadedVersionOfProp} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupSingleTrainRunHooks.ts';
import {TrainFormation} from 'types/trains/trainFormation';
import {useNotLoadingUpdateCrudIfActivityChanges} from 'async/trainAppAsync/trainAppHooks/trainGroupCrudListHooks.ts';

/**
 * TrainGroupSingleTrainRunDependency deals with lists of TrainGroupSingleTrainRuns that have details beyond basic data
 * loaded. This is usually the result of the user selecting a TrainGroup to load detail data or similar.
 * Detailed data is typically sensor data and also includes derived data from sensor data like geojson
 * Loads/Updates TrainGroup props in trainProps.trainGroupSingleTrainRunProps
 * This currently represents FilteredTrainGroups that have been selected in some way by the
 * user.
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainGroupSingleTrainRunDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: Required<TrainAppMapDependencyProps>): ReactElement => {
  const organizationHasServiceLines = doesOrganizationHaveServiceLines(organizationProps);

  const [trainGroups, setTrainGroups] = useState<TrainGroup[]>([]);
  const [trainGroupLookup, setTrainGroupLookup] =
    useState<Perhaps<Record<string, TrainGroup>>>(undefined);

  // Note trainGroupsForTrainRoute is defined in TrainGroupDependency.js
  useSyncTrainGroupsToStoredAndPreconfigured({
    loading,
    organizationHasServiceLines: doesOrganizationHaveServiceLines(organizationProps),
    filteredTrainGroups: trainProps.filteredTrainGroupProps!.filteredTrainGroups,
    trainGroupsForGroupingId:
      trainProps.filteredTrainGroupProps!.trainGroupsForTrainRoute,
    trainGroups,
    setTrainGroups,
  });

  const [crudTrainGroups, setCrudTrainGroups] =
    useState<PerhapsCemited<CrudList<TrainGroupSingleTrainRun>>>(undefined);

  // Manages changes to the TrainGroups
  useEffectCreateCrudTrainGroups(
    organizationProps.organization,
    organizationHasServiceLines,
    trainGroups,
    setTrainGroups,
    setCrudTrainGroups,
    trainGroupLookup,
    setTrainGroupLookup,
    trainProps.trainRouteGroupProps.trainRouteOrGroup,
    trainProps.filteredTrainGroupProps?.setTrainGroupsByGroupingId,
  );

  // Set the crudTrainGroup items to the fully loaded TrainRoutes when available
  // TrainRuns come from the train-api with TrainRouteMinimized instances
  useNotLoadingEffectSetLoadedVersionOfProp(
    loading,
    trainProps.trainRouteGroupProps.trainRoutes,
    crudTrainGroups,
    'trainRouteOrGroup',
  );
  // Set the fully loaded TrainFormations
  useNotLoadingEffectSetLoadedVersionOfProp<TrainFormation, TrainGroupSingleTrainRun>(
    loading,
    useNotLoadingMemo(
      loading,
      (trainGroupOnlyTrainFormations): TrainFormation[] => {
        return map(prop('trainFormation'), trainGroupOnlyTrainFormations);
      },
      [trainProps.trainGroupOnlyTrainFormationProps?.trainGroupOnlyTrainFormations],
    ),
    crudTrainGroups,
    'trainFormation',
  );

  // The active TrainGroupSingleTrainRuns from crudTrainGroups
  const activeTrainGroupSingleTrainRuns: Perhaps<TrainGroupSingleTrainRun[]> =
    useNotLoadingMemoActiveTrainGroups(
      loading || !crudTrainGroups,
      organizationProps,
      // Bootstrap trainProps to have trainProps.trainGroupSingleTrainRunProps.crudTrainGroups
      set(
        lensPath(['trainGroupSingleTrainRunProps', 'crudTrainGroups']),
        crudTrainGroups,
        trainProps,
      ),
      1,
      CemitTypename.trainGroupSingleTrainRun,
    );
  // Update any activeTrainGroupSingleTrainRuns in crudTrainGroups whose activity property changed
  useNotLoadingUpdateCrudIfActivityChanges<TrainGroupSingleTrainRun>(
    loading,
    activeTrainGroupSingleTrainRuns,
    crudTrainGroups,
  );

  const localLoadingProps = {
    // TODO fix loader if still needed
    // trainGroupsMatchTrainRouteOrGroup:
    //   useNotLoadingMemoTrainGroupsMatchTrainRouteOrGroup(
    //     loading,
    //     trainProps,
    //     trainGroups,
    //   ),
    // trainGroupsMatchCache: useNotLoadingMemoTrainGroupsMatchCache(
    //   loading,
    //   trainProps,
    //   trainGroups,
    // ),
    trainGroupsMatchCrud: useNotLoadingMemoTrainGroupsMatchCrud(
      loading || !crudTrainGroups,
      trainGroups,
      crudTrainGroups,
    ),
  };

  const whatIsLoading = useWhatIsLoading(
    loading,
    isLoadingStringOfDependencyUnit(TrainGroupSingleTrainRunDependency.name),
    TrainGroupSingleTrainRunDependency.name,
    {
      ...localLoadingProps,
    },
    values(localLoadingProps),
    appProps.setWhatDependenciesAreLoading,
    !organizationHasServiceLines ||
      // If we have ServiceLines, activeTrainGroups must be defined and no localLoadingProps can be true
      any(Boolean, values(localLoadingProps)),
  );

  const trainGroupSingleTrainRunProps = useMemoClsOrType<TrainGroupSingleTrainRunProps>(
    CemitTypename.trainGroupSingleTrainRunProps,
    {
      whatIsLoading,
      crudTrainGroups,
      activeTrainGroupSingleTrainRuns,
    },
  );

  const trainPropsMerged = useMemoMergeTrainProps(
    trainProps,
    trainProps.__typename,
    'trainGroupSingleTrainRunProps',
    trainGroupSingleTrainRunProps,
  );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    trainGroupSingleTrainRunProps,
    trainPropsMerged,
    'TrainGroupSingleTrainRunDependency',
  );

  // We don't require anything to be in TrainGroups, because not every TrainRoute has
  // a baseline TrainRun, which would otherwise be the default active TrainGroup
  return renderChildren({
    appProps,
    organizationProps,
    trainProps: trainPropsMerged,
    mapProps,
  });
};
TrainGroupSingleTrainRunDependency.displayName = 'TrainGroupSingleTrainRunDependency';
export default TrainGroupSingleTrainRunDependency;
// export default memo(TrainGroupSingleTrainRunDependency, (prevProps, currentProps) => {
//   const appPropsEqual = prevProps.appProps == currentProps.appProps;
//   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
//   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
//   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
//   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
// });
