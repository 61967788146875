import {
  AppTrainDependencyProps,
  TrainAppTrainDependencyProps,
} from 'types/propTypes/trainPropTypes/trainTrainAppDependencyProps';
import {Typography} from '@mui/material';

/**
 * Instructs the user to select one or more Trains
 * @param appProps
 * @constructor
 */
const ChooseTrainGroupMessage = ({
  appProps,
  trainProps,
}: TrainAppTrainDependencyProps) => {
  return (
    <Typography {...{color: 'secondary'}}>
      {trainProps.loading ? undefined : appProps.t('chooseTrainFormations')}
    </Typography>
  );
};

export default ChooseTrainGroupMessage;
