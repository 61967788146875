import {Box, Stack, Typography} from '@mui/material';
import {map} from 'ramda';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {Vehicle} from '../../../../../types/trains/vehicle';
import CollectionDevice from './CollectionDevice.tsx';
import {TrainFormationCollectionDevice} from '../../../../../types/sensors/trainFormationCollectionDevice';
import {TrainGroup} from 'types/trainGroups/trainGroup';

/**
 * A trains vehicle description from the CDCs
 * @param showTitle Default true. Show the title
 * @param trainRun
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainDescription = ({
  showTitle = true,
  trainGroup,
}: {
  showTitle: boolean;
  trainGroup: TrainGroup;
}) => {
  const {t} = useTranslation();
  const trainGroupCollectionDeviceComponents: JSX.Element[] = map(
    ({vehicle, collectionDevice}: TrainFormationCollectionDevice) => {
      return (
        <Typography variant="caption" key={collectionDevice.name}>
          {vehicle.name}
        </Typography>
      );
    },
    trainGroup.trainGroupCollectionDevices,
  );

  return (
    <Stack
      {...{
        direction: 'row',
        spacing: 2,
        sx: {color: 'secondary.main'},
      }}
    >
      {showTitle && (
        <Box sx={{width: '40px'}}>
          <Typography key="equipmentTitle" variant="caption">
            {t('train')}
          </Typography>
        </Box>
      )}
      <Stack key="vehicles" direction="row" spacing={1}>
        {trainGroupCollectionDeviceComponents}
      </Stack>
    </Stack>
  );
};
