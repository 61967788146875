import {CrudList} from '../../types/crud/crudList';
import {compact} from '@rescapes/ramda';
import {
  always,
  has,
  ifElse,
  includes,
  indexBy,
  length,
  lensPath,
  lensProp,
  map,
  set,
} from 'ramda';
import {Identified} from '../../types/identified';
import {Versioned} from '../../types/versioning/versioned';

/**
 * Given a listCrud and instances of that implement the listCrud instance type,
 * update the listCrud instances to the instances' [loadingStatusProperty].loading
 * value.
 *
 * This is used to sync up a listCrud list of instances' loading status when
 * an independent list of instances that based on the listCrud instances
 * is in a loading state.
 * @param loadingStatusProperty
 * @param instances
 * @param listCrud
 */
export const mirrorLoadingStatusInCrud = <T extends B, B extends Identified & Versioned>(
  loadingStatusProperty: keyof T,
  instances: T[],
  listCrud: CrudList<B>,
) => {
  const instancesLookup: Record<string, T> = indexBy(
    (trainGroup) => trainGroup.id!,
    instances,
  );

  // If any instance B in crudLIst.list needs to load, set it's [loadingStatusProperty].loading=true
  // and then filter down to those instances
  // This doesn't force loading but just shows a status to the user if needed
  const mirroredInstanceList: B[] = compact(
    map((listCrudInstance: B) => {
      return ifElse(
        (listCrudInstance: B) => {
          return has(listCrudInstance.id!, instancesLookup);
        },
        (trainGroup) =>
          set(
            lensProp(loadingStatusProperty as string),
            instancesLookup[listCrudInstance.id!][loadingStatusProperty],
            trainGroup,
          ),
        always(undefined),
      )(listCrudInstance);
    }, listCrud.list),
  );

  // Save the sensorDataIsLoading status
  if (length(mirroredInstanceList)) {
    // We only need to update the loadingStatusProperty, don't merge anything else
    listCrud.updateOrCreateAllNoMerge(mirroredInstanceList);
  }
};
