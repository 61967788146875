import {TypographyOptions} from '@mui/material/styles/createTypography';
import {Palette} from '@mui/material';

export const typography:
  | TypographyOptions
  | ((palette: Palette) => TypographyOptions)
  | undefined = {
  button: {
    // Disabled default uppercase
    textTransform: 'none',
  },
  fontFamily: 'Roboto, sans-serif',

  // Font weights
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  // Headings and typography variants
  h1: {
    fontSize: '2.5rem', // 40px
    fontWeight: 500,
    letterSpacing: '-0.24px',
    // Typically used for the largest headers, such as website or page titles.
  },
  h2: {
    fontSize: '2rem', // 32px
    fontWeight: 500,
    letterSpacing: '-0.24px',
    // Used for secondary headers, e.g., section titles.
  },
  h3: {
    fontSize: '1.75rem', // 28px
    fontWeight: 500,
    letterSpacing: '-0.06px',
    // Used for tertiary headers.
  },
  h4: {
    fontSize: '1.5rem', // 24px
    fontWeight: 500,
    letterSpacing: '-0.06px',
    // Used for quaternary headers or important information highlights.
  },
  h5: {
    fontSize: '1.25rem', // 20px
    fontWeight: 500,
    letterSpacing: '-0.05px',
    // Used for smaller headers, e.g., card titles.
  },
  h6: {
    fontSize: '1rem', // 16px
    fontWeight: 500,
    letterSpacing: '-0.05px',
    // Typically used for minor headings or annotations.
  },
  subtitle1: {
    fontSize: '0.875rem', // 14px
    fontWeight: 400,
    letterSpacing: '-0.05px',
    // Suitable for secondary text or subheadings.
  },
  subtitle2: {
    fontSize: '0.750rem', // 12px
    fontWeight: 400,
    letterSpacing: '0.1px',
    // Used for tertiary text, minor annotations, or supporting text.
  },
  body1: {
    fontSize: '1rem', // 16px
    fontWeight: 400,
    letterSpacing: '-0.05px',
    // Main text size for content, such as paragraphs.
  },
  body2: {
    fontSize: '0.750rem', // 12px
    fontWeight: 300,
    letterSpacing: '0.25px',
    // Slightly smaller text, useful for less-important information or details.
  },
  body3: {
    fontSize: '10px', // 12px
    fontWeight: 300,
    letterSpacing: '0.25px',
    lineHeight: '1.1',
    // Slightly smaller text, useful for less-important information or details.
  },
  body4: {
    fontSize: '10px', // 12px
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '1.1',
    // Slightly smaller text, useful for less-important information or details.
  },
};

export default typography;
