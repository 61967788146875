import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {AlertConfigProps} from 'types/alerts/alertConfigProps.ts';
import {AlertTrainGroupProps} from 'types/alerts/alertTrainGroupProps.ts';

export const createEmptyRealtimeScopeProps = (): AlertScopeProps => {
  return clsOrType<AlertScopeProps>(CemitTypename.realtimeTrainScopeProps, {
    realtimeTrainConfigProps: clsOrType<AlertConfigProps>(
      CemitTypename.realtimeTrainConfigProps,
      {},
    ),
    realtimeTrainGroupProps: clsOrType<AlertTrainGroupProps>(
      CemitTypename.realtimeTrainGroupProps,
      {},
    ),
  });
};
