import {CemitAppOrganizationComponentDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationComponentDependencyProps';
import {IconButton, Stack, Tooltip} from '@mui/material';
import {SxProps} from '@mui/system';
import {SvgIconComponent} from '@mui/icons-material';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {SyntheticEvent} from 'react';
import {ForwardBackward} from 'types/navigation/directionEnum..ts';
import {useTranslation} from 'react-i18next';
import {capitalize} from '@rescapes/ramda';

export interface VisionSpatialNavigatorItemProps extends CemitComponentProps {
  icon: SvgIconComponent;
  iconSx: SxProps;
  direction: ForwardBackward;
  meterDelta: number;
  handleSpatialNavigatorClick: (e: SyntheticEvent, direction: ForwardBackward) => void;
}

/**
 * Forward or Backward button to move along tracks
 * @param appProps
 * @param componentProps
 * @constructor
 */
export const VisionSpatialNavigatorItem = ({
  componentProps,
}: CemitAppOrganizationComponentDependencyProps<VisionSpatialNavigatorItemProps>) => {
  const {t} = useTranslation();
  const Icon = componentProps.icon;
  return (
    <Stack {...{direction: 'row', spacing: 0, sx: {justifyContent: 'center'}}}>
      <Tooltip
        {...{arrow: true, title: t(`move${capitalize(componentProps.direction)}`)}}
      >
        <IconButton
          {...{
            sx: {
              '& svg': {
                fontSize: '40px',
              },
            },
            'aria-label': `move ${componentProps.direction}`,
            color: 'secondary',
            onClick: (e: SyntheticEvent) => {
              componentProps.handleSpatialNavigatorClick(e, componentProps.direction);
            },
          }}
        >
          <Icon {...{sx: componentProps.iconSx}} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
