import React, {useRef, useState} from 'react';
import {dataPathConfiguration} from 'config/appConfigs/trainConfigs/dataConfig.tsx';
import {useMemoGetChartDataConfigs} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trackRouteUtils.ts';
import {Stack} from '@mui/material';
import TrainChartDisplayContainer from '../../trainAppChartComponents/TrainChartDisplayContainer.tsx';
import {useDebounceValue} from 'usehooks-ts';
import {ChartDataCategoryConfig} from 'types/dataVisualizations/chartDataCategoryConfig';
import {chain, uniqBy} from 'ramda';
import {doesOrganizationHaveWheelScan} from 'utils/organization/organizationUtils.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {TrainGroupsScopeProps} from 'types/trainGroups/trainGroupScopeProps';

/**
 * Shows graphs for the TrainGroups of componentProps.scopedTrainGroups
 * @param trainProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @returns {JSX.Element}
 * @constructor
 */
const TrainGroupRunningCharacteristicsContainer = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  componentProps,
}: TrainAppMapomponentDependencyProps<TrainGroupsScopeProps>) => {
  const {chosenAcceleration, setChosenAcceleration} = appProps;

  // The TrainFormation size is the optional area right of the TrainGroup list. Its size is
  // adjusted by pulling the horizontal SplitPane divider or by changing the browser window size
  const [runningCharacteristicsSize, setRunningCharacteristicsSize] = useState({
    width: 0,
    height: 0,
  });

  const ref = useRef();
  // Wrap runningCharacteristicsSize in useDebounce so that dragging the divider doesn't constantly updated, rather every
  // 100ms
  const [debouncedRunningCharacteristicsSize] = useDebounceValue(
    runningCharacteristicsSize,
    100,
  );
  // Force change trainDetailSize when the divider is moved
  const onChangeRunningCharacteristicsSize = ({
    lastMeasure,
    secondaryMeasure,
  }: {
    lastMeasure: number;
    secondaryMeasure: number;
  }) => {
    setRunningCharacteristicsSize({width: lastMeasure, height: secondaryMeasure});
  };

  const handleDrawerOpen = () => {
    appProps.setRunningCharacteristicsConfigurationOpen(true);
  };

  const handleDrawerClose = () => {
    appProps.setRunningCharacteristicsConfigurationOpen(false);
  };

  const chartDataCategoryConfigs: ChartDataCategoryConfig[] = dataPathConfiguration({
    appProps,
    organizationProps,
    trainProps,
  });
  const otherDisplayDataPaths = uniqBy(
    (s: string) => s,
    chain((chartDataCategoryConfig: ChartDataCategoryConfig): string[] => {
      return chartDataCategoryConfig.otherDisplayDataPaths || [];
    }, chartDataCategoryConfigs),
  );
  const dataPathsConfigs = useMemoGetChartDataConfigs(
    appProps,
    chartDataCategoryConfigs,
    chosenAcceleration.derivedProp,
  );

  const trainCharDisplayContainer = (
    <TrainChartDisplayContainer
      key="trainCharDisplayContainer"
      {...{
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        componentProps: {
          dataPathsConfigs,
          chosenAcceleration,
          setChosenAcceleration,
          otherDisplayDataPaths,
        },
      }}
    />
  );

  const hasWheelScan = doesOrganizationHaveWheelScan(
    organizationProps.loading,
    organizationProps.organization,
  );
  return (
    <Stack
      key="trainRunningCharacteristicsAndMaybeTable"
      {...{
        sx: {
          minWidth: 0,
          minHeight: 0,
          width: '100%',
          height: '100%',
          flex: 1,
          overflow: 'hidden',
        },
      }}
    >
      {trainCharDisplayContainer}
    </Stack>
  );
};
export default TrainGroupRunningCharacteristicsContainer;
