import {useEffect, useState} from 'react';
import {Client} from 'graphql-ws';
import {lensPath, slice} from 'ramda';
import {RealtimeTrainSignal} from 'types/realtimeTrain/signalData';
import {overClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';

const SUBSCRIPTION_ITEM_LIMIT = 1000;
/**
 * Creates a graphql websocket subscription based on spaceId
 * @param client
 * @param query
 * @param spaceId
 * @param realtimeIsActive
 */
export const useSubscription = <T,>(
  client: Client,
  query: string,
  spaceId: string,
  realtimeIsActive: boolean,
) => {
  const [data, setData] = useState<T[] | undefined>(undefined);

  useEffect(() => {
    if (!spaceId || !realtimeIsActive) {
      return undefined;
    }
    const subscribe = async () => {
      try {
        const subscription = client.iterate<{data: T}>({
          query,
          variables: {spaceId},
        });

        for await (const result of subscription) {
          if (result.data) {
            setData((prevData) => {
              // Change the timestamp to a date
              const updated = overClassOrType<RealtimeTrainSignal>(
                lensPath(['signalAdded', 'timestamp']),
                (timestamp: string) => new Date(timestamp),
                clsOrType<RealtimeTrainSignal>(
                  CemitTypename.realtimeTrainSignal,
                  result.data as RealtimeTrainSignal,
                ),
              );
              return slice(-SUBSCRIPTION_ITEM_LIMIT, Infinity, [
                ...(prevData || []),
                updated,
              ]);
            });
          }
        }
      } catch (error) {
        console.error('Error in subscription:', error);
      }
    };

    subscribe();

    return () => {
      client.dispose(); // Clean up the WebSocket connection
    };
  }, [client, query, spaceId, realtimeIsActive]);
  return data;
};
