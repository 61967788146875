import {Stack} from '@mui/material';
import React, {forwardRef, memo, useRef} from 'react';
import SplitPaneWrapper from './SplitPaneWrapper.tsx';
import {FrontendView} from '../../../config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import TrainGroupListAndMaybeDetailBoards from './TrainGroupListAndMaybeDetailBoards.tsx';
import {Size} from '../../../types/layout/size';
import {CemitComponentProps} from '../../../types/propTypes/cemitComponenProps';
import {includesAny} from '../../../utils/functional/functionalUtils.ts';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {TrainAppMapComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';

export interface TrainBoardProps extends CemitComponentProps {
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;

  trainDetailSize: Size;
  onChangeTrainDetailSize: () => void;
  // The height in percent of the TrainList and TrainFormation top section compared to the optional
  // height of the Train detail bottom section
  minimumTrainListAndFormationTopHeight: string | number;

  trainFormationSize: {
    width: Number;
    height: Number;
  };
  onChangeTrainFormationSize: () => void;
  // The minimum width of the TrainList when the TrainFormation is showing
  minimumTrainListLeftWidth: string | number;
}

/**
 * Shows a TrainGroup filter board (top) and TrainGroup equipment detail for the active TrainGroups
 * TODO The filter is currently hardcoded to RideComfort filters
 * TODO The equipment was a list of wheelgroups for Mantena. It's not currently used for anything else
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @param componentProps.handleDrawerOpen
 * @param componentProps.handleDrawerClose
 * @param trainDetailSize: The width and height of right size of the divided container tha holds this component
 * @param sx
 * @returns {JSX.Element}
 * @constructor
 */
const TrainFilterAndMaybeEquipmentListBoards = memo(
  ({
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    componentProps,
  }: TrainAppMapComponentDependencyProps<TrainBoardProps>) => {
    const frontendViewDetailTrainFromationEquipmentIsActive = includesAny(
      [FrontendView.detailTrainFormationEquipment],
      appProps.activeViews || [],
    );
    // If the detail view is present, make the default list height minimized, otherwise take up the whole height
    const defaultListHeight = frontendViewDetailTrainFromationEquipmentIsActive
      ? componentProps.minimumTrainListAndFormationTopHeight
      : '100%';

    // In detail mode, optionally show the TrainFormation diagram next to the minimized TrainList
    const trainListAndFormation = (
      <TrainGroupListAndMaybeDetailBoards
        key="trainPageListAndFormation"
        {...{
          appProps,
          organizationProps,
          trainProps,
          mapProps,
          componentProps: {
            trainDetailSize: componentProps.trainDetailSize,
            onChangeTrainFormationSize: componentProps.onChangeTrainFormationSize,
            minimumTrainListLeftWidth: componentProps.minimumTrainListLeftWidth,
            handleDrawerOpen: componentProps.handleDrawerOpen,
            handleDrawerClose: componentProps.handleDrawerClose,
          },
        }}
      />
    );

    // Either show the List and Detail in a horizontal separator or just show the list
    const trainListAndMaybeDetail = frontendViewDetailTrainFromationEquipmentIsActive ? (
      <SplitPaneWrapper
        {...{
          split: 'horizontal',
          displayProps: appProps.displayProps,
          defaultSize: defaultListHeight,
          onChange: componentProps.onChangeTrainDetailSize,
          minSize: componentProps.minimumTrainListAndFormationTopHeight,
        }}
      >
        {[
          // The train list and possibly the formation diagram
          trainListAndFormation,
        ]}
      </SplitPaneWrapper>
    ) : (
      trainListAndFormation
    );

    return (
      <Stack
        key="trainBoard"
        {...{
          spacing: 1,
          sx: {
            minHeight: 0,
            flex: 1,
            overflow: 'hidden',
            // Force the TrainBoard to be full width
            minWidth: '100%',
            width: '100%',
            height: '100%',
            zIndex: 2,
          },
        }}
      >
        {trainListAndMaybeDetail}
      </Stack>
    );
  },
);
TrainFilterAndMaybeEquipmentListBoards.displayName = 'TrainBoard';
export default TrainFilterAndMaybeEquipmentListBoards;
