import {
  OrderedRoutePoint,
  OrderedRoutePointResponse,
  OrderedRoutePointDerived,
} from '../../types/trainRouteGroups/orderedRoutePoint';
import {lensProp, map, omit, over} from 'ramda';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {
  RoutePoint,
  RoutePointResponse,
  RoutePointDerived,
} from '../../types/trainRouteGroups/routePoint';
import {createRoutePoint} from './routePointCrud.ts';
import {distanceAlongLine} from '../../utils/distance/distanceUtils.ts';
import {
  scheduledStopPointOfRoutePoint,
  scheduledStopPointsOfTrainRouteOrGroup,
} from '../../appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import {TrainRouteOrGroup} from '../../types/trainRouteGroups/trainRouteOrGroup';
import {headOrThrow} from '../../utils/functional/functionalUtils.ts';
import {Feature, Point} from 'geojson';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';
import {useMemoClsOrType} from '../../appUtils/typeUtils/useMemoClsOrType.ts';

/**
 * Creates an OrderedRoutePoint from the OrderedRoutePointResponse
 * this does create on all child types
 * @param orderedRoutePointResponse
 */
export const createOrderedRoutePoint = (
  orderedRoutePointResponse: OrderedRoutePointResponse,
) => {
  return clsOrType<OrderedRoutePoint>(
    CemitTypename.orderedRoutePoint,
    over<OrderedRoutePointResponse, RoutePointResponse>(
      lensProp('routePoint'),
      (routePoint: RoutePointResponse): RoutePoint => {
        return createRoutePoint(routePoint);
      },
      orderedRoutePointResponse,
    ),
  );
};

/**
 * Adds routeDistance to orderedRoutePoint.routePoint
 * @param orderedRoutePoint
 * @param distanceToRoutePoint
 */
export const orderedRoutePointDerived = (
  orderedRoutePoint: OrderedRoutePoint,
  distanceToRoutePoint: number,
): OrderedRoutePointDerived => {
  const routePointDerived: RoutePointDerived = clsOrType<RoutePointDerived>(
    CemitTypename.routePointDerived,
    {
      ...orderedRoutePoint.routePoint,
      routeDistance: distanceToRoutePoint,
    },
  );

  return clsOrType<OrderedRoutePointDerived>(CemitTypename.orderedRoutePointDerived, {
    ...omit<OrderedRoutePoint, 'routePoint'>(['routePoint'], orderedRoutePoint),
    routePoint: routePointDerived,
  });
};

/**
 * For each TrainRoute of TrainRouteOrGroup.trainRouteGroups, get a list of OrderedRoutePointDerived
 * @param trainRouteOrGroup
 * @param orderedRoutePoints
 */
export const orderedRoutePointSetsDerived = (
  trainRouteOrGroup: TrainRouteOrGroup,
  orderedRoutePoints: OrderedRoutePoint[],
): OrderedRoutePointDerived[] => {
  const startPoint = headOrThrow(
    scheduledStopPointsOfTrainRouteOrGroup(trainRouteOrGroup),
  ).geojson;
  return map((orderedRoutePoint: OrderedRoutePoint) => {
    const distanceToRoutePoint = distanceAlongLine({
      units: 'meters',
      start: startPoint,
      end: scheduledStopPointOfRoutePoint(orderedRoutePoint.routePoint)
        .geojson as Feature<Point>,
      line: trainRouteOrGroup.trackRouteLineString,
    });
    return orderedRoutePointDerived(orderedRoutePoint, distanceToRoutePoint);
  }, orderedRoutePoints);
};
