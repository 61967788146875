import {
  all,
  always,
  any,
  chain,
  compose,
  cond,
  equals,
  filter,
  head,
  includes,
  is,
  length,
  lt,
  map,
  mergeRight,
  pick,
  T,
  unless,
  values,
} from 'ramda';
import {
  findOnlyOneOrThrow,
  headOrThrow,
  onlyOneValueOrThrow,
  toArrayIfNot,
} from '../../utils/functional/functionalUtils.ts';
import {ClassFromObj} from '../../types/classes';
import {CemitTypename} from '../../types/cemitTypename.ts';
import {Cemited} from '../../types/cemited';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {chainObjToValues} from '@rescapes/ramda';
import {cemitAppCemitedClassesManifest} from './cemitAppCemitedClassesManifest.ts';
import {CemitedClass} from './cemitedClass.ts';
import {clsOrType} from '../../appUtils/typeUtils/clsOrType.ts';

/**
 * Get the class or classes that the obj.__typename resolves to or throws
 * @param obj
 * @param emptyOnMiss Default false, if true, return an empty list if no class is defined instead of throwing
 */
export const cemitTypeObjectAsClasses = <T extends CemitedClass>(
  obj: Pick<T, '__typename'>,
  emptyOnMiss: boolean = false,
): ClassFromObj<T>[] | never => {
  return toArrayIfNot(
    cemitObjectToClassOrClasses<T>(obj, emptyOnMiss) as ClassFromObj<T>,
  );
};

/**
 * Resolve the class matching obj.__typename.
 * @param obj an object implemented Cemited so that it has a __typename
 * @param undefinedOnMiss Default false, if true, return undefined if no class is defined instead of throwing
 * @returns The class or undefined if there is a miss and undefinedOnMiss is true
 */
export const cemitTypeObjectAsClass = <T extends CemitedClass>(
  obj: Pick<T, '__typename'>,
  undefinedOnMiss: boolean = false,
): Perhaps<ClassFromObj<T>> | never => {
  const classes: ClassFromObj<T>[] = cemitTypeObjectAsClasses<T>(obj, undefinedOnMiss);

  if (length(classes) > 1) {
    throw new Error(`cemitTypeObjectAsClassInstance called with an object ${obj.__typename} that resolves to multiple classes: ${map((cls) => cls.name, classes)}. Limit
the object to one type before calling cemitTypeObjectAsClassInstance`);
  }
  return head<ClassFromObj<T>>(classes);
};
/**
 * Resolve the class matching obj.__typename and instantiate it with obj.
 * This is useful for seeing what interfaces obj implements at runtime and in the future we will
 * probably convert all objects into classes with this
 * @param obj an object implemented Cemited so that it has a __typename. If it is already a CemitedClass,
 * it is returned
 * @returns an instantiation of a class matching the typename or thows if none exists yet
 */
export const cemitTypeObjectAsClassInstance = <T extends CemitedClass>(obj: T): T => {
  if (is(CemitedClass, obj)) {
    return obj;
  }
  const cls = cemitTypeObjectAsClass<T>(obj);
  return new cls(obj);
};
/**
 * Like cemitTypeObjectAsClassInstance but limits the instantiated instance to the members of T.
 * This removes
 * @param downcastTypename
 * @param obj
 */
export const cemitTypeObjectAsClassInstanceDowncastedInstance = <T extends CemitedClass>(
  downcastTypename: CemitTypename,
  obj: T,
) => {
  const [cls, downcastObj] = _cemitTypeObjectAsClassInstanceDowncasted<T>(
    downcastTypename,
    obj,
  );
  // Instantiate object as that class the limited attributes
  return new cls(downcastObj) as T;
};
/**
 * Downcasts to an object of the given typeObject T extending Cemited or the equivalent CemitedClass if the latter exists.
 * The downcastTypename must resolve to a class that is a base class of obj.__typename's resolved class
 * @param downcastTypename
 * @param obj
 */
export const cemitTypeObjectAsClassInstanceDowncastedObject = <
  T extends F,
  F extends CemitedClass = T,
>(
  downcastTypename: CemitTypename,
  obj: F,
) => {
  const [_cls, downcastObj] = _cemitTypeObjectAsClassInstanceDowncasted<T>(
    downcastTypename,
    obj,
  );
  // Just return the object
  return downcastObj as T;
};
export const _cemitTypeObjectAsClassInstanceDowncasted = <T extends CemitedClass>(
  downcastTypename: CemitTypename,
  obj: T,
): [ClassFromObj<T>, T] => {
  // Make sure obj implements the downcasted type
  implementsCemitTypeOrThrow(downcastTypename, obj);
  const objInstance = cemitTypeObjectAsClassInstance<T>(obj);
  // Get the class we want to downcast to
  const classes: ClassFromObj<T>[] = cemitTypeObjectAsClasses<T>({
    __typename: downcastTypename,
  });
  // If there are multiple classes that match the typename, find the one that obj.__typename extends
  const eligibleClasses = filter((clz) => {
    return objInstance instanceof clz;
  }, classes);
  if (length(eligibleClasses) != 1) {
    throw new Error(
      `Expected obj's corresponding class ${objInstance.prototype.name} to be a subclass of one of ${map((cls) => cls.name, classes)}`,
    );
  }
  const cls = headOrThrow(eligibleClasses);
  // Get the downcasted typename if downcastTypename resolved to multiple classes
  const typenameOfClass: CemitTypename = classToCemitTypeName(cls);

  // Get all attributes of the downcast class. We must instantiate it to access the class properties
  // We use Object.keys instead of Object.getOwnProperties to get inherited properties.
  const keys: string[] = Object.keys(new cls({__typename: downcastTypename}));
  // Instantiate object as that class the limited attributes
  const typeObject = mergeRight<T, Pick<T, '__typename'>>(
    pick<T, keyof T>(keys as (keyof T)[], obj) as T,
    {__typename: typenameOfClass},
  ) as T;
  return [cls, clsOrType(typeObject.__typename, typeObject)];
};
/**
 * Resolves the CemitTypename of the give Cemited class
 */
const classToCemitTypeName = compose(
  (pairs: [ClassFromObj<CemitedClass>, CemitTypename][]) => {
    // Create a function that expects a class and returns a CemitTypename
    return (cls: ClassFromObj<CemitedClass>): CemitTypename | never => {
      const results = filter(
        (pair: [ClassFromObj<CemitedClass>, cemitTypeName: CemitTypename]): boolean => {
          return equals(pair[0], cls);
        },
        pairs,
      );
      if (equals(0, length(results))) {
        throw new Error(
          `Expected class ${cls.name} to resolve to one CemitTypename, but got none`,
        );
      }
      if (lt(1, length(results))) {
        throw new Error(
          `Expected class ${cls.name} to resolve to one CemitTypename, but got pairs ${results}`,
        );
      } else {
        return headOrThrow(results)[1];
      }
    };
  },
  (cemitTypeNameToClasses) => {
    // Get pairs of [cls, cemitTypename]
    return chainObjToValues(
      (classes: ClassFromObj<CemitedClass>, cemitType: CemitTypename) => {
        return map<
          ClassFromObj<CemitedClass>,
          [ClassFromObj<CemitedClass>, CemitTypename]
        >(
          (cls) => {
            return [cls, cemitType];
          },
          // Only process CemitTypenames that resolve to one class. Those resolving to multiple
          // are classes are Or types and must have separate listing for the individual types
          unless<ClassFromObj<Cemited>[], ClassFromObj<Cemited>[]>(
            compose(equals(1), length),
            always([]),
          )(toArrayIfNot(classes)),
        );
      },
      cemitTypeNameToClasses,
    );
  },
)(cemitAppCemitedClassesManifest());
/**
 * Maps obj.__typename to all Cemit classes we have implemented that extend Cemit interfaces.
 * The classes never do more than accept all the properties of the interface.
 * @param obj
 * @param emptyOnMiss Return an empty list if no class exists yet
 */
export const cemitObjectToClassOrClasses = <R extends CemitedClass>(
  obj: Pick<R, '__typename'>,
  emptyOnMiss: boolean = false,
): ClassFromObj<R> | ClassFromObj<R>[] | never => {
  return cemitTypenameToClassOrClasses(obj.__typename, emptyOnMiss);
};

export const cemitTypenameToClassOrClasses = <R extends CemitedClass>(
  typename: CemitTypename,
  emptyOnMiss: boolean = false,
): ClassFromObj<R> | ClassFromObj<R>[] | never => {
  if (!typename) {
    throw new Error(
      'typename is not defined. Check the stacktrace when debugging to find out why the incoming object does not have a __typename property',
    );
  }

  return cond<[CemitTypename], ClassFromObj<R> | ClassFromObj<R>[] | never>([
    [
      (typename: CemitTypename) => {
        // On match return the single or multiple classes
        return Boolean(cemitAppCemitedClassesManifest()[typename]);
      },
      (typename: CemitTypename) => {
        return cemitAppCemitedClassesManifest()[typename] as
          | ClassFromObj<R>
          | ClassFromObj<R>[];
      },
    ],
    [
      T,
      () => {
        if (emptyOnMiss) {
          // On miss and emptyOnMiss, return []
          return [] as ClassFromObj<R>[];
        }
        throw Error(
          `Class implementation for ${typename} not yet implemented. Add it in cemitClasses.ts`,
        );
      },
    ],
  ])(typename);
};

/**
 * Returns the single class corresponding to the CemitTypename when only one class is expected
 * @param typename
 * @param emptyOnMiss
 */
export const cemitTypenameToClass = <T extends Cemited>(
  typename: CemitTypename,
  emptyOnMiss: boolean = false,
): ClassFromObj<T> | never => {
  return onlyOneValueOrThrow(
    toArrayIfNot(
      cemitTypenameToClassOrClasses(typename, emptyOnMiss),
    ) as ClassFromObj<T>[],
  );
};

/**
 * All unique Cemit classes registered in cemitTypeNameToClasses
 */
export const cemitClasses: ClassFromObj<CemitedClass>[] = chain((cls) => {
  // Only process CemitTypenames that resolve to one class. Those resolving to multiple
  // are classes are 'Or' types and must have separate listing for the individual types
  return unless<ClassFromObj<Cemited>[], ClassFromObj<Cemited>[]>(
    compose(equals(1), length),
    always([]),
  )(toArrayIfNot(cls));
}, values(cemitAppCemitedClassesManifest()));

/**
 * Returns true if the given obj implements typename. The object is converted to the class of its typename to
 * see if it inherits from the class of typename
 * TODO make this curryable with ramda's curry. I can't find good documentation on how to pass types to a curry
 * call. See https://www.freecodecamp.org/news/typescript-curry-ramda-types-f747e99744ab/
 * @param typename
 * @param obj
 */
export const implementsCemitTypeViaClass = <T extends CemitedClass>(
  typename: CemitTypename,
  obj: Perhaps<Cemited>,
): never | boolean => {
  const classes: ClassFromObj<T>[] = toArrayIfNot(
    cemitObjectToClassOrClasses({__typename: typename}),
  );
  if (!obj) {
    return false;
  }
  return any((cls) => is(cls, cemitTypeObjectAsClassInstance(obj)), classes);
};

/**
 * Simple equalilty check of obj.__typename against a CemitTypename
 * @param typename
 * @param obj
 */
export const equalsCemitType = (
  typename: CemitTypename,
  obj: Perhaps<Cemited>,
): boolean => {
  return equals(typename, obj?.__typename);
};
/**
 * Cast obj to T if it matches the typename
 * @param typename
 * @param obj
 */
export const castIfEqualsCemitType = <T extends Cemited>(
  typename: CemitTypename,
  obj: Perhaps<Cemited>,
): Perhaps<T> => {
  return equals(typename, obj?.__typename) ? T : undefined;
};

/**
 * Returns true if all objs implement the given typename
 * @param typename
 * @param objs
 */
export const allImplementCemitType = <T extends CemitedClass>(
  typename: CemitTypename,
  objs: Perhaps<Cemited[]>,
): never | boolean => {
  return _allOrAnyImplementCemitType<T>(all, typename, objs);
};
/**
 * Returns true if any obj implements the given typename
 * @param typename
 * @param objs
 */
export const anyImplementCemitType = <T extends CemitedClass>(
  typename: CemitTypename,
  objs: Perhaps<Cemited[]>,
): never | boolean => {
  return _allOrAnyImplementCemitType<T>(any, typename, objs);
};
type AllOrAny = <T, U extends {all: (fn: (a: T) => boolean) => boolean}>(
  fn: (a: T) => boolean,
  obj: U,
) => boolean;

/**
 * Calls implementsCemitType on each item of objs
 * @param allOrAny ramda.all or ramda.any
 * @param typename
 * @param objs
 */
export const _allOrAnyImplementCemitType = <T extends CemitedClass>(
  allOrAny: AllOrAny,
  typename: CemitTypename,
  objs: Perhaps<Cemited[]>,
): never | boolean => {
  const classes: ClassFromObj<T>[] = toArrayIfNot(
    cemitObjectToClassOrClasses({__typename: typename}),
  );

  if (!objs) {
    return false;
  }
  return allOrAny((obj: Cemited) => {
    // Get the corresponding class of the obj based on its __typename
    if (!obj) {
      return false;
    }
    const instance = cemitTypeObjectAsClassInstance<T>(obj as T);
    // See if objClass is a subclass of any of classes
    return any((clz) => {
      return instance instanceof clz;
    }, classes);
  }, objs);
};
export const cemitTypeError = <T extends CemitedClass>(
  expectedTypename: CemitTypename,
  obj: Perhaps<T>,
): never | boolean => {
  throw new Error(
    `Expected CemitTypename: ${expectedTypename}. Got ${obj ? obj.__typename : 'obj is undefined'}`,
  );
};
/**
 * Calls cemitTypeImplements and if false calls cemitTypeError. Else returns true
 * @param typename
 * @param obj
 */
export const implementsCemitTypeOrThrow = (
  typename: CemitTypename,
  obj: Perhaps<Cemited>,
): never | boolean => {
  if (!implementsCemitTypeViaClass(typename, obj)) {
    cemitTypeError(typename, obj);
  }
  return true;
};
/**
 * Like implementsCemitTypeOrThrow but returns obj as type T
 * @param typename
 * @param obj
 */
export const asCemitedClassOrThrow = <T extends Cemited>(
  typename: CemitTypename,
  obj: Perhaps<Cemited>,
): never | T => {
  if (!(obj && implementsCemitTypeViaClass(typename, obj))) {
    cemitTypeError(typename, obj);
  }
  return obj as T;
};
/**
 * Returns obj as T if it implements typename, else undefined
 * @param typename
 * @param obj
 */
export const maybeAsCemitedClass = <T extends Cemited>(
  typename: CemitTypename,
  obj: Perhaps<Cemited>,
): Perhaps<T> => {
  if (!(obj && implementsCemitTypeViaClass(typename, obj))) {
    return undefined;
  }
  return obj as T;
};

/**
 * Finds the class that extends obj's corresponding class and implements AsDerived.
 * Since javascript has no Interfaces and the typescript Interfaces are gone at runtime, this
 * simply checks that the class name conforms to the standard of contiaining 'Derived'
 * @param obj
 */
export const derivedType = (obj: Cemited): CemitTypename | never => {
  const objectAsClass = cemitTypeObjectAsClass(obj);
  const asDerivedClass = findOnlyOneOrThrow((cls: ClassFromObj<CemitedClass>) => {
    return cls.prototype instanceof objectAsClass && includes('Derived', cls.name);
  }, cemitClasses);
  return classToCemitTypeName(asDerivedClass);
};
