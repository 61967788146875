import {
  applySpec,
  compose,
  cond,
  equals,
  filter,
  find,
  head,
  indexBy,
  join,
  map,
  prop,
  propOr,
  T,
} from 'ramda';
import {extremes, headOrThrow, idsEqual} from 'utils/functional/functionalUtils.ts';
import {reqStrPathThrowing} from '@rescapes/ramda';
import {scaleLinear} from 'd3-scale';
import {TrainRoute, TrainRouteDerived} from '../../../types/trainRouteGroups/trainRoute';
import {
  TrainRouteGroup,
  TrainRouteGroupDerived,
  TrainRouteGroupWithOrderedRoutePointsDerived,
} from '../../../types/trainRouteGroups/trainRouteGroup';
import {RoutePoint, RoutePointDerived} from '../../../types/trainRouteGroups/routePoint';
import {
  ScheduledStopPoint,
  ScheduledStopPointDerived,
} from '../../../types/stops/scheduledStopPoint';
import {TFunction} from 'i18next';
import {
  TrainRouteOrGroup,
  TrainRouteOrGroupDerived,
} from '../../../types/trainRouteGroups/trainRouteOrGroup';
import {RailwayLine} from '../../../types/railways/railwayLine';
import {
  OrderedRoutePoint,
  OrderedRoutePointDerived,
} from '../../../types/trainRouteGroups/orderedRoutePoint';
import {PointProjection} from '../../../types/geometry/pointProjection';
import {typenameEquals} from '../../typeUtils/typenameUtils.ts';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {Cemited} from '../../../types/cemited';

import {
  implementsCemitTypeViaClass,
  maybeAsCemitedClass,
} from '../../../classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {Perhaps} from '../../../types/typeHelpers/perhaps';
import {StateSetter} from '../../../types/hookHelpers/stateSetter';
import {useMemoClsOrType} from '../../typeUtils/useMemoClsOrType.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {DistanceRange} from '../../../types/distances/distanceRange';

/**
 * Gets the scheduledStopPoints of the TrainRoute and adds the routeDistance from the routePoint to each.
 * trainRouteOrGroup.orderedRoutePoints must already have derived values
 * @param trainRouteOrGroupWithOrderedRoutePointsDerived The TrainRoute instance
 * @returns list ScheduledStopPoints with routeDistance added
 */
export const scheduledStopPointsOfTrainRouteOrGroup = (
  trainRouteOrGroupWithOrderedRoutePointsDerived: TrainRouteOrGroup,
): ScheduledStopPointDerived[] => {
  const routePoints: RoutePoint[] = routePointsOfTrainRouteOrGroup(
    trainRouteOrGroupWithOrderedRoutePointsDerived,
  );
  return map<RoutePoint, ScheduledStopPointDerived>((routePoint: RoutePointDerived) => {
    return clsOrType<ScheduledStopPointDerived>(CemitTypename.scheduledStopPoint, {
      ...scheduledStopPointOfRoutePoint(routePoint),
      routeDistance: routePoint.routeDistance,
    });
  }, routePoints);
};

/**
 * The ordered RoutePoints of the TrainRoute
 * @param trainRouteOrGroup
 * @returns The RoutePoints
 */
export const routePointsOfTrainRouteOrGroup = (
  trainRouteOrGroup: TrainRouteOrGroup,
): RoutePoint[] => {
  return map(prop('routePoint'), trainRouteOrGroup.orderedRoutePoints);
};

/**
 * Returns the RoutePoint matching the ScheduledStopPoint
 * @param trainRouteOrGroup
 * @param scheduledStopPoint
 * @returns {*}
 */
export const routePointOfTrainRouteAndScheduledStopPoint = (
  trainRouteOrGroup: TrainRouteGroupWithOrderedRoutePointsDerived,
  scheduledStopPoint: ScheduledStopPoint,
) => {
  return find((routePoint) => {
    return idsEqual(scheduledStopPointOfRoutePoint(routePoint), scheduledStopPoint);
  }, routePointsOfTrainRouteOrGroup(trainRouteOrGroup));
};

/**
 * Currently only on projection is expected per RoutePoint and thus one ScheduledStopPoint
 * @param routePoint
 * @returns {*}
 */
export const scheduledStopPointOfRoutePoint = (
  routePoint: RoutePoint,
): ScheduledStopPoint => {
  return routePoint.scheduledStopPoint;
};

export const scheduledStopPointOfOrderedRoutePoint = (
  orderedRoutePoint: OrderedRoutePoint,
): ScheduledStopPoint => {
  return orderedRoutePoint.routePoint.scheduledStopPoint;
};
/**
 * Returns the origin name of the TrainRoute or TrainRouteGroup of t('allOrigins') if the TrainRouteGrou
 * doesn't restrict the origin
 * @param t Translation service
 * @param trainRouteOrGroup A TrainRoute or TrainRouteGroup instance
 * @returns {*}
 */
export const trainRouteOrGroupOriginName = (
  {t}: {t: TFunction},
  trainRouteOrGroup: TrainRouteGroup,
) => {
  return trainRouteOrGroup.startScheduledStopPoint?.shortName || t('allOrigins');
};

/**
 * Returns the destination name of the TrainRoute or TrainRouteGroup of t('allDestinations') if the TrainRouteGrou
 * doesn't restrict the origin
 * @param t Translation service
 * @param trainRouteOrGroup A TrainRoute or TrainRouteGroup instance
 * @returns {*}
 */
export const trainRouteOrGroupDestinationName = (
  {t}: {t: TFunction},
  trainRouteOrGroup: TrainRouteGroup,
) => {
  return trainRouteOrGroup.endScheduledStopPoint?.shortName || t('allDestinations');
};

export const trainRouteOrGroupName = (
  {t}: {t: TFunction},
  trainRouteOrGroup: TrainRouteGroup,
) => {
  return join(' ', [
    trainRouteOrGroupOriginName({t}, trainRouteOrGroup),
    t('to'),
    trainRouteOrGroupDestinationName({t}, trainRouteOrGroup),
  ]);
};

/**
 * Gets the inverse route of the current trainRouteOrGroup if it has one
 @param trainRoutesOrGroups Used to resolve the reverse instance from an id
 @param trainRouteOrGroup The current TrainRoute
 */
export const getReverseTrainRouteOrGroup = (
  trainRoutesOrGroups: TrainRouteOrGroup[],
  trainRouteOrGroup: TrainRouteOrGroup,
): TrainRouteOrGroup | undefined => {
  if (!trainRoutesOrGroups) {
    throw new Error(`trainRoutesOrGroups is not defined`);
  }
  return find((testTrainRoute) => {
    return equals(reverseTrainRouteOrGroupId(trainRouteOrGroup), testTrainRoute.id);
  }, trainRoutesOrGroups);
};

/**
 * Finds the reverse TrainRouteOrGroup and calls chooseTrainRoute(reverseRoute);
 * @param trainRoutesOrGroups Used to resolve the reverse instance from an id
 * @param setTrainRouteOrGroup Sstter to call with the reversed TrainRouteOrGroup
 * @param trainRouteOrGroup
 */
export const reverseTrainRouteOrGroup = (
  {
    trainRoutesOrGroups,
    setTrainRouteOrGroup,
  }: {
    trainRoutesOrGroups: Perhaps<TrainRouteOrGroup[]>;
    setTrainRouteOrGroup: StateSetter<TrainRouteOrGroup>;
  },
  trainRouteOrGroup: TrainRouteOrGroup,
) => {
  const reversedTrainRouteOrGroup = getReverseTrainRouteOrGroup(
    trainRoutesOrGroups,
    trainRouteOrGroup,
  );
  if (!reversedTrainRouteOrGroup) {
    throw new Error('No reverse reversedTrainRouteOrGroup defined');
  }
  setTrainRouteOrGroup(reversedTrainRouteOrGroup);
};

/***
 * Returns the reverse TrainRouteGroup or TrainRoute
 * @param trainRouteOrGroup
 * @returns {*}
 */
export const reverseTrainRouteOrGroupId = (
  trainRouteOrGroup: TrainRouteOrGroup,
): Perhaps<string> => {
  return cond([
    [
      (t: Cemited) =>
        implementsCemitTypeViaClass(CemitTypename.trainRouteGroupMinimized, t),
      prop('reverseTrainRouteGroupId'),
    ],
    [
      (t: Cemited) => implementsCemitTypeViaClass(CemitTypename.trainRouteMinimized, t),
      prop('reverseTrainRouteId'),
    ],
    [
      T,
      (instance: Cemited) => {
        throw new Error(`Unexpected type ${instance.__typename}`);
      },
    ],
  ])(trainRouteOrGroup);
};

/**
 * Returns True if the trainRoute has __typename equal to 'TrainRoute'
 * @param trainRouteOrGroup
 * @returns {*}
 */
export const isTrainRouteGroup = (trainRouteOrGroup: TrainRouteOrGroup): boolean => {
  return implementsCemitTypeViaClass(CemitTypename.trainRouteGroup, tr);
  return typenameEquals(CemitTypename.trainRouteGroup, trainRouteOrGroup);
};

/**
 * Given a TrainRoute and one of it's ScheduledStopPoints, find the reference ScheduledStop point of
 * the Railway that the ScheduledStopPoint is on and return the formers routeDistance so that we can calculate
 * the absolute distance from the reference ScheduledStopPoint to the one given here
 * @param trainRouteOrGroup
 * @param scheduledStopPoint
 * @param railwayLines Complete objects scheduledStopPoint.railwayLines or minimized so must be matched to these
 * @returns {*}
 */
export const referenceStopDistanceForTrainRouteOrGroup = (
  trainRouteOrGroup: TrainRouteGroupWithOrderedRoutePointsDerived,
  scheduledStopPoint: ScheduledStopPoint,
  railwayLines: RailwayLine[],
): number | undefined => {
  // Measure from the distance given by trainRoute.measureDistancesFrom or else the stop's routeDistance
  const scheduledStopPoints: ScheduledStopPointDerived[] =
    scheduledStopPointsOfTrainRouteOrGroup(trainRouteOrGroup);
  // Assume the same reference ScheduledStopPoint if the stop is on more than one railway
  const routePoint: Perhaps<RoutePoint> = routePointOfTrainRouteAndScheduledStopPoint(
    trainRouteOrGroup,
    scheduledStopPoint,
  );
  // pseudo-stops won't match here
  if (!routePoint) {
    return undefined;
  }
  const railwayLineOfStopPoint = head(routePoint.railwayLines || []);
  const railwayLine = find(idsEqual(railwayLineOfStopPoint), railwayLines);
  if (!railwayLine) {
    throw Error('railwayLine must be defined');
  }
  const referenceScheduledStopPoint = find(
    (scheduledStopPoint: ScheduledStopPointDerived) => {
      return idsEqual(railwayLine.referenceScheduledStopPoint, scheduledStopPoint);
    },
    scheduledStopPoints,
  );
  return referenceScheduledStopPoint?.routeDistance;
};

/**
 * Maps a distanceRange from one TrainRoute's distanceRange to another
 * @param activeTrainRouteOrGroup The TrainRoute or TrainRouteGroup. If a TrainRouteGroup, map
 * the distanceRange from the domain of TrainRouteGroup that matches it trainRouteGroups' range to
 * trainRoute's range
 * @param trainRouteOfTrainRun  The distanceRange to map
 * @param distanceRange
 * @returns {Object} The possibly updated distanceRange, which can be 0 to 0 if a range was chosen
 * that was outside of trainRoute's range
 */
export const convertDistanceRangeFromTrainRouteGroupToTrainRoute = (
  activeTrainRouteOrGroup: TrainRouteGroupDerived,
  trainRouteOfTrainRun: TrainRouteDerived,
  distanceRange: DistanceRange,
): DistanceRange => {
  if (
    implementsCemitTypeViaClass<TrainRoute>(
      CemitTypename.trainRoute,
      activeTrainRouteOrGroup,
    )
  ) {
    // No domain mapping neaded if the activeTrainRouteOrGroup is a TrainRoute
    if (!idsEqual(activeTrainRouteOrGroup, trainRouteOfTrainRun)) {
      throw new Error(
        'activeTrainRouteOrGroup is a TrainRoute but does not match trainRouteOfTrainRun',
      );
    }
    return trainRouteOfTrainRun.distanceRange;
  } else {
    // Create a converter
    const convert = createRouteDomainRangeFromAggregateConverter(
      activeTrainRouteOrGroup,
      trainRouteOfTrainRun,
    );
    // Map from the matching aggregate domain to the range that is the route min/max distances
    // Don't allow negative values, rather return a 0 distance range
    return compose<[DistanceRange], DistanceRange, DistanceRange>(
      applySpec({
        start: ({start}) => Math.max(start, 0),
        end: ({end}) => Math.max(end, 0),
      }),
      (distanceRange: DistanceRange) => map((value) => convert(value), distanceRange),
    )(distanceRange);
  }
};
/**
 * Creates a converter function to convert from the distanceRange domain of the given aggregateTrainRouteOrGroup
 * ot that of the given trainRoute, where the latter is always equal to or a subset of the aggregate TrainRoute
 * @param trainRouteGroup
 * @param trainRoute
 * @returns {Function} function expecting a distance in the aggregateTrainRouteOrGroup domain and converting
 * to the trainRoute's range. The returned distance can be negative if the value is outside the TrainRoute's range
 */
export const createRouteDomainRangeFromAggregateConverter = (
  trainRouteGroup: TrainRouteGroupDerived,
  trainRoute: TrainRouteDerived,
) => {
  const orderedRoutePoints: OrderedRoutePointDerived[] = extremes(
    trainRoute.orderedRoutePoints,
  );
  const trainRouteGroupOrderedRoutePointLookup: {[p: string]: OrderedRoutePointDerived} =
    indexBy<OrderedRoutePointDerived, string>(
      compose(prop('id'), scheduledStopPointOfOrderedRoutePoint),
      trainRouteGroup.orderedRoutePoints,
    );
  const matchingTrainRouteGroupPointDistances = map((routePoint: OrderedRoutePoint) => {
    const orderedRoutePoint: OrderedRoutePointDerived =
      trainRouteGroupOrderedRoutePointLookup[
        scheduledStopPointOfOrderedRoutePoint(routePoint).id
      ];
    if (!orderedRoutePoint) {
      throw new Error(
        'aggregateOrderedRoutePointLookup lacks the correct ScheduledStopPoint ids',
      );
    }
    return orderedRoutePoint.routePoint.routeDistance;
  }, orderedRoutePoints);
  const routePointDistances: number[] = map((orderedRoutePoint) => {
    return orderedRoutePoint.routePoint.routeDistance;
  }, orderedRoutePoints);
  return scaleLinear()
    .domain(matchingTrainRouteGroupPointDistances)
    .range(routePointDistances);
};

/**
 * Maps from on TrainRoute distanceRange domain to another where there are common ScheduledStopPoints
 * @param fromTrainRoute
 * @param toTrainRoute
 * @returns {Function}
 */
export const createRouteDomainRangeConverter = ({
  fromTrainRoute,
  toTrainRoute,
}: {
  fromTrainRoute: TrainRoute;
  toTrainRoute: TrainRoute;
}) => {
  const toTrainRouteOrderedRoutePointLookup = indexBy(
    compose(prop('id'), scheduledStopPointOfOrderedRoutePoint),
    toTrainRoute.orderedRoutePoints,
  );
  const fromTrainRouteOrderedRoutePoints = filter(
    (orderedRoutePoint) =>
      propOr(
        false,
        scheduledStopPointOfOrderedRoutePoint(orderedRoutePoint).id,
        toTrainRouteOrderedRoutePointLookup,
      ),
    fromTrainRoute.orderedRoutePoints,
  );
  const fromTrainRouteOrderedRoutePointLookup = indexBy(
    compose(prop('id'), scheduledStopPointOfOrderedRoutePoint),
    fromTrainRouteOrderedRoutePoints,
  );
  // Find the to OrderedRoutePoints that match the from OrderedRoutePoints
  const toTrainRouteOrderedRoutePointExtremes = extremes(
    filter(
      (orderedRoutePoint) =>
        propOr(
          false,
          scheduledStopPointOfOrderedRoutePoint(orderedRoutePoint).id,
          fromTrainRouteOrderedRoutePointLookup,
        ),
      toTrainRoute.orderedRoutePoints,
    ),
  );
  const domain = map((orderedRoutePoint) => {
    return fromTrainRouteOrderedRoutePointLookup[
      scheduledStopPointOfOrderedRoutePoint(orderedRoutePoint).id
    ].routePoint.routeDistance;
  }, toTrainRouteOrderedRoutePointExtremes);
  const range = map<OrderedRoutePoint, number>(
    reqStrPathThrowing('routePoint.routeDistance'),
    toTrainRouteOrderedRoutePointExtremes,
  );
  // Map the from OrderedRoutePoint distanceRange to the to OrderedRoutePoint distanceRange
  return scaleLinear().domain(domain).range(range);
};

/**
 * Returens the TrainRouteOrGroup trainDistanceInterval if the TrainRouteOrGroup is marked as
 * TrainRouteOrGroupDerived and trainDistanceInterval is defined
 * @param trainRouteOrGroup
 */
export const maybeTrainRouteOrGroupDistanceInterval = (
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup>,
) => {
  return maybeAsCemitedClass<TrainRouteOrGroupDerived>(
    CemitTypename.trainRouteOrGroupDerived,
    trainRouteOrGroup,
  )?.trainDistanceInterval;
};
